export default Backbone.Model.extend({

    initialize({ activityId }) {
        this.activityId = activityId
    },

    url() {
        return this.activityId ?
            '/training/get_session/' + this.id + '/' + this.activityId :
            '/training/get_session/' + this.id
    },

    parse(response) {
        this.isFinished = !!response.result
        this.list = new Backbone.Model(response.pairs_and_definition.metadata)

        if (!this.isFinished) {
            this.pairs = new Backbone.Collection(_.shuffle(response.pairs_and_definition.pairs))
            this.defineProgress(response.training_list_session.percentage_done)
        } else {
            this.result = response.result
        }
        return response.training_list_session
    },

    defineProgress(percentageDone) {

        const currentProgress = parseInt(percentageDone) || 0
        const remainingWordsNumber = this.pairs.length

        if (remainingWordsNumber) {
            const progressUpdate = (100 - currentProgress) / remainingWordsNumber
            this.set('currentProgress', currentProgress)
            this.set('progressUpdate', progressUpdate)
        }
    },

    incrementProgress() {
        const newProgress = this.get('currentProgress') + this.get('progressUpdate')
        this.set('currentProgress', newProgress)
    },

    decrementProgress() {
        const newProgress = this.get('currentProgress') - this.get('progressUpdate')
        this.set('currentProgress', newProgress)
    }
});
