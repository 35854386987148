import Styles from './Source1.scss';

import Template from './Source1.hbs';
import Util from 'util/util';
import ZoomButton from 'views/components/lightbox/zoomButton/ZoomButton';
import Lightbox from 'views/components/lightbox/Lightbox';
import TextToSpeechSource from 'views/components/taskGroups/sources/textToSpeech/TextToSpeech';

// Image source view
export default BaseView.extend({

    initialize(options) {

        this.title = this.model.get('title');

        this.source = this.model.get('source');

        // Turn newlines in caption into <br /> elements
        if (this.source && this.source.caption) {
            this.caption = Util.nl2br(this.source.caption);
        }

        // Assume the source has an image
        var hasImage = true;

        // Check if the source has an image
        if (
            this.source === null ||
                (
                    this.source &&
                    // New sources are given fileId 0 until an image is uploaded
                    this.source.fileId === 0
                )
        ) {
            hasImage = false;
        }

        this.setElement(Template({
            title: Util.renderContentSafely(this.title),
            imageId: hasImage && `${this.source.fileId}/${this.source.fileIdHash}`,
            caption: Util.renderContentSafely(this.caption),
            // TODO: Research if tripple brackets are really needed in HBS
            imageSource: hasImage && Util.renderContentSafely(this.source.imageSource),
            fullScreen: hasImage && this.source.fullScreen,
            size: hasImage && Styles[this.source.size] || Styles['full-width'],
            hasImage,
            Styles,
            isPortfolio: options.portfolioStyling
        }));

        // When the user is not on a mobile and the fullscreen option is set to true
        if (
            hasImage &&
                !ISMOBILE &&
                this.source.fullScreen
        ) {

            // Create the zoom button
            this.renderZoomButton();

            // Bind clicks on the image
            this.$('.js-source-image').on('click', () => {
                this.addChildView(new Lightbox({
                    contextUrl: `/edu_files/open/${this.source.fileId}/${this.source.fileIdHash}`
                }))
            })
        }

        // Disable speech if no image or no speech caption is present
        if (hasImage && this.source.speechCaption) {
            this.addChildView(new TextToSpeechSource({
                sourceId: this.model.id,
                audioPlayerTargetElement: this.$('.js-speech-player'),
            }), '.js-get-speech-button')
        }

    },

    renderZoomButton() {
        const zoomButton = this.addChildView(new ZoomButton({
            contextUrl: `/edu_files/open/${this.source.fileId}/${this.source.fileIdHash}`
        }), '.js-image-container')
        zoomButton.$el.addClass(Styles['zoombtn--source-1']);
    }

});
