var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"button--hidden") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"extensionIcon") : depth0),{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":58}}})) != null ? stack1 : "")
    + "</div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lock-icon") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"lock",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":56}}})) != null ? stack1 : "")
    + "</div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"extension-name") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"extensionName") : depth0), depth0))
    + "</div>\n</div>";
},"useData":true});