import Styles from './OrderForm.scss';

import Template from './OrderForm.hbs';
import Button from 'views/components/button/Button'
import ProductVariant from './productVariant/ProductVariant'
import RadioButton from 'views/components/radioButton/RadioButton';

export default BaseView.extend({

    initialize({ detailsOpen = false }) {
        const productName = this.model.name
        const userFullName = Backbone.Model.user.first_name_last_name()
        const userMail = Backbone.Model.user.get('email')
        const hasProductVariants = this.model.Variants && this.model.Variants.length

        this.setElement(Template({
            Styles,
            productName,
            userFullName,
            userMail,
            hasProductVariants,
            detailsOpen
        }))

        if (hasProductVariants) {

            // We have a product with variants, let the student choose from multiple options
            const variants = []
            for (const variant of this.model.Variants) {

                variants.push({
                    id: variant.id,
                    name: variant.name,
                    price: (variant.price_including_taxes || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    taxPercentage: (variant.tax_percentage || 0).toLocaleString(),
                    expirationDate: dayjs.utc().add(variant.license_validity_duration_in_days, 'days').format('D MMMM YYYY')
                })

                this.addChildView(
                    new RadioButton({
                        value: variant.id,
                        label: new ProductVariant({
                            name: variant.name,
                            price: (variant.price_including_taxes || 0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }),
                            taxPercentage: (variant.tax_percentage || 0).toLocaleString(),
                            expirationDate: dayjs.utc().add(variant.license_validity_duration_in_days, 'days').format('D MMMM YYYY')
                        }),
                        parentGroup: 'variant'
                    }),
                    '.js-variants'
                )
            }
        } else {
            // We have a product without variants, show a single option
            this.addChildView(
                new ProductVariant({
                    name: productName,
                    price: (this.model.price_including_taxes || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    taxPercentage: (this.model.tax_percentage || 0).toLocaleString(),
                    expirationDate: dayjs.utc().add(this.model.license_validity_duration_in_days, 'days').format('D MMMM YYYY')
                }),
                '.js-variants'
            )
        }

        // Add button to place the order.
        this.orderBtn = this.addChildView(new Button({
            label: window.i18n.gettext('Order'),
            inline: true,
            callback: () => { this.placeOrder() },
        }), '.js-order-btn');

    },

    /**
         * placeOrder
         *
         * Post order to backend. Show alert message that reloads the page when dismissed,
         * bringing the user to a actual activity.
         */
    placeOrder() {
        const product_id = this.model.id
        let variant_id = null

        // If the product has variants, check if the student chose one
        if (this.model.Variants && this.model.Variants.length) {
            const selection = this.el.querySelector('input[name=variant]:checked')

            if (!selection) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Select the option you want to order.'),
                )
                return
            }

            variant_id = selection.value
        }

        // Get the payment url and redirect the user
        $.post(
            '/products/payment_url.json', {
                product_id, variant_id
            }, (response) => {
                window.location = response.redirect_url
            }
        );
    }

});
