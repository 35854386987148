import QuickInput from 'views/components/quickInput/QuickInput'
import ShapeList from 'util/ShapeLoader'

export default class PasswordInput extends QuickInput {

    #showingPassword = false

    initialize({
        passwordCallback = () => {},
        isNewPassword
    }) {

        super.initialize({
            ...arguments[0],
            type: 'password',
            keepValueOnCallback: true,
            statusIcon: 'eye',
            statusIconCallback: this.onClickToggleShowPassword.bind(this)
        })

        this.inputCallback = passwordCallback

        this.inputElement

            // Check for key presses inside the input
            .on('keyup', this.onKeyUp)

            .attr({
                // Auto complete password if username input is found in the same form.
                // See https://html.spec.whatwg.org/multipage/form-control-infrastructure.html
                // If the user is making a new password, use the new-password auto complete
                // attribute instead of current-password so the browser or a password manager
                // can suggest a new password for the user.
                autocomplete: isNewPassword ? 'new-password' : 'current-password',
                // Make the type of the input password
                type: 'password',

                name: 'password',
                id: `${this.cid}-password`,
            })

    }

    onClickToggleShowPassword(e) {

        e.stopImmediatePropagation()

        if (!this.#showingPassword) {

            // Chang login input field to type text
            this.inputElement.attr('type', 'text')

            // Change the icon to prompt coding the password text
            this.$('.js-status').html(ShapeList['eye-off'])
        } else {

            // Change login input field to type password
            this.inputElement.attr('type', 'password')

            // Change the icon to prompt decoding the password text
            this.$('.js-status').html(ShapeList.eye)
        }

        this.#showingPassword = !this.#showingPassword
        return false;

    }

}
