import Styles from './ActivityChooser.scss';

import Template from './ActivityChooser.hbs';
import ContentLabel from 'views/components/contentLabel/ContentLabel.svelte'

/**
 * Modal for selecting active exam. When selected, the teacher
 * is navigated to the progress screen of that exam
 */
export default BaseView.extend({

    /**
     * @param {Object} options options object
     * @param {Object[]} options.exams exam activities
     */
    initialize({
        activityModels,
        goToUrl
    }) {

        this.setElement(Template({ Styles }))

        activityModels.forEach(activityModel => {
            this.addSvelteChildView(this.$el, ContentLabel, {
                model: activityModel,
                labelSuffix: activityModel.getGroupModel().get('name'),
                smallText: true,
                link: `${goToUrl}/${activityModel.get('id')}`
            })
        })
    }
})
