import Styles from './QuickInput.scss';

import Template from './QuickInput.hbs';
import Util from 'util/util';

export default class QuickInput extends BaseView {

    get events() {
        return {
            click: 'focusInput'
        }
    }

    /**
     *
     * @param {Boolean} autoFocus       If true, focus input on init
     * @param {Boolean} defaultIfEmpty  If true, return initial default value when input field is empty
     * @param {String} defaultValue     Initial value for the input field
     * @param {Function} deleteCallback Callback for the delete button
     * @param {Boolean} displayInline   If true, display as inline-flex instead of flex and auto-resize to fit content
     * @param {Boolean} enableHTMLshortcuts If true, enable shortcuts to add markup HTML tags using keyboard commands
     * @param {Boolean} hasUppercase    If true, transform input to only upper case
     * @param {Function} inputCallback  Callback when user hits enter or the button
     * @param {Function} inputCallbackWithoutButton Callback when user hits enter
     * @param {Boolean} doInputCallbackOnBlur If true, trigger input callback when input element emits a blur event
     * @param {String} inputMode        Force on-screen keyboard input mode for input field
     * @param {Boolean} isReadOnly      If true, set input field to be read-only
     * @param {Boolean} isWider         If true and combined with displayInline, min width of input is bigger
     * @param {Boolean} keepValueOnCallback If true, input field content does not get removed after inputCallback call
     * @param {Number} maxLength        Max charachter limit of input. Displays limit to the user.
     * @param {Boolean} noAutoCapitalize If true, disable auto capitalization
     * @param {Boolean} noAutoCorrect   If true, disable auto correct, auto complete and spell check
     * @param {Boolean} noMargin        If true, remove margins from around component
     * @param {Function} pasteCallback  Callback called after paste event
     * @param {String} placeholder      Input field placeholder string
     * @param {Function} searchCallback Callback called on every input, usefull for search inputs
     * @param {Boolean} skipValidation  If true, skips content sanitisation (WARNING: USE WITH CARE)
     * @param {String} theme            Optional special theming
     * @param {String} statusIcon       Optional icon at the right of the text input field for custom interactions
     * @param {Function} statusIconCallback Interaction when clicking on the status icon, if present.
     * @param {String} type             Set input field type (eg. 'text', 'email', 'url', 'number', etc.)
     * @param {String} label            Optional string to use as <label>
     * @param {Number} minSearchString  Optional number to determine minimal number of character to call searchcallback
     *
     * @memberof QuickInput
     */
    initialize({
        autoFocus,
        defaultIfEmpty,
        defaultValue,
        deleteCallback,
        displayInline,
        enableHTMLshortcuts,
        hasUppercase,
        inputCallback,
        inputCallbackWithoutButton,
        doInputCallbackOnBlur,
        inputMode,
        isReadOnly,
        isWider,
        keepValueOnCallback,
        maxLength,
        noAutoCapitalize,
        noAutoCorrect,
        noMargin,
        pasteCallback,
        placeholder,
        searchCallback,
        skipValidation,
        theme,
        statusIcon,
        statusIconCallback,
        type,
        label,
        minSearchStringLength = 2
    }) {

        _.bindAll(
            this,
            'autoResize',
            'focusInput',
            'getInput',
            'onKeyUp',
            'onKeyUpSearch',
            'showCharacterCount',
            'triggerDeleteCallback',
            'triggerInputCallback',
            'enterHTMLshortcut'
        );

        // Return default value (or placeholder, if no default value is specified) when inputfield is empty.
        this.defaultIfEmpty = defaultIfEmpty;
        this.placeholder = placeholder;
        this.defaultValue = defaultValue;
        this.keepValueOnCallback = keepValueOnCallback;
        this.maxLength = maxLength;
        this.minSearchStringLength = minSearchStringLength;

        // If true, removal of script tags and other interactivity by validating the input as HTML is skipped.
        // This is usefull for when the you don't want the browser to fix invalid HTML. See issue LB6919 for
        // more information.
        // WARNING
        // Only use this when the content does not get rendered as HTML in the DOM.
        this.skipValidation = skipValidation;

        this.setElement(Template({

            cid: this.cid,

            label,

            Styles,

            theme: Styles[`quick-input--theme-${theme}`],

            // Boolean, if true input is displayed inline-flex instead of flex and adjusts its size to the content.
            displayInline,

            // [Optional] String to use as a placeholder value.
            placeholder: this.placeholder,
            // [Optional] String to pre-fill the input field.
            defaultValue: this.defaultValue,

            // If input type is email, set type to email or default text
            inputType: type || 'text',

            // [Optional] String, set special keyboard input mode appropriate for the current input type.
            inputMode: inputMode || ['search', 'email', 'url'].includes(type) && type,

            statusIcon,

            // [Optional] Boolean, if true it will not add margin so position should be done by parent
            noMargin,

            // [Optional] Boolean, if true disable autocomplete, autocorrect and spellcheck.
            noAutoCorrect,

            isReadOnly,

            maxLength: this.maxLength,

            // [Optional] Boolean, if true removes auto capitalization
            noAutoCapitalize: !!noAutoCapitalize,

            // [Optional] Boolean, if true show input text upcased
            hasUppercase: !!hasUppercase,

            // [Optional] Boolean, if true input has more width
            isWider: !!isWider,

            // If an optional input callback function is defined, render an submit button next to the field.
            inputBtn: inputCallback instanceof Function && !searchCallback,

            // If an optional keyup callback function is defined, render an search button next to the field.
            searchBtn: searchCallback instanceof Function,

            // If an optional delete callback function is defined,
            // render a delete button next to the field
            deleteBtn: deleteCallback instanceof Function,

        }));

        // Assign input element to variable for easy lookup later.
        this.inputElement = this.$('input');

        // If a an input callback function is defined, create event listeners for the inputfield keys and
        // clicks on the submit button.
        if (inputCallback instanceof Function || inputCallbackWithoutButton instanceof Function) {
            this.inputCallback = inputCallback || inputCallbackWithoutButton;
            this.inputElement.on('keyup', this.onKeyUp);
            this.$('.js-enter').on('click', this.triggerInputCallback);
            this.$('.js-enter').on('keyup', this.onKeyUp);
            if (doInputCallbackOnBlur) {
                this.$el.on('focusout', this.triggerInputCallback)
            }

            if (type === 'search') {
                this.$('.js-search').on('mousedown', this.triggerInputCallback);
            }
        }

        // If a delete callback function is defined,
        // create listeners for clicks on the delete button
        if (deleteCallback instanceof Function) {

            // If a parent has been defined, save it to the view.
            // Making it possible to delete the parent
            if (parent) {
                this.parent = parent;
            }

            this.deleteCallback = deleteCallback;

            this.$('.js-delete').on('click', this.triggerDeleteCallback);
        }

        if (pasteCallback instanceof Function) {
            this.inputElement.on('paste', pasteCallback)
        }

        if (searchCallback instanceof Function) {
            this.hasSearchString = !!(this.defaultValue);
            this.searchCallback = searchCallback;
            this.inputElement.on('input', this.onKeyUpSearch);
        }

        // If input element is displayed inline, auto resize the input area to fit the content.
        if (displayInline) {
            this.inputWithMeasureElement = this.$('.js-auto-size-width-measure');
            this.inputElement.on('input', this.autoResize);
            _.defer(this.autoResize);
        }

        // Show current character count of input compared to max character count if a
        // max input length is more than 0.
        if (this.maxLength > 0) {
            this.showCharacterCount();
            this.inputElement.on('input', this.showCharacterCount);
        }

        // If autoFocus is true, focus input field and select all of the content when the DOM is ready.
        if (autoFocus) {
            this.inputElement.ready(() => {
                Util.placeCaret(this.inputElement[0], true)
            })
        }

        if (statusIcon && statusIconCallback instanceof Function) {
            this.$('.js-status').on('click', (e) => {
                e.stopImmediatePropagation()
                statusIconCallback(e)
            })
        }

        // If true, allow users to press for example ctrl-b to insert <b> tags
        if (enableHTMLshortcuts) {
            this.el.querySelector('input').addEventListener('keydown', this.enterHTMLshortcut)
        }
    }

    focusInput(e) {
        // Prevent click from propagating to parent views.
        this.stopAllEvents(e);

        this.el.querySelector('input').focus()
    }

    /**
     * autoResize
     *
     * Since input elements do not resize to fit their value, use an invisible
     * dummy span element for measuring the actual width of the text.
     */
    autoResize() {
        this.inputWithMeasureElement.text(this.inputElement.val());
        this.inputElement.innerWidth(this.inputWithMeasureElement.innerWidth());
    }

    /**
     * showCharacterCount
     *
     * Show current input character count and maximum character count.
     */
    showCharacterCount() {
        this.$('.js-char-count').text(this.getInput().length + ' / ' + this.maxLength);
    }

    /**
     * onKeyUp
     *
     * @param {jQuery.event} e  jQuery's event object
     */
    onKeyUp(e) {

        // If the input field is disabled, do not trigger a callback
        if (this.$('input:disabled').length > 0) {
            return
        }

        // If key pressed is Enter.
        if (e.keyCode === 13 || e.key === 'Enter') {
            this.stopAllEvents(e);
            this.triggerInputCallback();
        }
    }

    /**
     * onKeyUpSearch
     *
     * This function will be called when the user lets the key
     * up. It will get the inputted value and check the length
     * of it.
     *
     */
    onKeyUpSearch() {

        // Get the inputted value
        var query = this.getInput();

        // Check if there are at least 3 characters
        if (query.length > this.minSearchStringLength) {

            // Call the search callback with the input value as param
            this.searchCallback(query);

            // Set hasSearchString to true to precent unneccesary callback calls
            this.hasSearchString = true;

            // Else there value is to small to search
        } else {

            // Check if hasSearchString is true, this check is made
            // to prevent unneccesary callback calls when the user goes
            // from 3 character to 2 and from 2 to 1 and from 1 to 0.
            if (this.hasSearchString) {

                // Call searchCallback with empty value
                this.searchCallback('');

                // Set hasSearchString to false
                this.hasSearchString = false;
            }
        }
    }

    /**
     * triggerInputCallback
     *
     * Execute the input callback function with the field's content as an argument, then clear the field's content.
     *
     * @param {Event|undefined} e   event that triggered this method
     */
    triggerInputCallback(e) {
        // When focusing out anywhere in the QuickInput component, check if the focus shifts to another element
        // within the component (eg. from a <input> to a <button> and visa versa). If so, do not trigger any
        // callbacks based on the input.
        if (e && e.type === 'focusout' && this.el.contains(e.relatedTarget)) {
            return
        }

        this.inputCallback(this.getInput());

        if (!this.keepValueOnCallback) {
            this.inputElement.val('');
        }
    }

    /**
     * triggerDeleteCallback
     *
     * Execute the delete callback function.
     * If a parent is given through the options, destroy it aswell.
     * If not. Just destroy the quickinput.
     */

    triggerDeleteCallback() {
        this.deleteCallback();
    }

    /**
     * getInput
     *
     * Get input field value.
     * If value is an empty string and defaultIfEmpty is true, return default value instead, and if that is not
     * defined, return placeholder value. If that isn't defined either, return empty string.
     *
     * @return {string} trimmed input element contents with all scripts and unwanted attributes removed.
     */
    getInput() {
        var s = (
            this.skipValidation ? this.inputElement.val()
            // Unescape sequence such that some characters like "&" get encoded correctly after sanitisation.
                : Util.unescape(Util.stripScripts(this.inputElement.val()))
        ).trim();
        return this.defaultIfEmpty && s.length === 0 ? this.defaultValue || this.placeholder || s : s;
    }

    /**
     * setInput
     *
     * Overwrite input field contents with something else.
     *
     * @param {string} value Value that should be set in the input
     */
    setInput(value) {
        this.inputElement.val(value);
    }

    /**
     * disable
     *
     * Disables input field.
     *
     */
    disable() {
        this.$('input').attr('disabled', true);

        this.$el.addClass(Styles['quick-input--is-disabled']);
    }

    /**
     * enable
     *
     * Enables input field.
     */
    enable() {
        this.$('input').attr('disabled', false);

        this.$el.removeClass(Styles['quick-input--is-disabled']);

    }

    changePlaceholder(newPlaceholder) {
        this.inputElement.attr('placeholder', newPlaceholder)
    }

    setCustomValidity(message) {
        this.inputElement[0].setCustomValidity(message)
        // Clear validation state when user inputs a new value
        this.inputElement.one('input', () => {
            this.inputElement[0].setCustomValidity('')
        })
    }

    // If the user presses ctrl-b, ctrl-i, ctrl-u, ctrl-= or ctrl-shift-= insert HTML tags around the selected
    // text or at the cursor position
    enterHTMLshortcut(e) {

        // On Apple devices check if command key is pressed, otherwise check if control key is pressed
        const os = window.uaparser.getOS().name
        if (os === 'Mac OS' || os === 'iOS' ? !e.metaKey : !e.ctrlKey) {
            return
        }

        let tag
        switch (e.keyCode) {
            case 66:
                tag = 'b'
                break
            case 73:
                tag = 'i'
                break
            case 85:
                tag = 'u'
                break
            case 187:
                tag = e.shiftKey ? 'sup' : 'sub'
                break
            default:
                return
        }

        e.stopPropagation()
        e.preventDefault()

        // Insert the HTML tag in the text
        const input = this.el.querySelector('input')
        const before = input.value.substr(0, input.selectionStart)
        const selection = input.value.substr(
            input.selectionStart,
            input.selectionEnd - input.selectionStart
        )
        const after = input.value.substr(input.selectionEnd)
        input.value = before + '<' + tag + '>' + selection + '</' + tag + '>' + after

        // Place the cursor after the closing tag (after the selection, 2 tag names, 5 characters such as <>)
        const cursorPosition = before.length + selection.length + (2 * tag.length) + 5
        input.setSelectionRange(cursorPosition, cursorPosition)
    }
}
