var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"transcript") : stack1), depth0))
    + " source__theme-default\">\n					<div class=\"source-title\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Transcript",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":50}}}))
    + "</div>\n					<div class=\"source-text\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"transcript") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n				</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n	<div class=\"source-container\">\n		<div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"source-7") : stack1), depth0))
    + "\">\n			<div class=\"js-audio-player\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"transcript") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":3},"end":{"line":11,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n";
},"useData":true});