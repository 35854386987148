import Styles from './SelectSubjects.scss';

import Template from './SelectSubjects.hbs';
import SubjectItem from 'views/pages/activities/show/types/adaptive/selectSubjects/subjectItem/SubjectItem'
import TreeView from 'views/components/treeView/TreeView'
import SubjectsCollection from 'collections/SubjectsCollection'

export default BaseView.extend({

    initialize(options) {

        // Make this accessible within following methods
        _.bindAll(this,
            'renderSubject',
            'onLoadSubject',
            'updateSaveButtonWithSelectedNumber'
        )

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }))

        this.collection = new SubjectsCollection(options.defaultSubjects.models)
        this.collection.url = `/activities/get_subjects_status/${options.activityId}.json`

        // Fetch the collection
        this.collection.fetch({

            // Passing the following data
            data: $.param({

                // Add the chapter id to the data
                chapter_id: this.model.id,

                // Tell backend we need a filterable list
                add_section_ids: true,
            }),

            // Add the following callback
            success: this.onLoadSubject
        })

        // Create collection with a single chapter in it as input for the tree view used for filtering subjects by
        // their section id.
        const chaptersCollection = new this.model.collection.constructor(this.model)
        chaptersCollection.first().sections.each((section) => {
            // Check each section in the chapter by default.
            section.set({isChecked: true})
        })
        this.addChildView(new TreeView({
            rootCollection: chaptersCollection,
            isSelectable: true,
            maxDepth: 1
        }), '.js-content')

        // Show number of selected subjects in submit button label on init and when this number changes.
        _.defer(this.updateSaveButtonWithSelectedNumber)
        this.listenTo(
            this.collection,
            'change:isChecked',
            this.updateSaveButtonWithSelectedNumber
        )

    },

    /**
     * onLoadSubject
     *
     * When the subjects collection is succesfully fetched,
     * this method will be called. It will loop through the collection
     * and call the renderSubject method for each item.
     *
     * @param  {SubjectsCollection} collection     Collection of subjects
     */
    onLoadSubject(collection) {
        collection.each(this.renderSubject)
    },

    /**
     * renderSubject
     *
     * Create a new SubjectItem view and add it to the DOM.
     *
     * @param  {SubjectModel} subjectModel   subject model
     */
    renderSubject(subjectModel) {
        this.addChildView(new SubjectItem({
            model: subjectModel,
            chapterModel: this.model
        }), '.js-subjects')
    },

    /**
     * updateSaveButtonWithSelectedNumber
     *
     * Update fullscreen submit button label to reflect total number of selected subjects.
     */
    updateSaveButtonWithSelectedNumber() {

        // Get the number of selected subjects
        const numberSelectedSubjects = this.collection.where({isChecked: true}).length

        // Get the second fullscreen button. Since the second button is the save
        // button we should be able to rely on the array ordering
        // And get the label element. Change the text to:
        Backbone.View.Components.fullscreen.getButtons()[1].labelElement.innerText =
            window.i18n.sprintf(
                window.i18n.gettext('Use %s subjects'),
                numberSelectedSubjects
            )
    }

})
