import Styles from './Theory.scss';

import Template from './Theory.hbs';
import Source from 'views/components/taskGroups/sources/Source';
import SearchResults from 'views/components/fullscreen/theory/searchResults/SearchResults.svelte';
import SelectTheory from 'views/components/modals/selectTheory/SelectTheory';
import QuickInput from 'views/components/quickInput/QuickInput'
import Button from 'views/components/button/Button';
import TheorySourcesCollection from 'collections/TheorySourcesCollection'

export default BaseView.extend({

    events: {
        'click .js-open-theory-branch': 'onClickTheoryBranch',
        'click .js-toggle-theory-index': 'toggleTheoryIndex',
        'click .js-source-content-container': 'onClickTheoryContent'
    },

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options as defined by parent
     */
    initialize(options) {
        _.bindAll(this,
            'render',
            'scrollspy',
            'renderSources',
            'getSiblingsArray',
            'toggleTheoryIndex',
            'onLoadTheoryBranches',
            'loadSourcesByBranchIds',
            'getArraySiblingsByIndex',
            'onSourcesRendered',
            'onClickPrint'
        );

        // store preselectedTheoryBranch if View is initialized with branch id
        if (options.theory_branch_id) {
            this.preselectedTheoryBranch = options.theory_branch_id;
        }

        // store preselectedSourceId if View is initialized with source id
        if (options.source_id) {
            this.preselectedSourceId = options.source_id;
        }

        // store Theory Collection Id for later use (f.e. in concept search)
        this.theoryCollectionId = options.theory_collection_id;

        // load index (nested branched) for the theory collection
        $.get(
            '/theory/get_nested_branches_for_collection/' + options.theory_collection_id + '.json',
            this.onLoadTheoryBranches
        );

        $('body').addClass('theory');
    },

    /**
     * onChangeSearchField
     *
     * will process value changes of the search field
     * @param {String} query    search field input
     */
    onChangeSearchField(query) {

        // Only search with 3 characters or more
        if (query.length < 3) {

            // show the index again
            this.$('.js-levels').show()

            // hide the search results holder
            this.$('.js-search-results-holder').hide()

            return
        }

        // Add results view if not already there
        if (!this.searchResultsView) {
            this.searchResultsView = this.addSvelteChildView('.js-search-results-holder', SearchResults, {
                theoryCollectionId: this.theoryCollectionId,
                onClickResultCallback: (sourceId, branchId) => {
                    this.preselectedSourceId = sourceId
                    this.preselectedTheoryBranch = branchId
                    this.openSecondaryBranchId(branchId, true)
                }
            })
        }

        // Send search request to backend
        this.searchResultsView.search(query)

        // hide the default index
        this.$('.js-levels').hide();

        // show the search results holder
        this.$('.js-search-results-holder').show();

    },

    /**
     * onClickTheoryBranch
     *
     * This function listens to clicks on theory branches and calls the openTheoryBranch function after.
     *
     * @param  {event} e - the click event
     */
    onClickTheoryBranch(e) {

        // Get the element on which the user has clicked and call the openTheoryBranch function
        this.openTheoryBranch(e.currentTarget);
    },

    /**
     * openTheoryBranch
     *
     * This function will be called when the user clicks on a branch link
     * in the index.
     *
     * @param  {Element} element    selected element
     */
    openTheoryBranch(element) {

        // Select the clicked element with jQuery
        const selectedBranchElement = $(element);

        // Switch/case the data-type
        switch (selectedBranchElement.data('type')) {

            // When the type is primary
            case 'primary' :

                // Get the holder element
                var branchHolder = selectedBranchElement.closest('.js-theory-branch-holder');

                // When in responsiveness mode, when a primary level gets a click
                // the index should be toggled to open
                this.toggleTheoryIndex('open');

                // Check if the state is closed
                if (branchHolder.data('theory-holder-state') === 'closed') {

                    // Set the state to open
                    branchHolder.data('theory-holder-state', 'open');

                    // Add class to open the holder
                    branchHolder.addClass(Styles['index-level--open']);

                    TweenMax.fromTo(branchHolder, {
                        height: branchHolder.find('.js-open-theory-branch').height()
                    }, {
                        duration: 0.35,
                        height: branchHolder.height(),
                        ease: 'power3.inOut',
                        onComplete() {
                            TweenMax.set(branchHolder, { height: 'auto' });
                        }
                    });

                // Else the holder is open, we need to close it
                } else {

                    TweenMax.to(branchHolder, {
                        duration: 0.35,
                        height: branchHolder.find('.js-open-theory-branch').height(),
                        ease: 'power3.inOut',
                        onComplete() {
                            // Set the state to closed
                            branchHolder.data('theory-holder-state', 'closed');

                            // Remove the open class from the holder
                            branchHolder.removeClass(Styles['index-level--open']);

                            TweenMax.set(branchHolder, {height: 'auto'});
                        }
                    });

                }

                break;

            // When the type is secondary
            case 'secondary' :

                var clickedBranchId = selectedBranchElement.data('theory-branch-id');
                this.openSecondaryBranchId(clickedBranchId, false);

                break;
        }
    },

    /**
     * openSecondaryBranchId

        * @param  {int}  clickedBranchId    id of branch to open
        * @param  {Boolean} isCalledFromSearch true if called from a search result
        *
        */
    openSecondaryBranchId(clickedBranchId, isCalledFromSearch) {

        // if not called from search, there is no preselectedSourceId
        // so we need to find one
        if (!isCalledFromSearch) {

            // Find in the theory collection all theory for this branch using the Spy Id. This
            // is the ID given to the model for the scrollSpy to identify which source belongs
            // to which branch.
            const theoryModels = this.theoryCollection.where({
                TheorySpyId: clickedBranchId
            });

            // When there are models found
            if (theoryModels.length > 0) {

                // Get the first model from found theories
                // add set the preselectedSourceId so that
                // when sources are rendered, the view can be scrolled to
                // the correct source
                this.preselectedSourceId = theoryModels[0].id;
            } else {
                this.preselectedTheoryBranch = clickedBranchId;
            }
        }

        // When the sources are rendered, execute inline function
        // This function should be above the loadSourcesByBranchIds
        this.$el.on('sourcesRendered', this.onSourcesRendered);

        // Create an array getting siblings by executing getArraySiblingsByIndex
        var selfAndSiblings = this.getSiblingsArray(clickedBranchId);

        // Get the sources for the clicked branchId
        this.loadSourcesByBranchIds(_.pluck(selfAndSiblings, 'id'));
    },

    /**
     * openIndexAndScrollToSource
     *
     * This function will take the globally defined preselected theory
     * branch and preselected source id and use it to fold open the right
     * item at the index and scroll to the right source
     *
     */
    openIndexAndScrollToSource() {

        // If there is a globally preselected source
        if (this.preselectedSourceId !== undefined) {

            // Create event for when sources are rendered so we can scroll to it
            this.$el.on('sourcesRendered', this.onSourcesRendered);
        }

        // If there is a globally preselected branch
        if (this.preselectedTheoryBranch !== undefined) {

            // Get the level item of the preselectedTheoryBranch
            var levelItem = this.$(
                '.js-open-theory-branch[data-theory-branch-id="' +
                    this.preselectedTheoryBranch +
                '"][data-type="secondary"]'
            );

            // Get the holder element
            var branchHolder = levelItem.closest('.js-theory-branch-holder');

            // Check if the state is closed
            if (branchHolder.data('theory-holder-state') === 'closed') {

                // Set the state to open
                branchHolder.data('theory-holder-state', 'open');

                // Add class to open the holder
                branchHolder.addClass(Styles['index-level--open']);

            // Else the holder is open, we need to close it
            } else {

                // Set the state to closed
                branchHolder.data('theory-holder-state', 'closed');

                // Remove the open class from the holder
                branchHolder.removeClass(Styles['index-level--open']);
            }
        } else {

            // Simulate click on the first secondary theory branch.
            // This will open the theory book on the fist page.
            this.openTheoryBranch(this.$('.js-open-theory-branch[data-type="secondary"]')[0]);
        }
    },
    /**
     * onSourcesRendered
     *
     * is called when the (new) sources are rendered, so we can scroll to
     * the correct position
     */
    onSourcesRendered() {

        var selectedSource = null;

        // get the jquery object to scroll to
        // base on euther the preselectedSourceId
        // or the preselectedTheoryBranch
        if (this.preselectedSourceId) {
            // Distill the active source element using the data-source-id attribute
            selectedSource = this.$('[data-source-id=' + this.preselectedSourceId + ']');

            // reset preselectedSourceId & preselectedTheoryBranch
            this.preselectedSourceId = null;
            this.preselectedTheoryBranch = null;

        } else if (this.preselectedTheoryBranch) {

            var firstSourceInBranch = this.theoryCollection.findWhere({
                TheorySpyId: this.preselectedTheoryBranch
            });

            // Fix for LB6607; This can occur when the user clicks on the learning material
            // button at a higher level. Normally it'll start at section 1.1. But when there
            // are no sources in that section, the frontend will crash. This fix will navigate
            // the user to the first available source. This is also a data problem, since there
            // shouldn't be empty branches
            if (!firstSourceInBranch) {
                firstSourceInBranch = this.theoryCollection.first();

                window.sentry.withScope(scope => {
                    scope.setTag('theory_collection_id', this.theoryCollectionId);
                    scope.setTag('theory_branch_id', this.preselectedTheoryBranch);
                    scope.setTag('collection-branch', `collection_id: ${this.theoryCollectionId} - branch_id: ${this.preselectedTheoryBranch}`);
                    window.sentry.captureException(new Error('Empty branch found in theory'));
                });
            }

            selectedSource = firstSourceInBranch ? this.$('[data-source-id=' + firstSourceInBranch.id + ']') : null

            this.preselectedTheoryBranch = null;
        }

        // if source is found
        // scroll to correct location
        if (selectedSource !== null && selectedSource.length) {

            var scrollTop = selectedSource[0].offsetTop;

            // check if source is part of a mergedSource
            if (selectedSource.parents('.js-source').length > 0) {

                // than add the offset of that source to the scrollTop
                scrollTop += selectedSource.parents('.js-source')[0].offsetTop;
            }

            // check if index is header on top of page (in response mode)
            if (this.$('.js-toggle-theory-index').css('display') === 'flex' &&
                this.$('.js-toggle-theory-index').css('width') !== '300px') {
                scrollTop -= parseInt(this.$('.js-toggle-theory-index').css('height'));
            }

            // Scroll container to the right offset
            this.sourceContainer.scrollTop(
                scrollTop
            );
        }

        // When in responsiveness mode, when a secondary level gets a click
        // the index should be closed
        this.toggleTheoryIndex('close');

        // Remove event since we're done with this load
        this.$el.off('sourcesRendered');
    },

    /**
     * getArrayOfSecondaryBranches
     *
     * This function will get all the secondary branches and returns it as an
     * array
     *
     * @return {Array}  Array containing all the secondary branches
     */
    getArrayOfSecondaryBranches() {

        // Get an array of secondary branches by flatten the plucked array
        return _.flatten(

            // Since we need the second level, we need the children of the highest level
            // so we can easily pluck all the children objects. This will create an array
            // with array containing the children. If we flatten this array shallow we get
            // a big array containing all the children
            _.pluck(this.theoryBranches, 'children'),

            // Shallow is true
            true
        );
    },

    /**
     * getSiblingsArray
     *
     * This function will take a branch ID and returns itself including its
     * next two siblings with it.
     *
     * @param  {string} id  Based on a branch ID it wil get siblings
     * @return {Array}      Array of sibling branches
     */
    getSiblingsArray(id) {

        // Get the array with secondaryBranches
        var secondaryBranches = this.getArrayOfSecondaryBranches();

        // Get the index of the clicked item so we can get the siblings of it
        var indexOfClicked = _.findIndex(secondaryBranches, {id});

        // Return an array of gotten siblings by executing getArraySiblingsByIndex
        return this.getArraySiblingsByIndex(secondaryBranches, indexOfClicked, 2);
    },

    /**
     * getArraySiblingsByIndex
     *
     * This function will get the sibling objects from an array.
     * It has an optional option depth to determine how many
     * siblings deep we need to get. When depth is higher than
     * number of siblings before or after it the depth will reach
     * to what is possible and skip the rest.
     *
     * @param  {Array} array        Array where sibling should be found
     * @param  {integer} index      Index of self within passed array
     * @param  {integer} depth   How many siblings deep
     * @return {Array}              Array with siblings
     */
    getArraySiblingsByIndex(array, index, depth) {

        // Check if depth is not undefined and is an integer
        if (depth !== undefined && parseInt(depth) === depth) {

            // This will be the array holding the siblings
            var siblingArray = [];

            // Create a holder for the depth, so the original will not be modified
            var beforeDepth = depth;

            // Create a holder for the index, so the original will not be modified
            var beforeIndex = (index - (depth + 1));

            // Execute loop untill depth is completed or index reached 1
            while (beforeDepth > 0) {

                // Increment beforeindex
                beforeIndex++;

                // While we are still in a valid range and result is not undefined
                if (beforeIndex >= 0 && array[beforeIndex] !== undefined) {

                    // Add to siblingArray
                    siblingArray.push(array[beforeIndex]);
                }

                // Decremenet depth
                beforeDepth--;
            }

            // Add self to array
            siblingArray.push(array[index]);

            // Create a holder for the depth, so the original will not be modified
            var afterDepth = depth;

            // Create a holder for the index, so the original will not be modified
            var afterIndex = index;

            // Execute loop untill depth is completed
            while (afterDepth > 0) {

                // Increment index
                afterIndex++;

                // Limit is array length minus 2 since array starts at 0 and length at 1
                // and when loop is executed we do ++
                if (afterIndex <= (array.length - 2) && array[afterIndex] !== undefined) {
                    siblingArray.push(array[afterIndex]);
                }

                // Decrement the depth
                afterDepth--;
            }

            // Return the result
            return siblingArray;

        // Else only get the first siblings
        }

        // Call itself with depth 1 to prevent code repeation
        return this.getArraySiblingsByIndex(array, index, 1)

    },

    /**
     * onClickTheoryContent
     *
     * This function will be called when the user clicks on the content container.
     * It will call the toggleTheoryIndex with as forced direction 'close'. Since
     * we uses jQuery's removeClass function it will do nothing when class cannot be
     * removed.
     *
     */
    onClickTheoryContent() {

        // Trigger the close on theory index
        this.toggleTheoryIndex('close');
    },

    /**
     * toggleTheoryIndex
     *
     * This function will toggle the index in responsive view. By Default
     * it will just toggle, but a forced direction can be given to force the
     * index to open or close
     *
     * @param  {string} forcedDirection Can force the index to open or close
     */
    toggleTheoryIndex(forcedDirection) {

        // Get the content holder
        var contentHolder = this.$('.js-source-content-holder');

        // Get the index holder
        var indexHolder = this.$('.js-theory-branches-index');

        // Posible directions
        var directions = ['open', 'close'];

        // Check if there is a forces direction
        if (directions.indexOf(forcedDirection) !== -1) {

            // Switch/case the forcedDirection
            switch (forcedDirection) {

                // When the index should be open
                case 'open' :

                    // Add the open index class to the content holder
                    contentHolder.addClass(Styles['theory__content__open-index']);

                    // Add the open index class to the index holder
                    indexHolder.addClass(Styles['theory__index--open']);

                    // update label
                    this.$('.js-toggle-theory-index-label').text(
                        window.i18n.gettext('Close index')
                    );

                    break;

                // When the index should be closed
                case 'close' :

                    // Remove the open index class from the content holder
                    contentHolder.removeClass(Styles['theory__content__open-index']);

                    // Remove the open index class form the index holder
                    indexHolder.removeClass(Styles['theory__index--open']);

                    // update label
                    this.$('.js-toggle-theory-index-label').text(
                        window.i18n.gettext('Open index')
                    );

                    break;
            }

        // When no forced direction is given, toggle the content based on open index class
        } else {

            // When the content holder has the open index class
            if (contentHolder.hasClass(Styles['theory__content__open-index'])) {

                // Invoke itself, forcing the index to close to prevent code repeation
                this.toggleTheoryIndex('close');

            // Else the index is still closed
            } else {

                // Invoke itsel, forcing the index to open to prevent code repeation
                this.toggleTheoryIndex('open');
            }
        }
    },

    /**
     * loadSourcesByBranchIds
     *
     * This function will load sources by giving it an array of
     * branch ids
     *
     * @param  {Array} branchIds An array containing branchIds
     */
    loadSourcesByBranchIds(branchIds = []) {

        // Filter the branchids to remove already loaded sources
        branchIds = branchIds.filter((branchId) => {
            // Use find where to check if there are already sources loaded for this branch
            // to remove ids from list that al already loaded
            return branchId && this.theoryCollection.any({TheorySpyId: branchId}) === false
        })

        // Check if there are any branchIds given (they can be filtered out)
        if (branchIds.length > 0) {

            // Create an url safe id list joined by a ','
            var idsForInUrl = branchIds.join(',');
            // Set the URL for the theory collection
            this.theoryCollection.url = '/theory/get_sources_for_branches/' + idsForInUrl + '.json';

            // Get fetch the theory using above url
            this.theoryCollection.fetch({

                // Add newly found models to the collection
                add: true,

                // Do not remove any theory from collection
                remove: false,

                // Callback for when call is successful
                success: this.renderSources
            });
        } else {
            this.$el.trigger('sourcesRendered');
        }
    },

    // Create a holder for the index branches
    theoryBranches: [],

    /**
     * onLoadTheoryBranches
     *
     * This function is called when the index branches are loaded.
     *
     * @param  {Object} data Object with data passed from the backend
     */
    onLoadTheoryBranches(data) {

        // Make the theoryBranches global
        this.theoryBranches = data;

        // Rerender this view
        this.render();

        // Remove the spinner
        this.$('.js-spinner').remove();
    },

    // Holder for holding the active element
    activeTheoryPath: null,

    /**
     * scrollspy
     *
     * This is the scrollspy function and will be called when the user scrolls trough
     * the sources. It will calculate at which source the user is and highlights this
     * in the index
     *
     */
    scrollspy() {

        // load image when it is in viewport
        //
        // bacause the height of images is unknown before loading
        // the loading of the image will increase the height of the source
        // thereby changing the scroll position. To fix this, all images not within the viewport
        // yet have no `src` attribute (and nothing is loaded yet).
        // When the image comes into the viewport, it is loaded (by changing the data-src with the src
        // attribute)
        //
        // loop over all hidden images
        _.each(this.$('img[data-src]'), (hiddenImg) => {

            // check is image is in viewport
            if (this.isElementInViewport(hiddenImg)) {

                // load image by changing data-src with src
                $(hiddenImg).attr('src', $(hiddenImg).attr('data-src')).removeAttr('data-src');

                // animate the image height from 0 pixels to its normal height
                TweenMax.from($(hiddenImg), {
                    height: 0,
                    opacity: 0,
                    duration: 0.3,

                    // After animation make sure that the image height is set to initial.
                    // This prevents a bug where the height of the image is not inserted yet into the src attribute
                    // due to fast navigation through the textbook.
                    onComplete(image) {
                        $(image).css({height: 'initial'});
                    },
                    onCompleteParams: [hiddenImg]
                });
            }
        })

        // Create an offset the let the spy alarm earlier when element is almost at top
        var spyOffset = 10;

        // Create a list of elements which should be spied on
        var items = this.$('.js-source-content-container .js-source');

        // Get the active element, using the filter function
        var active = items.filter(function(index, item) {

            // When the position function is not undefined (so it exists)
            if ($(item).position !== undefined) {

                // Get the position from the top, we use position here instead of offset
                // since we need the space relative from the parent container instead of
                // relative to the body.
                //
                // Return true or false whether it is within the view including offset
                return ($(item).position().top <= spyOffset);

            }

            // Else it is imposible to determine position, remove it from the list
            return false

            // Revert list to array and pop the last element
        }).toArray().pop();

        // When active is undefined
        if (active === undefined) {

            // Pick the first element
            active = items[0];
        }

        // Get the path id from the spy id data
        const pathSpy = active.dataset.theoryPathSpyId

        // When the activeTheoryPath is not the same branch
        if (this.activeTheoryPath !== pathSpy) {

            // Load siblings for new branch
            // Create an array getting siblings by executing getArraySiblingsByIndex
            var selfAndSiblings = this.getSiblingsArray(pathSpy);

            // Get the sources for the clicked branchId
            this.loadSourcesByBranchIds(_.pluck(selfAndSiblings, 'id'));

            // Remove the active class from all the index branches
            this.$('.js-open-theory-branch').removeClass(Styles['index-level--sub--active']);

            // Get the secondary branch element
            var secondaryBranch = this

                // Get the active index branch based on scrollspy
                .$('.js-open-theory-branch[data-theory-branch-id="' + pathSpy + '"]')

                // Add the active class to it
                .addClass(Styles['index-level--sub--active']);

            // Get the holder element
            var branchHolder = secondaryBranch.closest('.js-theory-branch-holder');

            // Check if the state is closed
            if (branchHolder.data('theory-holder-state') === 'closed') {

                // Set the state to open
                branchHolder.data('theory-holder-state', 'open');

                // Add class to open the holder
                branchHolder.addClass(Styles['index-level--open']);
            }

            // Update the activeTheoryPath
            this.activeTheoryPath = pathSpy;
        }
    },

    /**
     * render
     *
     * This function will render this view. When the view is already
     * rendered it will just rerender it overwriting everything that was
     * initialized
     *
     */
    render() {

        // Create the theory element
        var newElement = $(Template({

            // Passing the style object with it
            Styles,

            // Passing an array of branches with it
            theoryBranches: this.theoryBranches
        }));

        // Replace (dummy) element with the new redered element
        this.$el.replaceWith(newElement);

        // Make sure Backbone recognizes this new element
        this.setElement(newElement);

        this.theoryCollection = new TheorySourcesCollection(null, {
            comparator(theoryItemA, theoryItemB) {
                // To sort the theory the right way, we need to sort on the lft as well as
                // the theory_sequence. The lft is coming from the nested-set-model which is
                // used in the backend. This depends the depth of the deepest path. Within this
                // deepest level we have a theory_sequence. So we need to sort on both attributes.

                // check if same branch
                if (theoryItemA.get('lft') === theoryItemB.get('lft')) {

                    // Normalize the theory types, when it's an empty string convert to null to make
                    // the match between item A's theory type equal to item B's theory type
                    if (theoryItemA.get('theory_type') === '') {
                        theoryItemA.set('theory_type', null)
                    }
                    if (theoryItemB.get('theory_type') === '') {
                        theoryItemB.set('theory_type', null)
                    }

                    // check if same theory type
                    if (theoryItemA.get('theory_type') === theoryItemB.get('theory_type')) {

                        // return orderinged based on theory sequence
                        return theoryItemA.get('theory_sequence') - theoryItemB.get('theory_sequence')
                    }

                    // return ordering based on theory_type
                    if (theoryItemA.get('theory_type') === 'standaard') {
                        return -1
                    }
                    return 1
                }
                // return ordering based on branch
                return theoryItemA.get('lft') - theoryItemB.get('lft')
            }
        })

        // load sources if Theory is initialized with preselected branch
        if (this.preselectedTheoryBranch !== undefined) {

            // Create an array getting siblings by executing getArraySiblingsByIndex
            var selfAndSiblings = this.getSiblingsArray(this.preselectedTheoryBranch);

            // Get the sources for the clicked branchId
            this.loadSourcesByBranchIds(_.pluck(selfAndSiblings, 'id'));
        }

        this.searchInput = this.addChildView(new QuickInput({
            type: 'search',
            placeholder: window.i18n.gettext('Search'),
            noMargin: true,
            inputCallback: (input) => {
                this.onChangeSearchField(input)
            },
            keepValueOnCallback: true,
            searchCallback: () => {}
        }), '.js-index-search')

        if (Backbone.Collection.theoryBooks.get(this.theoryCollectionId)?.get('disable_print') === 0
        && !Backbone.Model.user.get('is_demo')) {
            this.addChildView(
                new Button({
                    icon: 'print',
                    title: window.i18n.gettext('Print'),
                    theme: 'secondary',
                    callback: this.onClickPrint
                }),
                '.js-index-search'
            )
        }

        this.sourceContainer = this.$('.js-source-content-container');

        // Listen for scroll events in the sources container
        this.sourceContainer.on('scroll', this.scrollspy);

        // Show the preselected source in the reader
        this.openIndexAndScrollToSource();
    },

    /**
     * renderSources
     *
     * This function will be called when new sources are loaded. It will loop trough
     * all the loaded sources and merge same sources together. This merged source will
     * be added to their own collection. Where we're looping trough to render the
     * source for the user.
     *
     */
    renderSources() {

        // Sort theory collection before rendering
        this.theoryCollection.sort();

        // Get the array with secondaryBranches
        const secondaryBranches = this.getArrayOfSecondaryBranches()

        // Get the index of the scrollspy item so we can compare it to the current index
        const indexOfScrollSpy = _.findIndex(secondaryBranches, {id: this.activeTheoryPath});

        // Loop through the created mergedTheoryCollection
        this.theoryCollection.each((theoryModel) => {

            // Check if this merged source isn't already rendered. This will prevent this
            // source from being rendered multiple times
            if (!theoryModel.get('isRendered')) {

                // add a flag so that Source knows the source is shown
                // in the Theory View
                theoryModel.set('inTheory', true);

                // Create a new source view
                const sourceView = new Source({

                    // Passing the model with it
                    model: theoryModel,

                });

                // do not load images by default, to prevent weird scrolling behavior
                // the images are loaded when they are part of the viewport
                _.each(sourceView.$('img'), function(imgTag) {
                    $(imgTag).attr('data-src', $(imgTag).attr('src')).removeAttr('src');
                }, this);

                // Set spyid to default 0
                var spyId = 0;

                // When the path exists and is bigger than 1
                if (theoryModel.get('path') !== undefined && theoryModel.get('path').length > 1) {

                    // Set spy id to the path's secondary id (the lowest level we support in the index)
                    spyId = theoryModel.get('path')[1].id;
                }

                // Get the index of the current item so we can compare it to the scrollspy index
                var indexOfCurrentItem = _.findIndex(secondaryBranches, {id: spyId});

                // Get the index of the model so we can determine the next and previous
                var indexOfModel = this.theoryCollection.indexOf(theoryModel);

                // Store the scroll position before modifcations
                var oldScroll = this.sourceContainer.scrollTop();

                if (indexOfModel === 0) {
                    // Just prepend the source to show content to user
                    this.sourceContainer.prepend(sourceView.$el);

                } else {

                    // Get previous model
                    var previous = this.theoryCollection.at(indexOfModel - 1);

                    // Add this source after the previous one
                    previous.get('TheoryView').$el.after(sourceView.$el);

                }

                // Check if the scrollspy is active and if there is a next view available
                if (indexOfScrollSpy !== -1 &&
                    // Check if the index of current item is bigger than scrollspy
                    indexOfScrollSpy >= indexOfCurrentItem
                ) {
                    // Calcute the diff between previous height and new height now source is added
                    var addedSourceHeight = sourceView.$el.outerHeight(true);
                    // Keep scroll position based on:
                    // http://stackoverflow.com/a/21494434
                    this.sourceContainer.scrollTop(
                        oldScroll + addedSourceHeight
                    );
                }

                // Add js-source class to be able to select all sources
                sourceView.$el.addClass('js-source');

                sourceView.el.dataset.theoryCollectionId = theoryModel.get('theory_collection_id')
                sourceView.el.dataset.theoryBranchId = theoryModel.get('theory_branch_id')
                sourceView.el.dataset.theoryPathSpyId = spyId

                // Update the theoryModel to set the isRendered to true
                // Add the spy id to the model, so we can search for it later in the collection
                // Update the theorymodel to bind the create view to it
                theoryModel.set({
                    isRendered: true,
                    TheorySpyId: spyId,
                    TheoryView: sourceView,
                })
            }

        })

        // Trigger sources loaded so we can calculate the right position for a click
        this.$el.trigger('sourcesRendered');
    },

    /**
     * isElementInViewport
     *
     * description here
     *
     * @param  {DOMnode} el     DOM element to
     */
    // Found at: http://stackoverflow.com/a/7557433
    isElementInViewport(el) {

        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (
                window.innerHeight || document.documentElement.clientHeight
            ) &&
            // or $(window).height()
            rect.right <= (
                window.innerWidth || document.documentElement.clientWidth
            )
            // or $(window).width()
        );
    },

    /**
     * onClickPrint
     *
     * When clicking on the print button, open a modal where the user can select theory
     * content for print.
     */
    onClickPrint() {

        // Create modal with 2 buttons: Cancel and Print.
        var buttons = {};
        buttons[window.i18n.gettext('Cancel')] = {
            // Close modal when clicked
            callback: Backbone.View.Components.modal.close,
            theme: 'secondary',
        };
        buttons[window.i18n.gettext('Print')] = {
            callback() {
                // Use array of selected theory branch IDs. If array has a length of 0, display
                // warning message alerting the user that at least one branch has to be selected.
                // Otherwise, go to the URL of the print server with the array as parameter.
                var theorySelection = Backbone.View.Components.modal.subView.selectedTheory;
                if (theorySelection.length) {
                    window.open(`/theory/print/${Backbone.View.menubar.getGroup().id}/${theorySelection.toString()}`, '_blank')
                    window.statsTracker.trackEvent(
                        window.app.controller.activePath.join('/'),
                        'print theory',
                        `print theory with ${theorySelection.length} items`
                    )
                    Backbone.View.Components.modal.close();
                } else {
                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('Select at least 1 theory item to print'),
                        'warning'
                    );
                }
            },
            icon: 'print'
        };
        Backbone.View.Components.modal.open(SelectTheory, {
            title: window.i18n.gettext('Select theory for print'),
            buttons,
            parentView: this
        });

    },

    onDestroy() {
        $('body').removeClass('theory');
    }

});
