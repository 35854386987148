import Template from './Template29.hbs';
import WordSelectionStyles from 'views/components/taskGroups/tasks/wordSelection/WordSelection.scss';
import FragmentTemplate from 'views/components/taskGroups/tasks/wordSelection/Fragment.hbs';

export default BaseView.extend({

    events: {
        'click .js-fragment': 'selectFragment'
    },

    initialize(options) {
        _.bindAll(
            this,
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;
        this.isPresentation = options.isPresentation;

        // Array of text fragments, each fragment being an object that looks something like this:
        // {
        //  text: "abc", // The actual content of the fragment.
        //  select: true // If this fragment is the correct answer.
        // }
        this.fragments = this.model.get('task_info_json').text;
        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {
        // Collection with the answer of the current user.
        this.JSONAnswer = (this.taskView.response.get('json_answer') || []).map(Number);

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles: WordSelectionStyles
        }));

        // Create fragment element for each fragment.
        // Add an highlight if it already has been selected.
        _.each(this.fragments, (fragment, index) => {

            // If fragment is only a line break, append br tag instead of fragment.
            if (fragment.text === '\n') {

                this.$el.append('<br>');

            } else {

                // TODO convert existing tasks of type 29 to split linebreaks and text into
                // seperate fragments.

                // If fragment starts with a linebreak append a <br> tag befor the fragment.
                if (/^\n/.test(fragment.text)) {
                    this.$el.append('<br>');
                }

                this.$el.append(FragmentTemplate({
                    Styles: WordSelectionStyles,
                    fragment: fragment.text,
                    fragmentIndex: index,
                    fragmentStatus: (
                        _.contains(this.JSONAnswer, index) ? WordSelectionStyles['fragment--selected'] : undefined
                    )
                }));

                // If fragment ends with a linebreak append a <br> tag after the fragment.
                if (/\n$/.test(fragment.text)) {
                    this.$el.append('<br>');
                }

            }

        })
    },

    /**
     * selectFragment
     *
     * When clicking on a fragment element react by adding/removing the selection status from the
     * response and toggling the correct styling.
     *
     * @param  {Event} e click event
     */
    selectFragment(e) {
        this.stopAllEvents(e);
        // Refuse interaction when showing answers.
        if (!this.$el.hasClass('showAnswers')) {
            var sentenceFragmentElement = $(e.currentTarget);
            // Get index of the selected fragment in the list of all fragments.
            var fragmentIndex = parseInt(sentenceFragmentElement.data('fragment-index'));
            // Check if aformentioned index appears in the response. If yes, remove this value from
            // the response and remove the selection styling. Otherwise add the index to the
            // response and apply the selection styling.
            this.JSONAnswer = _.clone(this.JSONAnswer);
            if (_.contains(this.JSONAnswer, fragmentIndex)) {
                this.JSONAnswer = _.without(this.JSONAnswer, fragmentIndex);
                sentenceFragmentElement.removeClass(WordSelectionStyles['fragment--selected']);
            } else {
                this.JSONAnswer.push(fragmentIndex);
                sentenceFragmentElement.addClass(WordSelectionStyles['fragment--selected']);
            }

            // When the user is not in a presentation we save the answer.
            if (!this.isPresentation) {
                this.saveAnswer();
            }
        }
    },

    /**
     * saveAnswer
     *
     * Convert this.JSONAnswer to a simple object and pass it to the saveResponse method of the parent taskView
     * to patch the answer to the server.
     */
    saveAnswer() {
        this.taskView.saveResponse(this.JSONAnswer);
    },

    sendPresentationAnswer() {
        this.lockAnswer();
        this.taskView.saveResponse(this.JSONAnswer);
    },

    /**
     * showAnswer
     *
     * Show the correct answer to the task.
     */
    showAnswer() {

        _.each(this.$('.js-fragment'), (fragmentElement) => {

            fragmentElement = $(fragmentElement);

            var index = parseInt(fragmentElement.data('fragment-index'));

            fragmentElement.addClass(WordSelectionStyles['fragment--show-answer']);

            if (
                this.fragments[index].select &&
                _.contains(this.JSONAnswer, index)
            ) {
                fragmentElement.addClass(WordSelectionStyles['fragment--correct']);
            } else if (
                this.fragments[index].select &&
                !_.contains(this.JSONAnswer, index)
            ) {
                if (APPLICATION === 'author') {
                    fragmentElement.addClass(WordSelectionStyles['fragment--selected']);
                } else {
                    fragmentElement.addClass(WordSelectionStyles['fragment--missing-answer']);
                }
            } else if (!this.fragments[index].select &&
                _.contains(this.JSONAnswer, index)
            ) {
                fragmentElement.addClass(WordSelectionStyles['fragment--incorrect']);
            }

        })

    },

    showAuthorAnswer() {
        this.showAnswer()
    },

    /**
     * hideAnswer
     *
     * Hide the correct answer to the task.
     */
    hideAnswer() {
        this.$('.js-fragment').removeClass(
            WordSelectionStyles['fragment--show-answer'] + ' ' +
            WordSelectionStyles['fragment--correct'] + ' ' +
            WordSelectionStyles['fragment--missing-answer'] + ' ' +
            WordSelectionStyles['fragment--incorrect']
        );
    },

    /**
     * getCorrectAnswer
     *
     * Generate summary of the correct answer to use above the list of student answers.
     *
     * @return {string} HTML blob to be inserted above the list of student answers.
     */
    getCorrectAnswer() {
        return _.reduce(this.fragments, (answerString, fragment) => {
            if (fragment.select) {
                return answerString + FragmentTemplate({
                    Styles: WordSelectionStyles,
                    fragment: fragment.text,
                    fragmentStatus: WordSelectionStyles['fragment--answer-summary'] + ' ' +
                        WordSelectionStyles['fragment--show-answer']
                });
            }
            return answerString + fragment.text + ' '
        }, '');
    },

    // (TEACHER ONLY) Create HTML blob containing the answer of each student
    /**
     * getStudentAnswer
     *
     * Generate summary of the answer student has given for use in the list of student answers.
     *
     * @param  {Object} responseModel Student response data
     * @return {string}               HTML blob to be used for the student answer view.
     */
    getStudentAnswer(responseModel) {

        var answerString = '';
        var JSONAnswer = responseModel.get('json_answer');

        if (JSONAnswer) {
            JSONAnswer = JSONAnswer.map(Number);
            _.each(this.fragments, (fragment, index) => {

                var fragmentStatus = (
                    WordSelectionStyles['fragment--answer-summary'] + ' ' +
                    WordSelectionStyles['fragment--show-answer'] + ' '
                );
                if (
                    fragment.select &&
                    _.contains(JSONAnswer, index)
                ) {
                    fragmentStatus += WordSelectionStyles['fragment--correct'];
                } else if (
                    fragment.select &&
                    !_.contains(JSONAnswer, index)
                ) {
                    fragmentStatus += WordSelectionStyles['fragment--missing-answer'];
                } else if (!fragment.select &&
                    _.contains(JSONAnswer, index)
                ) {
                    fragmentStatus += WordSelectionStyles['fragment--incorrect'];
                }

                answerString += FragmentTemplate({
                    Styles: WordSelectionStyles,
                    fragment: fragment.text,
                    fragmentStatus
                });
            });
        }

        return answerString;
    },

    /**
     * lockAnswer
     *
     * Triggered when the lock-answers event is sent from a Presentation view.
     * It will make sure students can't change their answer. This is typically
     * done when the taskState inside the presentation is 1 or 2, meaning the
     * teacher is showing the student's answers.
     */
    lockAnswer() {

        // Stop listening to the backbone events in this view
        this.undelegateEvents();

        for (const fragment of this.el.querySelectorAll('.js-fragment')) {
            fragment.classList.add(WordSelectionStyles['fragment--locked'])
        }

    },

    /**
     * unlockAnswer
     *
     * Triggered when the unlock-answers event is sent from a Presentation view.
     * It will make sure students chan fill an answer again.
     */
    unlockAnswer() {

        // Listen to backbone events again
        this.delegateEvents();

        for (const fragment of this.el.querySelectorAll('.js-fragment')) {
            fragment.classList.remove(WordSelectionStyles['fragment--locked'])
        }

    }

});
