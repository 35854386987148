var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"flashcard") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SharedStyles") : depth0)) != null ? lookupProperty(stack1,"question") : stack1), depth0))
    + "\">\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"card") : stack1), depth0))
    + " js-card\">\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"item") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"item--question") : stack1), depth0))
    + "\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"flashcard__instruction") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SharedStyles") : depth0)) != null ? lookupProperty(stack1,"question__instruction") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"questionLabel") : depth0), depth0))
    + " "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"two-rotating-arrows",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":116},"end":{"line":6,"column":149}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"item__content") : stack1), depth0))
    + "\">\n                <p class=\"js-question\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"questionValue") : depth0), depth0))
    + "</p>\n            </div>\n        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"item") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"item--answer") : stack1), depth0))
    + "\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"flashcard__instruction") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SharedStyles") : depth0)) != null ? lookupProperty(stack1,"question__instruction") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"answerLabel") : depth0), depth0))
    + " "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"two-rotating-arrows",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":13,"column":114},"end":{"line":13,"column":147}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"item__content") : stack1), depth0))
    + "\">\n                <p class=\"js-answer\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"answerValue") : depth0), depth0))
    + "</p>\n            </div>\n        </div>\n\n    </div>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"navigation") : stack1), depth0))
    + "\">\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"previous") : stack1), depth0))
    + " js-previous-card\" disabled>"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-back",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":22,"column":70},"end":{"line":22,"column":94}}})) != null ? stack1 : "")
    + "</button>\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"next") : stack1), depth0))
    + " js-next-card\" disabled>"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-forward",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":23,"column":62},"end":{"line":23,"column":89}}})) != null ? stack1 : "")
    + "</button>\n    </div>\n\n</div>\n";
},"useData":true});