import Template from './Orderedlist.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Orderedlist extends Button {

    // Built-in TinyMCE command for inserting ordered lists
    command = 'InsertOrderedList'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Numbered list');
    }

}
