var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"in-sidebar") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + " js-ref-button\">\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"bookmark",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":34}}})) != null ? stack1 : "")
    + "\n            "
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Go to content",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":34}}}))
    + "\n        </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + " js-alter-annotatation-button\">\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"pencil",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":32}}})) != null ? stack1 : "")
    + "\n            "
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Edit",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":25}}}))
    + "\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"annotation-item") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isSidebar") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":84}}})) != null ? stack1 : "")
    + "\">\n\n    <section class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"navigation") : stack1), depth0))
    + " no-print\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isSidebar") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    </section>\n\n    <section class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"content") : stack1), depth0))
    + " js-note-content\">\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"noteContent") : stack1), depth0)) != null ? stack1 : "")
    + "\n    </section>\n\n    <section class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"about") : stack1), depth0))
    + " no-print\">\n        <div class=\"js-annotation-path\"></div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"author") : stack1), depth0))
    + "\">\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"authorName") : depth0), depth0))
    + "\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"dateAdded") : stack1), depth0))
    + "\n        </div>\n    </section>\n</div>";
},"useData":true});