import Styles from './Summary.scss';

import Template from './Summary.hbs';
import SubjectsCollection from 'collections/SubjectsCollection'
import SubjectProgressLabel from 'views/components/subjectProgressLabel/SubjectProgressLabel'
import Button from 'views/components/button/Button'

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onLoadSubjectsCollection',
        );

        this.work_on = options.work_on;

        this.setElement(Template({
            Styles,
            options,
            numTasks: this.model.tasks.length,
            numTaskGroups: this.model.task_groups.length,
            pluralTaskGroups: window.i18n.ngettext('task group', 'task groups', this.model.task_groups.length),
            pluralSources: window.i18n.ngettext('source', 'sources', this.model.task_groups.length),
            activityName: this.model.get('name'),
            deadline: this.model.deadline.getLabel()
        }));

        // Check if we can show a background image
        // const hasBackgroundImage = !!this.model.getBackgroundImage()
        // const hasBackgroundImage = false

        // Add either a start or end button depending on the location of this activity summary.
        if (options.showStartBtn) {
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Begin'),
                    callback: this.work_on.navigationBar.nextItem,
                    size: 'medium',
                    theme: 'secondary',
                    iconRight: 'arrow-right',
                }),
                this.$('.js-start-finish-btn')
            );
        } else if (options.showFinishBtn) {

            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Finish'),
                    size: 'medium',
                    iconRight: 'arrow-right',
                    callback: () => {
                        Backbone.history.navigate(
                            `/sections/show/${this.model.get('section_id')}`,
                            {trigger: true}
                        )}
                }),
                this.$('.js-start-finish-btn')
            );

        }

        if (ISMOBILE) {
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Jump to task group'),
                    theme: 'transparent',
                    callback: this.work_on.showSidebarActivityNavigation,
                    iconRight: 'arrow-right',
                }),
                this.$('.js-jump-to-task-group')
            )
        }

        // Subjects are only relevant when there are tasks
        if (this.model.tasks.length > 0) {
            const subjectsCollection = new SubjectsCollection()
            subjectsCollection.url = `/activities/get_subjects_status/${this.model.id}.json`
            subjectsCollection.fetch({
                success: this.onLoadSubjectsCollection,
                error: (collection, response) => {
                    response.failSilently = true
                }
            })
        }

    },

    onLoadSubjectsCollection(subjectsCollection) {
        subjectsCollection.each((model) => {
            this.addChildView(new SubjectProgressLabel({
                model
            }), '.js-subjects')
        })
    },

    /**
    * onInView
    *
    * Fade in and slide up the whole view when transition animation
    * called by work_on is finised to ensure a smooth transition.
    */
    onInView() {
        TweenMax.to(this.$('> div'), {
            duration: 0.3,
            // Set opacity to 1
            opacity: 1,
            // Set y axis to 0
            y: 0
        });
    },

})
