export default class PastedImageUploader {

    // parseHTML: if true, do not only scan for image files, but also scan for img tags in pasted HTML
    constructor(uploadModel, parseHTML = false) {

        _.bindAll(
            this,
            'onPaste',
        )

        this.uploadModel = uploadModel
        this.parseHTML = parseHTML

    }

    /**
     * Check paste event for image binary or image url. If an image
     * is found, upload and insert the image directly in the editor
     *
     * @param {ClipboardEvent} e a ClipboardEvent
     *
     */
    onPaste(e) {

        const items = e.clipboardData.items
        if (!items) {
            return
        }

        // Scan for type 'image/*'
        // This can be present when copying from filesystem or when copying an individual
        // image from an internet page
        for (const item of items) {
            // If we find an image, try to upload it
            if (item.kind === 'file' && item.type.startsWith('image/')) {
                e.preventDefault()
                this.processPastedBinaryImage(item.getAsFile())
                return
            }
        }

        if (!this.parseHTML) {
            return
        }
        // Then scan for type 'text/html'
        // This can be present when copying from Google Docs, Microsoft 365, Apple Pages
        // or selected text on an internet page
        let hasFoundImage = false
        for (const item of items) {
            if (item.type === 'text/html') {

                // Get the HTML string
                item.getAsString((pastedHTML) => {

                    const location = this.extractImageLocation(pastedHTML)

                    // If we found a URL, try to get the contents and upload it
                    if (location?.startsWith('http')) {
                        this.uploadModel.sendImageFromInternetToUploadServer(location)
                        hasFoundImage = true
                        return
                    }

                    // If we found a base64 encoded image, try to process and upload it
                    if (location?.startsWith('data:image/')) {
                        this.uploadModel.sendBase64ImageToUploadServer(location)
                        hasFoundImage = true
                        return
                    }
                })
            }
            if (hasFoundImage) {
                return
            }
        }
    }

    /**
     * Upload and insert the image blob
     *
     * @param {Blob} imageBlob Pasted image's blob
     */
    processPastedBinaryImage(imageBlob) {
        this.uploadModel.submitFile.call(this.uploadModel, imageBlob)
    }

    /**
     * Search for the src attribute of the image tag and capture the location
     * of that attribute
     *
     * @param {string} pastedText Text containing image tag
     * @returns {null | string} The found image location
     */
    extractImageLocation(pastedText) {
        /**
         * \s           any whitespace character
         * .*?          zero to unlimited times characters non-greedy/lazy
         * src="(.+?)"  the group we're interested in : any character until
         *              the closing double quote is found (non-greedy/lazy)
         */
        const imageLocationRegex = /<img\s.*?src="(.+?)"/
        const match = imageLocationRegex.exec(pastedText)

        return match && match[1]
    }

}
