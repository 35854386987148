var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"variant") : stack1), depth0))
    + " js-variant\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"number") : depth0), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"variant__index") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"number") : depth0), depth0))
    + "</div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"variant__content") : stack1), depth0))
    + " js-content\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"answer") : depth0), depth0))
    + "</div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"next-button") : stack1), depth0))
    + " js-next-button\">"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"arrow-forward",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":82}}})) != null ? stack1 : "")
    + "</div>\n</div>\n";
},"useData":true});