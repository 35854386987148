import SourcesCollection from 'collections/SourcesCollection'
import TheoryYearTemplate from 'views/components/taskGroups/sources/source12/templates/elements/readerYear.hbs'
import TheoryTitleTemplate from 'views/components/taskGroups/sources/source12/templates/elements/readerTitle.hbs'

export default class TheorySourcesCollection extends SourcesCollection {

    parse(responseArray) {
        return TheorySourcesCollection.mergeSources(responseArray, this.toJSON())
    }

    static mergeSources(responseArray, mergedSourcesArray = []) {
        if (!Array.isArray(responseArray)) {
            window.sentry.withScope(scope => {
                scope.setExtra('response', responseArray)
                scope.setExtra('url', this.url)
                window.sentry.captureException(new Error('Malformed TheorySourcesCollection response'))
            })
            return mergedSourcesArray
        }
        let lastYearId = 0
        let lastHandbookType = 'standaard'
        let searchFromIndex = 0
        for (const currentSource of responseArray) {
            if (currentSource.element_type === 'task') {
                continue
            }

            const lastMergedSourceModelIndex = _.findLastIndex(mergedSourcesArray, (mergedSourceObject, index) => {
                // Do not consider merging current source if it before the search offset, not a text source (type 12) or
                // has a path of a length of zero (it does not belong to a learning text collection).
                if (index < searchFromIndex || currentSource.template_id !== 12 || _.size(currentSource.path) === 0) {
                    return false
                }
                const pathA = mergedSourceObject.path.slice(0, 2)
                const pathB = currentSource.path.slice(0, 2)
                return _.isEqual(pathA, pathB)
            })

            let lastMergedSourceObject
            let currentTemplate
            let lastTemplate
            if (lastMergedSourceModelIndex > -1) {
                lastMergedSourceObject = mergedSourcesArray[lastMergedSourceModelIndex]
                lastTemplate = lastMergedSourceObject.source?.options?.template || 'default'
                currentTemplate = currentSource?.options?.template || 'default'
            }

            if (
                lastMergedSourceObject &&
                currentTemplate === lastTemplate && currentTemplate === 'default'
            ) {
                // Join current search with prior source with the same path.
                const source = lastMergedSourceObject.source
                const currentYear = currentSource.path[2] || { id: 0 }

                if (lastYearId !== currentYear.id || lastHandbookType !== currentSource.theory_type) {
                    source.text += TheoryYearTemplate({
                        year: currentYear.name,
                        isPlus: currentSource.theory_type === 'plus',
                        isExtra: currentSource.theory_type === 'extra',
                    })
                    lastYearId = currentYear.id
                    lastHandbookType = currentSource.theory_type
                }

                // Append title and text of source to last model's text.
                const sourceTitle = currentSource.title && currentSource.title.trim() || '&nbsp;'
                source.text += TheoryTitleTemplate({
                    title: sourceTitle,
                    id: currentSource.id,
                })
                source.text += currentSource.source.text

                // Create list of source IDs that make up this merged source.
                lastMergedSourceObject.mergedSourceIds.push(currentSource.id)

            } else {

                currentSource.mergedSourceIds = [currentSource.id]

                // If the current source does not have a proper theory path, this is a discontinuity between sources
                // that would otherwise be merged. To ensure that sources won't be merged, only search for sources to
                // merge with from this index onwards.
                if (_.size(currentSource.path) === 0) {
                    searchFromIndex = mergedSourcesArray.length
                }

                mergedSourcesArray.push(currentSource)

                lastYearId = Array.isArray(currentSource.path) ? currentSource.path[2]?.id : 0

                lastHandbookType = currentSource.theory_type

            }

        }
        return mergedSourcesArray
    }

}
