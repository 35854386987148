var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"index-level") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"index-level--head") : stack1), depth0))
    + " js-theory-branch-holder\" data-theory-holder-state=\"closed\">\n                <div class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"index-level__title") : stack1), depth0))
    + " js-open-theory-branch\" data-theory-branch-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-type=\"primary\">\n                    <span class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"title__sequence") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/add.js")).call(alias3,(data && lookupProperty(data,"index")),1,{"name":"add","hash":{},"data":data,"loc":{"start":{"line":18,"column":66},"end":{"line":18,"column":82}}}))
    + "</span>\n                    <span class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"title__label") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                    <span class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"title__icon") : stack1), depth0))
    + "\">\n                        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":21,"column":48}}})) != null ? stack1 : "")
    + "\n                    </span>\n                </div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"index-level__sublevels") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"children") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":30,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\""
    + alias2(alias1(((stack1 = (depths[2] != null ? lookupProperty(depths[2],"Styles") : depths[2])) != null ? lookupProperty(stack1,"index-level") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depths[2] != null ? lookupProperty(depths[2],"Styles") : depths[2])) != null ? lookupProperty(stack1,"index-level--sub") : stack1), depth0))
    + " js-open-theory-branch\" data-theory-branch-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-type=\"secondary\">\n                        <span class=\""
    + alias2(alias1(((stack1 = (depths[2] != null ? lookupProperty(depths[2],"Styles") : depths[2])) != null ? lookupProperty(stack1,"title__sequence") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/add.js")).call(alias3,(container.data(data, 1) && lookupProperty(container.data(data, 1),"index")),1,{"name":"add","hash":{},"data":data,"loc":{"start":{"line":27,"column":73},"end":{"line":27,"column":92}}}))
    + "."
    + alias2(__default(require("../../../../util/handlebarsHelpers/add.js")).call(alias3,(data && lookupProperty(data,"index")),1,{"name":"add","hash":{},"data":data,"loc":{"start":{"line":27,"column":93},"end":{"line":27,"column":109}}}))
    + "</span>\n                        <span class=\""
    + alias2(alias1(((stack1 = (depths[2] != null ? lookupProperty(depths[2],"Styles") : depths[2])) != null ? lookupProperty(stack1,"title__label") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"theory") : stack1), depth0))
    + " theory-reader\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"theory__index") : stack1), depth0))
    + " js-theory-branches-index\">\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"index__open-index-button") : stack1), depth0))
    + " js-toggle-theory-index\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"index__open-index-button--icon") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"menu",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":34}}})) != null ? stack1 : "")
    + "\n            </div>\n            <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"index__open-index-button--label") : stack1), depth0))
    + " js-toggle-theory-index-label\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Open index",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":98},"end":{"line":8,"column":117}}}))
    + "</span>\n        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"index__search") : stack1), depth0))
    + " js-index-search\"></div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"index__searchholder") : stack1), depth0))
    + " js-search-results-holder\"></div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"index__levels") : stack1), depth0))
    + " js-levels\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"theoryBranches") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":33,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"theory__content") : stack1), depth0))
    + " js-source-content-holder\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"theory__container") : stack1), depth0))
    + " js-source-content-container\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"theory__container__inner") : stack1), depth0))
    + "\"></div>\n        </div>\n    </div>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"theory__spinner") : stack1), depth0))
    + " js-spinner\">\n        <svg viewBox=\"25 25 50 50\">\n          <circle class=\"path\" cx=\"50\" cy=\"50\" r=\"20\" fill=\"none\" stroke-width=\"5\" stroke-miterlimit=\"10\"/>\n        </svg>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});