var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<details class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"help-container") : stack1), depth0))
    + "\">\n    <summary>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Need more help?",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":37}}}))
    + "</summary>\n    <div>\n        <h4>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"For students",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":33}}}))
    + "</h4>\n        <p>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Ask your teacher to reset your password as follows:",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":71}}}))
    + "</p>\n        <ul>\n            <li>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Log in as teacher and navigate to Settings",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":67}}}))
    + "</li>\n            <li>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Find the group of the student",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":54}}}))
    + "</li>\n            <li>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Go to the student and click Change password",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":68}}}))
    + "</li>\n        </ul>\n\n        <h4>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"For teachers",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":33}}}))
    + "</h4>\n        <p>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"To change your password please call Learnbeat at +31 20 700 98 54.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":13,"column":11},"end":{"line":13,"column":86}}}))
    + "</p>\n    </div>\n</details>\n";
},"useData":true});