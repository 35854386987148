import Styles from './Template33.scss';

import Template from './Template33.hbs';
import TextSelector from 'views/components/taskGroups/tasks/template33/textSelector/TextSelector';
import AnswerTemplate from 'views/components/taskGroups/tasks/template33/Answer.hbs';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'getCorrectAnswer',
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;

        this.savedFragments = this.model.get('task_info_json').text;

        // Convert all entries to Number type values if they are not already.
        var JSONAnswer = (this.taskView.response.get('json_answer') || []).map(Number);

        // Create the view, passing the styling with it
        this.setElement(Template({}));

        this.textSelector = this.addChildView(new TextSelector({
            JSONAnswer,
            savedFragments: this.savedFragments,
            selectionMode: this.model.get('task_info_json').mode
        }), this.$el);

        // Show existing selections.
        this.textSelector.showSelection(_.map(JSONAnswer, (fragmentID) => {
            return {
                id: fragmentID,
                select: true
            };
        }));

        if (APPLICATION === 'webapp') {
            // Setup selection change listener if selection gets changed.
            this.listenTo(this.textSelector, 'JSONAnswerChanged', this.saveAnswer);

            // Scroll to top of task when model answer is viewed for the correct
            // reading position.
            this.on('onShowModelAnswer', () => {
                window.scrollTo(0, this.$el.offset().top);
            })
        }

    },

    /**
         * showAnswer
         *
         * Show the correct answer to the task.
         */
    showAnswer() {
        // Color correct/incorrect/missing fragments.
        this.textSelector.showAnswer()
    },

    showAuthorAnswer() {
        // Color correct answers in selection color.
        this.textSelector.showSelection(_.where(this.savedFragments, {
            select: true
        }))
    },

    /**
         * hideAnswer
         *
         * Hide the correct answer to the task.
         */
    hideAnswer() {
        this.textSelector.hideAnswer();
    },

    /**
         * createSummary
         *
         * Create DOM string representation of summarized version of the answer to the task.
         *
         * SELECT_WORD
         * When the selection mode is words (SELECT_WORD), this method gathers the 2 neighbouring
         * words of a selected words to give the user some context on where the words are in the
         * complete text.
         * For example, this text…
         * "This was a [triumph!] I'm making a [note] here: Huge success! It's hard to overstate my [satisfaction.]"
         * …becomes…
         * "…was a [triumph!] I'm making a [note] here: Huge…",
         * "…overstate my [satisfaction.]"
         * Note that the words "triumph!" and "note" are in the same group because the 2 words
         * after "triumph!" overlap with the first 2 words before "note".
         *
         * SELECT_SENTENCE/SELECT_PARAGRAPH
         * When the selection mode is something other than SELECT_WORD, like SELECT_SENTENCE or
         * SELECT_PARAGRAPH, truncate only the fragment itself into a version that will fit
         * easier into the users screen when it is a very long sentence or any paragraph. It
         * will only show the first 3 words and last 3 words of the sentence or paragraph.
         * For example, these sentences…
         * "[This was a triumph! ][I'm making a note here: Huge success! ]It's hard to overstate my satisfaction."
         * …becomes…
         * "[This was a triumph!]",
         * "[I'm making a… …here: Huge success!]"
         *
         * @param {Array|undefined}   responseModel     If student response is passed, also add these to the summary.
         * @return {string}  DOM string
         */
    createSummary(responseModel) {

        // Only look at selectable fragments. This excludes things like lone <p>,<br> and <img> tags.
        var fragments = _.filter(this.savedFragments, _.isObject);

        var truncatedFragmentGroups = [];

        const useResponseModel = responseModel && responseModel.length

        // Each correct fragment is surrounded by 2 neighbouring fragments if selectionMode is words.
        if (this.textSelector.selectionMode === this.textSelector.SELECT_WORD) {

            // Gather all neighbouring groups.
            _.each(fragments, (fragment, index) => {
                // If fragment is supposed to be selected.
                if (
                    fragment.select ||
                        (useResponseModel && responseModel.indexOf(fragment.id) > -1)
                ) {
                    // Get 2 fragments before the current index, the current fragment and the 2 fragments
                    // that follow.
                    truncatedFragmentGroups.push(fragments.slice(
                        // If index - 2 < 0, slice from index 0 instead.
                        Math.max(0, index - 2),
                        // Index + 3 (exclusive)
                        index + 3
                    ));
                }
            });

            // Merge overlapping groups by checking for IDs that are present in both group i and
            // group i + 1. Then unify these groups in group i + 1 and get rid of group i. Then continue
            // from i to account for the new length of the array.
            for (var i = 0; i < truncatedFragmentGroups.length; i++) {
                if (_.intersection(
                    truncatedFragmentGroups[i],
                    truncatedFragmentGroups[i + 1]
                ).length) {
                    truncatedFragmentGroups[i + 1] = _.union(
                        truncatedFragmentGroups[i],
                        truncatedFragmentGroups[i + 1]
                    );
                    truncatedFragmentGroups.splice(i, 1);
                    i--;
                }
            }

        } else {

            // If selection mode is SELECT_SENTENCE or SELECT_PARAGRAPH, get only the fragments that
            // are supposed to be selected and truncate those.
            _.each(fragments, (fragment) => {

                if (
                    fragment.select ||
                        (useResponseModel && responseModel.indexOf(fragment.id) > -1)
                ) {
                    // Split str by groups of whitespace and block html tags and remove empty entries.
                    const splitFragmentText = fragment.t.split(
                        // \s+                      one or more spaces
                        // <\/?                     opening or closing tag
                        // (?:p|h\d|blockquote|li|ul|ol|aside|img) block element types
                        // [\w\s=".':;#\-\/\?]*?    zero or more of any kind of character valid as HTML attribute
                        // >
                        /\s+|<\/?(?:p|h\d|blockquote|li|ul|ol|aside|img)[\w\s=".':;#\-\/\?]*?>/ig
                    ).filter(Boolean)

                    // If fragments has more than 6 words, truncate the fragment by inserting an …
                    // after the first 3 words and before the last 3 words of the fragment.
                    // Clone the object item to prevent the source text itself from being changed by
                    // this truncation operation.
                    if (splitFragmentText.length > 6) {
                        var leftStr = _.first(splitFragmentText, 3).join(' ') + '… '
                        var rightStr = ' …' + _.last(splitFragmentText, 3).join(' ')
                        fragment = _.clone(fragment)
                        fragment.t = leftStr + rightStr
                    }

                    // Return a truncatedFragmentGroup with only one entry, the fragment itself.
                    truncatedFragmentGroups.push([fragment])
                }

            })

        }

        // Create a DOM representation for each fragment group using AnswerTemplate.
        // For each selected fragment add styling to show this is the correct answer.
        return _.map(truncatedFragmentGroups, (truncatedFragmentGroup) => {

            // Create empty AnswerTemplate element.
            var truncatedFragmentGroupElement = $(AnswerTemplate({
                Styles
            }));

            // Create parsed text version of truncatedFragmentGroup content and append it to
            // the element.
            truncatedFragmentGroupElement.append(this.textSelector.createContent(truncatedFragmentGroup));

            // Add … to the start and end of a word group.
            if (this.textSelector.selectionMode === this.textSelector.SELECT_WORD) {
                truncatedFragmentGroupElement.append('…');
                truncatedFragmentGroupElement.prepend('…');
            }

            // Add styling to all correct fragments.
            _.each(truncatedFragmentGroup, (fragment) => {
                if (fragment.select) {
                    truncatedFragmentGroupElement.find('[data-id=' + fragment.id + ']').addClass(
                        Styles.answer__correct
                    );
                }
            });

            return truncatedFragmentGroupElement.prop('outerHTML');

        }).join('')

    },

    /**
         * getCorrectAnswer
         *
         * Generate summary of the correct answer to use above the list of student answers.
         *
         * @return {string} HTML blob to be inserted above the list of student answers.
         */
    getCorrectAnswer() {
        return this.createSummary();
    },

    /**
         * getStudentAnswer
         *
         * Generate summary of the answer student has given for use in the list of student answers.
         *
         * @param  {Object} responseModel Student response data
         * @return {string}               HTML blob to be used for the student answer view.
         */
    getStudentAnswer(responseModel) {

        // Convert all entries to Number type values if they are not already.
        responseModel = (responseModel.get('json_answer') || []).map(Number);

        // Return empty string if no answer has been given.
        if (responseModel.length === 0) {
            return '';
        }

        // Mark the student answer as correct/incorrect/missing using the same
        // code as showAnswer, only using answerElement as context
        // instead of this.textSelector.$el and with responseModel instead of
        // the current user's JSONAnswer.
        const answerElement = $('<div>' + this.createSummary(responseModel) + '</div>')
        this.textSelector.showAnswer(true, answerElement, responseModel);

        return answerElement.html();

    },

    /**
         * saveAnswer
         *
         * Convert this.JSONAnswer to a simple object and pass it to the saveResponse method of the parent taskView
         * to patch the answer to the server.
         */
    saveAnswer() {
        this.taskView.saveResponse(this.textSelector.JSONAnswer);
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {
        _.invoke(this.childViews, 'undelegateEvents')

        this.textSelector.setSelectionLock(true)
    },

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {
        _.invoke(this.childViews, 'delegateEvents')

        this.textSelector.setSelectionLock(false)
    }

});
