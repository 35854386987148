import Styles from './VideoTour.scss';

import Template from './VideoTour.hbs';
import ContentList from 'views/components/taskGroups/selectContent/contentList/ContentList';
import TaskGroupModel from 'models/TaskGroupModel';
import Source13View from 'views/components/taskGroups/sources/source13/Source13';

export default BaseView.extend({

    initialize() {

        _.bindAll(
            this,
            'insertVideo',
            'createContentList'
        );

        this.setElement(Template({
            Styles
        }));

        // Get the Taskgroup with demo videos
        // For the Noordhoff demo user, there is a separate Taskgroup for demo videos
        let taskGroupId = 561424
        if (Backbone.Model.user.id === 913388) {
            taskGroupId = 2486968
        }
        this.taskGroupModel = new TaskGroupModel({ id: taskGroupId });

        this.taskGroupModel.fetch({
            success: this.createContentList
        });

    },
    createContentList() {
        // Create list of videos
        this.searchResultListView = this.addChildView(new ContentList({
            collection: this.taskGroupModel.elements,
            selectDefault: true,
            callback: this.insertVideo
        }), '.js-content-list');

        this.searchResultListView.createList();
    },

    insertVideo(sourceID) {
        // If a instance of the source exists, destroy it.
        if (this.videoView) {
            this.unregisterAndDestroyChildView(this.videoView);
        }

        const sourceModel = this.taskGroupModel.elements.findWhere({id: sourceID});

        this.videoView = this.addChildView(
            // Child of the type source13
            new Source13View({

                // Pass on the created model
                model: sourceModel,

                isVideoActivity: true,
                isDemo: true
            }), '.js-preview');

        window.statsTracker.trackEvent(
            window.app.controller.activePath.join('/'),
            'Demo video clicked',
            'Source ID: ' + sourceID
        )
    }

});
