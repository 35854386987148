import Styles from './Source3.scss';

import Template from './Source3.hbs';
// Download file source view
export default BaseView.extend({

    initialize() {
        const fileId = this.model.get('source')?.fileId
        const cloudStorageUrl = this.model.get('source')?.cloudStorageUrl

        this.setElement(Template({
            Styles,
            hasFile: fileId || cloudStorageUrl,
            fileId,
            fileIdHash: this.model.get('source')?.fileIdHash,
            cloudStorageUrl,
            title: this.model.get('title')
        }));

    }
});
