export default {

    /**
     * initAlgebrakit
     *
     * Sets AlgebraKiT configuration and loads dependencies (js and css)
     *
     */
    initAlgebrakit() {
        if (window.AlgebraKIT === undefined) {
            window.AlgebraKIT = {
                config: {
                    // TODO, check if these can be removed
                    widgets: [{
                        name: 'akit-exercise',
                        handwriting: 'myscript'
                    }],
                    // to load custom css
                    theme: 'learnbeat',

                    // to send all request via learnbeat proxy
                    proxy: location.origin + '/akit_proxy'
                },
            };

            // Interaction with the Algebrakit widget will trigger calls to the API which in turn
            // send back events that we can intercept with a listener.
            // To make sure we don't add the same listener again, this object keeps track of all active listeners.
            window.AlgebraKITListeners = []

            // Everything but our production environment should talk with Algebrakit's beta environment
            const domain = (DOMAIN.isLive) ?
                'https://widgets.algebrakit.eu' :
                'https://widgets.staging.algebrakit.com'

            $('body').append(
                $('<script>', {
                    type: 'text/javascript',
                    src: domain + '/akit-widgets.min.js'
                })
            );
        }
    },

    /**
         * parseExerciseHtml
         *
         * Parse exercise HTML of an exercise to show it's solution.
         * Add an extra attribute to the HTML to either show the Akit widget's solution-mode (when showing model answer)
         * or review-mode (when checking answers).
         *
         * @param  {String} exerciseHtml html that needs to be loaded into the akit widget
         * @param  {String} mode         type of mode to show, either solution-mode or review-mode
         * @return {String}              html with review-mode attribute added
         */
    parseExerciseHtml(exerciseHtml, mode) {

        // Compound exercises are made of <akit-content> elements
        const akitContent = exerciseHtml.match(/<akit-content (.+?)>/);

        if (akitContent) {

            // add review-mode attribute to this element
            const newAkitElement = this.adjustHtml(akitContent, mode);

            // Replace the exercise HTML with the new akit interaction
            return exerciseHtml.replace(/<akit-content (.+?)>/, newAkitElement);
        }

        // Single exercises are made out of <akit-exercise> elements
        const akitExercise = exerciseHtml.match(/<akit-exercise (.+?)>/);

        if (akitExercise) {

            // add review-mode attribute to this element
            const newAkitElement = this.adjustHtml(akitExercise, mode);

            // Replace the exercise HTML with the new akit interaction
            return exerciseHtml.replace(/<akit-exercise (.+?)>/, newAkitElement);
        }
    },

    /**
         * adjustHtml
         *
         * Add review mode at the end of an element
         * @param  {String} elem string representing html elem
         * @param  {String} mode type of mode to show, either solution-mode or review-mode
         * @return {String}      edited string with review-mode attribute
         */
    adjustHtml(elem, mode) {
        return elem[0].replace('>', ' ' + mode + '>');
    }

}
