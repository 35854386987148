import Template from './Quote.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import BlockquoteTemplate from 'views/components/wysiwyg/elements/blockquote.hbs';

export default class Quote extends Button {

    // Custom command
    command = 'insertBlockquote'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Quote');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        const customEl = editorObject.customEl
        const customSH = editorObject.customSH

        // Add the command for inserting a blockquote.
        editor.addCommand(this.command, () => {
            // Pass the template and editor with it as a blockElement.
            // Note that BlockquoteTemplate doesn't have a newline at end of file.
            // If it did, this newline would be inserted into the editor,
            // adding an unwanted empty line after the inserted content.
            customEl.blockElement(BlockquoteTemplate, editor);
        });

        // Create the state handler for this block element
        customSH.blockElementHandler(
            // Listen to command: insertBlockquote
            this.command,
            // Use the BlockquoteTemplate template to indentify the block
            BlockquoteTemplate(),
            // Pass the editor with it
            editor
        );
    }

}
