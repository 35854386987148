var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":11,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"work-on__student-metadata\">\n                <div class=\"work-on__student-answer-column-sublabel\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"lastUpdatedAbsolute") || (depth0 != null ? lookupProperty(depth0,"lastUpdatedAbsolute") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastUpdatedAbsolute","hash":{},"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":6,"column":99}}}) : helper)))
    + "\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"responseTypeLabel") || (depth0 != null ? lookupProperty(depth0,"responseTypeLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"responseTypeLabel","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":41}}}) : helper)))
    + "\n                    <span class=\"no-print\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"lastUpdatedRelative") || (depth0 != null ? lookupProperty(depth0,"lastUpdatedRelative") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastUpdatedRelative","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":66}}}) : helper)))
    + "</span>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"work-on__student-answer-column\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"responseTypeLabel") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"work-on__student-answer-inner js-student-answer\">\n        <div class=\"text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"givenAnswer") || (depth0 != null ? lookupProperty(depth0,"givenAnswer") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"givenAnswer","hash":{},"data":data,"loc":{"start":{"line":15,"column":26},"end":{"line":15,"column":43}}}) : helper))) != null ? stack1 : "")
    + "</div>\n    </div>\n    <div class=\"js-score-holder\"></div>\n\n</div>\n";
},"useData":true});