import Util from 'util/util'

export default class InputUtil {

    static validateEmailInput(input = '') {
        if (!input || !Util.validateEmail(input)) {
            Backbone.View.layout.openStatus(window.i18n.gettext('You did not provide a valid email address.'))
            return false
        }
        return true
    }

    static validateURLInput(input = '') {
        if (!input || !Util.validateURL(input)) {
            Backbone.View.layout.openStatus(window.i18n.gettext('This is not a valid URL.'))
            return false
        }
        return true
    }

    static enterHTMLShortcut(e) {
        // On Apple devices check if command key is pressed, otherwise check if control key is pressed
        const os = window.uaparser.getOS().name
        if (os === 'Mac OS' || os === 'iOS' ? !e.metaKey : !e.ctrlKey) {
            return
        }

        let tag
        switch (e.keyCode) {
            case 66:
                tag = 'b'
                break
            case 73:
                tag = 'i'
                break
            case 85:
                tag = 'u'
                break
            case 187:
                tag = e.shiftKey ? 'sup' : 'sub'
                break
            default:
                return
        }

        e.stopPropagation()
        e.preventDefault()

        // Insert the HTML tag in the text
        const textarea = e.target
        const before = textarea.value.substr(0, textarea.selectionStart)
        const selection = textarea.value.substr(
            textarea.selectionStart,
            textarea.selectionEnd - textarea.selectionStart
        )
        const after = textarea.value.substr(textarea.selectionEnd)
        textarea.value = before + '<' + tag + '>' + selection + '</' + tag + '>' + after

        // Place the cursor after the closing tag (after the selection, 2 tag names, 5 characters such as <>)
        const cursorPosition = before.length + selection.length + (2 * tag.length) + 5
        textarea.setSelectionRange(cursorPosition, cursorPosition)

        // Trigger input event so Svelte can see the input value has changed.
        textarea.dispatchEvent(new Event('input', {bubbles: true}))
    }

    static inputTabCharacter(e) {
        // Make it possible to enter tabs in the textarea.
        if (e.key === 'Tab' && !e.shiftKey) {
            e.preventDefault()
            const selectionStart = e.target.selectionStart
            e.target.value =
                e.target.value.substring(0, selectionStart) +
                '\t' +
                e.target.value.substring(e.target.selectionEnd)
            e.target.selectionEnd = selectionStart + 1
        }
    }
}
