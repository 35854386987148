import Styles from './OptionGroup.scss';

import Template from './OptionGroup.hbs';
export default BaseView.extend({

    events: {
        'click .js-choice': 'onClickChoiceItem'
    },

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Object as defined by parent
     */
    initialize({
        choices,
        callback,
        doCallbackOnInitialize,
        selectedIndex,
        fullWidth = false
    }) {

        // Make this accessible within following methods
        _.bindAll(this,
            'goToPrevious',
            'moveToItem'
        );

        this.choices = choices

        // Set this currentChoice
        this.currentChoice = selectedIndex || 0

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            choices,
            selectedIndex: this.currentChoice,
            fullWidth
        }));

        this.callback = callback
        if (doCallbackOnInitialize && callback) {
            callback(
                // Pass the selected choice
                this.getCurrentChoice(),
                // Pass the index
                this.currentChoice
            )
        }
    },

    getCurrentChoice() {

        // Return the current choice
        return this.choices[this.currentChoice];
    },

    goToPrevious() {

        // Get the element of the previous coice
        var previousChoiceDOMElement = this.$(`[data-index=${this.prevChoice}]`);

        // Move to the previous choice
        this.moveToItem(previousChoiceDOMElement);
    },

    /**
     * onClickChoiceItem
     *
     * This function will be called when the user clicks on a
     * item. It will visualy toggle the activity from the active
     * choice to the new choice and will call the callback.
     *
     * @param  {Object} e   jQuery's event object
     */
    onClickChoiceItem(e) {

        // Distill the clicked choice as DOM element using the event object
        var clickedChoiceDOMElement = this.$(e.currentTarget);

        // Move to the clicked choice item
        this.moveToItem(clickedChoiceDOMElement);
    },

    moveToItem(item) {

        // Get the index from the clicked element using the data attribute
        var clickIndex = parseInt(item.data('index'));

        // Check if the clicked element isn't the active one
        if (!item.hasClass(Styles['item--active'])) {

            // Remove the active classes from all choices
            this.$('.js-choice').removeClass(Styles['item--active']);

            // Add the active class to the clicked item
            item.addClass(Styles['item--active']);

            // Set previous choice
            this.prevChoice = this.currentChoice;

            // Set the current choice to the click index
            this.currentChoice = clickIndex;

            // Check if there is a callback
            if (this.callback) {

                // Call the callback
                this.callback(

                    // Pass the selected choice
                    this.choices[clickIndex],

                    // Pass the index
                    clickIndex,

                    // Pass the previous item
                    this.goToPrevious
                );

            }

            // When active
        } else {

            if (
                // Check if there is a click attribute
                this.choices[clickIndex].click &&

                // Check if click should always be executed
                this.choices[clickIndex].click === 'always' &&

                // And if there is a callback
                this.callback
            ) {

                // Call the callback
                this.callback(

                    // Pass the selected choice
                    this.choices[clickIndex],

                    // Pass the index
                    clickIndex,

                    // Set the third argument to true
                    // so the callback can handle when
                    // it's not changed but recalled
                    true
                );
            }
        }
    }

});
