import Styles from './EditColumnsModal.scss';

import Template from './EditColumnsModal.hbs';
import QuickInput from 'views/components/quickInput/QuickInput';

export default BaseView.extend({

    initialize() {

        _.bindAll(this, 'saveColumns')

        this.setElement(Template({ Styles }))

        this.leftColumnInput = this.addChildView(new QuickInput({
            label: window.i18n.gettext('Left column'),
            placeholder: window.i18n.gettext('Left column'),
            defaultValue: this.model.get('metadata').left_column_name,
            noMargin: true,
        }), '.js-left-column-input')

        this.rightColumnInput = this.addChildView(new QuickInput({
            label: window.i18n.gettext('Right column'),
            placeholder: window.i18n.gettext('Right column'),
            defaultValue: this.model.get('metadata').right_column_name,
            noMargin: true,
        }), '.js-right-column-input')

        _.defer(() => {
            Backbone.View.Components.modal.addButtons({
                [window.i18n.gettext('Cancel')]: {
                    callback: Backbone.View.Components.modal.close,
                    theme: 'secondary',
                },
                [window.i18n.gettext('Save')]: {
                    keyCode: 13,
                    callback: this.saveColumns,
                }
            })
        })
    },

    saveColumns() {
        const left = this.leftColumnInput.getInput()
        const right = this.rightColumnInput.getInput()

        if (!left || !right) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Enter a name for both columns.')
            )
            return
        }

        // Do not allow long column names to avoid issues with rendering on small screens
        if (left.length > 20 || right.length > 20) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Column names cannot be longer than 20 characters.')
            )
            return
        }

        $.post({
            url: this.model.activityId ?
                '/training/edit_columns/' + this.model.id + '/' + this.model.activityId :
                '/training/edit_columns/' + this.model.id,
            data: {
                left_column_name: left,
                right_column_name: right,
            },
            success: (response) => {
                if (response.status === 'success') {
                    // Update the model with the new column names
                    const metadata = this.model.get('metadata')
                    this.model.set('metadata', {
                        ...metadata,
                        left_column_name: left,
                        right_column_name: right,
                    })

                    Backbone.View.Components.modal.close()

                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('The column names have been updated.'),
                        'success'
                    )
                }
            }
        })
    }

})
