var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img\n    class=\"js-expression\"\n    src=\"data:image/svg+xml;base64,"
    + alias4(((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":46}}}) : helper)))
    + "\"\n    data-base64svg=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":4,"column":20},"end":{"line":4,"column":31}}}) : helper)))
    + "\"\n    data-base64formula=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"texFormula") || (depth0 != null ? lookupProperty(depth0,"texFormula") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"texFormula","hash":{},"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":38}}}) : helper)))
    + "\"\n/>\n";
},"useData":true});