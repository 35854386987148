import Styles from './HyperlinkMaker.scss';

import Template from './HyperlinkMaker.hbs';
import Util from 'util/util';
import QuickInput from 'views/components/quickInput/QuickInput.svelte'

export default BaseView.extend({

    initialize(options) {

        this.chosenOptions = {}

        _.bindAll(
            this,
            'getOptions'
        );

        const selectedNode = options.selection.getNode()

        // When an existing link is selected, select the full link text (to avoid splitting the link in two)
        if (selectedNode.tagName === 'A') {
            options.selection.select(selectedNode)
        }

        this.setElement(Template({
            Styles,
            url: selectedNode && selectedNode.href,
            title: options.selection.getContent({ format: 'text' })
        }));

        this.URLInputField = this.addSvelteChildView(
            '.js-hyperlink-input',
            QuickInput,
            {
                value: selectedNode && selectedNode.href,
                placeholder: 'https://',
                type: 'url',
                label: window.i18n.gettext('URL'),
            },
        )

        this.titleInputField = this.addSvelteChildView(
            '.js-title-input',
            QuickInput,
            {
                value: options.selection.getContent({format: 'text'}),
                placeholder: window.i18n.gettext('Title'),
                label: window.i18n.gettext('Title'),
            }
        )

    },

    getOptions() {
        this.chosenOptions.title = this.titleInputField.getValue() || window.i18n.gettext('link')
        const url = this.URLInputField.getValue()
        this.chosenOptions.hyperlink = Util.validateURL(url) ? url : 'https://' + url
        return this.chosenOptions
    }

});
