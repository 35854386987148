import Modal from 'views/components/modals/Modal';
import Message from 'views/components/modals/confirm/message/Message';
import ConfirmStyles from 'views/components/modals/confirm/Confirm.scss';

export default class AlertModal extends Modal {

    initialize(options) {

        super.Styles = ConfirmStyles

        super.initialize(options)

        // Make options accessible within this
        _.extend(this, options);

        // Make this accessible within the following functions
        _.bindAll(this,
            'onClickYes',
        );

        this.open(Message, {
            title: options.title,
            buttons: {
                // Add the Ok button for dismissing the alert window.
                [options.yesButtonLabel || window.i18n.gettext('Ok')]: {
                    callback: this.onClickYes,
                    keyCode: 13,
                }
            },
            message: options.message,
            // Prevent modal from being dismissed.
            removeCloseButton: true,
        });

    }

    /**
     * onClickYes
     *
     * This function will be called when the user clicks on the
     * yes button. It wall call the yes callback function and then
     * close the confirm modal.
     *
     */
    onClickYes() {

        // Call the yes callback function
        this.callbackYes()

        // Close the modal
        this.canBeClosed = true
        this.close()
    }

}
