import Styles from './PracticeCards.scss';

import Template from './PracticeCards.hbs';
import SinglePracticeCard from 'views/pages/words/practiceCards/singlePracticeCard/SinglePracticeCard'

export default class PracticeCards extends BaseView {
    initialize({callback}) {

        this.setElement(Template({
            Styles,
        }));

        this.flashCard = this.addChildView(new SinglePracticeCard({
            cardTitle: window.i18n.gettext('Turn cards'),
            cardIcon: 'flash-card',
            descriptionText: window.i18n.gettext('Get familiar with a word'),
            starAmount: 1,
            buttonCallback: () => callback('cards'),
            isDone: false,
        }), '.js-card-flash-cards');

        this.multipleChoiceCard = this.addChildView(new SinglePracticeCard({
            cardTitle: window.i18n.gettext('Multiple choice'),
            cardIcon: 'task-1',
            descriptionText: window.i18n.gettext('Learn to choose a word'),
            starAmount: 2,
            buttonCallback: () => callback('multiple_choice'),
            isDone: false,
        }), '.js-card-multiple-choice')

        this.openCard = this.addChildView(new SinglePracticeCard({
            cardTitle: window.i18n.gettext('Open questions'),
            cardIcon: 'task-2',
            descriptionText: window.i18n.gettext('Learn to spell a word'),
            starAmount: 3,
            buttonCallback: () => callback('open'),
            isDone: false,
        }), '.js-card-open-questions')

    }

}
