import Styles from './RadioButton.scss';

import Template from './RadioButton.hbs';
import Util from 'util/util'

export default BaseView.extend({

    initialize({
        inline,
        callback,
        isChecked,
        isDisabled,
        label,
        parentGroup,
        rightAlignedText,
        subText,
        tooltip,
        value,
    }) {

        const hasViewLabel = label instanceof BaseView;

        this.setElement(Template({
            Styles,
            cid: this.cid,
            inline,
            label: hasViewLabel
                // Render empty string if label is a view
                ? ''
                : Util.renderContentSafely(label),
            parentGroup,
            rightAlignedText,
            subText,
            tooltip,
            value,
        }));

        if (hasViewLabel) {
            label.appendTo(this.$('.js-label'));
        }

        this.inputElement = this.el.querySelector('input')

        this.setDisabled(isDisabled)
        this.setState(isChecked)

        if (callback) {
            this.el.querySelector('input').addEventListener('change', e => {
                callback(parseInt(e.currentTarget.value))
            })
        }
    },

    setDisabled(isDisabled) {
        this.inputElement.disabled = isDisabled;
    },

    isDisabled() {
        return this.inputElement.disabled;
    },

    /**
         * setState
         *
         * Set state in DOM
         *
         * @param {Boolean} isChecked  new active state
         */
    setState(isChecked) {
        this.inputElement.checked = isChecked;
    },

    /**
         * getState
         *
         * Gets the current state.
         *
         * @return {Boolean} If the input is checked or not.
         */
    getState() {
        return this.inputElement.checked;
    }

});
