import Styles from './Login.scss';

import Template from './Login.hbs';
import SSOBar from 'views/components/ssobar/SSOBar.svelte'
import ConditionsAndPrivacy from 'views/pages/users/login/conditionsAndPrivacy/ConditionsAndPrivacy'
import UsernameAndPassword from 'views/components/register/parts/card/parts/login/UsernameAndPassword'
import Util from 'util/util'
import HeroButton from 'views/components/heroButton/HeroButton'

export default BaseView.extend({

    events: {
        'click .js-nav-disclaimer': 'openDisclaimerSidebar'
    },

    initialize() {

        this.previousUsername = '';

        // get the previous username from the localstorage
        if (Util.hasSupportForLocalstorage()) {
            this.previousUsername = localStorage.getItem('previousUsername');
        }

        // Non-animated theme can have a single background image
        let backgroundImage
        if (Backbone.Model.app_version.get('url')) {
            backgroundImage = 'https://static.learnbeat.nl/app_versions/background-images/' + Backbone.Model.app_version.get('url')
        }

        this.setElement(Template({
            Styles,
            logo: Backbone.Model.app_version.get('logo') || Backbone.Model.app_version.get('label'),
            marketing_site: Backbone.Model.app_version.get('marketing_site'),
            label: Backbone.Model.app_version.get('label'),
            theme: Backbone.Model.app_version.get('theme'),
            language: LANGUAGE,
            isAnimated: /^animated\-/.test(Backbone.Model.app_version.get('theme')),
            backgroundImage
        }));     
        
        this.mountSSOBar();

        this.addChildView(
            new HeroButton({
                firstLine: window.i18n.gettext('Register with a join-code'),
                callback: () => {
                    Backbone.history.navigate(
                        'users/register_join_code',
                        { trigger: true }
                    )
                }
            }),
            '.js-join-code'
        )

        if (document.location.search) {
            this.parseSearchParam();
        }

        this.listenTo(
            Backbone,
            'login:successful',
            (response) => {

                // if user was requesting another URL but was sent to the login page
                // we get an extra option `redirect_uri`, so we use that to send to that
                // location
                if (_.isString(response.redirect_uri)) {
                    window.goToLocation = response.redirect_uri.replace(/^\//, '')
                }

                Backbone.Model.user.loadUserInfo();
            }
        );

        // Append username and password form.
        this.addChildView(new UsernameAndPassword({
            callback: this.onLogin,
            previousUsername: this.previousUsername
        }), '.js-login');
    },

    /**
    * onLogin
    *
    * This function is triggered as a callback from the UsernameAndPasswordView.
    *
    * @param  {string} username - username
    * @param  {string} password - password
    * @param  {Button} submitButtonView - submitButtonView
    */
    onLogin(username, password, submitButtonView) {

        // Login
        Backbone.Model.user.logIn(
            username,
            password,
            null,
            submitButtonView
        );

    },

    /**
     * openDisclaimerSidebar
     *
     * Show Conditions & Privacy text in the sidebar.
     */
    openDisclaimerSidebar() {
        // Create a new disclaimer view
        if (!this.conditionsAndPrivacy) {
            this.conditionsAndPrivacy = new ConditionsAndPrivacy();
            this.registerChildView(this.conditionsAndPrivacy);
        }

        // Open the diclaimer view in the sidebar
        Backbone.View.sidebar.showSidebar(
            this.conditionsAndPrivacy,
            window.i18n.gettext('Conditions & Privacy'),
            600
        );
    },

    /**
     * parseSearchParam
     *
     * Show status message or another action based on current document location search parameters.
     */
    parseSearchParam() {
        var alertTitle
        var alertBody
        var alertButton
        const params = new URLSearchParams(location.search)

        if (params.has('link_has_expired')) {
            // When a link to reset password has expired
            // this will be passed in the url's search parameter on redirect to login screen
            alertTitle = window.i18n.gettext('This link has expired')
            alertBody = window.i18n.gettext(
                'The link to reset your password has expired. Please try again.'
            )
            alertButton = window.i18n.gettext('Close')
        } else if (params.has('password_changed')) {
            // When user sets a new password, he will be redirected to the login view
            // this will be passed in the url's search parameter
            alertTitle = window.i18n.gettext('New password has been set')
            alertBody = window.i18n.gettext(
                'Your new password is set. You can now log in with your new password.'
            )
        } else if (params.has('su_unknown_identity')) {
            // When the backend has failed to recognize the user as a teacher or student, or if
            // the groupInfo is unknown. The user is directed out of the sign up flow and asked to try again.
            alertTitle = window.i18n.gettext('Registration error')
            alertBody = window.i18n.gettext(
                'Something went wrong while trying to register you. Please try again.'
            )
            alertButton = window.i18n.gettext('Close')
        } else if (params.email_address) {
            // Pre-fill email address in user name input field if the email address is a valid one.
            if (Util.validateEmail(params.email_address)) {
                this.previousUsername = params.email_address;
            }
        } else if (params.has('wt_email_already_used')) {
            alertTitle = window.i18n.gettext('This email is already in use')
            alertBody = window.i18n.gettext(
                'There already exists an account with this email address. You can log in or reset your password.'
            )
            alertButton = window.i18n.gettext('Close')
        } else if (params.has('wt_link_expired')) {
            alertTitle = window.i18n.gettext('This link has expired')
            alertBody = window.i18n.gettext(
                'This invitation link is expired. Ask your inviter to send a new invite.'
            )
            alertButton = window.i18n.gettext('Close')
        } else if (params.has('signup_link_expired') || params.has('ra_link_expired')) {
            alertTitle = window.i18n.gettext('This link has expired')
            alertBody = window.i18n.gettext(
                'This invitation link has expired. Ask your teacher to send a new invite.'
            )
            alertButton = window.i18n.gettext('Close')
        } else if (params.has('demo_link_expired')) {
            alertTitle = window.i18n.gettext('This link has expired')
            alertBody = window.i18n.gettext(
                'This demo link is expired. Request a new demo account to use the demo.'
            )
            alertButton = window.i18n.gettext('Close')
        } else if (params.has('magic_login_link_expired')) {
            alertTitle = window.i18n.gettext('This link has expired')
            alertBody = window.i18n.gettext(
                'This login link is expired. We will send you a new link which is valid for another hour.'
            )
            alertButton = window.i18n.gettext('Close')
        }

        // If search param was parsed with a statusmessage and type as output
        if (alertTitle && alertBody) {
            // Show status message
            Backbone.View.layout.openAlert(
                alertTitle,
                alertBody,
                () => {},
                alertButton
            );
        }

        // Clean search param
        Backbone.history.navigate(
            '/users/login',
            {trigger: false}
        );

    },

    onDestroy() {
        $('body').css('background-image', '');
    },

    mountSSOBar() {
        const consumers = Backbone.Model.app_version.get('consumers');

        const ssoContainer = this.$('.js-sso-bar')[0];
        if (ssoContainer) {
            this.addSvelteChildView(
                ssoContainer,
                SSOBar,
                {
                    fromLoginScreen: true,
                    consumers
                }
            )
        }
    },

})
