import Styles from './YouTube.scss';

import Template from './YouTube.hbs';
import YouTubePlayer from 'youtube-iframe';

// YouTube video view
var YouTube = BaseView.extend({

    initialize(options) {

        this.videoHash = options.videoHash;
        this.openAndPlay = options.openAndPlay;
        this.fullscreen = options.disableFullscreen ? '0' : '1'
        // Generate unique number so duplicate videos are possible on the same page.
        this.randomID = _.random(1000, 9999);

        this.setElement(Template({
            Styles,
            randomID: this.randomID,
            videoHash: this.videoHash,
            isVideoActivity: options.isVideoActivity
        }));

        // Made sure DOM has loaded before inserting video
        _.defer(_.bind(this.insertVideo, this));
    },

    insertVideo() {

        YouTubePlayer.load(function(YT) {
            new YT.Player(
                `js-video-${this.randomID}-${this.videoHash}`, {
                    videoId: this.videoHash,
                    width: '100%',
                    height: '100%',
                    playerVars: {
                        autoplay: this.openAndPlay,
                        modestbranding: 1,
                        rel: 0,
                        playsinline: 1,
                        fs: this.fullscreen
                    }
                }
            );
        }.bind(this));

    }

});

export default YouTube;
