export default {

    /**
     * launchIntoFullscreen
     *
     * This function triggers the fullscreen mode in the Fullscreen API.
     * It will try different calls of the API call based on the vendor type.
     *
     * @param  {element} element - The element to show in fullscreen mode
     */
    launchIntoFullscreen(element = document.documentElement) {
        if (element.requestFullscreen) {
            element.requestFullscreen()
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen()
        } else {
            window.sentry.withScope(() => {
                window.sentry.captureMessage('Tried to open fullscreen mode, but failed')
            })
        }
    },

    /**
         * checkFullscreen
         *
         * Check if user is in fullscreen mode
         *
         * @return {bolean} - boolean saying if user is fullscreen
         */
    checkFullscreen() {
        return (
            document.fullscreenElement ||
                document.webkitFullscreenElement
        )
    },

    /**
         * exitFromFullscreen
         *
         * This function will exit the user from the fullscreen mode.
         * It will try different calls of the API call based on the vendor type.
         *
         */
    exitFromFullscreen() {
        if (this.checkFullscreen()) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
        }
    },

    /**
         * checkIfFullscreenIsPossible
         *
         * This function checks if fullscreen is supported in the user's browser.
         * If so it returns true, otherwise false.
         *
         * @return {boolean} - Possibility of toggling the fullscreen mode
         */
    checkIfFullscreenIsPossible() {
        return (
            document.fullscreenEnabled ||
                document.webkitFullscreenEnabled
        )
    },

    // Return the event to listen to for changes in the fullscreen state
    // The user either enters or leaves fullscreen mode
    getFullscreenChangeEvent() {
        if (document.onfullscreenchange !== undefined) {
            return 'fullscreenchange'
        }
        if (document.onwebkitfullscreenchange !== undefined) {
            return 'webkitfullscreenchange'
        }
    }

}
