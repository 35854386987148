var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"logout") : stack1), depth0))
    + "\">\n    <span>"
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"You can't make an exam in the mobile environment.<br><br> Are you not using a smartphone? <br><br><a>Go to desktop version</a>",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":147}}})) != null ? stack1 : "")
    + "</span>\n    <div class=\"js-logout-btn\"></div>\n</div>\n";
},"useData":true});