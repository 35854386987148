import Styles from './TreeItem.scss';

import Template from './TreeItem.hbs';
import ContentLabel from 'views/components/contentLabel/ContentLabel';
import Checkbox from 'views/components/checkbox/Checkbox';
import RadioButton from 'views/components/radioButton/RadioButton';
import TreeButton from 'views/components/treeView/treeButton/TreeButton';
import Util from 'util/util';

export default class TreeItem extends BaseView {

    get parentModel() {
        return this.model?.getParent()
    }

    get childModels() {
        const children = this.model?.getChildren()

        if (this.hideAdaptiveStudent) {
            // do not count adaptive_student activity for change layer overlay.
            // do not use collection.filter here: that returns an array of models
            // instead of a collection.
            return new children.constructor(
                children?.filter(child => child.get('type') !== 'adaptive_student')
            )
        }

        return children
    }

    get hasChildren() {
        return _.size(this.childModels) > 0 && (this.maxDepth === Infinity || this.depth < this.maxDepth)
    }

    get depth() {
        return this.depthMap.get((this.model.constructor.type ?? '') + this.model.id)
    }

    /**
    * TreeItemView
    *
    * Component which creates an interactive tree browser view based on data with a parent-child relationship.
    *
    * this.model {Backbone.Model}
    * Model which is part of a collection which a a parent-child relationship. This model requires the following
    * methods to work with for the TreeItemView component:
    *  - getChildren()
    *    Returns Backbone.Collection of child models (eg. chapter->sections) or undefined.
    *  - getParent()
    *    Returns parent model (eg. section->chapter) or undefined (eg. group->undefined).
    *  - getSiblings()
    *    Returns sibling models connected to the same parent (eg. section->all sections of the parent chapter).
    *
    * @param {Object} options
    * Options object. May contain the following listed below:
    *
    * @param {Boolean|Function|undefined} options.isSelectable
    * Optional Boolean or Function which takes the model of the tree item as the first argument. If the Boolean is
    * true or the function evaluates to true, allow this tree item to have a checkbox or radiobutton input element.
    *
    * @param {Boolean|undefined} options.isSingleSelect
    * If both this and isSelectable are true, use a radiobutton element instead of a checkbox input element, allowing
    * for only one item in the entire tree to be selected at the time.
    *
    * @param {Object|undefined} options.additionalWidgetOptions
    * Optional options to add extra widgets (like buttons, progress bars, etc.) to the tree item view.
    * See resolveAdditionalWidgetOptions in TreeItem view for more information.
    *
    * @param {Boolean|undefined} options.hideAdaptiveStudent
    * Optional. If true, filter out activities of the type adaptive_student
    *
    * @param {Boolean|undefined} options.allClosedByDefault
    * If true, none of the tree items are unfolded when the component is created. Usefull if to not to overwelm the
    * user with information and also save on render time.
    *
    * @param {Number|undefined} options.maxDepth
    * If set, defines a maximum depth till which child tree branches can be added. For example usefull to prevent
    * activities to show up in a tree that only cares about chapters and sections.
    *
    * @param {Object|undefined} options.styleOptions
    * Optional options to modify default styling of tree item view. For example {hasArrowLeft: true} hides the
    * fold/unfold arrows.
    */
    initialize(options) {

        _.extendOwn(this, options);
        const isActivity = this.model.constructor.type === 'activity';

        this.hideAdaptiveStudent = options.hideAdaptiveStudent

        // Optional max depth of tree.
        this.maxDepth = options.maxDepth ?? Infinity

        // Only render child model views once.
        this.renderChildModels = _.once(this._renderChildModels);

        const showArrow = this.showArrow()

        // Create the view, passing the styling with it
        this.setElement(Template({

            Styles,

            hasChildren: this.hasChildren,

            showArrow,
            showPointer: showArrow || this.getIsSelectable(),
            isLinear: isActivity && this.model.get('type') === 'linear',

            isActivity,

            activityType: isActivity && this.model.get('type'),

            activityLabel: isActivity && this.model.getActivityTypeLabel(this.model.get('type')),

            hasArrowLeft: this.styleOptions?.hasArrowLeft,

            numberOfTasksGroups: isActivity && this.model.get('count_task_groups'),

            itemType: this.model.constructor.type,
            itemId: this.model.id,

        }));

        // Create content label to use as label.
        this.label = this.createLabel(options.contentLabelOptions)

        // Enable fold/unfold interaction if current item has child items.
        // Else, if TreeItem contains checkbox or radio button, use the whole
        // tree item to toggle the state
        if (this.hasChildren || this.showArrow()) {
            this.$('> .js-current-item').on('click', this.onClickToggleFold.bind(this))
        } else if (this.getIsSelectable()) {
            this.$('> .js-current-item').on('click', () => this.toggleInput())
        }

        // If item has checkbox by either the isSelectable attribute being true or evalutation as true via a
        // special condition wrapped in a function
        if (this.getIsSelectable()) {

            // When user can only select a single item, use RadioButton.
            if (this.isSingleSelect) {

                this.radioButton = this.addChildView(new RadioButton({

                    isChecked: this.model.get('isChecked'),

                    callback: () => this.singleSelectCallback && this.singleSelectCallback(this, false),

                    // If tree branch has children, use an empty label for the radio button itself and append in a
                    // sibling element so clicking the label expands/collapses the tree instead of changing
                    // the selection.
                    label: this.hasChildren ? ' ' : this.label,

                    // Use cid number of the root of the tree (TreeView) as the radio button group name.
                    parentGroup: this.rootCid,

                    // Use model id numbers as the radiobutton index values.
                    value: this.model.id
                }), '> .js-current-item > .js-label');
                // Prevent that clicking the radio buttons also unfolds/folds the tree item.
                this.radioButton.el.addEventListener('click', function(e) {
                    e.stopPropagation()
                })

            } else {

                // Add Checkbox if content is selectable and no single select requirement is specified.
                this.checkbox = this.addChildView(
                    new Checkbox({
                        isChecked: this.model.get('isChecked'),
                        callback: this.onClickToggleCheckbox.bind(this),

                        // If tree branch has children, use an empty label for the checkbox itself and append in a
                        // sibling element so clicking the label expands/collapses the tree instead of changing
                        // the selection.
                        label: this.hasChildren ? ' ' : this.label,

                        // Use model id numbers as the checkbox index value.
                        value: this.model.id
                    }),

                    // Use the '>' selector to prevent selecting a
                    // child level's label container
                    '> .js-current-item > .js-label'
                );

                // Update the visibile checkbox state according to the model state.
                this.listenTo(
                    this.model,
                    'change:isChecked',
                    _.partial(
                        this.showState,
                        this.checkbox
                    )
                );

                if (this.hasChildren) {

                    // Listen to child model changes to set state of current model.
                    this.listenTo(
                        this.childModels,
                        'change:isChecked',
                        _.partial(
                            this.childCheckedAttributeChanged,
                            this.checkbox
                        )
                    );
                    // Set inital state based on child models.
                    this.childCheckedAttributeChanged(this.checkbox, this.childModels.at(0));
                }

            }

            // If tree branch is selectable and has children, add the label as a sibling element to the radio button
            // or checkbox so clicking the label results in expanding/collapsing the tree instead of changing
            // the selection.
            if (this.hasChildren) {
                this.label.appendTo(this.$('> .js-current-item > .js-label'))
            }

        } else {
            this.label.appendTo(this.$('> .js-current-item > .js-label'))
        }

        this.resolveAdditionalWidgetOptions(this.additionalWidgetOptions)

        // Unfold or fold everything on init.
        this.sublevelStateIsOpen = !!this.parentsOfSelectedItems || this.allClosedByDefault;

        this.toggleFold(true);

        if (this.parentsOfSelectedItems) {
            this.expandParentsOfSelected()
        }

        const name = this.label.el.querySelector('.js-name');
        Util.handleSearchMatches({ name }, this.model.get('matches'));
    }

    toggleInput() {

        if (!this.radioButton && !this.checkbox) {
            return
        }

        // if a radio button is selected, do not allow to deselect
        // by clicking on on it again
        if (this.radioButton && !this.radioButton.getState()) {

            this.radioButton.setState(!this.radioButton.getState())

            if (this.singleSelectCallback) {
                this.singleSelectCallback(this, false)
            }
        }

        if (this.checkbox) {
            this.checkbox.toggleState()
        }

    }

    rerenderChildren() {

        // get from global collection
        this.model = Backbone.Collection[this.model.constructor.pluralType].get(this.model.id)

        this.childViews
            .filter(view => view instanceof TreeItem)
            .forEach(view => this.unregisterAndDestroyChildView(view))

        this._renderChildModels()

        delete this.collapsedChildren

    }

    /**
     * Expand the tree item if all parents of selected items should be
     * expanded (if option "parentsOfSelectedItems" is truthy)
     */
    expandParentsOfSelected() {

        const hasSelectedChild = this.parentsOfSelectedItems?.get(this.depth)?.has(this.model.id)

        if (hasSelectedChild) {
            this.toggleFold()
        }
    }

    /**
     * True if isSelectable attribute is true or evaluates as true via a custom condition wrapped in a function.
     * This allows the tree item from being selected by the user via a checkbox of radio button input.
     *
     * @returns {Boolean} is selectable
     */
    getIsSelectable() {
        return this.isSelectable === true || (
            this.isSelectable instanceof Function &&
            this.isSelectable(this.model)
        )
    }

    /**
     * resolveAdditionalWidgetOptions
     *
     * @param  {Object} options - options for additional widgets (buttons, sliders, etc.) which are secondary.
     */
    resolveAdditionalWidgetOptions(options = {}) {

        // Assign the item button, which if defined, is displayed to the right of the tree item for special actions
        // using the item model. If defined as an plain object, use the same button for every tree item. If defined
        // as a function, call this function to generate a custom button for the current tree item.

        _.each(options.itemButtons, (button) => {

            this.addTreeButton(
                button instanceof Function ? button(this.model) : button,
                options.styleOptions
            )

        })
    }

    /**
     * Create content label component instance to represent current tree item.
     *
     * @param {Object} contentLabelOptions  optional options to add/override for contentLabel
     * @returns {ContentLabel} instance of ContentLabel component
     */
    createLabel(contentLabelOptions = {}) {
        if (contentLabelOptions instanceof Function) {
            contentLabelOptions = contentLabelOptions(this.model) || {}
        }

        return this.addChildView(
            new ContentLabel({
                model: this.model,
                hasNoLink: true,
                isCompact: true,
                publishedContentTree: this.publishedContentTree,
                ...contentLabelOptions
            })
        )
    }

    /**
     * addTreeButton
     *
     * Adds treebutton component based on input
     *
     * @param  {Object} button  Button object
     * @param  {Object} styleOptions options passed to treeview for style
     */
    addTreeButton(button, styleOptions) {
        if (!_.isEmpty(button)) {

            button.layerType = this.model.constructor.type

            this.addChildView(new TreeButton({
                model: this.model,
                ...button,
                ...styleOptions,
            }), '.js-buttons')
        }
    }

    /**
     * childCheckedAttributeChanged
     *
     * Change checkbox when selection of child models has changed.
     *
     * @param {Checkbox} checkbox               View of the checkbox
     * @param {Backbone.Model} childModel       Child model
     */
    childCheckedAttributeChanged(checkbox, childModel) {
        const siblings = childModel.getSiblings()
        const siblingStates = _.compact(siblings?.pluck('isChecked'))
        // TODO implement half-selected state for Checkbox. See also
        // https://developer.mozilla.org/en-US/docs/Web/CSS/:indeterminate
        checkbox.setState(siblingStates.length === siblings?.length, true)
    }

    /**
     * onClickToggleCheckbox
     *
     * When user clicks the checkbox, set the current model and all child models to the same state.
     *
     * @param  {Boolean} state          Boolean state if is checked or not
     */
    onClickToggleCheckbox(state) {
        this.setState(state, this.model)

        if (this.singleSelectCallback) {
            this.singleSelectCallback(this, true, state)
        }
    }

    /**
     * setState
     *
     * Set states of current and child views recursively.
     *
     * @param {Boolean} state           Boolean state if is checked or not
     * @param {Backbone.Model} model    current and child model
     * @param {Number} childDepth       depth measured from this.model onwards
     */
    setState(state, model, childDepth = 0) {
        if (
            // Before setting the state of this model, access the depth of this model in the tree. If it is nested too
            // deep, is does not appear in the tree presented to the user and its state should not be set. AND check if
            // all items in the true can be selected OR if this particular model can be selected.
            this.depth + childDepth <= this.maxDepth && this.getIsSelectable()
        ) {
            model.set({isChecked: state})
            const childModels = model.getChildren()
            if (childModels) {
                childModels.each((model) => {
                    this.setState(state, model, childDepth + 1)
                })
            }
        }
    }

    /**
     *
     * @param {Checkbox} checkbox       Checkbox view
     * @param {Backbone.Model} model    model containing state
     * @param {Boolean} state           state of check attribute
     */
    showState(checkbox, model, state) {
        checkbox.setState(state, true);
    }

    /**
     * renderChildModels
     *
     * This method will render child model for this level.
     *
     */
    _renderChildModels() {

        if (!this.hasChildren) {
            if (this.endOfCollectionCallback && this.model.constructor.type !== 'activity') {
                const children = this.childModels
                this.endOfCollectionCallback(this.el, new children.constructor())
            }
            return
        }

        let index = 0
        for (const model of this.childModels) {

            const childView = this.addChildView(new this.constructor({
                model,
                allClosedByDefault: this.collapsedChildren ? this.collapsedChildren[index] : this.allClosedByDefault,
                maxDepth: this.maxDepth,
                depthMap: this.depthMap,
                isSelectable: this.isSelectable,
                isSingleSelect: this.isSingleSelect,
                singleSelectCallback: this.singleSelectCallback,
                parentsOfSelectedItems: this.parentsOfSelectedItems,
                styleOptions: this.styleOptions,
                contentLabelOptions: this.contentLabelOptions,
                additionalWidgetOptions: this.additionalWidgetOptions,
                publishedContentTree: this.publishedContentTree,
                endOfCollectionCallback: this.endOfCollectionCallback,
                alwaysRenderArrow: this.alwaysRenderArrow,
                onToggleFold: this.onToggleFold,
                hideAdaptiveStudent: this.hideAdaptiveStudent,
                rootCid: this.rootCid,
            }), '> .js-child-items')

            if (
                this.endOfCollectionCallback &&
                !model.getChildren().length &&
                model.constructor.type !== 'activity'
            ) {
                this.endOfCollectionCallback(childView.el, model.getChildren())
            }

            index += 1

        }

        if (this.endOfCollectionCallback && this.childModels.length) {
            this.endOfCollectionCallback(this.el, this.childModels)
        }

    }

    /**
     * onClickToggleFold
     *
     * When tree item is clicked.
     */
    onClickToggleFold() {
        this.toggleFold();

        // hook for the view that created the TreeView to react on
        // folding / unfolding
        if (this.onToggleFold) {
            this.onToggleFold(this, this.sublevelStateIsOpen)
        }
    }

    /**
     * toggleFold
     *
     * Fold or unfold the present item.
     *
     * @param {Boolean} isInit      true on initialize to skip transition animation
     */
    toggleFold(isInit) {

        const childContainer = this.$('> .js-child-items');
        const arrowContainer = this.$('> .js-current-item .js-arrow svg');

        // Check if the sublevelState is open
        if (this.sublevelStateIsOpen) {

            // Start TweenMax animation
            TweenMax.to(

                // Set the container to the sublevel container
                childContainer,

                {

                    // Set opacity to 1
                    opacity: 0.4,

                    // Set the height to 0
                    height: 0,

                    // Do 0.3 seconds an start object
                    duration: isInit ? 0 : 0.3,

                    // Set easing to expo
                    ease: 'power2.inOut'
                }
            );

            if (arrowContainer.length) {

                TweenMax.to(
                    arrowContainer,
                    {
                        rotation: 0,
                        duration: isInit ? 0 : 0.3,
                        ease: 'power2.inOut'
                    }
                )
            }

            // Update the state
            this.sublevelStateIsOpen = false;
            this.el.setAttribute('aria-expanded', false)
        } else {

            // Render child model views when unfolding item for the first time. Doing this here (loading only
            // the visible items) instead of in initialize (loading the entire tree at once) results in a
            // massive performace improvement.
            this.renderChildModels();

            // send trigger if TreeView was initialized collapsed. the view creating the
            // TreeView then can work on the elements of the previously unrendered
            // tree item
            if (this.allClosedByDefault && this.model.getGroupModel) {
                this.model.getGroupModel()?.trigger('treeitem-expanded')
            }

            // Get the actualHeight from the sublevel container
            const actualHeight = childContainer.css('height', 'auto').height();

            // Set the height back to 0 since we want to use tweenmax to animate
            childContainer.css('height', 0).css('opacity', 0.4);

            if (arrowContainer.length) {
                TweenMax.to(
                    arrowContainer,
                    {
                        rotation: -180,
                        duration: isInit ? 0 : 0.3,
                        ease: 'power2.inOut'
                    }
                )
            }

            // Start tweenmax animation
            TweenMax.to(

                // Set the container to the sublevel container
                childContainer,

                {
                    // Set opacity to 1
                    opacity: 1,

                    // Animate the height to the actualHeight
                    height: actualHeight,

                    // Do 0.3 seconds an start object
                    duration: isInit ? 0 : 0.3,

                    // onComplete set the height to auto for sub sublevel containers
                    onComplete: () => {
                        childContainer.css('height', 'auto');
                    },

                    // Set easing to expo
                    ease: 'power4.inOut'
                }
            );

            // Update the state
            this.sublevelStateIsOpen = true;
            this.el.setAttribute('aria-expanded', true)
        }
    }

    showArrow() {
        return this.hasChildren || (this.alwaysRenderArrow && this.model.constructor.type !== 'activity')
    }

}
