import TaskGroupModel from 'models/TaskGroupModel';
import TasksCollection from 'collections/TasksCollection';

export default class TaskGroupsCollection extends Backbone.Collection {

    get model() {
        return TaskGroupModel
    }

    get comparator() {
        return 'sequence'
    }

    preinitialize() {
        this.constructor.type = 'taskGroups'
    }

    getAboutTaskGroupCollection() {
        return {
            taskGroupCount: this.length,
            taskCount: this.getTasks().length,
            subjects: _.countBy(_.flatten(this.invoke('getSubjects'))),
            questionTypes: _.countBy(_.flatten(this.invoke('getQuestionTypes')))
        }
    }

    getTasks() {
        return new TasksCollection(
            this.reduce((m, model) => {
                return m.concat(model.tasks.models)
            }, [])
        )
    }

    findTaskById(taskId) {
        return this.getTasks().get(taskId)
    }

    setSequence(cidToSequenceArray = [], noCall) {
        let sequence = 0
        const idToSequenceArray = cidToSequenceArray.reduce((m, cid) => {
            const model = this.get(cid)
            if (model) {
                model.set({
                    sequence,
                })
                m.push(model.id)
                sequence++
            }
            return m
        }, [])

        if (!noCall && this.at(0)) {
            $.post(`/task_groups/update_sequence/${this.at(0).get('parent_id')}.json`, {
                task_groups: idToSequenceArray
            })
        }
    }

    findTaskGroupOfTask(id) {
        for ( const model of this) {
            if (model.tasks.get(id)) {
                return model
            }
        }
        return null
    }

    canBeCopied() {
        if (this.length === 0) {
            return false
        }

        let editableCopy = false
        for (const taskGroupModel of this.models) {
            if (!taskGroupModel.canBeCopied()) {
                return false;
            }
            if (!taskGroupModel.canBeEdited()) {
                editableCopy = true
            }
        }

        return editableCopy ? 'editable' : 'primary'
    }
}
