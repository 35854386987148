import Styles from './Template1.scss';

import Template from './Template1.hbs';
import Util from 'util/util';
import ZoomButton from 'views/components/lightbox/zoomButton/ZoomButton';
import ItemTemplate from 'views/components/taskGroups/tasks/template1/Template1Item.hbs';
import AudioPlayer from 'views/components/audioPlayer/AudioPlayer';
import Answer from 'views/components/taskGroups/tasks/template1/answer/Answer';
import ShapeList from 'util/ShapeLoader';
import RenderSpecialElements from 'util/RenderSpecialElements';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';

export default BaseView.extend({

    events: {
        'click .js-item': 'clickItem'
    },

    specialElementsConvertList: {
        // Convertion for HTML element: 'img'
        img: {
            // Default options
            global: {
                // Set template for the img tag
                template: DefaultImageElement
            }
        }
    },

    initialize(options) {

        _.bindAll(
            this,
            'clickItem',
            'showAnswer',
            'hideAnswer',
            'lockAnswer',
            'unlockAnswer',
            'forceRepaint'
        );

        this.taskView = options.task_view;
        this.isPresentation = options.isPresentation;

        // If no inputType is defined, fall back on 'text'.
        this.inputType = this.model.get('task_info_json').inputType || 'text';

        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {
        this.setElement(Template({
            Styles,
            hasImages: this.inputType === 'image'
        }));

        // Render an item for each answer option.
        _.each(this.model.get('task_info_json').answers, (answer, index) => {
            this.$el.append(this.renderItem(answer, index));
        })

        // If task already has an answer, mark this as such in the DOM.
        if (this.taskView.response.get('json_answer') !== undefined) {
            this.allreadyAnswered = this.taskView.response.get('json_answer');
        }
        if (this.allreadyAnswered) {
            _.each(this.allreadyAnswered, (answer) => {
                // use findKey function to map the id of the answer to the place in the shuffled list
                this.$('.js-item:nth-child(' + (
                    _.indexOf(this.model.get('task_info_json').keys, parseInt(answer)) + 1
                ) + ')').addClass(Styles['item--selected']).addClass('js-selected');
            })
        }

        // This HACK fixes a bug where images inside the presentation mode are painted before they are loaded.
        // This results in the images not being rendered at their maximum size.
        // Therefore force a repaint when the images is loaded.
        if (this.isPresentation && this.inputType === 'image') {

            // Create reference to this
            var that = this;

            // Go through all image elements inside this view
            this.$('img').each(() => {

                // When the image is fully loaded
                if (this.complete) {

                    // Call the repaint function
                    that.forceRepaint.call(this);
                } else {

                    // If not, force repaint when the image is loaded
                    $(this).one('load', that.forceRepaint);
                }
            })
        }

        // Get data on the given answer as reflected by the DOM.
        this.getSelected();
    },

    /**
     * renderItem
     *
     * Render answer item to the DOM.
     *
     * @param  {string|Object} answer       answer data
     * @param  {number} index               answer sequence
     * @param  {boolean} isAnswerSummary    true if render item for answer summary
     *
     * @returns {jQuery} jQuery element based on ItemTemplate
     */
    renderItem(answer, index, isAnswerSummary) {

        // Create item template.
        var itemTemplate = $(ItemTemplate({
            Styles,
            index: Util.numToLetter(index).toUpperCase()
        }));

        var itemContent = itemTemplate.find('.js-content');

        // Add special styling if answer is rendered for summary.
        if (isAnswerSummary) {
            itemTemplate.addClass(Styles['item--answer-summary']).addClass(Styles['item--lock-answer']);
        }

        switch (this.inputType) {

            case 'text':

                itemContent.html(answer);

                break;

            case 'image':

                itemContent.html('<img src="/edu_files/open/' + answer + '">');
                itemTemplate.addClass(Styles['item--has-image']);

                // Don't render a zoom button for the author or summary mode.
                if (APPLICATION !== 'author' && !isAnswerSummary && !ISMOBILE) {
                    this.addChildView(new ZoomButton({
                        contextUrl: '/edu_files/open/' + answer
                    }), itemContent);
                }

                break;

            case 'audio':

                // Don't render the full audio player view for author or summary mode. Instead render just
                // the title of the audio fragment preceded by an audio icon.
                if (APPLICATION !== 'author') {
                    this.addChildView(
                        new AudioPlayer({
                            url: '/edu_files/open/' + answer.fileId + '/' + answer.fileIdHash,
                            style: 'small',
                        }), itemContent
                    );

                } else {
                    itemContent.html(ShapeList['source-7'] + answer.title);
                }

                break;

            case 'formula':

                itemContent.html(
                    '<img class="js-expression" ' +
                    'src="data:image/svg+xml;base64,' + answer.content + '" ' +
                    'data-base64formula="' + answer.texFormula + '" />'
                );
                itemTemplate.addClass(Styles['item--has-formula']);

                // Set parent to this.taskView.
                // This will make sure student answer items and model answers are also converted to SVG
                _.defer(RenderSpecialElements, {}, this.taskView, itemContent);

                break;

        }

        return itemTemplate;

    },

    /**
     * clickItem
     *
     * When clicking on item apply the apropiate styling and update the answer data.
     *
     * @param  {jQuery.event} e     jQuery click event
     */
    clickItem(e) {
        this.stopAllEvents(e);
        if (!this.$el.hasClass('showAnswers')) {
            var clickedItem = $(e.currentTarget);

            if (clickedItem.hasClass('js-selected')) {
                clickedItem.removeClass('js-selected').removeClass(Styles['item--selected']);
            } else {

                if (!this.model.get('task_info_json').multiple_answers) {
                    this.$('.js-item').removeClass('js-selected').removeClass(Styles['item--selected']);
                }
                clickedItem.addClass('js-selected').addClass(Styles['item--selected']);
            }

            this.getSelected();

            var answerList = [];
            for (var i = 0; i < this.answer_given.length; i++) {
                if (this.answer_given[i].isSelected) {
                    // get id of answer given on place x in array
                    answerList.push(this.model.get('task_info_json').keys[i]);
                }
            }
            this.taskView.saveResponse(answerList);
        }
    },

    /**
     * getSelected
     *
     * Get selected answer options based on their representation in the DOM.
     * This ensures correct order of answer options.
     */
    getSelected() {
        this.answer_given = [];
        _.each(this.$('.js-item'), (view, index) => {
            this.answer_given.push({
                option: this.model.get('task_info_json').answers[index],
                isSelected: this.$(view).hasClass('js-selected')
            });
        })
    },

    /**
     * Show the correct answer to the task.
     */
    showAnswer() {
        _.each(this.answer_given, (givenAnswer, index) => {

            // Find the item in the DOM at the same index as givenAnswer.
            const itemElement = this.$('.js-item').eq(index)

            // Deep comparison to check if givenAnswer matches any of the possible correct options.
            // By doing a deep comparison both primitive (text, image) and object data types (audio)
            // can be checked for equality.
            let isCorrectAnswer = false
            if (this.model.get('task_info_json').correct_answers.length) {
                isCorrectAnswer = _.any(this.model.get('task_info_json').correct_answers, (correctAnswer) => {
                    return _.isEqual(givenAnswer.option, correctAnswer);
                });
            } else {
                isCorrectAnswer = true
            }

            if (givenAnswer.isSelected) {
                if (isCorrectAnswer) {
                    itemElement.addClass(Styles['item--correct']);
                } else {
                    itemElement.addClass(Styles['item--incorrect']);
                }
            } else if (isCorrectAnswer) {
                if (APPLICATION === 'author') {
                    itemElement.addClass(Styles['item--selected'])
                } else {
                    itemElement.addClass(Styles['item--missing-answer']);
                }
            }

        })
    },

    showAuthorAnswer() {
        this.showAnswer()
    },

    /**
     * hideAnswer
     *
     * Hide the correct answer to the task.
     */
    hideAnswer() {
        this.$('.js-item').removeClass(
            Styles['item--correct']
        ).removeClass(
            Styles['item--incorrect']
        ).removeClass(
            Styles['item--missing-answer']
        );
    },

    /**
     * getCorrectAnswer
     *
     * Generate summary of the correct answer to use above the list of student answers.
     *
     * @return {string} HTML blob to be inserted above the list of student answers.
     */
    getCorrectAnswer() {
        var answerItem = '';

        if (this.inputType === 'audio') {

            // Audio files need to be put in a view that executes JS
            // If put in an HTML file, the audio player will have no interactivity
            answerItem = new Answer({});
            this.registerChildView(answerItem);
        }

        _.each(this.model.get('task_info_json').correct_answers, (answer) => {

            // Create an index variable which will display
            // the positon of the right answer [f.e. A, B, C D]
            var index;

            // Exceptions are made for audio files
            if (this.inputType === 'audio') {

                // Match the correct answer with its index by looking at fileId.
                index = _.findIndex(this.model.get('task_info_json').answers, {
                    fileId: answer.fileId
                });

            } else if (this.inputType === 'formula') {

                // Match the correct answer with its index if objects are equal.
                index = _.findIndex(this.model.get('task_info_json').answers, answer);

            } else {

                // Match the correct answer with its index in the answer options array.
                index = _.indexOf(this.model.get('task_info_json').answers, answer);

            }

            if (this.inputType === 'audio') {

                // Add a DOM element for each possible answer and add it to the view
                answerItem.$el.append(this.renderItem(answer, index, true));

            } else {

                // For each possible correct answer, create a DOM representation and
                // convert this to a plain DOM string.
                answerItem += this.renderItem(answer, index, true)[0].outerHTML;

            }

        })
        return answerItem;
    },

    /**
     * getStudentAnswer
     *
     * Generate summary of the answer student has given for use in the list of student answers.
     *
     * @param  {Object} responseModel Student response data
     * @return {string}               HTML blob to be used for the student answer view.
     */
    getStudentAnswer(responseModel) {

        // Define inputType again for compatibility with progress and drilldown view since
        // those views ignore the initialize method of this view.
        // If no inputType is defined, fall back on 'text'.
        this.inputType = this.model.get('task_info_json').inputType || 'text';

        var answerItem = '';

        if (this.inputType === 'audio' && responseModel.has('json_answer')) {

            // Audio files need to be put in a view that executes JS
            // If put in an HTML file, the audio player will have no interactivity
            answerItem = new Answer({});
            this.registerChildView(answerItem);
        }

        if (responseModel.has('json_answer')) {
            _.each(responseModel.get('json_answer'), (answer, index) => {

                // Change the index of the answer by matching it to its keys.
                // This is relevant for answer options containing images.
                // Here, the user can only recognize a correct answer by its index.
                index = _.indexOf(this.model.get('task_info_json').keys, parseInt(answer));

                // Find the content of the answer by it's index.
                answer = this.model.get('task_info_json').answers[
                    _.indexOf(this.model.get('task_info_json').keys, parseInt(answer))
                ];

                // Deep comparison to check if givenAnswer matches any of the possible correct options.
                // By doing a deep comparison both primitive (text, image) and object data types (audio)
                // can be checked for equality.
                var isCorrectAnswer = _.any(
                    this.model.get('task_info_json').correct_answers,
                    (correctAnswer) => {
                        return _.isEqual(answer, correctAnswer);
                    }
                );

                // Create a DOM representation of the answers given by the student.
                var itemTemplate = this.renderItem(answer, index, true);

                if (isCorrectAnswer) {
                    itemTemplate.addClass(Styles['item--correct']);
                } else {
                    itemTemplate.addClass(Styles['item--incorrect']);
                }

                if (this.inputType === 'audio') {

                    // Append the template to the answer view
                    answerItem.$el.append(itemTemplate);
                } else {
                    answerItem += itemTemplate[0].outerHTML;
                }

            })
        }

        return answerItem;

    },

    /**
     * lockAnswer
     *
     * This function is triggered when the lock-answers event is sent from a
     * Presentation view. It will make sure students can't change their answer.
     * This is typically done when the slideState inside the presentation is 1 or 2,
     * meaning the teacher is showing the student's answers.
     *
     */
    lockAnswer() {

        // Stop listening to the backbone events in this view
        this.undelegateEvents();

        // Add lock-answer class to answer items
        this.$('.js-item').addClass(Styles['item--lock-answer']);

    },

    /**
     * unlockAnswer
     *
     * This function is triggered when the unlock-answers event is sent from a
     * Presentation view. It will make sure students chan fill an answer again.
     *
     */
    unlockAnswer() {

        // Listen to backbone events again
        this.delegateEvents();

        // Remove lock-answer class from answer items
        this.$('.js-item').removeClass(Styles['item--lock-answer']);

    },

    /**
     * forceRepaint
     *
     * This HACK forces webkit to repaint, which will resolve a bug in presentation mode
     * where the image is placed in its container without being fully loaded.
     *
     */
    forceRepaint() {

        this.el.style.display = 'none';

        /* jshint ignore:start */
        this.el.offsetHeight;
        /* jshint ignore:end */
        this.el.style.display = '';

        // For reference to this hack:
        // jscs:disable maximumLineLength
        //  https://stackoverflow.com/questions/3485365/how-can-i-force-webkit-to-redraw-repaint-to-propagate-style-changes#answer-3485654
        // jscs:enable maximumLineLength
    },

});
