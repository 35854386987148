import Template from './Template30.hbs';
import WordSelection from 'views/components/taskGroups/tasks/wordSelection/WordSelection';
import FragmentTemplate from 'views/components/taskGroups/tasks/wordSelection/Fragment.hbs';
import QuickSelect from 'views/components/quickSelect/QuickSelect';
import GapTemplate from 'views/components/taskGroups/tasks/wordSelection/Gap.hbs';
import FragmentTooltipTemplate from 'views/components/taskGroups/tasks/wordSelection/FragmentTooltip.hbs';
import WordSelectionStyles from 'views/components/taskGroups/tasks/wordSelection/WordSelection.scss';

export default WordSelection.extend({

    events: {
        'click .js-click-fragment': 'onClickInlineFragment'
    },

    initialize(options) {

        _.bindAll(
            this,
            'lockAnswer'
        );

        this.taskView = options.task_view;
        this.isPresentation = options.isPresentation
        this.useDropdownInput = this.model.get('task_info_json').options &&
            this.model.get('task_info_json').options.useDropdownInput &&
            APPLICATION !== 'author'

        this.prepareData();

        this.renderTask()

    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles: WordSelectionStyles
        }));

        // Reduce gap templates and plain text into a single string and append
        // it to the DOM. This makes it possible for the browser to correct
        // with any invalid HTML automatically without having to normalize
        // and parse each individual fragment, which can result in errors.
        this.$el.append(this.fragments.reduce((content, fragment) => {
            if (fragment instanceof Object) {
                return content + GapTemplate({
                    fragmentID: fragment.id,
                    Styles: WordSelectionStyles
                });
            }
            return content + this.insertPlainText(fragment);
        }, ''))

        // Create fragment element for each fragment.
        // Add an highlight if it already has been selected.
        for (const fragment of this.fragments) {

            if (fragment instanceof Object === false) {
                continue
            }
            const sortedAnswers = this.sortAnswerVariants(fragment.answers)

            const existingAnswer = _.findWhere(this.JSONAnswer, {id: fragment.id})

            const gapElement = this.$(`.js-gap[data-gap-id=${fragment.id}]`)

            if (this.useDropdownInput) {
                this.addChildView(new QuickSelect({
                    items: [{
                        label: '\u00A0',
                        index: -1
                    }].concat(sortedAnswers.map((answer, index) => {
                        return {
                            label: answer.text,
                            index
                        }
                    })),
                    defaultValueIndex: _.findIndex(sortedAnswers, {text: existingAnswer?.answer.text}) + 1,
                    callback: this.onSelectDropdownFragment.bind(this, fragment.id)
                }),
                gapElement
                    .addClass([
                        WordSelectionStyles['dropdown-holder'],
                        'js-fragment'
                    ]).attr('data-fragment-index', fragment.id)
                )

                continue
            }

            gapElement.append(sortedAnswers.reduce((content, answer) => {
                let isSelected = false
                if (existingAnswer) {
                    isSelected = existingAnswer.answer.text === answer.text
                }
                // Get template we just added by searching for its data-gap-id and
                // append a fragment template to it.
                return content + FragmentTemplate({
                    Styles: WordSelectionStyles,
                    fragment: answer.text,
                    fragmentIndex: fragment.id,
                    fragmentStatus: isSelected ? WordSelectionStyles['fragment--selected'] : undefined
                })
            }, ''))

        }

    },

    /**
     * selectFragment
     *
     * When clicking on a fragment element react by adding/removing the selection status from the
     * response and toggling the correct styling.
     *
     * @param {Element} fragmentElement    interacted fragment element
     * @param {Number}  fragmentIndex      index of fragment/gap
     * @param {Number}  gapOptionIndex     index of option within gap.
     */
    selectFragment(fragmentElement, fragmentIndex, gapOptionIndex) {
        this.JSONAnswer = _.clone(this.JSONAnswer)

        const existingFragment = _.findWhere(this.fragments, {id: fragmentIndex})
        const answer = {
            id: fragmentIndex,
            answer: _.omit(
                existingFragment.answers[gapOptionIndex],
                'correct'
            )
        }

        const existingAnswerIndex = _.findIndex(this.JSONAnswer, {id: fragmentIndex})

        if (existingAnswerIndex >= 0) {
            if (gapOptionIndex === -1 || _.isEqual(this.JSONAnswer[existingAnswerIndex], answer)) {
                this.JSONAnswer.splice(existingAnswerIndex, 1)
            } else {
                this.JSONAnswer[existingAnswerIndex] = answer
                fragmentElement?.classList.add(WordSelectionStyles['fragment--selected'])
            }
        } else {
            this.JSONAnswer.push(answer)
            fragmentElement?.classList.add(WordSelectionStyles['fragment--selected'])
        }

        // Save the new answer.
        this.saveAnswer()
    },

    onClickInlineFragment(e) {
        this.stopAllEvents(e)
        // Refuse interaction when showing answers.
        if (!this.$el.hasClass('showAnswers')) {
            const fragmentElement = e.currentTarget
            const fragmentIndex = parseInt(fragmentElement.dataset.fragmentIndex)
            const gapElement = this.$('.js-gap[data-gap-id=' + fragmentIndex + ']')

            // Remove selection status styling from all fragments within the current gap.
            gapElement.find('.js-fragment').removeClass(WordSelectionStyles['fragment--selected'])

            const gapOptionIndex = gapElement.find('.js-fragment').index(fragmentElement)

            this.selectFragment(fragmentElement, fragmentIndex, gapOptionIndex)
        }
    },

    onSelectDropdownFragment(fragmentIndex, selectedItem) {
        if (!this.$el.hasClass('showAnswers')) {
            const gapOptionIndex = selectedItem.index
            this.selectFragment(undefined, fragmentIndex, gapOptionIndex)
        }
    },

    /**
     * showAnswer
     *
     * Show the correct answer to the task.
     */
    showAnswer() {

        for (const fragment of this.fragments) {

            if (fragment instanceof Object === false) {
                continue
            }

            const givenGapAnswer = _.findWhere(this.JSONAnswer, {id: fragment.id})

            const fragmentElements = this.$('.js-fragment[data-fragment-index=' + fragment.id + ']')

            _.each(fragmentElements, (fragmentElement, fragmentIndex) => {

                fragmentElement.classList.add(WordSelectionStyles['fragment--show-answer'])

                const gapAnswer = fragment.answers[
                    this.useDropdownInput ?
                        fragmentElement.querySelector('select').selectedIndex - 1 :
                        fragmentIndex
                ]

                if (
                    gapAnswer?.correct &&
                    givenGapAnswer && givenGapAnswer.answer.text === gapAnswer.text
                ) {
                    fragmentElement.classList.add(WordSelectionStyles['fragment--correct'])
                } else if (
                    (
                        // If the gapAnswer iteratee is correct
                        gapAnswer?.correct &&

                        // But the user hasn't filled an answer
                        !givenGapAnswer
                    ) ||
                    (
                        // Or if the gapAnswer iteratee is correct
                        gapAnswer?.correct &&

                        // And the user has filled a gap answer
                        givenGapAnswer &&

                        // But the answer of the user doesn't equal the correct answer
                        givenGapAnswer.answer.text !== gapAnswer.text
                    )
                ) {
                    if (APPLICATION === 'author') {
                        fragmentElement.classList.add(WordSelectionStyles['fragment--selected'])
                    } else {
                        fragmentElement.classList.add(WordSelectionStyles['fragment--missing-answer'])
                    }
                } else if (
                    gapAnswer === undefined || (
                        !gapAnswer.correct &&
                        givenGapAnswer &&
                        givenGapAnswer.answer.text === gapAnswer.text
                    )
                ) {
                    if (APPLICATION !== 'author') {
                        fragmentElement.classList.add(WordSelectionStyles['fragment--incorrect'])
                    }
                    if (this.useDropdownInput) {
                        fragmentElement.append($(FragmentTooltipTemplate({
                            Styles: WordSelectionStyles,
                            tooltipText: _.findWhere(fragment.answers, {correct: true})?.text
                        }))[0])
                    }
                }
            })

        }

    },

    showAuthorAnswer() {
        this.showAnswer()
    },

    /**
     * Sort fragments alphabetical or reverse alphabetical if a sequence mode option is set in task_info_json
     *
     * @param {Object} fragment     unsorted fragments
     * @returns {Object}            sorted fragments
     */
    sortAnswerVariants(fragment) {
        const sequenceMode = this.model.get('task_info_json').options.sequenceMode

        const isReverse = sequenceMode === 'alphabetical-reverse'
        if (isReverse || sequenceMode === 'alphabetical') {
            fragment.sort((a, b) => {
                const index = a.text.localeCompare(b.text, window.app_version.language, {sensitivity: 'base', numeric: true})
                return isReverse ? (0 - index) : index
            })
        }

        return fragment
    },

    /**
     * hideAnswer
     *
     * Hide the correct answer to the task.
     */
    hideAnswer() {
        this.$('.js-fragment').removeClass([
            WordSelectionStyles['fragment--show-answer'],
            WordSelectionStyles['fragment--correct'],
            WordSelectionStyles['fragment--missing-answer'],
            WordSelectionStyles['fragment--incorrect']
        ])
        if (this.useDropdownInput) {
            this.$('.js-answer-tooltip').remove()
        }
    },

    lockAnswer() {
        this.$('.js-fragment').addClass(WordSelectionStyles['fragment--locked'])
        this.undelegateEvents();
        if (this.useDropdownInput) {
            this.el.querySelectorAll('select')?.forEach(el => el.disabled = true)
        }
    },

    unlockAnswer() {
        this.$('.js-fragment').removeClass(WordSelectionStyles['fragment--locked'])
        this.delegateEvents();
        if (this.useDropdownInput) {
            this.el.querySelectorAll('select')?.forEach(el => el.disabled = false)
        }
    }

});
