import Styles from './List.scss';

import Template from './List.hbs';
import Button from 'views/components/button/Button';
import ListItem from 'views/components/combinedInputs/listItem/ListItem';
import EditColumnsModal from 'views/pages/words/editColumnsModal/EditColumnsModal';

export default BaseView.extend({

    /**
     * initialize a words list block
     *
     * @param {Object} options Options object
     *
     * @param {number} options.leftColumnHeaderLength The length of the left column header in percentage
     */
    initialize({
        leftColumnWidth = 50,
        leftItemCollectionKey,
        rightItemCollectionKey,
        noItemsText,
        deleteListItemCallback,
        editListItemCallback,
        parentElement,
    }) {

        this.parentElement = parentElement

        this.bindAll([
            'deleteFromList',
            'onClickEditColumns'
        ])

        this.leftItemCollectionKey = leftItemCollectionKey;
        this.rightItemCollectionKey = rightItemCollectionKey;
        this.leftColumnWidth = leftColumnWidth;

        this.editListItemCallback = editListItemCallback;
        this.deleteListItemCallback = deleteListItemCallback;

        this.setElement(Template({
            Styles,
            leftColumnName: this.model.get('metadata').left_column_name,
            rightColumnName: this.model.get('metadata').right_column_name,
            noItemsText
        }));

        this.$('.js-column-header-left').css({ width: leftColumnWidth + '%' });

        this.addChildView(
            new Button({
                icon: 'pencil',
                theme: 'outlined',
                callback: this.onClickEditColumns
            }),
            '.js-edit-columns'
        )

        this.renderInitialList();

        // format copied text
        this.addCopyListener()

    },

    renderInitialList() {
        this.collection.forEach(listItem => {
            this.renderListItem(listItem);
        });
        this.toggleEmptyListMessage();
    },

    renderListItem(newItem) {
        this.toggleEmptyListMessage();

        this.addChildView(
            new ListItem({
                leftItemContent: newItem.get(this.leftItemCollectionKey),
                rightItemContent: newItem.get(this.rightItemCollectionKey),
                leftItemWidth: this.leftColumnWidth,
                model: newItem,
                deleteListItemCallback: this.deleteFromList,
                editListItemCallback: this.editListItemCallback,
            }),
            '.js-list-body'
        );
    },

    deleteFromList(model) {

        this.deleteListItemCallback(model);
        this.toggleEmptyListMessage();
    },

    toggleEmptyListMessage() {
        const emptyListDiv = this.$('.js-no-list-items');

        if (this.collection.length) {
            emptyListDiv.hide();
        } else if (!this.collection.length) {
            emptyListDiv.show();
        }
    },

    /**
     * dis- or enable editing existing word pairs
     */
    toggleEditableStatus() {
        this.$el.toggleClass('is-not-editable')
        this.$('.js-edit-columns').toggle()
    },

    // Opens a modal to edit the names of the list columns
    onClickEditColumns() {
        Backbone.View.Components.modal.open(
            EditColumnsModal,
            {
                title: window.i18n.gettext('Edit column names'),
                model: this.model,
            }
        )

        // When column names are updated, also update the view
        this.model.once('change:metadata', () => {
            this.el.querySelector('.js-column-header-left').textContent = this.model.get('metadata').left_column_name
            this.el.querySelector('.js-column-header-right > span').textContent = this.model.get('metadata').right_column_name
            $('.js-combination-input-left input').attr('placeholder', this.model.get('metadata').left_column_name)
            $('.js-combination-input-right input').attr('placeholder', this.model.get('metadata').right_column_name)
        })
    },

    /**
     * when (part of) list is copied, add a tab character to
     * clipboard text between word pairs
     */
    addCopyListener() {
        this.el.addEventListener('copy', e => {

            let words = document.getSelection().toString()
                .split('\n')

            if (words.length % 2 !== 0) {
                return
            }

            // when a single word is copied, sometimes there is a new line
            // copied along. remove that
            words = words.filter(word => word)

            // raw copy from a learnbeat woordjes list gives
            // a string without tabs, with \n after every left and right word
            // for example, the following list:
            //
            //      verhaal     story
            //      tweeling	twins
            //
            // when copied and pasted gives
            //      'verhaal\nstory\ntweeling\ntwins'
            // this function returns it as two tab separated columns
            // that can be pasted in another woordjes list or excel:
            //      'verhaal\tstory\ntweeling\ttwins'

            const clipboardText = words.map((word, index) => {
                if ((index ) % 2 === 0) {
                    return `${word}\t`
                } else if (index === words.length - 1) {
                    return word
                }
                return `${word}\n`
            }).join('')

            e.clipboardData.setData('text/plain', clipboardText)
            e.preventDefault()
        })
    }

});
