<script>
    import Button from 'views/components/button/Button.svelte';
    import Chooser from 'views/components/chooser/Chooser.svelte';
    import Modal from 'views/components/modals/Modal.svelte';

    let modalIsOpen = true
    let chooser
    const list = Backbone.Collection.groups.map((groupModel) => {
        return {
            id: groupModel.id,
            label: groupModel.get('name'),
            isChecked: groupModel.get('is_favorite')
        }
    })

    function saveFavorites() {
        const groupIds = _.pluck(chooser.getSelection(), 'id')

        Backbone.Collection.groups.each((groupModel) => {
            groupModel.set('is_favorite', groupIds.includes(groupModel.id));
        })

        Backbone.Collection.groups.sort()
        modalIsOpen = false

        jQuery.post({
            url: '/groups/mark_favorite.json',
            data: { groupIds },
            success: () => {
                Backbone.history.loadUrl()
            }
        })
    }
</script>

<Modal
    title={window.i18n.gettext('Select favorite groups')}
    hasMargin={false}
    canBeClosedWithEscapeKey={true}
    bind:isOpen={modalIsOpen}
>
    <p class="explanation">{window.i18n.gettext('Favorite groups are shown on top.')}</p>

    <Chooser {list} isMultiSelect={true} bind:this={chooser}></Chooser>

    <svelte:fragment slot="buttons">
        <Button
            label={window.i18n.gettext('Cancel')}
            theme="secondary"
            callback={() => { modalIsOpen = false }}
        ></Button>
        <Button
            label={window.i18n.gettext('Save')}
            callback={saveFavorites}
        ></Button>
    </svelte:fragment>
</Modal>

<style lang="scss">
    .explanation {
        margin: 0 24px 12px 24px;
    }
</style>