import Template from './Outdent.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Outdent extends Button {

    // Built-in TinyMCE command to indent the current selection
    command = 'Outdent'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Decrease indention');
    }

}
