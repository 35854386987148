import ActivitiesCollection from 'collections/ActivitiesCollection';
import ContentLabelModel from './ContentLabelModel'
import SectionsCollection from 'collections/SectionsCollection';

export default class SectionModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'section'
        this.constructor.pluralType = 'sections'
    }

    initialize() {

        _.bindAll(
            this,
            'onActivityAdded'
        );

        // Push activities to the global collection (use push instead of add because order in global collection
        // does not matter, in addition of it saving a lot of CPU time)
        this.activities = new ActivitiesCollection(
            Backbone.Collection.activities.push(this.get('Activity'), {
                silent: true
            })
        )
        this.unset('Activity', {
            silent: true
        })

        this.set({
            path: 'sections',
        })

        if (this.id) {
            this.onChangeId();
        } else {
            this.on('change:id', this.onChangeId);
        }

        this.on('change:sequence', _.debounce(this.updateSequence))
    }

    url() {
        if (this.id) {
            return '/sections/backbone_backend/' + this.id + '.json';
        }
        return '/sections/backbone_backend.json'
    }

    /**
         * getParent
         *
         * Return parent model.
         *
         * @returns {ChapterModel}          parent chapter
         */
    getParent() {
        return this.getChapterModel();
    }

    /**
         * getChildren
         *
         * Return child collection in the order of content.
         *
         * @return {ActivitiesCollection}    activities connected to this section.
         */
    getChildren() {
        return this.activities;
    }

    /**
         * getSiblings
         *
         * Get collection of sibling models.
         *
         * @return {SectionsCollection}      All sections with the same parent chapter.
         */
    getSiblings() {
        return this.getParent()?.sections || new SectionsCollection()
    }

    getChapterModel() {
        return Backbone.Collection.chapters.get(this.get('chapter_id'))
    }

    getGroupModel() {
        return Backbone.Collection.groups.get(this.getChapterModel()?.get('group_id'))
    }

    getAllCrumblepathModels() {
        const groupModel = this.getGroupModel()
        const crumblepathModels = []
        crumblepathModels.push(groupModel.getCrumblepathModel())

        if (groupModel.get('layers') > 2) {
            crumblepathModels.push(this.getChapterModel().getCrumblepathModel())
        }

        if (groupModel.get('layers') > 1) {
            crumblepathModels.push(this.getCrumblepathModel())
        }

        return crumblepathModels
    }

    getCrumblepathModel() {

        const options = {
            label: this.get('name'),
            index: this.get('index'),
            level: 'sections',
            path_id: this.get('id')
        }

        if (ContentLabelModel.isNumberless(this)) {
            options.index = ''
        } else if (this.getGroupModel().get('layers') > 2) {
            options.index = this.getChapterModel().get('index') + '.' + this.get('index')
        }

        return new Backbone.Model(options)
    }

    getSourceId() {
        return this.get('source_section_id')
    }

    getName(type = 'show') {
        if (this.getGroupModel().get('layers') === 1) {
            switch (type) {
                case 'show':
                    return window.i18n.gettext('Learning material')
                case 'progress':
                    return window.i18n.gettext('Progress')
                case 'author':
                    return window.i18n.gettext('Author')
            }
        }
        return this.get('name')
    }

    addActivity(class_id) {
        $.get('/sections/add_activity/' + this.id + '/' + class_id + '.json', this.onActivityAdded);
    }

    onActivityAdded(response) {
        if (response.status === 'success') {
            Backbone.history.navigate('activities/edit/' + response.activity_id, {
                trigger: true
            });
        } else {
            Backbone.View.layout.openStatus(response.message);
        }
    }

    onChangeId() {
        this.activities.url = '/sections/get_activities/' + this.id + '.json';
        this.activities.parent_id = this.id;
    }

    updateSequence(model, newSequence) {
        let newIndex = newSequence + 1
        if (!this.get('numberless')) {
            const otherSections = this.getSiblings().filter((otherSection) => {
                return !otherSection.get('numberless') && otherSection.get('sequence') < newSequence
            })
            newIndex = otherSections.length + 1
        }
        model.set({
            index: newIndex,
        })
    }

    savePatchWithKeyToServer(key, value, callback) {
        return new Promise((resolve) => {
            // set model and save to server
            const payload = {
                [key]: value
            }

            this.save(
                payload,
                {
                    patch: true,
                    success: (model, response) => {
                        if (callback) {
                            callback()
                        }

                        resolve(response?.status)
                    }
                });
        })
    }

    isRootLayer() {
        return this.getGroupModel()?.get('layers') === 1
    }

    getPath(type, withoutId = false) {
        const base = `/sections/${type}`

        if (withoutId) {
            return base
        }
        return `${base}/${this.id}`
    }

    // Get the background image of the chapter or the course
    getBackgroundImage() {
        return this.getChapterModel()?.getBackgroundImage()
    }

    // Get a random header background image based on the course we are in
    getHeaderImage() {
        return this.getGroupModel()?.getHeaderImage()
    }

}
