import Styles from './Template36.scss';

import Template from './Template36.hbs';
import Checkbox from 'views/components/checkbox/Checkbox';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'saveAnswer',
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;

        this.renderTask()

    },

    renderTask() {
        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        // If task was made before the implementation of LEARN-641 (checklist template) it only supported one
        // checkbox, then convert the format so it is an array with a single item.
        const taskInfo = this.model.get('task_info_json')
        const list = taskInfo.list || [{
            id: 1,
            label: taskInfo.label
        }]

        // If the checkbox was checked before the implementation of LEARN-641, convert it to a list format of IDs,
        // were each ID is linked to a checkbox in the checklist, where the already given response is assumed to be
        // for the first item in the list.
        const jsonAnswer = this.parseResponse(this.taskView.response.get('json_answer'), list)

        // Create a checklist item for each item in the list.
        list.forEach((item) => {
            this.addChildView(new Checkbox({
                // Check item if its ID exists in response list.
                // Cast to string due to how response is returned by the backend.
                isChecked: jsonAnswer.includes(item.id.toString()),
                value: item.id,
                label: item.label,
                callback: this.saveAnswer
            }), this.$el)
        })

    },

    /**
         * If the checkbox was checked before the implementation of LEARN-641, convert it to a list format of IDs,
         * were each ID is linked to a checkbox in the checklist, where the already given response is assumed to be
         * for the first item in the list.
         *
         * @param {Object} response     json_answer object
         * @param {Array} list          List of items on the checklist
         * @returns {Array}             Reformatted response data
         */
    parseResponse(response, list) {
        // If the checkbox was checked before the implementation of LEARN-641, convert it to a list format of IDs,
        // were each ID is linked to a checkbox in the checklist, where the already given response is assumed to be
        // for the first item in the list.
        if ((response | 0) === 1) {
            return [_.first(list).id.toString()]
        }
        if (Array.isArray(response) === false) {
            return []
        }
        return response
    },

    /**
         * showAnswer
         *
         * Show the correct answer to the task.
         */
    showAnswer() {},

    showAuthorAnswer() {},

    /**
         * hideAnswer
         *
         * Hide the correct answer to the task.
         */
    hideAnswer() {},

    /**
         * getCorrectAnswer
         *
         * Generate summary of the correct answer to use above the list of student answers.
         *
         * @return {string} HTML blob to be inserted above the list of student answers.
         */
    getCorrectAnswer() {
        return this.model.get('task_info_json').label;
    },

    /**
         * getStudentAnswer
         *
         * Generate summary of the answer student has given for use in the list of student answers.
         *
         * @param  {Object} responseModel Student response data
         * @return {BaseView}             Small view containing checkboxes representing student's answer
         */
    getStudentAnswer(responseModel) {
        // Return no answer given if answer is undefined or an empty array.
        if (_.isEmpty(responseModel.get('json_answer'))) {
            return false
        }

        const taskInfo = this.model.get('task_info_json')
        const list = taskInfo.list || [{
            id: 1,
            label: taskInfo.label
        }]
        const jsonAnswer = this.parseResponse(responseModel.get('json_answer'), list)

        // Create ad-hoc view instance to contain all checkboxes for representing the student answer.
        return this.addChildView(new(BaseView.extend({
            tagName: 'div',
            initialize() {
                list.forEach((item) => {
                    this.addChildView(new Checkbox({
                        // Check item if its ID exists in response list.
                        // Cast to string due to how response is returned by the backend.
                        isChecked: jsonAnswer.includes(item.id.toString()),
                        label: item.label,
                        isDisabled: true
                    }), this.$el)
                })
            }
        })))
    },

    /**
         * saveAnswer
         *
         * Convert this.JSONAnswer to a simple object and pass it to the saveResponse method of the parent taskView
         * to patch the answer to the server.
         *
         */
    saveAnswer() {
        this.taskView.saveResponse(_.pluck(this.el.querySelectorAll('input:checked'), 'value'))
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {
        _.invoke(
            this.getChildViewsOfInstance(Checkbox),
            'setDisabled',
            true
        );
    },

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {
        _.invoke(
            this.getChildViewsOfInstance(Checkbox),
            'setDisabled',
            false
        );
    }

});
