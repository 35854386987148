var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"work-on-answer") : stack1), depth0))
    + "\">\n	<span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"work-on-answer__column") : stack1), depth0))
    + "\">\n		"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\n	</span>\n	<span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"work-on-answer__column") : stack1), depth0))
    + "\">\n		"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"items") || (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"items","hash":{},"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":13}}}) : helper))) != null ? stack1 : "")
    + "\n	</span>\n</span>\n";
},"useData":true});