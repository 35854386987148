import Template from './Aside.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import AsideTemplate from 'views/components/wysiwyg/elements/aside.hbs';

export default class Aside extends Button {

    // Custom command
    command = 'insertAside'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Aside');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        const customEl = editorObject.customEl
        const customSH = editorObject.customSH

        // Add the command for inserting an aside.
        editor.addCommand(this.command, () => {
            // Pass the template and editor with it as a blockElement.
            // Note that AsideTemplate doesn't have a newline at end of file.
            // If it did, this newline would be inserted into the editor,
            // adding an unwanted empty line after the inserted content.
            customEl.blockElement(AsideTemplate, editor);
        });

        // Create the state handler for this block element
        customSH.blockElementHandler(
            // Listen to command: insertAside
            this.command,
            // Use the AsideTemplate template to indentify the block
            AsideTemplate(),
            // Pass the editor with it
            editor
        );
    }
}
