var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-label") : stack1), depth0))
    + " js-missing-task\" data-task-group-index="
    + alias1(((helper = (helper = lookupProperty(helpers,"taskGroupIndex") || (depth0 != null ? lookupProperty(depth0,"taskGroupIndex") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"taskGroupIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":73},"end":{"line":1,"column":91}}}) : helper)))
    + ">"
    + alias1(((helper = (helper = lookupProperty(helpers,"taskGroupIndex") || (depth0 != null ? lookupProperty(depth0,"taskGroupIndex") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"taskGroupIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":92},"end":{"line":1,"column":110}}}) : helper)))
    + alias1(((helper = (helper = lookupProperty(helpers,"taskIndex") || (depth0 != null ? lookupProperty(depth0,"taskIndex") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"taskIndex","hash":{},"data":data,"loc":{"start":{"line":1,"column":110},"end":{"line":1,"column":123}}}) : helper)))
    + "</div>\n";
},"useData":true});