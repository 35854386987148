import Styles from './DropdownItem.scss';

import Template from './DropdownItem.hbs';
import UploadModel from 'models/UploadModel'
import ToggleSwitch from 'views/components/toggleSwitch/ToggleSwitch.svelte'

export default class DropdownItem extends BaseView {

    get events() {
        return {
            click: 'onClickItem'
        }
    }

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options as defined by parent view
     */
    initialize(options) {

        this.callback = options.callback;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            label: options.label,
            icon: options.icon,
            hasToggle: options.hasToggle,
            identifier: options.identifier,
            hasSubItems: !!options.subItems,
            isSubItem: options.isSubItem
        }));

        if (options.subItems) {
            for (const subItem of options.subItems) {
                subItem.isSubItem = true
                this.dropdownItem = this.addChildView(
                    new DropdownItem(subItem),
                    '.js-sub-items'
                )
            }
        }

        if (options.uploadDetails) {
            this.addUploadModel(options.uploadDetails)
        }

        if (options.hasToggle) {

            this.toggleSwitch = this.addSvelteChildView('.js-toggle-holder', ToggleSwitch, {
                isChecked: options.toggleState,
                callback: this.callback
            })

        }
    }

    onClickItem(e) {
        if (this.toggleSwitch) {
            // Redirect click to the toggle switch
            this.toggleSwitch.toggleState()
            e.stopPropagation()
        } else if (this.dropdownItem) {
            // Let the item expand
            e.stopPropagation()
        } else if (this.uploadModel) {
            // Let the upload model handle the callback
            return
        } else if (this.callback) {
            this.callback();
        }
    }

    addUploadModel(uploadDetails) {
        uploadDetails.element = this.$el
        this.uploadModel = new UploadModel(uploadDetails);
        this.uploadModel.on('change:url', (uploadModel) => {
            this.callback(uploadModel)
        })
    }

}
