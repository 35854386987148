<script>
    import Icon from '../icon/Icon.svelte';

    export let items = []
    export let defaultValue = null
    export let label = ''
    export let placeholder = ''
    export let inline = false
    export let disabled = false
    export let callback = () => {}

    export function getValue() {
        // If selected value is not contained in the list or is the placeholder, {item: {value: undefined}} is returned.
        return {
            item: selectedItem
        }
    }

    export function setValue(value) {
        const item = items.find(item => item.value === value)
        if (item) {
            selectedItem = item
        } else if (placeholder) {
            selectedItem = {value: undefined}
        }
    }

    const id = _.uniqueId()
    const isDefaultValueValid = items.some((item) => item.value === defaultValue)
    let selectedItem

    // If no value is preselected, make sure a placeholder is present
    if (!placeholder && !isDefaultValueValid) {
        placeholder = window.i18n.gettext('None')
    }

    // Set the default if given
    if (isDefaultValueValid) {
        setValue(defaultValue)
    }

    function onChange() {
        if (callback) {
            callback(selectedItem)
        }
    }
</script>

<div class="quick-select" class:inline class:disabled>
    {#if label}
        <label for={id} class="label">{label}</label>
    {/if}
    <div class="select-container" component="quick-select-select-container">
        <select
            {id}
            bind:value={selectedItem}
            on:change={onChange}
            {disabled}
            title={selectedItem?.label}
        >
            {#if placeholder}
                <option value={{value: undefined}} disabled="true" selected={!isDefaultValueValid}>
                    {placeholder}
                </option>
            {/if}
            {#each items as item}
                <option value={item} disabled={item.disabled} selected={item.selected}>{item.label}</option>
            {/each}
        </select>
        <div class="value" inert>
            {selectedItem?.label || placeholder || ''}
        </div>
        <div class="arrow" inert>
            <Icon name="arrow-down"></Icon>
        </div>
    </div>
</div>

<style lang="scss">
    @import 'src/styling/globals.scss';

    .quick-select {
        display: flex;
        flex-direction: column;

        &.inline {
            display: inline-flex;
            align-items: flex-start;
        }

        &.disabled {
            opacity: 0.8;
        }

        .label {
            @include label-text;
            color: $blue;
            margin-bottom: 8px;
        }

        .select-container {
            @include text-input;

            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            font-size: 14px;

            select {
                position: absolute;
                inset: 0;
                padding-left: 8px;
                padding-right: 8px;
                opacity: 0;
                appearance: none;
                cursor: pointer;

                &:disabled {
                    cursor: not-allowed;
                }
            }

            .arrow {
                display: flex;
                width: 16px;
                height: 16px;
                fill: currentColor;
            }
        }
    }
</style>
