var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support-category--link") : stack1), depth0))
    + " js-support-page\">\n            <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support-category--text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Do you want to find out on your own?",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":37,"column":66},"end":{"line":37,"column":111}}}))
    + "</span>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support-category--outer") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support-category--text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"How can we help you?",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":57},"end":{"line":2,"column":86}}}))
    + "</div>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__columns") : stack1), depth0))
    + "\">\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column") : stack1), depth0))
    + " js-support-button\" data-type=\"problem\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-icon") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"warning",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":37}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-label-outer") : stack1), depth0))
    + " js-total-label\">\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-sublabel") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"I have",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":10,"column":71},"end":{"line":10,"column":86}}}))
    + "</div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-label") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"a problem",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":11,"column":68},"end":{"line":11,"column":86}}}))
    + "</div>\n            </div>\n        </button>\n\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column") : stack1), depth0))
    + " js-support-button\" data-type=\"content-mistake\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-icon") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"content",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":37}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-label-outer") : stack1), depth0))
    + " js-total-label\">\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-sublabel") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"I see",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":20,"column":71},"end":{"line":20,"column":85}}}))
    + "</div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-label") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"a content mistake",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":21,"column":68},"end":{"line":21,"column":94}}}))
    + "</div>\n            </div>\n        </button>\n\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column") : stack1), depth0))
    + " js-support-button\" data-type=\"idea\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-icon") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":39}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-label-outer") : stack1), depth0))
    + " js-total-label\">\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-sublabel") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"I have",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":30,"column":71},"end":{"line":30,"column":86}}}))
    + "</div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-support__column-label") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"an idea",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":31,"column":68},"end":{"line":31,"column":84}}}))
    + "</div>\n            </div>\n        </button>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"linkToSupportPage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});