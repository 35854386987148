import Styles from './FixEmail.scss';

import Template from './FixEmail.hbs';
import Util from 'util/util';
import EmailInput from 'views/components/quickInput/emailInput/EmailInput';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onClickSendLogin'
        );

        var strippedEmail = Util.stripTags(options.email);

        this.studentId = options.studentId || '';
        this.groupId = options.groupId;

        this.parent = options.parent;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            email: strippedEmail,
            isTeacherFixingStudent: !!this.studentId
        }));

        // Add a childview
        this.emailInput = this.addChildView(new EmailInput({
            defaultValue: strippedEmail,
            autoFocus: true,
            emailCallback: this.onClickSendLogin
        }), '.js-input-field');

    },

    addButtons() {
        var buttons = {};

        buttons[window.i18n.gettext('Cancel')] = {
            callback: Backbone.View.Components.modal.close,
            theme: 'secondary'
        };

        buttons[window.i18n.gettext('Resend login information')] = {
            // Set callback to the onClickSendLogin method within the subview
            callback: this.emailInput.triggerInputCallback,
        };

        Backbone.View.Components.modal.addButtons(buttons, true);
    },

    onClickSendLogin(input) {

        var data = {
            email: input,
            resend_credentials: true
        };

        if (this.studentId) {

            // Add student id attribute to data object, only if there is a student id
            // Otherwise this means the user is trying to fix his/her own email
            data.studentId = this.studentId;
        }

        // Send the email to the backend
        $.post(
            '/users/save_email.json',
            data,
            _.bind(function(data) {

                if (data && data.status === 'success') {

                    // If there is no student id nor group id passed
                    if (!this.studentId && !this.groupId) {

                        // This means the user is fixing his own email
                        // Therefore set change his email in the user model
                        Backbone.Model.user.set({
                            email: input
                        });

                        // Else this is a teacher who holds a collection of
                        // students with no credentials.
                    } else {

                        //  Remove this student through his id.
                        this.collection.remove(this.studentId);

                        // Destroy related pending action view
                        this.parent.destroy();

                    }

                    // Open thank you succes message
                    Backbone.View.layout.openStatus(window.i18n.gettext('Thanks!'), 'success');

                    // Close modal
                    Backbone.View.Components.modal.close();
                } else {

                    if (data.message === 'email already in use') {
                        Backbone.View.layout.openStatus(window.i18n.gettext(
                            'This email address is already in use, please enter an other email address.'
                        ));
                    } else if (data.message === 'email already valid') {
                        Backbone.View.layout.openStatus(window.i18n.gettext(
                            'This student\'s email has already been changed.'
                        ), 'warning');

                        // If this modal is used by a teacher with a collection of
                        // students without credentials
                        if (this.collection && this.studentId) {

                            // Remove the student from the collection so the
                            // related pending action will be removed
                            this.collection.remove(this.studentId);
                        }
                        Backbone.View.Components.modal.close();
                    } else {
                        Backbone.View.layout.openStatus(window.i18n.gettext(
                            'Something went wrong with saving your email, please try again'
                        ));
                    }
                }
            }, this)
        );

    }

});
