import Styles from './Source35.scss';

import Template from './Source35.hbs';
import AkitUtil from 'views/components/taskGroups/tasks/template35/Util';

export default BaseView.extend({

    initialize() {

        AkitUtil.initAlgebrakit();

        this.setElement(Template({
            exerciseHtml: this.model.get('source').exerciseHtml,
            Styles
        }));
    }

});
