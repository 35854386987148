import Template from './Indent.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Indent extends Button {

    // Built-in TinyMCE command to indent the current selection
    command = 'Indent'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Increase indention');
    }

}
