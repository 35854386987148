import SubjectsCollection from 'collections/SubjectsCollection';

export default class CourseModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'course'
    }

    getBackgroundImage() {
        return this.get('image')
    }

    // Get a random header image
    getHeaderImage() {
        return _.sample(this.get('header_images'))
    }

    async getSubjects() {

        // If subjects for this course were already loaded, return them
        if (this.subjects) {
            return this.subjects
        }

        // Otherwise, request subjects from the server and then return them
        await $.get({
            url: '/courses/' + this.id + '/subjects.json',
            success: (response) => {
                this.subjects = new SubjectsCollection(response)
            }
        })
        return this.subjects
    }

}
