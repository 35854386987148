import Styles from './Template38.scss';

import Template from './Template38.hbs';
import Hedy from './hedy/Hedy'

export default BaseView.extend({

    events: {
        editorChange: 'onEditorChange'
    },

    initialize(options) {

        _.bindAll(
            this,
            'lockAnswer',
            'unlockAnswer',
            'onEditorChange',
            'saveResponse'
        )

        this.taskView = options.task_view;
        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {
        this.setElement(Template({
            Styles
        }))

        // When author, display the model answer when present, otherwise the task
        if (APPLICATION === 'author') {
            this.addChildView(new Hedy({
                level: this.model.get('task_info_json').level,
                fullWidth: this.model.get('task_info_json').fullWidth,
                program: this.model.get('task_info_json').answer || this.model.get('task_info_json').program
            }), '.js-answer-container')
            return
        }

        // If there is already a response for this task -> load as program, otherwise get the task starting program
        let program = this.model.get('task_info_json').program
        if (this.taskView.response.get('json_answer')) {
            program = this.taskView.response.get('json_answer')
        }

        this.hedyView = this.addChildView(new Hedy({
            level: this.model.get('task_info_json').level,
            fullWidth: this.model.get('task_info_json').fullWidth,
            program,
            showRun: true
        }), '.js-answer-container')

        // Nice to have: Implement a 'reset' button to reset the code to the starting program of the task

        this.listenTo(this.hedyView, 'codeEditorChange', () => {
            this.onEditorChange()
        })

        this.listenTo(this.hedyView, 'programExecuted', () => {
            this.onProgramExecuted()
        })
    },

    lockAnswer() {
        this.hedyView.iframe.postMessage({
            mode: 'read'
        }, '*');
    },

    unlockAnswer() {
        this.hedyView.iframe.postMessage({
            mode: 'edit'
        }, '*');
    },

    showAnswer() {
        if (!this.modelAnswerView) {
            this.modelAnswerView = this.addChildView(new Hedy({
                level: this.model.get('task_info_json').level,
                program: this.model.get('task_info_json').answer,
                readOnly: true
            }), '.js-model-answer')
        }
        this.$('.js-model-answer-container').show();
    },

    showAuthorAnswer() {},

    hideAnswer() {
        this.$('.js-model-answer-container').hide();
    },

    getCorrectAnswer() {
        return new Hedy({
            level: this.model.get('task_info_json').level,
            program: this.model.get('task_info_json').answer,
            showRun: true
        })
    },

    getStudentAnswer(responseModel) {
        const response = responseModel.get('json_answer')
        if (response) {
            return new Hedy({
                level: this.model.get('task_info_json').level,
                program: response,
                showRun: true
            })
        }
        return ''
    },

    onEditorChange() {
        clearTimeout(this.pendingSaveTimeout);
        this.pendingSaveTimeout = setTimeout(this.saveResponse, 2000);
    },

    onProgramExecuted() {
        this.saveResponse()
    },

    saveResponse() {

        clearTimeout(this.pendingSaveTimeout);

        this.taskView.saveResponse(this.hedyView.program);
    },

});
