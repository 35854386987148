var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"answers") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":248}}})) != null ? stack1 : "")
    + "\">\n\n        <td class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row__column") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"isImage") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "        </td>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"answers") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(16, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "\n        <td></td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"answers") : depths[1]),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":3,"column":75},"end":{"line":3,"column":101}}}),lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"correctAnswers") : depths[1]),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":3,"column":102},"end":{"line":3,"column":135}}}),{"name":"isEqual","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":64},"end":{"line":3,"column":241}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row--correct") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row--incorrect") : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"ItemStyles") : depths[1])) != null ? lookupProperty(stack1,"media") : stack1), depth0))
    + "\">\n                    <img src=\"/edu_files/open/"
    + alias2(alias1(depth0, depth0))
    + "\">\n                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"answers") : depths[1]),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":16,"column":18},"end":{"line":16,"column":44}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row__column") : stack1), depth0))
    + "\">\n                    "
    + ((stack1 = lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"answers") : depths[1]),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":50}}})) != null ? stack1 : "")
    + "\n                </td>\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row__column") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row__column--neutral") : stack1), depth0))
    + "\">\n                    "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Not filled",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":39}}}))
    + "\n                </td>\n";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"correctAnswers") : depths[1]),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":51}}}),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":34,"column":19}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"answer-table__row__column") : stack1), depth0))
    + "\">\n                    "
    + ((stack1 = lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"correctAnswers") : depths[1]),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":57}}})) != null ? stack1 : "")
    + "\n                </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-table") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"options") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":39,"column":13}}})) != null ? stack1 : "")
    + "</table>\n";
},"useData":true,"useDepths":true});