import Styles from './StatusMessage.scss';

import Template from './StatusMessage.hbs';
export default class StatusMessage extends BaseView {

    /**
     * The status message will show a message at the buttom of the
     * screen, telling a message to the user. For example when things
     * went wrong or correct.
     *
     * @param {Object} {
     *     message {String} mesasge for the user,
     *     status {String} type/priority of the message can be:
     *                      'error', 'success', 'offline', 'warning', 'info', 'videoTour', 'loading'
     *     extraOptions {Object} extra options to pass, will be explained in initialize method
     * }
     */
    initialize({
        message,
        status,
        extraOptions
    }) {

        _.bindAll(this,
            'openStatus',
            'onMessageClosed',
            'closeMessage',
            'setProgress'
        );

        this.message = message;
        this.status = status;

        // Possible extra options:
        // - noHide:            if true, keep showing the status message until the user closes it
        // - elementCallback:   a function to run when the user clicks on the status message (default nothing)
        // - buttonCallback:    a function to run when the user clicks on the button (default close)
        // - mustReappear:      if true, show the message again after it was interrupted by another message
        // - hasProgress        if true, show a progress bar filled by the setProgress function
        this.extraOptions = extraOptions || {};

        const iconMatcher = {
            error: 'alert',
            success: 'checkmark-circle-fill',
            offline: 'autorenew',
            warning: 'warning-fill',
            info: 'info-circle',
            videoTour: 'source-13',
            loading: 'spinner'
        };

        // set the icon for the button
        const button = this.status === 'videoTour' || this.extraOptions.hasArrowIcon ? 'arrow-right' : 'cross';

        this.setElement(Template({
            Styles,
            message: this.message,
            statusClass: Styles[`is-${this.status}`],
            icon: iconMatcher[this.status],
            button,
            noHide: this.extraOptions.noHide,
            isClickable: !!this.extraOptions.elementCallback,
            hasProgress: this.extraOptions.hasProgress
        }));

        // TODO why is this being called before window.app is ready?
        if (window.app) {
            window.statsTracker.trackEvent(
                window.app.controller.activePath.join('/'),
                `statusMessage: ${this.status}: ${this.message}`
            );
        }

        // Add click listeners
        if (this.extraOptions.elementCallback) {
            this.$el.on('click', this.extraOptions.elementCallback);
        }

        this.$('.js-button').on(
            'click',
            (this.extraOptions.buttonCallback)
                ? this.extraOptions.buttonCallback
                : this.closeMessage
        );

        this.openAnimation = TweenMax.to(
            this.$el, {
                ease: 'back.out',
                duration: 0.35,
                y: '0px',
                paused: true,
                onReverseComplete: this.onMessageClosed,
                onComplete: () => {
                    // Set transform to a percentage again to avoid resizing bugs
                    this.el.style.transform = 'translateX(-50%)'
                }
            }
        );

        this.openStatus();
    }

    openStatus() {

        this.openAnimation.play();

        // Close the message after 4 seconds, except when no hide is present
        if (!this.extraOptions.noHide) {

            const longerDurationMessageTypes = [
                'error',
                'warning'
            ].includes(
                this.status
            );

            this.closeTimeout = setTimeout(
                _.bind(this.closeMessage, this),
                longerDurationMessageTypes ? 6500 : 4500
            );
        }
    }

    /**
     * closeMessage
     *
     * Close status message with an fancy animation.
     *
     * @param  {event} e    click event (optional)
     */
    closeMessage(e) {

        // If closeMessage is called by clicking on js-icon-close,
        // prevent event propagation from external click handlers.
        if (e) {
            this.stopAllEvents(e);
        }

        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }

        this.openAnimation.reverse();
    }

    // The animation for closing has finished
    onMessageClosed() {

        // Tell the layout that the status message has closed, so it can decide if something else needs to be shown
        Backbone.View.layout.trigger('statusMessageClosed')
        Backbone.View.layout.unregisterAndDestroyChildView(this)
    }

    // Set the progress bar to a given percentage
    setProgress(percentage) {
        const progressBar = this.el.querySelector('.js-progress')
        if (progressBar) {
            const x = -(100 - Math.round(percentage))
            progressBar.style.transform = 'translateX(' + x + '%)'
        }
    }

    // Update the text to a given message
    setMessage(message = '') {
        this.$('.js-text').text(message)
    }
}
