var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"all-correct") : stack1), depth0))
    + "\">\n                    <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"allCorrect") : depth0), depth0))
    + "</span>\n                    <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"words practiced",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":11,"column":50},"end":{"line":11,"column":74}}}))
    + "</span>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"chart-row") : stack1), depth0))
    + "\" id=\"firstTry\">\n                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"donut",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":39}}})) != null ? stack1 : "")
    + "\n                    <div>\n                        <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firstTry") : depth0), depth0))
    + "</span>\n                        <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"correct in 1 attempt",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":18,"column":54},"end":{"line":18,"column":83}}}))
    + "</span>\n                    </div>\n                </div>\n\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"chart-row") : stack1), depth0))
    + "\" id=\"secondTry\">\n                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"donut",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":39}}})) != null ? stack1 : "")
    + "\n                    <div>\n                        <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"secondTry") : depth0), depth0))
    + "</span>\n                        <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"correct in 2 attempts",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":26,"column":54},"end":{"line":26,"column":84}}}))
    + "</span>\n                    </div>\n                </div>\n\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"chart-row") : stack1), depth0))
    + "\" id=\"moreThanTwo\">\n                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"donut",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":39}}})) != null ? stack1 : "")
    + "\n                    <div>\n                        <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"moreThanTwo") : depth0), depth0))
    + "</span>\n                        <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"more than 2 attemps needed",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":34,"column":54},"end":{"line":34,"column":89}}}))
    + "</span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"results") : stack1), depth0))
    + "\">\n\n    <h2>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"You've finished all the words, well done!",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":58}}}))
    + "</h2>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"results__inner") : stack1), depth0))
    + "\">\n        <img src=\"https://static.learnbeat.nl/frontend-images/training-result.svg\">\n        <div class='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"results__text") : stack1), depth0))
    + "'>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"allCorrect") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"close-button-container") : stack1), depth0))
    + " js-close-button\"></div>\n</div>\n";
},"useData":true});