import Styles from './List.scss';

import Template from './List.hbs';
import CombinedInputs from 'views/components/combinedInputs/CombinedInputs';
import List from 'views/pages/words/list/List';
import PracticeCards from 'views/pages/words/practiceCards/PracticeCards';
import WordsListModel from 'models/WordsListModel';
import Chooser from 'views/components/chooser/Chooser';
import ShareListModal from 'views/pages/words/shareListModal/ShareListModal';
import SetListNameModal from 'views/pages/words/setListNameModal/SetListNameModal';
import PasteListModal from 'views/pages/words/pasteListModal/PasteListModal';
import Util from 'util/util';
import HeroButton from 'views/components/heroButton/HeroButton';
import HeroDropdown from 'views/components/heroButton/heroDropdown/HeroDropdown';
import Button from 'views/components/button/Button';
import Spinner from 'views/components/spinner/Spinner';

export default class WordsList extends BaseView {

    initialize({
        params,
        activityModel,
        isCalledFromAuthor = false
    }) {

        _.bindAll(
            this,
            'addToCollection',
            'addToList',
            'changeWordPair',
            'deleteFromCollection',
            'deleteList',
            'editItem',
            'handlePaste',
            'batchImportWords',
            'updateEditedListItem',
            'validationCallback',
        )

        this.setElement(Template({ Styles }))

        // Distinguish the two training types: as activity and as a standalone
        this.isActivity = !!activityModel
        this.activityModel = activityModel

        // Distinguish between the author (start in edit mode) and learning material (start in view mode) mode
        this.isCalledFromAuthor = isCalledFromAuthor;

        // Set the input width. The headers will scale along with the inputs
        this.leftInputWidth = ISMOBILE ? 50 : 40;

        // Words as activity has an ActivityModel, read the list id from that model if it is an activity.
        // Else retrieve the id from the URL params
        this.listId = this.isActivity ?
            this.activityModel.get('training_list_id') :
            parseInt(params[0])

        if (Number.isNaN(this.listId)) {
            return;
        }

        const spinner = this.addChildView(new Spinner(), '.js-spinner')

        this.model = new WordsListModel({
            id: this.listId,
            activityId: this.activityModel?.id,
        });
        this.request = this.model.fetch({
            success: () => { this.onLoadList() },
            error: (model, response) => {
                if (response.status === 403) {
                    Backbone.history.navigate('/words/home', { trigger: true })
                }
            },
            complete: () => { spinner?.destroy() }
        });

        /**
         * block saving words per word if this.blockSavingWordsServerSide = true
         * this.prevents sending a POST request for every word when a list of words
         * is pasted (which triggers backbone "add" event). pasted words are saved
         * seperately to the backend
         */
        this.blockSavingWordsServerSide = false;
        this.blockPatchingWordsServerSide = false;

        // Clear the header. Do not do this in author because author takes care of this
        if (!this.isCalledFromAuthor) {
            Backbone.View.header.setTitle()
            Backbone.View.header.clearButtons()
            Backbone.View.header.setCrumblepath()
        }

        // prevent rendering double center-panel in Author view!
        if (this.isCalledFromAuthor) {
            this.el.classList.remove('center-panel')
        }
    }

    onDestroy() {
        this.request?.abort()
    }

    /**
     * switch from "view" mode to "edit" mode, or vice versa
     *
     * @param {boolean} isInitial if called when page is loded
     */
    toggleViewMode(isInitial = false) {

        if (!isInitial) {

            /**
             * In Author, do not switch mode "in page", but navigate to learning material
             */
            if (this.isActivity && !this.isViewMode) {
                Backbone.history.navigate('/activities/show/' + this.activityModel.id, { trigger: true })
                return
            }

            this.isViewMode = !this.isViewMode

            this.listView.toggleEditableStatus()

            window.scrollTo({ top: 0, behavior: 'instant' })
        }

        this.practiceCardsView.$el.toggle()
        this.pasteListButton?.$el.toggle()
        this.editListButton?.$el.toggle()
        this.toggleCombinedInputs()
        this.toggleSaveListButton()
    }

    onLoadList() {

        // The name comes either from the activity model, or from the list itself
        const name = this.isActivity ? this.activityModel.get('name') : this.model.get('metadata').name

        this.canEditList = this.model.canBeEdited();

        // if called from author, and not owner, hide the "paste list" hero button
        if (this.isCalledFromAuthor && !this.canEditList) {
            document.querySelector('.js-paste-training-list').style.display = 'none'
        }

        this.mountComponents();

        // decide if training should be opened in "view" or "edit" mode
        this.setInitialMode();

        // Set crumblepath and title. Do not do this in author, because author takes care of this
        if (!this.isCalledFromAuthor) {
            this.setCorrespondingCrumblePath();
            Backbone.View.header.setTitle(name)
        }

        this.model.get('pairs').on('add', this.addToList);
        this.model.get('pairs').on('change', this.changeWordPair);
        /**
         * add listener for enabled/disabled state of the start practice button,
         * if there are no pairs in the list, disable the button.
         */
        this.model.get('pairs').on(
            'remove add',
            this.disableSaveListButtonIfListHasNoPairs.bind(this)
        );

        // check if multiple choice card should be made unavailable
        this.setMultipleChoiceCardAvailabilty();
        this.disableSaveListButtonIfListHasNoPairs();

    }

    /**
     * Populate page from top to bottom with the training components
     */
    mountComponents() {

        const metadata = this.model.get('metadata')

        this.addHeroComponents();

        // Add the Practice cards variants: flash, multiple choice and open
        this.practiceCardsView = this.addChildView(
            new PracticeCards({
                callback: (type) => {
                    this.selectTrainingDirection(type);
                }
            }),
            '.js-practice-cards'
        )

        let canEdit = false
        if ((this.canEditList && !this.isActivity) || (this.canEditList && this.isCalledFromAuthor)) {
            canEdit = true
        }

        // Add the word pairs list
        this.listView = this.addChildView(
            new List({
                model: this.model,
                collection: this.model.get('pairs'),
                leftColumnWidth: this.leftInputWidth,
                leftItemCollectionKey: 'left_value',
                rightItemCollectionKey: 'right_value',
                noItemsText: canEdit ?
                    window.i18n.gettext('You haven\'t placed any items in this list yet. Type in the list or copy and paste a word list in the inputs below.') :
                    window.i18n.gettext('This list doesn\'t contain any words yet'),
                deleteListItemCallback: this.deleteFromCollection,
                editListItemCallback: this.editItem,
                combinedInputsView: this.combinedInputsView,
                parentElement: this.el,
            }),
            '.js-list'
        )

        // Add the inputs for adding a new word pair to the list
        this.combinedInputsView = this.addChildView(
            new CombinedInputs({
                leftInputWidth: this.leftInputWidth,
                leftInputPlaceholder: metadata.left_column_name,
                rightInputPlaceholder: metadata.right_column_name,
                rightSideIcon: 'enter',
                rightSideIconText: 'Enter',
                callback: this.addToCollection,
                validationCallback: this.validationCallback,
                pasteCallback: this.handlePaste,
            }),
            '.js-combined-inputs'
        )

        // Add a hint for how to add synonyms
        this.$('.js-synonyms').html(window.i18n.sprintf(
            window.i18n.gettext('Tip: you can add synonyms using the %s character.'),
            '<kbd>/</kbd>'
        ))

        this.saveListButtonView = this.addChildView(
            new Button({
                iconRight: 'arrow-right',
                label: window.i18n.gettext('Save list'),
                callback: () => this.toggleViewMode(),
            }),
            '.js-save'
        )

        /**
         * initially, the view starts in "view mode". Hide views and
         * change behaviour that are not needed in "view" mode
         */
        this.listView.toggleEditableStatus()
        this.toggleCombinedInputs()
        this.toggleSaveListButton()
    }

    /**
     * setInitialMode
     *
     * Set a variable two distinguish two page states: a "view" mode
     * and an "edit" mode
     */
    setInitialMode() {

        this.isViewMode = this.isActivity ?
            !this.canEditList || !this.isCalledFromAuthor :
            !this.canEditList || this.model.get('pairs').length > 0

        if (!this.isViewMode) {
            this.toggleViewMode(true)
        }

        if (!this.isViewMode) {
            // make list items editable in "edit" mode
            this.listView.toggleEditableStatus()
        }

        if (
            (this.isViewMode && !this.model.get('pairs').length) ||
            (this.isCalledFromAuthor && !this.canEditList)
        ) {
            this.practiceCardsView.$el.hide();
        }
    }

    /**
     * Enable or disable adding new word pairs through
     * the combined inputs component
     *
     * @param {boolean} shouldToggleHiddenState
     * if hidden, should be made visible. if false, a lowered opacity state will be toggled
     *
     * @return {void}
     */
    toggleCombinedInputs(shouldToggleHiddenState = true) {
        if (shouldToggleHiddenState) {
            return this.combinedInputsView.$el.toggle()
        }

    }

    toggleSaveListButton() {
        this.saveListButtonView.$el.toggle()
        this.$('.js-synonyms').toggle()
    }

    /**
     * setCorrespondingCrumlePath
     *
     * Set corresponding crumble path depending on if the training is
     * of type standalone or activity
     */
    setCorrespondingCrumblePath() {
        if (this.isActivity) {
            Backbone.View.header.setCrumblepath(
                this.activityModel.getAllCrumblepathModels(),
                'show'
            );
            return
        }

        Backbone.View.header.setCrumblepath([
            new Backbone.Model({
                label: window.i18n.gettext('Little words'),
                url: '/words/home',
            }),
            new Backbone.Model({
                label: this.model.get('metadata').name,
                url: '/words/list/' + this.listId
            })
        ]);

    }

    addToCollection(leftValue, rightValue) {
        this.model.get('pairs').add({
            left_value: Util.normaliseCharacters(leftValue),
            right_value: Util.normaliseCharacters(rightValue),
        });
    }

    updateEditedListItem(leftInputContent, rightInputContent) {

        leftInputContent = Util.normaliseCharacters(leftInputContent)
        rightInputContent = Util.normaliseCharacters(rightInputContent)

        // update model
        this.editedListItemView.model.set({
            left_value: leftInputContent,
            right_value: rightInputContent,
        });

        // update visible part
        this.editedListItemView.$('.js-left-item').text(leftInputContent);
        this.editedListItemView.$('.js-right-item').text(rightInputContent);

        this.combinedInputsEdit.destroy();

        // show view mode of updated list item again
        this.editedListItemView.$('.js-list-item-replaceable').show();

        // remove temporary references
        this.combinedInputsEdit = null;
        this.editedListItemView = null;

        // make combinedInputs for adding active again
        this.combinedInputsView.toggleActiveState()

        // allow editing list items again
        this.listItemIsBeingEdited = false;

        // allow submitting list again
        this.saveListButtonView.enable()

    }

    addToList(model) {
        this.listView.renderListItem(model);

        if (!this.blockSavingWordsServerSide) {
            this.saveCreatedWords({
                left_value: model.get('left_value'),
                right_value: model.get('right_value'),
            });
        }
    }

    deleteFromCollection(model) {
        this.model.get('pairs').remove(model);

        $.ajax({
            url: this.isActivity ?
                '/training/delete_word/' + model.get('id') + '/' + this.activityModel.id :
                '/training/delete_word/' + model.get('id'),
            type: 'DELETE',
        });
    }

    validationCallback(leftInputView, rightInputView) {
        if (!leftInputView.getInput() || !rightInputView.getInput()) {
            return false;
        }

        return true;
    }

    editItem(listItemView) {
        // disallow editing more than one list item at once and
        // disallow editing in view mode
        if (this.isViewMode || this.listItemIsBeingEdited) {
            return;
        }

        this.listItemIsBeingEdited = true;

        // store temporary reference to listItemView
        this.editedListItemView = listItemView;

        // temporarily hide the edited list item
        listItemView.$('.js-list-item-replaceable').hide();

        this.combinedInputsEdit = new CombinedInputs({
            leftInputWidth: this.leftInputWidth,
            leftInputPlaceholder: this.model.get('metadata').left_column_name,
            rightInputPlaceholder: this.model.get('metadata').right_column_name,
            rightSideIcon: 'checkmark',
            callback: this.updateEditedListItem,
            validationCallback: this.validationCallback
        });

        this.combinedInputsEdit.setInputs(
            listItemView.model.get('left_value'),
            listItemView.model.get('right_value')
        );

        // disable combinedIputs for adding items
        this.combinedInputsView.toggleActiveState();

        // disable start practice button
        this.saveListButtonView.disable()

        // finally show an edit version of the CombinedInputs component
        this.addChildView(this.combinedInputsEdit, listItemView.$el);
    }

    deleteList() {
        Backbone.View.layout.openConfirmStatus(
            window.i18n.gettext('Are you sure you want to delete this list?'),
            () => {
                $.ajax({
                    url: '/training/delete/' + this.listId,
                    type: 'DELETE',
                });

                Backbone.View.layout.openStatus(
                    window.i18n.gettext('List is successfully removed'),
                    'success',
                );

                Backbone.history.navigate(
                    '/words/home',
                    {trigger: true}
                );
            },
            null,
            window.i18n.gettext('Delete'),
            window.i18n.gettext('Cancel')
        );
    }

    addHeroComponents() {
        /**
         * If a user does not own the list or
         * if the list is called as activity,
         * add only the print button
         */
        if (this.isActivity || !this.canEditList) {

            // The print button should not be shown on mobile and in the author
            if (!ISMOBILE && !this.isCalledFromAuthor) {
                Backbone.View.header.addButton(
                    new HeroButton({
                        firstLine: window.i18n.gettext('Print list'),
                        callback: () => { this.printList() },
                        icon: 'print',
                    })
                )
            }

            return;
        }

        if (!ISMOBILE) {
            this.pasteListButton = Backbone.View.header.addButton(
                new HeroButton({
                    firstLine: window.i18n.gettext('Paste list'),
                    callback: () => { this.openPasteListModal() },
                    icon: 'copy',
                })
            )
            /**
             * the default view mode is "view", hide the paste list
             * button until switched to "edit" mode
             */
            this.pasteListButton.$el.hide()
        }

        this.editListButton = Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Edit list'),
                callback: () => this.toggleViewMode(),
                icon: 'pencil',
            })
        )

        this.shareListButton = Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Share list'),
                callback: () => { this.onClickShareButton() },
                icon: 'people-plus',
            })
        )

        // don't add the "More" drop down herobutton on small screens
        if (ISMOBILE) {
            return;
        }

        Backbone.View.header.addButton(
            new HeroDropdown({
                firstLine: window.i18n.gettext('More'),
                icon: 'arrow-drop-down-circle',
                dropdownItems: [
                    {
                        icon: 'pencil',
                        label: window.i18n.gettext('Change title'),
                        callback: () => { this.onClickChangeName() },
                    },
                    {
                        icon: 'print',
                        label: window.i18n.gettext('Print list'),
                        callback: () => { this.printList() },
                    },
                    {
                        icon: 'trash',
                        label: window.i18n.gettext('Delete list'),
                        callback: this.deleteList,
                    },
                ],
            })
        )
    }

    /**
     * selectTrainingDirection
     *
     * When selecting one of the three types of cards, open a modal in which the user selects
     * either the direction "from_to" or "to_from". On choosing, navigate to to the training session.
     * If there is not an existing session for a specific direction, first create the session before
     * navigating to the excercise type.
     *
     * @param {string} type One of "open", "cards", "multiple_choice"
     */
    selectTrainingDirection(type) {
        let percentageDoneTextLeftRightDirection;
        let percentageDoneTextRightLeftDirection;

        const metadata = this.model.get('metadata')
        const doneText = '% ' + window.i18n.gettext('done');

        /**
         * TODO: the backend now gives back an int for 0 percentage_done and string for non-zero percentages done.
         * Until backend fixes this, disregard 0% percentage_done statuses.
         */

        const sessions = this.model.get('sessions');
        if (sessions && sessions[type]) {
            if (sessions[type].left_right && typeof sessions[type].left_right.percentage_done === 'string') {
                percentageDoneTextLeftRightDirection = sessions[type].left_right.percentage_done === '100' ?
                    window.i18n.gettext('Completed') :
                    sessions[type].left_right.percentage_done + doneText;
            }

            if (sessions[type].right_left && typeof sessions[type].right_left.percentage_done === 'string') {
                percentageDoneTextRightLeftDirection = sessions[type].right_left.percentage_done === '100' ?
                    window.i18n.gettext('Completed') :
                    sessions[type].right_left.percentage_done + doneText;
            }
        }

        Backbone.View.Components.modal.open(
            Chooser,
            {
                title: window.i18n.gettext('In which direction do you want to practice?'),
                list: [
                    {
                        label: window.i18n.gettext(metadata.left_column_name) +
                            ' - ' + window.i18n.gettext(metadata.right_column_name),
                        direction: 'left_right',
                        rightAlignedText: percentageDoneTextLeftRightDirection,
                    },
                    {
                        label: window.i18n.gettext(metadata.right_column_name) +
                        ' - ' + window.i18n.gettext(metadata.left_column_name),
                        direction: 'right_left',
                        rightAlignedText: percentageDoneTextRightLeftDirection,

                    }
                ],
                useContentLabel: false,
                defaultIndex: 0,
                buttons: [
                    {
                        label: window.i18n.gettext('Cancel'),
                        callback: Backbone.View.Components.modal.close,
                        theme: 'secondary',
                    },
                    {
                        label: window.i18n.gettext('Begin'),
                        keyCode: 13,
                        callback: () => {
                            // Navigate to existing session if found
                            const direction = Backbone.View.Components.modal.subView.getSelection().direction

                            if (
                                sessions &&
                                sessions.hasOwnProperty(type) &&
                                sessions[type][direction] &&
                                sessions[type][direction].percentage_done !== '100'
                            ) {
                                const url = this.isActivity ?
                                    '/activities/show/' + this.activityModel.get('id') + '/sessions/' + sessions[type][direction].id :
                                    '/words/sessions/' + sessions[type][direction].id;

                                Backbone.history.navigate(
                                    url,
                                    {trigger: true}
                                );

                                Backbone.View.Components.modal.close();
                                return;
                            }

                            // start spinner
                            Backbone.View.Components.modal.getButtons()
                                .find(button => button.label === window.i18n.gettext('Begin'))
                                .disable(true)

                            /**
                             * If no session exists, Create training session first. Afterwards close
                             * modal and navigate to /words/session/${newSessionId}
                             */
                            $.post({
                                url: this.isActivity ?
                                    '/training/start_training_session/' + this.listId + '/' + this.activityModel.id :
                                    '/training/start_training_session/' + this.listId,
                                data: {
                                    learn_direction: direction,
                                    type,
                                },
                                success: (response) => {
                                    Backbone.View.Components.modal.close();
                                    const url = this.isActivity ?
                                        '/activities/show/' + this.activityModel.get('id') + '/sessions/' + response.training_list_session.id :
                                        '/words/sessions/' + response.training_list_session.id;
                                    Backbone.history.navigate(
                                        url,
                                        { trigger: true }
                                    );
                                }
                            })
                        },
                    }
                ]
            }
        );

    }

    disableSaveListButtonIfListHasNoPairs() {
        const isDisabled = this.saveListButtonView.disabled;
        const hasPairs = !!this.listView.collection.length;

        if (hasPairs && isDisabled) {
            this.saveListButtonView.enable()
        } else if (!hasPairs && !isDisabled) {
            this.saveListButtonView.disable()
        }

        /**
         * TODO : rename so disabling multiple choice is also covered!!
         */

        this.setMultipleChoiceCardAvailabilty();
    }

    // If pasted text contains new lines and tabs, handle it as a batch import
    handlePaste(event) {
        const pastedText = event.originalEvent.clipboardData.getData('text')
        if (this.splitTextInPairs(pastedText).length > 0) {
            event.preventDefault()
            this.batchImportWords(pastedText)
        }
    }

    // Splits text seperated by tabs and newlines and returns the word pairs
    splitTextInPairs(text) {

        // split text into lines (by using a multiline, global match to end of string)
        const lines = text.match(/.+$/gm);

        // fallback for empty text
        if (!lines) {
            return []
        }

        return lines.reduce((m, line) => {
            const pair = line.split('\t').filter(word => word.trim().length > 0)
            if (pair.length === 2) {
                m.push({
                    left_value: pair[0],
                    right_value: pair[1],
                })
            }
            return m
        }, [])
    }

    // Import a list of words. Text should be seperated by \n for each pair and \t between words
    batchImportWords(text) {

        // If no new lines are present, inform the user we cannot process this list
        if (!/\n/.test(text)) {

            Backbone.View.layout.openStatus(
                window.i18n.gettext('This does not look like a list. Use multiple lines and two columns.')
            )
            window.sentry.withScope(scope => {
                scope.setExtra('text', text)
                window.sentry.captureMessage('Pasted list without line breaks')
            })
            return
        }

        // If no tabs are present, inform the user we cannot process this list
        if (!/\t/.test(text)) {

            Backbone.View.layout.openStatus(
                window.i18n.gettext('This does not look like a list. Create two columns using tabs.')
            )
            window.sentry.withScope(scope => {
                scope.setExtra('text', text)
                window.sentry.captureMessage('Pasted list without columns')
            })
            return
        }

        const pairs = this.splitTextInPairs(text)

        // Check if we have at least one pair. If not, inform the user
        if (pairs.length === 0) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('This does not look like a list. Use multiple lines and two columns.')
            )
            window.sentry.withScope(scope => {
                scope.setExtra('text', text)
                window.sentry.captureMessage('Pasted list in unrecognized format')
            })
            return
        }

        // prevent sending multiple requests. on paste, the pairs all all sent in a single request
        this.blockSavingWordsServerSide = true;
        this.model.get('pairs').add(pairs);

        this.saveCreatedWords(pairs, JSON.stringify(text))

        this.blockSavingWordsServerSide = false;

        // Import complete, inform the user
        Backbone.View.Components.modal.close()
        const message = window.i18n.sprintf(
            window.i18n.ngettext(
                '%d word was added succesfully.',
                '%d words were added succesfully.',
                pairs.length),
            pairs.length)
        Backbone.View.layout.openStatus(message, 'success')

    }

    /**
     * saveCreatedWord
     *
     * Persist the new word pairs in the backend.
     *
     * @param {Object | Object[]} words One word pair as object or an array of word pairs
     * @param {String} rawPaste the raw paste string (with \t \n etc.) for sentry logging
     */
    saveCreatedWords(words, rawPaste) {

        const wordsArray = Array.isArray(words) ? words : [words];

        $.ajax({
            url: this.isActivity ?
                '/training/add_words/' + this.listId + '/' + this.activityModel.id :
                '/training/add_words/' + this.listId,
            type: 'POST',
            data: { wordpairs: wordsArray },
            success: (response) => {

                if (!response.hasOwnProperty('inserted_wordpairs')) {

                    window.sentry.withScope(scope => {
                        scope.setExtra('raw paste', rawPaste)
                        window.sentry.captureMessage('Unrecognized paste in Woordjes')
                    })
                    return
                }

                /**
                 * Update the model with the new backend metadata. The backend returns an
                 * id along with some other metadata like created_at. When updating the model,
                 * prevent Backbone from firing the change event. Normally a change event
                 * sends a PATCH request. In this case that's unwanted.
                 */
                this.blockPatchingWordsServerSide = true;

                response.inserted_wordpairs.forEach((newWordPairData, index) => {
                    const pairIndex = this.model.get('pairs').length - wordsArray.length + index;
                    this.model.get('pairs').at(pairIndex).set(newWordPairData);
                });

                this.blockPatchingWordsServerSide = false;
            }
        });
    }

    changeWordPair(model) {

        if (this.blockPatchingWordsServerSide) {
            return;
        }

        $.ajax({
            url: this.isActivity ?
                '/training/update_word/' + model.get('id') + '/' + this.activityModel.id :
                '/training/update_word/' + model.get('id'),
            type: 'PATCH',
            data: JSON.stringify({
                left_value: model.get('left_value'),
                right_value: model.get('right_value'),
            }),
            contentType: 'application/json',
        });
    }

    /**
     *  changeShareLevel
     *
     * @param {int} newShareLevel Level of sharing (0: not shared, 1: shared)
     */
    changeShareLevel(newShareLevel) {
        $.ajax({
            url: '/training/update/' + this.listId,
            type: 'PATCH',
            data: JSON.stringify({
                share_level: newShareLevel,
            }),
            contentType: 'application/json',
            success: () => {
                this.model.get('metadata').share_level = newShareLevel
            }
        });
    }

    onClickShareButton() {
        Backbone.View.Components.modal.open(
            ShareListModal,
            {
                title: window.i18n.gettext('Share list'),
                /**
                 * TODO: for now only expecting sharelevel 0 or 1.
                 * Add extra logic if extra share levels are defined.
                 */
                isShared: this.model.get('metadata').share_level === 1,
                changeShareLevel: this.changeShareLevel.bind(this),
            }
        );
    }

    /**
     * onClickChangeName - open modal for changing name
     */
    onClickChangeName() {
        Backbone.View.Components.modal.open(
            SetListNameModal,
            {
                title: window.i18n.gettext('Change name of list'),
                model: this.model,
                editMode: true
            }
        )

        this.listenTo(this.model, 'listNameUpdated', () => {
            Backbone.View.header.setTitle(this.model.get('metadata').name);

            Backbone.View.header.setCrumblepath([
                new Backbone.Model({
                    label: window.i18n.gettext('Little words'),
                    url: '/words/home',
                }),
                new Backbone.Model({
                    label: this.model.get('metadata').name,
                    url: '/words/list/' + this.listId
                })
            ])
        })
    }

    openPasteListModal() {
        if (!this.canEditList) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You can only paste if you are the author of this list'),
                'error'
            )

            return
        }
        Backbone.View.Components.modal.open(
            PasteListModal,
            {
                title: window.i18n.gettext('Paste list'),
                submitCallback: this.batchImportWords,
                inputCallback: this.splitTextInPairs
            }
        );
    }

    /**
     * setMultipleChoiceCardAvailabilty - only allow multiple choice if >= 4 word pairs
     */
    setMultipleChoiceCardAvailabilty() {
        const { button } = this.practiceCardsView.multipleChoiceCard

        if (this.model.get('pairs').length < 4 && !button.disabled) {
            button.disable()
        } else if (this.model.get('pairs').length >= 4 && button.disabled) {
            button.enable()
        }
    }

    printList() {
        window.print()
        window.statsTracker.trackEvent(
            'words/list',
            'print word list'
        )
    }
}
