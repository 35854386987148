import Template from './CopyToPortfolioModal.hbs';
import TimeUtil from 'util/TimeUtil'
import Chooser from 'views/components/chooser/Chooser.svelte'

export default class CopyToPortfolioModal extends BaseView {

    initialize() {
        this.setElement(Template({}))

        const list = this.collection.map(item => ({
            label: item.get('title'),
            value: item.get('id'),
            rightAlignedText: window.i18n.sprintf(
                window.i18n.gettext('Last modification: %s'),
                TimeUtil.prettyDate(item.get('modified_on'))
            )
        }))

        this.chooser = this.addSvelteChildView(this.$el, Chooser, {
            list
        })
    }

}