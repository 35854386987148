import Styles from './Spinner.scss';

import Template from './Spinner.hbs';
export default BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Object as defined by parent
     */
    initialize(options) {

        // Default fallback for when options are not set
        options = options || {};

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles,
            hasModalParent: options.modalParent || false,
            isInsideButton: options.isInsideButton && true,
        }));
    }

});
