import Styles from './ResponseBar.scss';

import Template from './ResponseBar.hbs';
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte';

export default BaseView.extend({

    initialize(options) {

        // Shorten response object for readability
        var response = options.response;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            count: response.count,
            isCheckingAnswers: options.slideState === 2,
            isCorrect: response.correct
        }));

        if (options.slideState === 1) {

            // Construct a progress bar that displays the amount of times
            // an answer was given compared to the total answers given
            this.addSvelteChildView('.js-response-bar', ProgressBar, {
                fragments: {

                    // Amount of answer given compared to total answers given
                    gray: response.count / options.responseTotal
                },
                isLarge: true
            })
        } else {

            if (response.correct) {
                // Construct a progress bar that displays the amount of times
                // an answer was given compared to the total answers given
                this.addSvelteChildView('.js-response-bar', ProgressBar, {
                    fragments: {

                        // Amount of answer given compared to total answers given
                        green: response.count / options.responseTotal,
                    },
                    isLarge: true
                })
            } else {

                // Construct a progress bar that displays the amount of times
                // an answer was given compared to the total answers given
                this.addSvelteChildView('.js-response-bar', ProgressBar, {
                    fragments: {

                        // Amount of answer given compared to total answers given
                        red: response.count / options.responseTotal,
                    },
                    isLarge: true
                })
            }

        }
    }

});
