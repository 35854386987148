import Styles from './Template39.scss';

import Template from './Template39.hbs';
import Table from './table/Table'
import TableStyles from 'views/components/taskGroups/tasks/template22/table/Table.scss'

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'lockAnswer',
            'unlockAnswer',
            'saveResponse'
        )

        this.taskView = options.task_view

        this.taskInfoJson = this.model.get('task_info_json')

        this.renderTask()
    },

    renderTask() {
        this.setElement(Template({Styles}))

        this.table = this.addChildView(new Table({
            tableData: this.taskInfoJson.gridCells,
            response: this.taskView.response.get('json_answer'),
        }), this.$el)

        // When table cell inputs receive an input, debounce this to prevent creating a new response
        // for each small change to the table inputs resulting in unnecessary calls to the backend.
        this.saveResponseDebounced = _.debounce(this.saveResponse, 400)
        this.table.$('td input').on('input', this.saveResponseDebounced)

    },

    /**
     * Apply styling to cell depending on if the state of the element matches the data containing the correct answer.
     *
     * @param {Object} cell data representing cell
     * @param {Element} cellElement corresponding cell element
     */
    renderCellAnswer(cell, cellElement) {
        const inputElement = cellElement.querySelector('input')
        inputElement.disabled = true
        if (inputElement.checked) {
            cellElement.classList.add(cell.cellText ? TableStyles['correct'] : TableStyles['incorrect'])
        } else if (cell.cellText) {
            cellElement.classList.add(TableStyles['missing'])
        }
    },

    showAnswer() {
        for (const cell of this.table.gapCells) {
            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            this.renderCellAnswer(cell, cellElement)
        }
    },

    showAuthorAnswer() {
        for (const cell of this.table.gapCells) {
            if (cell.isGapCell && cell.cellText) {
                this.table.getCellElement(cell.rowIndex, cell.columnIndex).querySelector('input').checked = true
            }
        }
    },

    hideAnswer() {
        for (const cell of this.table.gapCells) {
            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            cellElement.querySelector('input').disabled = false
            cellElement.classList.remove(TableStyles['correct'], TableStyles['incorrect'], TableStyles['missing'])
        }
    },

    lockAnswer() {
        for (const cell of this.table.gapCells) {
            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            cellElement.querySelector('input').disabled = true
        }
    },

    unlockAnswer() {
        for (const cell of this.table.gapCells) {
            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            cellElement.querySelector('input').disabled = false
        }
    },

    /**
     * getCorrectAnswer
     *
     * This function will be called when the user wants to see the model answer.
     * It will return a string which can be HTML and
     * that HTML will be shown for each student
     *
     * @return {string}  String containing the correct answer (HTML)
     */
    getCorrectAnswer() {
        const correctAnswerTable = new Table({
            tableData: this.taskInfoJson.gridCells,
            isReadOnly: true,
            showAnswer: true,
        })
        for (const cell of correctAnswerTable.gapCells) {
            correctAnswerTable.setCellElementContent(cell, true, true)
        }
        return correctAnswerTable
    },

    /**
     * getStudentAnswer
     *
     * This function will be called for each student for when the users clicks
     * on student answers button. It will return a string which can be HTML and
     * that HTML will be shown for each student
     *
     * @param  {Backbone.Model} responseModel   The response model for each student
     * @return {string}                         String containing a student answer (HTML)
     */
    getStudentAnswer(responseModel) {
        // Create a variable for response's json_answer
        const response = responseModel.get('json_answer')

        if (!response) {
            return ''
        }

        const studentResponseTable = new Table({
            tableData: this.taskInfoJson.gridCells,
            response,
            isReadOnly: true,
        })

        for (const cell of studentResponseTable.gapCells) {
            const cellElement = studentResponseTable.getCellElement(cell.rowIndex, cell.columnIndex)
            this.renderCellAnswer(cell, cellElement)
        }

        return studentResponseTable
    },

    saveResponse() {

        // For each gap, get it's current user input.
        const responseObject = this.table.gapCells.reduce((m, cell) => {

            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            const cellInputState = cellElement.querySelector('input').checked

            // Add a new object to the response object for checked inputs.
            if (cellInputState) {
                m.push({

                    // Save the row
                    row: cell.rowIndex,

                    // Save the column
                    column: cell.columnIndex,

                    // Save the text
                    text: cellInputState
                })
            }

            return m

        }, [])

        // Save the updated response
        this.taskView.saveResponse(responseObject)
    }

})
