var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"contextUrl") || (depth0 != null ? lookupProperty(depth0,"contextUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contextUrl","hash":{},"data":data,"loc":{"start":{"line":19,"column":89},"end":{"line":19,"column":103}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "https://";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"source-element__chrome\">\n    <div class=\"source-element__chrome-buttons source-element__chrome-buttons--left\">\n        <div class=\"source-element__chrome-button-outer\">\n            <svg fill=\"rgba(0,0,0,.35)\" height=\"24\" viewBox=\"0 0 24 24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\">\n            <path d=\"M0 0h24v24H0z\" fill=\"none\"/>\n            <path d=\"M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z\"/>\n            </svg>\n        </div>\n        <div class=\"source-element__chrome-button-outer\">\n            <svg fill=\"rgba(0,0,0,.35)\" height=\"24\" viewBox=\"0 0 24 24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\">\n            <path d=\"M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z\"/>\n            <path d=\"M0 0h24v24H0z\" fill=\"none\"/>\n            </svg>\n        </div>\n    </div>\n\n    <div class=\"source-element__chrome-outer\">\n        <div class=\"source-element__chrome-address-outer\">\n            <div class=\"source-element__chrome-address js-website-url\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"contextUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":19,"column":71},"end":{"line":19,"column":126}}})) != null ? stack1 : "")
    + "</div>\n        </div>\n    </div>\n\n    <div class=\"source-element__chrome-buttons source-element__chrome-buttons--right\">\n        <div class=\"source-element__chrome-outer\">\n            <div class=\"source-element__chrome-ui-buttons\">\n                <div class=\"source-element__chrome-ui-buttons-element\"></div>\n                <div class=\"source-element__chrome-ui-buttons-element\"></div>\n                <div class=\"source-element__chrome-ui-buttons-element\"></div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});