export default class ExamTaskGroupSetModel extends Backbone.Model {

    initialize(attr, {activity_id}) {
        this.activity_id = this.collection ? this.collection.activity_id : activity_id
    }

    url() {
        if (this.isNew()) {
            return `/exam_sets/exam_set/${this.activity_id}.json`
        }
        return `/exam_sets/exam_set/${this.collection.activity_id}/${this.id}.json`
    }

}
