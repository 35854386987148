var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"You haven't added a group yet. <a href='/groups/pick_course'>Add a group</a> to continue.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":104}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"You are not in a group yet. <a href='/users/join'>Add a group</a> to continue.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":93}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../util/handlebarsHelpers/isTeacher.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isTeacher","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":14}}})) != null ? stack1 : "");
},"useData":true});