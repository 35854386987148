import Styles from './NavigationBar.scss';

import Template from './NavigationBar.hbs';
/**
 * TODO: refactor in such a way that the rendering and navigation logic are split.
 * This component is NOT rendered on mobile but on mobile some of the methods of this
 * component are still used to move to the next item. This requires a ISMOBILE check
 * whenever an action is done on a DOM element.
 */
import NavigationItem from 'views/pages/activities/show/navigationBar/navigationItem/NavigationItem';
import NavigationIcon from 'views/pages/activities/show/navigationBar/navigationIcon/NavigationIcon';

var NavigationBar = BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options as defined by parent
     */
    initialize(options) {

        // Make the type accessible with methods
        this.type = options.type;

        // Make this accessible within methods
        _.bindAll(this,
            'start',
            'nextItem',
            'previousItem',
            'addItem',
            'resizeNavigation',
            'goToNavigationItem'
        );

        // Create the view, passing the styling with it.
        // Don't bother rendering if ISMOBILE, since it's hidden anyway.
        if (!ISMOBILE) {
            this.setElement(Template({
                Styles,
                type: this.type
            }));

            // Ugly fix for mobile browsers, see:
            // https://nicolas-hoizey.com/2015/02/
            // viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html
            $(window).on('resize', this.resizeNavigation);

            // Initial call the resize navigation
            this.resizeNavigation();
        }

    },

    /**
     * resizeNavigation
     *
     * Method for recalculating and setting the 100vh because of crappy mobile browsers.
     *
     */
    resizeNavigation() {
        this.$el.css('height', window.innerHeight);
    },

    /**
     * getItemViewList
     *
     * This function will filter the childviews based on instance and
     * will return an list (array) with all childviews of instance NavigationItem
     *
     * @return {Array}      List with all childviews of type navigationitem
     */
    getItemViewList() {

        // Return filtered list of childviews.
        return _.filter(this.childViews, (child) => {
            return child instanceof NavigationItem;
        });
    },

    /**
     * getNavigatableViewList
     *
     * This function will filter the childviews based on instances that can be used to
     * navigate to.
     *
     * @return {Array}      List with all childviews of type navigationitem
     */
    getNavigatableViewList() {

        // Return filtered list of childviews sorted by index attribute.
        return _.sortBy(_.filter(this.childViews, (child) => {
            return child instanceof NavigationItem || child instanceof NavigationIcon;
        }), 'index');
    },

    /**
     * addItem
     *
     * This function will add a item to the created navigationbar
     *
     * @param  {Object} itemOptions         itemOptions as defined in start method
     * @param  {Backbone.Model} itemModel   itemModel as looped by the collection
     * @param  {number} index               Index as number
     */
    addItem(itemOptions, itemModel, index) {

        // Start the itemobject
        var itemObject = {

            // Set the model to the item model
            model: itemModel,

            // Set the index to the index + 1
            // (since index starts at 0, and humans starts at 1)
            index: (index + 1)
        };

        // Check if the item option can be clicked
        if (itemOptions.canBeClicked === true) {

            // Set the clickCallback to the goToNavigationItem
            itemObject.clickCallback = this.goToNavigationItem;
        }

        // Create a new navigationItem
        var navigationItem = new NavigationItem(itemObject);

        // Register the navigationItem as childview
        this.registerChildView(navigationItem);

        // Add the navigation item to the navigation bar
        navigationItem.appendTo(this.$('.js-items'));

        if (
            // Check if there is a startAt given and index is equal
            (itemObject.startAt && itemObject.startAt === index) ||

            // Or if there isn't a startAt and index is equal to zero
            (!itemObject.startAt && index === 0)
        ) {

            // Set item to active
            navigationItem.setActive();

            // Store item within the global activeItem holder
            this.goToNavigationItem(navigationItem);
        }

    },

    /**
     * start
     *
     * This function will be called to start the navigationBar. It will Animate
     * the bar into the view and render all the items
     *
     * @param  {Object} itemOptions     Options for items as defined by parent
     */
    start(itemOptions) {

        // These are the default item option
        var defaultItemOptions = {

            // By default the item can be clicked
            canBeClicked: true
        };

        // Loop trough collection and add items for each item in collection
        this.collection.each(

            // Start the partial method of backbone to
            _.partial(

                // Call the addItem function but
                this.addItem,

                // Add the combined itemOptions with defaultItemOptions as
                // first param
                _.extend(defaultItemOptions, itemOptions)
            )
        );

        // Check if there is type set
        if (this.type) {

            // Render extra options for the given type
            this.renderForType(this.type);
        }

    },

    /**
     * nextItem
     *
     * This method can be used to navigate to the next item
     */
    nextItem() {

        // Get all the navigatable items
        var items = this.getNavigatableViewList();

        // Get the index of the active item
        var activeIndex = _.indexOf(items, this.activeItem);

        // Check if there is a next item, if the index of the item is not greater than
        // the number of items.
        if (activeIndex < items.length - 1) {

            // Get the next item
            var nextItem = _.findWhere(items, {
                index: this.activeItem.index + 1
            });

            // Navigate to next item
            this.goToNavigationItem(nextItem);
        }
    },

    /**
     * previousItem
     *
     * This method can be used to navigate to the previous item
     */
    previousItem() {

        // Get all the navigatable items
        var items = this.getNavigatableViewList();

        // Get the index of the active item
        var activeIndex = _.indexOf(items, this.activeItem);

        // Check if there is a previous item
        if (activeIndex - 1 >= 0) {

            // Get the next item
            var previousItem = _.findWhere(items, {
                index: this.activeItem.index - 1
            });

            // Navigate to next item
            this.goToNavigationItem(previousItem);
        }
    },

    /**
     * findItemByIndex
     *
     * Find an navigatable list item by an index.
     *
     * @param  {number}                         index to search for
     * @return {NavigationItem|NavigationIcon}  instance of a navigatable item
     */
    findItemByIndex(index) {

        index = parseInt(index);

        // Get all the navigatable items
        var items = this.getNavigatableViewList();

        // Get first item with the same index
        return _.findWhere(items, {
            index
        });

    },

    /**
     * renderForType
     *
     * This function will render additional items according to given
     * type.
     *
     * @param  {string} type    Type as passed trough options
     */
    renderForType(type) {

        // Switch / case the type
        switch (type) {

            // When type is adaptive
            case 'adaptive':

                // Add a summary button as navigation icon
                var summaryButton = new NavigationIcon({

                    // Set the icon to assignment
                    icon: 'assignment',

                    // Set button action to summary
                    buttonAction: 'summary',
                });

                // Register the summaryButton as childview
                this.registerChildView(summaryButton);

                // Append the icon item to the items container
                this.$('.js-items').append(summaryButton.$el);

                // Since the animation movement relies on index, set the index correctly for this icon
                summaryButton.index = ((this.getNavigatableViewList()).indexOf(summaryButton) + 1);

                break;

            default:

                var summaryStart = new NavigationIcon({

                    // Set the icon to assignment
                    icon: 'assignment',

                    // Set button action to summary-start
                    buttonAction: 'summary-start',

                    clickCallback: this.goToNavigationItem,
                });
                summaryStart.index = 0;
                this.registerChildView(summaryStart);
                this.$('.js-items').prepend(summaryStart.$el);

                var summaryEnd = new NavigationIcon({

                    // Set the icon to assignment
                    icon: 'checkmark-circle',

                    // Set button action to summary-end
                    buttonAction: 'summary-end',

                    clickCallback: this.goToNavigationItem,
                });
                summaryEnd.index = this.collection.size() + 1;
                this.registerChildView(summaryEnd);
                this.$('.js-items').append(summaryEnd.$el);

        }
    },

    /**
     * goToNavigationItem
     *
     * This function will move to a given navigation item
     *
     * @param  {Backbone.View} navigationItem   Navigation item view
     */
    goToNavigationItem(navigationItem) {

        if (this.activeItem !== navigationItem) {

            var previousIndex;

            // Check if there is an active item
            if (this.activeItem) {

                // On the current active item, call the deactive
                this.activeItem.setDeactive();

                // Get the previous index
                previousIndex = this.activeItem.index;

                // Store the previous item
                this.deactivatedItem = this.activeItem;

                // Else this is the first item
            } else {

                // Set previous to -1 to make animation correct
                previousIndex = -1;
            }

            // Set the new active item
            this.activeItem = navigationItem;

            // Make the new active item active
            this.activeItem.setActive();

            // Get the next index
            var nextIndex = this.activeItem.index;

            // Movement will be an integer to determine the movement.
            // If positive, then new item is after previous. If negatieve
            // the new item is before previous. If zero no movement.
            // Number is steps to take to new item.
            var movement = (nextIndex - previousIndex);

            // Create a type variable
            // Check if the active item is an instance of navigationItem, return item
            var type = (this.activeItem instanceof NavigationItem) ? 'item' :

                // Check if the active item is an instance of navigationIcon, return icon
                (this.activeItem instanceof NavigationIcon) ? 'icon' :

                // Else we couldn't determine, return undefined
                    undefined;

            // Add the type to the item's type
            this.activeItem.itemType = type;

            // Trigger the event on this view to tell that there is a new active
            this.trigger('newActiveItem', this.activeItem, movement, this.deactivatedItem, type);

            if (!ISMOBILE) {
                // center NavigationItem.
                this.scrollNavigationItemToCenter(navigationItem.index);
            }
        }
    },

    /**
     * scrollNavigationItemToCenter
     *
     * center the active NavigationItem component in the NavigationBar component. Besides
     * centering the NavigationItem, it also prevent the active item of being invisible in the
     * y overflow.
     *
     * @param {number} index the human-readable index (index + 1) of the active NavigationItem
     */
    scrollNavigationItemToCenter(index) {

        _.defer(() => {

            const navigationItem = this.el.querySelector(`[data-navigation-item-index="${index}"]`)
            const scrollableNavigationBar = this.el.querySelector('.js-items')

            // If a navigation item is present, scroll it to the center of the navigation bar
            if (navigationItem) {
                const navigationItemOffset = navigationItem.offsetTop - (navigationItem.offsetHeight / 2)
                const navigationBarHeight = scrollableNavigationBar.offsetHeight

                scrollableNavigationBar.scrollTo({
                    top: navigationItemOffset - (navigationBarHeight / 2),
                    behavior: 'smooth'
                })
            }

            if (!this.init &&
                scrollableNavigationBar &&
                scrollableNavigationBar.scrollHeight > window.innerHeight
            ) {

                // display arrows
                this.$('.js-navigation-arrow').css('display', 'flex');

                // initalize the navigation arrows: check if they need to be enabled / disabled
                this.updateNavigationArrows();

                // add scroll listener to NavigationBar
                this.addScrollListener();

                // add click listener for the arrows
                this.addClickListener();

                // prevent adding multiple listeners
                this.init = true;
            }

        })
    },

    /**
     * addScrollListener - scroll listener for navigationbar to update navigation arrows
     */
    addScrollListener() {

        _.defer(() => {

            // call updateNavigationArrow again - debounced - after a scroll
            const scrollableNavigationBar = this.el.querySelector('.js-items');

            if (!scrollableNavigationBar) {
                return;
            }

            scrollableNavigationBar.addEventListener('scroll', _.throttle(() => {
                this.updateNavigationArrows();
            }, 200));
        });

    },

    /**
     * Add click listener for navigation up and down arrows
     */
    addClickListener() {

        /**
         * add a click listener for arrow up (.js-navigation-arrow-top) and
         * arrow down (.js-navigation-arrow-bottom) which scrolls up and scrolls
         * down respectively. add animation so that the user know a scroll
         * has taken place
         */
        ['.js-navigation-arrow-bottom', '.js-navigation-arrow-top'].forEach(arrowSelector => {

            const arrow = this.$(arrowSelector);

            arrow.on('click', () => {
                const forwards = arrow.hasClass('js-navigation-arrow-bottom');

                // calculate distance to be moved
                const navigationBar = this.el.querySelector('.js-items');
                let moveDistance = 0.5 * navigationBar.offsetHeight;

                // invert the distance to be moved when going backwards
                moveDistance = forwards ? moveDistance : -1 * moveDistance;

                navigationBar.scrollTo({
                    top: navigationBar.scrollTop + moveDistance,
                    behavior: 'smooth'
                })
            })
        })
    },

    /**
     * updateNavigationArrows - calculates if navigation arrows need to be updated
     */
    updateNavigationArrows() {
        const arrow = this.el.querySelector('.js-navigation-arrow');
        const arrowUp = this.$('.js-navigation-arrow-top');
        const arrowDown = this.$('.js-navigation-arrow-bottom');
        const firstNavItem = this.el.querySelector('.js-items > button:first-child')
        const lastNavItem = this.el.querySelector('.js-items > button:last-child')

        if (!arrow || !arrowUp.length || !arrowDown.length || !firstNavItem || !lastNavItem) {
            return;
        }

        const arrowHeight = arrow.getBoundingClientRect().height;
        const firstNavItemTop = firstNavItem.getBoundingClientRect().top;
        const lastNavItemBottom = lastNavItem.getBoundingClientRect().bottom;

        const arrowUpShouldBeActive = firstNavItemTop < arrowHeight;
        const arrowDownShouldBeActive = lastNavItemBottom > window.innerHeight - arrowHeight;

        this.toggleArrow(arrowUp, arrowUpShouldBeActive);
        this.toggleArrow(arrowDown, arrowDownShouldBeActive);
    },

    /**
     * toggleArrow - enable or disable the navigation arrow
     *
     * @param {jQuery} arrow jQuery object of the navigation arrow.
     * @param {boolean} shouldBeActive Boolean indicating if should be enabled (true) or disabled (false)
     */
    toggleArrow(arrow, shouldBeActive) {
        const disabledClass = Styles['navigation-arrow--disabled'];

        if (shouldBeActive && arrow.hasClass(disabledClass)) {
            arrow.removeClass(disabledClass);
        } else if (!shouldBeActive && !arrow.hasClass(disabledClass)) {
            arrow.addClass(disabledClass);
        }
    },

});

export default NavigationBar;
