import Styles from './AboutTaskGroup.scss';

import Template from './AboutTaskGroup.hbs';
import SidebarAboutTaskGroupItemView from 'views/pages/activities/show/sidebars/aboutTaskGroup/item/Item';

export default BaseView.extend({

    initialize() {
        this.$el.html(Template({
            Styles,
            frameworkTitle: this.model.getGroupModel().getCourseModel().get('framework_name')
        }));

        const courseBrandingModel = Backbone.Collection.course_branding
            .get(this.model.getChapterModel().get('course_branding_id'));
        if (courseBrandingModel) {
            var courseBrandingLogo = courseBrandingModel.get('logo_white');
            if (courseBrandingLogo) {
                this.$('.js-method-logo').html(courseBrandingLogo);
            }
        }

        this.model.tasks.each((taskModel, index) => {
            this.addChildView(new SidebarAboutTaskGroupItemView({
                model: taskModel,
                num: index + 1,
                tagName: 'tr'
            }), '.js-table-holder');
        });

        this.showAuthorName()
    },

    async showAuthorName() {
        const authorName = await this.model.getAuthorName()
        this.$('.js-author').text(authorName)

        if (authorName.toLowerCase() === 'vo-content') {
            this.$('.js-cc-license').css('display', 'flex')
        }
    }
});
