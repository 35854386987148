var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " autocomplete=\"off\" autocorrect=\"off\" autocapitalize=\"off\" spellcheck=\"false\" data-gramm_editor=\"false\" data-enable-grammarly=\"false\" data-gramm=\"false\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td data-column=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cell") : depth0)) != null ? lookupProperty(stack1,"columnIndex") : stack1), depth0))
    + "\""
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isNotAuthor.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotAuthor","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":222}}})) != null ? stack1 : "")
    + "></td>\n";
},"useData":true});