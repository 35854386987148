import Template from './Template31.hbs';
import WordSelection from 'views/components/taskGroups/tasks/wordSelection/WordSelection';
import FragmentTemplate from 'views/components/taskGroups/tasks/wordSelection/Fragment.hbs';
import GapTemplate from 'views/components/taskGroups/tasks/wordSelection/Gap.hbs';
import WordSelectionStyles from 'views/components/taskGroups/tasks/wordSelection/WordSelection.scss';
import QuickInput from 'views/components/quickInput/QuickInput';
import Util from 'util/util';

export default WordSelection.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onEditGap',
            'sendPresentationAnswer',
            'renderFragments',
            'lockAnswer'
        );

        this.taskView = options.task_view;
        this.isPresentation = options.isPresentation;

        this.prepareData();

        // Normalise correct answers.
        this.fragments = _.map(this.fragments, (fragment) => {
            if (fragment.answers) {
                _.each(fragment.answers, (answer) => {
                    answer.text = Util.normaliseCharacters(answer.text);
                });
            }
            return fragment;
        });

        this.isCaseSensistive = false;
        if (
            this.model.get('task_info_json').options &&
                this.model.get('task_info_json').options.caseSensitive
        ) {
            this.isCaseSensistive = true;
        }

        this.hasBigGaps = false;
        if (
            this.model.get('task_info_json').options &&
                this.model.get('task_info_json').options.hasBigGaps
        ) {
            this.hasBigGaps = true;
        }

        this.renderTask()
    },

    /**
         * renderTask
         *
         * This method will render the template of the task. It will be
         * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
         * if only the answer view is necessary
         *
         */
    renderTask() {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles: WordSelectionStyles
        }));

        // If presentation mode, wait for the parent presentation views to be
        // fully rendered. This is needed to prevent a bug in presentation
        // mode where the fragments are strangely placed. Otherwise render
        // the gap text fragments right away to prevent situations where
        // the Template31 DOM isn't ready when methods as showAnswer or
        // lockAnswer are called.
        if (this.isPresentation) {
            _.defer(this.renderFragments);
        } else {
            this.renderFragments();
        }

        // Debounce the saveAnswer method by 1000 ms so it only fires when more
        // than 1000 ms has elapsed since the last time it was called. This
        // is to prevent DoSS-ing ourselfs every time the user types something
        // in an input field.
        this.saveAnswerDebounced = _.debounce(this.saveAnswer, 2000);

    },

    /**
         * renderFragments
         *
         * This function appends fragments to the task inside the DOM.
         * Create fragment element for each fragment. Add a highlight
         * if it already has been selected.
         */
    renderFragments() {

        // Reduce gap templates and plain text into a single string and append
        // it to the DOM. This makes it possible for the browser to correct
        // with any invalid HTML automatically without having to normalize
        // and parse each individual fragment, which can result in errors.
        this.$el.append(_.reduce(this.fragments, (content, fragment) => {
            if (fragment instanceof Object) {
                return content + GapTemplate({
                    fragmentID: fragment.id,
                    Styles: WordSelectionStyles
                });
            }
            return content + this.insertPlainText(fragment);
        }, ''))

        // Add gap answer input fields.
        _.each(this.fragments, (fragment) => {
            if (fragment instanceof Object) {

                // Get template we just added by searching for its data-gap-id.
                var gapElement = this.$('.js-gap[data-gap-id=' + fragment.id + ']');

                if (APPLICATION === 'author') {

                    gapElement.append(_.reduce(fragment.answers, (content, gapAnswer) => {
                        return content + FragmentTemplate({
                            Styles: WordSelectionStyles,
                            fragment: gapAnswer.text || '…',
                            fragmentStatus: WordSelectionStyles['fragment--selected']
                        });
                    }, ''));

                } else {

                    var existingAnswer = _.findWhere(this.JSONAnswer, {
                        id: fragment.id
                    }) || '';
                    if (existingAnswer) {
                        existingAnswer = existingAnswer.answer.text;
                    }

                    var answerInput = this.addChildView(new QuickInput({
                        defaultValue: existingAnswer,
                        displayInline: true,
                        noAutoCapitalize: true,
                        noAutoCorrect: true,
                        isWider: this.hasBigGaps
                    }), gapElement);
                    answerInput.$el.on('input', _.partial(this.onEditGap, fragment.id, answerInput, _));

                }
            }
        })

    },

    /**
         * lockAnswer
         *
         * Locks the answer option.
         * User can't give answers.

         */
    lockAnswer() {

        _.invoke(this.getChildViewsOfInstance(QuickInput), 'disable');

        // When user is following a teacher inside a presentation
        if (this.taskView.sendButton) {
            // Get all QuickInput answer options
            var inputFields = this.getChildViewsOfInstance(QuickInput);

            // Loop through answer options
            _.each(inputFields, (inputField, index) => {

                // Link the right answer to input field iteratee
                var givenAnswer = _.findWhere(this.savedAnswers, {
                    id: index + 1
                });

                // If there is an answer saved
                if (givenAnswer) {

                    // Set the value of input to the answer text
                    // To make sure any unsaved answers are not checked
                    inputField.setInput(givenAnswer.answer.text);
                } else {

                    // Even if no answer was saved, there could be an unsaved answer
                    // We need to delete this to make sure it isn't checked either
                    inputField.setInput('');
                }
            })

        }

    },

    /**
         * unlockAnswer
         *
         * Unlocks the answer option.
         * User can give answers again.
         */
    unlockAnswer() {
        // Call disable function on all QuickInput child views
        _.invoke(this.getChildViewsOfInstance(QuickInput), 'enable');
    },

    /**
         * onEditGap
         *
         * Whenever user types something in answerInput input element, save this
         * input as the given answer for the current gap.
         *
         * @param  {number} gapID       ID number of the gap being edited
         * @param  {string} answerInput Current contents of answerInput
         * @param  {Event} e            input change event
         */
    onEditGap(gapID, answerInput, e) {

        this.stopAllEvents(e);

        if (!this.$el.hasClass('showAnswer')) {

            var input = Util.normaliseCharacters(answerInput.getInput());

            this.JSONAnswer = _.clone(this.JSONAnswer);

            var answer = {
                id: gapID,
                answer: {
                    text: input
                }
            };

            var existingAnswerIndex = _.findIndex(this.JSONAnswer, {
                id: gapID
            });
            if (existingAnswerIndex >= 0) {
                if (input) {
                    this.JSONAnswer[existingAnswerIndex] = answer;
                } else {
                    this.JSONAnswer.splice(existingAnswerIndex, 1);
                }
            } else if (input) {
                this.JSONAnswer.push(answer);
            }

            // When the user is not in a presentation we save the answer.
            if (!this.isPresentation) {

                /**
                     * enables us to stop debounced function. flag is set to
                     * false in base class (WordSelection.saveAnswer)
                     */
                this.debounceIsActive = true
                // Save answer
                this.saveAnswerDebounced();
            }

        }

    },

    /**
         * showAnswer
         *
         * Show the correct answer to the task.
         */
    showAnswer() {

        if (APPLICATION === 'author') {
            return;
        }

        // If a student clicks the show answer button while the debounce is active,
        // save the response first and then show the answer
        if (this.debounceIsActive) {
            this.saveAnswerDebounced.cancel()
            this.saveAnswer()
        }

        _.each(_.filter(this.fragments, _.isObject), (fragment) => {

            var fragmentAnswers = fragment.answers;
            if (!this.isCaseSensistive) {
                // Create a deep clone where all answers have been transformed to lower case.
                fragmentAnswers = this.transformLowerCase(fragmentAnswers);
            }

            // Get student answer given for the current gap.
            var givenGapAnswer = _.findWhere(this.JSONAnswer, {
                id: fragment.id
            });

            // If there is anything in the current gap, check if this this matches any the model answers for
            // this gap. Else if there is nothing in the current gap, check if this correct and insert an … as
            // a placeholder for this empty answer.
            var isCorrectAnswer = false;
            if (givenGapAnswer) {
                givenGapAnswer = givenGapAnswer.answer.text;
                var lookupAnswer = Util.normaliseCharacters(givenGapAnswer);
                if (!this.isCaseSensistive) {
                    lookupAnswer = lookupAnswer.toLowerCase();
                }
                isCorrectAnswer = _.any(fragmentAnswers, {
                    text: lookupAnswer
                });
            } else {
                isCorrectAnswer = fragmentAnswers[0].text.length === 0;
                givenGapAnswer = '…';
            }

            var gapElement = this.$('.js-gap[data-gap-id=' + fragment.id + ']');
            gapElement.find('> div:has(input)').hide();

            var fragmentStatusClass = (isCorrectAnswer ?
                WordSelectionStyles['fragment--correct'] : WordSelectionStyles['fragment--incorrect']
            ) + ' ' + WordSelectionStyles['fragment--show-answer'];

            if (this.model.get('grading_mode') === 'student') {

                // Check if grading mode is student, because when it is
                // student we want to override the class because we don't want
                // to give any wrong or right indications
                fragmentStatusClass = WordSelectionStyles['fragment--show-answer'];

                // Set correct answer to false to force template to show a tooltip with the
                // correct answer which will tell the user what his/her answer should look
                // like.
                isCorrectAnswer = false;
            }

            gapElement.append(FragmentTemplate({
                Styles: WordSelectionStyles,
                fragment: givenGapAnswer,
                fragmentStatus: fragmentStatusClass,
                showAnswerTooltip: !isCorrectAnswer,
                tooltipText: fragment.answers[0].text || '…'
            }));

            // Make the gap at least as wide as the tooltip, to prevent
            // the tooltip from falling outside of the viewport
            const tooltipWidth = gapElement.find('.js-answer-tooltip').width()
            // Only apply when tool tip is wider than 100px (the min width of a fragment).
            if (tooltipWidth > 100) {
                gapElement.find('.js-fragment').css('min-width', tooltipWidth)
            }

        })

    },

    showAuthorAnswer() {
        this.showAnswer()
    },

    /**
         * hideAnswer
         *
         * Hide the correct answer to the task.
         */
    hideAnswer() {
        this.$('.js-gap .js-fragment').remove();
        this.$('.js-gap > div:has(input)').css({
            display: ''
        });
    },

    /**
         * sendPresentationAnswer
         *
         * This function is called when user clicks
         * a send button inside a presentation.
         * It will then save the answer in the backend and notify the teacher an answer was given.
         */
    sendPresentationAnswer() {
        this.savedAnswers = this.JSONAnswer;

        this.saveAnswer();
        this.lockAnswer();
    },

    /**
         * If there's a debounced save going on: cancel and
         * directly save.
         */
    beforeDestroy() {

        if (!this.debounceIsActive) {
            return
        }

        this.saveAnswerDebounced.cancel()

        this.saveAnswer()

    },

});
