import Styles from './QuestionCard.scss';

import Template from './QuestionCard.hbs';
import MultipleChoice from 'views/pages/words/multipleChoice/MultipleChoice'
import OpenQuestion from 'views/pages/words/openQuestion/OpenQuestion'
import Flashcard from 'views/pages/words/flashcard/Flashcard'
import WordsResponseModel from 'models/WordsResponseModel'
import Button from 'views/components/button/Button'
import HeroButton from 'views/components/heroButton/HeroButton'
import ToggleSwitch from 'views/components/toggleSwitch/ToggleSwitch.svelte'

export default BaseView.extend({

    events: {
        'click .js-question-status': 'onClickQuestionStatus'
    },

    initialize({
        sessionModel,
        showResults,
        closeCallback
    }) {
        _.bindAll(
            this,
            'addCard',
            'setResponse',
            'moveToNextCard',
            'showNextCard',
            'saveResponse',
            'setInstructionText',
        )

        this.setElement(Template({
            Styles
        }))

        this.sessionModel = sessionModel
        this.showResults = showResults
        this.nextButton = this.el.querySelector('.js-next-card')

        // index of currect words pair
        this.index = 0

        this.addCard()

        // element to show after choosing the answer to display time left untill the next question
        this.timeOutProgress = this.el.querySelector('.js-progress-circle')

        if (this.sessionModel.get('currentProgress') > 0) {
            this.el.querySelector('.js-progress').style.width = `${this.sessionModel.get('currentProgress')}%`
        }

        // listen to localStorage collection that would trigger the event
        // if the given response was the last one in this session and show the Results page
        this.listenTo(Backbone.Collection.localStorage, 'endOfSession', (result) => {
            this.showResults(result)
        })

        this.$('.js-training-close').on('click', closeCallback)

        this.addChildView(new Button({
            label: window.i18n.gettext('Stop'),
            icon: 'cross',
            theme: 'secondary',
            callback: closeCallback
        }), '.js-close-button')
    },

    addCard() {
        // start measuring duration
        this.startTime = Date.now()

        this.setPairText()

        const question = this.pairsArray[this.index]
        const pairCardText = question.get('pairCardText')

        switch (this.sessionModel.get('type')) {

            case 'multiple_choice' :
                this.questionView = this.addChildView(
                    new MultipleChoice({
                        parent: this,
                        pairCardText
                    }),
                    '.js-training-template'
                )
                break;

            case 'open' :
                this.questionView = this.addChildView(
                    new OpenQuestion({
                        parent: this,
                        pairCardText
                    }),
                    '.js-training-template'
                )

                if (this.nextButton) {
                    this.nextButton.disabled = false
                }
                break;

            case 'cards' :
                this.questionView = this.addChildView(
                    new Flashcard({
                        parent: this,
                        pairCardText
                    }),
                    '.js-training-template'
                )
                break;
        }
    },

    setResponse(isCorrect, givenAnswer) {
        this.response = { isCorrect, givenAnswer }
    },

    moveToNextCard(skipTimeout = false) {

        if (!this.endTime) {
            this.endTime = Date.now()
        }

        if (skipTimeout) {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.showNextCard()
            return
        }

        let timeDelay
        switch (this.sessionModel.get('type')) {
            case 'multiple_choice':
                timeDelay = this.response.isCorrect ? 2000 : 4000
                break
            case 'open':
                timeDelay = this.response.isCorrect ? 3000 : 10000
                break
        }

        // time for user to see the correct answer before replacing this view
        this.timeout = setTimeout(() => {
            this.showNextCard()
        }, timeDelay)

        const spinner = this.timeOutProgress.querySelector('svg:last-child circle')
        spinner.style.animationDuration = timeDelay + 'ms'
        this.timeOutProgress.style.display = 'flex'

        this.el.querySelector('.js-question-status')?.classList.add(Styles['is-clickable'])
    },

    showNextCard() {

        this.el.querySelector('.js-question-status')?.classList.remove(Styles['is-clickable'])

        if (this.timeOutProgress) {
            this.timeOutProgress.style.display = 'none';
        }

        this.saveResponse()

        this.index = this.index + 1

        if (this.pairsArray.length > this.index) {
            if (this.response.isCorrect) {
                this.sessionModel.incrementProgress()
                this.updateProgressBar()
            }

            if (this.questionView) {
                this.unregisterAndDestroyChildView(this.questionView)
            }
            this.addCard()
        }
    },

    // Go to the previous card. This is only avaiable for flash cards
    showPreviousCard() {
        if (this.sessionModel.get('type') !== 'cards' || this.index < 1) {
            return
        }

        this.index = this.index - 1
        this.sessionModel.decrementProgress()
        this.updateProgressBar()

        if (this.questionView) {
            this.unregisterAndDestroyChildView(this.questionView)
        }
        this.addCard()
    },

    saveResponse() {

        const question = this.pairsArray[this.index]
        if (!question) {
            return
        }

        const triesNumber = question.get('amount_of_tries')
        if (!triesNumber) {
            question.set({
                amount_of_tries: 1
            })
        } else {
            question.set({
                amount_of_tries: triesNumber + 1
            })
        }

        if (!this.response.isCorrect) {

            // Let the pair come back after 5, 6 or 7 other words (or at the end when less words are remaining)
            const retryAfter = Math.floor(6 + Math.random() * 3)
            this.pairsArray.splice(this.index + retryAfter, 0, question)
        }

        const responseModel = new WordsResponseModel({
            response: this.response.givenAnswer,
            training_list_word_id: question.get('id'),
            training_list_session_id: this.sessionModel.get('id'),
            score: this.response.isCorrect ? 1 : 0,
            amount_of_tries: question.get('amount_of_tries'),
            started_at: this.startTime,
            answered_at: this.endTime,
            displayed_sequence: this.sessionModel.get('type') === 'multiple_choice' ?
                question.get('pairCardText').variants :
                null
        })
        responseModel.save()

        delete this.startTime
        delete this.endTime
    },

    setPairText() {
        const direction = this.sessionModel.get('learn_direction')

        _.each(this.sessionModel.pairs.models, (pair) => {

            pair.set({
                pairCardText: {
                    pairId: pair.id,
                    questionLabel: direction === 'left_right' ?
                        this.sessionModel.list.get('left_column_name') :
                        this.sessionModel.list.get('right_column_name'),
                    questionValue: direction === 'left_right' ?
                        pair.get('left_value') :
                        pair.get('right_value'),
                    answerLabel: direction === 'left_right' ?
                        this.sessionModel.list.get('right_column_name') :
                        this.sessionModel.list.get('left_column_name'),
                    answerValue: direction === 'left_right' ?
                        pair.get('right_value') :
                        pair.get('left_value'),
                    variants: this.sessionModel.get('type') === 'multiple_choice' ?
                        this.parseMultipleChoiceVariants(pair, direction) :
                        null,
                }
            })
        })
        this.pairsArray = this.sessionModel.pairs.models
    },

    parseMultipleChoiceVariants(pair, direction) {
        const answerValue = direction === 'left_right' ? 'right_value' : 'left_value'
        const variants = this.parseDistractors(pair, direction)
        variants.push(pair.get(answerValue))
        return _.shuffle(variants)
    },

    parseDistractors(pair, direction) {
        const distractorDirection = direction === 'left_right' ? 'right' : 'left'
        return pair.get('distractors')?.split(',').map((item, index) => {
            return pair.get('distractor_' + (index + 1) + '_' + distractorDirection + '_value')
        }) || []
    },

    setInstructionText(text) {
        const commands = this.el.querySelector('.js-commands')
        if (commands) {
            commands.textContent = text
        }
    },

    updateProgressBar() {
        this.el.querySelector('.js-progress').style.width = `${this.sessionModel.get('currentProgress')}%`
    },

    onClickQuestionStatus(e) {
        if (e.currentTarget.classList.contains(Styles['is-clickable'])) {
            this.moveToNextCard(true)
        }
    },

    // Get the language code for generating speech from text
    getTextToSpeechLanguage(label) {
        switch (label) {
            case 'Engels':
                return 'en-GB'
            case 'Frans':
                return 'fr-FR'
            case 'Duits':
                return 'de-DE'
            case 'Spaans':
                return 'es-ES'
        }
    },

    addAutoSpeechButton() {
        if (this.speechButton) {
            return
        }

        this.speechButton = Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Speech'),
                showBadge: true,
                callback: () => {
                    speechToggleSwitch.toggleState()
                }
            })
        )
        const speechToggleSwitch = this.addSvelteChildView(this.speechButton.$('.js-badge'), ToggleSwitch, {
            darkMode: true,
            isChecked: false,
            callback: (newState) => {
                this.sessionModel.set('autoSpeech', newState)
            }
        })
    },
});
