<script>
    import Util from 'util/util'
    import Spinner from 'views/components/spinner/Spinner.svelte'

    // Id of the theory collection, used for the URL
    export let theoryCollectionId

    // Callback to execute for navigating to the result
    export let onClickResultCallback

    export function search(query) {
        request?.abort()
        request = jQuery.get({
            url: `/theory/search/${theoryCollectionId}.json?query=${encodeURIComponent(query)}`,
        })
    }

    let request

    function onClickResult(result) {
        onClickResultCallback(result.source_id, result.branch_id)
    }
</script>

{#if request}
    {#await request}
        <div class="spinner">
            <Spinner></Spinner>
        </div>
    {:then results}
        {#each results as result}
            <button class="result" on:click={onClickResult(result)}>
                <div class="title">{@html Util.renderContentSafely(result.source_title)}</div>
                <div class="text">{@html Util.renderContentSafely(result.relevant_text)}</div>
                <div>
                    {#each result.branch_names as location}
                        <span class="location">{location}</span>
                    {/each}
                </div>
            </button>
        {:else}
            <div class="no-results">{window.i18n.gettext('No results found')}</div>
        {/each}
    {/await}
{/if}

<style lang="scss">
    @import 'src/styling/globals.scss';

    .spinner {
        display: flex;
        width: 48px;
        height: 48px;
        margin: 16px auto;
    }

    .result {
        width: 100%;
        padding: 16px;
        cursor: pointer;
        background-color: $white;
        border-bottom: 1px solid $line-gray;

        &:hover {
            background-color: $white-highlight;
        }

        .title {
            @include large-text;

            color: $blue;
        }

        .text {
            margin: 4px 0;
        }

        .location {
            color: $blue-black-50;

            &:not(:last-child)::after {
                content: ' › ';
            }
        }
    }

    .no-results {
        background: $white;
        padding: 16px;
    }
</style>