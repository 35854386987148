var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotMobile.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotMobile","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":55},"end":{"line":1,"column":103}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "has-actionbutton";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"center-panel activities-list "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isTeacher.js")).call(alias1,{"name":"isTeacher","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":41},"end":{"line":1,"column":117}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"js-activities-holder\">\n        <div class=\"tile tile--activity-list tile--activity-list__no-activities js-no-activities\">\n            <div class=\"tile__content\">\n                <span class=\"tile--activity-list--item__no-activities-text\">"
    + container.escapeExpression(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias1,"No activities available",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":76},"end":{"line":5,"column":108}}}))
    + "</span>\n                <div class=\"js-reload-page\"></div>\n            </div>\n        </div>\n    </div>\n    <div class=\"js-practice-activities-holder\"></div>\n    <div class=\"js-pagination\"></div>\n</div>\n";
},"useData":true});