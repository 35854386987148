import Styles from './Message.scss';

import Template from './Message.hbs';
export default class ConfirmModalMessage extends BaseView {

    initialize({message}) {
        this.setElement(Template({
            confirmMessage: message || '',
            Styles
        }));
    }
}
