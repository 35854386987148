import Styles from './AnswerExplanation.scss';

import Template from './AnswerExplanation.hbs';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import renderSpecialElements from 'util/RenderSpecialElements';

export default BaseView.extend({

    specialElementsConvertList: {
        // Convertion for HTML element: 'img'
        img: {
            // Default options
            global: {
                // Set template for the img tag
                template: DefaultImageElement
            }
        }
    },

    initialize({
        explanation,
        icon = 'lightbulb',
        title,
        doNotAnimate = false,
    }) {

        this.setElement(Template({
            Styles,
            title,
            explanation,
            icon,
        }));

        renderSpecialElements({},
            this,
            this.$('.js-answer-text')
        );

        if (!doNotAnimate) {
            TweenMax.fromTo(
                this.$el, {
                    height: 0,
                }, {
                    duration: 0.25,
                    height: 'auto',
                    ease: 'power3.out'
                }
            )
        }

    },

    hide() {
        TweenMax.to(
            this.$el, {
                duration: 0.4,
                height: 0,
                marginTop: 0,
                ease: 'power3.out',
                onComplete: () => {
                    this.destroy()
                }
            }
        );
    }

});
