import Styles from './TreeView.scss';

import Template from './TreeView.hbs';
import TreeItem from 'views/components/treeView/treeItem/TreeItem';

export default class TreeView extends BaseView {

    /**
     * TreeView
     *
     * Component which creates an interactive tree browser view based on data with a parent-child relationship.
     *
     * @param {Backbone.Collection} rootCollection
     * Collection of models which for the roots of the tree structure. Each model in this collection requires the
     * following methods to work:
     *  - getChildren()
     *    Returns Backbone.Collection of child models (e.g. chapter->sections) or undefined.
     *  - getParent()
     *    Returns parent model (e.g. section->chapter) or undefined (e.g. group->undefined).
     *  - getSiblings()
     *    Returns sibling models connected to the same parent (e.g. section->all sections of the parent chapter).
     *
     * @param {Boolean|Function|undefined} isSelectable
     * Optional Boolean or Function which takes the model of the tree item as the first argument. If the Boolean is
     * true or the function evaluates to true, allow this tree item to have a checkbox or radiobutton input element.
     *
     * @param {Boolean|undefined} isSingleSelect
     * If both this and isSelectable are true, use a radiobutton element instead of a checkbox input element, allowing
     * for only one item in the entire tree to be selected at the time.
     *
     * @param {Object|undefined} additionalWidgetOptions
     * Optional options to add extra widgets (like buttons, progress bars, etc.) to the tree item view.
     * See resolveAdditionalWidgetOptions in TreeItem view for more information.
     *
     * @param {Boolean|undefined} allClosedByDefault
     * If true, none of the tree items are unfolded when the component is created. Useful if to not overwhelm the
     * user with information and also save on render time.
     *
     * @param {Number|undefined} maxDepth
     * If set, defines a maximum depth till which child tree branches can be added. For example useful to prevent
     * activities to show up in a tree that only cares about chapters and sections. The depth is 0 at the root,
     * 1 the direct children of the root, etc. Setting maxDepth to one means that only the root and its direct children
     * are rendered in the tree view.
     *
     * @param {Object|undefined} styleOptions
     * Optional options to modify default styling of tree item view. For example {hasArrowLeft: true} shows the
     * arrow on the left side of the item.
     *
     * @param {Object|undefined} contentLabelOptions
     * Optional options to set/override ContentLabel options throughout the tree.
     *
     * @param {Backbone.Collection} publishedContentTree
     * If a TreeView has publishedContentTree, the contentLabel needs to rendered slightly different
     * Pass the model in the publishedContentTree to the ContentLabel in that case
     *
     * @param {boolean[]} collapsedRootItems
     * The collapsed/expanded toggle state of the root TreeItems
     *
     * @param {boolean[]} collapsedChildren
     * The collapsed/expanded toggle state of the children
     *
     * @param {Function} endOfCollectionCallback
     * After a TreeItem has rendered every children, this function, if provided, will provide
     * a hook to perform more actions, e.g. adding an element after the last rendered child element
     *
     * @param {Function} onToggleFold
     * If this function is passed, it will be called when a TreeItem is expanded or collapsed
     *
     * @param {boolean} alwaysRenderArrow
     * Normally, TreeItems without children do not have arrows to expand/collapse the children.
     * In some cases it might be necessary to override this behaviour
     *
     * @param {boolean} calledFromChangeStructureOverlay
     * if this TreeView was instantiated in the "Change structure overlay"
     */
    initialize({
        rootCollection,
        isSelectable,
        isSingleSelect,
        singleSelectCallback,
        additionalWidgetOptions,
        allClosedByDefault,
        maxDepth = Infinity,
        styleOptions,
        contentLabelOptions,
        expandParentsOfSelectedItems,
        publishedContentTree,
        collapsedChildren,
        collapsedRootItems,
        endOfCollectionCallback,
        onToggleFold,
        hideAdaptiveStudent = false,
        alwaysRenderArrow = false,
        calledFromChangeStructureOverlay = false
    }) {

        let groupModel

        if (
            rootCollection instanceof Backbone.Collection &&
            rootCollection.at(0) &&
            rootCollection.at(0).getGroupModel
        ) {
            groupModel = rootCollection.at(0).getGroupModel()
        } else if (Array.isArray(rootCollection) && rootCollection.length && rootCollection[0].getGroupModel) {
            groupModel = rootCollection[0].getGroupModel()
        }

        this.groupModel = groupModel
        this.publishedContentTree = publishedContentTree

        // if rootCollection has .getGroupModel (note: GroupModel also has .getGroupModel
        // which returns itself), double check if the given rootCollection is valid
        // for the amount of layers
        const collection = groupModel ? this.getRealRootCollection(rootCollection) : rootCollection

        this.expandParentsOfSelectedItems = expandParentsOfSelectedItems

        this.setElement(Template({
            Styles,
            calledFromChangeStructureOverlay,
        }));

        // Plant tree roots
        let index = 0
        for (let [id, model] of collection.entries()) {

            if (hideAdaptiveStudent && model.get('type') === 'adaptive_student') {
                continue
            }

            const depthMap = TreeView.getDepthMap(model, maxDepth)
            const parentsOfSelectedItems =
                expandParentsOfSelectedItems && this.getParentsOfSelectedItems(model, depthMap, maxDepth)

            this.rootTreeItem = this.addChildView(new TreeItem({
                model,
                isSelectable,
                isSingleSelect,
                singleSelectCallback,
                additionalWidgetOptions,
                allClosedByDefault: collapsedRootItems ? collapsedRootItems[index] : allClosedByDefault,
                collapsedChildren: collapsedChildren && collapsedChildren[index],
                maxDepth,
                rootCid: this.cid,
                styleOptions,
                contentLabelOptions,
                parentsOfSelectedItems,
                depthMap,
                publishedContentTree: publishedContentTree && publishedContentTree.get(id),
                hideAdaptiveStudent,
                endOfCollectionCallback,
                alwaysRenderArrow,
                onToggleFold,
            }), this.$el)

            // normally, if TreeView has endOfCollectionCallback, the callback is
            // called at the end of the collection. this also needs to happen if
            // a chapter or section is empty. Example: render a "add layer" row to end
            // of collection. Such a row also needs to be rendered for empty
            // chapters or sections. Important: filter out adaptive_student
            if (endOfCollectionCallback && collection.constructor.type !== 'activities') {

                const children = model.getChildren()

                // Note: Backbone.Collection.filter does not return a Collection
                const childrenWithoutAdaptiveStudent = new children.constructor(
                    children.filter(child => child.get('type') !== 'adaptive_student')
                )

                if (childrenWithoutAdaptiveStudent.length === 0) {
                    // important! pass the element of the treeItem , not the treeView
                    endOfCollectionCallback(this.rootTreeItem.el, childrenWithoutAdaptiveStudent)
                }

            }

            index += 1

        }

        if (endOfCollectionCallback) {
            endOfCollectionCallback(this.el, collection)
        }
    }

    getRealRootCollection(rootCollection) {

        // for published content there is different way to find root layer
        // (see ContentLabelModel)
        if (this.publishedContentTree) {
            return rootCollection
        }

        if (rootCollection.constructor.type === 'groups') {
            return rootCollection
        }

        switch (this.groupModel.get('layers')) {

            // everything is allowed
            case 3: {
                return rootCollection
            }

            // replace chapters collection, other collections allowed
            case 2: {
                if (rootCollection.constructor.type === 'chapters') {
                    const dummyChapter = Backbone.Collection.chapters
                        .findWhere({ group_id: this.groupModel.id })

                    if (dummyChapter) {
                        return dummyChapter.sections
                    }
                }

                return rootCollection

            }
            case 1 : {
                if (rootCollection.constructor.type === 'activities') {
                    return rootCollection
                }

                const dummyChapter = Backbone.Collection.chapters
                    .findWhere({ group_id: this.groupModel.id })

                if (dummyChapter) {
                    return dummyChapter.sections.at(0).activities
                }
                return rootCollection

            }
            default: {
                return rootCollection
            }
        }
    }

    /**
     * When planting the root brances, calculate depth of it and all its child items recursively
     *
     * @param {Backbone.Model} parentModel  measure depth of this model and its child models
     * @param {Number|Infinity} maxDepth    maximum depth to stop iterating at
     * @param {Number} depth                last known depth, needed for recursion
     * @param {Map} map                     map of model identifiers and its depth in the tree, needed for recursion
     * @returns {Map}                       The complete map of depths for this branch from the root of the tree
     */
    static getDepthMap(parentModel, maxDepth = Infinity, depth = 0, map = new Map()) {
        map.set((parentModel.constructor.type ?? '') + parentModel.id, depth)
        const children = parentModel.getChildren()
        if (depth < maxDepth && children) {
            for (const childModel of children) {
                map = TreeView.getDepthMap(childModel, maxDepth, depth + 1, map)
            }
        }
        return map
    }

    /**
     * For all depths, find the items that have selected children on a lower level. Save the
     * ids of these items. These are used by the TreeItems to expand those tree items
     * if the TreeView has the options "expandParentsOfSelectedItems"
     *
     * @param {Backbone.Model} parentModel
     * the model to start with. traverse the model to find child models with isChecked attribute
     * @param {Map} depthMap    pass the previously calculated map of depth level per model
     * @param {Number|Infinity} maxDepth    maximum depth to stop iterating at.
     * @param {number} previousDepth
     * the last known depth. needed for the recursion
     * @param {Map} map
     * the result map. contains the depth (1, 2, n) as key, and the ids of the models
     * on that depth that have a selected child. because these ids should be unique,
     * a set is used
     * @returns {Map} see description of param map
     */
    getParentsOfSelectedItems(parentModel, depthMap, maxDepth, previousDepth = 0, map = new Map()) {

        if (this.isParentOfSelectedItem(parentModel)) {

            let iterationDepth = previousDepth - 1
            let previousParent = parentModel

            while ( iterationDepth >= 0 ) {

                previousParent = previousParent.getParent()

                if (!previousParent) {
                    break
                }

                // If the iteration depth does not match the depth of the real parent element in the depth map
                // continue the loop to get the parent of that parent until the actual parent model is reached.
                // This is relevant for groups with less than 3 layers, where getting the parent of a model may
                // return a "dummy" section/chapter instead of the actual parent needed to render in the tree view.
                if (depthMap.get((previousParent.constructor.type ?? '') + previousParent.id) !== iterationDepth) {
                    continue
                }

                if (!map.has(iterationDepth)) {
                    map.set(iterationDepth, new Set([ previousParent.id ]))
                } else {
                    map.get(iterationDepth).add(previousParent.id)
                }

                iterationDepth -= 1
            }
        }

        const children = parentModel.getChildren()
        if (previousDepth < maxDepth && children) {
            for (const childModel of children) {
                map = this.getParentsOfSelectedItems(childModel, depthMap, maxDepth, previousDepth + 1, map)
            }
        }

        return map
    }

    /**
     * @param {Backbone.Model} parentModel parent model
     * @returns {boolean} matches condition
     */
    isParentOfSelectedItem(parentModel) {
        // if there is a custom callback, use that function
        if (typeof this.expandParentsOfSelectedItems === 'function') {
            return this.expandParentsOfSelectedItems(parentModel)
        }
        // default to checking the "isChecked" attribute of the parent
        return parentModel.get('isChecked')
    }
}
