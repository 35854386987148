export default class ProgressBarUtil {

    static getStudentProgressFragments(hasVisibleAnswers, hasVariableTaskCount, score, percChecked, progress) {
        if (
            hasVisibleAnswers === false ||
            (!_.isNumber(score) && !_.isNumber(percChecked))
        ) {
            return {
                blue: progress
            }
        }
        if (hasVariableTaskCount) {
            return {
                green: score,
                red: 1 - score
            }
        }
        return {
            green: progress * percChecked * score,
            red: progress * percChecked * (1 - score),
            gray: progress - progress * percChecked
        }
    }

}