export default Backbone.View.extend({

    // Whether the view is on the DOM
    isAttached: false,

    /**
     * onRoute
     *
     * Optional behaviour for when a navigation event occours.
     *
     * @param {Function} callback   Something to do when navigation happens.
     */
    onRoute(callback) {
        this.listenToOnce(window.app.router, 'route', callback);
    },

    /**
     * stopAllEvents
     *
     * This function is an holder to call three jQuery functions
     * who prevent events and stuff.
     *
     * @param  {Event} e    event object
     */
    stopAllEvents(e) {
        if (e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
        }
    },

    /**
     * replaceTemplate
     *
     * This function will safely replace the template with a new given
     * one. This can be used for rerender methods or methods that needs
     * to be rendered after the model has been loaded.
     *
     * @param  {$el} newTemplate    New element to replace old one with
     */
    replaceTemplate(newTemplate) {

        // Make sure the new template is a jQuery element to enable
        // jQuery methods for the new template. This won't break if an
        // jQuery element has been passed as argument.
        newTemplate = $(newTemplate);

        // Remove all the bound events
        this.undelegateEvents();

        // Store reference to old element so we can destroy it later
        // safely after replacement
        var oldElement = this.$el;

        // Replace element in the DOM using jQuery's replaceWith method
        this.$el.replaceWith(newTemplate);

        // Make the new element backbone friendly by calling backbone's
        // setElement method. This will attach the element to the view's
        // $el object
        this.setElement(newTemplate);

        // Clear old element from DOM
        oldElement.remove();

        // Redelegate backbone events back to the view
        this.delegateEvents();
    },

    /**
     * addChildView
     *
     * This method will add the view to the DOM. It will also
     * make sure the view is registered as child view.
     *
     * @param  {Backbone.View} view     View that should be added to DOM
     * @param  {$.el | string} element  jQuery element to which the view should be added
     * @param  {string} type            String of the method that should be used (optional)
     * @param  {boolean} silent         Boolean for calling the child view silent
     * @return {Backbone.View}          Return the view that is added to the DOM
     */
    addChildView(view, element, type, silent) {

        // Sometimes we need to create a child view without adding it to
        // the DOM because this process is being handled trough different
        // views. Therefore the option was added to do nothing when element
        // is not set.
        //
        // An example of this is featured content in the user/showview
        if (element
            && (
                typeof view.shouldRender === 'function'
                && view.shouldRender()
            )
        ) {

            let selector

            // Check if the passed element is a string (selector)
            if (typeof element === 'string') {

                selector = element

                // Select the element for this view
                element = this.$(element)
            }

            // If multiple elements match, only add to the first one
            if (element.length > 1) {
                element = element.first()
                console.warn('Multiple elements match "' + selector + '". Only adding to the first one.')
            }

            // Check if the element exists
            if (element.length === 0) {

                // Warn the developer
                console.warn(
                    `Trying to add a view to a not existing or not reachable element! "${selector}"`,
                    arguments
                );
            }

            // Check if there is a type set, if this is set use the type
            // as method in the jQuery element. If not set it will use append
            // by default
            element[(type) ? type : 'append'](view.$el);
        }

        // Register the view as a child so it can be destructed nicely
        this.registerChildView(view);

        if (typeof view.show === 'function' && !silent) {

            // Call the show method
            view.show();
        }

        // Return the view
        return view;
    },

    addSvelteChildView(element, component, props = {}) {

        // Detect if it's a Svelte view
        // Cannot be done the logical way (eg. ViewConstructor instanceof SvelteComponent)
        // https://github.com/sveltejs/svelte/issues/3360#issuecomment-518657022
        if (typeof component.prototype?.$destroy !== 'function') {
            console.error('Cannot add child: not a Svelte component')
            window.sentry.withScope(scope => {
                scope.setExtra('element', element)
                scope.setExtra('component', component?.name)
                window.sentry.captureException(new Error('Cannot add child: not a Svelte component'))
            })
            return
        }

        if (typeof element === 'string') {
            element = this.el.querySelector(element);
        } else if (element instanceof jQuery) {
            if (element.length > 1) {
                element = element.first()
                console.warn(`Multiple elements match "${element}". Only adding to the first one.`)
            }

            if (element.length === 0) {
                console.warn(
                    `Trying to add a view to a not existing or not reachable element! "${element}"`,
                    arguments
                );
            }

            element = element[0];
        }

        if (!(element instanceof Element)) {
            console.error('element parameter is not an existing DOM Element. Unable to mount Svelte view')
            window.sentry.withScope(scope => {
                scope.setExtra('element', element)
                scope.setExtra('component', component?.name)
                window.sentry.captureException(new Error('element parameter is not an existing DOM Element. Unable to mount Svelte view'))
            })
            return
        }

        const view = new component({
            target: element,
            props
        })

        this.registerChildView(view);
        return view;
    },

    registerChildView(view) {
        if (!this.childViews) {
            this.childViews = [];
        }

        if (_.indexOf(this.childViews, view) === -1) {
            this.childViews.push(view);
            if (!this.isAttached && typeof view.setAttached === 'function') {
                view.setAttached();
            }
        }
    },

    unregisterChildView(view) {
        if (this.childViews) {
            var index = _.indexOf(this.childViews, view);
            if (index > -1) {
                this.childViews.splice(index, 1);
            }
        }
    },

    unregisterAndDestroyChildView(childView) {
        this.unregisterChildView(childView);
        if (typeof childView.destroy === 'function') {
            childView.destroy()
        }
        if (typeof childView.$destroy === 'function') {
            childView.$destroy()
        }
    },

    appendTo($node) {
        this.$el.appendTo($node);

        // Do not update isAttached here because being appended doesn't
        // necessarily mean it's also attached to the DOM
    },

    /**
     * onShowAndLoaded
     *
     * We can use this method to check if the view is loaded and the model of the view
     * is loaded. This callback can be used to execute functions using a fetched model
     * after the view is shown.
     *
     * Previously we did an this.onShowAndLoaded = _.after(2, this.showAndLoaded) but
     * that gave some problems when the show was called multiple times. The result was
     * that the model was not fully loaded and the view was throwing some errors.
     *
     * This method makes sure the show AND loaded are called
     *
     * @param  {string} type    Type of what is loaded
     */
    onShowAndLoaded(type) {

        // Logic to set the isShown by default to false, unless the method is called with
        // as first argument 'show', then it is true
        this.isShown = (type === 'show') ? true : this.isShown || false;

        // Logic to set the isLoaded by default to false, unless the method is called with
        // as first argument 'loaded', then it is true
        this.isLoaded = (type === 'loaded') ? true : this.isLoaded || false;

        if (this.isShown && this.isLoaded) {

            if (_.size(arguments)) {

                // Do a hack to remove the first item from arguments (which will be type)
                // we do this because the arguments variable is not an array type, so we
                // cannot call arguments.shift();
                [].shift.call(arguments, 1);
            }

            // Call the showAndLoaded method with this as scope and pass on the arguments
            this.showAndLoaded(...arguments)
        }
    },

    showAndLoaded() {
        console.warn('Show and loaded called, but no show and loaded method defined');
    },

    // TODO Replace this method with updateAttached
    setAttached() {
        this.isAttached = true;
        if (this.childViews) {
            for (var i = 0; i < this.childViews.length; i++) {
                var childView = this.childViews[i];
                if (typeof childView.setAttached === 'function') {
                    childView.setAttached();
                }
            }
        }

        this.onAttach();
    },

    updateAttached(attached) {
        if (this.isAttached !== attached) {
            this.isAttached = attached;
            if (this.childViews) {
                for (var i = 0; i < this.childViews.length; i++) {
                    var childView = this.childViews[i];
                    if (typeof childView.updateAttached === 'function') {
                        childView.updateAttached(this.isAttached);
                    }
                }
            }

            if (this.isAttached) {
                this.onAttach();
            } else {
                this.onDetach();
            }
        }
    },

    detach() {
        this.$el = this.$el.detach();
        this.updateAttached(false);
    },

    attachTo(element) {
        this.$el.appendTo(element);
        this.updateAttached(true);
    },

    hide(callback) {
        callback(this);
    },

    show() {
        this.onShowAndLoaded('show');
    },

    destroy() {
        this.beforeDestroy();
        this.destroyChildViews();
        this.remove();
        this.onDestroy();
    },

    getChildViewsOfInstance(instance) {

        // Return array with childs
        return _.filter(

            // From the childview collection
            this.childViews,

            // Filter out the childs that are instance of instance
            child => (child instanceof instance)
        );
    },

    destroyChildViewsOfInstance(instance) {

        // Check if there are any childview
        if (this.childViews) {

            // Create an filtered array
            var childsOfInstance = this.getChildViewsOfInstance(instance);

            // Loop trough the childs that are a instance of instance
            for (var i = childsOfInstance.length - 1; i >= 0; i--) {

                // Distill child view from childview array
                var childView = childsOfInstance[i];

                // Remove the childview as childview
                this.unregisterChildView(childView);

                // Call destroy on child
                if (typeof childView.destroy === 'function') {
                    childView.destroy()
                }
                if (typeof childView.$destroy === 'function') {
                    childView.$destroy()
                }
            }
        }
    },

    destroyChildViews() {
        if (this.childViews) {
            for (var i = this.childViews.length - 1; i >= 0; i--) {
                var childView = this.childViews[i];
                this.unregisterChildView(childView);
                if (typeof childView.destroy === 'function') {
                    childView.destroy()
                }
                if (typeof childView.$destroy === 'function') {
                    childView.$destroy()
                }
            }
        }
    },

    /**
     * bindAll - bind the given method names to the this of the view
     *
     * @param {string[]} methodNames Array of methodNames
     */
    bindAll(methodNames) {
        methodNames.forEach(methodName => {
            if (typeof this[methodName] !== 'function') {
                throw new Error('Trying to bind non-existing method "' + methodName + '" to a view.')
            }
            this[methodName] = this[methodName].bind(this);
        });
    },

    /**
     * shouldRender
     *
     * This method determines if a view should be rendered when
     * added via the addChildView method. It allows us to keep
     * elements from rendering when specific conditions are true.
     * For example: We want to hide a newsblock from rendering
     * when the users has an exam product.
     *
     * @return {Bool} whether the view should be rendered or not
     */
    shouldRender() {
        return true;
    },

    // Placeholder methods

    // onBeforeAttach: function() {},

    onAttach() {},

    // onBeforeDetach: function() {},

    onDetach() {},

    // onBeforeShow: function() {},

    // onAfterShow: function() {},

    // onBeforeHide: function() {},

    // onAfterHide: function() {},

    onDestroy() {},

    beforeDestroy() {}

});
