import Styles from './Source4.scss';

import Template from './Source4.hbs';
import Util from 'util/util';

// External link source view
export default BaseView.extend({

    initialize() {

        if (!this.model.get('source')) {
            this.model.set('source', {});
        }

        this.provider_url = '';
        if (typeof this.model.get('source').provider_url !== 'undefined') {
            this.provider_url = this.model.get('source').provider_url;
        }

        // Validate the URL. This prevents rendering risky href="javascript:alert('xss')" URLs
        let contextUrl = this.model.get('source').url
        if (!Util.validateURL(contextUrl)) {
            contextUrl = ''
        }

        this.setElement(Template({
            Styles,
            hasUrlData: !!contextUrl,
            contextUrl,
            title: this.model.get('title') || this.model.get('source').title,
            intro: this.model.get('source').description,
            prettyLink: this.provider_url ?
                this.provider_url.replace('http://', '').replace('www.', '') :
                contextUrl
        }));
    }

});
