import Template from './LostPasswordSuccessModal.hbs';
/**
 * This modal instance is triggered when the user did a POST request with a username/email
 */

import LostPasswordStyles from 'views/components/modals/lostPassword/LostPassword.scss';

export default BaseView.extend({

    initialize(options) {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles: LostPasswordStyles,
            username: options.username
        }));

    }

});
