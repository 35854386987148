var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/isNotMobile.js")).call(alias1,{"name":"isNotMobile","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":11,"column":20}}})) != null ? stack1 : "")
    + "    <div class=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options__option") : stack1), depth0))
    + " js-online-sidebar\" data-label=\""
    + alias2(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Invite students",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":12,"column":74},"end":{"line":12,"column":98}}}))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"people",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":28}}})) != null ? stack1 : "")
    + "\n        <span class=\"js-online-number\"></span>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options__option") : stack1), depth0))
    + " js-author\" data-label=\""
    + alias1(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias2,"Enter author mode",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":66},"end":{"line":8,"column":92}}}))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias2,"author",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":28}}})) != null ? stack1 : "")
    + "\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options__option") : stack1), depth0))
    + " js-fullscreen\" data-label=\""
    + alias1(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias2,"Enter fullscreen mode",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":19,"column":70},"end":{"line":19,"column":100}}}))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias2,"fullscreen",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":32}}})) != null ? stack1 : "")
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"left-side") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options__option") : stack1), depth0))
    + " js-exit\" data-label=\""
    + alias2(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Exit presentation",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":64},"end":{"line":2,"column":90}}}))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":27}}})) != null ? stack1 : "")
    + "\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isTeacher") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isFullscreenPossible") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"right-side") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options__option") : stack1), depth0))
    + " js-back\" data-label=\""
    + alias2(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Previous",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":25,"column":64},"end":{"line":25,"column":81}}}))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-back",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":32}}})) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options__option") : stack1), depth0))
    + " js-slide-index\"></div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"options__option") : stack1), depth0))
    + " js-next\" data-label=\""
    + alias2(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Next",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":29,"column":64},"end":{"line":29,"column":77}}}))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-forward",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":30,"column":35}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});