import ChapterModel from 'models/ChapterModel';

export default class ChaptersCollection extends Backbone.Collection {

    get model() {
        return ChapterModel
    }

    get comparator() {
        return 'sequence'
    }

    preinitialize() {
        this.constructor.type = 'chapters'
    }

    parse(response) {
        if (Array.isArray(response)) {
            // First sort the backend response by sequence
            response.sort((a, b) => {
                return Math.sign(a.sequence - b.sequence)
            })
            // Then set the index, use no increment for numberless
            let index = 0
            for (const chapter of response) {
                if (chapter.numberless) {
                    chapter.index = index
                    continue
                }
                index++
                chapter.index = index
            }
        }
        return response
    }

    setSequence(cidToSequenceArray = [], noCall) {
        const idToSequenceArray = cidToSequenceArray.map((cid, sequence) => {
            return this.get(cid).set({
                sequence,
            }).id
        })

        // noCall is a boolean whether the update sequence call should be triggered
        if (!noCall) {
            this.saveSequence(idToSequenceArray)
        }
    }

    saveSequence(chapterIds) {
        return $.post('/chapters/update_sequence.json', {
            chapter_ids: chapterIds,
        });
    }

}
