<script>
    import ProgressBar from 'views/components/progressBar/ProgressBar.svelte'
    import ContentLabel from 'views/components/contentLabel/ContentLabel.svelte'
    import Icon from 'views/components/icon/Icon.svelte'

    export let model

    const progress = model.get('progress')
    const hasProgress = _.isFinite(progress)
    const progressPercentage = hasProgress && Math.round(progress * 100) + '%'
    const showLinkToProgress = ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW)
</script>

<div class="slot">
    <a href="/sections/show/{model.id}" class="content">
        <ContentLabel {model}></ContentLabel>
    </a>

    {#if hasProgress}
        <div class="navigation">
            {#if showLinkToProgress}
                <a href="/sections/progress/{model.id}" class="button">
                    <Icon name="progress"></Icon>
                    <span>{window.i18n.gettext('Progress')}</span>
                    <strong>{progressPercentage}</strong>
                </a>
            {:else}
                <div class="button locked">
                    <div class="progress-bar">
                        <ProgressBar fragments={{ blue: progress }}></ProgressBar>
                    </div>
                    <strong>{progressPercentage}</strong><span>{window.i18n.gettext('made')}</span>
                </div>
            {/if}
        </div>
    {/if}
</div>

<style lang="scss">
    @import "src/styling/globals.scss";

    .slot {
        @include box-shadow;

        margin-bottom: 12px;
        background: $white;
        border-radius: 12px;

        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            overflow: hidden;
            text-decoration: none;
            padding: 16px;

            &:hover {
                :global([component=content-label__name]) {
                    color: $blue;
                }
            }
        }

        .navigation {
            display: flex;
            border-top: 1px solid $background-gray;
            white-space: nowrap;
            overflow-x: hidden;

            .button {
                user-select: none;

                display: flex;
                align-items: center;
                padding: 12px 16px;
                border-right: 1px solid $background-gray;

                color: $blue-black-80;
                fill: currentColor;
                text-decoration: none;

                transition: color 0.35s $ease-in-out-quad, background 0.35s $ease-in-out-quad;

                &:first-child {
                    border-bottom-left-radius: 12px;
                }

                &:not(.locked):hover {
                    cursor: pointer;
                    color: $black;
                    background: $white-highlight;
                }

                span {
                    margin-left: 4px;

                    @media (max-width: 620px) {
                        display: none;
                    }
                }

                strong {
                    margin-left: 4px;
                    font-weight: 400;
                    color: $blue-black;
                }

                :global(svg) {
                    width: 16px;
                    height: 16px;
                }

                .progress-bar {
                    margin-left: 4px;
                    margin-right: 16px;
                    width: 200px;

                    @media (max-width: 620px) {
                        width: 100px;
                    }
                }
            }
        }
    }
</style>