var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"student-grading") : stack1), depth0))
    + "\">\n    <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grading-label") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"I answered this question",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":76}}}))
    + "</span>\n    <span>\n        <span data-score=\"0\" class=\"js-score "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grading-option") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grading-option--red") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Incorrect",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":103},"end":{"line":4,"column":121}}}))
    + "</span>\n        <span data-score=\"0.5\" class=\"js-score "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grading-option") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grading-option--orange") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Almost correct",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":108},"end":{"line":5,"column":131}}}))
    + "</span>\n        <span data-score=\"1\" class=\"js-score "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grading-option") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grading-option--green") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Correct",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":6,"column":105},"end":{"line":6,"column":121}}}))
    + "</span>\n    </span>\n</div>";
},"useData":true});