import Styles from './NewsCard.scss';

import Template from './NewsCard.hbs';
import NewsMessage from '../../teacherCards/newsCard/newsMessage/NewsMessage'
import Util from 'util/util'

export default class NewsCard extends BaseView {
    get events() {
        return {
            click: 'onClickNews',
            'click .js-hide': 'onClickHideNews',
        }
    }

    initialize() {
        this.setElement(Template({
            Styles,
            title: this.model.get('title'),
            description: Util.stripTags(this.model.get('html_text')),
            date: dayjs(this.model.get('last_published')).format('dddd D MMMM YYYY'),
            buttonText: window.i18n.gettext('Read more')
        }))
    }

    onClickNews() {
        Backbone.View.sidebar.showSidebar(
            new NewsMessage({
                model: this.model
            }),
            window.i18n.gettext('News'),
            520
        )
    }

    onClickHideNews(event) {
        event.stopPropagation()
        $.post({
            url: '/users/set_news_to_closed/' + this.model.id + '.json'
        })
        window.statsTracker.trackEvent(
            'News',
            'Hide message',
            this.model.id
        )
        this.destroy()
    }
}
