var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " js-fullscreen";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-outer--mobile") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-container--fullscreen") : stack1), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-background"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFullscreen") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":66}}})) != null ? stack1 : "")
    + " "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-outer") : stack1), depth0))
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/isMobile.js")).call(alias1,{"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":91},"end":{"line":2,"column":150}}})) != null ? stack1 : "")
    + " no-print\" role=\"dialog\" aria-labelledby=\"modal-title-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"cid") : depth0), depth0))
    + "\">\n\n    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-inner") : stack1), depth0))
    + " js-background\">\n\n        <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-viewport") : stack1), depth0))
    + " js-background\">\n\n            <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-container") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFullscreen") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":118}}})) != null ? stack1 : "")
    + " js-modal-component-container js-dont-close-sidebar\">\n\n                <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-content") : stack1), depth0))
    + "\">\n\n                    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-title") : stack1), depth0))
    + " js-title\">\n\n                        <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-title__items") : stack1), depth0))
    + " js-title-items\" id=\"modal-title-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"cid") : depth0), depth0))
    + "\"></div>\n                        <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-title__icons") : stack1), depth0))
    + "\">\n                            <button class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-title__icons--icon") : stack1), depth0))
    + " js-close-modal\" title=\""
    + alias3(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias1,"Close",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":22,"column":104},"end":{"line":22,"column":118}}}))
    + "\">\n                                "
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/shape.js")).call(alias1,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":23,"column":32},"end":{"line":23,"column":51}}})) != null ? stack1 : "")
    + "\n                            </button>\n                        </div>\n                    </div>\n\n                    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-included-view") : stack1), depth0))
    + " js-modal-included-view\"></div>\n\n                    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-buttons") : stack1), depth0))
    + "  js-modal-buttons\"></div>\n                </div>\n\n                <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-sidebar") : stack1), depth0))
    + " js-modal-sidepanel\">\n\n                    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-sidebar__title") : stack1), depth0))
    + " js-modal-sidebar-title\"></div>\n\n                    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal-sidebar__text") : stack1), depth0))
    + " js-modal-sidebar-text\"></div>\n                </div>\n            </div>\n    </div>\n</div>\n";
},"useData":true});