import Styles from './GroupChooser.scss';

import Template from './GroupChooser.hbs';
import ContentLabel from 'views/components/contentLabel/ContentLabel'
import NoGroupAlertTemplate from 'views/pages/users/noGroupsAlert/NoGroupsAlert.hbs'

export default BaseView.extend({

    /**
     * Modal to pick the current group context. This modal should only be called via
     * Backbone.View.layout.openGroupChooser(action), and then only from navigation events if no current
     * group context is set (Backbone.View.menubar.getGroup() === undefined) or to change it explicitly from some
     * global navigation element like the crumblepath.
     *
     * @param {String} action       Target page action (part after the second /)
     */
    initialize({
        action
    }) {
        const list = this.generateGroupList(Backbone.Model.user.get('is_student'), action)
        this.setElement(Template({
            Styles
        }))
        this.$el.append(list)
    },

    /**
     * Generate list of clickable ContentLabels to populate the list of groups.
     *
     * @param {Boolean} isStudent   If current user is student, show different labels and alter navigation rules.
     * @param {String} action       Target page action (part after the second /)
     * @returns {Array}             Array of ContentLabel components
     */
    generateGroupList(isStudent, action) {
        const isForStudentPlanner = isStudent && action === 'planner'
        const currentGroup = Backbone.View.layout?.menubar?.getGroup();

        const list = Backbone.Collection.groups.reduce((m, model) => {

            // For students navigating to the planner, only list groups that have an active planner.
            if (isForStudentPlanner && !model.get('has_active_planner')) {
                return m
            }

            const contentLabel = new ContentLabel({
                model,
                labelSuffix: isStudent ? model.get('name') : model.getCourseModel().get('name'),
                hasNoLink: true,
                hasNoLinkCallback: () => {
                    this.navigateToGroup(model, action)
                }
            });

            if (currentGroup && currentGroup.id === model.id) {
                contentLabel.el.classList.add(Styles['active']);
                contentLabel.el.addEventListener('click', () => {
                    Backbone.View.Components.modal.close()
                })
            }

            if (model.get('is_favorite')) {
                contentLabel.el.classList.add(Styles['is-favorite']);
            }

            m.push(contentLabel.$el)
            return m
        }, [])

        if (list.length) {
            return list
        }

        return `<p>${NoGroupAlertTemplate()}</p>`
    },

    navigateToGroup(groupModel, action) {

        // For teachers navigating from a content page to another group,
        // check if the same content is present in the other group.
        // If so, navigate there directly.
        const currentId = parseInt(window.app.controller.activeParams[0])
        const level = window.app.controller.activePath[0]
        if (
            Backbone.Model.user.get('is_teacher') &&
            currentId &&
            ['chapters', 'sections', 'activities'].includes(level) &&
            ['show', 'progress', 'author'].includes(action)
        ) {
            const similarModel = this.getSimilarContent(currentId, Backbone.Collection[level], groupModel)
            if (similarModel) {
                Backbone.history.navigate(level + '/' + action + '/' + similarModel.id, { trigger: true })
                return
            }
        }

        // For students navigating to results, the URL format is different
        if (action === 'results' && Backbone.Model.user.get('is_student')) {
            Backbone.history.navigate('/students/results/' + groupModel.id + '/' + Backbone.Model.user.id, { trigger: true })
            return
        }

        // In all other cases, navigate to the root of the group
        Backbone.history.navigate('groups/' + action + '/' + groupModel.id, { trigger: true })
    },

    getSimilarContent(currentId, collection, groupModel) {

        // Get the source id of the current content
        const sourceId = collection.get(currentId)?.getSourceId()
        if (!sourceId) {
            return
        }

        // Check if the chosen group contains content with the same source id
        return collection.find((model) => {
            return (model.getSourceId() === sourceId && model.getGroupModel().id === groupModel.id)
        })
    }

})
