import Styles from './End.scss';

import Template from './End.hbs';
import Spinner from 'views/components/spinner/Spinner';
import Button from 'views/components/button/Button'

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'responseSaveStateChanged',
            'handInExam',
            'showCompleted'
        );

        this.isClosedByTeacher = options.isClosedByTeacher;
        this.work_on = options.work_on;
        this.isStudentInSEB = this.work_on.isStudentInSEB;

        Backbone.history.navigate(
            'activities/show/' + this.model.id + '/finished', {
                trigger: false
            }
        );

        this.setElement(Template({
            Styles
        }));

        this.spinner = this.addChildView(
            new Spinner({}),
            this.$('.js-spinner')
        );

        // If the exam was not closed by the teacher, remind ourselves to hand in the exam after all responses are saved
        this.examNotHandedIn = !this.isClosedByTeacher

        // If there are unsaved responses, start saving them
        if (Backbone.Model.user.responsesBuffer.getUnsyncedActivityResponses(this.model.id).length > 0) {
            this.listenTo(
                Backbone.Model.user.responsesBuffer,
                'update',
                this.responseSaveStateChanged
            );
            Backbone.Model.user.responsesBuffer.syncResponseToServer();
        } else {
            this.responseSaveStateChanged();
        }

    },

    // This function continues to the next step when a response has been saved
    responseSaveStateChanged() {

        // If this activity still has unsaved responses, keep showing the loading state
        if (Backbone.Model.user.responsesBuffer.getUnsyncedActivityResponses(this.model.id).length > 0) {
            return
        }

        // If we need to hand in the exam, do so now
        if (this.examNotHandedIn) {
            this.handInExam()
            return
        }

        // We are done, show the completed screen
        this.showCompleted()
    },

    // Tell the backend that the student wants to hand in the exam
    handInExam() {

        $.post({
            url: '/exam_sessions/hand_in_exam/' + this.model.id + '.json'
        }).then((response) => {

            // When the backend accepts, show the completed screen
            // Otherwise, try again in 5 seconds
            if (response.status === 'success') {
                this.showCompleted()
                return
            }
            setTimeout(this.handInExam, 5000)
        }, () => {

            // When the call fails, try again in 5 seconds
            setTimeout(this.handInExam, 5000)
        })

    },

    // Tell the student that everything has been saved and the exam is completed
    showCompleted() {
        Backbone.Model.user.unset('lockToURL')

        this.spinner.destroy();

        var endMessage = (this.isClosedByTeacher ?
            window.i18n.sprintf(
                window.i18n.gettext('Your teacher just took in this %s. All your answers have been saved.'),
                this.model.getActivityTypeLabel().toLowerCase()
            ) :
            window.i18n.sprintf(
                window.i18n.gettext('You handed in your %s. All your answers have been saved.'),
                this.model.getActivityTypeLabel().toLowerCase()
            )
        );
        this.$('.js-end-message').text(endMessage);

        this.addChildView(new Button({
            label: this.model.get('type') === 'diagnostic_exam' ?
                window.i18n.gettext('Close diagnostic exam') : window.i18n.gettext('Close exam'),
            size: 'medium',
            callback: () => {

                // Navigate to quit Safe Exam Browser.
                if (this.isStudentInSEB) {
                    document.location = '/users/seb_quit';
                    return;
                }

                this.work_on.gotoHome();

            }
        }), '.js-start-finish-btn');
    }

});
