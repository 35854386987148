<script>
    // boolean whether the init state should be checked
    export let isChecked = false
    // boolean whether the init state should be disabled
    export let isDisabled = false
    // string containing the message for when disabled
    export let disabledCallbackText = false;
    // boolean whether the toggle should be dark mode or not
    export let darkMode = false
    // function to be executed on toggle
    export let callback = () => {}
    // label: string containg the label(s) for this toggle
    export let label = ''

    // boolean for wether the label(s) is/are static or statefull.
    // example stateful: "ON/OFF"
    // example static: "Turn feature X on"
    $: labelStateful = typeof label === 'object';

    function onChange() {
        if (isDisabled) {
            if (disabledCallbackText) {
                Backbone.View.layout.openStatus(disabledCallbackText, 'warning')
            }
            return
        }

        setState(!isChecked)
    }

    export function toggleState(silent = false) {
        setState(!isChecked, silent)
    }

    export function setState(checked, silent = false) {
        isChecked = checked

        if (silent) {
            return
        }

        if (callback) {
            callback(isChecked);
        }
    }
</script>

<button
    class="toggle"
    class:toggle--active={isChecked}
    class:toggle--darkmode={darkMode}
    role="switch"
    aria-checked={isChecked}
    on:click|stopPropagation={onChange}
>
    <div class="box">
        <div class="knob"></div>
    </div>

    <div class="label--state">
        {isChecked ? window.i18n.gettext('On').toUpperCase() : window.i18n.gettext('Off').toUpperCase()}
    </div>

    {#if labelStateful}
        <div class="label">
            {isChecked ? label.on : label.off}
        </div>
    {/if}

    {#if !labelStateful && label}
        <div class="label">{label}</div>
    {/if}

</button>

<style lang="scss">
    @import "src/styling/globals.scss";

    .toggle {
        display: flex;
        align-items: center;

        cursor: pointer;

        &--active {
            .box {
                background: $blue;
            }

            .knob {
                margin-left: 18px;
            }

            .label--state {
                color: $blue;
            }

            &:hover {
                .box {
                    background: $blue-dark;
                }

                .knob {
                    margin-left: 16px;
                }
            }
        }

        &:not(&--active):hover {
            .box {
                background: $status-gray;
            }

            .knob {
                margin-left: 2px;
            }
        }

        &--darkmode {
            .label {
                color: $white;
            }

            .label--state {
                color: $white;
            }

            .box {
                background: rgba($white, 35%) !important;
            }

            &.toggle--active {
                .box {
                    background: $status-green !important;
                }
            }
        }
    }

    .box {
        display: flex;
        align-items: center;
        height: 24px;
        width: 42px;

        border: 2px solid transparent;
        border-radius: 24px;
        background: rgba($status-gray, 0.75);

        transition: background 0.35s $ease-in-out-quad;
    }

    .knob {
        width: 20px;
        height: 20px;
        margin-left: 0;

        border-radius: 20px;
        background: $white;

        transition: margin-left 0.35s $ease-in-out-quad;
    }

    .label {
        @include normal-text;

        user-select: none;
        margin-left: 8px;
        color: $blue-black-80;

        &:empty {
            display: none;
        }

        &--state {
            transition: color 0.35s $ease-in-out-quad;
            // Should be enough space for the words "OFF"/"ON", "UIT"/"AAN" without shifting the label.
            min-width: 30px;
            margin-left: 8px;
        }
    }
</style>
