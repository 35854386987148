import Util from 'util/util'

export default class TeacherModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'teacher'
        this.constructor.pluralType = 'teachers'
    }

    last_name_first_name() {
        return Util.lastNameFirstName(this.get('first_name'), this.get('prefix'), this.get('sortable_last_name'))
    }

    first_name_last_name() {
        return Util.firstNameLastName(this.get('first_name'), this.get('prefix'), this.get('sortable_last_name'))
    }

    /**
     * getCrumblepathModel
     *
     * This will return a crumblepath valid model
     *
     * @return {Backbone.Model}     crumblepath valid model
     */
    getCrumblepathModel() {
        // Return crumblepath valid model
        return new Backbone.Model({
            label: this.first_name_last_name(),
            level: 'students',
            path_id: this.get('id')
        });
    }

    /**
     * getAllCrumblepathModels
     *
     * This function will return an array of all the models for this
     * crumblepath
     *
     * @return {Array}      Array with all the models
     */
    getAllCrumblepathModels() {
        return [
            this.getGroupModel().getCrumblepathModel(),
            this.getCrumblepathModel()
        ]
    }

    getGroupModel() {
        // Return the group bound to this teacher
        return Backbone.Collection.groups.get(this.get('group_id'));
    }

}
