import Template from './AdminVerificationModal.hbs';
import Modal from 'views/components/modals/Modal'
import QuickInput from 'views/components/quickInput/QuickInput.svelte'

export default class AdminVerification extends Modal {

    initialize() {

        super.initialize({})

        let request
        const submitCallback = (code) => {
            if (code.length < 8) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Code is invalid')
                )
                return
            }
            request?.abort()
            _.last(this.getButtons()).disable(true)
            request = $.post({
                url: '/administrator/authenticate_session',
                data: {
                    code,
                },
                success: ({status}) => {
                    if (status === 'success') {
                        Backbone.View.layout.closeAdminVerificationModal()
                        Backbone.history.loadUrl(Backbone.history.fragment)
                    } else {
                        Backbone.View.layout.openStatus(
                            window.i18n.gettext('Code is invalid or has expired')
                        )
                        _.last(this.getButtons()).enable()
                    }
                },
                error: () => {
                    _.last(this.getButtons()).enable()
                },
            })
        }

        this.open(AdminVerificationForm, {
            title: window.i18n.gettext('Enter your verification code'),
            removeCloseButton: true,
            submitCallback,
            buttons: {
                [window.i18n.gettext('Go back')]: {
                    theme: 'secondary',
                    callback: () => {
                        Backbone.View.layout.closeAdminVerificationModal()
                        Backbone.history.navigate('/users/settings', true)
                    }
                },
                [window.i18n.gettext('Continue')]: {
                    callback: () => {
                        const code = this.subView.input.getValue()
                        submitCallback(code)
                    }
                }
            }
        })

    }

}

class AdminVerificationForm extends BaseView {
    initialize({
        submitCallback,
    }) {
        this.setElement(Template({}))

        this.input = this.addSvelteChildView(
            this.el,
            QuickInput,
            {
                maxLength: 8,
                placeholder: window.i18n.gettext('Code'),
                submitCallback,
                showSubmitButton: false,
                isXL: true,
                autoFocus: true
            }
        )
    }
}
