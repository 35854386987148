import Score from 'util/Score';

export default Backbone.Model.extend({

    defaults: {
        isVisible: true
    },

    initialize() {
        this.set({
            name: this.get('subject')
        });
    },

    /**
     * getParent
     *
     * This function will return the parent subject. It will select the subject with
     * the same id as the parent subject id
     *
     * @return {SubjectModel}     Backbone model of parent subject
     */
    getParent() {
        return this.collection.findWhere({
            id: this.get('parent_subject_id')
        });
    },

    /**
     * getChildren
     *
     * This function will get all the children of this subject. It will select the children
     * by using it's own Id to filter subject with this id as parent_subject_id.
     *
     * @return {SubjectsCollection|Backbone.Collection}              Collection of child subjects
     */
    getChildren() {
        // Create new instance of the collection of the type the model is a part of with only the child models.
        // This will be the type SubjectsCollection in most cases, sbut could also be a modified version for
        // special use cases.
        return new this.collection.constructor(this.collection.where({
            parent_subject_id: this.get('id')
        }));
    },

    /**
     * getSiblings
     *
     * This function will get all the siblings of this subject. It will select the siblings
     * by selecting subjects with the same parent subject id.
     *
     * @return {SubjectsCollection|Backbone.Collection}              Collection of sibling subjects
     */
    getSiblings() {
        // Create new instance of the collection of the type the model is a part of with only the sibling models.
        // This will be the type SubjectsCollection in most cases, but could also be a modified version for
        // special use cases.
        return new this.collection.constructor(this.collection.where({
            parent_subject_id: this.get('parent_subject_id')
        }));
    },

    /**
     * getAncestors
     *
     * This function will get all the ancestors of this subject. It will select the ancestors
     * using the nested set model. This is a model used mostly in the backend to select a selection
     * of subjects using a low cost (in resources) query. It works with a left en a right.
     *
     * More info on nested set model can be found here:
     * https://en.wikipedia.org/wiki/Nested_set_model
     *
     * @return {Array}              Array with ancestors
     */
    getAncestors() {

        // Return the found ancestors in an array
        return this.collection.filter((subjectB) => {
            return (
                (
                    // Check if the left side is bigger then subjectB's left side
                    this.get('lft') > subjectB.get('lft')
                ) && (

                    // Check if the right side is smaller then subjectB's right side
                    this.get('rgt') < subjectB.get('rgt')
                ) && (

                    // Since nested set model is resetted for every course we need to add
                    // a check to only do this for the same course
                    this.get('course_id') === subjectB.get('course_id')
                )
            );
        });
    },

    /**
     * getDescendants
     *
     * This function will get all the descendants of this subject. It will select the descendants
     * using the nested set model. This is a model used mostly in the backend to select a selection
     * of subjects using a low cost (in resources) query. It works with a left en a right.
     *
     * More info on nested set model can be found here:
     * https://en.wikipedia.org/wiki/Nested_set_model
     *
     * @return {Array}              Array with descendants
     */
    getDescendants() {

        // Return the found descendants in an array
        return this.collection.filter(function(subjectB) {
            return (
                (
                    // Check if the left side is smaller then subjectB's left side
                    this.get('lft') < subjectB.get('lft')
                ) && (

                    // Check if the right side is bigger then subjectB's right side
                    this.get('rgt') > subjectB.get('rgt')
                ) && (

                    // Since nested set model is resetted for every course we need to add
                    // a check to only do this for the same course
                    this.get('course_id') === subjectB.get('course_id')
                )
            );
        }, this);
    },

    hasParent() {
        return (this.getParent() !== undefined);
    },

    hasChildren() {
        return (this.getChildren().length > 0);
    },

    hasSiblings() {
        return (this.getSiblings().length > 0);
    },

    hasAncestors() {
        return (this.getAncestors().length > 0);
    },

    hasDescendents() {
        return (this.getDescendents().length > 0);
    },

    getScore() {
        return (_.isEmpty(this.get('averageScore')) ? this.get('score') : this.get('averageScore').score_avg) || 0
    },

    getScoreColor() {
        const hasSufficientTasksMade = this.get('goal_tasks_made')

        if (!hasSufficientTasksMade) {
            return
        }

        return Score.getColor(this.getScore())
    },

    getScoreLabel() {
        const hasSufficientTasksMade = this.get('goal_tasks_made')
        if (hasSufficientTasksMade) {
            return window.i18n.sprintf(window.i18n.gettext('<b>%s%%</b> score'), (this.getScore() * 100).toFixed())
        }
        return window.i18n.gettext('no score')
    },

    getTasksMade() {
        return (
            _.isEmpty(this.get('averageScore')) ? this.get('tasks_made') : this.get('averageScore').tasks_avg
        ) || 0
    }

}, {
    type: 'subject'
});
