var Handlebars = require("../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"overview") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"overview__header") : stack1), depth0))
    + " js-header\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"overview__question") : stack1), depth0))
    + "\">\n            "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"introduction") : depth0), depth0)) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"overview__tabs") : stack1), depth0))
    + " js-tabs\"></div>\n    </div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"overview__answer-container") : stack1), depth0))
    + " js-answer-container\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"no-responses-text") : stack1), depth0))
    + " js-no-responses-text\">\n            "
    + alias2(__default(require("../../../../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"There are no answers given yet",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":51}}}))
    + ".\n        </div>\n    </div>\n</div>\n";
},"useData":true});