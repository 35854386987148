import Template from './TreeButton.hbs';
import TreeButtonStyles from 'views/components/treeView/treeItem/TreeItem.scss';

export default BaseView.extend({

    initialize(options) {

        const clickable = options.callback && options.disabled !== true

        this.setElement(Template({
            Styles: TreeButtonStyles,
            ...options,
            clickable,
            selector: options.layerType && options.icon === 'move' && `js-move-${options.layerType}`,
        }));

        if (clickable) {
            this.$el.on('click', (e) => {
                this.stopAllEvents(e)
                options.callback(this.model)
            })
        }
    }

});
