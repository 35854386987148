var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"no-content") : stack1), depth0))
    + "\">\n            "
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"This chapter doesn't have any content yet.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":63}}}))
    + "\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"js-sections-holder\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hide_for_exercise") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":22,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <div class=\"tile is-blue js-practice-tile\">\n                <div class=\"tile__content\">\n                    <div>\n                        <div class=\"tile__content__icon\">\n                            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias1,"exercises",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":51}}})) != null ? stack1 : "")
    + "\n                        </div>\n                        <div class=\"tile__content__title\">\n                            "
    + container.escapeExpression(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias1,"Practice by yourself",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":57}}}))
    + "\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"container") : stack1), depth0))
    + "\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasNoContent") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});