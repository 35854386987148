import Styles from './Competency.scss';

import Template from './Competency.hbs';
import QuickInput from 'views/components/quickInput/QuickInput'
import DatePicker from 'views/components/datePicker/DatePicker'
import Component from '../component/Component'
import Button from 'views/components/button/Button'

export default class Competency extends BaseView {

    initialize({
        isReadOnly,
        evaluationModel,
        title = '',
    }) {

        const isPreview = isReadOnly && evaluationModel === undefined
        const isArchive = isReadOnly && evaluationModel
        const isOpenEvaluation = !isReadOnly && evaluationModel && !evaluationModel.get('is_finished')
        const isExpandedByDefault = Backbone.Model.user.get('is_student') && evaluationModel &&
            evaluationModel === evaluationModel.collection.last()

        this.setElement(Template({
            id: evaluationModel?.id,
            Styles,
            isArchive,
            isPreview,
            title,
            isExpandedByDefault,
        }))

        if (isArchive) {
            this.$('.js-evaluator').append(evaluationModel.get('evaluator'))
        } else if (isOpenEvaluation) {
            const evaluatorNameInput = this.addChildView(new QuickInput({
                label: window.i18n.gettext('Evaluator'),
                placeholder: window.i18n.gettext('Evaluator name'),
                defaultValue: evaluationModel.get('evaluator') || Backbone.Model.user.first_name_last_name(),
                noMargin: true,
                keepValueOnCallback: true,
                doInputCallbackOnBlur: true,
                inputCallbackWithoutButton: (value) => {
                    evaluationModel.set({evaluator: value})
                }
            }), '.js-evaluator')
            evaluationModel.set({evaluator: evaluatorNameInput.getInput()}, {silent: true})
        }

        if (isArchive) {
            this.$('.js-evaluation-date').append(
                new dayjs(evaluationModel.get('evaluation_date')).local().format('dd D MMM YYYY')
            )
        } else if (isOpenEvaluation) {
            const evaluationDateInput = this.addChildView(new DatePicker({
                label: window.i18n.gettext('Date'),
                selectionMode: 'day',
                selectedDate: evaluationModel.get('evaluation_date') || new dayjs(),
                // Do not allow for dates in the future
                selectionRange: {
                    max: new dayjs()
                }
            }), '.js-evaluation-date')
            evaluationModel.set({
                evaluation_date: evaluationDateInput.selectedDate.utc().toISOString()
            }, {silent: true})
            this.listenTo(evaluationDateInput, 'change:date', (selectedDate) => {
                evaluationModel.set({evaluation_date: selectedDate.utc().toISOString()})
            })
        }

        if (isArchive) {
            this.$('.js-evaluation-score').text(
                evaluationModel.getScore().toLocaleString(undefined, {style: 'percent'})
            )
        }

        for (const component of this.model.get('components')) {
            this.addChildView(new Component({
                model: component,
                competencyModel: this.model,
                isPreview,
                isArchive,
                isOpenEvaluation,
                evaluationModel,
            }), '.js-components')
        }

        if (isOpenEvaluation) {
            this.addChildView(new Button({
                label: window.i18n.gettext('Reset evaluation'),
                icon: 'restart',
                theme: 'secondary',
                inline: true,
                callback: () => {
                    evaluationModel.destroy()
                    this.destroy()
                }
            }), 'footer')

            const finalizeButton = this.addChildView(new Button({
                label: window.i18n.gettext('Finalize evaluation'),
                icon: 'send',
                inline: true,
                callback: () => {
                    finalizeButton.disable(true)
                    try {
                        evaluationModel.finalize()
                        this.listenToOnce(evaluationModel, 'sync', () => {
                            Backbone.View.layout.openStatus(
                                window.i18n.gettext('Evaluation finalized'),
                                'success'
                            )
                            finalizeButton.disable()
                            this.destroy()
                        })
                    } catch (e) {
                        Backbone.View.layout.openStatus(e)
                        finalizeButton.enable()
                    }
                }
            }), 'footer')
        }

    }

}
