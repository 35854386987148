import Template from './Microsoft365.hbs';
import Styles from 'views/components/taskGroups/tasks/template21/Template21.scss';
import LoadExternalStaticResources from 'util/LoadExternalStaticResources.js';

const MESSAGE_PREFIX = '[Learnbeat-Custom]';
export default class Microsoft365 extends BaseView {

    get extensionIcon() { return 'office365-logo-color'; }
    get extensionName() { return 'Microsoft 365'; }
    get extensionTrackName() { return 'template21/extensions/' + this.extensionName; }

    get OneDrive() { return window.OneDrive; }

    /**
     * initialize
     *
     * Creates an instance of GoogleDrive.
     *
     * @param {*} {
     *  parent       {object} parentview as object,
     *  authOptions  {object} object to authenticate for Google API,
     *  mimeTypes    {array}  array with allowed mime types,
     *  query        {string} string to search for
     * }
     * @memberof Microsoft365
     */
    initialize({ parent, pickerOptions, allowedExtensions }) {

        this.pickerOptions = pickerOptions;
        this.allowedExtensions = allowedExtensions;
        this.hasClickListener = false;

        _.bindAll(this,
            'onReceiveMessage',
            'addMicrosoft365Button',
            'onClickMicrosoft365Button'
        );

        // Scope the onPickFile method with the parent object
        this.onPickedFiles = this.onPickedFiles.bind(parent);

        this.setElement(Template({
            Styles,
            extensionIcon: this.extensionIcon,
            extensionName: this.extensionName,
        }));

        this.LESR = LoadExternalStaticResources();

        this.LESR.addResource({
            identifier: 'OneDriveSDK',
            url: '//js.live.net/v7.2/OneDrive.js',
            type: 'javascript'
        }, this.addMicrosoft365Button);
    }

    /**
     * onReceiveMessage
     *
     * Listener for the message event on the window. This method is used
     * to handle communication between the OneDrive file popup and the main
     * window.
     *
     * @param {*} {
     *  data       {string} message string as send by child window,
     *  source     {object} window object of the child that send the message
     * }
     * @memberof Microsoft365
     */
    onReceiveMessage({ data, source }) {

        if (data.indexOf(MESSAGE_PREFIX) === -1) {
            return;
        }

        try {
            const message = JSON.parse(data.substring(MESSAGE_PREFIX.length));

            if (message && message.error) {
                switch (message.error) {
                    case 'access_denied':
                        window.statsTracker.trackEvent(
                            this.extensionTrackName,
                            'Denied access'
                        );
                        window.focus();
                        source.close();

                        // Since the OneDrive SDK (not our wrapper) does
                        // a selfdestruct on unknown errors, we need to
                        // reinitialize the library after this error
                        delete window.OneDrive;
                        this.LESR.reload('OneDriveSDK');
                        break;
                }
            }
        } catch (e) {
            // Do nothing, invalid message so safe to ignore
        }
    }

    /**
     * addMicrosoft365Button
     *
     * Method to make the drive button visible and attach the correct
     * click listener
     *
     * @memberof Microsoft365
     */
    addMicrosoft365Button() {

        // Don't do this if there already is a click listener
        if (this.hasClickListener) {
            return;
        }

        // Cleanup eventlistener, it will be bound again in the onClickMicrosoft365Button
        // callback function after a click. This prevents multiple template21 on a single
        // page response to the message of another template21.
        window.removeEventListener('message', this.onReceiveMessage);

        this.$el
            // Prevent the user from clicking multiple times at the button and
            // opening multiple oauth popups
            .one('click', this.onClickMicrosoft365Button)
            .css({ display: 'flex' });

        this.hasClickListener = true;
    }

    /**
     * onClickMicrosoft365Button
     *
     * This method will be called when the users clicks on the
     * select from drive button.
     *
     * @returns {boolean} false to stop further execution
     * @memberof Microsoft365
     */
    onClickMicrosoft365Button() {

        this.hasClickListener = false;

        if (Backbone.Model.user.get('is_teacher')) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('As teacher it isn\'t possible to upload a file.'),
                'info'
            );

            this.addMicrosoft365Button()
            return false;
        }

        window.statsTracker.trackEvent(
            this.extensionTrackName,
            'Clicked Microsoft365 button'
        );

        // Listen to the message event, because microsoft fails to handle their own oauth
        // flow correctly. We need to do it ourselfs
        window.addEventListener('message', this.onReceiveMessage);

        // Prefix all the extensions with a '.' (dot) because onedrive requires it
        const prefixedExtensions = this.allowedExtensions.map(ext => `.${ext}`);

        let createLinkParameters = { type: 'edit', scope: 'anonymous' };

        // Temporarily add the following behind an usersetting so we can test what happens if
        // we leave the scope empty for schools that don't allow sharing outside of their
        // organisation. If it works like a charm, we should make this the main setup
        if (Backbone.Model.user.getSetting('scopelessMicrosoftHandIn')) {
            createLinkParameters = { type: 'edit' };
        }

        this.OneDrive.open({
            ...this.pickerOptions,
            action: 'share',
            success: (files) => this.onPickedFiles(files, this),
            // If the user cancels the flow, just readd the button to
            // reattach the click listener so the user can retry
            cancel: this.addMicrosoft365Button,
            error: this.receivedError,
            advanced: {
                ...(this.pickerOptions.advanced || {}),
                createLinkParameters,
                filter: prefixedExtensions.join(',')
            }
        });

        return false;
    }

    /**
     * onPickedFiles
     *
     * Callback for when the user picks one or more files
     *
     * NOTE: This method is scoped with the Template21 view object
     *
     * @param {object} data object created by picker api of Google
     * @param {object} extension this extension class
     * @memberof Microsoft365
     */
    onPickedFiles(data, { extensionName, addMicrosoft365Button }) {

        const { value: files } = data;

        // Correctly dereference/clone response map variable
        this.responseObject = Object.assign({}, this.responseObject);

        window.statsTracker.trackEvent(
            this.extensionTrackName,
            'Added file(s)',
            files.length
        );

        files.forEach(({
            id,
            size,
            file,
            name,
            permissions
        }) => {

            const { mimeType } = file;
            const realMime = mimeType.split(';');

            const permission = permissions[0];

            if (!permission) {
                // If permission is not available, we cannot guarantee access for the teacher.
                // So log it to Sentry so we know how much this happens, and prevent further
                // execution so the user won't think their file is uploaded. He/She will
                // probably try it again or contact support to which we can act by searching
                // in Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('fileData', arguments[0]);
                    window.sentry.captureMessage('Missing permissions in OneDrive hand in');
                });
                return;
            }

            const { link } = permission;
            const url = link.webUrl;

            const options = {
                id,
                url,
                size,
                extensionName,
                fileType: realMime[0],
                isExtensionFile: true,
                date: new Date().toISOString()
            }

            this.responseObject[name] = options;
            this.renderFileBlock(options, name);
        });

        this.task_view.saveResponse(this.responseObject);

        // Readd the click listener to the microsoft button
        addMicrosoft365Button();
    }

    /**
     * receivedError
     *
     * This method will be called when the picker triggers
     * an error
     *
     * @param {string} error string containing the error
     * @memberof Microsoft365
     */
    receivedError(error) {

        window.statsTracker.trackEvent(
            this.extensionTrackName,
            'Received error',
            error
        );

        window.sentry.withScope(scope => {
            scope.setExtra('errorMessage', error);
            window.sentry.captureMessage('Error when picking files for Microsoft365');
        });

        // Readd the click listener to the microsoft button
        this.addMicrosoft365Button();
    }

    beforeDestroy() {
        window.removeEventListener('message', this.onReceiveMessage);
    }
}
