var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " source-textbook-heading-level--is-plus";
},"3":function(container,depth0,helpers,partials,data) {
    return " source-textbook-heading-level--is-extra";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"source-textbook-heading js-meta-year\">\n    <div class=\"source-textbook-heading-level"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPlus") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":45},"end":{"line":2,"column":105}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExtra") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":105},"end":{"line":2,"column":167}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"source-textbook-heading-level__label\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"year") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n        <div class=\"source-textbook-heading-level__symbol\">\n            <div>\n                "
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"add",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":33}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});