import Styles from './AppointMenu.scss';

import Template from './AppointMenu.hbs';
import Button from 'views/components/button/Button'

export default class AppointMenu extends BaseView {

    initialize({
        appointOptions,
        currentType,
        changeParseType,
        definitionMapping
    }) {

        const hasCurrentType = (currentType && currentType !== '*');
        let optionsList = [];

        optionsList.push({
            id: '*',
            label: 'Geen'
        })

        optionsList = [
            ...optionsList,
            ...appointOptions.map(option => {
                return {
                    id: option,
                    label: `${option.toUpperCase()} (${definitionMapping[option]})`,
                }
            })
        ]

        this.setElement(Template({
            Styles,
            optionsList,
            hasCurrentType,
            currentType: hasCurrentType ? currentType : '*'
        }));

        const selectElement = this.el.querySelector('.js-option-select');

        this.addChildView(
            new Button({
                icon: 'arrow-down-bold',
                theme: 'secondary',
                label: hasCurrentType ? currentType.toUpperCase() : undefined
            }),
            '.js-open-button'
        )

        // Listen for changes on the select field
        selectElement.addEventListener('change', () => changeParseType(selectElement?.value));
    }
}