import Template from './Column.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import ColumnTemplate from 'views/components/wysiwyg/elements/columns.hbs';

export default class Column extends Button {

    // Custom command
    command = 'insertColumn'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Column');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        const customEl = editorObject.customEl
        const customSH = editorObject.customSH

        // Add the command for inserting a blockquote.
        editor.addCommand(this.command, () => {
            customEl.blockElement(ColumnTemplate, editor);
        });

        // Create the state handler for this block element
        customSH.blockElementHandler(
            // Listen to command: insertBlockquote
            this.command,
            // Use the blockquote template to indentify the block
            ColumnTemplate(),
            // Pass the editor with it
            editor
        );
    }
}
