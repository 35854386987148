import Template from './Analytics.hbs';
import AnalyticsStyles from 'views/components/taskGroups/tasks/analytics/Analytics.scss'
import ItemStyles from 'views/components/taskGroups/tasks/template1/Template1.scss'
import Template1 from '../Template1'
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte'

export default class Template1Analytics extends Template1 {

    initialize({
        mistakes = [],
        taskModel,
    }) {

        this.inputType = taskModel.get('task_info_json').inputType || 'text'
        const answerOptions = _.map(taskModel.get('task_info_json').keys, (key, index) => {
            const answer = taskModel.get('task_info_json').answers[index]
            return {
                key,
                answer,
                isCorrectAnswer: taskModel.get('task_info_json').correct_answers.some((correctAnswer) => {
                    return _.isEqual(correctAnswer, answer)
                }),
                mistakePercentage: _.findWhere(mistakes, {id: key})?.percentage,
                index,
            }
        })

        this.setElement(Template({
            Styles: AnalyticsStyles,
            ItemStyles,
        }))

        const hasCorrectAnswer = answerOptions.some((answerOption) => {
            return answerOption.isCorrectAnswer === true
        })

        for (const answerOption of answerOptions) {
            const itemElement = this.renderItem(
                answerOption.answer,
                answerOption.index,
                true
            )

            const color = hasCorrectAnswer ?
                (answerOption.isCorrectAnswer ? 'green' : 'red') :
                'gray'

            this.addSvelteChildView(itemElement.find('.js-analytics'), ProgressBar, {
                fragments: {
                    [color]: answerOption.mistakePercentage,
                },
                showLabel: true,
            })
            itemElement.addClass(ItemStyles['item--analytics'])
            this.$('section').append(itemElement)
        }
    }

    clickItem() {}

}
