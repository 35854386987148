export default {

    isAllowed(path) {
        switch (path) {

            // Home
            case 'users/home':
                return ACL.isAllowed(ACL.resources.HOME, ACL.actions.VIEW)

            // Learning material
            case 'groups/show':
            case 'chapters/show':
            case 'sections/show':
            case 'activities/show':
            case 'task_groups/show':
            case 'groups/search':
                return ACL.isAllowed(ACL.resources.GROUPS, ACL.actions.VIEW)

            // Planner
            case 'groups/planner':
                return ACL.isAllowed(ACL.resources.PLANNER, ACL.actions.VIEW)

            // Progress
            case 'groups/progress':
            case 'chapters/progress':
            case 'sections/progress':
            case 'activities/progress':
            case 'task_groups/progress':
            case 'students/progress':
            case 'answers/progress':
                return ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW)

            // Results
            // TODO: should both be groups/results!
            case 'students/results':
                return ACL.isAllowed(ACL.resources.RESULTS, ACL.actions.VIEW, {type: 'users'})
            case 'groups/results':
                return ACL.isAllowed(ACL.resources.RESULTS, ACL.actions.VIEW, {type: 'groups'})

            // Author
            case 'groups/author':
            case 'chapters/author':
            case 'sections/author':
            case 'activities/author':
            case 'task_groups/author':
                return ACL.isAllowed(ACL.resources.AUTHOR, ACL.actions.VIEW)

            // Library
            case 'library/home':
            case 'library/view':
            case 'library/add':
            case 'library/author':
                return ACL.isAllowed(ACL.resources.LIBRARY, ACL.actions.VIEW)

            // Feedback
            case 'users/notes':
                return ACL.isAllowed(ACL.resources.FEEDBACK, ACL.actions.VIEW, {type: 'list'})

            // Annotations
            case 'groups/annotate':
            case 'chapters/annotate':
            case 'sections/annotate':
            case 'activities/annotate':
            case 'task_groups/annotate':
                return ACL.isAllowed(ACL.resources.ANNOTATIONS, ACL.actions.VIEW)

            // Portfolio
            // TODO: Rename to: users/portfolio
            case 'portfolio/projects':
                return ACL.isAllowed(ACL.resources.PORTFOLIO, ACL.actions.VIEW, {type: 'projectList'})
            // TODO: Rename to portfolio/show
            case 'portfolio/project':
                return ACL.isAllowed(ACL.resources.PORTFOLIO, ACL.actions.VIEW, {type: 'project'})
            // TODO: Rename to: users/portfolio
            case 'portfolio/students':
                return ACL.isAllowed(ACL.resources.PORTFOLIO, ACL.actions.VIEW, {type: 'studentList'})
            case 'portfolio/group':
                return ACL.isAllowed(ACL.resources.PORTFOLIO, ACL.actions.VIEW, {type: 'group'})

            // Words
            case 'words/home':
            case 'words/list':
            case 'words/course':
            case 'words/sessions':
            case 'words/results':
                return ACL.isAllowed(ACL.resources.WORDS, ACL.actions.VIEW)

            // Settings
            case 'users/settings':
                return ACL.isAllowed(ACL.resources.SETTINGS, ACL.actions.VIEW)

            // Join groups
            case 'users/join':
                return ACL.isAllowed(ACL.resources.GROUPS, ACL.actions.JOIN)

            // Create and manage groups
            case 'groups/settings':
            case 'groups/invite':
            case 'groups/createaccounts':
            case 'groups/pick_course':
            case 'groups/create':
            case 'groups/created':
                return ACL.isAllowed(ACL.resources.GROUPS, ACL.actions.ADD)

            case 'accessor/connections':
                return ACL.isAllowed(ACL.resources.ACCESSOR, ACL.actions.VIEW);

            // Student grading
            case 'activities/grading':
                return ACL.isAllowed(ACL.resources.STUDENTGRADING, ACL.actions.VIEW)

            // Products
            case 'products/payment_status':
                return ACL.isAllowed(ACL.resources.PRODUCTS, ACL.actions.VIEW)

            case 'licenses/renewal':
                return ACL.isAllowed(ACL.resources.PRODUCTS, ACL.actions.VIEW)

            // Administrator panel
            case 'groups/admin':
            case 'groups/admin_create':
            case 'teachers/admin':
            case 'students/admin':
                return ACL.isAllowed(ACL.resources.ADMIN, ACL.actions.VIEW)

            default:
                return false
        }
    }
}
