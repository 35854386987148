import Styles from './NewsListItem.scss';

import Template from './NewsListItem.hbs';
import NewsMessage from '../../newsMessage/NewsMessage'

export default class NewsListItem extends BaseView {
    get events() {
        return {
            click: 'onClickNews'
        }
    }

    initialize() {
        this.setElement(Template({
            Styles,
            title: this.model.get('title'),
            headerImage: this.model.get('header_image_url')
        }))
    }

    onClickNews() {
        Backbone.View.sidebar.showSidebar(
            new NewsMessage({
                model: this.model
            }),
            window.i18n.gettext('News'),
            520
        )
    }
}
