import SectionModel from 'models/SectionModel';

export default class SectionsCollection extends Backbone.Collection {

    get model() {
        return SectionModel
    }

    get comparator() {
        return 'sequence'
    }

    preinitialize() {
        this.constructor.type = 'sections'
    }

    parse(response) {
        if (Array.isArray(response)) {
            // First sort the backend response by sequence
            response.sort((a, b) => {
                return Math.sign(a.sequence - b.sequence)
            })
            // Then set the index, use no increment for numberless
            let index = 0
            for (const section of response) {
                if (section.numberless) {
                    section.index = index
                    continue
                }
                index++
                section.index = index
            }
        }
        return response
    }

    setSequence(cidToSequenceArray = [], noCall) {
        const idToSequenceArray = cidToSequenceArray.map((cid, sequence) => {
            return this.get(cid).set({
                sequence,
            }).id
        })

        // noCall is a boolean whether the update sequence call should be triggered
        if (!noCall) {
            this.saveSequence(idToSequenceArray)
        }
    }

    saveSequence(activityIds) {
        return $.post('/sections/update_sequence.json', {
            section_ids: activityIds,
        })
    }

}
