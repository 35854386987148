export default Backbone.Model.extend({

    // Start the time log for the given user, activity and task group
    start(data) {
        this.set('userId', data.userId)
        this.set('activityId', data.activityId)
        this.set('taskGroupId', data.taskGroupId)
        this.set('startTime', Date.now())

        // Start a timer and update every second
        this.duration = 0
        this.timer = setInterval(() => {
            this.duration += 1000
        }, 1000)
    },

    // Stop the time log and save it to the backend
    stopAndSave() {

        // If there is no start time or no timer, we cannot save a duration
        if (!this.get('startTime') || !this.timer) {
            return
        }

        // Stop the timer and set the end time based on the duration
        clearInterval(this.timer)
        this.set('endTime', this.get('startTime') + this.duration)

        // Do not save if the duration is less than 5 seconds
        // This prevents overloading the backend when students are navigating
        if (this.duration < 5000) {
            this.remove()

        } else {

            // Do not allow durations longer than 15 minutes
            const maxDuration = 15 * 60 * 1000
            if (this.duration > maxDuration) {
                this.set('endTime', this.get('startTime') + maxDuration)

                // Monitor longer than 60 minutes so that we can keep track of extemely long durations
                if (this.duration > 60 * 60 * 1000) {
                    window.sentry.withScope(scope => {
                        scope.setExtra('duration in minutes', this.duration / 1000 / 60)
                        scope.setExtra('time log', this.attributes)
                        window.sentry.captureMessage('Task group duration longer than 60 minutes')
                    })
                }
            }

            // Send the time log to the backend
            this.save()
        }
    },

    /**
         * save
         *
         * Save model to localStorage and try to sync it to the backend.
         */
    save() {

        // Create localStorage model with the current model type and model data.
        // Remember the localStorage model ID so it can be removed from
        // localStorage once the current model has been successfully synced to
        // the backend.
        var localStorageTypeModel = Backbone.Collection.localStorage.add({
            modelType: 'TimeLogModel',
            modelData: this.attributes
        })
        this.localStorageModelID = localStorageTypeModel.id

        this.sync()

    },

    /**
         * sync
         *
         * Sync time log to the backend. Remove the copy of the data
         * in localStorage if POST request succeeds.
         */
    sync() {
        $.post({
            url: '/users/save_time.json',
            timeout: 10000,
            data: {
                user_id: this.get('userId'),
                activity_id: this.get('activityId'),
                task_group_id: this.get('taskGroupId'),
                startTime: this.get('startTime'),
                endTime: this.get('endTime')
            },
            success: (response) => {
                if (response.status === 'error' && response.err_code === 28404) {
                    // Log event to Sentry
                    window.sentry.withScope(scope => {
                        scope.setExtra('task_group_id', this.get('taskGroupId'))
                        scope.setExtra('activity_id', this.get('activityId'))
                        scope.setExtra('user_id', this.get('userId'))
                        window.sentry.captureMessage('Save time for a deleted task group or activity')
                    })
                }

                // When an error, still handle as a success to prevent retrying to save time
                Backbone.Collection.localStorage.onSyncSuccess(this.localStorageModelID)
            },
            error: () => {
                Backbone.Collection.localStorage.onSyncError()
            }
        })
    },

    // Remove the time log from local storage
    remove() {
        _.partial(Backbone.Collection.localStorage.onSyncSuccess, this.localStorageModelID)
    }

}, {
    type: 'timeLog'
})
