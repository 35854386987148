var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-send-note "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"container") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"send-to") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Send to",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":53}}}))
    + " <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"recipient_name") : depth0), depth0))
    + "</strong></div>\n    <div class=\"js-group-selector "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"group-selector") : stack1), depth0))
    + "\"></div>\n    <div class=\"js-message-input\"></div>\n    <div class=\"js-send-button\"></div>\n</div>\n";
},"useData":true});