import Styles from './OpenQuestion.scss';

import Template from './OpenQuestion.hbs';
import SharedStyles from 'views/pages/words/questionCard/QuestionCard.scss'
import QuickInput from 'views/components/quickInput/QuickInput';
import shapeList from 'util/ShapeLoader';
import Util from 'util/util'
import TextToSpeech from 'views/pages/words/textToSpeech/TextToSpeech'
import CharacterButton from 'views/pages/words/openQuestion/characterButton/CharacterButton'
import Button from 'views/components/button/Button';

export default class OpenQuestion extends BaseView {

    initialize({
        parent,
        pairCardText
    }) {
        _.bindAll(
            this,
            'onKeyUpNextCard',
            'onClickNext',
            'addSpecialCharacters',
            'dontKnowAnswer',
            'changeIncorrectToCorrectAnswer'
        )

        const { pairId, questionLabel, questionValue, answerLabel, answerValue } = pairCardText
        this.answerValue = answerValue
        this.answerLabel = answerLabel
        this.parent = parent

        this.setElement(Template({
            Styles,
            SharedStyles,
            questionLabel,
            questionValue
        }));

        this.parent.setInstructionText(window.i18n.gettext('Press enter to continue'))

        this.input = this.addChildView(
            new QuickInput({
                noMargin: true,
                inputCallbackWithoutButton: this.onClickNext,
                placeholder: answerLabel,
                keepValueOnCallback: true,
                noAutoCapitalize: true,
                noAutoCorrect: true
            }),
            '.js-input-container'
        );

        this.dontKnowButton = this.addChildView(
            new Button({
                label: window.i18n.gettext('I don\'t know'),
                theme: 'secondary',
                callback: this.dontKnowAnswer
            }),
            '.js-answer-buttons'
        )

        this.changeAnswerButton = this.addChildView(
            new Button({
                label: window.i18n.gettext('I was right!'),
                theme: 'secondary',
                callback: this.changeIncorrectToCorrectAnswer
            }),
            '.js-answer-buttons'
        )
        this.changeAnswerButton.el.style.display = 'none'

        // If the question is supported by text to speech, add a button
        const questionToSpeechLanguage = this.parent.getTextToSpeechLanguage(questionLabel)
        if (questionToSpeechLanguage && questionValue.length < 150) {
            const direction = this.parent.sessionModel.get('learn_direction')
            this.questionSpeechButton = this.addChildView(new TextToSpeech({
                pairId,
                value: direction === 'left_right' ? 'left' : 'right',
                lang: questionToSpeechLanguage
            }), this.$('.js-question'))

            // Add button to header to enable auto speech
            this.parent.addAutoSpeechButton()

            // If auto speech is enabled, read the question
            this.listenTo(this.parent.sessionModel, 'change:autoSpeech', this.playAutoSpeech)
            this.playAutoSpeech()
        }

        _.defer(() => this.input.focusInput())

        this.inputContainer = this.el.querySelector('.js-input-container')
        this.nextButton = this.el.querySelector('.js-next-button')

        this.nextButton.addEventListener('click', this.onClickNext)

        // Add buttons to insert characters such as é or ü for selected courses
        this.addSpecialCharacters(this.answerLabel)

    }

    addSpecialCharacters(language) {
        let characters = []
        switch (language.toLowerCase()) {
            case 'duits':
                characters = ['ü', 'ä', 'ß', 'ö']
                break
            case 'spaans':
                characters = ['í', 'ó', 'é', 'á', 'ñ', 'ú', 'ü', '¿', '¡']
                break
            case 'frans':
                characters = ['é', 'è', 'ê', 'à', 'â', 'ç', 'ô', 'ù', 'û', 'î', 'œ']
                break
        }

        if (characters.length === 0) {
            return
        }

        // Add buttons for each special character
        const input = this.inputContainer.querySelector('input')
        for (const character of characters) {
            this.addChildView(
                new CharacterButton({
                    character,
                    input
                }),
                '.js-character-buttons'
            )
        }
    }

    playAutoSpeech() {
        if (this.questionSpeechButton && this.parent.sessionModel.get('autoSpeech')) {
            this.questionSpeechButton.el.click()
        }
    }

    onKeyUpNextCard(e) {
        if (e.keyCode === 13) {
            document.addEventListener('keyup', this.onKeyUpNextCard, { once: true})
            this.parent.moveToNextCard(true)
        }
    }

    onClickNext() {

        // If we are not in input mode, move to the next question (skip the timeout)
        if (this.inputContainer.style.display === 'none') {
            this.parent.moveToNextCard(true)
            return
        }

        // Do not allow empty answers
        const inputValue = this.input.getInput()
        if (inputValue.length === 0) {
            return
        }

        this.input.disable()
        this.inputContainer.style.display = 'none'
        this.dontKnowButton.el.style.display = 'none'
        this.$('.js-character-buttons').hide()

        // get the text from input
        this.givenAnswer = Util.stripTags(Util.normaliseCharacters(inputValue))
        let correctAnswer = Util.stripTags(Util.normaliseCharacters(this.answerValue))

        // convert to lowercase for comparison, except when the answer is in German
        if (this.answerLabel !== 'Duits') {
            this.givenAnswer = this.givenAnswer.toLowerCase()
            correctAnswer = correctAnswer.toLowerCase()
        }

        // Split the correct answer and the given answer on a '/' and remove surrounding whitespace
        const correctAnswers = correctAnswer.split(/\s*\/\s*/)
        const givenAnswers = this.givenAnswer.split(/\s*\/\s*/)

        // Check if the given answer is correct: each of the given answers should be in the correct answers list
        this.isCorrect = true
        for (const given of givenAnswers) {
            if (!correctAnswers.includes(given)) {
                this.isCorrect = false
                break
            }
        }

        if (this.isCorrect) {
            this.showCorrectAnswer()
        } else {
            this.showIncorrectAnswer()
            this.changeAnswerButton.el.style.display = ''
        }

        this.parent.setResponse(this.isCorrect, this.givenAnswer)
        this.parent.moveToNextCard()

        // prevent changing the card to the next immediately after giving an answer
        setTimeout(() => {
            document.addEventListener('keyup', this.onKeyUpNextCard, { once: true})
        }, 500)
    }

    // Show the correct answer and proceed to the next question
    dontKnowAnswer() {
        this.inputContainer.style.display = 'none'
        this.dontKnowButton.el.style.display = 'none'
        this.$('.js-character-buttons').hide()

        this.input.setInput('…')

        this.showIncorrectAnswer()

        this.parent.setResponse(false, '')
        this.parent.moveToNextCard()

        // Prevent going to next question immediately when clicking the button using the enter key
        setTimeout(() => {
            document.addEventListener('keyup', this.onKeyUpNextCard, { once: true })
        }, 500)
    }

    /**
     * correct given answer in case of misspelling
     **/
    changeIncorrectToCorrectAnswer() {

        // Remove the tooltip and show the given answer as correct
        this.$('.js-tooltip').text('')
        this.$('.js-result').removeClass(Styles['result--incorrect'])
        this.$('.js-next-button').removeClass(Styles['next-button--incorrect'])

        this.changeAnswerButton.el.style.display = 'none'

        this.showCorrectAnswer()
        this.parent.setResponse(true, this.givenAnswer)
    }

    // Show the given answer as a correct answer
    showCorrectAnswer() {
        this.$('.js-result').addClass(Styles['result--correct'])
        this.$('.js-result').text(this.input.getInput())

        this.$('.js-next-button').html(shapeList['arrow-forward'])
        this.$('.js-next-button').addClass(Styles['next-button--correct'])
    }

    // Show the given answer as an incorrect answer
    showIncorrectAnswer() {
        this.$('.js-result').addClass(Styles['result--incorrect'])
        this.$('.js-result').text(this.input.getInput())
        this.$('.js-tooltip').text(this.answerValue)

        this.$('.js-next-button').html(shapeList['arrow-forward'])
        this.$('.js-next-button').addClass(Styles['next-button--incorrect'])
    }

    onDestroy() {
        document.removeEventListener('keyup', this.onKeyUpNextCard)
    }

}
