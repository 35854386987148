import Styles from './Reply.scss';

import Template from './Reply.hbs';
import TimeUtil from 'util/TimeUtil';
import NoteStyles from 'views/components/notes/noteWorkOn/NoteWorkOn.scss'

export default BaseView.extend({

    initialize() {

        _.bindAll(this,
            'onChangeModel'
        );

        var sender_model;
        if (Backbone.Model.user.get('is_teacher')) {
            sender_model = Backbone.Collection.students.get(this.model.get('user_id'));
        } else {
            sender_model = Backbone.Model.user;
        }

        this.setElement(Template({
            Styles,
            NoteStyles,
            sender: sender_model.first_name_last_name(),
            message: this.model.get('message'),
            pretty_time: TimeUtil.prettyDate(this.model.get('created'))
        }));

        this.listenTo(this.model, 'change:created', this.onChangeModel);
    },

    onChangeModel() {
        TweenMax.fromTo(this.$('.js-time-text'), {
            opacity: 0
        }, {
            opacity: 1,
            duration: 1.5,
            delay: 1
        })
        this.$('.js-time-text').text(TimeUtil.prettyDate(this.model.get('created')));
    }

})
