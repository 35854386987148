import Styles from './NewsList.scss';

import Template from './NewsList.hbs';
import NewsListItem from './newsListItem/NewsListItem'

export default class NewsList extends BaseView {

    initialize() {
        this.setElement(Template({
            Styles
        }))

        const newsCollection = new Backbone.Collection()
        newsCollection.url = '/users/news.json'
        newsCollection.fetch({
            success: () => {
                newsCollection.each((model) => {
                    this.addChildView(
                        new NewsListItem({
                            model
                        }),
                        this.$el
                    )
                })

                window.statsTracker.trackEvent(
                    'News',
                    'Show all messages',
                    newsCollection.length
                )
            }
        })
    }

}
