import Styles from './ProductVariant.scss';

import Template from './ProductVariant.hbs';
export default class ProductVariant extends BaseView {

    initialize({ name, price, taxPercentage, expirationDate }) {
        this.setElement(Template({
            Styles,
            name,
            price,
            taxPercentage,
            expirationDate
        }))
    }

}
