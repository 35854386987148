import Styles from './NavigationItem.scss';

import Template from './NavigationItem.hbs';
var NavigationItem = BaseView.extend({

    events: {
        click: 'clickCallback'
    },

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options object as defined by parent
     */
    initialize(options) {

        // Check if the clickCallback is set
        if (options.clickCallback) {

            // Make clickCallback accessible within methods
            this.clickCallback = _.partial(options.clickCallback, this);
        }

        // Make index accessible for methods
        this.index = options.index;

        // Create the view, passing the styling with it
        this.setElement(Template({
            index: this.index,
            hasNoClick: _.isUndefined(this.clickCallback),
            Styles
        }));

        // Listen for a change in state
        this.listenTo(

            // Listen to this model
            this.model,

            // Listen for change on state attribute
            'change:state',

            // Call the onChangeState method
            this.onChangeState
        );
    },

    /**
     * onChangeState
     *
     * This method will be called when the state attribute of the model
     * changes.
     *
     */
    onChangeState() {

        // Call the setState method passing the new state
        this.setState(this.model.get('state'));
    },

    /**
     * setState
     *
     * This method can be called directly on this view to set the state.
     * It will remove the old state and apply the new state.
     *
     * @param  {string} state   New state in string, can be undefined then it wil remove state
     */
    setState(state) {

        // Check if current is not undefined
        if (this.state) {

            // Remove the current state's class
            this.$('.js-state').removeClass(Styles['nav-item__status--' + this.state]);
        }

        // Store the state globally
        this.state = state;

        // Check if the new state is not undefined
        if (this.state) {

            // Set the new state's class
            this.$('.js-state').addClass(Styles['nav-item__status--' + this.state]);
        }
    },

    /**
     * setDeactive
     *
     * This method will make the item deactive by removing the active class
     *
     */
    setDeactive() {

        // Remove the active class from the element
        this.$el.removeClass(Styles['nav-item--active']);
    },

    /**
     * setActive
     *
     * This method will make the item active by adding the active class
     *
     */
    setActive() {

        // Add the active class to the element
        this.$el.addClass(Styles['nav-item--active']);
    },

});

export default NavigationItem;
