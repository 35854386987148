import Styles from './PendingGroupCard.scss';

import Template from './PendingGroupCard.hbs';
import Button from 'views/components/button/Button'

export default BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     */
    initialize() {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            course: this.model.get('course_name'),
            name: this.model.get('group_name'),
            background: this.model.get('course_image')
        }));

        // Add button so student can refresh page manually when socket event
        // does not come through.
        this.addChildView(new Button({
            icon: 'refresh',
            callback() {
                Backbone.Model.user.set({
                    acceptedToGroup: true
                });
                Backbone.Model.user.trigger('join-request-accepted-by-teacher');
            }
        }), '.js-refresh-btn')

    }

});
