import Styles from './UserSelector.scss';

import Template from './UserSelector.hbs';
import Chooser from 'views/components/chooser/Chooser.svelte'
import ToggleSwitch from 'views/components/toggleSwitch/ToggleSwitch.svelte'

export default BaseView.extend({

    events: {
        'change input': 'onSelectionChange'
    },

    initialize({
        key
    }) {
        this.key = key;

        this.collection = this.model.getGroupModel().students

        const showAnswersVisibleOnCompletion = (key === 'answers_visible' && this.model.get('type') === 'linear')

        const hasNoStudents = this.collection.length === 0

        this.setElement(Template({
            Styles,
            showAnswersVisibleOnCompletion,
            hasNoStudents
        }))

        if (!hasNoStudents) {

            this.chooser = this.addSvelteChildView(this.$el, Chooser, {
                list: this.collection.map((student) => {
                    return {
                        id: student.id,
                        label: student.last_name_first_name(),
                        isChecked: _.contains(this.model.get(key), student.id)
                    }
                }),
                isMultiSelect: true,
                showSelectAllInput: true,
                selectAllText: window.i18n.gettext('All students'),
                style: 'sidebar'
            })

        }

        if (showAnswersVisibleOnCompletion) {
            this.addSvelteChildView('.js-answers-completion', ToggleSwitch, {
                isChecked: parseInt(this.model.getMetadata('answers_visible_on_completion')),
                callback: (state) => {
                    this.model.setMetadata('answers_visible_on_completion', state)
                }
            })
        }

    },

    onSelectionChange() {

        const selection = this.chooser.getSelection()

        this.model.savePatchWithKeyToServer(this.key, _.pluck(selection, 'id'))

    }

});
