var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sideline") : stack1), depth0))
    + " js-title-sideline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"indexLetter") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":8,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"exam","generated_exam","diagnostic_exam",{"name":"isInList","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":12,"column":33}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"linear","","adaptive","adaptive_student",{"name":"isInList","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":24,"column":33}}})) != null ? stack1 : "")
    + "                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"js-index-letter "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-index") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasLinkToTask") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":74},"end":{"line":7,"column":135}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"indexLetter") : depth0), depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-index--hoverable") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"exam-points-container") : stack1), depth0))
    + " js-task-exam-points\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score") : stack1), depth0))
    + " js-grade-score-holder\">\n\n                            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score__score-holder") : stack1), depth0))
    + " js-grade-holder\" data-graded-by=\"\">\n                                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score__score") : stack1), depth0))
    + " js-grade-score\"></div>\n                            </div>\n\n                            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"grade-score__title") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"score",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":21,"column":71},"end":{"line":21,"column":85}}}))
    + "</div>\n\n                        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"js-introduction work-on__task-title\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"introduction") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"presentation",{"name":"isNotEqual","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":161,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":8},"end":{"line":166,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"js-answer-explanation\"></div>\n\n            <div class=\"js-task-answer no-print "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-answer") : stack1), depth0))
    + "\">\n\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"adaptive","adaptive_student",{"name":"isNotInList","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":154,"column":32}}})) != null ? stack1 : "")
    + "            </div>\n\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n            <div class=\"work-on__task-notes "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isStudent.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isStudent","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":44},"end":{"line":40,"column":106}}})) != null ? stack1 : "")
    + " js-notes\"></div>\n\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "is-student "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"student-note") : stack1), depth0));
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_answers") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":95,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isTeacher.js")).call(alias1,{"name":"isTeacher","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":97,"column":20},"end":{"line":133,"column":34}}})) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasModelAnswer") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":93,"column":31}}})) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"no_answer") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":73,"column":35}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                                <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":57,"column":36},"end":{"line":57,"column":55}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"No answer to this question",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":58,"column":42},"end":{"line":58,"column":77}}}))
    + "</span>\n                                </div>\n\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"js-show-answer "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStandalone") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":77},"end":{"line":62,"column":129}}})) != null ? stack1 : "")
    + "\">\n                                        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias1,"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":63,"column":40},"end":{"line":63,"column":63}}})) != null ? stack1 : "")
    + "\n                                        <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStandalone") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":65,"column":44},"end":{"line":69,"column":51}}})) != null ? stack1 : "")
    + "                                        </span>\n                                    </div>\n\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"is-standalone") : stack1), depth0));
},"24":function(container,depth0,helpers,partials,data) {
    return "                                                "
    + container.escapeExpression(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Check my answer",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":66,"column":48},"end":{"line":66,"column":72}}}))
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                                                "
    + container.escapeExpression(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Answer to this question",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":68,"column":48},"end":{"line":68,"column":80}}}))
    + "\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasExplanation") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":77,"column":28},"end":{"line":91,"column":35}}})) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                                <div class=\"js-show-answer "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"isStandalone") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":73},"end":{"line":79,"column":125}}})) != null ? stack1 : "")
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":80,"column":36},"end":{"line":80,"column":59}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Explanation of the answer",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":81,"column":42},"end":{"line":81,"column":76}}}))
    + "</span>\n                                </div>\n\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotMobile.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotMobile","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":24},"end":{"line":114,"column":40}}})) != null ? stack1 : "")
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPreview") : depth0),{"name":"unless","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":28},"end":{"line":113,"column":39}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":32},"end":{"line":105,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTaskAnalytics") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":32},"end":{"line":112,"column":39}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"js-check-answer "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"people",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":102,"column":36},"end":{"line":102,"column":56}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Answers by students",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":103,"column":42},"end":{"line":103,"column":70}}}))
    + "</span>\n                                </div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"js-open-analytics "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"btn") : stack1), depth0))
    + "\">\n                                    "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"trend-up",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":109,"column":36},"end":{"line":109,"column":58}}})) != null ? stack1 : "")
    + "\n                                    <span>"
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Task analytics",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":110,"column":42},"end":{"line":110,"column":65}}}))
    + "</span>\n                                </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canAddResponses") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":24},"end":{"line":131,"column":31}}})) != null ? stack1 : "")
    + "\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"unsaved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-unsaved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":120,"column":32},"end":{"line":120,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"No answer is saved",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":121,"column":38},"end":{"line":121,"column":65}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"syncing\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-syncing",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":124,"column":32},"end":{"line":124,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved locally and syncing to server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":125,"column":38},"end":{"line":125,"column":92}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"saved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-saved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":128,"column":32},"end":{"line":128,"column":58}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved on server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":129,"column":38},"end":{"line":129,"column":72}}}))
    + "</span>\n                            </div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isStudent.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isStudent","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":20},"end":{"line":152,"column":34}}})) != null ? stack1 : "")
    + "\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"unsaved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-unsaved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":140,"column":32},"end":{"line":140,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"No answer is saved",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":141,"column":38},"end":{"line":141,"column":65}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"syncing\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-syncing",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":144,"column":32},"end":{"line":144,"column":60}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved locally and syncing to server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":145,"column":38},"end":{"line":145,"column":92}}}))
    + "</span>\n                            </div>\n                            <div class=\"js-response-saved-status "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" data-status=\"saved\">\n                                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"server-saved",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":148,"column":32},"end":{"line":148,"column":58}}})) != null ? stack1 : "")
    + "\n                                <span>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Answer is saved on server",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":149,"column":38},"end":{"line":149,"column":72}}}))
    + "</span>\n                            </div>\n\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"send-answer-btn") : stack1), depth0))
    + " js-send-presentation-answer\"></div>\n\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"js-work-on-answer\"></div>\n            <div class=\"js-task-analytics\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n    <article class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task") : stack1), depth0))
    + "\" data-task-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"task_id") : depth0), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-title") : stack1), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotEqual.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"activityType") : depth0),"presentation",{"name":"isNotEqual","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-container") : stack1), depth0))
    + "\">\n            <div class=\"js-content\"></div>\n        </div>\n\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotAuthor.js")).call(alias3,{"name":"isNotAuthor","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":168,"column":24}}})) != null ? stack1 : "")
    + "\n    </article>\n</div>\n";
},"useData":true});