<script>
    import QuickInput from 'views/components/quickInput/QuickInput.svelte';

    export let groupId
    export let searchButtonElement
    export let isVisible = false

    let containerElement

    // Hide the search input when clicking on something that is not the search button or the input field
    function onClickDocument(event) {
        if (searchButtonElement?.contains(event.target) || containerElement?.contains(event.target)) {
            return
        }

        isVisible = false
    }

    function onSubmitSearch(query) {
        if (!query) {
            return
        }

        Backbone.history.navigate(`/groups/search/${groupId}?query=${encodeURIComponent(query)}`, { trigger: true })
    }
</script>

<svelte:document on:click={onClickDocument} />

{#if isVisible}
    <div bind:this={containerElement} class="container">
        <QuickInput
            type="search"
            placeholder={window.i18n.gettext('Search within this learning material..')}
            autoFocus={true}
            submitCallback={onSubmitSearch}>
        </QuickInput>
    </div>
{/if}

<style lang="scss">
    .container {
        margin-bottom: 24px;
        animation: searchFadeIn 0.25s ease-out;
    }

    @keyframes searchFadeIn {
        0% {
            opacity: 0;
            transform: translateY(-24px);
        }
    }
</style>