var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"annotation-input") : stack1), depth0))
    + "\">\n    <fieldset>\n        <legend>"
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Make an annotation",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":43}}}))
    + "</legend>\n\n        <section>\n            <div class=\"js-annotation js-annotation-input-container\"></div>\n        </section>\n\n        <section class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"form-actions") : stack1), depth0))
    + "\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"save-path") : stack1), depth0))
    + " js-dropdown-path-holder\" data-prefix-label=\""
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Save in",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":10,"column":89},"end":{"line":10,"column":105}}}))
    + "\"></div>\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"save-button") : stack1), depth0))
    + " js-save-annotation-button\"></div>\n        </section>\n\n    </fieldset>\n</form>\n";
},"useData":true});