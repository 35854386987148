import SourceModel from 'models/SourceModel'

export default class PortfolioItemModel extends SourceModel {

    initialize() {

        super.initialize()

        this.listenTo(
            this,
            'change:sequence',
            this.onChangeSequence
        );

        this.onChangeSequence()

    }

    /**
     * onChangeSequence
     *
     * This method will be called when the sequence is changed. It will make
     * sure the sequence is cast to the correct type, This is important for
     * changing the sequence because we need to search for a neighbour using
     * these sequences
     *
     */
    onChangeSequence() {
        this.set('sequence', parseInt(this.get('sequence')))
    }

    /**
     * url
     *
     * This method will return the correct url for the model for when
     * there is an id and for when there is no ID.
     *
     * @return {string}     URL to use for this model
     */
    url() {
        return (this.id) ?
            '/portfolio/item/' + this.id + '.json' :
            '/portfolio/item.json'
    }

    /**
     * save_element
     *
     * This method will be used to override the current
     * save element method from the element modal. This way
     * the element will always send the correct project_id
     * with the save/patch request.
     *
     * @param  {Object} attributes      Attributes to save
     */
    save_element(attributes) {
        // Shim the project id from the real attributes to the attributes
        // object of which to store/save in the backend. This will add
        // project_id to the POST request
        attributes.project_id = this.get('project_id')

        // Call the original logic for saving an element passing the
        // scope of the model and the attributes argument
        super.save_element(attributes)
    }

    initEmptyState() {
        // if a portfolio item is copied from learning material,
        // and has a source, skip overriding the source
        if (this.has('source')) {
            return
        }

        super.initEmptyState(this)

        // Since the element model removes the source attribute when it
        // can't find a default structure. The post/patch won't send a
        // source with it. The portfolio backend can't handle this,
        // because it needs a source. To fix this we are goint to
        // override the undefined source to an empty string to send the
        // backend an 'empty' source (empty string will be stored as
        // empty field).
        if (
            this.get('element_type') === 'source' &&
            this.get('source') === undefined
        ) {
            this.set('source', '')
        }
    }

}
