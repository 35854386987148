import Template from './AnalyticsSummary.hbs';
import AnalyticsStyles from 'views/components/taskGroups/tasks/analytics/Analytics.scss'

export default class Template2AnalyticsAnswerList extends BaseView {
    initialize({
        list
    }) {
        this.setElement(Template({
            Styles: AnalyticsStyles,
            list,
        }))
    }
}
