import Styles from './TextSelector.scss';

import Template from './TextSelector.hbs';
import Util from 'util/util';

export default BaseView.extend({

    // Selection mode enums
    SELECT_WORD: 0,
    SELECT_SENTENCE: 1,
    SELECT_PARAGRAPH: 2,

    events: {
        'mousedown .js-fragment': 'setSelection',
        'mouseover .js-fragment': 'setSelection',
        'mouseenter .js-fragment': 'addHover',
        'mouseleave .js-fragment': 'removeHover'
    },

    initialize(options) {

        this.savedFragments = options.savedFragments;
        this.selectionMode = options.selectionMode;
        this.JSONAnswer = options.JSONAnswer;

        this.isSelectionLocked = false;

        this.rerender();

    },

    rerender() {

        var rerendered = $(Template({
            Styles,
            content: Util.renderContentSafely(this.createContent(this.savedFragments))
        }));

        // Store reference to old element
        var oldElement = this.$el;

        // Replace and set view element.
        this.$el.replaceWith(rerendered);
        this.setElement(rerendered);

        // Remove old element
        oldElement.remove();

        // (Re)Bind the events to this view.
        this.delegateEvents();

    },

    /**
     * createContent
     *
     * Create DOM string from fragment array.
     *
     * @param  {Array} fragments    array of fragment data
     * @return {string}             DOM string
     */
    createContent(fragments) {

        // Content string of DOM elements making up the text.
        var content = '';

        // List of inline tags that need to handles as if they are nested within a
        // fragment instead of outside of one. This is to prevent tag mismatches when
        // introducing the span wrappers around selectable fragments. Otherwise the
        // browser will try to 'fix' this invalid HTML automatically, resulting in
        // unwanted behavior.
        //
        // For example, this data…
        // [
        // "<p>",
        // {
        //     "t": "Few <strong>black taxis drive up major roads on quiet hazy nights.",
        //     "id": 1
        // },
        // " ",
        // {
        //     "t": "Back in June</strong> we delivered <em>oxygen equipment</em> of the same size.",
        //     "id": 2
        // },
        // "</p>"
        //
        // …would be converted to this by the browser when it becomes DOM:
        //
        // <p>
        //      <span data-id=1>Few <strong>black taxis drive up major roads on quiet hazy nights.</strong></span>
        //      <strong>
        //          <span data-id=2>Back in June</span>
        //      </strong>
        //      we delivered <em>oxygen equipment</em> of the same size.
        // </p>
        //
        // Here part of the second selectable fragment is not inside the selectable container anymore
        // because the browser didn't find the right closing tag. With the special parsing for inline
        // elements like <strong> and <em> implemented below you will get the following output:
        //
        // <p>
        //      <span data-id=1>Few <strong>black taxis drive up major roads on quiet hazy nights.</strong></span>
        //      <span data-id=2><strong>Back in June</strong> we delivered <em>oxygen equipment</em> of the
        //      same size.</span>
        // </p>
        var arrayOfTagsToHandle = [{
            name: 'strong'
        }, {
            name: 'em'
        }, {
            name: 'sub'
        }, {
            name: 'sup'
        }, {
            name: 'span',
            openingTag: '<span style="text-decoration: underline;">',
            openingTagRegEx: new RegExp(
                // Start opening tag + additional whitespace and attributes.
                '<span[\\w\\s=".\':;#\\-\/\\?]*?' +
                // Underline styling.
                'style=["\']text-decoration:\\s?underline;?["\']' +
                // Additional whitespace and attributes + end opening tag.
                '[\\w\\s=".\':;#\\-\/\\?]*?>', 'i'
            )
        }];
        arrayOfTagsToHandle = _.map(arrayOfTagsToHandle, (tagInfo) => {
            return _.extend({
                openingTag: '<' + tagInfo.name + '>',
                closingTag: '</' + tagInfo.name + '>',
                openingTagRegEx: new RegExp('<' + tagInfo.name + '[\\w\\s=".\':;#\\-\/\\?]*?>', 'i'),
                closingTagRegEx: new RegExp('<\/' + tagInfo.name + '\\s*?>', 'i'),
                isInside: false,
                isStarting: false,
                isEnding: false
            }, tagInfo);
        });

        _.each(fragments, (fragment) => {

            // If fragment is selectable.
            if (fragment instanceof Object) {

                var fragmentContent = '';

                // Check for each tag in arrayOfTagsToHandle if it has a starting tag
                // and a closing tag of this type.
                _.each(arrayOfTagsToHandle, (tagToHandle) => {
                    if (tagToHandle.openingTagRegEx.test(fragment.t)) {
                        tagToHandle.isStarting = true;
                    }

                    if (tagToHandle.closingTagRegEx.test(fragment.t)) {
                        tagToHandle.isEnding = true;
                    }
                });

                // Add selectable fragment wrapper span with data on fragment id and
                // selection state.
                var fragmentContentStart = (
                    '<span class="js-fragment ' + Styles.fragment + '" ' +
                    'data-id=' + fragment.id + '>'
                );
                fragmentContent += fragmentContentStart;

                // If inside the inline tag, insert an opening tag before the content.
                _.each(arrayOfTagsToHandle, (tagToHandle) => {
                    if (tagToHandle.isInside) {
                        fragmentContent += tagToHandle.openingTag;
                    }
                });

                // Insert content.
                fragmentContent += fragment.t;

                _.each(arrayOfTagsToHandle, (tagToHandle) => {

                    // If tag has an opening tag in the present fragment, add a
                    // closing tag unless it contains an closing tag of the
                    // same type.
                    if (
                        (tagToHandle.isStarting || tagToHandle.isInside) &&
                        !tagToHandle.isEnding
                    ) {
                        fragmentContent += tagToHandle.closingTag;
                    }

                    // Set flag that content is inside the tag while it is
                    // not the start of the tag for the next fragment.
                    if (tagToHandle.isStarting) {
                        tagToHandle.isInside = true;
                        tagToHandle.isStarting = false;
                    }

                    // Set flag that tag has closed and the content is no
                    // longer inside it for the next fragment.
                    if (tagToHandle.isEnding) {
                        tagToHandle.isInside = false;
                        tagToHandle.isEnding = false;
                    }
                });

                // Close fragment wrapper.
                fragmentContent += '</span>';

                // Split fragment into multiple spans before and after line breaking HTML.
                fragmentContent = fragmentContent.replace(
                    // Find 1 or more consecutive line breaking tags.
                    // <\/?                     opening or closing tag
                    // (?:p|h\d|blockquote|li|ul|ol|aside|img) block element types
                    // [\w\s=".':;#\-\/\?]*?    zero or more of any kind of character valid as HTML attribute
                    // >
                    /(<\/?(?:p|h\d|blockquote|li|ul|ol|aside|img)[\w\s=".':;#\-\/\?]*?>)+/ig,
                    (match) => {
                        return match.replace(
                            // Closing fragment before line breaking closing tag.
                            /(<\/[\w\s=".':;#\-\/\?]+>)+/gi, '</span>$&'
                            // Open fragment after line breaking closing tags.
                        ).replace(
                            /(<(?!\/)[\w\s=".':;#\-\/\?]+>)+/gi, '$&' + fragmentContentStart
                        );
                    }

                    // Remove empty fragment spans.
                ).replace(fragmentContentStart + '</span>', '');

                // Add an extra spaces between sentence and paragraph fragments for better readability.
                if (this.selectionMode !== this.SELECT_WORD) {
                    fragmentContent += '&nbsp';
                }

                content += fragmentContent;

            } else if (!_.any(arrayOfTagsToHandle, (tag) => {
                // If any of the tags listed in arrayOfTagsToHandle are found of either the opening
                // or closing variant, do not add this non-selectable fragment to the DOM to prevent
                // unexpected rendering.
                return tag.openingTagRegEx.test(fragment) || tag.closingTagRegEx.test(fragment);
            })) {
                content += fragment;
            }

        })

        return content;

    },

    /**
     * showSelection
     *
     * Show the current fragment selection in the DOM representation.
     *
     * @param  {Array} fragments    array of fragments
     */
    showSelection(fragments) {
        _.each(fragments, (fragment) => {
            this.$('.js-fragment[data-id=' + fragment.id + ']').attr('data-select', fragment.select | 0);
        })
    },

    /**
     * addHover
     *
     * Add hover styling to all fragments of the same ID.
     *
     * @param  {MouseEvent} e   mouseenter event
     */
    addHover(e) {
        this.$('.js-fragment[data-id=' + e.currentTarget.dataset.id + ']').addClass(Styles['fragment--hover']);
    },

    /**
     * removeHover
     *
     * Removes hover styling from all fragments with the same ID.
     *
     * @param  {MouseEvent} e   mouseout event
     */
    removeHover(e) {
        this.$('.js-fragment[data-id=' + e.currentTarget.dataset.id + ']').removeClass(Styles['fragment--hover']);
    },

    /**
     * showAnswer
     *
     * Show the correct answer to the task.
     *
     * @param  {boolean} isStudentAnswer    true if rendering student answer
     * @param  {Element} context            element scope
     * @param  {Array} responseModel        user answers
     */
    showAnswer(isStudentAnswer, context, responseModel) {
        // If this is not for showing a student answer (Template33.getStudentAnswer),
        // use this views DOM as the element scope and the current user's JSONAnswer
        // as the responseModel for marking the selected fragments. Also disable the
        // editing of selections when this happens.
        if (!isStudentAnswer) {
            context = this.$el;
            responseModel = this.JSONAnswer;
        }
        _.each(this.savedFragments, (fragment) => {

            var isExistingAnswer = _.contains(responseModel, fragment.id);

            // Mark if correct answer.
            if (fragment.select && isExistingAnswer) {
                context.find('.js-fragment[data-id=' + fragment.id + ']').addClass(Styles['fragment--correct']);
            } else if (fragment.select && !isExistingAnswer) {
                // Mark if missing answer.
                context.find('.js-fragment[data-id=' + fragment.id + ']').addClass(Styles['fragment--missing']);
            } else if (!fragment.select && isExistingAnswer) {
                // Mark if incorrect answer.
                context.find('.js-fragment[data-id=' + fragment.id + ']').addClass(Styles['fragment--incorrect']);
            }

        })
    },

    /**
     * hideAnswer
     *
     * Hide the correct answer to the task.
     */
    hideAnswer() {
        // Remove all answer grading markings and unlock the editing of selections.
        this.$('.js-fragment').removeClass(
            Styles['fragment--correct'] + ' ' +
            Styles['fragment--missing'] + ' ' +
            Styles['fragment--incorrect']
        );
    },

    /**
     * setSelection
     *
     * Select or delect a selectable fragment
     *
     * @param  {Event} e    click event on a selectable fragment
     */
    setSelection(e) {
        var isDragging = e.type === 'mouseover' && e.buttons === 1;
        if (!this.isSelectionLocked &&
            (e.type === 'mousedown' || isDragging)
        ) {
            var fragment = _.findWhere(this.savedFragments, {
                id: parseInt(e.currentTarget.dataset.id)
            });
            if (fragment) {

                var isExistingAnswer = _.contains(this.JSONAnswer, fragment.id);

                // If mouse is dragging, get selection status from the frame the user
                // pressed the mouse down to also apply that selection status to all
                // fragments being dragged over.
                if (e.type === 'mousedown') {
                    this.mouseOverSelectionStatus = isExistingAnswer;
                }
                if (isDragging) {
                    isExistingAnswer = this.mouseOverSelectionStatus;
                }

                this.JSONAnswer = _.without(this.JSONAnswer, fragment.id);
                if (!isExistingAnswer) {
                    this.JSONAnswer.push(fragment.id);
                }

                // Show selection change in DOM.
                this.showSelection([{
                    id: fragment.id,
                    select: !isExistingAnswer
                }]);

                // Sort JSONAnswer to prevent unneeded PATCH requests.
                this.JSONAnswer = _.sortBy(this.JSONAnswer);

                // Trigger change for view Template33 to call the saveAnswer method.
                this.trigger('JSONAnswerChanged');

            }
        }
    },

    /**
     * setSelectionLock
     *
     * Enable/Disable the editing of selections.
     *
     * @param  {boolean} isSelectionLocked  should selection editing be locked or not?
     */
    setSelectionLock(isSelectionLocked) {
        this.isSelectionLocked = isSelectionLocked;
        this.$el.toggleClass(Styles['text--locked'], this.isSelectionLocked);
    }

});
