var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"dropzone") : stack1), depth0))
    + " js-dropzone\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"category") : stack1), depth0))
    + "\">\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"items") : stack1), depth0))
    + "\">\n		<div class=\"js-category-items\"></div>\n"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isNotAuthor.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotAuthor","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":7,"column":18}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});