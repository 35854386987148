var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"TextareaStyling") : depth0)) != null ? lookupProperty(stack1,"textarea-wrapper") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"TextareaStyling") : depth0)) != null ? lookupProperty(stack1,"dummy") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"diff") : stack1), depth0))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"diff") || (depth0 != null ? lookupProperty(depth0,"diff") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"diff","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":69}}}) : helper))) != null ? stack1 : "")
    + "</div>\n</div>\n";
},"useData":true});