import Styles from './TeacherMaterial.scss';

import Template from './TeacherMaterial.hbs';
export default BaseView.extend({
    initialize() {

        this.setElement(Template({
            Styles,
            collection: this.collection
        }))

    },
})
