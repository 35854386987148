var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../util/handlebarsHelpers/isStudent.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isStudent","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":18}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"js-join-group\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"toggle-environment") : stack1), depth0))
    + " js-toggle-environment\">\n        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"desktop",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":29}}})) != null ? stack1 : "")
    + "\n        "
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"Desktop version",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":32}}}))
    + "\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"toggle-environment") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"show-mobile") : stack1), depth0))
    + " js-toggle-environment\">\n        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"smartphone",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":32}}})) != null ? stack1 : "")
    + "\n        "
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Mobile version",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":31}}}))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n    <div class=\"js-alert "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"alert") : stack1), depth0))
    + "\"></div>\n\n    <div class=\"js-spinner "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"spinner") : stack1), depth0))
    + "\"></div>\n    <div class=\"js-error "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"error") : stack1), depth0))
    + "\"></div>\n\n    <div class=\"js-cards-container\"></div>\n\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isMobile.js")).call(alias3,{"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isMobile.js")).call(alias3,{"name":"isMobile","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotMobile.js")).call(alias3,{"name":"isNotMobile","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":26,"column":20}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});