import Styles from './Colors.scss';

import Template from './Colors.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import ColorPopupTemplate from 'views/components/wysiwyg/parts/buttons/colors/ColorsPopup.hbs';
import ColorsButtonTemplate from 'views/components/wysiwyg/parts/buttons/colors/ColorButton.hbs';

export default class ColorsInput extends Button {

    // Built-in TinyMCE command for changing text color
    command = 'ForeColor'

    // These colors are hard-copied from DrawingCanvasUtil.js as we can't import these dynamically
    get colors() {
        return [
            '#000000', '#de2ea0', '#2ba5ff',
            '#53ffcd', '#45d71a', '#ff4d49',
            '#ff9d2b', '#f0da1d', '#9b9b9b'
        ]
    }

    initializeButton() {
        _.bindAll(this,
            'openPopupMenu',
            'onChosenColor',
            'mouseUpDocument'
        );

        this.icon = Template();
        this.tooltip = window.i18n.gettext('Colors');
    }

    mouseUpDocument(e) {
        // Close the colour picker menu if the click target is outside of the color picker or color picker button.
        if (!this.el.contains(e.target)) {
            this.closePopupmenu()
        }
    }

    onClickButton(e) {
        this.stopAllEvents(e);
        this.openPopupMenu(e);
    }

    closePopupmenu() {
        // Re-enable the tooltip
        this.disableTooltip = false;

        // Remove popup
        this.popup?.remove()
        delete this.popup;

        // Remove click listener from Learnbeat document and TinyMCE iframe document.
        document.removeEventListener('click', this.mouseUpDocument)
        if (this.wysiwyg.editor) {
            this.wysiwyg.editor.getDoc().removeEventListener('click', this.mouseUpDocument)
        }
    }

    openPopupMenu(e) {
        // Open popup if it's not already visible.
        if (this.popup === undefined) {
            this.popup = $(ColorPopupTemplate({
                Styles
            }))
            this.$('.js-icon-holder').after(this.popup);
            this.appendColorButtons(this.colors)

            // Disable tooltip so it does not go over the popup menu.
            this.disableTooltip = true;
            this.onDeHoverButton();

            // Add click listener to Learnbeat document and TinyMCE iframe document so the popup gets closed
            // when user clicks anywhere but the popup menu itself.
            document.addEventListener('click', this.mouseUpDocument)
            if (this.wysiwyg.editor) {
                this.wysiwyg.editor.getDoc().addEventListener('click', this.mouseUpDocument)
            }

        } else if (!this.popup[0].contains(e.target)) {
            // Close when popup is open and clicked target is not popup.
            this.closePopupmenu();
        }
    }

    appendColorButtons(colors) {
        const colorsContainer = this.$('.js-colors')
        colorsContainer.empty()
        for (const color of colors) {
            const button = ColorsButtonTemplate({
                color: `${color}`,
            })

            colorsContainer.append(button)
        }

        // Add click listener for each color on in the color popup.
        colorsContainer.find('.js-color-button').on('click', this.onChosenColor);
    }

    onChosenColor(e) {
        this.stopAllEvents(e);

        // Distill the color from the clicked element
        const color = e.currentTarget.dataset.color

        // Execute the MCE command
        this.wysiwyg.onMceCommand(this, color);

        // Close popupmenu after choosing color
        this.closePopupmenu();
    }

}
