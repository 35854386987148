var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"no-group-tile") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isStudentOrMobile") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression(__default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"This group doesn't have any content yet.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":65}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"This group doesn't have any content yet, go to {0}the library{1} to add new content.",(depth0 != null ? lookupProperty(depth0,"addContentUrl") : depth0),"</a>",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasStudentsOrIsNotTeacher") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":26,"column":19}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"no-group-tile") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"This group doesn't contain students yet, go to {0}manage students{1} to add students.",(depth0 != null ? lookupProperty(depth0,"manageStudentsUrl") : depth0),"</a>",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"recent-progress") : stack1), depth0))
    + " js-recent-progress\">\n        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"bookmark",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":30}}})) != null ? stack1 : "")
    + "\n        "
    + alias1(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias2,"You were last active in",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":40}}}))
    + "\n        <span class=\"js-recent-progress-section\"></span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n\n    <div class=\"js-search-input\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasContent") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isStudent.js")).call(alias1,{"name":"isStudent","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":35,"column":18}}})) != null ? stack1 : "")
    + "\n    <div class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"chapters-overview") : stack1), depth0))
    + " js-chapters\"></div>\n</div>\n";
},"useData":true});