import TaskModel from 'models/TaskModel'
import SourceModel from 'models/SourceModel'
import Util from 'util/util'
export default class ElementsCollection extends Backbone.Collection {

    preinitialize() {
        this.constructor.type = 'elements'
    }

    // To prevent sources and tasks with the same ID from colliding, use the model type as prefix.
    // So when using ElementsCollection directly, use collection.get('source' + id) or
    // collection.get('task' + id) to get the correct model. If the type of all models is known,
    // use TasksCollection or SourcesCollection instead.
    modelId(attr) {
        return attr.element_type + attr.id;
    }

    get model() {
        return function(attr, options) {
            switch (attr.element_type) {
                case 'task': return new TaskModel(attr, options);
                case 'source': return new SourceModel(attr, options);
            }
        }
    }

    comparator(model) {
        return parseInt(model.get('sequence'));
    }

    setSequence(cidToSequenceList) {

        var task_index = 0;
        var arrayToSend = _.map(cidToSequenceList, (cid, sequence) => {

            var model = this.get(cid);

            if (!model) {
                model = this.last();
            }

            if (model.get('element_type') === 'task') {
                model.set('index', Util.numToLetter(task_index++));
            }
            model.set('sequence', sequence);

            return {
                id: model.id,
                sequence,
                task_index,
                element_type: model.get('element_type')
            };
        })

        this.sort();

        if (this.length > 0) {
            $.post('/tasks/update_sequence/' + this.parent_id + '.json', {new_sequence: arrayToSend});
        }

    }

}
