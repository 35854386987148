var Handlebars = require("../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subjectName") : depth0), depth0))
    + "</div>\n<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"points-container") : stack1), depth0))
    + "\">\n    <div data-progress=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subjectId") : depth0), depth0))
    + "\"></div>\n    <div><b>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"receivedPoints") : depth0), depth0))
    + "</b> <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lighter-text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"of",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":84}}}))
    + "</span> <b>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attainablePoints") : depth0), depth0))
    + "</b></div>\n</div>\n";
},"useData":true});