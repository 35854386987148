import Styles from './CrumblepathItem.scss';

import Template from './CrumblepathItem.hbs';
export default class CrumblepathItem extends BaseView {

    initialize({ model, action, isFirstItem }) {

        let url = model.get('url')
        if (!url) {
            url = '/' + model.get('level') + '/' + action + '/' + model.get('path_id')
        }

        this.setElement(Template({
            Styles,
            index: model.get('index'),
            label: model.get('label'),
            url,
            isFirstItem
        }))
    }

}
