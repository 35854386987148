import Styles from './WordCounter.scss';

import Template from './WordCounter.hbs';
export default class WordCounter extends BaseView {

    initialize(text = null) {

        // Create the template
        this.setElement(Template({
            Styles
        }));

        if (text) {
            this.updateWordCount(text)
        } else {
            this.showWordCount()
        }
    }

    /**
     * Updates the counter view with the latest count value
     *
     * @param {Number} count    word count
     */
    showWordCount(count = 0) {
        this.el.textContent = window.i18n.sprintf(window.i18n.ngettext('%s word', '%s words', count), count)
    }

    /**
     * Counts the words for the provided text. Calls the showWordCount() if we find differentiating count from current.
     *
     * @param {string} text Text for which we count the amount of alphanumeric containing words
     */
    updateWordCount(text = '') {
        this.showWordCount(WordCounter.getWordCount(text))
    }

    static getWordCount(text = '') {
        const matches = text.match(/[\p{L}\p{N}]+/ug)
        return matches ? matches.length : 0
    }

}
