var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "\">\n                                    "
    + alias1(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Practicing is not possible due to missing subjects.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":96}}}))
    + "\n                                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPractice") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":33,"column":39}}})) != null ? stack1 : "")
    + "\n                                <div class=\"js-subjects "
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"subjects") : stack1), depth0))
    + "\" data-empty-state=\""
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"No subjects are selected.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":35,"column":95},"end":{"line":35,"column":129}}}))
    + "\"></div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "\">\n                                        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"What subjects do you want to practice?",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":26,"column":40},"end":{"line":26,"column":87}}}))
    + "\n                                    </div>\n                                    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"option-group") : stack1), depth0))
    + " js-option-group\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "\">\n                                        "
    + alias1(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"What are your subjects?",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":31,"column":40},"end":{"line":31,"column":72}}}))
    + "\n                                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n\n    <div class=\"work-on__taskgroup\">\n        <div class=\"center-panel\">\n            <div class=\"js-made-before-banner\"></div>\n        </div>\n    </div>\n\n    <div class=\"js-activity-viewport\">\n        <div class=\"js-taskgroup\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"introduction") : stack1), depth0))
    + "\">\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"container") : stack1), depth0))
    + "\">\n                    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"content-holder") : stack1), depth0))
    + "\">\n                        <div class=\"center-panel\">\n                            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"tasks-made") : stack1), depth0))
    + "\">\n                                <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"number") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"made") : depth0), depth0))
    + "</span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"tasks made",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":16,"column":79},"end":{"line":16,"column":98}}}))
    + "\n                            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isEmptyPractice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":37,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                    <div class=\"center-panel\">\n                        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"buttons") : stack1), depth0))
    + "\">\n                            <div class=\"js-start-activity\"></div>\n                        </div>\n                    </div>\n                </div>\n\n\n            </div>\n        </div>\n        <div class=\"work-on__taskgroup\">\n            <div class=\"center-panel\">\n                <div class=\"js-activity-buttons "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"activity-bottom") : stack1), depth0))
    + "\"></div>\n            </div>\n        </div>\n    </div>\n\n</div>\n";
},"useData":true});