import Template from './Answer.hbs';
var AnswerView = BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     */
    initialize() {

        // Create the view, passing the styling with it
        this.setElement(Template({}));
    },

});

export default AnswerView;
