import Styles from './GroupNote.scss';

import Template from './GroupNote.hbs';
import NoteView from 'views/components/notes/noteWorkOn/NoteWorkOn'

export default BaseView.extend({
    initialize() {

        this.setElement(Template({
            Styles,
        }))

        this.addChildView(new NoteView({
            model: this.model,
        }), '.js-group-note')

    },
})
