import Styles from './StudentAnswer.scss';

import Template from './StudentAnswer.hbs';
import AkitUtil from 'views/components/taskGroups/tasks/template35/Util';

export default BaseView.extend({

    initialize({

        // The HTML sent from Algebrakit that will render the
        // right widget for this session
        exerciseHtml,

        // The related session generated from Algebrakit.
        // Responses/progress is saved per session.
        sessionId,

        // The mode of the Algebrakit question
        mode,

        type,

        // The task model
        // this.model
    }) {

        AkitUtil.initAlgebrakit();

        if (type === 'COMPOUND') {

            // When we have a compound exercise, we can load the exercise HTML inside the widget
            // but we need to add the review-mode attribute.
            this.exerciseHtml = AkitUtil.parseExerciseHtml(exerciseHtml, mode);

        }

        this.setElement(Template({
            exerciseHtml: this.exerciseHtml,
            sessionId,
            mode,
            type,
            Styles
        }));
    },

    /**
         * parseExerciseHtml
         *
         * Parse exercise HTML of a compound exercise.
         * Add the attribute review-mode to the akit interaction element.
         *
         * @param  {String} exerciseHtml html that needs to be loaded into the akit widget
         * @param  {String} mode         solution or review mode
         * @return {String}              html with review-mode attribute added
         */
    parseExerciseHtml(exerciseHtml, mode) {

        const akitInteraction = exerciseHtml.match(/<akit-interaction (.+?)>/);

        if (akitInteraction) {

            // add review-mode attribute to this element
            const newAkitElement = this.adjustHtml(akitInteraction, mode);

            // Replace the exercise HTML with the new akit interaction
            return exerciseHtml.replace(/<akit-interaction (.+?)>/, newAkitElement);
        }

        const akitContent = exerciseHtml.match(/<akit-content (.+?)>/);

        if (akitContent) {

            // add review-mode attribute to this element
            const newAkitElement = this.adjustHtml(akitContent, mode);

            // Replace the exercise HTML with the new akit interaction
            return exerciseHtml.replace(/<akit-content (.+?)>/, newAkitElement);
        }

    },

    /**
         * adjustHtml
         *
         * Add review mode at the end of an element
         * @param  {String} elem string representing html elem
         * @param  {String} mode solution or review mode
         * @return {String}      edited string with review-mode attribute
         */
    adjustHtml(elem, mode) {
        return elem[0].replace('>', ' ' + mode + '>');
    }
});
