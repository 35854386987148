import Styles from './InlineCrumblepath.scss';

import Template from './InlineCrumblepath.hbs';
export default class InlineCrumblepath extends BaseView {

    initialize({
        fragments,
        pageType,
        isLink = true
    }) {

        // All items are expected to be objects, with the following properties:
        //   - id (id of the model item)
        //   - level (f.e.: groups, chapters, etc.)
        //   - label (name of the model item)
        // Optional attributes:
        //   - index (index to prefix label)
        //   - isHidden (to hide this model from crumblepath)
        const items = (typeof this.model?.getAllCrumblepathModels === 'function')
            ? this.model.getAllCrumblepathModels().map(model => {
                return {
                    ...model.attributes,
                    // Rename path_id to id
                    id: model.get('path_id')
                }
            })
            : fragments;

        if (!items || items.length === 0) {
            console.error('No (valid) items are passed to the inlineCrumblepath');
            return;
        }

        this.setElement(Template({
            Styles,
            items,
            isLink,
            pageType
        }))
    }
}
