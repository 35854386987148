import Styles from './SubjectProgressLabel.scss';

import Template from './SubjectProgressLabel.hbs';
import ToolTip from 'views/components/toolTip/ToolTip'

export default BaseView.extend({

    initialize() {

        this.setElement(Template({
            Styles,
            subjectName: _.unescape(this.model.get('name')),
            color: Styles[`subject__status--${this.model.getScoreColor()}`]
        }))

        this.addChildView(new ToolTip({
            text: window.i18n.sprintf(
                window.i18n.gettext('%s / <b>%s</b> made'),
                this.model.getScoreLabel(),
                this.model.getTasksMade()
            ),
            parentElement: this.$el,
            alignment: 'top'
        }), this.$el)

    }

})
