import Styles from './ActivationCodeForm.scss';

import Template from './ActivationCodeForm.hbs';
import Button from 'views/components/button/Button'
import QuickInput from 'views/components/quickInput/QuickInput';
import BaseView from 'views/BaseView';
import ContactOptionsTemplate from './ContactOptions.hbs';

export default BaseView.extend({

    events: {
        'click .js-support-options': 'onClickSupportOptions'
    },

    CODE_LENGTH: 10,

    /**
     * @param {Object} options options object
     * @param {Integer} options.schoolId the activity model
     */
    initialize({
        schoolId,
    }) {

        this.schoolId = schoolId

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        // Add input for activation code.
        this.codeInput = this.addChildView(new QuickInput({
            maxLength: this.CODE_LENGTH,
            hasUppercase: true,
            keepValueOnCallback: true,
            theme: 'xl',
            inputCallbackWithoutButton: () => { this.sendCode() }
        }), '.js-code-input');

        // Add button to activate license code.
        this.orderBtn = this.addChildView(new Button({
            label: window.i18n.gettext('Activate license'),
            inline: true,
            callback: () => { this.sendCode() }
        }), '.js-submit-code');

    },

    /**
     * sendCode
     *
     * Post activation code.
     */
    sendCode() {

        this.activationCode = this.codeInput.getInput()

        if (!this.activationCode) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Enter an activition code.')
            )
            return
        }

        if (this.activationCode.length !== this.CODE_LENGTH) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('An activation code consists of 10 characters.')
            )
            return
        }

        this.orderBtn.disable(true)
        this.codeInput.disable()

        $.post({
            url: '/products/use_activation_code.json',
            data: {
                productId: this.model.id,
                activationCode: this.activationCode,
                schoolId: this.schoolId,
            },
            success: (response) => { this.processCode(response) }
        })
    },

    /**
     * processCode
     *
     * After code has been submitted, show verification on the validity of the
     * activation code to the user.
     *
     * @param {Object} response     AJAX response object
     */
    processCode(response) {

        if (response.status === 'success') {

            Backbone.View.layout.openAlert(
                window.i18n.gettext('Your code has been ratified!'),
                window.i18n.gettext('You\'re now granted immediate access to the learning materials.'),
                () => {
                    // When on /licences/renewal path, we don't know which activity the user came from,
                    // so we redirect them to /users/home.
                    if (window.app.controller.activePath.includes('activities')) {
                        window.location.reload()
                        return
                    }

                    Backbone.history.navigate('/users/home', {trigger: true})
                },
                window.i18n.gettext('Continue to learning materials')
            );
        } else if (response.status === 'error') {
            var errorText = ''

            switch (response.error) {
                case 33001:
                    errorText = window.i18n.gettext('This activation code is not valid. An activation code consists of 10 characters.')
                    break;
                case 33002:
                    errorText = window.i18n.gettext('This activation code has already been used.')
                    break
                case 33003:
                    errorText = window.i18n.gettext('This activation code is intended for another course program.')
                    break
                default:
                    errorText = window.i18n.gettext('Something went wrong:' + response.error)
            }
            this.showSupportOptions(window.i18n.gettext('Incorrect activation code'), errorText)
        }

        this.orderBtn.enable()
        this.codeInput.enable()
    },

    onClickSupportOptions() {
        this.showSupportOptions(
            window.i18n.gettext('Do you have questions about your activation code?'),
            ContactOptionsTemplate()
        )
    },

    /**
     * showSupportOptions - show modal with various contact options of vendors
     *
     * @param  {String} errorTitle title for the modal
     * @param  {String} errorText text describing the error, can be emtpy
     */
    showSupportOptions(errorTitle, errorText) {

        Backbone.View.layout.openAlert(
            errorTitle,
            errorText,
            () => {},
            window.i18n.gettext('Close')
        );

        const data = {
            activationCode: this.activationCode
        }

        const supportLink = Backbone.View.layout.alertMessage.el.querySelector('.js-support-modal')

        if (supportLink) {
            supportLink.addEventListener('click', () => {
                Backbone.View.layout.alertMessage.onClickYes()
                Backbone.View.layout.showSupport(data, 'problem')
            }, {
                once: true
            })

        }

    }

});
