import Styles from './QuickSelect.scss';

import Template from './QuickSelect.hbs';
export default class QuickSelect extends BaseView {

    get items() {
        return this._items || []
    }

    set items(items) {
        if (Array.isArray(items)) {
            this._items = items

            // Remove any select options already present
            this.selectElement.innerHTML = ''

            // Add a disabled option in front of the other option if
            // a placeholder has been defined.
            if (this.placeholder) {
                const placeHolderOption = new Option(this.placeholder)
                placeHolderOption.disabled = true
                this.selectElement.add(placeHolderOption)
            }

            // Populate <select> with <option> elements.
            for (let index = 0; index < items.length; index++) {
                const item = items[index]
                const option = new Option(
                    item.label ?? item,
                    index
                )
                option.disabled = item.disabled === true
                this.selectElement.add(option)
            }
        }
    }

    get selectedIndex() {
        return this.selectElement.selectedIndex
    }

    set selectedIndex(index) {
        // Check if there is a default value index. If yes, select the value at this index.
        if (!isNaN(index) && index !== -1) {
            // Set the selected item index
            this.selectElement.selectedIndex = index + !!this.placeholder
        } else if (/Safari|Edge|IE/.test(window.uaparser.getBrowser().name)) {
            // Since Safari, Edge and IE default to auto-selecting the first selectable item instead of having no
            // selected option (selectedIndex = -1), make sure the first option is selected instead,
            // regardless of this option being selectable or not.
            this.selectElement.selectedIndex = 0
        } else {
            this.selectElement.selectedIndex = -1
        }
    }

    set disabled(value) {
        this.selectElement.disabled = value
        this.el.querySelector('[data-quick-select-container]').classList.toggle(Styles['container--disabled'], value)
    }

    get disabled() {
        return this.selectElement.disabled
    }

    /**
     * QuickSelect
     *
     * Fancy select form element component.
     *
     * @param {Object} options
     * Options object
     *
     * @param {Array} [options.items]
     * Array of objects defining the options in the list. Example: [
     *   {label: window.i18n.gettext('Example', value: 'example')}
     * ]
     *
     * @param {Number|undefined} [options.defaultValueIndex]
     * If defined, sets which item is selected by default.
     *
     * @param {Function} [options.callback]
     * Function to call when selected option has changed.
     *
     * @param {String|undefined} [options.label]
     * String to show above the select form element.
     *
     * @param {String|undefined} [options.placeholder]
     * Optional string to use as the first option in the list.
     *
     * @param {String|undefined} [options.theme]
     * Apply custom styling.
     *
     * @param {boolean} [options.disabled]
     * Sets selector to a disabled state if true.
     */
    initialize({
        items = [],
        defaultValueIndex,
        callback = function() {},
        label,
        placeholder,
        theme,
        disabled = false,
    }) {

        if (!placeholder && (defaultValueIndex === undefined || defaultValueIndex === -1)) {
            placeholder = window.i18n.gettext('None')
        }
        this.placeholder = placeholder

        // Bind this to the following functions
        _.bindAll(this,
            'getValue',
            'onChangeSelectField',
            'selectItemByGlobalIndex'
        );

        // Build the dropdown using Template
        this.setElement(Template({

            cid: this.cid,

            // Pass the styling to the template
            Styles,

            // Pass the label to the template
            label,

            // Pass the label to the template
            placeholder,

            // Pass the theme to the template
            theme: Styles[`quick-select--theme-${theme}`]
        }));

        this.selectElement = this.el.querySelector('select');

        this.disabled = disabled

        // Set this.items, which will populate the select element with options based on this list.
        this.items = items

        // Make callback accessible within method
        this.callback = callback

        // Listen for changes on the select field
        this.selectElement.addEventListener('change', this.onChangeSelectField);

        // If value of defaultValueIndex is a number largen than -1, set select option of that index as selected.
        this.selectedIndex = defaultValueIndex

        // Call the select item by global index function
        this.selectItemByGlobalIndex();

    }

    /**
     * onChangeSelectField
     *
     * This function will be called when the select field has
     * been changed. It will set the index and item to the chosen
     * option. It will also update the label with the chosen one
     */
    onChangeSelectField() {

        // Set the selected to the global index
        this.selectItemByGlobalIndex();

        // Check if there is a callback set
        if (this.callback) {

            // Send the selected item object with it
            this.callback(this.selectedItem, this)
        }
    }

    /**
     * selectItemByGlobalIndex
     *
     * This function wil select the right item and handle internal logic for reading value
     * for the index which is set into this.selectedItemIndex.
     *
     */
    selectItemByGlobalIndex() {

        // Get the selected item using the selectedItemIndex defined above
        if (this.placeholder && this.selectedIndex <= 0) {
            delete this.selectedItem;
            this.$('.js-current-label').text(this.placeholder)
        } else if (this.selectedIndex !== -1) {
            // Get selecteditem by dropdown item index minus the placeholder entry if present.
            this.selectedItem = this.items[this.selectedIndex - !!this.placeholder];

            if (typeof this.selectedItem === 'string') {

                // Update the label text using the chosen item, which is a string
                this.$('.js-current-label').text(this.selectedItem);

            } else {

                // When the selected item is not a string use the label property
                this.$('.js-current-label').text(this.selectedItem.label);
            }
        }

    }

    getIndexByItemValue(value) {
        return this.items.findIndex((item) => {
            return item === value || item.value === value
        })
    }

    setValueByItemValue(value, silent) {
        this.setValue(this.getIndexByItemValue(value), silent)
    }

    /**
     * setValue
     *
     * @param {Number} index        Option index to be selected
     * @param {Boolean} silent      Do not trigger the callback if true
     */
    setValue(index, silent) {

        this.selectedIndex = index;

        this.selectItemByGlobalIndex();

        if (!silent && this.callback) {
            this.callback(this.selectedItem, this)
        }

    }

    /**
     * getValue
     *
     * This function can be used as a getter. It will return the
     * chosen value.
     *
     * @return {Object}  Object defining chosen item and chosen index
     */
    getValue() {

        // Return an object defining chosen item and chosen index
        return {

            // Add the index to it
            index: this.selectedIndex,

            // Add the item to it
            item: this.selectedItem
        };
    }
}
