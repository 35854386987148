import Template from './Subscript.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Subscript extends Button {

    // Custom command
    command = 'ToggleSubscript'

    // Activate button state if <sub> tag is in editor selection/cursor.
    commandQueryName = 'Subscript'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Subscript');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        editor.addCommand(this.command, () => {
            // Request the state of the superscript form the editor, if true, toggle it off.
            if (editor.queryCommandState('Superscript')) {
                editor.execCommand('Superscript')
            }
            editor.execCommand('Subscript')
        })
    }

}
