import Styles from './TabButton.scss';

import Template from './TabButton.hbs';
export default BaseView.extend({

    initialize(options) {

        // Create the element, passing the styling with it.
        this.setElement(Template({
            Styles,
            label: options.label,
            type: options.type
        }));

        this.setBadge(options.badge);

    },

    /**
         * Determines if tab active.
         *
         * @return {boolean} True if tab active, False otherwise.
         */
    isTabActive() {
        return this.$el.hasClass(Styles['tab--active']);
    },

    /**
         * Sets the tab inactive.
         */
    setTabInactive() {
        this.$el.removeClass(Styles['tab--active']);
    },

    /**
         * Sets the tab active and clear badge.
         */
    setTabActive() {
        this.$el.addClass(Styles['tab--active']);
        this.setBadge('');
    },

    /**
         * setBadge
         *
         * Set badge content.
         *
         * @param {string} s   content
         */
    setBadge(s) {
        this.$('.js-badge').text(s || '');
    },

    setLabel(label = '') {
        this.$('.js-label').text(label)
    }

});
