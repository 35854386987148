var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lost-password-modal") : stack1), depth0))
    + " modal-margin\">\n\n    <div class=\"js-forgot-form\">\n        <p>"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Enter your email address or username",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":56}}}))
    + "</p>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"forgot-input") : stack1), depth0))
    + " js-forgot-input\"></div>\n        <div class=\"js-forgot-submit "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"forgot-submit") : stack1), depth0))
    + "\"></div>\n    </div>\n\n"
    + ((stack1 = container.invokePartial(require("../LostPasswordHelp.hbs"),depth0,{"name":"../LostPasswordHelp.hbs","hash":{"Styles":(depth0 != null ? lookupProperty(depth0,"Styles") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n</div>\n";
},"usePartial":true,"useData":true});