import DropdownItem from 'views/components/heroButton/heroDropdown/dropdownItem/DropdownItem'
import HeroButton from 'views/components/heroButton/HeroButton'

export default class HeroDropdown extends HeroButton {

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options as defined by parent view
     */
    initialize(options) {

        _.bindAll(
            this,
            'onClickDocument',
            'renderDropdownItem',
            'toggleDropdownMenu'
        );

        this.isDropdownButton = _.size(options.dropdownItems);

        super.initialize.call(this, options)

        if (this.isDropdownButton) {
            this.isOpen = false;
            this.callback = this.toggleDropdownMenu;
            _.each(options.dropdownItems, this.renderDropdownItem);

            // Since callback isn't dynamically updated, we need to
            // reattach the events to update the callback method.
            this.delegateEvents();
        }
    }

    renderDropdownItem(itemOptions) {
        this.addChildView(
            new DropdownItem(itemOptions),
            '.js-dropdown-menu'
        );
    }

    /**
     * onClickDocument
     *
     * This method will be called when the 'document' receives a click event.
     * It will check if the clicked target was made on this view or a child
     * of this view. If not it will close the dropdown menu.
     *
     * @param  {Object} e   Event object
     */
    onClickDocument(e) {

        // Check if target of click isn't the herobutton nor a descendant of the herobutton
        if (!this.$el.is(e.target) && this.$el.has(e.target).length === 0) {
            this.isOpen = false;
            this.closeDropdownMenu();
        }
    }

    toggleDropdownMenu() {
        if (this.isOpen) {
            this.closeDropdownMenu();
            $(document).off('click', this.onClickDocument);
        } else {
            this.openDropdownMenu();
            $(document).on('click', this.onClickDocument);
        }

        this.isOpen = !this.isOpen;
    }

    openDropdownMenu() {

        const dropdownMenu = this.$('.js-dropdown-menu');
        TweenMax.to(
            dropdownMenu,
            {
                height: dropdownMenu.outerHeight(),
                width: dropdownMenu.outerWidth(),
                display: 'flex',
                duration: 0.5,
                ease: 'power1.out',
                onComplete: () => {
                    dropdownMenu.css('height', '')
                    dropdownMenu.css('width', '')
                }
            }
        )

    }

    closeDropdownMenu() {
        this.el.querySelector('.js-dropdown-menu').style.display = ''
    }

    /**
     * onDestroy
     *
     * This method will be called when this view is destroyed. It will clean
     * up the event listeners that where set during the use of this component
     *
     */
    onDestroy() {
        $(document).off('click', this.onClickDocument);
    }

}
