import Template from './NoResponseStudentsList.hbs';
import Button from 'views/components/button/Button'

export default class NoResponseStudentsList extends BaseView {

    initialize({
        studentModels
    }) {
        this.setElement(Template({
            label: window.i18n.sprintf(window.i18n.ngettext(
                '%s student has not yet given an answer',
                '%s students have not yet given an answer',
                studentModels.length
            ), studentModels.length),
            students: studentModels.map(student => student.last_name_first_name())
        }))
        this.$('.js-name-list').hide()

        let isReaveled = false
        const revealButton = this.addChildView(new Button({
            label: NoResponseStudentsList.getRevealNamesLabel(studentModels, isReaveled),
            icon: 'eye',
            theme: 'transparent',
            callback: () => {
                isReaveled = !isReaveled
                revealButton.label = NoResponseStudentsList.getRevealNamesLabel(studentModels, isReaveled)
                revealButton.icon = isReaveled ? 'eye-off' : 'eye'
                this.$('.js-name-list').toggle(isReaveled)
            }
        }), '.js-header')
    }

    static getRevealNamesLabel(studentModels, isReaveled) {
        return isReaveled ?
            window.i18n.ngettext('Hide name', 'Hide names', studentModels.length) :
            window.i18n.ngettext('Show name', 'Show names', studentModels.length)
    }
}
