import Template from './Simplify.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

// Take contents of editor and simplify the language of the content with Vertex AI.
export default class Simplify extends Button {

    // Custom command
    command = 'simplify'

    initializeButton() {
        this.icon = Template()
        this.tooltip = window.i18n.gettext('Simplify text')
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        editor.addCommand(this.command, () => {
            const content = editor.getContent()
            if (content.length < 50 || content.length > 5000) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Not enough or too much text to simplify'),
                    'warning'
                )
                return
            }

            Backbone.View.layout.openStatus(
                window.i18n.gettext('Text is being simplified, please wait'),
                'loading', { noHide: true }
            )

            // Extract the activity id from the url, we need it to find the group with corresponding grade and level
            const match = window.location.pathname.match(/task_groups\/author\/(\d*)/);
            if (!match || match.length !== 2) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Something went wrong when simplifying the text'),
                    'error'
                )
                return
            }

            // Get the activity id from the match array and parse to an integer
            const activityId = parseInt(match[1])

            $.post({
                url: '/sources/simplify',
                data: {
                    text: editor.getContent(),
                    activityId
                },
                success: (response) => {
                    if (response.status === 'success') {
                        editor.setContent(response.text)
                        Backbone.View.layout.openStatus(
                            window.i18n.gettext('Text has been simplified and updated in the editor'),
                            'success'
                        )
                        return
                    }

                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('Something went wrong when simplifying the text'),
                        'error'
                    )
                }
            })
        });
    }
}
