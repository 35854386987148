import Styles from './SideNavigation.scss';

import Template from './SideNavigation.hbs';
import SideNavigationItem from 'views/pages/activities/show/types/linear/PresentationMode/SideNavigation/NavigationItem/SideNavigationItem'
import SideNavigationItemStyles from 'views/pages/activities/show/types/linear/PresentationMode/SideNavigation/NavigationItem/SideNavigationItem.scss'

export default class SideNavigation extends BaseView {

    get events() {
        return {
            'click .js-navigation-arrow-top': 'arrowUp',
            'click .js-navigation-arrow-bottom': 'arrowDown',
        }
    }

    initialize({ presentationMode }) {

        _.bindAll(
            this,
            'showActiveElement'
        )

        this.setElement(Template({
            Styles,
        }));

        this.presentationMode = presentationMode;

        for (const taskGroupModel of this.collection) {
            this.addTaskGroupButton(taskGroupModel)
        }

        let highestSubTaskAmount = 0;
        for (const taskGroupModel of this.collection) {
            if (taskGroupModel.elements.length > highestSubTaskAmount) {
                highestSubTaskAmount = taskGroupModel.elements.length
            }
        }
        _.defer(() => {
            const navBarLength = this.el.querySelector('.js-items').scrollHeight + (highestSubTaskAmount * 42)
            if (navBarLength > this.el.querySelector('.js-navbar').clientHeight) {
                this.showArrowButtons()
            }
        })
    }

    addTaskGroupButton(taskGroupModel) {
        this.addChildView(new SideNavigationItem({
            model: taskGroupModel,
            presentationMode: this.presentationMode,
        }), '.js-items')
    }

    showActiveElement(elementModel) {
        for (const navigationItemEl of this.el.querySelectorAll('.js-task-group-button,.js-element-button')) {
            navigationItemEl.classList.remove(SideNavigationItemStyles['nav-item--active'])
        }
        const activeTaskGroupNavigationItemEl = this.el.querySelector(
            `.js-task-group-button[data-task-group-id="${elementModel.get('task_group_id')}"]`
        )
        activeTaskGroupNavigationItemEl.classList.add(SideNavigationItemStyles['nav-item--active'])
        const activeElementNavigationItemEl = activeTaskGroupNavigationItemEl.nextElementSibling.querySelector(
            `.js-element-button[data-element-id="${elementModel.get('element_type')}${elementModel.id}"]`
        )
        if (activeElementNavigationItemEl) {
            activeElementNavigationItemEl.classList.add(SideNavigationItemStyles['nav-item--active'])
        }

        requestAnimationFrame(() => {
            activeTaskGroupNavigationItemEl.parentElement.scrollIntoView({
                behavior: 'instant',
                block: 'nearest',
            })
        })
    }

    showArrowButtons() {
        this.$('.js-navigation-arrow').css('display', 'flex');
    }

    arrowUp() {
        const navigationBar = this.el.querySelector('.js-items');
        const moveDistance = -0.5 * navigationBar.offsetHeight;
        navigationBar.scrollTo({
            top: navigationBar.scrollTop + moveDistance,
            behavior: 'smooth'
        })
    }

    arrowDown() {
        const navigationBar = this.el.querySelector('.js-items');
        const moveDistance = 0.5 * navigationBar.offsetHeight;
        navigationBar.scrollTo({
            top: navigationBar.scrollTop + moveDistance,
            behavior: 'smooth'
        })
    }
}
