export default class CompetencyComponentModel extends Backbone.Model {

    get defaults() {
        return {
            weight: 1,
        }
    }

    get competencyId() {
        return this.collection.competencyId
    }

    url() {
        if (this.id) {
            return `/competencies/component/${this.competencyId}/${this.id}`
        }
        return `/competencies/component/${this.competencyId}`
    }
}
