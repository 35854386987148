var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"not-found") : stack1), depth0))
    + "\">\n    "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias2,"warning",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":25}}})) != null ? stack1 : "")
    + " "
    + alias1(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias2,"No audio found for this source.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":66}}}))
    + "\n</div>";
},"useData":true});