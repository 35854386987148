var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"card") : stack1), depth0))
    + "\">\n\n    <header>\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"support",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":29}}})) != null ? stack1 : "")
    + "\n        <div class=\"header-content\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"news-title") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</div>\n            <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"date") : depth0), depth0))
    + "</p>\n        </div>\n        <button class=\"js-hide "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"hide") : stack1), depth0))
    + "\" title=\""
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Hide",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":9,"column":55},"end":{"line":9,"column":68}}}))
    + "\">"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":9,"column":70},"end":{"line":9,"column":89}}})) != null ? stack1 : "")
    + "</button>\n    </header>\n\n    <article>\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\n    </article>\n\n    <footer>\n        <button>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"buttonText") : depth0), depth0))
    + "</button>\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-right",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":33}}})) != null ? stack1 : "")
    + "\n    </footer>\n\n</div>";
},"useData":true});