import Styles from './Flashcard.scss';

import Template from './Flashcard.hbs';
import SharedStyles from 'views/pages/words/questionCard/QuestionCard.scss'
import TextToSpeech from 'views/pages/words/textToSpeech/TextToSpeech'

export default class Flashcard extends BaseView {

    get events() {
        return {
            'click .js-card': 'changeSide'
        }
    }

    initialize({
        parent,
        pairCardText,
    }) {

        _.bindAll(
            this,
            'changeSide',
            'onKeyUp'
        )

        this.parent = parent

        const { pairId, questionLabel, questionValue, answerLabel, answerValue } = pairCardText

        this.setElement(Template({
            Styles,
            SharedStyles,
            questionLabel,
            questionValue,
            answerLabel,
            answerValue
        }));

        // If the question is supported by text to speech, add a button
        const direction = this.parent.sessionModel.get('learn_direction')
        const questionToSpeechLanguage = this.parent.getTextToSpeechLanguage(questionLabel)
        if (questionToSpeechLanguage && questionValue.length < 150) {
            this.questionSpeechButton = this.addChildView(new TextToSpeech({
                pairId,
                value: direction === 'left_right' ? 'left' : 'right',
                lang: questionToSpeechLanguage
            }), this.$('.js-question'))

            // Add button to header to enable auto speech
            this.parent.addAutoSpeechButton()

            // If auto speech is enabled, read the question
            this.listenTo(this.parent.sessionModel, 'change:autoSpeech', this.playAutoSpeech)
            this.playAutoSpeech()
        }

        // If the answer is supported by text to speech, add a button
        const answerToSpeechLanguage = this.parent.getTextToSpeechLanguage(answerLabel)
        if (answerToSpeechLanguage && answerValue.length < 150) {
            this.answerSpeechButton = this.addChildView(new TextToSpeech({
                pairId,
                value: direction === 'left_right' ? 'right' : 'left',
                lang: answerToSpeechLanguage
            }), this.$('.js-answer'))
            this.answerSpeechButton.el.setAttribute('disabled', true)

            // Add button to header to enable auto speech
            this.parent.addAutoSpeechButton()
            this.listenTo(this.parent.sessionModel, 'change:autoSpeech', this.playAutoSpeech)
        }

        this.parent.setInstructionText(window.i18n.gettext('Press space or click on the card to turn'))

        document.addEventListener('keyup', this.onKeyUp)

        if (this.parent.index > 0) {
            this.previousButton = this.el.querySelector('.js-previous-card')
            this.previousButton.disabled = false
            this.previousButton.addEventListener('click', () => { this.parent.showPreviousCard() }, { once: true})
        }
    }

    changeSide() {
        this.$('.js-card').toggleClass(Styles['card__rotated'])
        this.parent.setResponse(true)
        this.parent.setInstructionText(window.i18n.gettext('Press enter to continue'))

        if (!this.nextButton) {
            this.nextButton = this.el.querySelector('.js-next-card')
            this.nextButton.disabled = false
            this.nextButton.addEventListener('click', () => this.parent.moveToNextCard(true), { once: true})
        }

        this.playAutoSpeech()
    }

    playAutoSpeech() {

        // Disable speech button on the non visible side of the card (if present)
        const answerSideVisible = this.el.querySelector('.js-card').classList.contains(Styles['card__rotated'])
        if (this.answerSpeechButton) {
            this.answerSpeechButton.el.disabled = !answerSideVisible

            // If auto speech is enabled, read the answer
            if (answerSideVisible && this.parent.sessionModel.get('autoSpeech')) {
                this.answerSpeechButton.el.click()
            }
        }
        if (this.questionSpeechButton) {
            this.questionSpeechButton.el.disabled = answerSideVisible

            // If auto speech is enabled, read the question
            if (!answerSideVisible && this.parent.sessionModel.get('autoSpeech')) {
                this.questionSpeechButton.el.click()
            }
        }
    }

    onKeyUp(e) {
        const key = e.keyCode || e.key

        if (key === 32 || key === ' ') {
            this.changeSide()
        } else if (this.nextButton &&
            (key === 13 || key === 'Enter' || key === 39 || key === 'ArrowRight')
        ) {
            this.parent.moveToNextCard(true)
        } else if (key === 37 || key === 'ArrowLeft') {
            this.parent.showPreviousCard()
        }
    }

    onDestroy() {
        document.removeEventListener('keyup', this.onKeyUp)
    }

}
