import ElementsCollection from 'collections/ElementsCollection'
import TaskModel from 'models/TaskModel'
export default class TasksCollection extends ElementsCollection {

    preinitialize() {
        this.constructor.type = 'tasks'
    }

    get model() {
        return TaskModel
    }

    modelId(attr) {
        return attr.id;
    }

    /**
     * applyTaskLabelSorting
     *
     * Sort tasks based on the sequence of the task group in the activity and their sequence
     * in that task group.
     *
     * @param  {Backbone.Model} activityModel - activity model related to the tasks collection to be sorted
     */
    applyTaskLabelSorting(activityModel) {

        // The tasks are normally sorted on sequence inside their task group
        // Loop through the tasks and set task label so we can sort the tasks linearly
        this.each(task => {

            // Set the task label per task (such as: 2A or 10B)
            // Do this by finding the related task group model in the activity model
            // and select its task label.
            const taskLabel = activityModel.task_groups.get((task.get('task_group_id'))).getTaskLabel(task.id)
            task.set('taskLabel', taskLabel);
        })

        // Make sure double digit numbers are not sorted before single digits
        // such as:
        // 1A
        // 2A
        // 2B
        // 10A
        this.comparator = function(task) {
            return [task.get('taskLabel').length, task.get('taskLabel')];
        };
        this.sort();

    }

    getTasksOfType(templateId) {
        return this.filter(task => task.get('template_id') === templateId)
    }

}
