var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "transform:translateX(50%);";
},"3":function(container,depth0,helpers,partials,data) {
    return "width:auto";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"key") : stack1), depth0))
    + " js-key\" style=\"grid-column-start:"
    + alias1(((helper = (helper = lookupProperty(helpers,"columnStart") || (depth0 != null ? lookupProperty(depth0,"columnStart") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"columnStart","hash":{},"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":78}}}) : helper)))
    + ";grid-column-end:"
    + alias1(((helper = (helper = lookupProperty(helpers,"columnEnd") || (depth0 != null ? lookupProperty(depth0,"columnEnd") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"columnEnd","hash":{},"data":data,"loc":{"start":{"line":1,"column":95},"end":{"line":1,"column":108}}}) : helper)))
    + ";grid-row:"
    + alias1(((helper = (helper = lookupProperty(helpers,"row") || (depth0 != null ? lookupProperty(depth0,"row") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"row","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":125}}}) : helper)))
    + ";"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"isColumnOffset") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":126},"end":{"line":1,"column":181}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"hasCustomWidth") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":181},"end":{"line":1,"column":220}}})) != null ? stack1 : "")
    + "\" data-cmd=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"cmd") || (depth0 != null ? lookupProperty(depth0,"cmd") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"cmd","hash":{},"data":data,"loc":{"start":{"line":1,"column":232},"end":{"line":1,"column":241}}}) : helper))) != null ? stack1 : "")
    + "\" data-key=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":1,"column":253},"end":{"line":1,"column":260}}}) : helper)))
    + "\" data-shift-cmd=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"shiftCmd") || (depth0 != null ? lookupProperty(depth0,"shiftCmd") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"shiftCmd","hash":{},"data":data,"loc":{"start":{"line":1,"column":278},"end":{"line":1,"column":290}}}) : helper)))
    + "\" data-shift-key=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"shiftKey") || (depth0 != null ? lookupProperty(depth0,"shiftKey") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"shiftKey","hash":{},"data":data,"loc":{"start":{"line":1,"column":308},"end":{"line":1,"column":320}}}) : helper)))
    + "\" data-s=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"s") || (depth0 != null ? lookupProperty(depth0,"s") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"s","hash":{},"data":data,"loc":{"start":{"line":1,"column":330},"end":{"line":1,"column":335}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":1,"column":337},"end":{"line":1,"column":346}}}) : helper))) != null ? stack1 : "")
    + "</button>\n";
},"useData":true});