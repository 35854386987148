var Routes = {
    GENERIC: 'generic',
    GENERIC_INDEX: 'generic_index'
};

export default Backbone.Router.extend({

    routes: {
        ':controller/:action/*params': Routes.GENERIC,
        ':controller/:action(/?)': Routes.GENERIC,
        ':controller(/?)': Routes.GENERIC_INDEX,
        '(/?)': Routes.GENERIC_INDEX
    }

}, {
    Routes
});
