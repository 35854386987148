module.exports = function(item) {
    // Remove first item from arguments (is: item), and the last (containing the options)
    // Use array.prototype.slice.call as a hack since we can't use slice on arguments. This
    // will create a valid copy of the arguments.
    // See: http://stackoverflow.com/a/9510206
    var list = Array.prototype.slice.call(arguments, 1).slice(0, -1);

    // Get options same way as list
    var options = Array.prototype.pop.call(arguments);

    // Add support for arrays in list. So we can do:
    // {{#isInList item [array of values] moreItem1}}
    list = _.flatten(list);

    // Return if the argument is in list
    return (list.indexOf(item) !== -1) ? options.fn(this) : options.inverse(this);
};
