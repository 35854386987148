import ResponseModel from 'models/ResponseModel';
import Util from 'util/util';

export default Backbone.Collection.extend({
    model: ResponseModel,

    /**
     * Add response model to a response buffer, which is a ResponsesCollection which allows to only store one
     * revision for each response instance and mirrors what it stores into local storage.
     *
     * When a student generates a response, it should always go through this system to ensure the responses are
     * saved into a client-side memory and storage before attempting to sync to the server.
     *
     * @param {ResponseModel} model
     * A new instance of ResponseModel or a revised of an existing ResponseModel instance.
     */
    addToBuffer(model) {

        // Store the unique identifier in a constant so that it can be removed later on
        const oldUniqIdentifier = model.get('uniqid')

        // Generate a new unique identifier for most recent response.
        model.setUniqIdentifier()

        // Add most recent response to both local storage and this collection so it can be synced later.
        if (Util.hasSupportForLocalstorage()) {
            try {
                window.localStorage.setItem(`response-${model.get('uniqid')}`, JSON.stringify(model.attributes))
                // Set flag indicating save status to local, which will change when it will be successfully
                // processed by the backend.
                model.set({
                    saved_state: 'local'
                })
            } catch (err) {
                window.sentry.withScope(scope => {
                    scope.setExtra('errorMessage', err)
                    scope.setExtra('responseData', model.attributes)
                    window.sentry.captureException('Can\'t save response to local storage')
                })
                model.set({
                    saved_state: 'unsaved'
                })
            }
        } else {
            // Set flag indicating response only exists in memory since it cannot be saved in local storage and
            // is not yet been processed successfully by the server.
            model.set({
                saved_state: 'unsaved'
            })
        }
        this.push(model)

        // Try to remove existing item for previous revision of response from local storage.
        this.removeResponseFromLocalStorage(oldUniqIdentifier)

    },

    /**
     * Whenever an instance of ResponseModel has been successfully processed by the server, or if the response
     * contains invalid data the server cannot process, remove said model from the buffer in both memory and
     * storage. This way the system can not attempt to sync the same data again.
     *
     * @param {ResponseModel} model
     * An existing instance of ResponseModel to be removed from the buffer.
     */
    removeFromBuffer(model) {

        // Try to remove existing item for previous revision of response from local storage.
        this.removeResponseFromLocalStorage(model.get('uniqid'))

        // Remove revision model.
        this.remove(model.id)
    },

    // If a response with the given unique identifier is present in local storage, remove it
    removeResponseFromLocalStorage(uniqid) {
        if (Util.hasSupportForLocalstorage()) {
            try {
                window.localStorage.removeItem('response-' + uniqid)
            } catch (err) {
                window.sentry.withScope(scope => {
                    scope.setExtra('errorMessage', err)
                    window.sentry.captureException('Can\'t remove response from local storage')
                })
            }
        }
    },

    // For a specific activity, get all responses that are not synced to the server yet
    // Use this when you want to be sure all answers are saved (e.g. handing in an exam)
    getUnsyncedActivityResponses(activityId) {
        return this.filter((response) => {
            return response.get('activity_id') === activityId
        })
    },

    // Get the oldest response that belongs to the current user and sync it
    syncResponseToServer() {
        const firstResponseModel = this.findWhere({
            user_id: Backbone.Model.user.id
        })
        if (firstResponseModel) {
            if (firstResponseModel.activeRequest === undefined) {
                firstResponseModel.syncFromLocalStorage()
            }
        } else {
            this.trigger('allResponsesSynced');
        }
    }

}, {
    type: 'responses'
});
