import Styles from './FileBlock.scss';

import Template from './FileBlock.hbs';
import Util from 'util/util';
import fileIconsTemplate from '../partials/FileIcons.hbs';
import shapeList from 'util/ShapeLoader';
import Spinner from 'views/components/spinner/Spinner';
import ToolTip from 'views/components/toolTip/ToolTip';

export default class FileBlock extends BaseView {

    get events() {
        return {
            click: 'onClickFileBlock',
            'click .js-remove-file': 'onClickRemoveFile'
        };
    }

    /**
     *
     *
     * @param {String} identifier to request this fileblock with (must be unique)
     * @param {Object} {
     *          name {String} initial name of the file
     *          size {Number} size of the file
     *          icon {String} icon for this file (Google or Microsoft)
     *          url {String} url to get this file with
     *          fileId {String} id of the file
     *          progress {Number} in percentages of the initial progress
     *          error {String} Initial error state
     * }
     * @memberof FileBlock
     */
    initialize(identifier, { name, size, icon, url, fileId, progress, error }) {

        _.bindAll(
            this,
            'setProgress'
        );

        this.setElement(Template({ Styles, urlIdentifier: identifier }));

        this.identifier = identifier;
        this.name = name;
        this.size = size;
        this.icon = icon;
        this.url = url;
        this.error = error;
        this.fileId = fileId;
        this.progress = progress;
    }

    /**
     * onClickRemoveFile
     *
     * Callback function for when the user clicks on the cross icon
     *
     * @param {Object} e event object
     * @memberof FileBlock
     */
    onClickRemoveFile(e) {
        e.stopPropagation()
        this.trigger('remove');
    }

    /**
     * onClickFileBlock
     *
     * Callback function for when the user clicks on the whole fileBlock
     *
     * @memberof FileBlock
     */
    onClickFileBlock() {
        if (!this.error && this.url) {
            window.open(this.url, '_blank')
        }
    }

    /**
     * hideRemoveButton
     *
     * Hides the remove button to disable removal for the user
     *
     * @memberof FileBlock
     */
    hideRemoveButton() {
        this.$('.js-remove-file').hide();
    }

    /**
     * showRemoveButton
     *
     * Shows the remove button to enable removal for the user
     *
     * @memberof FileBlock
     */
    showRemoveButton() {
        this.$('.js-remove-file').show();
    }

    /**
     * completeUpload
     *
     * Function to render the fileblock as complete. It'll show a checkmark
     * to the user and tell them upload is complete
     *
     * @param {Function} callback function to call after animations
     * @memberof FileBlock
     */
    completeUpload(callback) {
        this.el.querySelector('.js-upload-percentage').style.display = 'none';

        const container = this.el.querySelector('.js-upload-finished');
        const icon = this.el.querySelector('.js-upload-finished__icon');
        const label = this.el.querySelector('.js-upload-finished__label');

        container.style.display = 'flex';

        TweenMax.set(icon, {
            opacity: 0,
            scale: 0.8,
            y: -10
        })

        TweenMax.set(label, {
            opacity: 0,
            scale: 0.8,
            y: -10
        })

        TweenMax.to(icon, {
            duration: 0.3,
            opacity: 1,
            scale: 1,
            y: 0,
            ease: 'expo.inOut'
        })

        TweenMax.to(label, {
            duration: 0.3,
            opacity: 1,
            scale: 1,
            y: 0,
            delay: 0.35,
            ease: 'expo.inOut'
        })

        /**
         * Add timeout so the user has some time to see the upload completed message
         */
        setTimeout(() => {

            TweenMax.to(icon, {
                duration: 0.3,
                opacity: 0,
                scale: 0.8,
                y: -10,
                ease: 'expo.inOut'
            });

            TweenMax.to(label, {
                duration: 0.3,
                opacity: 0,
                scale: 0.8,
                y: -10,
                delay: 0.35,
                ease: 'expo.inOut',
                onComplete: () => {

                    container.style.opacity = 0;

                    // hide upload finished container
                    this.el.querySelector('.js-upload-finished').style.display = 'none'

                    // render icon
                    _.defer(callback);

                }
            });

        }, 1500);
    }

    /**
     * removeSpinner
     *
     * Removes spinner
     *
     * @memberof FileBlock
     */
    removeSpinner() {
        this.destroyChildViewsOfInstance(Spinner);

        if (this.iconHolder) {
            this.iconHolder.css('display', '');
        }
    }

    /**
     * addSpinner
     *
     * Adds a spinner
     *
     * @memberof FileBlock
     */
    addSpinner() {
        if (this.iconHolder) {
            this.iconHolder.css('display', 'none');
        }

        const spinner = this.addChildView(
            new Spinner({}),
            '.js-icon-holder'
        );

        spinner.el.classList.add(Styles['spinner-holder']);
    }

    /**
     * setFileIcon
     *
     * This method will render the file icon of the file.
     *
     * @param {Object} {
    *          icon {String} svg of the icon
    *          imageUrl {String} optional url to render a preview image
     * }
     * @memberof FileBlock
     */
    setFileIcon({ icon, imageUrl }) {
        const iconOptions = {
            Styles,
            icon,
            imageUrl
        }

        this.el.querySelector('.js-upload-percentage').style.display = 'none';
        this.el.querySelector('.js-progress-bar').classList.add(Styles['progress-bar--loaded']);

        if (this.iconHolder) {
            this.iconHolder.remove();
        }

        this.iconHolder = $(fileIconsTemplate(iconOptions));
        this.$('.js-icon-holder').append(this.iconHolder);

        if (this.getChildViewsOfInstance(Spinner).length > 0) {
            this.iconHolder.css('display', 'none');
        }

        const filenameLabel = this.el.querySelector('.js-file-name')
        const removeFileIcon = this.el.querySelector('.js-remove-file')
        const fileSize = this.el.querySelector('.js-file-size')

        TweenMax.set(filenameLabel, { scale: 0.8 })
        TweenMax.set(removeFileIcon, { scale: 0.8 })

        if (this.size) {
            TweenMax.set(fileSize, {
                scale: 0.8,
                ease: 'back.in'
            })
        }

        TweenMax.to(removeFileIcon, {
            duration: 0.15,
            opacity: 1,
            scale: 1,
            ease: 'back.easeInOut'
        })

        TweenMax.to(filenameLabel, {
            duration: 0.15,
            opacity: 1,
            scale: 1,
            ease: 'back.easeInOut',
        })

        if (this.size) {
            TweenMax.to(fileSize, {
                duration: 0.15,
                opacity: 1,
                scale: 1,
                ease: 'back.easeInOut',
                delay: 0.35,
            })
        }
    }

    showSharingFailedMessage() {
        this.warning = {
            title: window.i18n.gettext('Could not share file'),
            text: window.i18n.gettext(
                'We were unable to share the file for you, make sure your teacher can access the file.'
            )
        }
    }

    setProgress(percentage) {

        // Only allow numbers
        if (!isFinite(percentage)) {
            return;
        }

        // Abort if the progress bar is not present anymore
        const progressBar = this.el.querySelector('.js-progress-bar');
        if (!progressBar) {
            return;
        }

        // Round the percentage and make sure it is between 0 and 100
        percentage = Math.round(percentage);
        percentage = Math.max(percentage, 0);
        percentage = Math.min(percentage, 100);

        // Update the progress bar
        const moveToPosition = - 1 * (100 - percentage);
        progressBar.style.transform = `translateX(${moveToPosition}%)`;

        // Update the percentage
        const percentageElement = this.el.querySelector('.js-upload-percentage');
        percentageElement.textContent = percentage + '%'
    }

    /**
     * name
     *
     * Getter and setter of the name property. It will reflect the value
     * directly into the DOM
     *
     * @memberof FileBlock
     */
    get name() { return this._name; }
    set name(name) {

        this._name = name;

        let tempIconStore;

        if (!name) {
            this.$('.js-file-name').text('');
            return
        }

        if (this.icon) {
            tempIconStore = this.icon;
        }

        this.$('.js-file-name').text(decodeURIComponent(name));

        if (tempIconStore) {
            this.icon = tempIconStore;
        }
    }

    /**
     * size
     *
     * Getter and setter of the size property. It will convert the value to
     * bytes and update the filesize in DOM
     *
     * @memberof FileBlock
     */
    get size() { return this._size; }
    set size(size) {
        this._size = size;
        this.$('.js-file-size').data('input-value', size);
        size = size && Util.formatBytes(Math.round(size), 0);
        this.$('.js-file-size').toggleClass(Styles['size--visible'], !!size);
        this.$('.js-file-size').text(size);
    }

    /**
     * error
     *
     * Getter and setter for error propery. It will show the message to the user
     * and make the progressbar red.
     *
     * @memberof FileBlock
     */
    get error() {
        return this._message;
    }
    set error(message) {

        this._message = message;

        if (!message) {
            this.el.classList.remove(Styles['error']);
            return;
        }

        this.setFileIcon({ icon: shapeList['alert'] });
        this.el.classList.add(Styles['error']);

        if (message !== true) {
            this.el.querySelector('.js-error-text').textContent = message;
        }
    }

    get warning() {
        return this._warning;
    }
    set warning(message) {

        this._warning = message;

        if (!message) {
            this.destroyChildViewsOfInstance(ToolTip);
            this.el.querySelector('.js-warning-button').style.display = 'none';
            return;
        }

        const { title, text } = message;

        this.el.querySelector('.js-warning-button').style.display = 'flex';
        this.addChildView(new ToolTip({
            title,
            text,
            parentElement: this.$('.js-warning-button'),
            alignment: 'bottom'
        }), this.$('.js-warning-button'));
    }

    /**
     * icon
     *
     * Getter and setter for icon within the filename. This will be used
     * for extensions
     *
     * @memberof FileBlock
     */
    get icon() {
        return this._icon;
    }
    set icon(value) {

        this._icon = value;

        if (this.$('.js-metadata-container').has('.js-icon-container')) {
            this.$('.js-icon-container').remove();
        }

        if (value) {
            const iconContainer = document.createElement('div');
            // TODO: Check if value exists as SVG in shapelist for extra security
            iconContainer.innerHTML = value;
            iconContainer.classList.add('js-icon-container');
            iconContainer.classList.add(Styles['extension-icon']);
            this.el.querySelector('.js-metadata-container .js-file-name').prepend(iconContainer);
        }
    }

    /**
     * fileId
     *
     * Getter and setter for the fileId. It will update the data attribute
     * in the DOM
     *
     * @memberof FileBlock
     */
    get fileId() {
        return this._fileId;
    }
    set fileId(id) {
        this._fileId = id;
        this.$el.attr('data-uploading-file-id', id);
    }
}
