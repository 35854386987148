// ElementModel is logic shared between TaskModel and SourceModel.
// Warning: don't use ElementModel directly. Use SourceModel or TaskModel instead or by adding
// to an ElementsCollection, SourcesCollection or TasksCollection.

export default class ElementModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'element'
    }

    initialize() {
        if (!this.id) {
            this.initEmptyState();
        }
    }

}
