import Util from 'util/util'

export default class ContentLabelModel extends Backbone.Model {

    initialize(attr, {
        layerModel,
        isCompact = false,
        publishedContentTree,
        displayFirstNameFirst = false,
    }) {

        this._displayFirstNameFirst = displayFirstNameFirst
        this._isCompact = isCompact
        this._publishedContentTree = publishedContentTree

        this.set({layerModel})

        this.setLabel()

    }

    setLabel() {

        const layerModel = this.get('layerModel')
        const type = layerModel.constructor.type
        const isNumberless = ContentLabelModel.isNumberless(layerModel, this._isCompact)

        this.set({
            name: layerModel.get('name'),
            index: null,
        })

        switch (type) {
            case 'group': {

                // for students override group name with course name
                if (Backbone.Model.user.get('is_student')) {
                    this.set({name: layerModel.getCourseModel().get('name')})
                }
                break
            }
            case 'theory':
            case 'chapter': {

                this.set({index: layerModel.get('index')})

                break
            }
            case 'section': {

                const sectionIndex = layerModel.get('index')

                // TODO fix for bieb
                // TODO fix for groupTree__index component in library/add activity
                if (this._publishedContentTree) {

                    // in case of published content, show only the section index
                    // in case the section is the highest level. check the highest
                    // level of the published content tree to see if it contains
                    // activities
                    this.set({
                        index: this._publishedContentTree.hasOwnProperty('activities') ?
                            sectionIndex :
                            `${layerModel?.getChapterModel().get('index')}.${ sectionIndex }`
                    })
                    break
                }

                const parent = layerModel.getParent()

                // only show section index if group has 2 layers
                if (parent?.isRootLayer()) {
                    this.set({index: sectionIndex})
                    break
                }

                this.set({index: `${ parent ? parent.get('index') : '1' }.${ sectionIndex }`})

                break
            }
            case 'activity': {

                if (layerModel.get('type') && layerModel.get('type') === 'adaptive_student') {
                    this.set({name: window.i18n.gettext('Practice by yourself')})
                }

                const activityIndex = ContentLabelModel.getActivityIndex(layerModel, this._isCompact)
                this.set({index: activityIndex})

                break
            }
            case 'taskGroup': {

                this.set({ index: layerModel.get('index') })

                if (!this.get('name')) {
                    this.set({name: layerModel.getShortText()})
                }

                break
            }
            case 'teacher':
            case 'user':
            case 'student': {

                this.set({
                    name: !this._displayFirstNameFirst ?
                        layerModel.last_name_first_name() :
                        layerModel.first_name_last_name()
                })

                break
            }

            case 'element':
            case 'task':
            case 'source': {

                if (type === 'task') {
                    const taskLabel = layerModel.get('taskLabel')
                    this.set({
                        index: this._isCompact || !taskLabel ?
                            layerModel.get('index') :
                            taskLabel
                    })
                }

                const elementName = layerModel.getElementName()
                let safeName = Util.stripTags(layerModel.getShortText())

                if (safeName) {
                    safeName = ` - ${safeName}`
                }

                // example:
                // ${ elementName} - ${ safeName }
                // Tekst - "Gevaren van AI vallen heus wel mee, zegt hyperintelligente computer"

                this.set({name: `${ elementName }${ safeName }`})

                break
            }
        }

        this.set({
            title: !isNumberless && this.get('index') ?
                `${ this.get('index') } ${ this.get('name') }` :
                this.get('name'),
            isNumberless,
            indexForContentLabelComponent: this.get('index'),
        })
        if (isNumberless) {
            this.unset('index')
        }

    }

    static getActivityIndex(layerModel, isCompact) {
        // start with activity index
        let index = layerModel.get('index')

        if (isCompact) {
            return index
        }

        const section = layerModel.getSectionModel()

        if (!section) {
            return index
        }

        const chapter = section.getParent()
        const layers = layerModel.getGroupModel().get('layers')

        // else prepend section index
        if (layers > 1) {
            index = `${ section.get('index')} ${ index }`
        }

        // and prepend chapter index
        if (layers > 2) {
            index = `${ chapter.get('index') }.${ index }`
        }

        return index

    }

    static isNumberless(layerModel, isCompact = true) {
        if (layerModel.get('numberless')) {
            return true
        }
        // For sections, we need to check to chapter as well
        // For activities, we need to check the section if it is also visible
        if (
            layerModel.constructor.type === 'section' ||
            ((layerModel.constructor.type === 'activity') && !isCompact)
        ) {
            const parentModel = layerModel.getParent?.()
            if (parentModel) {
                return ContentLabelModel.isNumberless(parentModel)
            }
        }
        return false
    }
}
