import Styles from './Actionbutton.scss';

import Template from './Actionbutton.hbs';
export default BaseView.extend({

    events: {
        click: 'onClickActionButton'
    },

    iconType: 'next',
    hideTimeout: false,

    initialize(options) {

        _.bindAll(this,
            'show',
            'hide',
            'setLabel',
            'positionButton',
            'onClickActionButton',
            'onFinishedLoading'
        );

        // Make sure the options is never empty, take argument or make plain object
        options = options || {};

        // Create a default callback for click
        this.clickCallback = function() {};

        // Make options accessible within this
        _.extend(this, options);

        // Build element with template vars
        this.setElement(Template({
            Styles
        }));

        // Hide by default
        this.$el.hide();

        if (ISMOBILE) {
            $(window).on('resize', this.positionButton);
            this.positionButton();
        }

        this.isShowing = false;
        this.$('.js-actionbutton--icon').hide();
        this.$('.js-actionbutton--icon[data-icon-type="next"]').show();
    },

    /**
     * show
     *
     * This function will handle the showing of the actionbutton.
     * It wil set (or clear) its label and set the callback
     *
     * @param  {Function} callback  This is the function which will be executed on click
     * @param  {string} label       This is the label, when empty it wil not show label
     */
    show(callback, label) {

        // Remove previous timeout, if set
        if (this.hideTimeout !== undefined || this.hideTimeout !== null) {
            clearTimeout(this.hideTimeout);
        }

        this.setLabel(label);

        this.clickCallback = callback;

        this.$el.show();

        this.isShowing = true;
    },
    /**
     * onClickActionButton
     *
     * This function will be called on a click on the actionbutton.
     * It will execute the callback given within this.
     @param {object} e event Object
     */
    onClickActionButton(e) {

        this.undelegateEvents()

        if (e) {
            e.stopPropagation()
        }

        // Execute clickCallback if function
        if (this.clickCallback !== undefined && typeof this.clickCallback === 'function') {
            this.clickCallback()
        }

        // If the callback is not persistant, hide the action button
        if (!this.persistantCallback) {
            this.hide()
        }

        setTimeout(() => {
            this.delegateEvents()
        }, 1000)
    },
    /**
     * onFinishedLoading - description
     *
     * @param  {boolean} status Status if succesful or failure
     * @param  {string} label   optional label
     */
    onFinishedLoading(status, label) {

        // When status is true (successful)
        if (status === true) {

            // Set icon to confirm
            this.switchIcon('confirm');

            // When status is false (failed)
        } else {

            // Set icon to cross
            this.switchIcon('cross');
        }

        // Show the action button with the given label, when not given no label will be shown
        this.show(null, label);

        // Set timeout to hide the action button
        this.setHideTimeout(2000);

    },

    /**
     * setLoading
     *
     * This function will transform the button into a loading state button. It will
     * use the Loading... label when there is no label given. keepStyle is true.
     *
     * @param  {string} label Optional label
     */
    setLoading(label) {

        // Switch icon to loading
        this.switchIcon('loading');

        // Show the new actionbutton
        this.show(null, label || window.i18n.gettext('Loading...'));
    },

    /**
     * hide
     *
     * Hide the actionbutton
     *
     */
    hide() {

        // Remove previous timeout, if set
        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout);
        }

        this.$el.hide();
        this.isShowing = false;
    },

    /**
     * switchIcon
     *
     * Function to switch out the icon of the icon button. It will hide
     * all icons by default and then unhide the chosen one.
     *
     * @param  {string}     type The type of icon
     * @returns {Boolean}   Return false on abort
     */
    switchIcon(type) {

        // When the to be switched to icon is the same as the active stop execution
        // to prevent unneccesary transitions
        if (type === this.iconType) {
            return false;
        }

        // Hide the now active icon
        this.$('.js-actionbutton--icon[data-icon-type="' + this.iconType + '"]').hide();

        // Show the new icon
        this.$('.js-actionbutton--icon[data-icon-type="' + type + '"]').show();

        // Store the type in a global variable
        this.iconType = type;
    },

    /**
     * setLabel
     *
     * This function will show or hide the label based on the argument given.
     * It will also update te text of the label element
     *
     * @param  {string} labelText The label text
     */
    setLabel(labelText) {
        if (ISMOBILE) {
            this.$('.js-actionbutton-inner').text(labelText);
        } else if (!labelText) {
            this.$('.js-actionbutton-label__outer').hide();
            this.$('.js-actionbutton-label').html('');
        } else {
            this.$('.js-actionbutton-label__outer').show();
            this.$('.js-actionbutton-label').html(labelText);
        }
    },

    /**
     * setHideTimeout
     *
     * This is the timeout function. It can be used to hide the actionbutton after
     * a specific time.
     *
     * @param  {number} timeout     Set the timeout to hide the actionbutton
     */
    setHideTimeout(timeout) {

        // Where there is a valid timeout set
        if (timeout !== undefined && typeof timeout === 'number') {

            // Add the timeout
            this.hideTimeout = setTimeout(() => {
                this.hide()
            }, timeout);
        }
    },

    positionButton() {

        var width;
        var screenWidth = $(document).width();
        // When in landscape
        if (screenWidth > $(document).height()) {

            width = screenWidth - 150 * 2;

        } else {
            // Takes width of screen and subtracts fixed horizontal padding of 16 * 2
            width = screenWidth - 16 * 2;
        }

        // Add mobile styling to action button
        this.$('.js-actionbutton').addClass(Styles['actionbutton__outer-is--mobile']);

        // Add mobile styling to action button inner element
        this.$('.js-actionbutton-inner').addClass(Styles['actionbutton__inner--is-mobile']);

        TweenMax.set(this.$('.js-actionbutton-inner'), {
            width,
        });

        this.$('.js-actionbutton-label__outer').hide();

        // Calculates the center of document
        var position = (screenWidth - width) / 2;

        // Append actionbutton to center of document
        TweenMax.set(this.$('.js-actionbutton'), {
            left: position
        });

    }
})
