import Styles from './Tabs.scss';

import Template from './Tabs.hbs';
import TabButton from 'views/components/tabs/tabButton/TabButton';

export default BaseView.extend({

    // Array containing tab objects.
    // Example of such an object:
    // {
    //     // Label for display in the DOM.
    //     label: window.i18n.gettext('Class overview'),
    //     // View that is created when this tab button is clicked.
    //     view: GroupProgressView,
    //     // parameters to pass with the view mentioned above.
    //     viewParameters: {
    //         model: this.groupModel
    //     }
    // }
    tabs: [],

    // The view that is currently activated by the active tab.
    activeView: null,

    // [optional] Key to get and set a value to save the last used tab.
    userSettingKey: '',

    events: {
        'click .js-tab': 'onClickTab'
    },

    /**
     * addTabs
     *
     * Creates tab buttons from the this.tabs array.
     *
     * @param {boolean} overrideTemplate if true, don't use the template of this component.
     */
    addTabs(overrideTemplate) {

        if (!overrideTemplate) {
            this.setElement(Template({
                Styles
            }));
        }

        // Loop through tabs array to create a tab button for each entry.
        _.each(this.tabs, (tab) => {
            // Associate the freshly made tab button view to the source object itself.
            tab.tabButtonView = this.addChildView(new TabButton({
                label: tab.label,
                type: tab.type
            }), '.js-tabs');
        })

        // Check if there is a user settings key defined.
        // If so, try to get this value from the database.
        if (this.userSettingKey) {
            this.userSettingValue = Backbone.Model.user.getSetting(this.userSettingKey);
        }

        // Set active tab to that defined by the users's setting.
        // If user setting is not defined, use the first tab instead.
        this.setActiveTab(
            _.findWhere(this.tabs, {
                type: this.userSettingValue
            }) || _.first(this.tabs)
        );

    },

    /**
     * onClickTab
     *
     * When element with the class '.js-tab' (a tab) is clicked, set this element as the active tab.
     *
     * @param {Event} e     click event
     */
    onClickTab(e) {
        var clickedTabObject = _.findWhere(this.tabs, {
            type: e.currentTarget.dataset.tabType
        });
        this.setActiveTab(clickedTabObject);
    },

    /**
     * setActiveTab
     *
     * Make the active tab appear active for the user, update the user setting (if available).
     * Then destroy any views connected to currently active tabs and create a new one connected to the
     * clicked tab model.
     *
     * @param {Object} clickedTabObject     tab data object
     */
    setActiveTab(clickedTabObject) {
        this.trigger('tabSwitched')

        // Make sure clicked tab model exists.
        // If so, also check if this tab isn't already the active one.
        if (
            clickedTabObject &&
            clickedTabObject.tabButtonView.isTabActive() === false
        ) {

            // If this.activeView is defined, destroy it to make room for the new one.
            if (this.activeView) {
                this.unregisterAndDestroyChildView(this.activeView);
            }

            // Set all tabs inactive.
            _.each(this.tabs, function(tab) {
                tab.tabButtonView.setTabInactive();
            });
            // Set clicked tab active.
            clickedTabObject.tabButtonView.setTabActive();

            // If user setting key is defined, update this setting.
            if (this.userSettingKey) {
                Backbone.Model.user.addSetting(this.userSettingKey, clickedTabObject.type);
            }

            // Create the new view and set this as the currently active view.
            this.activeView = this.addChildView(
                new clickedTabObject.view(clickedTabObject.viewParameters),
                '.js-tab-view-container'
            );

        }

    },

});
