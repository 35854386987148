import Styles from './ResponseItem.scss';

import Template from './ResponseItem.hbs';
import ResponseBar from 'views/pages/activities/show/types/presentation/parts/responseOverview/responseBar/ResponseBar';
import Util from 'util/util';

var ResponseItem = BaseView.extend({

    initialize(options) {

        // Create mutable variable that will hold the response value
        var responseValue = options.response.value;

        if (

        // If a response is passed
            options.response &&
                options.taskModel &&

                // And the task is a multipe choice
                options.taskModel.get('template_id') === 1
        ) {
            if (
            // Check if type is either image, audio or formula
                options.taskModel.get('task_info_json').inputType === 'image' ||
                    options.taskModel.get('task_info_json').inputType === 'audio' ||
                    options.taskModel.get('task_info_json').inputType === 'formula'
            ) {

                //  If so, we don't have labels but keys. We need to parse these numbers
                // to a letter. So it will be displayed like the question
                responseValue = Util.numToLetter(parseInt(options.response.key)).toUpperCase();
            }
        }

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            response: responseValue
        }));

        // Render the response bar
        this.addChildView(
            new ResponseBar({

                // Send response object
                response: options.response,

                // And total of responses
                responseTotal: options.responseTotal,

                slideState: options.slideState,

                taskModel: options.taskModel,

            }), '.js-response-bar'
        );
    },

});

export default ResponseItem;
