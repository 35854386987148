var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup") : stack1), depth0))
    + "\">\n\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button\" data-command=\"mceTableInsertRowBefore\">\n        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Insert row before",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":34}}}))
    + "\n    </button>\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button\" data-command=\"mceTableInsertRowAfter\">\n        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Insert row after",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":33}}}))
    + "\n    </button>\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button\" data-command=\"mceTableDeleteRow\">\n        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Remove row",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":27}}}))
    + "\n    </button>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option-seperator") : stack1), depth0))
    + "\"></div>\n\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button js-toggle-border\" data-command=\"toggleBorder\">\n          "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"toggleBorder") : depth0), depth0))
    + "\n    </button>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option-seperator") : stack1), depth0))
    + "\"></div>\n\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button\" data-command=\"mceTableInsertColBefore\">\n        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Insert column before",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":22,"column":37}}}))
    + "\n    </button>\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button\" data-command=\"mceTableInsertColAfter\">\n        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Insert column after",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":36}}}))
    + "\n    </button>\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button\" data-command=\"mceTableDeleteCol\">\n        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Remove column",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":28,"column":30}}}))
    + "\n    </button>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option-seperator") : stack1), depth0))
    + "\"></div>\n\n    <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"popup__table-option") : stack1), depth0))
    + " js-table-option-button\" data-command=\"mceTableDelete\" data-once=\"true\">\n        "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Remove table",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":34,"column":29}}}))
    + "\n    </button>\n</div>\n";
},"useData":true});