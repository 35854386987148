import Styles from './ResponseBlock.scss';

import Template from './ResponseBlock.hbs';
import ResponseItem from 'views/pages/activities/show/types/presentation/parts/responseOverview/responseItem/ResponseItem';
import Button from 'views/components/button/Button'

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onClickOtherAnswers'
        );

        this.slideState = options.slideState;
        this.taskModel = options.taskModel;

        this.parent = options.parent;
        this.presentation = options.parent.presentation;

        if (

        // If there were more than 1 responses given
            options.responseTotal > 1 &&

                // If we are in the check answer phase
                this.presentation.slideState === 2
        ) {

            // Make the correct answers appear at the top
            options.responses = _.sortBy(options.responses, {
                correct: false
            });
        } else {
            options.responses = _.sortBy(options.responses, {
                count: true
            });
        }

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        if (options.responseTotal > 0) {
            this.renderBlockWithResponses(options);
        } else {
            this.$('.js-no-responses-text').show();
        }

    },

    /**
         * renderBlockWithResponses
         *
         * This function is triggered when the task has responses.
         * It will render the responses inside the responseoverview.
         *
         * @param  {Object} options - Options passed from the constructor
         */
    renderBlockWithResponses(options) {

        // This represents the container where the rendered response items
        // will be appended to
        var answerContainer = '.js-visible-responses';

        // Loop through the response array
        _.each(options.responses, function(response, index) {

            // When there are 5 or more answers,
            if (index >= 4) {

                // add the new answers to the hidden responses container
                answerContainer = '.js-hidden-responses';
            }

            this.renderResponseItem(response, options.responseTotal, answerContainer);

        }, this);

        if (answerContainer === '.js-hidden-responses') {

            // If we added response items to the hidden-responses container
            // This means we need to create a response item with the 'other' label
            // to represent these response items
            this.createOtherResponsesButton(options.responses, options.responseTotal);

        }
    },

    /**
         * onClickOtherAnswers
         *
         * Click handler function, if user clicks on other answer.
         *
         */
    onClickOtherAnswers() {
        this.toggleFoldOtherAnswers();
    },

    /**
         * toggleFoldOtherAnswers
         *
         * Animation function that shows the other responses given.
         *
         */
    toggleFoldOtherAnswers() {

        // Set default button text
        var otherResponsesBtnText = window.i18n.gettext('Show more answers');

        // When the other responses are folded
        if (this.folded) {

            // Set the height to auto and opacity to 1
            TweenMax.set(this.$('.js-hidden-responses'), {
                height: 'auto',
                display: 'block'
            });

            // Start from height, low opacity and display none
            TweenMax.from(this.$('.js-hidden-responses'), {
                display: 'none',
                height: 0,
                duration: 0.25,
                ease: 'power3.out'
            });

            // Set button text for when the button is unfolded
            otherResponsesBtnText = window.i18n.gettext('Show less answers');

        } else {

            // Animate back to 0 height and no display
            TweenMax.to(
                this.$('.js-hidden-responses'), {
                    height: 0,
                    display: 'none',
                    duration: 0.25,
                    ease: 'power3.out'
                });
        }

        // Change text of button in DOM
        this.otherAnswersBtn.changeLabel(otherResponsesBtnText);

        // Make sure the state is changed
        this.folded = !this.folded;
    },

    /**
         * renderResponseItem
         *
         * This function constructs a response item that will communicate
         * the response value, the times this responses was given (and compared to the total).
         *
         * @param  {Object} response      Object containing data about the response sent from backend
         * @param  {number} responseTotal The total of responses given per answer option
         * @param  {string} answerContainer Selector string of the element the response should attach to
         */
    renderResponseItem(response, responseTotal, answerContainer) {

        // Render the response bar
        this.addChildView(
            new ResponseItem({

                // Send response object
                response,

                // And total of responses
                responseTotal,

                slideState: this.slideState,

                taskModel: this.taskModel,

            }), answerContainer
        );
    },

    /**
         * createOtherResponsesButton
         *
         * This function creates a button.
         * On click it will toggle the hidden student responses.
         *
         */
    createOtherResponsesButton() {

        this.addChildView(
            this.otherAnswersBtn = new Button({
                label: window.i18n.gettext('Show more answers'),
                callback: this.onClickOtherAnswers

            }), '.js-other-responses-btn'
        );

        this.folded = true;
        this.$('.js-other-responses-btn').show();
    }

});
