import Styles from './Summary.scss';

import Template from './Summary.hbs';
import SubjectsCollection from 'collections/SubjectsCollection'
import SubjectProgressLabel from 'views/components/subjectProgressLabel/SubjectProgressLabel'
import Button from 'views/components/button/Button'

export default BaseView.extend({

    /**
    * initialize
    *
    * Initializing function, which will be called on creation. It
    * will create a DOM element based on the given template.
    *
    * @param  {Object} options     Options as defined by parent
    */
    initialize(options) {

        // Make this accessible within following methods
        _.bindAll(this,
            'onInView',
            'stopActivity',
            'onLoadSubjectsStatus'
        );

        // Make showview accessible within methods
        this.showView = options.showView;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        this.adaptiveSummaryCollection = new SubjectsCollection()
        this.adaptiveSummaryCollection.url = `/activities/get_subjects_status/${this.model.id}.json`

        // Start listening to
        this.listenTo(

            // Listen to the subjects collection
            this.adaptiveSummaryCollection,

            // listen for the following events
            'change update reset',

            // When event triggered, call onChangeSubjectsCollection
            this.onChangeSubjectsCollection
        )

        const data = {
            // Tell the backend it's the summary page who's requesting
            add_summary: true,
        }

        if (this.model.get('type') === 'adaptive_student') {
            // Pass on the subject ids
            data.subject_ids = options.adaptiveSubjects.pluck('id')
        }

        // Do a call to the subject statusses
        this.adaptiveSummaryCollection.fetch({
            data: $.param(data),
            success: this.onLoadSubjectsStatus
        })

        // Remove the onbeforeunload logic
        window.onbeforeunload = undefined;
    },

    /**
    * onChangeSubjectsCollection
    *
    * This method will be called when the subjects collection changes.
    * Remove all existing subject progress labels and add new ones using the collection.
    */
    onChangeSubjectsCollection() {
        this.destroyChildViewsOfInstance(SubjectProgressLabel)
        this.adaptiveSummaryCollection.each((model) => {
            this.addChildView(new SubjectProgressLabel({
                model
            }), '.js-subjects')
        })
    },

    /**
    * onLoadSubjectsStatus
    *
    * This method will be called when fetching subject statusses is succesful. It will
    * add the right statistics to the page.
    *
    * @param  {SubjectsCollection} collection      subjects collection
    * @param  {Object} response                    Response object from backend
    */
    onLoadSubjectsStatus(collection, response) {

        this.$('.js-made').text(response.tasks_made)

        // Parse the motivational message
        this.parseMotivationalMessage(response.message);
    },

    parseMotivationalMessage(messageObject) {

        // Set a message holder
        var messages = [];

        // Check if there is a message object
        if (!_.isEmpty(messageObject)) {

            // If there is a message object start switch case on keyword
            switch (messageObject.keyword) {

                case 'score_decreased':

                    messages.push(
                        window.i18n.gettext('Keep on going to improve your scores! 💪')
                    )

                    break

                // When score is increased
                case 'score_increased':

                    // Add message
                    messages.push(

                        window.i18n.sprintf(window.i18n.ngettext(
                            'Congratulations 🔥🔥 ! You improved your score on %s subject!',
                            'Congratulations 🔥🔥 ! You improved your score on %s subjects!',
                            messageObject.value || 0
                        ), messageObject.value || 0)
                    )
                    break;
            }
        }

        // If there wasn't a message found
        if (messages.length === 0) {

            // Add the default message
            messages.push(
                window.i18n.gettext(
                    'Kudos for your hard work 👍 ! Keep on going to improve your scores!'
                )
            );
        }

        // Get the message by taking the message list
        var message = _.sample(messages)

        // Add the message to the js-motivational-message container
        this.$('.js-motivational-message').text(message)
    },

    /**
    * onInView
    *
    * Since there is a bug where elements with transform css cannot be position absolute,
    * we're force to do a nice animation. This method is called after the animation from show
    * view is ended and the transform style is removed from the holder. It will/should Animate
    * all elements visible on the summary page. Else it will be ugly flashy
    *
    */
    onInView() {

        // Create a button to quit the adaptive activity.
        this.addChildView(new Button({
            label: window.i18n.gettext('Stop'),
            icon: 'cross',
            callback: this.stopActivity,

            // Add all the neccessary styling options:
            inline: true,
            theme: 'secondary',
        }), '.js-stop-button')

        // Create a button for restarting the adaptive activity.
        this.addChildView(new Button({
            label: window.i18n.gettext('Continue'),
            iconRight: 'arrow-right',
            callback: this.showView.restart,
            inline: true,
            theme: 'secondary',
        }), '.js-next-button')

    },

    /**
    * stopActivity
    *
    * This method will stop the adaptive activity by navigating to the
    * section show view.
    *
    */
    stopActivity() {
        const sectionModel = this.model.getSectionModel()
        Backbone.history.navigate(
            `sections/show/${sectionModel.id}`,
            {trigger: true}
        )
    }

})
