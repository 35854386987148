export default Backbone.Model.extend({

    initialize({ activityId }) {
        this.activityId = activityId
    },

    url() {
        return this.activityId ?
            '/training/get_list/' + this.id + '/' + this.activityId :
            '/training/get_list/' + this.id
    },

    parse(response) {
        response.pairs = new Backbone.Collection(response.pairs);

        /**
         * TODO!!!
         * In case there are no sessions, ask backend to return null or an empty object. Right now
         * it returns an empty array, but if there is at least one session, it returns an object.
         * That's inconsistent and confusing.
         */

        if (Array.isArray(response.sessions) && response.sessions.length === 0) {
            response.sessions = {};
        }

        return response;
    },

    canBeEdited() {
        if (this.get('metadata').user_id === Backbone.Model.user.id) {
            return true
        }

        if (this.activityId && Backbone.Model.user.get('is_teacher')) {
            const groupModel = Backbone.Collection.activities.get(this.activityId)?.getGroupModel()
            if (groupModel?.get('collaboration_enabled') && groupModel.get('teachers').has(this.get('metadata').user_id)) {
                return true
            }
        }

        return false
    }

});
