import Styles from './SubjectItem.scss';

import Template from './SubjectItem.hbs';
import Checkbox from 'views/components/checkbox/Checkbox'

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'setSubjectState'
        )

        this.setElement(Template({
            Styles,
            scoreLabel: this.model.getScoreLabel(),
            scoreColor: Styles[`score--${this.model.getScoreColor()}`],
            tasksMade: this.model.getTasksMade()
        }))

        this.checkbox = this.addChildView(new Checkbox({
            label: this.model.get('name'),
            isChecked: this.model.get('isChecked'),
            callback: this.setSubjectState
        }), '.js-content')

        this.listenTo(
            options.chapterModel.sections,
            'change:isChecked',
            this.setSubjectVisibility
        )

    },

    setSubjectState(state) {
        this.model.set({isChecked: state})
    },

    setSubjectVisibility(model) {
        const isRelevantSectionChecked = this.model.get('section_ids').some((sectionId) => {
            const sectionModel = model.collection.get(sectionId)
            return sectionModel && sectionModel.get('isChecked')
        })
        if (isRelevantSectionChecked) {
            this.$el.show()
        } else {
            this.$el.hide()
            this.checkbox.setState(false, true)
        }
    }

})
