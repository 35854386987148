var Handlebars = require("../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"container") : stack1), depth0))
    + "\">\n    <div class=\"js-navbar\">\n        <div class=\"js-sidenavigation-container "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sidenavigation-container") : stack1), depth0))
    + "\">\n            <button\n                class=\"js-navigation-arrow js-navigation-arrow-top "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"arrow") : stack1), depth0))
    + "\"\n                value=\"arrow-up\"\n            >\n                "
    + ((stack1 = __default(require("../../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-up",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":38}}})) != null ? stack1 : "")
    + "\n            </button>\n            <div class=\"js-items "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sidenavigation-container__items") : stack1), depth0))
    + "\"></div>\n            <button\n                class=\"js-navigation-arrow js-navigation-arrow-bottom "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"arrow") : stack1), depth0))
    + "\"\n                value=\"arrow-bottom\"\n            >\n                "
    + ((stack1 = __default(require("../../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":40}}})) != null ? stack1 : "")
    + "\n            </button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});