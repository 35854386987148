import Styles from './Template34.scss';

import Template from './Template34.hbs';
import Util from 'util/util';
import Item from 'views/components/taskGroups/tasks/template34/item/Item';
import AnswerTemplate from 'views/components/taskGroups/tasks/template34/Answer.hbs';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onOrderChange',
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;

        // Convert all entries to Number type values if they are not already.
        var JSONAnswer = (this.taskView.response.get('json_answer') || []).map(Number);

        this.items = this.model.get('task_info_json').items || [];

        // Array containing the item IDs in the correct order.
        this.correctOrder = this.model.get('task_info_json').correct_order;

        // Sort items to that of the already given answer.
        // Or in case of author, in the correct order.
        this.answeredItems = _.sortBy(this.items, (item) => {
            if (APPLICATION === 'author') {
                return this.correctOrder.indexOf(item.id)
            }

            return JSONAnswer.indexOf(item.id)
        });

        this.renderTask()
    },

    /**
         * renderTask
         *
         * This method will render the template of the task. It will be
         * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
         * if only the answer view is necessary
         *
         */
    renderTask() {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        // Create view for all items.
        this.itemViews = [];
        _.each(this.answeredItems, (model) => {
            var itemView = this.addChildView(new Item({
                model,
                collection: this.answeredItems
            }), this.$el);
            itemView.on('orderChange', this.onOrderChange);
            this.itemViews.push(itemView);
        })
    },

    /**
         * onOrderChange
         *
         * When the reorder animation is completed, the 'orderChange' event is
         * triggerd (see Item view). Reset the animation state, detach the DOM
         * of ail items and append them again in the new order. Finally, save
         * the user's answer.
         */
    onOrderChange() {

        // Remove placeholder divs used during the transition animation.
        this.$('.js-item-placeholder').remove();

        // Detach item view DOM from view and remove inline style attribute
        // used during the transition animation.
        _.each(this.itemViews, (itemView) => {
            itemView.detach();
            itemView.$el.removeAttr('style');
        });

        // Sort views in the new order and re-attach them to the DOM in
        // said order.
        this.itemViews = _.sortBy(this.itemViews, (itemView) => {
            return itemView.getItemIndex();
        });
        _.each(this.itemViews, (itemView) => {
            itemView.attachTo(this.$el);
            itemView.showNavigation();
        })

        // Save the new answer.
        this.saveAnswer();

    },

    /**
         * showAnswer
         *
         * Show the correct answer to the task.
         */
    showAnswer() {
        _.invoke(this.itemViews, 'showAnswer', this.correctOrder);
    },

    showAuthorAnswer() {
        this.showAnswer()
    },

    /**
         * hideAnswer
         *
         * Hide the correct answer to the task.
         */
    hideAnswer() {
        _.invoke(this.itemViews, 'hideAnswer');
    },

    /**
         * createSummary
         *
         * Create summarized version of items for use in the getCorrectAnswer
         * and getStudentAnswer methods.
         *
         * @param  {Array} items    input items objects
         * @return {Array}          items with shortend content
         */
    createSummary(items) {
        return _.map(items, (model) => {

            // Clone the object item to prevent the source text itself
            // from being changed by this truncation operation.
            model = _.clone(model);

            // Remove all HTML tags from the text to prevent unwanted corrections by
            // the browser if a tag does not close properly or they are image tags.
            model.text = Util.stripTags(model.text);

            // Split this into words.
            var splitItemText = model.text.split(/\s+/);

            // If item text has more than 6 words, truncate the text by inserting an
            // … after the first 3 words and before the last 3 words and getting rid
            // of the rest.
            if (splitItemText.length > 6) {
                var leftStr = _.first(splitItemText, 3).join(' ') + '… ';
                var rightStr = ' …' + _.last(splitItemText, 3).join(' ');
                model.text = leftStr + rightStr;
            }

            return model;

        });
    },

    /**
         * getCorrectAnswer
         *
         * Generate summary of the correct answer to use above the list of student answers.
         *
         * @return {string} HTML blob to be inserted above the list of student answers.
         */
    getCorrectAnswer() {
        // Sort items by the correct answer order.
        var correctAnswerItems = _.sortBy(this.items, (item) => {
            return this.correctOrder.indexOf(item.id);
        })
        return _.map(this.createSummary(correctAnswerItems), (item, index) => {
            return AnswerTemplate({
                Styles,
                index: index + 1,
                content: Util.renderContentSafely(item.text)
            });
        }).join('');
    },

    /**
         * getStudentAnswer
         *
         * Generate summary of the answer student has given for use in the list of student answers.
         *
         * @param  {Object} responseModel Student response data
         * @return {string}               HTML blob to be used for the student answer view.
         */
    getStudentAnswer(responseModel) {

        // Convert all entries to Number type values if they are not already.
        responseModel = (responseModel.get('json_answer') || []).map(Number);

        // Return empty string if no answer has been given.
        if (responseModel.length === 0) {
            return '';
        }

        // Sort items to that of the answer given by the student and remove items that where
        // not present when the response was created.
        var studentAnsweredItems = _.filter(this.items, (item) => {
            return _.contains(responseModel, item.id);
        });
        studentAnsweredItems = _.sortBy(studentAnsweredItems, (item) => {
            return responseModel.indexOf(item.id);
        });

        // Create summary of each item. Then create a answer element for each of those
        // and add the styling appropriate to if the answer is correct or not.
        return _.map(this.createSummary(studentAnsweredItems), (item, index) => {
            var answerElement = $(AnswerTemplate({
                Styles,
                index: index + 1,
                content: Util.renderContentSafely(item.text)
            }));
            if (item.lock) {
                answerElement.addClass(Styles['answer--gray']);
            } else if (this.correctOrder[index] === item.id) {
                answerElement.addClass(Styles['answer--correct']);
            } else {
                answerElement.addClass(Styles['answer--incorrect']);
            }
            return answerElement.prop('outerHTML');
        }).join('')

    },

    /**
         * saveAnswer
         *
         * Convert this.JSONAnswer to a simple object and pass it to the saveResponse method of the parent taskView
         * to patch the answer to the server.
         */
    saveAnswer() {
        this.taskView.saveResponse(_.pluck(this.answeredItems, 'id'));
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {
        _.invoke(this.childViews, 'undelegateEvents')
        _.invoke(this.itemViews, 'lockAnswer')
    },

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {
        _.invoke(this.childViews, 'delegateEvents')
        _.invoke(this.itemViews, 'unlockAnswer')
    }

});
