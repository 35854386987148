import Styles from './Competencies.scss';

import Template from './Competencies.hbs';
import QuickSelect from 'views/components/quickSelect/QuickSelect.svelte'
import Competency from './competency/Competency'
import CompetencyEvaluationCollection from 'collections/CompetencyEvaluationCollection'
import Button from 'views/components/button/Button'
import HeroButton from 'views/components/heroButton/HeroButton'

export default class CompetenciesActivity extends BaseView {

    initialize({
        params: [, studentId]
    }) {

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle()
        Backbone.View.header.setCrumblepath(
            this.model.getAllCrumblepathModels(),
            'show'
        )
        Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Print'),
                icon: 'print',
                callback: () => {
                    window.print()
                }
            })
        )

        this.setElement(Template({
            Styles
        }))

        if (studentId) {
            studentId = parseInt(studentId)
        } else if (Backbone.Model.user.get('is_student')) {
            studentId = Backbone.Model.user.id
        }

        this.competencyModel = this.model.get('competency')

        if (Backbone.Model.user.get('is_teacher')) {
            this.addSvelteChildView('.js-student-selector', QuickSelect, {
                items: [
                    {
                        label: window.i18n.gettext('No student selected'),
                        value: -1,
                    },
                    ...this.model.getGroupModel().students.map((student) => {
                        return {
                            value: student.id,
                            label: student.first_name_last_name(),
                        }
                    })
                ],
                defaultValue: studentId,
                label: window.i18n.gettext('Student'),
                callback: this.onSelectStudent.bind(this),
            })
        }

        if (studentId) {
            const evaluationCollection = new CompetencyEvaluationCollection(null, {
                competencyModel: this.competencyModel,
                userId: studentId
            })
            evaluationCollection.fetch({
                success: Backbone.Model.user.get('is_teacher') ?
                    this.renderTeacherStudentEvaluations.bind(this) :
                    this.renderStudentEvaluations.bind(this)
            })

            // When a form gets reset, that form will self-destruct. In response, create a new evaluation form.
            this.listenTo(evaluationCollection, 'destroy change:is_finished', () => {
                this.renderTeacherStudentEvaluations(evaluationCollection)
            })

            if (Backbone.Model.user.get('is_teacher')) {
                this.startNewEvaluationButton = this.addChildView(new Button({
                    icon: 'add',
                    label: window.i18n.gettext('Start new evaluation'),
                    inline: true,
                    callback: () => {
                        this.startNewEvaluationForm(evaluationCollection)
                    },
                }), '.js-start-evaluation-button')
                this.startNewEvaluationButton.$el.hide()
            }
        } else {
            this.renderExampleEvaluationForm()
        }

    }

    onSelectStudent({value: studentId}) {
        if (studentId === -1) {
            Backbone.history.navigate(`activities/show/${this.model.id}`, true)
        } else {
            Backbone.history.navigate(`activities/show/${this.model.id}/${studentId}`, true)
        }
    }

    renderExampleEvaluationForm() {
        this.addChildView(new Competency({
            model: this.competencyModel,
            title: this.model.get('name'),
            isReadOnly: true,
        }), '.js-open-evaluation')
        this.$('.js-evaluations-archive').hide()
    }

    renderStudentEvaluations(evaluationCollection) {
        if (evaluationCollection.length === 0) {
            this.renderExampleEvaluationForm()
            return
        }
        for (const evaluationModel of evaluationCollection) {
            if (this.el.querySelector(`[data-evaluation-id="${evaluationModel.id}"]`)) {
                continue
            }
            this.addChildView(new Competency({
                model: evaluationModel.getCompetencyVersion(),
                isReadOnly: true,
                evaluationModel,
            }), '.js-evaluations-archive')
        }
    }

    renderTeacherStudentEvaluations(evaluationCollection) {
        let openEvaluationModel
        for (const evaluationModel of evaluationCollection) {
            if (this.el.querySelector(`[data-evaluation-id="${evaluationModel.id}"]`)) {
                continue
            }
            if (!evaluationModel.get('is_finished')) {
                openEvaluationModel = evaluationModel
                continue
            }
            this.addChildView(new Competency({
                model: evaluationModel.getCompetencyVersion(),
                isReadOnly: true,
                evaluationModel,
            }), '.js-evaluations-archive')
        }

        this.startNewEvaluationButton.$el.toggle(!openEvaluationModel)

        if (openEvaluationModel) {
            // Create open evaluation form
            this.addChildView(new Competency({
                model: openEvaluationModel.getCompetencyVersion(),
                evaluationModel: openEvaluationModel,
                title: this.model.get('name'),
            }), '.js-open-evaluation')
        }
    }

    startNewEvaluationForm(evaluationCollection) {
        this.startNewEvaluationButton.$el.hide()
        // Create empty evaluation form
        const evaluationModel = evaluationCollection.add({
            student_id: evaluationCollection.userId,
            competency_id: this.competencyModel.id,
            user_id: Backbone.Model.user.id,
        })
        this.addChildView(new Competency({
            model: this.competencyModel,
            evaluationModel,
            title: this.model.get('name'),
        }), '.js-open-evaluation')
    }

}
