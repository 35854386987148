import Styles from './Coordinate.scss';

import Template from './Coordinate.hbs';
import QuickInput from 'views/components/quickInput/QuickInput';
import Util from 'util/util';
import ShapeList from 'util/ShapeLoader'
export default class CoordinateView extends BaseView {

    get events() {
        return {
            mouseup: 'onClickCoordinate'
        }
    }

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     The options as passed from the parent
     */
    initialize(options) {

        _.bindAll(
            this,
            'createCoordinateInput',
            'onAddLabel',
            'onClickCoordinate',
            'deleteCoordinate',
            'appendDropped',
            'showDraggable',
            'positionInput',
            'positionCoordinate',
            'onHover',
            'onOut',
            'checkLabelAlignment',
            'isCorrectAnswer'
        );

        // Set the following props on the view
        this.x = options.x;
        this.y = options.y;
        this.parent = options.parent;
        this.type = options.type;
        this.key = options.key;
        this.variants = options.variants;

        // If student can check the answers, save the answer to the view
        if (options.correctAnswer) {
            this.correctAnswer = options.correctAnswer;
        }

        // Check if a label is already added
        if (options.label) {
            this.label = options.label;
        }

        this.setElement(Template({
            Styles,
            label: Util.renderContentSafely(Util.unescape(this.label)),
            key: this.key
        }));

        // Listen if an input needs to be closed, so it can be deleted
        this.listenTo(this.parent, 'closeInput', this.deleteInput);

        // If there is no label set and the user is in editMode.
        //  Add the edit class to the coordinate
        //  and create a new coordinate input
        if (this.type === 'editMode') {
            if (!this.label) {
                this.editCoordinate();
            } else {
                // If the label is set, render a placed coordinate.
                this.$('.js-coordinate').addClass(Styles['coordinate--edit']);
            }
        }

        if (this.type === 'dragText' || this.type === 'fillText') {
            if (this.label) {
                // If the label is set, render a placed coordinate.
                this.$('.js-coordinate').addClass(Styles['coordinate--placed']);
            } else {

                // Render a blue coordinate
                this.$('.js-coordinate').addClass(Styles['coordinate--edit']);
            }
        }

        // Position the coordinate in the view
        this.positionCoordinate();

        // If no label is set, hide the label element and the its styling.
        if (!options.label) {
            this.$('.js-label').css('display', 'none');
        }
    }

    lockAnswer(callback) {

        // Remove events from DOM bound to this view
        this.undelegateEvents();

        this.el.classList.add(Styles['is-locked'])

        // If the answer needs scoring a callback function will be set from Template25.js.
        // If not it will be null.
        this.$el.on('click', callback);
    }

    unlockAnswer(callback) {

        // Remove the callback function or null
        this.$el.off('click', callback);

        this.el.classList.remove(Styles['is-locked'])

        // Bind the events of this view again to DOM
        this.delegateEvents();

    }

    /**
     * createCoordinateInput
     *
     * This function will create a QuickInput view so a user can
     * add a new label to a coordinate.
     */
    createCoordinateInput() {
        // Trigger the close input callback if an input is still open
        this.parent.trigger('closeInput');

        // Create a defaultValue that is passed to the constructor
        var defaultValue = ''

        // Make the label the default value of the input, if it's defined.
        if (this.label !== undefined) {
            this.$('.js-label').empty();
            defaultValue = Util.unescape(this.label)
        }

        // Create a variable delete button
        const deleteCallback = this.type === 'dragText' || this.type === 'fillText' ? false : this.deleteCoordinate;

        // Construct a new quick input and add props
        this.coordinateInput = new QuickInput({
            defaultValue,
            placeholder: window.i18n.gettext('Answer'),
            inputCallback: this.onAddLabel,
            deleteCallback,
            parent: this,
            noAutoCapitalize: true,
            noAutoCorrect: true
        });

        this.registerChildView(this.coordinateInput);

        this.inputIsClosed = false;

        // Make sure coordinate input is hidden before (re)-positioning it
        // Otherwise visual glitches in the DOM can occur when positioning the coordinate input
        this.coordinateInput.$el.hide();

        // Append it to the image container
        this.coordinateInput.appendTo(this.parent.$('.js-image-container'));

        // Call function that positions the input field
        this.positionInput();

        // Set the focus on the new input
        this.coordinateInput.$('input').trigger('focus');
    }

    /**
     * positionInput
     * This function determines how the input should be positioned relative to the coordinate.
     * If the input (which needs to be 344px wide) is placed on the edge of an image, it's possible that the
     * input will be compressed.
     */
    positionInput() {

        // Add standard CSS positioning for input element
        this.coordinateInput.$el.css({
            top: this.y,
        }).addClass(Styles['coordinate-input']);

        var imageWidth = this.parent.imageWidth;

        // Transform the relative position of X to an absolute coordinate on the image
        this.absoluteX = parseFloat(this.x) / 100 * imageWidth;

        // Check how much space is left after you subtract the width of the input (344px)
        var spaceForInput = imageWidth - this.coordinateInput.$el.width();

        // If the input field doesn't fit on either side of the coordinate
        // This only applies to images smaller than 2 times the width of the input = 688 pixels
        if (
            this.absoluteX > spaceForInput &&
            this.absoluteX + spaceForInput < imageWidth
        ) {

            // Position the input field around the middle of the image
            this.coordinateInput.$el.css('right', (this.absoluteX + spaceForInput) / 4);

            // When there is not enough space on the right side of the image to place input
        } else if (this.absoluteX > spaceForInput) {

            // Position the input from the right side by determining
            // the relative position from left to right.
            this.coordinateInput.$el.css(
                'right', imageWidth - this.absoluteX
            );

        } else {
            // If none of the above applies, this means there is room to position the coordinate
            // from the left using the X coordinate from the parent.
            this.coordinateInput.$el.css('left', this.x);

        }

        this.coordinateInput.$el.show();
    }

    /**
     * onAddLabel
     *
     * It destroys the current input.
     *
     */
    onAddLabel() {

        // Destroy input
        this.deleteInput();
    }

    /**
     * positionCoordinate
     *
     * This function determines how the coordinate should be positioned.
     * It will be based on the set type.
     */
    positionCoordinate() {

        // When the type of task is dragText
        if (this.type === 'dragText') {

            // Turns the dropzone of the coordinate element into a droppable object.
            this.$('.js-dropzone').droppable({

                // Draggable will snap to droppable if it overlaps in any amount
                tolerance: 'pointer',

                // Callback when draggable is dropped
                drop: this.appendDropped,

                // Callback when draggable is out of dropzone
                out: this.onOut,

                // Callback when draggable hovers over dropzone
                over: this.onHover,
                scope: this.parent.model.id

            });

        }

        // Add CSS to the coordinate, giving it the position passed on the given X and Y.
        this.$el.css({
            position: 'absolute',
            top: this.y,
            left: this.x,
            margin: '-10px 0 0 -7px'
        });

    }

    /**
     * onHover
     *
     * This function is triggered when you hover a draggable object over a droppable object.
     * It will make sure that only dropzone is added at the same time.
     *
     * @param  {Object} e - the mouse event
     */
    onHover(e) {

        $(e.target).addClass(Styles['is-active']);

        // Look in template25 view and select all the dropzones that are not the dropzone that is being hovered
        this.parent
            .$('.js-coordinate-container .js-dropzone').not($(e.target))

        // Remove the hover class
            .removeClass(Styles['is-active']);
    }

    /**
     * onOut
     *
     * This function is triggered when the draggable object is not hovering over a droppable anymore
     *
     * @param  {Object} e - Mouse event
     */
    onOut(e) {

        // Remove the hover class from the targeted dropzone
        $(e.target).removeClass(Styles['is-active']);
    }

    /**
     * appendDropped
     *
     * Function that's called when draggable is dropped on coordinate.
     * It will append the draggable's to the coordinate and save it.
     *
     * @param  {Object} e  Click event
     * @param  {Object} ui The dropped element
     */
    appendDropped(e, ui) {

        // If the target has the hover class, append the dropped
        if ($(e.target).hasClass(Styles['is-active'])) {

            // Find the dropped element
            var dropped = ui.draggable;

            if (this.label) {
                this.showDraggable();
            }

            // Hide it
            dropped.hide();

            // Add the left position of the dropped on element to absoluteX variable
            this.absoluteX = this.$el.position().left;

            // Set a the label of the dropped element on the droppable coordinate
            // Unescape the label so that we get & and > instead of &amp; and &gt;
            const label = Util.unescape(dropped.find('.js-label').html())
            this.setLabelToCoordinate(label)

            // Add the new response to the drag text responses array
            this.parent.saveAnswer();

        }

        // Remove the hover class from the targeted dropzone
        $(e.target).removeClass(Styles['is-active']);

    }

    /**
     * setLabelToCoordinate
     *
     * This functions adds the given label to the related coordinate in
     * this View and also in the DOM.
     *
     * @param  {string} label -
     */
    setLabelToCoordinate(label) {

        if (label) {
            label = label.trim();
            if (label.length > 0) {
                // Add text and placed styling to the label
                this.$('.js-label')
                    .css('display', 'inline-block')
                    .html(Util.renderContentSafely(Util.unescape(label)))
                    .addClass(Styles['coordinate__label--placed']);
                this.label = label;

                // When user is in edit mode
                if (this.type === 'editMode') {

                    // All coordinates are blue
                    this.$('.js-coordinate')
                        .removeClass(Styles['coordinate--placed'])
                        .addClass(Styles['coordinate--edit']);
                } else {

                    // All coordinates with labels are grey
                    this.$('.js-coordinate')
                        .removeClass(Styles['coordinate--edit'])
                        .addClass(Styles['coordinate--placed']);
                }

                this.checkLabelAlignment();
            }
        } else {

            this.label = '';

            // Hide the label element and it's styling if no label is set.
            this.$('.js-label').css('display', 'none');
        }

        this.parent.trigger('saveAnswer');
    }

    /**
     * checkLabelAlignment
     *
     * This function checks if a label is not positioned outside the image.
     *
     * TODO - check if label is not interfering with other labels
     * TODO - add more position options
     */
    checkLabelAlignment() {

        // Measure width of label including margin and padding
        var labelWidth = this.$('.js-label').outerWidth(true);

        // Transform the relative position of X to an absolute coordinate on the image
        var absoluteX = parseFloat(this.x) / 100 * this.parent.imageWidth;

        // When there is no room for the outerwidth of the label on the right side
        if (this.parent.imageWidth - absoluteX - labelWidth < 0) {
            this.$('.js-label').addClass(Styles['is-left-aligned']);
        }
    }

    /**
     * onClickCoordinate
     *
     * Click handler for clicks on any coordinates.
     * @param {Event} e     click event
     */
    onClickCoordinate(e) {

        // Cancel interactions if the target element is currently being dragged.
        if (e.currentTarget.classList.contains('ui-draggable-dragging')) {
            return
        }

        if (this.type === 'dragText') {
            this.showDraggable();
        } else {
            this.editCoordinate();
        }
    }

    /**
     * showDraggable
     *
     * This function makes a draggable appear back in its container.
     * And removes the coordinate from the coordinates view, so it won't be saved as an answer.
     *
     */
    showDraggable() {

        // Go through array of draggables
        _.each(this.parent.draggables, (draggable) => {

            // Check which draggable has the same label as the coordinate
            if (draggable.label === this.label) {

                // Show that draggable
                draggable.$el.show();
            }
        })

        // Loop through the coordinates
        _.each(this.parent.coordinates, (coordinate, index) => {

            // Check if any coordinates have the same key as this coordinate
            // Do a non-strict comparision since in some imports the key may be saved as a number instead of a string.
            // eslint-disable-next-line eqeqeq
            if (coordinate.key == this.key) {

                // Set this coordinate's label to an empty string
                this.parent.coordinates[index].label = '';

            }

        })

        // Set an empty label
        this.setLabelToCoordinate('');

        // Change coordinate class to edit
        this.$('.js-coordinate')
            .addClass(Styles['coordinate--edit'])
            .removeClass(Styles['coordinate--placed']);

        this.$('.js-label').removeClass(Styles['is-left-aligned']);

        // Trigger the save answer function
        this.parent.saveAnswer();
    }

    /**
     * editCoordinate
     *
     * This function handles the visual aspect of the editing of a coordinate.
     * Switching to an edit style, instead of a placed style.
     * It then calls the function that creates a new input for the cooridnate label.
     */
    editCoordinate() {

        // Switch placed class with edit class
        this.$('js-coordinate')
            .removeClass(Styles['coordinate--placed'])
            .addClass(Styles['coordinate--edit']);

        this.$el.css({
            position: 'absolute',
            left: this.x,
            top: this.y
        });

        // Create a new input
        this.createCoordinateInput();
    }

    /**
     * Destroy the coordinate input
     */
    deleteInput() {
        if (this.coordinateInput && !this.inputIsClosed) {
            const input = this.coordinateInput.getInput()
            this.setLabelToCoordinate(Util.stripTags(input))
            this.destroyChildViewsOfInstance(QuickInput);

            this.inputIsClosed = true;
        }
    }

    /**
     * deleteCoordinate
     *
     * This function deletes the current coorindate view and any related input.
     * It also removes the coordinate from the array that's holding all the coordinate views.
     */
    deleteCoordinate() {

        // Destroy quick input
        this.coordinateInput.destroy();

        // Remove current coordinate from array of coordinate views
        this.parent.coordinates = _.without(this.parent.coordinates, this);

        // Destroy current coordinate view
        this.destroy();

    }

    /**
     * checkAnswer
     *
     * This function checks if the added label is the same as the correct answer of the coordinate.
     * When its true it a correct-answer styling will be passed.
     * When it's false a wrong-answer styling will be passed and the correct answer is shown next to the coordinate.
     */
    checkAnswer() {

        // Set answer label to equal label in coordinate object or undefined
        // Undefined is a fallback if a coordinate is removed from the Hotspot canvas
        // The label is then removed from the object but not evaluated to false, which is
        // the default for answers that are not filled.
        var answerLabel = this.label || undefined;
        var correctAnswer = this.correctAnswer;
        var variants = this.variants;

        // If case sensitive options was set to false
        if (
            this.parent.answerType === 'fillText' &&
            this.parent.model.get('task_info_json').options.caseSensitive === false
        ) {

            if (typeof answerLabel === 'string') {
                answerLabel = answerLabel.trim().toLowerCase();
            }

            if (typeof correctAnswer === 'string') {
                correctAnswer = correctAnswer.trim().toLowerCase();
            }

            if (variants) {
                variants = _.map(variants, (variant) => {
                    if (typeof variant === 'string') {
                        return variant.trim().toLowerCase();
                    }
                })
            }
        }

        // When in the student view
        if (APPLICATION === 'webapp') {
            if (this.isCorrectAnswer(answerLabel, correctAnswer, variants) &&
                this.model.get('grading_mode') !== 'student'
            ) {
                this.$el.addClass(Styles['has-correct-answer']);
            } else {

                // Check if grading mode is not student, because when it is
                // student we always come into this else part and we don't want
                // to give any wrong or right indications
                if (this.model.get('grading_mode') !== 'student') {
                    this.$el.addClass(Styles['has-wrong-answer']);
                }
                this.$('.js-correct-answer').html(Util.renderContentSafely(Util.unescape(this.correctAnswer)))
                this.$('.js-correct-answer').css('display', 'inline-flex');

                this.$('.js-correct-answer').prepend(ShapeList['checkmark']);
            }
        } else {
            this.$('.js-correct-answer').html(Util.renderContentSafely(Util.unescape(this.correctAnswer)))
            this.$('.js-correct-answer').css('display', 'inline-flex');
        }
    }

    /**
     * unCheckAnswer
     *
     * This function removes all the styling given on to the coordinates in the checkAnswer function.
     *
     */
    unCheckAnswer() {
        this.$el.removeClass(Styles['has-wrong-answer'])
            .removeClass(Styles['has-correct-answer']);

        this.$('.js-correct-answer').hide();
    }

    isCorrectAnswer(answerLabel, correctAnswer, variants) {
        const correctAnswers = [correctAnswer]
        if (Array.isArray(variants)) {
            correctAnswers.push(...variants)
        }

        return correctAnswers.includes(answerLabel)
    }
}
