var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-sidebar-outer no-print\">\n    <div class=\"js-sidebar-overlay "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"overlay") : stack1), depth0))
    + "\"></div>\n    <aside class=\"js-sidebar sidebar "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sidebar") : stack1), depth0))
    + "\">\n        <div class=\"js-header "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\">\n            <div class=\"js-sidebar-header-bar "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header__title") : stack1), depth0))
    + "\">\n                <span class=\"js-sidebar-label\" style=\"display:none\"></span>\n                <span class=\"js-sidebar-header\">"
    + alias2(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias3,"Title",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":62}}}))
    + "</span>\n            </div>\n            <button class=\"js-close-button "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header__close") : stack1), depth0))
    + "\" title=\""
    + alias2(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias3,"Close",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":9,"column":76},"end":{"line":9,"column":90}}}))
    + "\">"
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":9,"column":92},"end":{"line":9,"column":111}}})) != null ? stack1 : "")
    + "</button>\n        </div>\n\n        <div class=\"sidebar-content js-inner\"></div>\n    </aside>\n</div>\n";
},"useData":true});