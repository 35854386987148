import Styles from './Source14.scss';

import Template from './Source14.hbs';
import Util from 'util/util';

var Source14 = BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Object as defined by parent view
     */
    initialize(options) {

        // Get the task id from the source
        var taskId = this.model.get('source').taskId;

        // Create holder for the response model
        var responseModel;

        // Create a holder for the task model
        var taskModel;

        // Check if the work_on is defined, which means the caller is Learnbeat
        if (options.work_on) {

            // Get all the responses using the model from the work_on
            var responsesCollection = options.work_on.model.responses;

            // Get all the tasks using the model from the work_on
            var tasksCollection = options.work_on.model.tasks;

            // Find the corresponding task model for this task
            taskModel = tasksCollection.findWhere({
                id: taskId
            });

            // Check if there are any responses
            if (_.size(responsesCollection) > 0) {

                // Find the corresponding response model for this task
                responseModel = responsesCollection.findWhere({
                    task_id: taskId
                });
            }

            // Else check if activity model can be found, which means the caller is activity
        } else if (Backbone.Model.user.currentOpenActivity) {

            // Get the task group model from the activity model
            taskModel = Backbone.Model.user.currentOpenActivity.tasks.get(taskId);
        }

        // Check if the task model is set, if not we don't render anything
        if (taskModel) {

            // Create the view, passing the styling with it
            this.setElement(Template({
                Styles,
                sourceTitle: this.model.get('title'),
                taskIntroduction: Util.renderContentSafely(taskModel.get('introduction')),

                // Set the response text
                responseText: (

                // Check if there is a response model
                    responseModel &&

                            // And that the user is a student
                            Backbone.Model.user.get('is_student')
                ) ?

                // Show the given answer without htlm tags
                    Util.stripTags(responseModel.get('json_answer')) :

                // Else show the text that there isn't an answer given yet
                    false
            }));
        }
    },

});

export default Source14;
