import ActivityModel from 'models/ActivityModel';

export default class ActivitiesCollection extends Backbone.Collection {

    get model() {
        return ActivityModel
    }

    preinitialize() {
        this.constructor.type = 'activities'
    }

    comparator(model) {
        // Sort activities with sequence -1 (like the adaptive_student activity type)
        // at the bottom of every list it is displayed at.
        if (model.get('sequence') === -1) {
            return Infinity
        }
        return model.get('sequence')
    }

    setSequence(cidToSequenceArray = [], noCall) {
        const idToSequenceArray = cidToSequenceArray.map((cid, sequence) => {
            return this.get(cid).set({
                sequence,
            }).id
        })

        // noCall is a boolean whether the update sequence call should be triggered
        if (!noCall) {
            this.saveSequence(idToSequenceArray)
        }
    }

    saveSequence(activityIds) {
        return $.post('/activities/update_sequence.json', {
            activity_ids: activityIds,
        })
    }

}
