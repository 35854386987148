import Template22Table from 'views/components/taskGroups/tasks/template22/table/Table'
import InputCell from './InputCell.hbs'
import Util from 'util/util'

// Extends Template22Table with methods specific to Template39
export default class Template39Table extends Template22Table {

    static get validGapTypes() {
        return ['radio', 'checkbox']
    }

    get gapType() {
        const firstGapCell = this.tableData.find((cell) => cell.isGapCell && cell.gapType)
        if (firstGapCell && Template39Table.validGapTypes.includes(firstGapCell.gapType)) {
            return firstGapCell.gapType
        }
        return 'radio'
    }

    initialize({
        tableData = [],
        response,
        isReadOnly
    }) {

        this.tableData = tableData
        this.response = response

        this.setElement(this.generateTemplate(tableData))

        this.setupTable(isReadOnly)

    }

    // Override this function from Template22Table, since we don't need to for Template39Table.
    setupCellGapElement() {}

    /**
     * Set cell element content based on its corresponding cell data object.
     * If the cell is a gap, make this an input cell if it wasn't already and apply a state to
     * depending on the showAnswer and isReadOnly flags.
     *
     * @param {Object} cell cell data
     * @param {Boolean} showAnswer show correct answer for this gap
     * @param {Boolean} isReadOnly if true, set cell input element to a disabled state
     */
    setCellElementContent(cell, showAnswer, isReadOnly) {
        const cellElement = this.getCellElement(cell.rowIndex, cell.columnIndex)
        if (cell.isGapCell) {
            const gapCellInput = cellElement.querySelector('input')
            if (!gapCellInput) {
                cellElement.outerHTML = InputCell({
                    cell,
                    cid: this.cid,
                })
                this.setCellElementContent(cell, showAnswer, isReadOnly)
                return
            }
            gapCellInput.disabled = isReadOnly
            cellElement.dataset.isGap = true
            if (showAnswer) {
                gapCellInput.checked = cell.cellText
            } else if (this.response) {
                const cellResponse = this.response.find(c => c.row === cell.rowIndex && c.column === cell.columnIndex)
                gapCellInput.checked = cellResponse?.text ?? false
            }
        } else {
            delete cellElement.dataset.gapType
            delete cellElement.dataset.isGap
            cellElement.innerHTML = Util.renderContentSafely(cell.cellText) || ''
        }
    }

}
