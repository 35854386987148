import Template from './Superscript.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Superscript extends Button {

    // Custom command
    command = 'ToggleSuperscript'

    // Activate button state if <sup> tag is in editor selection/cursor.
    commandQueryName = 'Superscript'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Superscript');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        editor.addCommand(this.command, () => {
            // Request the state of the subscript form the editor, if true, toggle it off.
            if (editor.queryCommandState('Subscript')) {
                editor.execCommand('Subscript')
            }
            editor.execCommand('Superscript')
        })
    }

}
