export default class LtiModel extends Backbone.Model {
    url() {
        return `/activities/lti/${this.id}`
    }

    // Check if the user has edit rights for this lti activity
    canBeEdited() {
        if (this.get('user_id') === Backbone.Model.user.id) {
            return true
        }
        return false
    }
}
