import Styles from './Item.scss';

import Template from './Item.hbs';
import ContentLabel from 'views/components/contentLabel/ContentLabel.svelte'
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte'
import WeekLabel from '../../../weekLabel/WeekLabel'

export default class Item extends BaseView {

    initialize({ url, hasProgress, progress }) {

        this.setElement(Template({
            Styles,
            url
        }))

        if (this.model.get('week')) {
            this.addChildView(
                new WeekLabel({
                    model: this.model
                }),
                this.$el
            )
        } else {
            this.addSvelteChildView(
                this.el,
                ContentLabel,
                {
                    model: this.model,
                    truncateName: true,
                    smallText: true,
                },
            )
        }

        if (hasProgress) {
            this.addSvelteChildView(this.$el, ProgressBar, {
                fragments: {
                    blue: progress
                },
                showLabel: true,
                width: 40
            })
        }
    }

}
