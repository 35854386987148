import Styles from './TitleItem.scss';

import Template from './TitleItem.hbs';
import Util from 'util/util'

export default BaseView.extend({

    // Events for this titleitem
    events: {
        // Click on whole titleitem, call the callback which is bound to this view via the
        // options which are extended within the 'this' element
        click: 'callback'
    },

    initialize(options) {

        // Make options accessible within this
        _.extend(this, options);

        // Set the title
        var title = (

            // Check if title is set within options
            (options && options.title) ?

            // If set, use title from option, else use empty space
                options.title : ' '
        );

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            title: Util.stripScripts(title),
            hasBackIcon: options.index > 0,
            Styles
        }));
    }

});
