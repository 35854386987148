import Styles from './Item.scss';

import Template from './Item.hbs';
import ModifyAnnotationSidebarView from 'views/components/annotations/sidebar/ModifyAnnotationSidebarView';
import TimeUtil from 'util/TimeUtil';
import Util from 'util/util';
import RenderSpecialElements from 'util/RenderSpecialElements';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import InlineCrumblepath from 'views/components/inlineCrumblepath/InlineCrumblepath'

/*
#    ### AnnotationsContainer    ###
#
#    This view will create a container for an annotation. The container is the
#    block of each annotation. This container contains the path of a annotation
#    and the time on which the annotation has been created. And the annotation
#    content itself
#
*/

export default BaseView.extend({

    specialElementsConvertList: {
        // Convertion for HTML element: 'img'
        img: {
            // Default options
            global: {
                // Set template for the img tag
                template: DefaultImageElement
            }
        }
    },

    // The events for this view
    events: {
        // On click execute clickOnAnnotation function
        'click .js-note-content': 'clickOnAnnotation',
        'click .js-alter-annotatation-button': 'clickOnAnnotation',
        'click .js-ref-button': 'onClickRefButton'
    },

    // Init function
    initialize(options) {
        /*
        #
        #    All the stuff before rendering the template
        #
        */
        _.bindAll(this,
            'loadEditor',
            'onChangedVisibility',
            'onChangedSearchContent',
            'onRemovedAnnotation',
            'onChangedNoteContent',
            'onChangedPath'
        );
        this.options = options;
        this.groupModel = Backbone.Collection.groups.get(this.model.get('group_id'));
        this.layers = this.groupModel.get('layers')
        this.atType = options.atType;

        var authorName = window.i18n.gettext('Anonymous user');
        // If current user is author of the note.
        if (Backbone.Model.user.id === this.model.get('user_id')) {
            authorName = Backbone.Model.user.first_name_last_name();
        } else if (
            // If user is student and note is authored by a teacher.
            Backbone.Model.user.get('is_student') &&
            this.groupModel
        ) {
            var teacherAuthor = this.groupModel.get('teachers').get(this.model.get('user_id'));
            if (teacherAuthor) {
                authorName = teacherAuthor.first_name_last_name();
            }
        } else if (
            // If user is teacher and note is authored by a student.
            Backbone.Model.user.get('is_teacher')
        ) {
            var studentAuthor = Backbone.Collection.students.get(this.model.get('user_id'));
            if (studentAuthor) {
                authorName = studentAuthor.first_name_last_name();
            }
        }
        // Set listerners for this model
        this.listenTo(this.model, 'change:isVisible', this.onChangedVisibility);
        this.listenTo(this.model, 'change:searchContent', this.onChangedSearchContent);
        this.listenTo(this.model, 'change:note_content', this.onChangedNoteContent);
        this.listenTo(this.model, 'change:group_id', this.onChangedPath);
        this.listenTo(this.model, 'change:chapter_id', this.onChangedPath);
        this.listenTo(this.model, 'change:section_id', this.onChangedPath);
        this.listenTo(this.model, 'change:activity_id', this.onChangedPath);
        this.listenTo(this.model, 'change:task_group_id', this.onChangedPath);
        this.listenTo(this.model, 'change:task_group_sequence', this.onChangedPath);
        this.listenTo(this.model, 'change:Activity', this.onChangedPath);
        this.listenTo(this.model, 'remove', this.onRemovedAnnotation);

        /*
        #
        #    Render the template
        #
        */

        // Compile template with the user and annotation objects
        this.setElement(Template({
            Styles,
            annotation: {
                noteContent: Util.renderContentSafely(this.model.get('note_content')),
                dateAdded: TimeUtil.prettyDate(this.model.get('date_added'))
            },
            canEdit: (
                this.model.get('user_id') === Backbone.Model.user.id
            ),
            authorName,
            isSidebar: options.isSidebar
        }));

        RenderSpecialElements({}, this, this.$('.js-note-content'));

        /*
        #
        #    Add objects to the template
        #
        */
        this.noPath = options.noPath;
        this.taskgroupPath = options.taskgroupPath;
        this.onChangedPath();

        /*
        #
        #    Loading the data from collections and models
        #
        */
        // Check if isvisible is true, if so show this model, else hide
        if (this.model.get('isVisible') === false) {
            this.$el.hide();
        } else {
            this.$el.show();
        }

        /*
        #
        #    All the listeners
        #
        */

    },

    /*
    #
    #    Logic functions, below
    #
    */

    onClickRefButton() {

        const { typeUrl } = this.getPathInfo();

        Backbone.history.navigate(typeUrl, {
            trigger: true
        });
    },

    getPathInfo() {

        let type;
        let typeUrl;
        let pathModel;

        if (parseInt(this.model.get('task_group_id')) !== 0) {
            type = 'task group';
            typeUrl = 'activities/show/' + this.model.get('activity_id') + '/' + this.model.get('task_group_id');
            pathModel = Backbone.Collection.activities.get(this.model.get('activity_id'));
        } else if (parseInt(this.model.get('activity_id')) !== 0) {
            type = 'activity';
            typeUrl = 'activities/show/' + this.model.get('activity_id');
            pathModel = Backbone.Collection.activities.get(this.model.get('activity_id'));
        } else if (parseInt(this.model.get('section_id')) !== 0) {
            type = 'section';
            typeUrl = 'sections/show/' + this.model.get('section_id');
            pathModel = Backbone.Collection.sections.get(this.model.get('section_id'));
        } else if (parseInt(this.model.get('chapter_id')) !== 0) {
            type = 'chapter';
            typeUrl = 'chapters/show/' + this.model.get('chapter_id');
            pathModel = Backbone.Collection.chapters.get(this.model.get('chapter_id'));
        } else if (parseInt(this.model.get('group_id')) !== 0) {
            type = 'group';
            typeUrl = 'groups/show/' + this.model.get('group_id');
            pathModel = Backbone.Collection.groups.get(this.model.get('group_id'));
        }

        return { type, typeUrl, pathModel };
    },

    onChangedPath() {

        if (this.noPath) {
            return;
        }

        this.destroyChildViewsOfInstance(InlineCrumblepath);

        const { pathModel } = this.getPathInfo();

        this.addChildView(
            new InlineCrumblepath({
                pageType: 'annotate',
                model: pathModel
            }),
            '.js-annotation-path'
        );
    },

    // This function will be executed when annotation gets removed
    onRemovedAnnotation() {
        if (this.model.get('user_id') === Backbone.Model.user.get('id')) {
            // remove it from the dom
            this.$el.remove();
        }
    },

    // This function will be executed when an annotation gets a double click
    clickOnAnnotation() {
        // Check if the user is allowed to modify or delete this annotations
        if (this.model.get('user_id') === Backbone.Model.user.get('id')) {

            // Check if sidebar is open
            if (this.options.sidebarView) {

                // Load the editor
                this.loadEditor(this.options.sidebarView);

                // Hide the annotations
                parent.$('.js-annotations-view-container').hide();

            } else {
                // Load a new modify annotation sidebarview
                var modifyAnnotationSidebarView = new ModifyAnnotationSidebarView({
                    model: this.model
                });
                this.registerChildView(modifyAnnotationSidebarView);

                // Set title and width for sidebar
                var title = window.i18n.gettext('Modify Annotation');
                var width = 600;

                // Open the sidebar
                Backbone.View.sidebar.showSidebar(modifyAnnotationSidebarView, title, width);

                // Listen on the sidebar view when it is closed and remove all editors within.
                this.listenToOnce(Backbone.View.sidebar, 'sidebarClosed', function() {
                    window.tinyMCE.remove(_.map(
                        Backbone.View.sidebar.el.querySelectorAll('textarea[id^=tinyEditorview]'),
                        el => `#${el.id}`
                    ).join())
                });
            }
        }
        // Else if not allowed to modify annotations
        else {
            // Remove listeners to this view
            this.undelegateEvents();
        }
    },

    loadEditor(parent) {
        var editAnnotation = new ModifyAnnotationSidebarView({
            model: this.model
        });
        parent.$('.js-edit-annotation').append(editAnnotation.$el);

        if (editAnnotation.show !== undefined && typeof editAnnotation.show === 'function') {
            editAnnotation.show();
        }
        this.registerChildView(editAnnotation);
    },

    // This function will be executed when the visibility of an annotation changes
    onChangedVisibility(model, showModel) {
        // Check if show model is true, if so show this model, else hide
        if (showModel) {
            // this.$el.show();
            TweenMax.to(this.$el, {
                opacity: 1,
                y: 0,
                duration: 0.25,
                ease: 'back.inOut',
                display: 'block'
            })
        } else {
            TweenMax.to(this.$el, {
                opacity: 0,
                y: 10,
                duration: 0.25,
                ease: 'back.inOut',
                display: 'none'
            })
        }
    },

    // This function wil be called when the searchcontent of a annotation changes
    onChangedSearchContent(model) {
        // Check if the search content is filled
        if (model.get('searchContent').trim().length > 0) {
            // If the search content is filled, fill the annotation content with searchcontent
            this.$('.js-note-content').html(model.get('searchContent'));
            RenderSpecialElements({}, this, this.$('.js-note-content'))
        }

        // Else is the search content is not filled
        else {
            // If the search content is empty, change the annotation content with the original content
            this.onChangedNoteContent(model);
        }
    },

    onChangedNoteContent(model) {
        this.$('.js-note-content').html(Util.renderContentSafely(model.get('note_content')));
        RenderSpecialElements({}, this, this.$('.js-note-content'));
    }

});
