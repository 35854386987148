import Styles from './Lti.scss';

import Template from './Lti.hbs';
import Button from 'views/components/button/Button';
import BackgroundImage from 'views/pages/activities/show/backgroundImage/BackgroundImage';

export default BaseView.extend({

    show() {
        Backbone.View?.menubar?.setPreferredSize('small')
    },

    initialize() {

        this.setElement(Template({
            Styles,
            activityName: this.model.get('name'),
            providerName: this.model.getActivityTypeLabel()
        }));

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle()

        Backbone.View.header.setCrumblepath(
            this.model.getAllCrumblepathModels(),
            'show'
        )

        this.addChildView(
            new Button({
                label: window.i18n.gettext('Open external activity'),
                callback: () => {
                    if (!this.model.get('lti').get('url')) {
                        Backbone.View.layout.openStatus(
                            window.i18n.gettext('The external URL is empty')
                        );
                        return;
                    }
                    window.open('/activities/lti/' + this.model.id, '_blank')
                },
                size: 'medium',
                theme: 'secondary',
                iconRight: 'external-link',
            }), '.lti-button');

        this.backgroundImage = this.addChildView(
            new BackgroundImage({
                imageUrl: this.model.getBackgroundImage()
            }),
            Backbone.View.layout.$el
        );
    }
})
