import QuickInput from 'views/components/quickInput/QuickInput';
import Util from 'util/util';

export default class EmailInput extends QuickInput {

    initialize({
        emailCallback
    }) {

        const options = arguments[0]

        options.type = 'email';
        options.placeholder = window.i18n.gettext('Email address');
        options.keepValueOnCallback = true;

        // Execute the initialize from the QuickInput.
        super.initialize(options)

        this.emailCallback = emailCallback
        this.inputCallback = this.validateEmail;
        this.inputElement.on('keyup', this.onKeyUp);
        this.inputElement.attr('autocomplete', 'email');

    }

    /**
     * validateEmail
     *
     * Check if input is a valid email address.
     * If not, show a error status message to the user.
     * If valid, execute callback.
     *
     * @param {String} input    input field content
     * @return {boolean} true if valid email adress
     */
    validateEmail(input) {
        try {
            if (!input || !Util.validateEmail(input)) {
                throw 'invalidEmail';
            }
            if (this.emailCallback) {
                this.emailCallback(input.toLowerCase());
            } else {
                return true
            }
        } catch (e) {
            if (e === 'invalidEmail') {
                Backbone.View.layout.openStatus(window.i18n.gettext('You did not provide a valid email address.'));
                return false
            }
        }
    }

}
