import Styles from './NoteWorkOn.scss';

import Template from './NoteWorkOn.hbs';
import NoteReplyView from 'views/components/notes/reply/Reply';
import NoteReplyModel from 'models/NoteReplyModel';
import SendNoteSidebarView from 'views/components/notes/sidebar/SendNote';
import Button from 'views/components/button/Button'
import TimeUtil from 'util/TimeUtil';
import Textarea from 'views/components/textarea/Textarea.svelte'

export default BaseView.extend({

    shouldRender() {
        return ACL.isAllowed(ACL.resources.FEEDBACK, ACL.actions.VIEW, {type: 'workOn'})
    },

    /**
     * @param {Object} options
     * View options
     * @param {Backbone.View}  options.parentView
     * The view that instantiated this view
     *
     * @param {Backbone.View} [options.taskView]
     * A related task view. Undefined for general class feedback notes
     */
    initialize({
        parentView,
        taskView,
    }) {

        this.bindAll([
            'onClickSendReplyToNote',
            'onSuccesReply',
            'removeNote',
        ])

        var recipient;

        // parent could be an answer (if it's a whole class feedback) or student
        this.parentView = parentView
        this.taskView = taskView

        if (
            this.model.get('recipient_user_id') !== '0' &&
            this.model.get('recipient_user_id') !== 0
        ) {
            var recipient_model;
            if (Backbone.Model.user.get('is_student')) {
                recipient_model = Backbone.Model.user;
            } else {
                recipient_model = Backbone.Collection.students.get(this.model.get('recipient_user_id'));
            }
            recipient = recipient_model.first_name_last_name();
        } else {
            recipient = window.i18n.gettext('Whole class');
        }

        // If the message was sent by a teacher and you are a student or another teacher, show their name
        let sender
        if (this.model.get('sender_id') !== Backbone.Model.user.id) {
            sender = Backbone.Collection.teachers.get(this.model.get('sender_id'))?.first_name_last_name()
        }

        this.setElement(Template({
            Styles,
            sender,
            recipient,
            note: this.model.attributes,
            isStudent: Backbone.Model.user.get('is_student'),
            isSender: this.model.get('sender_id') === Backbone.Model.user.id,
            pretty_time: TimeUtil.prettyDate(this.model.get('created')),
            isGroupNote: !!this.model.get('group_id')
        }))

        this.$('.js-note-text').append(this.model.get('message'))

        if (this.model.note_replies.length > 0) {
            this.model.note_replies.each((note_reply) => {
                this.addChildView(new NoteReplyView({
                    model: note_reply
                }), '.js-replies-holder');
            })
        }

        if (Backbone.Model.user.get('is_student')) {

            this.studentReplyInput = this.addSvelteChildView(
                '.js-student-reply-input',
                Textarea,
                {
                    placeholder: window.i18n.gettext('Reaction'),
                    minLines: 1,
                },
            )

            this.sendButton = this.addChildView(new Button({
                label: window.i18n.gettext('Send'),
                callback: this.onClickSendReplyToNote,
                inline: true,
            }), '.js-student-reply-input')
        } else if (Backbone.Model.user.get('is_teacher')) {
            this.addChildView(new Button({
                label: window.i18n.gettext('Edit'),
                theme: 'transparent',
                icon: 'pencil',
                callback: () => this.onClickEditNote()
            }), '.js-edit-note')

            this.addChildView(new Button({
                label: window.i18n.gettext('Remove'),
                theme: 'transparent',
                icon: 'trash',
                callback: () => this.onClickRemoveNote()
            }), '.js-remove-note')
        }

    },

    onClickRemoveNote() {
        // Confirm if note should be removed.
        Backbone.View.layout.openConfirmStatus(
            window.i18n.gettext('Are you sure you want to remove this note?'),
            this.removeNote,
            undefined,
            window.i18n.gettext('Remove note')
        );
    },

    removeNote() {
        this.model.destroy();
        this.destroy();

        /**
         * If called from /users/notes (for Group notes), also close sidebar
         */
        if (this.model.get('group_id')) {
            Backbone.View.sidebar.closeSidebar()
        }
    },

    onClickEditNote() {

        /**
         * when editing a Group node, the teacher is already in a sidebar.
         * First close that sidebar before opening a new sidebar
         */
        if (Backbone.View.sidebar.isOpen) {
            Backbone.View.sidebar.closeSidebar()
        }

        var sendNoteSidebar = new SendNoteSidebarView({
            // recipient_user_id 0 == whole class
            recipient_user_id: this.model.get('recipient_user_id'),
            activity_model: this.taskView && this.taskView.work_on.model,
            task_view: this.taskView,
            parentView: this.parentView,
            sendToWholeGroup: !!this.model.get('group_id'),
            groupId: this.model.get('group_id'),
            model: this.model
        });

        this.registerChildView(sendNoteSidebar);

        // new view for input
        Backbone.View.sidebar.showSidebar(sendNoteSidebar, window.i18n.gettext('Send feedback'), 630);

    },

    onClickSendReplyToNote() {

        var message = this.studentReplyInput.getValue()

        if (message.length < 1) {
            Backbone.View.layout.openStatus(window.i18n.gettext('A reply can not be empty'));
        } else {

            // Toggle spinner with loading state to true and keep the text while loading
            this.sendButton.disable(true)

            var note_reply = new NoteReplyModel({
                message,
                note_id: this.model.get('id'),

            });

            this.model.note_replies.add(note_reply);

            note_reply.save(null, {success: this.onSuccesReply});
            return false;
        }
    },

    onSuccesReply(note_reply) {

        var noteReplyView = this.addChildView(new NoteReplyView({
            model: note_reply
        }), '.js-replies-holder');
        TweenMax.from(noteReplyView.$el, {duration: 1, height: 0, paddingTop: 0})

        this.studentReplyInput.clearValue()

        setTimeout(() => { this.sendButton.enable() }, 500)
    }

});
