import Styles from './NavigationIcon.scss';

import Template from './NavigationIcon.hbs';
var NavigationIcon = BaseView.extend({

    events: {
        click: 'clickCallback'
    },

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options as defined by parent
     */
    initialize(options) {

        // Check if the clickCallback is set
        if (options.clickCallback) {

            // Make clickCallback accessible within methods
            this.clickCallback = _.partial(options.clickCallback, this);

        }

        // Make index accessible for methods
        this.index = options.index;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            icon: options.icon,
            hasNoClick: _.isUndefined(this.clickCallback),
            isAssignmentIcon: options.icon === 'assignment',
            isCheckmarkIcon: options.icon === 'checkmark-circle',
        }));

        // Make action accessible within methods
        this.action = options.buttonAction;
    },

    /**
     * getAction
     *
     * This method will return the action of the button
     *
     * @return {?}     It will return the action, whatever is in it
     */
    getAction() {
        return this.action;
    },

    /**
     * setDeactive
     *
     * This method will make the item deactive by removing the active class
     *
     */
    setDeactive() {

        // Remove the active class from the element
        this.$el.removeClass(Styles['nav-icon--active']);
    },

    /**
     * setActive
     *
     * This method will make the item active by adding the active class
     *
     */
    setActive() {

        // Add the active class to the element
        this.$el.addClass(Styles['nav-icon--active']);
    },

});

export default NavigationIcon;
