import Styles from './Draggable.scss';

import Template from './Draggable.hbs';
import Util from 'util/util';

export default class Draggable extends BaseView {

    initialize(options) {

        // Label needs to be bound to the view, for when a placed draggable needs to be returned
        // to its container.
        this.label = options.label;
        this.parent = options.parent;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            label: Util.renderContentSafely(Util.unescape(options.label)),
        }));

        this.$el.draggable({
            revert: true,
            classes: {
                'ui-draggable': Styles.draggable
            },

            // Make sure cursor is at exact middle of coordinate when dragging
            cursorAt: {
                top: 17,
                left: 7
            },

            // user can only drop the element within the task
            scope: this.parent.model.id,
            scrollSensitivity: 60,
            scrollSpeed: 40

        });
    }

    disableDrag(callback) {
        this.$el.draggable({
            disabled: true
        });
        this.$el.on('click', callback);
    }

    enableDrag(callback) {
        this.$el.draggable({
            disabled: false
        });
        this.$el.off('click', callback);
    }

}
