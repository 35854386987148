import Template from './ActivityNavigation.hbs';
import TaskGroupItem from 'views/pages/activities/show/sidebars/activityNavigation/item/Item'

export default class ActivityNavigation extends BaseView {

    /**
     * @param {Object} options options
     * @param {Backbone.View} options.work_on Linear Activity view
     */
    initialize({
        work_on,
    }) {

        this.$el.html(Template({}))

        this.model.task_groups.forEach((taskGroup, index) => {

            this.addChildView(new TaskGroupItem({
                model: taskGroup,
                activity_model: this.model,
                num: index,
                work_on,
            }), '.js-progress-holder')

        })

        // Scroll the sidebar so that the active task group is visible
        this.listenToOnce(Backbone.View.sidebar, 'sidebarIsOpen', () => {
            const activeItem = this.el.querySelector('[data-active=true]')
            activeItem?.scrollIntoView({ block: 'center' })
        })

    }

}
