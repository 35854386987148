var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"cloudStorageUrl") : depth0), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "target=\"_blank\" href=\"/edu_files/open/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileId") : depth0), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileIdHash") : depth0), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"file-icon") : stack1), depth0))
    + "\">\n            "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"source-3",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":34}}})) != null ? stack1 : "")
    + "\n            <span class=\"js-preview-title\" data-empty-label=\""
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Nameless file",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":83}}}))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</span>\n        </div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"file-label") : stack1), depth0))
    + "\">\n            "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"file-download",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":39}}})) != null ? stack1 : "")
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Download",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":56}}}))
    + "\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"not-found") : stack1), depth0))
    + "\">\n            "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias2,"warning",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":33}}})) != null ? stack1 : "")
    + " "
    + alias1(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias2,"Can't find a file related to this source.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":12,"column":34},"end":{"line":12,"column":84}}}))
    + "\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"file-url") : stack1), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cloudStorageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":103}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fileId") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":104},"end":{"line":1,"column":189}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFile") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "</a>\n";
},"useData":true});