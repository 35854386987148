var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"contextUrl") : depth0), depth0))
    + "\" class=\"js-image-element\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lightbox") : stack1), depth0))
    + " js-lightbox-outer js-background\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lightbox__container") : stack1), depth0))
    + " js-lightbox-container js-dont-close-sidebar\">\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lightbox__close") : stack1), depth0))
    + " js-close-lightbox\" title=\""
    + alias2(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias3,"Close",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":76},"end":{"line":3,"column":90}}}))
    + "\">\n            "
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":31}}})) != null ? stack1 : "")
    + "\n        </button>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lightbox--image") : stack1), depth0))
    + " js-image-container js-background\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"contextUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});