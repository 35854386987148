import GroupModel from 'models/GroupModel';

export default class GroupsCollection extends Backbone.Collection {

    get model() {
        return GroupModel
    }

    preinitialize() {
        this.constructor.type = 'groups'
    }

    comparator(groupA, groupB) {

        // Teachers can have favorite groups, these should go first
        if (Backbone.Model.user.get('is_teacher')) {
            if (groupA.get('is_favorite') !== groupB.get('is_favorite')) {
                return groupB.get('is_favorite') - groupA.get('is_favorite');
            }
        }

        let nameA = groupA.get('name')
        let nameB = groupB.get('name')
        // Sort groups by course name if user is a student, since for students groups are often displayed by their
        // course name, not the actual group name.
        if (Backbone.Model.user.get('is_student')) {
            nameA = groupA.getCourseModel() ? groupA.getCourseModel().get('name') : groupA.get('name')
            nameB = groupB.getCourseModel() ? groupB.getCourseModel().get('name') : groupB.get('name')
        }

        return nameA.localeCompare(nameB, window.app_version.language, {
            sensitivity: 'base',
            numeric: true
        })
    }

    getCourseIds() {
        return _.uniq(this.pluck('course_id'));
    }

    getLevelIds() {
        return _.uniq(this.pluck('level_id'));
    }

    getGeneralLevelIds() {
        var generalLevelIds = [];

        _.each(this.getLevelIds(), (levelId) => {
            var levelModel = Backbone.Collection.levels.get(levelId);

            if (levelModel) {
                generalLevelIds = generalLevelIds.concat(levelModel.get('general_level_ids'));
            }
        });

        return _.uniq(generalLevelIds);
    }

    getGrades() {
        return _.uniq(this.pluck('grade'));
    }

}
