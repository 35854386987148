var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " source--optional";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"js-optional-source\">\n			<div class=\"center-panel\">\n				<div class=\"optional-source\" title=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"sourceType") : depth0), depth0))
    + "\">\n					<div>\n						<div>"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"sourceIcon") : depth0),{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":33}}})) != null ? stack1 : "")
    + "</div>\n						<div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n					</div>\n					<div class=\"optional-source__arrow\">"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias2,"arrow-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":10,"column":41},"end":{"line":10,"column":65}}})) != null ? stack1 : "")
    + "</div>\n				</div>\n			</div>\n		</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " center-panel";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<article class=\"source "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_additional") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":68}}})) != null ? stack1 : "")
    + "\" data-source-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_additional") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":14,"column":8}}})) != null ? stack1 : "")
    + "	<div class=\"js-content"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInvalidTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":23},"end":{"line":15,"column":70}}})) != null ? stack1 : "")
    + "\"></div>\n</article>\n";
},"useData":true});