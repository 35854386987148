import Template from './Analytics.hbs';
import Tabs from 'views/components/tabs/Tabs'
import AnalyticsStyles from 'views/components/taskGroups/tasks/analytics/Analytics.scss'
import Template2AnalyticsAnswerList from './AnalyticsSummary'

export default class Template2Analytics extends Tabs {

    initialize({
        mistakes = [],
        corrects = []
    }) {

        this.setElement(Template({
            Styles: AnalyticsStyles,
        }))

        this.tabs = [
            {
                type: 'mistakes',
                label: window.i18n.gettext('Latest 20 wrong answers'),
                view: Template2AnalyticsAnswerList,
                viewParameters: {
                    list: mistakes,
                },
            },
            {
                type: 'correct',
                label: window.i18n.gettext('Latest 20 correct answers'),
                view: Template2AnalyticsAnswerList,
                viewParameters: {
                    list: corrects,
                },
            }
        ]
        this.addTabs(true)
    }
}
