import Styles from './Results.scss';

import Template from './Results.hbs';
import Button from 'views/components/button/Button'

export default BaseView.extend({

    initialize(options) {
        this.listId = options.listId

        this.options = options

        if (!options) {
            return
        }

        const firstTry = options.result['first-try']
        const secondTry = options.result['second-try']
        const moreThanTwo = options.result['more-than-two']
        const all = firstTry + secondTry + moreThanTwo

        this.setElement(Template({
            Styles,
            firstTry,
            secondTry,
            moreThanTwo,
            allCorrect: options.type === 'cards' ? all : false
        }))

        if (options.type !== 'cards') {
            this.el.querySelector('#firstTry #segment').style.strokeDasharray = `${firstTry / all * 100} ${(all - firstTry) / all * 100}`
            this.el.querySelector('#secondTry #segment').style.strokeDasharray = `${secondTry / all * 100} ${(all - secondTry) / all * 100}`
            this.el.querySelector('#moreThanTwo #segment').style.strokeDasharray = `${moreThanTwo / all * 100} ${(all - moreThanTwo) / all * 100}`
        }

        this.addChildView(new Button({
            label: window.i18n.gettext('Close'),
            icon: 'cross',
            callback: options.closeCallback
        }), '.js-close-button')

    }

});
