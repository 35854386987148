var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-item "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"item") : stack1), depth0))
    + "\">\n    <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"contentUrl") || (depth0 != null ? lookupProperty(depth0,"contentUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"contentUrl","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":27}}}) : helper)))
    + "\" class=\"js-content-url\"></a>\n    <a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"progressUrl") || (depth0 != null ? lookupProperty(depth0,"progressUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"progressUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":28}}}) : helper)))
    + "\" class=\"js-progress-url\"></a>\n</div>";
},"useData":true});