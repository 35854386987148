import Styles from './Category.scss';

import Template from './Category.hbs';
import Util from 'util/util';
import Item from 'views/components/taskGroups/tasks/template28/item/Item';
import 'jquery-ui/ui/widgets/droppable';

export default BaseView.extend({

    events: {
        'click .js-item': 'onRemoveItem'
    },

    initialize(options) {

        _.bindAll(
            this,
            'onDrop',
            'onAddItem'
        );

        this.categoryAnswer = options.categoryAnswer;
        this.itemsCollection = options.itemsCollection;

        this.setElement(Template({
            Styles,
            name: Util.renderContentSafely(this.model.get('name'))
        }));

        // Create droppable area that accepts elements with the .js-item class.
        this.$el.droppable({
            accept: '.js-item',
            tolerance: 'pointer',
            drop: this.onDrop,
        });

        // If answer is given, populate this category view with the list items he/she already placed in this
        // category. Remove these items from this.itemsCollection so they don't appear in the right column.
        this.categoryAnswer.get('items').each((model) => {
            this.itemsCollection.remove(this.itemsCollection.where({
                id: model.id
            }))
            this.onAddItem(model)
        })

    },

    /**
     * onDrop
     *
     * Trigger when an element with the class .js-item gets dropped on the element with the .js-drop-target class.
     *
     * @param  {Event} e      event data.
     * @param  {Object}       ui jQuery UI draggable event data.
     */
    onDrop(e, ui) {

        var dragItem = $(ui.draggable);

        // Remove dropped item from the items collection and add it to the items collection of this category.
        var categoryAnswerItemCollection = this.categoryAnswer.get('items');
        var itemModel = this.itemsCollection.get(dragItem.data('id'));
        // Check if itemModel actually exists in this.itemsCollection.
        // This is to prevent trying to add the same item to multiple categories.
        if (itemModel) {
            categoryAnswerItemCollection.add(itemModel);
            this.itemsCollection.remove(itemModel);

            // Create a new item view inside this category view.
            this.onAddItem(itemModel);
        }

        // Hide the dragged item now it's replaced with another item view.
        dragItem.hide();

    },

    /**
     * onAddItem
     *
     * Create new item view inside category.
     *
     * @param  {Backbone.Model} model item data model
     */
    onAddItem(model) {
        const itemView = this.addChildView(new Item({
            isInCategory: true,
            model
        }), '.js-category-items')
        model.view = itemView;
    },

    /**
     * onRemoveItem
     *
     * When user clicks on an item view within this category view, remove said item from the currentTarget
     * category item collection and destroy the item view.
     *
     * @param  {Event} e    click event
     */
    onRemoveItem(e) {
        const target = e.currentTarget
        if (!target.classList.contains('js-show-answers')) {
            var categoryAnswerItemCollection = this.categoryAnswer.get('items');
            var itemModel = categoryAnswerItemCollection.get(target.dataset.id)
            this.unregisterAndDestroyChildView(itemModel.view);
            categoryAnswerItemCollection.remove(itemModel);
            this.itemsCollection.add(itemModel);
        }
    },

    /**
     * lock
     *
     * This function blocks any user input events that can manipulate this view.
     *
     */
    lock() {
        this.undelegateEvents();

        _.invoke(this.getChildViewsOfInstance(Item), 'lock');
    },

    /**
     * unlock
     *
     * This function re-enables listening to user input.
     *
     */
    unlock() {
        this.delegateEvents();

        _.invoke(this.getChildViewsOfInstance(Item), 'unlock');

    }

});
