import ChaptersCollection from 'collections/ChaptersCollection'
import StudentsCollection from 'collections/StudentsCollection';
import AnnotationsCollection from 'collections/AnnotationCollection';
import TeacherCollection from 'collections/TeacherCollection';
import SectionsCollection from 'collections/SectionsCollection'
import ActivitiesCollection from 'collections/ActivitiesCollection'

export default class GroupModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'group'
        this.constructor.pluralType = 'groups'
    }

    initialize() {

        // Add chapters to the global collection and this group.
        this.chapters = new ChaptersCollection(
            Backbone.Collection.chapters.push(this.get('Chapter'), {
                silent: true,
                parse: true
            })
        )
        this.unset('Chapter', {
            silent: true
        })

        // Add teachers to the global collection and this group.
        this.set('teachers', new TeacherCollection(Backbone.Collection.teachers.add(this.get('Teachers'))));
        this.unset('Teachers');

        // Add theory collections to the global collection and this group.
        this.theoryBooks = new Backbone.Collection(
            Backbone.Collection.theoryBooks.add(this.get('TheoryCollections'))
        )
        this.unset('TheoryCollections');

        this.annotations = new AnnotationsCollection();

        // Add students to the global collection and this group.
        this.students = new StudentsCollection(Backbone.Collection.students.add(this.get('Student')));
        this.unset('Student');

        this.publishedContent = new Backbone.Collection(this.get('PublishedContent'))
        this.unset('PublishedContent')

    }

    /**
         * getParent
         *
         * Return parent model. Since groups do not have a real parent, return undefined.
         */
    getParent() {}

    /**
         * getChildren
         *
         * Return child collection in the order of content. These are chapters in the case of a group.
         *
         * @return {ChaptersCollection}  Chapters connected to this group.
         */
    getChildren() {
        const layers = this.get('layers')

        if (layers === 3) {
            return this.chapters
        }

        if (this.needsDummies()) {
            if (layers === 2) {
                return new SectionsCollection()
            } else if (layers === 1) {
                return new ActivitiesCollection()
            }
        }

        const dummyChapter = this.getDummyChapter()
        const sections = dummyChapter.sections

        if (layers === 2) {
            return sections
        }

        return sections.findWhere({ chapter_id: dummyChapter.id }).activities
    }

    /**
         * getSiblings
         *
         * Get collection of sibling models. In the case of a GroupModel these are all other groups.
         *
         * @return {GroupsCollection}    All groups
         */
    getSiblings() {
        return Backbone.Collection.groups;
    }

    getCrumblepathModel() {
        return new Backbone.Model({
            label: Backbone.Model.user.get('is_student') && this.getCourseModel() ? this.getCourseModel().get('name') : this.get('name'),
            level: 'groups',
            path_id: this.id,
            isHidden: this.isHidden(),
        });
    }

    getAllCrumblepathModels() {
        return [
            this.getCrumblepathModel()
        ]
    }

    getCourseModel() {
        return Backbone.Collection.courses.get(this.get('course_id'));
    }

    getGroupModel() {
        return this
    }

    getJoinLink() {
        return location.origin + '/join/' + this.get('join_code');
    }

    getTeacherMaterial() {
        return new Backbone.Collection(
            this.publishedContent.filter((publishedContent) => publishedContent.get('teacher_material'))
        )
    }

    // Get the background image of the group (via course)
    getBackgroundImage() {
        return this.getCourseModel()?.getBackgroundImage()
    }

    // Get a random header image for the group (via course)
    getHeaderImage() {
        return this.getCourseModel()?.getHeaderImage()
    }

    isHidden() {
        return this.get('layers') < 3
    }

    /**
     * If the current layer is the root layer.
     *
     * @param {boolean} excludeGroupLevel
     * if top level (group level) should be excluded
     * @returns {boolean} if this layer is the root later
     */
    isRootLayer(excludeGroupLevel = false) {
        if (excludeGroupLevel) {
            return false
        }
        return this.get('layers') === 3
    }

    getPath(type, withoutId = false) {
        const base = `/groups/${type}`

        if (withoutId) {
            return base
        }
        return `${base}/${this.id}`
    }

    /**
     * @param {number} layers new layer amount
     * @returns {Promise<Object>} response json
     */
    changeLayers(layers) {
        return new Promise((resolve, reject) => {
            $.post('/groups/change_group.json', {
                group_id: this.id,
                layers,
            }).done(
                data => resolve(data)
            ).fail(reject)
        })
    }

    /**
     * @returns {Backbone.Model} dummy chapter for groups with less than 3 layers
     */
    getDummyChapter() {
        return Backbone.Collection.chapters.findWhere({ group_id: this.id })
    }

    /**
     * @returns {Backbone.Model} dummy section for 1 layer groups
     */
    getDummySection() {
        return Backbone.Collection.sections.findWhere({
            chapter_id: this.getDummyChapter().id
        })
    }

    /**
     * the root layer of a group:
     *
     * group model for 3 layers, chapter model for 2 layers
     * and section model for 1 layer groups
     *
     * @returns {Backbone.Model} model for the root layer
     */
    getRootLayer() {
        if (this.needsDummies()) {
            return null
        }

        switch (this.get('layers')) {
            case 3:
                return this
            case 2:
                return this.getDummyChapter()
            case 1:
                return this.getDummySection()
        }
    }

    /**
     * Check if group needs dummies. A group with less than 3 layers needs to
     * have a dummy chapter to be considered fully initalized
     *
     * @returns {boolean} if group needs dummies
     */
    needsDummies() {
        if (this.get('layers') === 3) {
            return false
        }
        return !this.getDummyChapter()
    }

    /**
     *
     * Creates the necessary dummies for the amount of layers
     *
     * @param {Object} activityInfo activity info (name, activity type, etc.)
     * @returns {Backbone.Model} the root layer
     */
    async createDummies(activityInfo) {
        if (!this.needsDummies()) {
            return
        }

        const collection = this.get('layers') === 2 ?
            Backbone.Collection.sections :
            Backbone.Collection.activities

        const attributes = {
            ...activityInfo,
            group_id: this.id,
            // important! by sending parent_id null, the backend knows
            // to create dummy levels above the current layer
            parent_id: null,
            sequence: 0,
        }
        await new Promise(resolve => {
            collection.create(attributes, {
                wait: true,
                success: () => {
                    resolve()
                }
            })
        })

        await Backbone.Model.user.fetchCollections()
        return this.getRootLayer()

    }

}
