var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"show-answer-tooltip") : stack1), depth0))
    + " js-answer-tooltip\">\n    <div>\n        "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"checkmark",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":31}}})) != null ? stack1 : "")
    + "\n        <div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"tooltipText") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n    </div>\n</div>\n";
},"useData":true});