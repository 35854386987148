import Util from 'util/util'

export default class StudentModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'student'
        this.constructor.pluralType = 'students'
    }

    initialize() {}

    url() {
        return `/users/change_password_for_user/${this.get('id')}.json`;
    }

    last_name_first_name() {
        return Util.lastNameFirstName(this.get('first_name'), this.get('prefix'), this.get('sortable_last_name'))
    }

    first_name_last_name() {
        return Util.firstNameLastName(this.get('first_name'), this.get('prefix'), this.get('sortable_last_name'))
    }

    getCrumblepathModel() {
        return new Backbone.Model({
            label: this.first_name_last_name(),
            level: 'students',
            path_id: this.get('id')
        });
    }

    getChildren() {}

    /**
     * getSiblings
     *
     * Get collection all students within the same group.
     *
     * @returns {StudentsCollection} student collection
     */
    getSiblings() {
        return this.collection;
    }

    /**
     * nextStudent
     *
     * Get next student in global collection or a particular group.
     *
     * @param {Number} groupId get the next student within a particular group
     * @returns {StudentModel} next student
     */
    nextStudent(groupId) {
        return (groupId ? Backbone.Collection.groups.get(groupId).students : this.collection).getNext(this);
    }

    /**
     * previousStudent
     *
     * Get previous student in global collection or a particular group.
     *
     * @param {Number} groupId get the previous student within a particular group
     * @returns {StudentModel} previous student
     */
    previousStudent(groupId) {
        return (groupId ? Backbone.Collection.groups.get(groupId).students : this.collection).getPrevious(this);
    }
}
