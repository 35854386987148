import Styles from './TourToolTip.scss';

import Template from './TourToolTip.hbs';
export default BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options   Object as passed from parent
     *
     */
    initialize(options) {

        _.bindAll(this,
            'closeTooltip'
        );

        // Make left accessible within methods
        this.left = options.left;

        // Make target accessible for methods
        this.target = options.target;

        // Check if target is present on the page
        if ($(this.target).length === 0) {
            window.sentry.captureMessage('No target found for tour tooltip', {level: 'error'})
            return
        }

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            message: options.message,
            left: this.left
        }));

        // Check if the target is a fixed target
        if (options.targetIsFixed) {

            // Set the position for the tooltip to fixed
            this.$el.css('position', 'fixed');

            this.targetIsFixed = options.targetIsFixed;
        }

        // Add this view to the body
        this.appendTo($('body'));

        // add Tooltip when the page is loaded to position tooltip properly
        setTimeout(_.bind(this.positionTooltip, this), 500);

        // Add a click event listener to the target
        $(this.target).on('click', this.closeTooltip);

        // close the tooltip when navigating to other page
        this.onRoute(() => {
            this.closeTooltip();
        });

    },

    /**
         * closeTooltip
         *
         * This function will close the tooltip when the item to which it should bind
         * is clicked
         *
         */
    closeTooltip() {
        // Trigger an event to which other views can listen
        this.trigger('tourTooltipClosed');

        // Remove the click event listener to the target
        $(this.target).off('click', _.bind(this.closeTooltip, this));

        // Animate the icon
        TweenMax.to(this.$('.js-tooltip-icon'), {
            opacity: 0,
            x: (this.left) ? -50 : 50,
            duration: 0.2,
            ease: 'back.in(2)'
        });

        // Animate the label
        TweenMax.to(this.$('.js-tooltip-label'), {
            opacity: 0,
            x: (this.left) ? -50 : 50,
            ease: 'power4.out',
            delay: 0.1,
            duration: 0.2,
            onComplete: () => {
                // Unregister and destroy this TourTooltip view from LayoutView.
                Backbone.View.layout.unregisterAndDestroyChildView(this);
            }
        });
    },

    /**
         * positionTooltip
         *
         * This function will position the tooltip correctly to the target
         *
         */
    positionTooltip() {
        // Get the element to attach on
        var ElementToAttachOn = $(this.target);

        // Create an object with all dimensions for the element to attach on
        var AttachElement = {

            // Get the size for this element
            size: {

                // Get width
                width: ElementToAttachOn.outerWidth(),

                // Get height
                height: ElementToAttachOn.outerHeight(),
            },

            // Get the offset for this element
            offset: ElementToAttachOn.offset()
        };
            // After position show the tooltip
        this.$el.css('display', 'flex');

        // Set the top position of the tour tooltip
        this.$el.css('top', (
            AttachElement.offset.top + (
                AttachElement.size.height / 2
            ) - (
            // If the tool tip is fixed, subtract the scrolltop
                (this.targetIsFixed) ?
                    $(document).scrollTop() : 0
            )
        ));

        var left = AttachElement.offset.left +
                (
                    (this.left) ? -(this.$el.outerWidth(true)) : AttachElement.size.width
                );

        // If the left is negative, make it 0
        if (left < 0) {
            left = 0;
        }
        // Set the left position of the tour tooltip
        this.$el.css('left', left);

        /*
             * Do some animations
             */

        TweenMax.set(this.$('.js-tooltip-label,.js-tooltip-icon'), {
            opacity: 0.3,
            x: (this.left) ? -50 : 50
        });

        TweenMax.to(this.$('.js-tooltip-icon'), {
            opacity: 1,
            x: 0,
            duration: 0.2,
            ease: 'back.out(2)'
        });

        TweenMax.to(this.$('.js-tooltip-label'), {
            opacity: 1,
            x: 0,
            duration: 0.2,
            ease: 'power4.out',
            delay: 0.005
        });

        /*
             * End of animations
             */

        TweenMax.to(this.$('.js-tooltip-label,.js-tooltip-icon'), {
            x: 5,
            repeat: -1,
            duration: 0.8,
            delay: 0.3,
            yoyo: true
        });

    }

});
