<script>
    import Button from '../button/Button.svelte';
    import Icon from '../icon/Icon.svelte'
    import Spinner from '../spinner/Spinner.svelte';

    export function hide() {
        reset()
    }

    export function showLoading() {
        reset()
        showSpinner = true
    }

    export function showError(options) {
        reset()
        message = options.message
        buttonOptions = options.buttonOptions
    }

    let showSpinner, message, buttonOptions
    reset()

    function reset() {
        showSpinner = false
        message = ''
        buttonOptions = undefined
    }
</script>

{#if showSpinner || message || buttonOptions}
    <div class="page no-print">
        {#if showSpinner}
            <div class="spinner">
                <Spinner></Spinner>
            </div>
        {/if}

        {#if message}
            <div class="message">
                <Icon name="dead"></Icon>
                {message}
            </div>
        {/if}

        {#if buttonOptions}
            <Button {...buttonOptions}></Button>
        {/if}
    </div>
{/if}

<style lang="scss">
    @import 'src/styling/globals.scss';

    .page {
        @include z-index-primary-navigation;

        position: fixed;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $background-gray;
    }

    .message {
        @include large-text;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 0 16px 24px 16px;
        text-align: center;

        :global(svg) {
            width: 64px;
            fill: $status-gray;
        }
    }

    .spinner {
        animation: intro 1s ease;
        color: $blue-black-80;

        @keyframes intro {
            0% {
                opacity: 0;
            }

            70% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
</style>