import Styles from './Item.scss';

import Template from './Item.hbs';
import ContentLabel from 'views/components/contentLabel/ContentLabel.svelte'
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte'
import WeekLabel from '../../../weekLabel/WeekLabel'

export default class Item extends BaseView {

    initialize({ contentUrl, hasProgress, progress, progressUrl }) {

        this.setElement(Template({
            Styles,
            contentUrl,
            progressUrl
        }))

        if (this.model.get('week')) {
            this.addChildView(
                new WeekLabel({
                    model: this.model
                }),
                this.$('.js-content-url')
            )
        } else {
            this.addSvelteChildView(this.$('.js-content-url'), ContentLabel, {
                model: this.model,
                truncateName: true,
                smallText: true,
            })
        }

        if (hasProgress && !ISMOBILE) {
            this.addSvelteChildView(this.$('.js-progress-url'), ProgressBar, {
                fragments: {
                    blue: progress
                },
                showLabel: true,
                width: 40
            })
        }
    }

}
