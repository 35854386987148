import Styles from './UploadImage.scss';

import Template from './UploadImage.hbs';
import UploadModel from 'models/UploadModel';
import Checkbox from 'views/components/checkbox/Checkbox.svelte';
import QuickInput from 'views/components/quickInput/QuickInput.svelte'
import QuickSelect from 'views/components/quickSelect/QuickSelect.svelte'
import Spinner from 'views/components/spinner/Spinner';
import ShapeList from 'util/ShapeLoader';
import Textarea from 'views/components/textarea/Textarea.svelte'
import Button from 'views/components/button/Button'

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onChangeImageURL',
            'onChangeImageUploadStatus',
            'onChangeTitle',
            'onChangeSource',
            'onChangeCaption',
            'setImageSize',
            'setImagePosition'
        );

        this.setElement(Template({
            Styles
        }));

        // Only use element at the cursor if the element is an <img> and is contained in a expanded selection.
        // In Firefox, when the cursor is next to an <img> tag, it considers it as selected, even though it's
        // not contained in a selection range. To prevent this, only consider images that are inside of a selection
        // range as the "cursor element".
        this.cursorElement = options.atElement.nodeName === 'IMG' && !options.selection.isCollapsed() ?
            $(options.atElement) : $()

        this.simpleImageStyling = options.simpleImageStyling;

        this.chosenOptions = {
            // If we don't have an image yet, enable fullscreen by default.
            fullScreen: this.cursorElement.is('img') ? this.cursorElement.data('allow-fullscreen') : true,
        }

        // Image title input
        this.addSvelteChildView(
            '.js-settings',
            QuickInput,
            {
                label: window.i18n.gettext('Title reference for this image'),
                placeholder: window.i18n.gettext('Title reference for this image'),
                value: this.cursorElement.data('image-title'),
                inputCallback: this.onChangeTitle,
            },
        )
        this.onChangeTitle(this.cursorElement.data('image-title'))

        // Image source URL input
        this.addSvelteChildView(
            '.js-settings',
            QuickInput,
            {
                label: window.i18n.gettext('Source reference for this image'),
                placeholder: window.i18n.gettext('Source reference for this image'),
                value: this.cursorElement.data('image-resource'),
                inputCallback: this.onChangeSource,
            },
        )
        this.onChangeSource(this.cursorElement.data('image-resource'))

        // Image caption input
        this.addSvelteChildView(
            '.js-settings',
            Textarea,
            {
                value: this.cursorElement.data('image-caption'),
                label: window.i18n.gettext('Caption for this image'),
                placeholder: window.i18n.gettext('Caption for this image'),
                inputCallback: this.onChangeCaption,
            },
        )
        this.onChangeCaption(this.cursorElement.data('image-caption'))

        this.fullScreenCheckbox = this.addSvelteChildView('.js-settings', Checkbox, {
            isChecked: this.chosenOptions.fullScreen,
            label: window.i18n.gettext('Show fullscreen button'),
            callback: (state) => {
                console.log('callback', state)
                this.chosenOptions.fullScreen = state
            }
        })

        this.addChildView(new Button({
            label: window.i18n.gettext('Upload an image'),
            icon: 'file-upload',
        }), '.js-image-placeholder')

        // Create a new upload model
        this.uploadModel = new UploadModel({

            // Bind it to the following element
            element: this.$('.js-image-preview'),

            // Set the upload type
            uploadType: options.uploadType || 'wysiwyg-image',

            accept: 'image/*',

            customUploadStatusHandler: this.onChangeImageUploadStatus

            // Add event listeners with callbacks
        }).on('change:url', this.onChangeImageURL);

        // When it is a image we need to set the settings according to the previous settings.
        if (this.cursorElement.is('img')) {

            this.$('.js-image-placeholder').hide();

            /**
             * match both id only (legacy images), and id + hash:
             *     /edu_files/123456
             * and
             *     /edu_files/123456/abc123
             */
            this.chosenOptions.filesId = this.cursorElement.attr('src').match(/\d+(.+)?/)[0]

            this.appendImage(this.chosenOptions.filesId);

            /*
             * Check for position
             */
            // Check if is the image has the class source__inline-image-author--left
            if (this.cursorElement.hasClass('source__inline-image-author--left')) {
                this.setImagePosition('left');
                // Check if the image has the class source__inline-image-author--right
            } else if (this.cursorElement.hasClass('source__inline-image-author--right')) {
                this.setImagePosition('right');
                // Else it is middle
            } else {
                this.setImagePosition('middle');
            }

            /*
             * Check for sizing
             */
            // Check if is the image has the class source__inline-image-author--small
            if (this.cursorElement.hasClass('source__inline-image-author--small')) {
                this.setImageSize('small');
                // Check if the image has the class source__inline-image-author--large
            } else if (this.cursorElement.hasClass('source__inline-image-author--large')) {
                this.setImageSize('large');
                // Else it is class source__inline-image-author--medium
            } else if (this.cursorElement.hasClass('source__inline-image-author--medium')) {
                this.setImageSize('medium');
                // Else if full width
            } else {
                this.setImageSize('full-width');
            }

        }

    },

    /**
     * setImagePosition
     *
     * This function will set the dropdown label according to the selected options
     * and will update the chosenOptions object according to the selected option.
     *
     * @param  {string}     position   String containing the value of the selected option
     */
    setImagePosition(position) {

        // Don't bother setting position when simple image styling is enabled.
        if (this.simpleImageStyling) {
            return;
        }

        if (!this.imagePositionSelect) {
            // Image position select
            var selectItems = [{
                label: window.i18n.gettext('Left'),
                value: 'left'
            }, {
                label: window.i18n.gettext('Middle'),
                value: 'middle'
            }, {
                label: window.i18n.gettext('Right'),
                value: 'right'
            }];
            this.imagePositionSelect = this.addSvelteChildView('.js-image-transform-settings', QuickSelect, {
                label: window.i18n.gettext('Alignment'),
                items: selectItems,
                defaultValue: position,
                callback: (item) => { this.setImagePosition(item.value) }
            });
        }

        // Set class to true to render classes
        this.chosenOptions.class = true;

        // Set the base class which will be suffixed with the right version
        this.chosenOptions.positionClass = 'source__inline-image-author ';

        // Switch/case the value
        switch (position) {

            // When case is left
            case 'left':

                // Set the positionClass which will be put on the inserted image to the left version
                this.chosenOptions.positionClass += 'source__inline-image-author--left';

                break;

                // When case is right
            case 'right':

                // Set the positionClass which will be put on the inserted image to the right version
                this.chosenOptions.positionClass += 'source__inline-image-author--right';

                break;

                // default is middle
            default:

                // Set the positionClass which will be put on the inserted image to the middle version
                this.chosenOptions.positionClass += 'source__inline-image-author--middle';

                break;
        }
    },

    /**
     * setImageSize
     *
     * This function will set the dropdown label according to the selected options
     * and will update the chosenOptions object according to the selected option.
     *
     * @param  {string}     size   String containing the value of the selected option
     */
    setImageSize(size) {

        if (!this.imageSizeSelect) {
            // Image size select
            var selectItems = [{
                label: window.i18n.gettext('Small'),
                value: 'small'
            }, {
                label: window.i18n.gettext('Medium'),
                value: 'medium'
            }, {
                label: window.i18n.gettext('Large'),
                value: 'large'
            }, {
                label: window.i18n.gettext('Full width'),
                value: 'full-width'
            }];
            this.imageSizeSelect = this.addSvelteChildView('.js-image-transform-settings', QuickSelect, {
                label: window.i18n.gettext('Dimensions'),
                items: selectItems,
                defaultValue: size,
                callback: (item) => { this.setImageSize(item.value) }
            });
        }

        // Set class to true to render classes
        this.chosenOptions.class = true;

        // Switch/case the value
        switch (size) {

            // When image is large
            case 'large':

                // Set the sizeClass to the large version
                this.chosenOptions.sizeClass = 'source__inline-image-author--large';

                // Add the same class also to the preview image
                this.$('.js-image-preview img').css('transform', 'scale(0.85)');

                break;

                // When image is small
            case 'small':

                // Set the sizeClass to the small version
                this.chosenOptions.sizeClass = 'source__inline-image-author--small';

                // Add the same class also to the preview image
                this.$('.js-image-preview img').css('transform', 'scale(0.5)');

                break;

            case 'full-width':

                // Set the sizeClass to full width
                this.chosenOptions.sizeClass = 'source__inline-image-author--full-width';

                // Add the same class to the preview image
                this.$('.js-image-preview img').css('transform', 'scale(1)');

                break;

                // Default to medium
            default:

                // Set the sizeClass to the medium version
                this.chosenOptions.sizeClass = 'source__inline-image-author--medium';

                // Add the same class also to the preview image
                this.$('.js-image-preview img').css('transform', 'scale(0.7)');

                break;

        }
    },

    /**
     * When the title is changed update the preview title holder and put it in the chosenOptions
     * object.
     *
     * @param {String} value    new value for title
     */
    onChangeTitle(value) {
        this.$('.js-image-title').text(value)
        this.chosenOptions.title = value || null
    },

    /**
     * When the caption is changed put it in the chosenOptions
     * object.
     *
     * @param {String} value caption textarea input value
     */
    onChangeCaption(value) {
        this.chosenOptions.caption = value || null;
    },

    /**
     * When the resource URL is changed, update the preview resource element and put it in the
     * chosen options object.
     *
     * @param {String} value    mew value for source
     */
    onChangeSource(value) {
        if (value) {
            this.$('.js-image-source').html(ShapeList.hyperlink + value)
        } else {
            this.$('.js-image-source').text('');
        }
        this.chosenOptions.resource = value || null
    },

    appendImage(imageURL) {
        // Update the source of the preview image element
        if (this.spinner) {
            this.unregisterAndDestroyChildView(this.spinner);
        }
        this.$('.js-image-preview img').remove();
        this.$('.js-image-preview').append('<img>');
        this.$('.js-image-preview img').attr('src', '/edu_files/open/' + imageURL);
    },

    /**
     * onChangeImageURL
     *
     * This function will handle the change of image URL. This function will be called from
     * the upload model as an event. When the url changes update the preview image with it.
     *
     */
    onChangeImageURL() {

        // Get the url from the upload button and put it in the chosenOptions
        this.chosenOptions.filesId = `${this.uploadModel.get('filesId')}/${this.uploadModel.get('hash')}`;

        // Update the source of the preview image element
        this.appendImage(this.chosenOptions.filesId);

        if (!this.simpleImageStyling) {
            // Set position to middle
            this.setImagePosition('middle');
        }

        // Set size to large
        this.setImageSize('large');
    },

    /**
     * onChangeImageUploadStatus
     *
     * This function will be called when the image upload status changes. For example when a
     * upload fails. It will be called from the upload model as an event.
     *
     * @param  {Backbone.Model} model Model of the type upload
     */
    onChangeImageUploadStatus(model) {

        // Switch/case on the upload status
        switch (model.get('status')) {

            // When there is an error
            case 'error':

                Backbone.View.layout.openStatus(
                    model.get('errMessage'),
                    'error'
                );

                if (this.spinner) {
                    this.unregisterAndDestroyChildView(this.spinner);
                    this.$('.js-image-placeholder').show();
                }

                break;

                // When the state is loading
            case 'loading':
                this.$('.js-image-placeholder').hide();
                this.spinner = this.addChildView(
                    new Spinner({}),
                    '.js-image-preview'
                )
                break;

                // When the state is success
            case 'success':

                if (this.spinner) {
                    this.unregisterAndDestroyChildView(this.spinner);
                }

                break;
        }
    },

    /**
     * getOptions
     *
     * This function will return the chosen object from the chosenOptions object.
     * This can be used for debugging or inheritance.
     *
     * @return {Object}     The chosen options object
     */
    getOptions() {
        return this.chosenOptions;
    },

});
