import Template from './Image.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import UploadImageModal from 'views/components/wysiwyg/modals/uploadImage/UploadImage';
import ImageTemplate from 'views/components/wysiwyg/elements/image.hbs';

export default class Image extends Button {

    // Built-in TinyMCE command to add an <img src="…"> at the current cursor position
    command = 'insertImage'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Image');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor;
        const customEl = editorObject.customEl;
        const customSH = editorObject.customSH;

        // Add the command for inserting images.
        editor.addCommand(this.command, () => {
            customEl.modalComponentElement(

                // Set modal title
                window.i18n.gettext('Upload an image'),

                // Pass the to be inserted template
                ImageTemplate,

                // Define the to-be-opened modal(view)
                UploadImageModal,

                // Pass the editor object
                editor,

                // Image upload/edit options
                {
                    // If true, don't show alignment or size adjustments options for image elements.
                    simpleImageStyling: this.wysiwyg.simpleImageStyling
                },

            );
        });

        // Create the state handler for this block element
        customSH.blockElementHandler(

            // Listen to command: insertImage
            this.command,

            // Use the ImageTemplate template to indentify the block
            // Note that ImageTemplate doesn't have a newline at end of file.
            // If it did, this newline would be inserted into the editor,
            // adding an unwanted empty line after the inserted content.
            ImageTemplate(),

            // Pass the editor with it
            editor,
        );
    }

}
