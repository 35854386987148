import Styles from './CrumblepathButton.scss';

import Template from './CrumblepathButton.hbs';
export default class CrumblepathButton extends BaseView {

    initialize({ model, action, isActive }) {

        let url = model.get('url')
        if (!url) {
            url = '/' + model.get('level') + '/' + action + '/' + model.get('path_id')
        }

        let title
        switch (action) {
            case 'show':
                title = window.i18n.gettext('Learning material')
                break
            case 'progress':
                title = window.i18n.gettext('Progress')
                break
            case 'author':
                title = window.i18n.gettext('Author')
                break
        }

        this.setElement(Template({
            Styles,
            url,
            icon: action,
            title,
            isActive
        }))
    }

}
