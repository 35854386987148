import Template from './Column.hbs';
import CheckAnswerStudentScoringView from 'views/components/answers/student/scoring/Scoring';
import TimeUtil from 'util/TimeUtil';
import Util from 'util/util';

export default BaseView.extend({

    /**
     * @param {ResponseModel} response               Most recent or original response.
     * @param {String} givenAnswer                   String representing the student answer for this task.
     * @param {ActivityModel} activityModel          Parent activity model.
     * @param {Boolean} areReponseRevisionsEqual     If true, latest and original response are the same response.
     */
    initialize({
        response,
        givenAnswer,
        activityModel,
        areReponseRevisionsEqual,
        isInPresentMode = false,
    }) {

        var score = response.get('score');

        var scoreIsSet;
        if (score !== undefined && score !== -1) {
            scoreIsSet = true;
            score = score * 100;
        } else {
            scoreIsSet = false;
        }

        const lastUpdated = response.get('last_updated_by_student')
        const noAnswer = window.i18n.gettext('No answer is given yet.')
        const emptyAnswer = window.i18n.gettext('The student has given an empty answer.')

        if (
            givenAnswer === null ||
            (!givenAnswer && lastUpdated)
        ) {
            givenAnswer = '<span class="work-on__student-answer-inner-placeholder-text">' + emptyAnswer + '</span>'
        } else if (!givenAnswer) {
            givenAnswer = '<span class="work-on__student-answer-inner-placeholder-text">' + noAnswer + '</span>'
        } else {
            givenAnswer = Util.renderContentSafely(givenAnswer)
        }

        const isExam = activityModel.isExam()

        // Locally formatted date and time (4-9-2019 13:30:22) + aprox duration from now which is not displayed
        // in print media since paper cannot update in real time.
        let lastUpdatedRelative = ''
        let lastUpdatedAbsolute = ''
        let responseTypeLabel = ''
        if (lastUpdated && givenAnswer !== undefined) {
            lastUpdatedRelative = TimeUtil.prettyDate(lastUpdated)
            lastUpdatedAbsolute = TimeUtil.getAbsoluteDate(lastUpdated) + ' ' + TimeUtil.getAbsoluteTime(lastUpdated)
            if (isExam) {
                responseTypeLabel = window.i18n.gettext('Answer')
            } else if (areReponseRevisionsEqual) {
                responseTypeLabel = `${window.i18n.gettext('Latest answer')} (${window.i18n.gettext('equal to original answer')})`
            } else {
                responseTypeLabel = response.get('original') === 1 ?
                    window.i18n.gettext('Original answer') :
                    window.i18n.gettext('Latest answer')
            }
        }

        this.setElement(Template({
            givenAnswer,
            score,
            scoreIsSet,
            lastUpdatedAbsolute,
            lastUpdatedRelative,
            responseTypeLabel,
            isInPresentMode,
        }))

        // By default, create range of possible grades for use rendering the grade buttons
        // for the current response.
        var displayRange = [0, 25, 33, 50, 67, 75, 100];

        // If activity is an exam, create a range that ranges from 0 to the max amount of points
        // attainable for that exam task with steps of 0.5 point.
        if (isExam) {
            var maxPoints = activityModel.getAttainableExamPoints(response.get('task_id'));
            // Create range from zero (inclusive) to max possible points (exclusive) with steps of 0.5 point.
            displayRange = _.range(0, maxPoints + 0.5, 0.5);
        }

        // Create an array of the actual scores between 0 and 1 corresponding that maps with the
        // possible scores displayed to the user. For the default case 0 becomes 0, 100 becomes
        // 1, 50 becomes 0.5, etc. Round floating point numbers to 6 decimal precision.
        var range = displayRange.map((point) => {
            return Math.round(point / _.max(displayRange) * 1e6) / 1e6 || 0;
        });

        // Use decimal seperator for user's locale.
        displayRange = _.invoke(displayRange, 'toLocaleString');

        // Merge arrays into array of arrays for easy parsing by Handlebars.
        var ranges = _.zip(range, displayRange);

        // Create grading tools view.
        if (!isInPresentMode) {
            this.checkAnswerStudentScoringView = this.addChildView(
                new CheckAnswerStudentScoringView({
                    response,
                    isExam,
                    ranges,
                }),
                '.js-score-holder'
            );
        }
    }
});
