var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n\n    <button class=\"js-dropzone-failed "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"dropzone-failed") : stack1), depth0))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"refresh",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":29}}})) != null ? stack1 : "")
    + "\n        "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Loading failed. Click to try again.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":52}}}))
    + "\n    </button>\n\n    <div class=\"js-uploaded-files-container "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"upload-files-container") : stack1), depth0))
    + "\">\n        <div class=\"js-dropzone-container "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"dropzone-container") : stack1), depth0))
    + "\">\n            <div class=\"js-drag-and-drop-zone "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"dropzone-area") : stack1), depth0))
    + "\">\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"upload-icon") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"file-upload",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":77}}})) != null ? stack1 : "")
    + "</div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lock-icon") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"lock",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":12,"column":50},"end":{"line":12,"column":68}}})) != null ? stack1 : "")
    + "</div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"main-text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Drag files or browse for files to upload",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":13,"column":50},"end":{"line":13,"column":99}}}))
    + "</div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"types") : depth0), depth0))
    + "</div>\n                <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Maximum file size",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":15,"column":45},"end":{"line":15,"column":71}}}))
    + ": "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"maxSize") : depth0), depth0))
    + "MB</div>\n            </div>\n            <div class=\"js-file-preview "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"file-preview") : stack1), depth0))
    + "\"></div>\n            <div class=\"js-extension-buttons "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"extension-buttons") : stack1), depth0))
    + "\"></div>\n        </div>\n    </div>\n\n    <div class=\"js-add-to-portfolio-holder "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"portfolio-placer") : stack1), depth0))
    + "\"></div>\n    <div class=\"js-model-answer-holder\"></div>\n\n</div>\n";
},"useData":true});