import Template from './Show.hbs';
import ActivitySlot from 'views/pages/sections/show/activitySlot/ActivitySlot';
import HeroButton from 'views/components/heroButton/HeroButton';
import VisibilityModal from 'views/components/modals/setVisibility/SetVisibility';
import Button from 'views/components/button/Button'
import PaginationNavigation from 'views/components/paginationNavigation/PaginationNavigation';
import ActivitiesCollection from 'collections/ActivitiesCollection';

export default BaseView.extend({

    events: {
        'click .js-exercise-button': 'onClickExerciseButton',
    },

    initialize(options) {

        _.bindAll(
            this,
            'onClickAddActivity',
            'onLoadActivitiesInSection',
            'onChangeActivities',
            'onReloadPage',
            'addPagination'
        );

        this.sectionModel = Backbone.Collection.sections.get(options.params[0]);

        this.chapterModel = this.sectionModel.getChapterModel();

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle(this.sectionModel.getName('show'))
        Backbone.View.header.setCrumblepath(
            this.sectionModel.getAllCrumblepathModels(),
            'show'
        )

        this.setElement(Template({}))

        if (Backbone.Model.user.get('is_teacher')) {
            Backbone.View.header.addButton(
                new HeroButton({
                    firstLine: window.i18n.gettext('Set visibility'),
                    icon: 'eye',
                    callback: () => {
                        Backbone.View.Components.modal.open(VisibilityModal, {
                            title: window.i18n.gettext('Set visibility'),
                            model: this.sectionModel,
                            activitiesCollection: this.activitiesCollection
                        })
                    }
                })
            );
        }

        this.activitiesCollection = new ActivitiesCollection()
        this.activitiesCollection.url = `/sections/get_activities/${this.sectionModel.id}.json`
        this.activitiesCollection.fetch({
            success: this.onLoadActivitiesInSection,
            error: this.onErrorLoadingSection
        });
        this.onChangeActivities()
        this.listenTo(this.activitiesCollection, 'change', this.onChangeActivities)
    },

    /**
     * openToolTipForSectionShow
     *
     * This functions opens a tour tool tip that points towards the first activity of a section
     * that is a linear activity with a measurable amount of progress, meaning that the
     * activity contains at least 1 task.
     */
    openToolTipForSectionShow() {

        const activityList = this.getChildViewsOfInstance(ActivitySlot);

        const linearActivity = _.find(
            activityList,
            (activityView) => activityView.model.get('type') === 'linear'
        );

        const activityWithProgress = _.find(
            activityList,
            (activityView) =>
                activityView.model.get('type') === 'linear'
                && activityView.model.get('progress') !== null
        );

        // Preferer activity with progress above activity without progress
        if (activityWithProgress) {
            Backbone.View.layout.addTourTooltip({
                message: window.i18n.gettext('View tasks and sources'),
                target: activityWithProgress.$('.js-content-label > div')
            });

        // Else we just has to do with what we have
        } else if (linearActivity) {
            Backbone.View.layout.addTourTooltip({
                message: window.i18n.gettext('View tasks and sources'),
                target: linearActivity.$('.js-content-label > div')
            });
        }
    },

    onClickExerciseButton() {
        Backbone.history.navigate(`/chapters/exercise/${this.chapterModel.id}`, {trigger: true});
    },

    onReloadPage() {
        Backbone.history.loadUrl(Backbone.history.fragment);
    },

    onClickAddActivity() {
        Backbone.history.navigate(
            // Escape slash at the end of the string in a template due to a bug in xgettext, the GNU tool for
            // generating the translation file used by Poedit. https://gitlab.gnome.org/GNOME/gnome-shell/issues/537#note_309678
            `/sections/author/${this.sectionModel.id}${'/'}action:add_activity`,
            {trigger: true}
        );
    },

    onChangeActivities() {
        var answer_visible = false;
        this.activitiesCollection.each((activity) => {
            if (activity.get('activity_visible') !== undefined && activity.get('activity_visible').length > 0) {
                answer_visible = true;
            }
        })

        if (answer_visible) {
            this.$('.js-all-answers').addClass('visible');
            this.$('.js-all-answers').removeClass('not-visible');
        } else {
            this.$('.js-all-answers').addClass('not-visible');
            this.$('.js-all-answers').removeClass('visible');
        }
    },

    onLoadActivitiesInSection() {

        const activities = this.activitiesCollection.filter(
            activityModel => !!ACL.isAllowed(
                ACL.resources.ACTIVITIES,
                ACL.actions.VIEW,
                { type: activityModel.get('type') }
            )
        );

        if (activities.length > 0) {

            // update global activities collection
            Backbone.Collection.activities.push(activities, {silent: true})

            this.userListAnswers = [];

            activities.forEach((activity) => {

                const activityView = this.addChildView(
                    new ActivitySlot({ model: activity }),
                    activity.get('type') === 'adaptive_student'
                        // If type is adaptive student, append it to the practice container, which
                        // will be below the linear activities container
                        ? '.js-practice-activities-holder'

                        // When not of the adaptive type
                        // Append it to the normal activity holder
                        : '.js-activities-holder'
                );

                this.registerChildView(activityView);

                this.userListAnswers = _.union(this.userListAnswers, activity.get('answers_visible'));
            });

            // Remove the empty state that could be visible
            this.$('.js-no-activities').remove();

        } else {

            // If there are no activities, show an empty state with reload button
            this.addChildView(new Button({
                label: window.i18n.gettext('Reload page'),
                callback: this.onReloadPage
            }), '.js-reload-page');
            this.$('.js-no-activities').show();

        }

        if (!ISMOBILE) {
            this.addPagination()
        }

        if (Backbone.Model.user.get('is_demo')) {
            this.openToolTipForSectionShow();
        }

    },

    onErrorLoadingSection() {},

    show() {

        // Abort show if sectionModel is not defined.
        if (this.sectionModel === undefined) {
            return;
        }

        // If user is not mobile
        if (!ISMOBILE) {

            // Convert is_teach to Boolean and check if true
            if (Backbone.Model.user.get('is_teacher')) {
                Backbone.View.Components.actionbutton.switchIcon('plus');
                Backbone.View.Components.actionbutton.persistantCallback = true;
                Backbone.View.Components.actionbutton.show(
                    this.onClickAddActivity,
                    window.i18n.gettext('Add activity')
                );
            }
        }

        Backbone.View.header.setBackgroundImage(this.sectionModel)
    },

    hide(callback) {
        if (Backbone.Model.user.get('is_teacher')) {
            Backbone.View.Components.actionbutton.hide();
        }
        callback(this)
    },

    // Add buttons to navigate to previous and next section
    addPagination() {

        // Find the next and previous section (if available)
        const current = Backbone.Collection.sections.indexOf(this.sectionModel)
        const previousSection = Backbone.Collection.sections.at(current - 1)
        const nextSection = Backbone.Collection.sections.at(current + 1)
        let previousUrlOrCallback, previousLabel, nextUrlOrCallback, nextLabel

        // note: Backbone.Collection.at(), if given a negative index, retrieves a model from
        // the back of the collection. prevent showing the last model of the collection when
        // index of the current model is 0 (or lower)
        if (
            previousSection &&
            previousSection.getGroupModel()?.id === this.sectionModel.getGroupModel()?.id &&
            current > 0
        ) {
            previousUrlOrCallback = '/sections/show/' + previousSection.id
            previousLabel = previousSection.getCrumblepathModel().get('index') + ' ' + previousSection.get('name')
        }
        if (nextSection && nextSection.getGroupModel()?.id === this.sectionModel.getGroupModel()?.id) {
            nextUrlOrCallback = '/sections/show/' + nextSection.id
            nextLabel = nextSection.getCrumblepathModel().get('index') + ' ' + nextSection.get('name')
        }

        // Add pagination to the page
        this.$('.js-pagination').empty()
        this.addChildView(
            new PaginationNavigation({
                previousUrlOrCallback,
                previousLabel,
                nextUrlOrCallback,
                nextLabel,
            }),
            '.js-pagination'
        )
    },

    onDestroy() {
        Backbone.View.header?.removeBackgroundImage()
    }

});
