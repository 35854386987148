import Styles from './Input.scss';

import Template from './Input.hbs';
import Wysiwyg from 'views/components/wysiwyg/Wysiwyg';
import Button from 'views/components/button/Button.svelte'
import QuickSelect from 'views/components/quickSelect/QuickSelect.svelte';

/*    ### AnnotationInputView     ###
 #
 #    This view will handle the input for the annotations.
 #    It makes it posibile to select a path and creates an
 #    WYSWYG editor for the text itself.
 #    With the selected ID's it creates a path.
 #
 */

export default BaseView.extend({

    initialize(options, extra, annotationsView) {

        _.bindAll(this,
            'saveToLocalStorage',
            'onSaveButtonClick'
        );

        this.modelPath = {
            group: Backbone.Collection.groups.get(options[0].group_id),
            chapter: Backbone.Collection.chapters.get(options[0].chapter_id),
            section: Backbone.Collection.sections.get(options[0].section_id),
            activity: Backbone.Collection.activities.get(options[0].activity_id),
            // We don't have a global collection for task_groups, so save only the Id
            taskGroup: options[0].task_group_id
        };

        const groupModel = this.modelPath.group;

        if (!groupModel.needsDummies()) {

            const numberOfLayers = groupModel.get('layers');

            if (numberOfLayers === 2) {
                this.modelPath.chapter = groupModel.getDummyChapter();
            } else if (numberOfLayers === 1) {
                this.modelPath.chapter = groupModel.getDummyChapter();
                this.modelPath.section = groupModel.getDummySection();
            }
        }

        this.annotationsView = annotationsView;
        this.setElement(Template({ Styles }));
        this.renderDropdownItems();

        var editorOptions = {
            content: localStorage.annotation || '',
            buttonsPreset: 'annotations',
            autoFocus: true,
            label: window.i18n.gettext('Annotation')
        };

        if (extra) {
            editorOptions.content = extra.content;
        }

        this.inputField = this.addChildView(
            new Wysiwyg(editorOptions),
            '.js-annotation-input-container'
        );

        // Listen for change events sent from the WYSIWYG editor
        this.inputField.on('change', this.saveToLocalStorage);

        this.addSvelteChildView(
            '.js-save-annotation-button',
            Button,
            {
                label: window.i18n.gettext('Save annotation'),
                type: 'submit'
            }
        );

        this.el.addEventListener('submit', e => {
            this.stopAllEvents(e);
            this.onSaveButtonClick();
            return false;
        });
    },

    renderDropdownItems() {
        this.destroyChildViewsOfInstance(QuickSelect);
        this.$('.js-dropdown-path-holder').empty();

        const numberOfLayers = this.modelPath.group.get('layers')

        if (numberOfLayers > 2 && this.modelPath.group) {
            this.addPathOptions(this.modelPath.group.chapters)
        }

        if (numberOfLayers > 1 && this.modelPath.chapter) {
            this.addPathOptions(this.modelPath.chapter.sections)
        }

        if (this.modelPath.section) {
            this.addPathOptions(
                new Backbone.Collection(
                    this.modelPath.section.activities.filter(activityModel => activityModel.get('sequence') !== -1)
                )
            );
        }
    },

    addPathOptions(collection) {
        if (!collection || collection.length === 0) {
            return;
        }

        // Since we need to do some stuff based on the type, we need to get it.
        // however the collection itself only has a plural type and we want to
        // get the singular type, we take the type of the first model. This
        // model should always exists because of the early return above.
        const collectionType = collection.at(0)?.constructor?.type;

        const groupModel = this.modelPath.group;

        this.addSvelteChildView('.js-dropdown-path-holder', QuickSelect, {
            items: collection.map(
                (model) => {
                    const crumblepathModel = model.getCrumblepathModel()
                    return {
                        value: model.id,
                        label: crumblepathModel.get('index') + ' ' + crumblepathModel.get('label'),
                    }
                }
            ),
            callback: ({
                value
            }) => {

                this.modelPath[collectionType] = collection.get(value);

                // Use the collectiontype to determine what the "lower" levels
                // in the material hierarchy are. And reset those by setting
                // them to undefined
                if (collectionType === 'chapter') {
                    delete this.modelPath.section;
                }
                if (collectionType === 'chapter'
                    || collectionType === 'section'
                ) {
                    delete this.modelPath.activity;
                }
                if (collectionType === 'chapter'
                    || collectionType === 'section'
                    || collectionType === 'activity'
                ) {
                    delete this.modelPath.taskGroup;
                }

                this.renderDropdownItems();
            },
            defaultValue: this.modelPath[collectionType]?.id,
            placeholder: window.i18n.gettext('Choose ...')
        })

        // Add logic for "learnbeat-plat/flat", we have a great fallback for
        // when there aren't any dummies because then the chapters collection
        // of a group is empty and therefore won't render the dropdown because
        // of the early return.
        if (!groupModel.needsDummies()) {

            const numberOfLayers = groupModel.get('layers');

            // Do not render the dropdown for a chapter when there are only
            // two layers for this group. We'll do this by using an early return
            // and therefore the element of the dropdown won't be added to the DOM
            if (numberOfLayers === 2
                && collectionType === 'chapter'
            ) {
                return;

                // Do not render the dropdown for a chapter and section when there
                // are only two layers for this group. We'll do this by using an early
                // return and therefore the element of the dropdown won't be added to the DOM
            } else if (numberOfLayers === 1
                && (
                    collectionType === 'chapter'
                    || collectionType === 'section'
                )
            ) {
                return;
            }
        }
    },

    onSaveButtonClick() {

        if (Backbone.Model.user.get('is_demo')) {

            Backbone.View.layout.openStatus(
                window.i18n.gettext('Demo accounts are not allowed to save annotations'),
                'warning'
            )

            return
        }

        // Convert the modelPath to an object containing ID's to comply with the
        // original code in the AnnotationsContainer.
        this.savePath = {
            group_id: this.modelPath.group?.id || 0,
            chapter_id: this.modelPath.chapter?.id || 0,
            section_id: this.modelPath.section?.id || 0,
            activity_id: this.modelPath.activity?.id || 0,
            task_group_id: 0,
            task_group_sequence: 0
        }

        this.annotationsView.onSaveAnnotation();
    },

    // Save to local storage upon keypress
    saveToLocalStorage() {
        localStorage.annotation = this.inputField.getContent();
    }

});
