import Template from './Annotations.hbs';
import WysiwygView from 'views/components/wysiwyg/Wysiwyg';
import AnnotationItem from 'views/components/annotations/item/Item';
import AnnotationsCollection from 'collections/AnnotationCollection';
import Button from 'views/components/button/Button'

export default class Annotations extends BaseView {

    get events() {
        return {
            'click .js-make-annotation-tab': 'makeAnnotationClick',
            'click .js-view-annotations-tab': 'viewAnnotationsClick'
        }
    }

    get storageId() {
        return `annotation:${this.objectIds.activityId}`
    }

    initialize(options) {
        _.bindAll(this,
            'onSaveAnnotation',
            'makeAnnotationClick',
            'viewAnnotationsClick',
            'setAnnotationNumber',
        )

        this.objectIds = options[0];

        const editorContent = window.sessionStorage.getItem(this.storageId) || ''

        this.setElement(Template({}));

        // Create a WYSIWYG editor for the input field
        this.inputField = this.addChildView(
            new WysiwygView({
                id: 'annotationSideBar',
                buttonsPreset: 'annotations',
                content: editorContent,
            }),
            '.js-annotation-input-container',
            'html'
        );

        this.modus = 'make';

        this.setAnnotationNumber();
        this.listenTo(
            Backbone.Collection.groups.get(this.objectIds.schoolSchoolclassCourseId).annotations,
            'add',
            this.setAnnotationNumber
        );

        this.addChildView(
            new Button({
                label: window.i18n.gettext('Save annotation'),
                callback: this.onSaveAnnotation,
                inline: true,
            }),
            '.js-save-annotation-button',
        )

        this.saveToStorageFunction = _.debounce(() => {
            const editorContent = this.inputField.getContent()
            if (!editorContent && window.sessionStorage.getItem(this.storageId) === null) {
                return
            }
            if (editorContent === '') {
                window.sessionStorage.removeItem(this.storageId)
                return
            }
            window.sessionStorage.setItem(this.storageId, editorContent)
        }, 800)
        this.listenTo(this.inputField, 'change', this.saveToStorageFunction)
    }

    setAnnotationNumber() {
        const numberOfAnnotations = Backbone.Collection.groups.get(this.objectIds.schoolSchoolclassCourseId)
            .annotations.where({
                // Show annotations for this activity
                activity_id: parseInt(this.objectIds.activityId)

            }).length;

        const viewAnnotationsText = window.i18n.sprintf(window.i18n.ngettext(
            'View annotation (1)',
            'View annotations (%d)',
            numberOfAnnotations), numberOfAnnotations);

        this.$('.js-view-annotations-tab').text(viewAnnotationsText);
    }

    makeAnnotationClick() {
        if (this.modus !== 'make') {

            this.$('.js-annotations-make-container').show()
            this.$('.js-annotations-view-container').hide()
            this.$('.js-edit-annotation').hide()

            // Prevents adding new divs to the sidebar when switching between Make and Edit tabs
            this.$('.js-edit-annotation').empty()

            this.$('.js-make-annotation-tab').addClass('is-active')
            this.$('.js-view-annotations-tab').removeClass('is-active')

            this.modus = 'make'
        }
    }

    viewAnnotationsClick() {
        if (this.modus !== 'view') {
            this.$('.js-annotations-view-container').html('');
            this.$('.js-annotations-view-container').show();
            this.$('.js-edit-annotation').show();
            this.$('.js-annotations-make-container').hide();

            this.$('.js-make-annotation-tab').removeClass('is-active');
            this.$('.js-view-annotations-tab').addClass('is-active');

            const annotationsForTaskGroupCollection = new AnnotationsCollection(
                Backbone.Collection.groups.get(this.objectIds.schoolSchoolclassCourseId)
                    .annotations.where({
                    // Show annotations for this activity
                        activity_id: parseInt(this.objectIds.activityId)
                    })
            );

            annotationsForTaskGroupCollection.each((annotationObject) => {
                this.addChildView(
                    new AnnotationItem({
                        model: annotationObject,
                        taskgroupPath: true,
                        isSidebar: true,
                        sidebarView: this,
                    }),
                    this.$('.js-annotations-view-container'),
                    'prepend'
                )
            })

            this.modus = 'view';
        }
    }

    onSaveAnnotation() {

        if (Backbone.Model.user.get('is_demo')) {

            Backbone.View.sidebar.closeSidebar()

            Backbone.View.layout.openStatus(
                window.i18n.gettext('Demo accounts are not allowed to save annotations'),
                'warning'
            )

            return

        }

        this.saveToStorageFunction.cancel()
        window.sessionStorage.removeItem(this.storageId)

        // Define the path
        const path = {
            group_id: this.objectIds.schoolSchoolclassCourseId,
            chapter_id: this.objectIds.chapterId,
            section_id: this.objectIds.sectionId,
            activity_id: this.objectIds.activityId,
            task_group_id: this.objectIds.taskGroupId,
            task_group_sequence: this.objectIds.taskGroupSequence
        }

        // Get the annotation content from the TinyMC WYSIWYG editor
        const annotation = this.inputField.getContent()

        // Call the save annotation function, which is inherited from AnnotationsView
        Backbone.Collection
            .groups.get(path.group_id)
            .annotations.createAnnotation(
                annotation,
                path,
                this.annotationSaved
            );
    }

    annotationSaved() {
        // Close the sidebar
        Backbone.View.sidebar.closeSidebar();

        // Show a message that the annotation has been saved
        Backbone.View.layout.openStatus(
            window.i18n.gettext('The annotation has been saved succesfully.'),
            'success'
        );
    }

}
