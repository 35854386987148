import Styles from './AudioPlayer.scss';

import Template from './AudioPlayer.hbs';
import Spinner from 'views/components/spinner/Spinner'

export default class AudioPlayer extends BaseView {

    set mediaSource(src) {
        if (src instanceof Blob) {
            src = URL.createObjectURL(src)
        }
        this.audio.src = src
    }

    /**
     *
     * inititalize
     *
     * @param {Object} options Options object
     *
     * @param {string|undefined} options.title  Audio player title
     * @param  {string|undefined} options.style Style override for small screens
     * @param {string} options.url              Url for audio source (required)
     * @param {Boolean} options.autoPlay        Play audio automatically when source has loaded
     * @param {Number|undefined} options.playbackRate    Custom default audio playback rate (between 0.5 and 4)
     */
    initialize({
        title = '',
        style,
        url = '',
        autoPlay = false,
        playbackRate = 1,
    }) {

        _.bindAll(
            this,
            'onClickTogglePlayback',
            'onTogglePlayback',
            'onTimeUpdate',
            'onClickTimeline',
            'onDestroy'
        );

        // If the small styling property is passed through the options or if user is mobile
        const isSmall = style === 'small' || ISMOBILE || false;

        this.setElement(Template({
            Styles,
            title,
            isSmall,
        }));

        const spinner = this.addChildView(new Spinner({
            isInsideButton: true
        }), '.js-playback')

        // Construct new instance of HTMLAudioElement
        this.audio = new Audio()

        this.mediaSource = url

        // Value should be contrained between 0.5 and 4, otherwise most browsers won't be able to play the file.
        this.audio.defaultPlaybackRate = Math.max(Math.min(playbackRate, 4), 0.5)

        this.audio.autoplay = autoPlay

        // When audio metadata is loaded, initialize event listeners for the
        // player and buttons in the DOM.
        this.audio.addEventListener('loadedmetadata', () => {
            this.$('.js-total-time').text(this.getDurationStamp(this.audio.duration))

            // Append as <audio> element so we can pause other instances
            // of audio when playing this one.
            this.el.append(this.audio)

            spinner.destroy()
            this.$('.js-playback').attr('disabled', false)

            // When clicking on the playback (pause/play button).
            this.$('.js-playback').on('click', this.onClickTogglePlayback)

            // When player playback state changes.
            this.audio.addEventListener('play', this.onTogglePlayback)
            this.audio.addEventListener('pause', this.onTogglePlayback)

            // When player current time has changed while playing.
            this.audio.addEventListener('timeupdate', this.onTimeUpdate)

            // Show duration of audio file in the DOM.
            this.$('.js-total-time').text(this.getDurationStamp(this.audio.duration))

            // When clicking or dragging the timeline.
            const timeRange = this.el.querySelector('.js-time-range')
            timeRange.disabled = false
            timeRange.addEventListener('input', this.onClickTimeline)
        })

    }

    /**
     * onClickTogglePlayback
     *
     * Toggle playback after clicking the play/pause button.
     *
     * @param  {Event} e    click event
     */
    onClickTogglePlayback(e) {
        this.stopAllEvents(e);
        if (this.audio.paused) {
            this.audio.play();
        } else {
            this.audio.pause();
        }
    }

    /**
     * onTogglePlayback
     *
     * Show correct playback button status.
     */
    onTogglePlayback() {
        if (this.audio.paused) {
            this.$('.js-playback .js-pause').hide();
            this.$('.js-playback .js-play').css('display', 'flex');
        } else {
            this.$('.js-playback .js-pause').css('display', 'flex');
            this.$('.js-playback .js-play').hide();

            // Find other media elements and pause those.
            for (const mediaPlayerElement of document.querySelectorAll('audio,video')) {
                if (mediaPlayerElement !== this.audio) {
                    mediaPlayerElement.pause()
                }
            }
        }
    }

    /**
     * onTimeUpdate
     *
     * Get the currentTime of the audio and position the playhead accordingly.
     */
    onTimeUpdate() {
        this.$('.js-current-time').text(this.getDurationStamp(this.audio.currentTime));

        const timeRange = this.el.querySelector('.js-time-range')
        timeRange.value = this.audio.currentTime / this.audio.duration * timeRange.max

        // This trick sets the played part to blue, while the unplayed part is gray
        timeRange.style.backgroundSize = this.audio.currentTime / this.audio.duration * 100 + '% 100%'
    }

    /**
     * onClickTimeline
     *
     * When clicking or dragging the timeline, update the audio time.
     *
     * @param  {Event} e event data of the input event.
     */
    onClickTimeline(e) {
        this.audio.currentTime = e.target.value / e.target.max * this.audio.duration;
    }

    /**
     * getDurationStamp
     *
     * Create a nicely formatted duration stamp.
     *
     * @param  {number} sec number of seconds
     * @return {string}     timecode with left-padded zero's (ie. '06:23').
     */
    getDurationStamp(sec = 0) {
        return Math.floor(sec / 60).toString().padStart(2, '0') + ':' + Math.floor(sec % 60).toString().padStart(2, '0')
    }

    /**
     * onDestroy
     *
     * Pause audio to prevent it from playing in the background and allow it to be garbage collected.
     */
    onDestroy() {
        if (this.audio) {
            this.audio.pause();
        }
    }

}
