export default {

    getSchoolYearStart() {
        return dayjs().month() > 6 ?
            new dayjs().month(7).startOf('month') :
            new dayjs().subtract(1, 'year').month(7).startOf('month')
    },

    getSchoolYearEnd() {
        return dayjs().month() > 6 ?
            new dayjs().add(1, 'year').month(7).startOf('month') :
            new dayjs().month(7).startOf('month')
    },

    getAbsoluteTime(time) {
        return dayjs
            .utc(time, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .format('HH:mm');
    },

    getAbsoluteDate(time) {
        return dayjs
            .utc(time, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .format('DD MMM YYYY');
    },

    getAbsoluteDateTime(time) {
        return this.getAbsoluteDate(time) + ' ' + this.getAbsoluteTime(time)
    },

    // Show a duration in a short and readable way like '30 min.' or '3 hours'
    prettyDuration(timeInSec) {
        timeInSec = parseInt(timeInSec) || 0

        const minutes = Math.round(timeInSec / 60)
        if (minutes < 120) {
            return window.i18n.sprintf(
                window.i18n.gettext('%d min.'),
                minutes,
            )
        }

        const hours = Math.round(timeInSec / 60 / 60)
        return window.i18n.sprintf(
            window.i18n.ngettext(
                '%d hour',
                '%d hours',
                hours
            ),
            hours
        )
    },

    /**
     * prettyDate
     *
     * Parses a representation of a date and time and returns a string
     * describing the difference between this date and the current
     * date and time.
     *
     * @param  {string|number|dayjs|Date} time
     * data representing a time and date
     * @param  {boolean} isTimezoneAware
     * whether param time is timezone aware. sample timezone aware: 2020-06-02T14:49:25.803Z
     * (for example google cloud storage). Unaware: 2017-06-14 08:31:58 (e.g. our own backend
     * responses)
     * @return {string}
     * time difference in human-friendly language
     */
    prettyDate(time, isTimezoneAware = false) {
        const date = (isTimezoneAware)
            ? new dayjs(time)
            : dayjs.utc(time, 'YYYY-MM-DD HH:mm:ss');

        if (!date.isValid()) {
            return '';
        }

        // Example: 'in 4 days'
        if (date.isAfter(new dayjs())) {
            return date.toNow();
        }

        // Example: '4 days ago'
        return date.fromNow();
    },

    /**
     * Get a datetime string compatible with MySQL database.
     * Example '2021-10-21 15:02:45'
     *
     * Returns current datetime if no param is given
     *
     * @returns {string} example: '2021-10-21 15:02:45'
     */
    getDatabaseCompatibleTimestamp() {
        return dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
    }

};
