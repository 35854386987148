import Styles from './Confirm.scss';
import Modal from 'views/components/modals/Modal';
import Message from 'views/components/modals/confirm/message/Message';

export default class ConfirmModal extends Modal {

    initialize(options) {

        super.Styles = Styles

        super.initialize(options)

        // Make options accessible within this
        _.extend(this, options);

        // Make this accessible within the following functions
        _.bindAll(this,
            'onClickNo',
            'onClickYes',
        );

        this.open(Message, {
            buttons: {
                // Cancel button
                [options.noButtonLabel || window.i18n.gettext('Cancel')]: {
                    callback: this.onClickNo,
                    theme: 'secondary',
                    keyCode: 27,
                },
                // Confirm button
                [options.yesButtonLabel || window.i18n.gettext('Ok')]: {
                    callback: this.onClickYes,
                    keyCode: 13,
                }
            },
            title: options.title || window.i18n.gettext('Confirm'),
            message: options.message,
            // Prevent modal from being dismissed.
            removeCloseButton: true,
        });

    }

    /**
     * onClickYes
     *
     * This function will be called when the user clicks on the
     * yes button. It wall call the yes callback function and then
     * close the confirm modal.
     *
     */
    onClickYes() {

        // Call the yes callback function
        this.callbackYes();

        // Close the modal
        this.canBeClosed = true;
        this.close();
    }

    /**
    * onClickNo
    *
    * This function will be called when the user clicks on the
    * no button. It wall call the no callback function and then
    * close the confirm modal.
    */
    onClickNo() {

        // Only do callback if it is a function (so we can let callback no empty)
        if (typeof this.callbackNo === 'function') {

            // Call the no callback function
            this.callbackNo();
        }

        // Close the modal
        this.canBeClosed = true;
        this.close();
    }

}
