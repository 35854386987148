export default Backbone.Model.extend({

    initialize() {

        // Parse value to Number if result is not NaN for use with boolean logic.
        const NUMERIC_VALUE = Number(this.get('value'));
        if (!isNaN(NUMERIC_VALUE)) {
            this.set({
                value: NUMERIC_VALUE
            });
        } else if (this.get('value') === 'true') {
            this.set({
                value: 1
            });
        } else if (this.get('value') === 'false') {
            this.set({
                value: 0
            });
        }

    }

}, {
    type: 'settings'
});
