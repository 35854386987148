import Styles from './Source7.scss';

import Template from './Source7.hbs';
import Util from 'util/util';
import AudioPlayer from 'views/components/audioPlayer/AudioPlayer';
import NoContentTemplate from 'views/components/taskGroups/sources/source7/noContent.hbs';

// Audio source view
export default class Source7 extends BaseView {

    initialize() {

        this.setElement(Template({
            Styles,
            transcript: Util.renderContentSafely(this.model.get('source').transcript),
        }));

        if (!this.model.get('source').url) {
            this.$('.js-audio-player').append(NoContentTemplate({
                Styles
            }));
        } else {

            // Check if the browser is capable of playing MP3 audio.
            var audio = new Audio();

            if (audio.canPlayType('audio/mp3')) {

                // Construct new audio player
                this.addChildView(new AudioPlayer({
                    title: this.model.get('title'),
                    url: '/edu_files/open/' + this.model.get('source').url,
                }), '.js-audio-player');

            } else {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('mp3 is not supported by this browser')
                );
            }
        }
    }

}
