import Styles from './TextToSpeech.scss';

import Template from './TextToSpeech.hbs';
import TextToSpeech from 'views/components/textToSpeech/TextToSpeech'

export default class TextToSpeechWordPairs extends TextToSpeech {

    get url() {
        return `/training/synthesize?pairId=${this.pairId}&value=${this.value}&lang=${this.lang}`
    }

    get maxAttempts() {
        return 10
    }

    initialize({
        pairId,
        value,
        lang
    }) {

        // Set the pair id and value (left/right) to be spoken and the language code (e.g. 'en-GB')
        this.pairId = pairId
        this.value = value
        this.lang = lang

        this.setElement(Template({
            Styles
        }))

        this.el.addEventListener('click', (e) => {

            // Prevent clicking 'through' the button
            e.stopPropagation()

            // Prevent multiple clicks on the button
            this.el.setAttribute('disabled', true)

            // An important point to note is that on iOS, Apple currently mutes all sound output until the first time
            // a sound is played during a user interaction event - for example, calling playSound() inside a touch event
            // handler. You may struggle with Web Audio on iOS "not working" unless you circumvent this - in order to
            // avoid problems like this, just play a sound (it can even be muted by connecting to a Gain Node with zero
            // gain) inside an early UI event - e.g. "touch here to play".
            // Source: https://www.html5rocks.com/en/tutorials/webaudio/intro/
            this.playDummyAudio()

            // Create audio context directly after user interaction
            // webkitAudioContext is needed to support Safari < 14.1 and Safari iOS < 14.5
            const audioContext = new (window.AudioContext || window.webkitAudioContext)
            const audioSource = audioContext.createBufferSource()

            // Download the audio and play it
            this.fetchAudio(audioContext, audioSource)
        })

    }

    async fetchAudio(audioContext, audioSource, attempt = 0) {
        attempt++
        super.fetchAudio(this.url).then((arrayBuffer) => {
            Backbone.View.layout.closeStatus()
            this.el.removeAttribute('disabled')

            // Play audio from the start
            audioContext.decodeAudioData(arrayBuffer, (buffer) => {
                audioSource.buffer = buffer
                audioSource.connect(audioContext.destination)
                audioSource.start(0)
            })
        }).catch((error) => {
            if (error.error_code === 35202) {
                if (attempt > this.maxAttempts) {
                    this.el.removeAttribute('disabled')
                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('The audio could not be downloaded. Try again later.')
                    )
                } else {
                    setTimeout(() => {
                        this.fetchAudio(audioContext, audioSource, attempt)
                    }, 500)
                    if (attempt === 4) {
                        Backbone.View.layout.openStatus(error.displayMessage, 'loading', { noHide: true })
                    }
                }
            } else {
                if (error.displayMessage) {
                    Backbone.View.layout.openStatus(error.displayMessage)
                } else {
                    Backbone.View.layout.closeStatus()
                }
                this.el.removeAttribute('disabled')
            }
            console.warn(error)
        })
    }

}
