import Styles from './Source6.scss';

import Template from './Source6.hbs';
import Util from 'util/util';
import Button from 'views/components/button/Button'
import LineNumberTemplate from 'views/components/taskGroups/sources/source6/LineNumber.hbs';
import RenderSpecialElements from 'util/RenderSpecialElements';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import TextToSpeechSource from 'views/components/taskGroups/sources/textToSpeech/TextToSpeech';

// Text with line numbers source view
export default BaseView.extend({

    specialElementsConvertList: {
        // Convertion for HTML element: 'img'
        img: {

            // Default options
            global: {

                // Set template for the img tag
                template: DefaultImageElement,
            }
        }
    },

    initialize(options) {

        _.bindAll(
            this,
            'foldSource'
        );

        // Generate dom string for the source text with line numbers.
        const content = _.reduce(this.model.get('source').text, (m, line, index) => {
            /**
             * If line number is 1 or partitionable by 5 with zero remainder or the last
             * numbered line, add a line number on this line of the text by concatenating
             * the text with a line number span.
             *
             * Important: add non-breaking-space character to trimmed line to prevent tags at
             * the end of a line (e.g. </em> or </span>), followed by tags at the beginning of
             * the next line will be displayed inline instead of following the
             * "white-space: pre;" css! That is unless the line ends with a closing p or br tag,
             * where adding a non-breaking-space character will just result in an unwanted extra
             * empty line.
             */
            let lineStr = line.t.trim()

            // Test if trimmed string ends with a paragraph, break, first heading, aside or list item tag
            // <\/?                 start of opening or closing tag
            // (?:p|br|h1|aside|li) p, br, h1, aside, li
            // \s*?>                any amount of whitespace till the end of the tag
            const isParagraphEnd = /<\/?(?:p|br|h1|aside|li)\s*?>$/.test(lineStr)

            if (line.n % 5 === 0 || line.n === 1 || index === this.model.get('source').text.length - 1) {
                lineStr += LineNumberTemplate({
                    Styles, n: line.n
                }).trim()
            }

            // Only at non-breaking whitespace when not the end of a paragraph.
            if (!isParagraphEnd) {
                lineStr += '&nbsp;'
            }

            return m + lineStr + '\n'
        }, '')

        // If the source is displayed in a collapsed state or not. True if in webapp and the text
        // (an array of all lines of the text) is longer than 19 lines.
        const lineCount = this.model.get('source').text?.length || 0
        this.isCollapsed = APPLICATION === 'webapp' && lineCount >= 20

        this.setElement(Template({
            Styles,
            title: Util.renderContentSafely(this.model.get('title')),
            subtitle: Util.renderContentSafely(this.model.get('source').subtitle),
            content: Util.renderContentSafely(content),
            // TODO: Research if tripple brackets is needed in HBS
            reference: Util.renderContentSafely(this.model.get('source').reference),
            isCollapsed: this.isCollapsed
        }));

        // Only use source fold button in webapp.
        if (this.isCollapsed) {
            this.sourceFoldButton = this.addChildView(new Button({
                label: this.getButtonLabel(),
                icon: 'arrow-down',
                inline: true,
                callback: this.foldSource
            }), '.js-fold-btn');
        }

        // Disable speech if explicitly disabled or lineCount is 1 or less.
        if (!options.disableSpeech && lineCount > 1) {
            this.addChildView(new TextToSpeechSource({
                sourceId: this.model.id,
                audioPlayerTargetElement: this.$('.js-speech-player'),
            }), '.js-get-speech-button')
        }

        RenderSpecialElements({}, this)

    },

    /**
     * foldSource
     *
     * Expands and collapses source into collapsed and full height versions with an fancy animation
     * when the user presses the expand/collapse button.
     */

    foldSource() {
        // Invert collapsed state.
        this.isCollapsed = !this.isCollapsed;

        if (this.isCollapsed) {
            // Collapse to max height of 500px and remove rotation from arrow icon in button.
            TweenMax.to(this.$('.js-outer'), {
                height: 500,
                duration: 0.55,
                ease: 'power3.inOut'
            })
            this.$('.js-outer').toggleClass(Styles['outer--collapsed'], this.isCollapsed)
            this.sourceFoldButton.$('svg').css({
                transform: ''
            });
        } else {
            // Expand to default height and rotate arrow icon in button upside down.
            this.$('.js-outer').css({
                height: 'auto'
            });
            TweenMax.from(this.$('.js-outer'), {
                height: 500,
                duration: 0.55,
                ease: 'power3.inOut',
                onComplete: () => {
                    this.$('.js-outer').toggleClass(Styles['outer--collapsed'], this.isCollapsed)
                }
            })
            this.sourceFoldButton.$('svg').css({
                transform: 'rotate(180deg)'
            });
        }

        // Set button label to descibe the next action of the button.
        this.sourceFoldButton.changeLabel(this.getButtonLabel());
    },

    /**
     * getButtonLabel
     *
     * Set button label to descibe the next action of the button.
     *
     * @return {string}  button label text
     */
    getButtonLabel() {
        return this.isCollapsed ? window.i18n.gettext('Expand source') : window.i18n.gettext('Collapse source');
    }

});
