var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " tile__navigation__btn--locked";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <strong class=\"js-answers-amount\"></strong>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <strong>\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answersVisible") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":76}}})) != null ? stack1 : "")
    + "\n        </strong>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"On",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":46}}}))
    + " ";
},"8":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Off",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":56},"end":{"line":8,"column":68}}}))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"js-answers-visible tile__navigation__btn"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isStudent.js")).call(alias1,{"name":"isStudent","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":110}}})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":27}}})) != null ? stack1 : "")
    + "\n    <span>"
    + container.escapeExpression(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Answers",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":26}}}))
    + "</span>\n"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isTeacher.js")).call(alias1,{"name":"isTeacher","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":10,"column":18}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});