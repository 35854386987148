import Template from './Analytics.hbs';
import AnalyticsStyles from 'views/components/taskGroups/tasks/analytics/Analytics.scss'
import Template2AnalyticsAnswerList from '../../template2/analytics/AnalyticsSummary'

export default class Template31Analytics extends BaseView {

    initialize({
        mistakes = [],
        taskModel,
    }) {

        const gaps = mistakes.reduce((m, mistake) => {
            const gap = _.findWhere(taskModel.get('task_info_json').text, {id: mistake.id})
            if (gap) {
                m.push({
                    id: gap.id,
                    mistakes: mistake.answers,
                    answer: gap.answers[0].text || '…',
                })
            }
            return m
        }, [])

        this.setElement(Template({
            gaps,
            Styles: AnalyticsStyles,
            cid: this.cid,
        }))

        this.$('nav input.js-gap').on('click', (e) => {
            const gap = _.findWhere(gaps, {id: parseInt(e.target.dataset.gapId)})
            this.destroyChildViewsOfInstance(Template2AnalyticsAnswerList)
            this.addChildView(new Template2AnalyticsAnswerList({list: gap.mistakes}), 'section')
        })

        this.$('nav input.js-gap').first().click()

    }

}
