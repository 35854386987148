<script>
    import Icon from '../icon/Icon.svelte';

    export let consumers = [];
    export let fromLoginScreen = false;

    const lastLocation = !fromLoginScreen
        ? encodeURIComponent(window.location.pathname)
        : null;

    const goTo = lastLocation ? `?goto=${lastLocation}` : '';

    const links = [
        {
            id: 2,
            href: '/preauth/entreesomtoday',
            label: 'Somtoday',
            icon: 'somtoday-color',
        },
        {
            id: 11,
            href: '/preauth/entreemagister',
            label: 'Magister',
            icon: 'magister-logo',
        }, {
            id: 4,
            href: '/preauth/entree',
            label: 'Entree',
            icon: 'entree-logo',
        },
        {
            id: 10,
            href: '/preauth/surfconext',
            label: 'SURFconext',
            icon: 'surf-logo-black',
        },
        {
            id: 5,
            href: '/preauth/google',
            label: 'Google',
            icon: 'google-logo-color',
        },
        {
            id: 6,
            href: '/preauth/office365',
            label: 'Microsoft',
            icon: 'office365-logo-color',
        },
    ];

    function hasConsumer(id) {
        return consumers.some((consumer) => consumer.consumer_id === id)
    }
</script>

<div class="sso-buttons" class:session-expired={!fromLoginScreen}>
    {#each links as { id, href, label, icon } (id)}
        {#if hasConsumer(id)}
            <a href={href + goTo} class="sso-button">
                {#if icon}
                    <div class="icon">
                        <Icon name={icon}></Icon>
                    </div>
                {/if}
                <div class="label">{label}</div>
            </a>
        {/if}
    {/each}
</div>

<style lang="scss">
    .sso-buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        background-color: transparent;

        .sso-button {
            display: flex;
            align-items: center;
            padding: 12px 16px;
            width: 100%;
            text-decoration: none;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.75);
            background-color: $white;
            border-radius: 12px;
            transition:
                color 0.25s ease-in-out,
                background-color 0.25s ease-in-out;

            &:hover {
                background-color: $background-gray;
                color: black;
            }
        }
    }

    .session-expired {
        border-radius: 12px;

        .sso-button {
            border: 1px solid $line-gray;
            border-radius: 12px;
        }
    }

    .icon :global(svg) {
        display: flex;
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }

    .label {
        display: flex;
        align-items: center;
    }
</style>
