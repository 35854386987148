import Template from './Source.hbs';
import Util from 'util/util';
import Source1View from 'views/components/taskGroups/sources/source1/Source1';
import Source2View from 'views/components/taskGroups/sources/source2/Source2';
import Source3View from 'views/components/taskGroups/sources/source3/Source3';
import Source4View from 'views/components/taskGroups/sources/source4/Source4';
import Source6View from 'views/components/taskGroups/sources/source6/Source6';
import Source7View from 'views/components/taskGroups/sources/source7/Source7';
import Source9View from 'views/components/taskGroups/sources/source9/Source9';
import Source12View from 'views/components/taskGroups/sources/source12/Source12';
import Source13View from 'views/components/taskGroups/sources/source13/Source13';
import Source14View from 'views/components/taskGroups/sources/source14/Source14';
import Source15View from 'views/components/taskGroups/sources/source15/Source15';
import Source35View from 'views/components/taskGroups/sources/source35/Source35';
import StatusCard from 'views/components/statusCard/StatusCard'

export default BaseView.extend({

    Source1View,
    Source2View,
    Source3View,
    Source4View,
    Source6View,
    Source7View,
    Source9View,
    Source12View,
    Source13View,
    Source14View,
    Source15View,
    Source35View,

    events: {
        'click .js-optional-source': 'fold'
    },

    /**
         * initialize
         *
         * this.model {SourceModel}                     Model containing information on the source element.
         *
         * @param {BaseView} work_on                    Parent view of the parent TaskGroup view of this source view.
         * @param {ActivityModel} activityModel         Activity model
         * @param {boolean|undefined} disableSpeech     If true, disable speech reading for this source.
         * @param {boolean|undefined} isLearningText    If true, source is part of a learningtext context,
         *                                              not show view.
         * @param {boolean|undefined} isPreview         If true, parent activity is in preview mode, meaning it's
         *                                             read-only and answers cannot be entered or modified.
         */
    initialize({
        work_on,
        activityModel,
        disableSpeech,
        isLearningText,
        isPreview
    }) {
        // Disable speech if explicitly disabled or if course does not support it.
        disableSpeech = disableSpeech ?? !this.isCourseWithSpeech(activityModel)

        this.renderSource({
            work_on,
            activityModel,
            disableSpeech,
            isLearningText,
            isPreview
        })
    },

    /**
         * renderSource
         *
         * @param {Object} options      source view options. See the initialize method for documentatin.
         */
    renderSource(options) {
        try {

            const isPresentation = options.activityModel?.get('type') === 'presentation'

            this.templateView = new this['Source' + this.model.get('template_id') + 'View']({
                model: this.model,
                work_on: options.work_on,
                disableSpeech: options.disableSpeech,
                isPresentation,
                isPreview: options.isPreview
            });

            this.correctTables()

            const displayFolded =
                    this.model.get('is_additional') &&
                    APPLICATION === 'webapp' &&
                    !options.isLearningText

            this.setElement(Template({
                id: this.model.id,
                is_additional: displayFolded,
                branchId: this.model.get('theory_branch_id'),
                sourceIcon: this.model.getElementIcon(),
                sourceType: this.model.getElementName(),
                title: Util.renderContentSafely(this.model.get('title')),
                isPresentation,
            }));

            this.contentElement = this.$('.js-content');

            this.registerChildView(this.templateView);
            this.templateView.appendTo(this.contentElement);

            if (displayFolded) {
                this.contentElement.hide();
                this.contentElement.css({
                    overflow: 'hidden'
                });
            }

            if (options.isLearningText) {
                this.templateView.$el.removeClass('center-panel');
            }

        } catch (e) {

            e.name = 'Source template not found'

            window.sentry.withScope(scope => {
                scope.setExtra('type', 'Source')
                scope.setExtra('source_template_id', this.model.get('type'))
                scope.setExtra('id', this.model.get('id'));
                window.sentry.captureException(e)
            })

            // Useful for local debugging, will be stripped from production built
            // because it is overwritten by Sentry
            console.error(e)

            // show the user a warning that the task can not be loaded
            this.setElement(Template({
                hasInvalidTemplate: true,
            }))

            this.addChildView(
                new StatusCard({
                    icon: 'alert',
                    statusColor: 'red',
                    cardContent: window.i18n.gettext('This source could not be loaded.'),
                }),
                '.js-content'
            )
        }

    },

    /**
         * Prevent tables created by TinyMCE from overflowing containing element
         */
    correctTables() {

        const tables = this.templateView.el.querySelectorAll('table')

        if (!tables.length) {
            return
        }

        _.each(tables, tableElement => {
            const maxTableLength = tableElement.style.width

            if (!maxTableLength) {
                return
            }

            tableElement.style.width = ''
            tableElement.style.maxWidth = maxTableLength

        })

    },

    fold() {
        if (this.contentElement.is(':visible')) {
            TweenMax.to(
                this.contentElement, {
                    height: '0px',
                    opacity: 0,
                    overflow: 'hidden',
                    ease: 'power2.inOut',
                    duration: 0.25,
                    onComplete: () => {
                        // On complete, clear all inline styles and hide.
                        this.contentElement.attr('style', 'display:none');
                        this.$el.addClass('source--optional');
                    }
                }
            );
            this.$('.optional-source__arrow').removeClass('optional-source__arrow--active');
        } else {
            this.contentElement.show();
            TweenMax.from(
                this.contentElement, {
                    height: '0px',
                    opacity: 0,
                    overflow: 'hidden',
                    ease: 'power2.inOut',
                    duration: 0.25,
                    onComplete: () => {
                        // On complate, reset inline styles.
                        this.contentElement.attr('style', '');
                        this.$el.removeClass('source--optional');
                    }
                }
            );
            this.$('.optional-source__arrow').addClass('optional-source__arrow--active');
        }
    },

    isCourseWithSpeech(activityModel) {
        // TODO check on language code encoded on source model instead of course type in activity model
        if (!activityModel) {
            return false
        }

        if (activityModel) {
            /* eslint-disable no-inline-comments */
            const unsupportedCourses = [
                40, // Latin
                60, // Greek
            ]
            const courseId = activityModel.getGroupModel()?.get('course_id')
            return !unsupportedCourses.includes(courseId)
        }
        return true
    }

});
