import Styles from './PresentationSource.scss';

import Template from './PresentationSource.hbs';
import OptionGroup from 'views/components/optionGroup/OptionGroup'
import Source from 'views/components/taskGroups/sources/Source'
import SidebarLearningText from 'views/pages/activities/show/sidebars/learningText/LearningText'

export default class PresentationSource extends BaseView {

    initialize({
        initialTaskModel
    }) {

        const sourceCount = this.model.elements.where({element_type: 'source'}).length
        const hasLearningTexts = _.size(this.model.learningTexts) > 0

        this.setElement(Template({Styles}))

        // Just open learning text or source if this is the only one in this task group.
        if (hasLearningTexts && sourceCount === 0) {
            this.openLearningText()
            return
        }
        if (!hasLearningTexts && sourceCount === 1) {
            this.openSource(this.model.elements.findWhere({element_type: 'source'}))
            return
        }

        let selectedIndex = 0

        const choices = []
        if (hasLearningTexts) {
            choices.push({
                title: this.model.getExplanationLabel(true),
                icon: 'learning-text',
                type: 'openLearningText',
            })
        }
        if (sourceCount) {
            for (const element of this.model.elements) {
                if (element.get('element_type') === 'source') {
                    choices.push({
                        title: element.getShortText(),
                        icon: element.getElementIcon(),
                        type: 'openSource',
                        model: element,
                    })
                }
            }
            // Find the first source that comes before the current task in the list of elements.
            for (let index = this.model.elements.indexOf(initialTaskModel); index >= 0; index--) {
                const elementModel = this.model.elements.at(index)
                if (elementModel && elementModel.get('element_type') === 'source') {
                    selectedIndex = _.findIndex(choices, {model: elementModel})
                    break
                }
            }
        }
        this.addChildView(new OptionGroup({
            choices,
            callback: ({type, model}) => {
                switch (type) {
                    case 'openLearningText':
                        this.openLearningText()
                        break
                    case 'openSource':
                        this.openSource(model)
                        break
                }
            },
            doCallbackOnInitialize: true,
            selectedIndex,
        }), '.js-source-nav')

    }

    openLearningText() {
        if (this.activeSourceView instanceof SidebarLearningText){
            return
        }
        this.closeSource()
        this.activeSourceView = this.addChildView(new SidebarLearningText({
            collection: this.model.learningTexts,
            activityModel: this.model.getActivityModel(),
        }), '.js-source')
    }

    openSource(elementModel) {
        if (this.activeSourceView?.model === elementModel) {
            return
        }
        this.closeSource()
        this.activeSourceView = this.addChildView(new Source({
            model: elementModel,
            activityModel: this.model.getActivityModel(),
        }), '.js-source')
    }

    closeSource() {
        if (this.activeSourceView) {
            this.activeSourceView.destroy()
            delete this.activeSourceView
        }
    }

}
