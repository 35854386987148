import Styles from './ResponseOverview.scss';

import Template from './ResponseOverview.hbs';
import ResponseBlock from 'views/pages/activities/show/types/presentation/parts/responseOverview/responseBlock/ResponseBlock';
import Spinner from 'views/components/spinner/Spinner';

export default BaseView.extend({

    initialize(options) {
        _.bindAll(
            this,
            'fetchResponses',
            'renderResponses',
            'onFetchComplete'
        );

        this.presentation = options.presentation;
        this.taskModel = options.taskModel;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        this.fetchResponses();

    },

    /**
         * fetchResponses
         *
         * This function will the repsonses from the backend.
         *
         */
    fetchResponses() {

        // Create a spinner that is active during the post request
        this.spinner = this.addChildView(
            new Spinner(),
            '.js-spinner'
        );
        this.$('.js-spinner').show();

        // Create model that will hold all responses
        this.responseModel = new Backbone.Model();

        // Listen to any changes to the model, and then render responses
        this.listenTo(this.responseModel, 'change', this.renderResponses);

        // Fetch responses data and save this process inside variable
        this.responseModel.fetch({
            url: '/activities/get_presentation_answers/' + this.model.id + '/' + this.taskModel.id + '.json',
            success: this.onFetchComplete
        });
    },

    /**
         * onFetchComplete
         *
         * When the GET request is completed it will stop the spinner and
         * check if there are zero responses passed from the backend.
         *
         */
    onFetchComplete() {

        // Destroy spinner
        this.spinner.destroy();

        // And hide container
        this.$('.js-spinner').hide();

        // If the change object in the response model is empty
        if (_.isEmpty(this.responseModel.changed)) {

            // There are no responses to show.
            // We can therefore tell the response block we have no responses.
            this.renderResponseBlock(undefined, 0);

            // Trigger event that animates the response block in to the response overview
            this.animateResponseBlock();
        }
    },

    /**
         * renderResponses
         *
         * When the responses are succesfully fetched from the backend,
         * they will be constructed as response items.
         *
         */
    renderResponses() {
        this.destroyChildViewsOfInstance(Spinner);
        this.destroyChildViewsOfInstance(ResponseBlock);

        // If the change object in the response model is empty
        if (_.isEmpty(this.responseModel.changed)) {

            // There are no responses to show.
            // We can therefore tell the response block we have no responses.
            this.renderResponseBlock(undefined, 0);

        } else {

            // Simplify the response object
            var responses = this.responseModel.attributes;

            // This variable will hold the total amount of responses
            var responseTotal = 0;

            // If there any responses received from the backend
            if (_.size(responses)) {

                // Check if there is no array of answers inside the responses object
                // This is done with a gap text open f.e.
                if (_.isUndefined(_.first(responses).answers)) {

                    // Get the total of given responses
                    responseTotal = this.getTotalCountOfArray(responses);

                    // Render a response block that will hold all responses
                    this.renderResponseBlock(responses, responseTotal);

                } else {

                    // Loop through the responses array
                    _.each(responses, function(answerOption) {

                        // If there are multiple arrays of answers,
                        // we need to get the responseTotal again per iteration
                        responseTotal = this.getTotalCountOfArray(answerOption.answers);

                        // Render a response block that will hold the answer option
                        this.renderResponseBlock(answerOption.answers, responseTotal);

                    }, this);
                }

            }
        }
        this.animateResponseBlock();

    },

    /**
         * getTotalCountOfArray
         *
         * This function gets all the count properties inside an array of objects.
         *
         * @param  {Array} array an array of objects with count properties
         * @return {number}     the total number of responses given
         */
    getTotalCountOfArray(array) {

        var responseTotal = 0;

        // Pluck the count attribute
        var countArray = _.pluck(array, 'count');

        // Go through the count array
        _.each(countArray, function(countObject) {

            // And add the count values
            responseTotal = responseTotal + countObject;
        });

        // Return the new total
        return responseTotal;
    },

    /**
         * renderResponseBlock
         *
         * This function renders a block that will hold all the responses for one answer option.
         *
         * @param  {Array} answerOption  - list of responses given for answer option
         * @param  {number} responseTotal - number of responses given per answer option
         */
    renderResponseBlock(answerOption, responseTotal) {
        var responseBlockContainer = '.js-response-block';

        this.addChildView(
            new ResponseBlock({
                responses: answerOption,

                responseTotal,

                slideState: this.presentation.slideState,

                taskModel: this.taskModel,

                parent: this

            }), responseBlockContainer
        );
    },

    animateResponseBlock() {
        this.destroyChildViewsOfInstance(Spinner);

        var responseBlock = this.$('.js-response-block');

        // Get the animation distance for the x-axis
        var animateXTo = responseBlock.width();

        // Start a new animation to slide the new taskgroup in
        TweenMax.fromTo(responseBlock, {

            // Start at a reversed position of x
            x: animateXTo,

        }, {
            // Go to a zero position
            x: 0,
            duration: 0.2,
            ease: 'expo.inOut'
        });
    }

});
