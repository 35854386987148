<script>
    import ContentLabelModel from 'models/ContentLabelModel';
    import Icon from '../icon/Icon.svelte';
    import Avatar from '../avatar/Avatar.svelte';

    // Model for group, student, chapter, etc to base the label on
    export let model

    // Optional text before the label
    export let labelPrefix = ''

    // Optional text after the label
    export let labelSuffix = ''

    // If possible, use a short version of the index. Activity can be B instead of 1.3 B
    export let isCompact = false

    // Use 14px instead of 16px for font size
    export let smallText = false

    // Style the label for displaying on a dark background
    export let isOnBackground = false

    // URL to use if the label should be an a tag with href attribute
    export let link = null

    // Tree to base the label index on
    export let publishedContentTree = null

    // For users, start with their first name instead of last name
    export let displayFirstNameFirst = false

    // Use ellipsis when name does not fit (instead of expanding the label)
    export let truncateName = false

    const tagName = link ? 'a' : 'div'
    const contentLabelModel = new ContentLabelModel(
        null, {
            layerModel: model,
            model,
            isCompact,
            publishedContentTree,
            displayFirstNameFirst,
        })
    const name = contentLabelModel.get('name')
    const title = contentLabelModel.get('title')
    const index = contentLabelModel.get('indexForContentLabelComponent')
    const isNumberless = contentLabelModel.get('isNumberless')

    const icon = setIcon()
    const avatar = setAvatar()

    function setIcon() {
        if (isNumberless) {
            return 'arrow-forward'
        }
        if (model.constructor.type === 'group') {
            return 'people'
        }
        if (model.constructor.type === 'activity' && model.get('type') === 'adaptive_student') {
            return 'exercises'
        }
    }

    function setAvatar() {
        if (['student', 'teacher', 'user'].includes(model.constructor.type)) {
            return model.get('avatar')
        }
    }
</script>

<svelte:element
    this={tagName}
    href={link}
    class="label"
    class:small-text={smallText}
    class:is-on-background={isOnBackground}
    class:is-clickable={link}
    {title}>

    {#if labelPrefix}
        <span class="prefix">{labelPrefix}</span>
    {/if}

    <span class="index" class:has-avatar={avatar}>
        {#if avatar}
            <Avatar {avatar} {model}></Avatar>
        {:else if icon}
            <Icon name={icon}></Icon>
        {:else if index}
            {index}
        {/if}
    </span>

    <span component="content-label__name" class:truncate-name={truncateName}>{name}</span>

    {#if labelSuffix}
        <span class="suffix">{labelSuffix}</span>
    {/if}

</svelte:element>

<style lang="scss">
    @import "src/styling/globals.scss";

    .label {
        @include large-text;
        color: $blue-black;

        text-decoration: none;

        display: inline-flex;
        align-items: center;
        min-width: 0;
        max-width: 100%;

        &.small-text {
            @include normal-text;
        }

        &.is-clickable {
            cursor: pointer;

            &:hover {
                color: $blue;
            }
        }

        &.is-on-background {
            color: $white;

            .index {
                background: $blue-black-50;
                color: $white;
            }

            &.is-clickable:hover {
                color: $white;
                text-decoration: underline;

                .index {
                    background: $blue-black;
                    color: $white;
                }
            }
        }

        .prefix {
            margin-right: 8px;
            color: currentColor;
        }

        .index {
            display: inherit;
            align-items: inherit;
            justify-content: center;
            flex-shrink: 0;
            padding: 8px;
            margin-right: 8px;

            // Make sequnce square-ish.
            // Approximate min width of the label 8px padding + 16px content
            min-width: calc(8px + 16px + 8px);

            border-radius: $label-border-radius;
            background: $blue-10;
            color: $blue;
            white-space: nowrap;
            line-height: 1;

            &.has-avatar {
                padding: 0;
                border: 0;
                background: transparent;
                min-width: 0;
            }

            :global(svg) {
                width: 16px;
                height: 16px;
                fill: currentColor;
            }
        }

        .truncate-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .suffix {
            margin-left: auto;
            text-align: right;
        }

    }

    @media print {
        .label {
            color: $blue-black;
            font-size: 1em;

            .index {
                color: inherit;
                background: none;
            }
        }
    }
</style>
