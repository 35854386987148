import Styles from './LicensePanel.scss';

import Template from './LicensePanel.hbs';
import OrderForm from 'views/pages/licenses/orderForm/OrderForm';
import ActivationCodeForm from 'views/pages/licenses/activationCodeForm/ActivationCodeForm';

export default class LicensePanel extends BaseView {

    initialize() {

        Backbone.View.header.setTitle(this.model.get('name'))
        Backbone.View.header.setCrumblepath(
            this.model.getAllCrumblepathModels(),
            'show'
        )

        const productModel = this.model.get('Product');
        const activityStatus = this.model.get('status');

        this.setElement(Template({
            Styles
        }))

        // Remove all variant where price is 0, it can't be bought.
        productModel.Variants = (productModel.Variants || []).filter(
            variant => (variant.price_including_taxes || 0) !== 0
        );

        // Create a flat array with all pricings that are a number
        // and are not 0. This way we can check if there is anything
        // to be bought in a single check.
        const canBeBought = [
            (productModel.price_including_taxes || 0),
            ..._.pluck(productModel.Variants, 'price_including_taxes')
        ].filter(Number).length > 0

        // jscs:disable maximumLineLength
        switch (activityStatus) {
            case 'needs_payment':

                this.$('.js-instruction').html(window.i18n.sprintf(
                    window.i18n.gettext('You cannot access <strong>%s</strong> yet'),
                    productModel.name
                ))

                this.addChildView(
                    new ActivationCodeForm({
                        model: productModel,
                        schoolId: this.model.getGroupModel().get('school_id'),
                    }),
                    '.js-content'
                )

                if (canBeBought) {
                    this.addChildView(
                        new OrderForm({
                            model: productModel
                        }),
                        '.js-content'
                    )
                }

                break;

            case 'is_revoked':

                this.$('.js-instruction').html(window.i18n.sprintf(
                    window.i18n.gettext('Your access to <strong>%s</strong> is blocked'),
                    productModel.name
                ))
                this.$('.js-content').html(window.i18n.gettext('This may be caused by an overdue payment.') + ' <a href="mailto:support@dedact.nl">' + window.i18n.gettext('Please contact support') + '.</a>')

                break;

            // FIXME: This is for backwards compatibility in MBO retention. Can be removed after April 15th 2024.
            case 'renewal_required':
            case 'is_expired': {

                this.$('.js-instruction').html(window.i18n.sprintf(
                    window.i18n.gettext('You have no access to %s'),
                    '<strong>' + productModel.name + '</strong>'
                ))

                this.$('.js-instruction-small').text(window.i18n.gettext('Your license has expired.'))

                this.addChildView(
                    new ActivationCodeForm({
                        model: productModel,
                        activityModel: this.model.getGroupModel().get('school_id')
                    }),
                    '.js-content'
                )

                if (canBeBought) {
                    this.addChildView(
                        new OrderForm({
                            model: productModel
                        }),
                        '.js-content'
                    )
                }
            }
                break;

        }

    }

}
