import SourcesCollection from 'collections/SourcesCollection'
import PortfolioItemModel from 'models/PortfolioItemModel'
export default class PortfolioItemsCollection extends SourcesCollection {

    preinitialize() {
        this.constructor.type = 'portfolioItems'
    }

    get model() {
        return PortfolioItemModel
    }

}
