import Styles from './Lightbox.scss';

import Template from './Lightbox.hbs';
export default BaseView.extend({

    // Events for this view
    events: {

        // Click event for the whole modal
        click: 'onClickLightbox',

        // When clicked on close button, call function
        'click .js-close-lightbox': 'onClickCloseButton'
    },

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     This object is passed on creation
     */
    initialize({
        contextUrl = '',
        customElement
    }) {

        // Make this accessibile within the following function
        _.bindAll(this,
            'show'
        );

        this.setElement(Template({
            Styles,
            contextUrl,
        }))

        if (customElement) {
            // If the lightbox should show something that does not work as an <img> src,
            // you can append some other HTML string of Element instead.
            this.$('.js-image-container').append(customElement)
        }

        document.documentElement.classList.add('disable-scrolling')

        this.lightboxContainer = this.$('.js-lightbox-container');

        // Make image zoomable if needed
        const imageElement = this.el.querySelector('.js-image-element')
        imageElement?.addEventListener('load', () => {

            // An image is zoomable if it is less wide than its natural width and
            // the lightbox has horizontal space for it to grow
            if (imageElement.width < imageElement.naturalWidth &&
                imageElement.width < this.$el.width()) {
                imageElement.classList.add(Styles['zoomable'])
                imageElement.addEventListener('click', () => {
                    imageElement.classList.toggle(Styles['zoomed-in'])
                })
            }
        })

        // Attach itself to the DOM
        $('body').append(this.$el);

        _.defer(this.show);

    },

    show() {

        // Prepare elements for animations
        TweenMax.set(this.$el, {
            opacity: 0
        });
        TweenMax.set(this.lightboxContainer, {
            opacity: 0,
            scale: 0.8
        });

        // Animate elements
        TweenMax.to(this.$el, {
            opacity: 1,
            ease: 'expo.inOut',
            duration: 0.2
        });
        TweenMax.to(this.lightboxContainer, {
            opacity: 1,
            scale: 1,
            delay: 0.1,
            ease: 'expo.out',
            duration: 0.25
        })

    },

    /**
     * onClickLightbox
     *
     * This is the function which will handle the global click event for this lightbox.
     * It is used to close the modal when the user clicks outside of the container
     *
     * @param  {Object} e description
     * @return {boolean}   description
     */
    onClickLightbox(e) {

        // if the target of the click is a background container
        if ($(e.target).hasClass('js-background')) {

            // Close the modal
            this.onClickCloseButton();

            // Stop further execution of events
            return false;
        }

        // Continue execution of events
        return true;
    },

    /**
     * onClickCloseButton
     *
     * This function will be called when the lightbox should be closed.
     * For example when the user clicks on the close button.
     * It will destroy the lightbox view cleanly, it cannot be recovered
     *
     */
    onClickCloseButton() {

        // Animate elements
        TweenMax.to(this.lightboxContainer, {
            opacity: 0,
            scale: 0.8,
            ease: 'expo.out',
            duration: 0.25
        })

        TweenMax.to(this.$el, {
            opacity: 0,
            ease: 'expo.inOut',
            duration: 0.2,
            delay: 0.1,
            // Destroy this view
            onComplete: () => {
                this.destroy()
            }
        });
    },

    onDestroy() {
        document.documentElement.classList.remove('disable-scrolling')
    },
});
