import Styles from './BackgroundImage.scss';

import Template from './BackgroundImage.hbs';
export default class BackgroundImage extends BaseView {

    initialize({ imageUrl }) {
        this.setElement(Template({
            Styles,
            imageUrl
        }))
    }

}
