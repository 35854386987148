import Template from './Table.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import TableOptionsPopup from 'views/components/wysiwyg/parts/buttons/table/TableOptionsPopup.hbs';
import TableMakerModal from 'views/components/wysiwyg/modals/table/TableMaker';
import TableTemplate from 'views/components/wysiwyg/elements/table.hbs';

export default class Table extends Button {

    // Custom command
    command = 'insertTable'

    get toggleBorderLabel() {
        return this.border ? window.i18n.gettext('Remove border') : window.i18n.gettext('Add border')
    }

    initializeButton() {
        _.bindAll(this,
            'openPopupMenu',
            'closePopupmenu',
            'mouseUpDocument',
            'onClickTableOption'
        );

        this.icon = Template();
        this.tooltip = window.i18n.gettext('Table');
    }

    mouseUpDocument(e) {
        if (!this.el.contains(e.target)) {
            this.closePopupmenu();
        }
    }

    onClickButton(e) {
        // When the button has an active state
        if (this.isActive) {
            // Stop other logic to prevent weird behaviour
            e.preventDefault();

            // Open the popupmenu
            this.openPopupMenu(e);

            // When the button has an inactive state
        } else if (this.popup === undefined) {

            // Only do this if popup is closed, this fixes the issue where a row/column deletion
            // removes the active element which make this.isActive false while it should be true.
            // A click on the button or outside the popup will close the popup, so this will
            // happen when a user clicks outside of the table to create a new table.

            // Call the original onClickButton
            super.onClickButton.call(this, e)

        } else {
            // When menu is open
            // Close the popup menu since we lost focus
            this.closePopupmenu();
        }
    }

    closePopupmenu() {
        // Re-enable the tooltip
        this.disableTooltip = false;

        this.popup.find('.js-table-option-button').off('click', this.onClickTableOption);

        // Remove popup
        this.popup?.remove()
        delete this.popup

        // Remove click listener from Learnbeat document and TinyMCE iframe document.
        document.removeEventListener('click', this.mouseUpDocument)
        this.wysiwyg.editor.getDoc().removeEventListener('click', this.mouseUpDocument)
    }

    openPopupMenu(e) {
        // do not close the hidden buttons holder onClick
        e.stopPropagation();

        this.table = this.wysiwyg.editor.selection.getNode().closest('table');
        this.border = this.table.classList.contains('has-border');

        // Check if there isn't already a popup open
        if (this.popup === undefined) {
            this.popup = $(TableOptionsPopup({
                Styles: this.Styles,
                toggleBorder: this.toggleBorderLabel,
            }));
            this.$('.js-icon-holder').after(this.popup);

            // Disable tooltip so it does not go over the popup menu.
            this.disableTooltip = true;
            this.onDeHoverButton();

            // Add click listener to Learnbeat document and TinyMCE iframe document so the popup gets closed
            // when user clicks anywhere but the popup menu itself.
            document.addEventListener('click', this.mouseUpDocument)
            if (this.wysiwyg.editor) {
                this.wysiwyg.editor.getDoc().addEventListener('click', this.mouseUpDocument)
            }

            // Search in this.popup for symbol buttons and added click listener to is
            this.popup.find('.js-table-option-button').on('click', this.onClickTableOption);

            // Right align instead of left align popup when it is too close to right edge of the editor toolbar.
            this.popup.css({
                right: this.$el.width() * this.$el.index() > this.popup.width() ? '-1px' : 'unset'
            })

        } else if (!this.popup[0].contains(e.target)) {
            // Close when popup is open and clicked target is not popup.
            this.closePopupmenu();
        }
    }

    onClickTableOption(e) {
        // Prevent table modal from opening
        this.stopAllEvents(e);

        // Distill the symbol from the clicked element
        const tableOptionCommand = e.currentTarget.dataset.command;
        if (tableOptionCommand === 'toggleBorder') {
            this.toggleBorder();
        } else {

            // Check if the popup should be closed
            var closePopupMenu = e.currentTarget.dataset.once

            // Store the default command into a var
            var defaultCommand = this.command;

            // Update the command with the command of the tableOption
            this.command = tableOptionCommand;

            // Execute the MCE command
            this.wysiwyg.onMceCommand(this);

            // Restore the default command
            this.command = defaultCommand;
        }

        // If the popupmenu should be closed
        if (closePopupMenu) {
            this.closePopupmenu();
        }
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        const customEl = editorObject.customEl
        const customSH = editorObject.customSH

        // Add the command for inserting images
        editor.addCommand(this.command, () => {

            // Create a customElement of the type modalComponentElement
            customEl.modalComponentElement(

                // Set the modal title
                window.i18n.gettext('Create a table'),

                // Pass the to be inserted template
                TableTemplate,

                // Define the to-be-opened modal(view)
                TableMakerModal,

                // Pass the editor object
                editor
            );
        });

        // Create the state handler for this block element
        customSH.TableHandler(
            // Listen to command: insertTable
            this.command,

            // Pass the editor with it
            editor
        )
    }

    toggleBorder() {
        this.border = !this.border
        this.table.classList.toggle('has-border', this.border)
        this.$('.js-toggle-border').text(this.toggleBorderLabel)
    }

}
