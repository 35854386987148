import Styles from './Template23.scss';

import Template from './Template23.hbs';
import AudioPlayer from 'views/components/audioPlayer/AudioPlayer'
import AudioRecorder from 'views/components/audioRecorder/AudioRecorder'

export default BaseView.extend({

    /**
     * initialize
     *
     * @param {Object} Options Options object.
     * @param {Backbone.View} options.task_view Task component
     */
    initialize({
        task_view
    }) {

        // Make the taskview from options accesible in the this scope
        this.taskView = task_view;

        _.bindAll(
            this,
            'lockAnswer',
            'unlockAnswer',
            'showAnswer'
        );

        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {

        this.setElement(Template({
            Styles
        }));

        const response = this.taskView.response.get('json_answer');

        this.audioRecorder = this.addChildView(new AudioRecorder({
            uploadType: 'task-audio',
            onRecordingUploaded: (uploadModel) => {
                this.saveAnswer(uploadModel)
            },
            onRecordingDeleted: () => {
                this.saveAnswer()
            },
            audioPlayerOptions: {
                url: response?.url
            }
        }), this.$el, 'prepend')

    },

    /**
     * saveAnswer
     *
     * This function is triggered when a response has to be saved.
     * Depending on response type, the data is prepared so it can be uploaded to the server
     * and converted to MP3.
     *
     * @param  {UploadModel} uploadModel the response data object
     */
    saveAnswer(uploadModel) {

        if (!uploadModel) {

            // Immediately set the response url as null
            this.taskView.saveResponse({
                url: null
            });

        } else {

            this.taskView.saveResponse({
                url: uploadModel.get('eduFileUrl')
            })

        }

        if (this.taskView.response.get('need-scoring') === 0) {
            this.taskView.response.set('correction', true);
        }
    },

    /**
     * showAnswer
     *
     * This function will show the modelAnswer audio and the explanation.
     * It will also trigger the scoring options if an explanation or model answer is set.
     *
     */
    showAnswer() {

        // If the task model holds a model answer
        if (this.model.get('task_info_json').answer) {

            // Create new modelAnswer partial
            this.modelAnswer = this.addChildView(new AudioPlayer({
                title: window.i18n.gettext('Model answer'),
                url: '/edu_files/open/' + this.model.get('task_info_json').answer,
                style: 'small',
            }), '.js-model-answer-holder');
            this.modelAnswer.el.classList.add(Styles['audio-player--padded'])
        }

        // TODO Fix bug where answer is not yet set in the model when showing score options.
        // Because the audio file needs to be converted to MP3 and saved as an answer,
        // sometimes the answer will not be set yet in the response model when this function is called.
        // This can lead to the score options not showing,
        // because the json_answer in the response model is still undefined.
    },

    showAuthorAnswer() {},

    /**
     * lockAnswer
     *
     * This functions locks the delete button so the student can't
     * record a new answer before he scored his original answer.
     * It will also add a click event to the delete button which opens a
     * statusmessage informing the user he should score first.
     */
    lockAnswer() {
        this.audioRecorder.disable()
    },

    /**
     * unlockAnswer
     *
     * This function makes it possible for the user again to fill a response again.
     *
     */
    unlockAnswer() {
        this.audioRecorder.enable()
    },

    /**
     * hideAnswer
     *
     * This function hides the modelanswer, explanation and scoring options.
     *
     */
    hideAnswer() {
        if (this.modelAnswer) {
            this.unregisterAndDestroyChildView(this.modelAnswer);
        }
    },

    /**
     * getCorrectAnswer
     *
     * This function will check if an answer audio is included in the model.
     * It will then add it to the correct answer element in the checkAnswers view.
     *
     * @return {Object} - The Audio Player view
     */
    getCorrectAnswer() {
        var answerAudio;
        if (this.model.get('task_info_json').answer) {
            answerAudio = new AudioPlayer({
                url: '/edu_files/open/' + this.model.get('task_info_json').answer,
                style: 'small'
            });
            this.registerChildView(answerAudio)
            answerAudio.$el.addClass(Styles['audio-player--padded']);
        }

        return answerAudio;
    },

    /**
     * getStudentAnswer
     *
     * This function gets the answer from a student out of the response model.
     * If an answer was given, an audio player will be initiated.
     *
     * @param {Object} responseModel
     * Object containing response, which may look like {url: "/edu_files/open/123456/exampleHash"}.
     * @return {AudioPlayer|String} recordedAnswer
     * An instance of the audio player containing the response or an empty string if no answer is given.
     */
    getStudentAnswer(responseModel) {
        if (responseModel.get('json_answer')?.url) {
            // Construct new audio player
            const audioPlayer = new AudioPlayer({
                // Pass the created url with the Blob
                url: responseModel.get('json_answer').url,
                // Render the smaller version of the Audioplayer
                style: 'small'
            })
            this.registerChildView(audioPlayer)
            audioPlayer.$el.addClass(Styles['audio-player--padded'])
            return audioPlayer
        }
        return ''
    }

});
