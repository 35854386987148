import Styles from './Button.scss';

import Template from './Button.hbs';
// https://www.tiny.cloud/docs/tinymce/6/custom-toolbarbuttons/
// Base class for toolbar buttons. Create a class that extends this class when adding a new button.
export default class WysiwygButton extends BaseView {

    // Editor command that this button should execute
    // This can one built-in to TinyMCE (https://www.tiny.cloud/docs/tinymce/6/editor-command-identifiers/) or a
    // custom one, in which case its behaviour should be defined in the `addCommands` method.
    command = ''
    // commandQueryName is used to determine if the button state should be active depending on current
    // selection/cursor position. Value of `command` is used for this if `commandQueryName` is empty.
    commandQueryName = ''

    // Every toolbar button has these event listeners.
    get events() {
        return {
            // Click on whole titleitem, call the callback which is bound to this view via the
            // options which are extended within the 'this' element
            click: 'onClickButton',

            // Register a mousedown event to prevent moving focus
            mousedown: 'onMouseDown',
        }
    }

    initialize({
        wysiwyg
    }) {

        // Make options accessible within this
        _.bindAll(this,
            'setActive',
            'setDeactive',
            'onHoverButton',
            'onDeHoverButton'
        );

        // Make parents view (wysiwyg) accessible within methods
        this.wysiwyg = wysiwyg;

        // Make styles accessible in methods and childviews
        this.Styles = Styles;

        // Initialize the button
        this.initializeButton();

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles,
            tooltip: this.tooltip,
            icon: this.icon,
            cid: this.cid
        }));

        // When the button gets a hover, execute onHoverButton.
        // when loses hover execute onDeHoverButton
        // Use specific events for hover to define hover states since 'hover' also has the
        // event 'touched' inside of it. This causes tooltips to show instead of the buttons to trigger
        // when the button is touched. Read more at http://stackoverflow.com/a/22444532
        this.$el.on('mouseenter touchstart', this.onHoverButton);
        this.$el.on('mouseleave touchmove click', this.onDeHoverButton);

    }

    /**
     * getActiveState
     *
     * Function to get the active state of the button. If it has the class active
     * it will return true else it will return false.
     *
     * @return {boolean}  Boolean whether the button is active or not
     */
    getActiveState() {
        return this.$el.hasClass(Styles['is-active']);
    }

    toggle(isActive = this.isActive) {
        if (isActive) {
            this.setActive()
        } else {
            this.setDeactive()
        }
    }

    /**
     * setActive
     *
     * Function for making the button active.
     *
     */
    setActive() {
        this.$el.addClass(Styles['is-active']);
        this.isActive = true;
    }

    /**
     * setDeactive
     *
     * Function for making the button deactive.
     *
     */
    setDeactive() {
        this.$el.removeClass(Styles['is-active']);
        this.isActive = false;
    }

    /**
     * addCommands
     *
     * Create an empty click handler function. This can be overriden by views
     * who are extending this button class. And it will be called when the editor
     * is setup
     *
     */
    addCommands() {}

    /**
     * onHoverButton
     *
     * This function will be called when the buttons gets a hover events.
     * It will create a tooltip element and show this to the user.
     *
     */
    onHoverButton() {

        // When the tooltip is not disabled
        if (!this.disableTooltip) {

            // Make the tooltip visible for the user by changing display none to inline-block
            this.$('.js-tooltip').css('display', 'inline-block');
        }
    }

    /**
     * onDeHoverButton
     *
     * This function will be called when the button loses it's focus. It
     * will remove the created tooltip, which is create in the onHoverButton
     * function.
     *
     */
    onDeHoverButton() {

        // Make the tooltip invisible for the user by changing display inline-block to none
        this.$('.js-tooltip').css('display', 'none');
    }

    /**
     * onMouseDown
     *
     * This function excists for the only reason to block events.
     * This will prevent moving the focus
     *
     * Trick found in comment at:
     * http://stackoverflow.com/a/12154976
     *
     * Fiddle: http://jsfiddle.net/Ddffh/103/
     *
     * @param  {Object} e Mousedown event object
     */
    onMouseDown(e) {

        // Prevent weird behaviour by blocking default actions
        e.preventDefault()
    }

    /**
     * onClickButton
     *
     * This function will be called when the button gets a click event.
     * It will call the button's specific execution function if it exists
     * and then will execute the TinyMCE command.
     *
     * @param  {Object} e Click event object
     */
    onClickButton(e) {

        // Prevent weird behaviour by blocking default actions
        this.stopAllEvents(e);

        // Execute the MCE command using the onMceCommand function from the
        // wysiwyg.js using the this.command. This.command is the TinyMCE
        // command that should be executed on a click and should be defined
        // in the child views who are extending this view
        this.wysiwyg.onMceCommand(this);
    }
}
