var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <header class=\"js-student-selector no-print "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"student-selector") : stack1), depth0))
    + "\"></header>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"container") : stack1), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isTeacher.js")).call(alias3,{"name":"isTeacher","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":22}}})) != null ? stack1 : "")
    + "        <div class=\"js-evaluations-archive "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"evaluations") : stack1), depth0))
    + "\" data-empty=\""
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"This student has no prior evaluations",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":6,"column":79},"end":{"line":6,"column":125}}}))
    + "\"></div>\n        <div class=\"js-open-evaluation "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"open-evaluation") : stack1), depth0))
    + "\"></div>\n        <div class=\"js-start-evaluation-button "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"start-evaluation") : stack1), depth0))
    + "\"></div>\n    </div>\n</div>\n";
},"useData":true});