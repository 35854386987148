import Styles from './Presentation.scss';

import Template from './Presentation.hbs';
/**
* Presentation activity
*
* Key variables:
*
* slideState: A task inside a presentation can have several states.
* Each state is presented as an individual slide.
* By navigating the user can view the different states of task.
*  - 0, AKA filling answers: represents the phase where students perform the task and fill an answer
*  - 1, AKA indexing answers: represents the phase where the responses are displayed (but not checked)
*  - 2, AKA checking answers: represents the phase where the student answers are checked
*
* activeItem: The active item represents the task, source or summary view inside the presentation container
**
* studentsThatFilledAnswer: When the teacher is giving a presentation and the active item is a task, this list will
* keep track of the students that have given an answer.
*
* elementsInSlide: An array that keeps up which elements a slide includes. This is done to not repeatably
* apply the same logic when rendering elements in the slide.
*
* responseOverview: A sidebar that pops up when the user is inside task state 1 or 2 (except for an open question).
* The sidebar gives an overview of the student responses and checks them in state 2.
*
* sendStudentSidebar: A sidebar that the teacher can use to pull students into the presentation
*
*/

import TaskGroup from 'views/components/taskGroups/TaskGroup'
import Toolbar from 'views/pages/activities/show/types/presentation/toolbar/Toolbar'
import Summary from 'views/pages/activities/show/types/presentation/summary/Summary'
import AnswerCounter from 'views/pages/activities/show/types/presentation/answerCounter/AnswerCounter'
import ResponseOverview from 'views/pages/activities/show/types/presentation/parts/responseOverview/ResponseOverview'
import Template2ResponseOverview from 'views/pages/activities/show/types/presentation/parts/responseOverview/template2/Template2ResponseOverview'
import SendStudentSideBar from 'views/pages/activities/show/types/presentation/sidebar/SendStudentSidebar'

import FullscreenMode from 'util/FullscreenMode'
import StandbyMode from 'util/StandbyMode'
import Util from 'util/util'

export default BaseView.extend({

    initialize(options) {
        _.bindAll(
            this,
            'onNavigationKeyUp',
            'renderActiveSlide',
            'lockStudentAnswers',
            'unlockStudentAnswers',
            'toggleAnswer',
            'renderStudentsInPresentation',
            'checkIfAnswerCounterShouldRender',
            'renderStudentItems'
        );

        if (Backbone.View.menubar) {
            Backbone.View.menubar.$el.hide()
        }

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle()
        Backbone.View.header.setCrumblepath()
        Backbone.View.header.hide()

        // When a teacher is presenting:
        // - the teacher should whisper events in the presence channel
        // - the students should follow the slides of the teacher
        this.teacherIsPresenting = false

        this.isTeacher = Backbone.Model.user.get('is_teacher');
        this.taskGroupModels = options.model.task_groups;

        if (Backbone.Model.user.get('is_teacher')) {
            this.studentsThatFilledAnswer = [];
            this.teacherId = Backbone.Model.user.id;
        }

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        this.slideContainer = this.$('.js-slide');

        // Create toolbar
        this.toolbar = this.addChildView(
            new Toolbar({
                model: this.model,
                presentation: this
            }), '.js-toolbar'
        );

        // When user is student
        if (Backbone.Model.user.get('is_student')) {
            this.listenTo(this, 'student-saved-presentation-answer', this.onSaveStudentAnswer);
        }

        // Check if URL has less than 2 params
        if (options.params.length < 2) {

            // There is no taskgroup set in url,
            // therefore load the summary
            this.initSummary(true);

            this.taskGroupId = 0;

            this.slideState = 0;

        } else {

            // Take the taskgroup id from the second param
            this.taskGroupId = parseInt(options.params[1]);

            // If no task state is passed a param
            if (options.params.length < 3) {

                // Set the slideState to default 0
                this.slideState = 0;
            } else {

                // Take the slideState from third param
                this.slideState = parseInt(options.params[2]);
            }

            // Call function otherwise some task/source elements are not rendered well in the slide
            _.defer(this.renderActiveSlide);

            // Append active slide deferred because activeSlide
            // can still be undefined when trying to append
            _.defer(() => {
                if (this.activeSlide) {
                    this.activeSlide.appendTo(this.slideContainer);
                }
            })

            if (
                this.slideState >= 1 &&
                this.isTeacher
            ) {
                // animate the response overview
                this.animateResponseOverview();
            }

            if (this.taskGroupId > 0) {

                // When call stack has finished and any tasks are loaded,
                // check if answers need to be toggled
                _.defer(this.toggleAnswer);

                // Update navigation indicators inside the toolbar
                this.updateToolbar();

            }

        }

        if (Backbone.Model.user.get('is_student')) {
            _.defer(this.renderStudentItems);
        }

        // Join a presence channel for this activity
        this.channel = Backbone.Model.user.echo.join('presentation.' + this.model.id)

        if (Backbone.Model.user.get('is_teacher')) {
            this.studentsInPresentation = new Backbone.Collection()
            this.studentsInPresentation.on('add', () => {
                this.renderStudentsInPresentation()
                this.updateSlideOfStudents()
            })
            this.studentsInPresentation.on('remove', () => {
                this.renderStudentsInPresentation()
            })

            this.channel.here((members) => {
                this.studentsInPresentation.reset()
                for (const member of members) {
                    this.studentsInPresentation.add(Backbone.Collection.students.get(member.id))
                }
            })
            this.channel.joining((member) => {
                this.studentsInPresentation.add(Backbone.Collection.students.get(member.id))
            })
            this.channel.leaving((member) => {
                this.studentsInPresentation.remove(Backbone.Collection.students.get(member.id))
            })

            this.channel.listenForWhisper('studentSubmittedAnswer', (data) => {
                this.onStudentSubmittedAnswer(data)
            })

            // Create keyup listener to allow quick toggling between preview and author mode
            $(document).on('keyup', this.onNavigationKeyUp);
        }

        if (Backbone.Model.user.get('is_student')) {
            this.channel.listenForWhisper('changePresentationSlide', (data) => {

                // If we receive this event for the first time, go in follow mode
                if (!this.teacherIsPresenting) {
                    this.teacherIsPresenting = true

                    this.toolbar.onIsFollowingPresentation()

                    if (this.activeSlide?.onIsFollowingPresentation) {
                        this.activeSlide.onIsFollowingPresentation()
                    }
                }

                this.onSocketChangeStep(data)
            })
        }

        StandbyMode.disableAutoStandby()
    },

    onNavigationKeyUp(e) {
        // Do not respond when focus input element has focus, fullscreen is active or a modifier key is pressed.
        if (Util.shouldPreventDocumentLevelKeyEvent(e)) {
            return
        }

        // Only allow the content team to use this 'w' shortcut for navigating
        if (Backbone.Model.user.getSetting('IsAvailableForContentTeam') && e.key === 'w') {

            // If there is no task group is, default to the activity author
            let url = `/activities/author/${this.model.id}`
            if (this.taskGroupId > 0) {
                url = `/task_groups/author/${this.model.id}/${this.taskGroupId}`
            }
            Backbone.history.navigate(
                url,
                {trigger: true}
            )
        }
    },

    // When a teacher starts presenting, show which students are present and send them to the right slide
    teacherStartedPresenting() {
        this.teacherIsPresenting = true

        this.renderStudentsInPresentation()
        this.updateSlideOfStudents()
    },

    /**
    * onSocketChangeStep
    *
    * This is triggered when the socket activated the change-presentation-slide event.
    * Based on the data object it will trigger the renderActiveSlide function.
    *
    * @param  {Object} data Data object filled with taskgroupId and slideState
    */
    onSocketChangeStep(data) {

        if (

            // When the student is already inside the right taskgroup
            data.taskGroupId === this.taskGroupId &&
            data.slideState === this.slideState
        ) {

            this.renderStudentItems();

        } else {

            // Set the taskgroup id
            this.taskGroupId = data.taskGroupId;

            // Set the slideState
            this.slideState = data.slideState;

            // Set the direction of the animation (-1 = backward / 1 = forwards)
            this.direction = data.direction;

            // Set new active slide
            this.processSlideState();
        }
    },

    /**
        * renderActiveSlide
        *
        * This function renders the next slide based on this.taskgroupId and this.slideState.
        *
        */
    renderActiveSlide() {

        // If the taskGroupId is zero
        if (this.taskGroupId === 0) {

            // Render summary view, and set it as the intro slide
            this.initSummary(true);

        } else if (this.taskGroupId === -1) {

            // Render summary view, and set it as the ending slide
            this.initSummary(false);

        } else {

            var taskGroupModel = this.taskGroupModels.get(this.taskGroupId);

            if (

                // When we are checking answers and there is an open question present,
                // dont render the task group since its not used during this slide state.
                this.slideState > 0 &&
                taskGroupModel.elements.any({element_type: 'task', template_id: 2})
            ) {
                return;
            }

            // Render new taskgroup
            this.activeSlide = new TaskGroup({

                // Pass the presentation view
                work_on: this,

                // Find the model based on the taskGroupId
                model: taskGroupModel
            });

            this.registerChildView(this.activeSlide);

        }

    },

    /**
        * renderResponseOverview
        *
        * This function renders any items inside the slide that are teacher specific.
        *
        */
    renderResponseOverview() {
        var taskModel = this.getTaskInCurrentTaskGroup();

        // Define options for response overview
        var responseOverviewOptions = {
            presentation: this,
            model: this.model,

            // Pass the task id
            taskModel
        };

        // If task is template 2 (open question)
        if (
            taskModel.get('template_id') === 2
        ) {

            // Make sure any live response overviews are destroyed
            this.destroyChildViewsOfInstance(ResponseOverview);

            // We will render a specific response overview for template 2
            this.responseOverview = new Template2ResponseOverview(responseOverviewOptions);
            this.registerChildView(this.responseOverview);

            // Destroy the related task group
            this.destroyChildViewsOfInstance(TaskGroup);

        } else {

            // Listen to event that tells the presentation the response block is ready to be animated in
            this.listenTo(this.responseOverview, 'responses-rendered', this.onResponsesLoaded);

            // Create a new response overview
            this.responseOverview = new ResponseOverview(responseOverviewOptions);
            this.registerChildView(this.responseOverview);

        }
    },

    /**
        * toggleAnswer
        *
        * This function hides any open model answers and determines if a model should be shown.
        *
        */
    toggleAnswer() {

        // Check if the exercise contains tasks
        if (
            this.activeSlide &&
            _.first(this.activeSlide.taskViews) &&
            _.first(this.activeSlide.taskViews).templateView.hideAnswer
        ) {

            // Always hide answer first if task view is active
            _.first(this.activeSlide.taskViews).templateView.hideAnswer();
        }

        if (
            // If we are checking students answers
            this.slideState === 2 &&
            _.first(this.activeSlide.taskViews)
        ) {
            if (
                _.first(this.activeSlide.taskViews).templateView.showAnswer &&
                this.getTaskInCurrentTaskGroup().get('template_id') !== 2
            ) {
                // Call check answer function
                _.first(this.activeSlide.taskViews).templateView.showAnswer();
            } else {
                this.lockStudentAnswers();
            }
        }
    },

    /**
        * renderStudentItems
        *
        * This function renders any items in the slide that are specified for students.
        *
        */
    renderStudentItems() {

        // And task state is at least 1, meaning user can't perform a task
        if (this.slideState >= 1) {

            if (

                // If user is following a teacher
                this.teacherIsPresenting &&
                this.activeSlide &&
                this.activeSlide.taskViews &&
                this.activeSlide.taskViews.length
            ) {

                // Lock answers
                this.lockStudentAnswers();

                // Hide sendbutton if there is one
                if (_.first(this.activeSlide.taskViews).hasSendButton) {
                    _.first(this.activeSlide.taskViews).hideSendButton();
                }
            }
        } else if (
            // If user is following a teacher
            this.teacherIsPresenting &&
            this.activeSlide &&
            this.activeSlide.taskViews &&
            this.activeSlide.taskViews.length
        ) {
            // Unlock answers
            this.unlockStudentAnswers();

            // Show send button if there is one
            if (_.first(this.activeSlide.taskViews).hasSendButton) {
                _.first(this.activeSlide.taskViews).showSendButton();
            }
        }
    },

    updateBrowserAddress() {

        // Set the navigation url to pass the taskgroup id. This is usefull for debuggin
        Backbone.history.navigate(

            // Navigate to the following url
            '/activities/show/' + this.model.get('id') + '/' +
            this.taskGroupId + '/' + this.slideState,

            // Do not trigger navigation since it is not a valid URL
            {trigger: false}

        );
    },

    /**
        * updateNavigation
        *
        * This function updates any indicators of where the user is in the presentation.
        * Such as address bar and slide index inside the toolbar.
        *
        */
    updateToolbar() {

        // If we are viewing a summary screen the taskgroup id should either be -1 or 0
        if (this.taskGroupId < 1) {

            // Show a zero when we are in the intro screen and show the length of the taskgroups if we
            // are in the ending screen
            this.toolbar.setSlideIndex(this.taskGroupId === 0 ? this.taskGroupId : this.taskGroupModels.length);
        } else {

            // Set the index in the toolbar by getting the id of the taskgroup model
            // and increment this by one, because it's indexed as an array.
            this.toolbar.setSlideIndex(this.getTaskGroupIndexForId(this.taskGroupId) + 1);
        }
    },

    /**
        * getTaskGroupIndexForId
        *
        * This function finds the index of the taskgroup
        * inside the taskgroups collection based on the id.
        *
        * @param   {integer} id taskgroupId
        * @returns {integer}    index of taskgroupModel
        */
    getTaskGroupIndexForId(id) {
        return this.taskGroupModels.indexOf(this.taskGroupModels.get(id));
    },

    /**
        * getTaskGroupIdForIndex
        *
        * This fucntion finds the id of taskgroup based on its index in the taskgroups collection.
        *
        * @param   {integer} index taskgroup index in taskgroup collection
        * @returns {integer}   ID of taskGroupModel
        */
    getTaskGroupIdForIndex(index) {
        return this.taskGroupModels.at(index).id;
    },

    /**
        * getTaskInCurrentTaskGroup
        *
        * This function tries to find a task in the active task group.
        *
        * @return {Backbone.Model}  Returns a model of the found task, or undefined.
        */
    getTaskInCurrentTaskGroup() {
        return this.taskGroupModels.get(this.taskGroupId).elements.findWhere({
            element_type: 'task'
        });
    },

    /**
        * setPrevious
        *
        * This function is triggered when the user wants to see the previous step in the presentation.
        * It will always resolve to a slide state of 0, because you can't call a new response overview by
        * navigating backwards.
        *
        */
    setPrevious() {

        // Fallback to make sure you can't aggressively navigate past the intro screen
        if (this.taskGroupId === 0) {
            return;
        }

        // Meaning user is navigating backwards
        this.direction = -1;

        // Navigating backwards will always result in starting at a slide state of 0.
        // It's not possible to open a response overview by navigating backwards.
        this.slideState = 0;

        // The processSlideState function will determine if the user
        // has to stay on the current slide and remove a response overview or move one slide backwards.
        this.processSlideState();

    },

    /**
        * setNext
        *
        * This function is triggered when the user wants to see the next step in the presentation.
        * It will determine the slide state, which will resolve in rendering a new slide or a response overview.
        *
        */
    setNext() {

        // Fallback to make sure you can't aggressively navigate past the ending screen
        if (this.taskGroupId === -1) {
            return;
        }

        var taskGroupModel = this.taskGroupModels.get(this.taskGroupId);

        // 1 meaning user is navigating forward
        this.direction = 1;

        // When there is a task group model and a task state is set,
        // we are already viewing the answers of a task in the current slide
        if (taskGroupModel && this.slideState > 0) {

            // Set the slide state to 0, indicating the next slide can be rendered
            this.slideState = 0;

        } else if (taskGroupModel && this.slideState === 0) {

            // Try to collect a task from the current task group
            var taskInTaskGroup = this.getTaskInCurrentTaskGroup();

            if (

                (
                    // If user is a teacher and finds a task in taskgroup
                    this.isTeacher &&
                    taskInTaskGroup
                ) ||

                (
                    // When the task is an open question and the user is a student,
                    // we will skipping checking answers
                    !this.isTeacher &&
                    taskInTaskGroup &&
                    taskInTaskGroup.get('template_id') !== 2
                )

            ) {

                // Check if tne task's grading is disabled
                // NOTE another check is needed on template 2, since some of these tasks have grading mode auto
                // and will trigger the following bug in Sentry: S27611.
                if (taskInTaskGroup.get('template_id') === 2 || taskInTaskGroup.get('grading_mode') === 'none') {

                    // If the user is a teacher
                    if (this.isTeacher) {

                        // We have to render a response overview without the answers checked.
                        // Therefore, set the slide state to 1
                        this.slideState = 1;
                    } else {

                        // If the user is a student, there is no need to give an
                        // overview of the group's responses
                        // Therefore, set the slide state to 0, so the student can proceed
                        this.slideState = 0;
                    }

                // Otherwise the answers should be checked
                } else {

                    // In that case, we have to render a response overview with the answers checked.
                    // Therefore, set the slide state to 2
                    this.slideState = 2;
                }

            }

        } else {
            // In this case there is no taskgroup model because we are in a summary view
            // Set the slide state to 0, so we can move on to the next slideState
            this.slideState = 0;
        }

        this.processSlideState();

    },

    /**
        * processSlideState
        *
        * This function will determine what the presentation should do next based on the changed slide state.
        *
        */
    processSlideState() {

        // Check the set slide state
        switch (this.slideState) {

            case 0:

                if (this.isCheckingResponses) {

                    if (this.direction === 1) {
                        // When the user is not following a teacher, it means the slide state was
                        // changed by the user's own actions. Therefore a new task group id needs to be set
                        if (this.isTeacher || !this.teacherIsPresenting) {
                            this.setNewTaskGroupId();
                        }

                        // Animate in the new slide
                        this.animateSlide();
                    } else if (this.isTeacher) {
                        this.animateResponseOverview();
                    }
                } else {

                    // When the user is not following a teacher, it means the slide state was
                    // changed by the user's own actions. Therefore a new task group id needs to be set
                    if (this.isTeacher || !this.teacherIsPresenting) {
                        this.setNewTaskGroupId();
                    }

                    // Animate in the new slide
                    this.animateSlide();
                }

                // After the slide state is processed into the presentation,
                // it is safe to say that the current slide is not checking any responses
                this.isCheckingResponses = false;
                break;

            case 1:
            case 2:

                // Only teachers are able to view a response overview
                if (this.isTeacher) {

                    // animate the response overview
                    this.animateResponseOverview();
                }

                // After processing adjust this boolean to indicate we are now checking responses
                this.isCheckingResponses = true;

                break;
        }

        // Update the navigation display inside the toolbar and browser adress bar
        this.updateToolbar();
        this.updateBrowserAddress();

        if (this.taskGroupId > 0) {
            if (!this.isTeacher) {

                // Defer function to make sure taskgroup elements are loaded
                _.defer(this.renderStudentItems);
            }

            // Determine if we need to show the correct answer, based on the slide state
            this.toggleAnswer();
        }

        // When the teacher has students in follow mode
        if (this.isTeacher && this.teacherIsPresenting) {

            // Update their position in the presentation based on that of the teacher
            this.updateSlideOfStudents();

        }

    },

    /**
        * setNewTaskGroupId
        *
        * This function is called when a new slide should be loaded in the presentation.
        * It will determine at which task group the user is, and will choose the next task group to load.
        *
        */
    setNewTaskGroupId() {

        // Get the index based on the current task group id
        var taskGroupIndex = this.getTaskGroupIndexForId(this.taskGroupId);

        if (this.direction === 1) {

            // Increment the index by 1
            taskGroupIndex ++;

            if (taskGroupIndex >= this.taskGroupModels.length) {

                // Set task group id to -1, which means ending screen
                this.taskGroupId = -1;

            } else {

                // Find the new taskgroup id based on the incremented index
                this.taskGroupId = this.getTaskGroupIdForIndex(taskGroupIndex);

            }

        // If we are moving backwards
        } else {

            // If the user is currently at the ending screen
            if (this.taskGroupId === -1) {

                // Set the index to the last task group
                taskGroupIndex = this.taskGroupModels.length - 1;
            } else {

                // Decrement the index by 1
                taskGroupIndex --;
            }

            // If the index is -1 after decrementing, we don't have any task groups to show
            if (taskGroupIndex === -1) {

                // Set task group id to 0, which means intro screen
                this.taskGroupId = 0;

            } else {

                // Find the new taskgroup id based on the incremented index
                this.taskGroupId = this.getTaskGroupIdForIndex(taskGroupIndex);

            }

        }
    },

    /**
        * animateSlide
        *
        * This method will be called when there is a new active item. It will
        * switch between taskgroups sliding out the previous and sliding in the
        * next taskgroup
        *
        */
    animateSlide() {

        // Destroy views that could be constructed in the previous slide(s)
        this.destroyChildViewsOfInstance(TaskGroup);
        this.destroyChildViewsOfInstance(Summary);

        if (this.isTeacher) {
            this.$el.removeClass(Styles['has-sidebar'])
            this.destroyChildViewsOfInstance(ResponseOverview);
            this.destroyChildViewsOfInstance(Template2ResponseOverview);
        }

        // Construct the next taskgroup to be animated into the slide
        this.renderActiveSlide();

        this.activeSlide.appendTo(this.slideContainer);

        // Check if there is a show method
        if (this.activeSlide.show) {

            // Call the show method
            this.activeSlide.show();
        }

        if (this.isTeacher) {
            this.studentsThatFilledAnswer = [];
            this.checkIfAnswerCounterShouldRender();
        } else {
            this.renderStudentItems();
        }

    },

    /**
        * animateResponseOverview
        *
        * This function is called when the response overview is ready to be rendered and animated
        * into the teacher's presentation.
        *
        */
    animateResponseOverview() {

        // Grab the current task group and select the related task
        var taskModel = this.getTaskInCurrentTaskGroup();

        // Define upon which element the response overview should be appended.
        // Open questions are the exceptions because they should be appended on the slide container.
        var responsesContainer =
            (
                taskModel.get('template_id') === 2 ?
                    this.slideContainer :
                    this.$('.js-sidebar')
            );

        // When the current slide is not checking responses,
        // we need to render a response overview
        if (!this.isCheckingResponses) {

            // Render the response overview and append it to its container
            if (taskModel.get('template_id') !== 2) {
                this.$el.addClass(Styles['has-sidebar'])
            }
            this.renderResponseOverview();
            this.responseOverview.appendTo(responsesContainer);

        } else {
            // When the current slide is checking responses,
            // we need to remove the response overview

            if (this.getChildViewsOfInstance(Template2ResponseOverview).length) {

                // When we are showing a response overview for template 2 and are moving backwards,
                // we need to animate the template 2 taskgroup in again, since it's removed fmor the canvas
                this.animateSlide();
            }
            // Destroy any instances of response overviews to make sure the slide canvas is empty
            this.$el.removeClass(Styles['has-sidebar'])
            this.destroyChildViewsOfInstance(Template2ResponseOverview);
            this.destroyChildViewsOfInstance(ResponseOverview);

        }
        this.checkIfAnswerCounterShouldRender();
    },

    /**
        * updateSlideOfStudents
        *
        * When the teacher has decided which slide should be next, this function will send a socket event
        * to the students in follow mode. They will then be directed to change their presentation slide to the
        * new slide of the teacher.
        */
    updateSlideOfStudents() {

        // Only whisper when we are presenting
        if (!this.teacherIsPresenting) {
            return
        }

        this.channel.whisper('changePresentationSlide', {

            // Pass taskgroupId
            taskGroupId: this.taskGroupId,

            // and slideState
            slideState: this.slideState,

            // Pass teacher id
            teacherId: this.teacherId,

            direction: this.direction
        })
    },

    /**
        * initSummary
        *
        * This function renders the summary component.
        *
        * @param  {type} isIntro description
        */
    initSummary(isIntro) {

        if (this.activeSlide) {
            this.activeSlide.destroy();
        }

        this.activeSlide = this.addChildView(
            new Summary({
                summaryType: isIntro ? 'summary-start' : 'summary-end',
                presentation: this,
                model: this.model
            }), '.js-slide'
        );

        this.isCheckingResponses = false;

        // If the summary is the intro page, disable the back button.
        // If it's ending page, disable the next button.
        this.toolbar.disableButton(isIntro ? 'back' : 'next');

    },

    /**
        * renderStudentsInPresentation
        *
        * This function presents the students in follow mode in the DOM
        */
    renderStudentsInPresentation() {

        // In the summary screen, display the avatars of the students
        if (this.activeSlide.checkWhoIsInPresentation) {
            this.activeSlide.checkWhoIsInPresentation()
        }

        // Render answer counter if needed
        this.checkIfAnswerCounterShouldRender()

        // Update the number of students in the presentation toolbar
        this.toolbar.updateStudentsInPresentation()
    },

    /**
        * openSendStudentSidebar
        *
        * This functions opens a sidebar that lets the teacher send students that are online to the presentation.
        *
        */
    openSendStudentSidebar() {

        // If there is no sidebar registered to this view yet
        if (this.sendStudentSidebar) {
            this.sendStudentSidebar.destroy();
        }

        // Construct new sidebar
        this.sendStudentSidebar = new SendStudentSideBar({
            model: this.model,
            buttonLabel: window.i18n.gettext('Invite students'),
            callback: () => {
                this.teacherStartedPresenting()
            },
        });

        this.registerChildView(this.sendStudentSidebar);

        Backbone.View.sidebar.showSidebar(
            this.sendStudentSidebar,
            window.i18n.gettext('Invite students'),
            360
        );
    },

    /**
     * When a student answer is saved inside a presentation, this function is triggered.
     * It will then notify all teachers in the group.
     * By sending the studentId, the teacher can check if the student is in his presentation.
     *
     * @param {Object} args
     * event arguments, including {silent: true}, for if notification shouldn't trigger animation at teacher side.
     */
    onSaveStudentAnswer(args) {
        this.channel.whisper('studentSubmittedAnswer', {
            studentId: Backbone.Model.user.id,
            ...args
        })
    },

    /**
     * This function is triggered when the presentation view of a teacher received an event
     * from the teacher's user model.
     * It will then check if the student is part of the students following the teacher.
     * If so, it will increment the answer counter.
     *
     * @param {Number} studentId    student ID of the answer giving student
     * @param {Boolean} silent      if true, do not display an animation
     */
    onStudentSubmittedAnswer({studentId, silent}) {

        if (
            this.answerCounter &&
            // If this student is part of the students following the teacher
            this.studentsInPresentation.get(studentId) &&
            // Check if this studentId isn't already added to the filled answer array
            _.contains(this.studentsThatFilledAnswer, studentId) === false
        ) {

            // Push student id to array
            this.studentsThatFilledAnswer.push(studentId)

            // Increment answer counter and send student's avatar
            this.answerCounter.increment(this.model.getGroupModel().students.get(studentId), silent)
        }
    },

    /**
        * checkIfAnswerCounterShouldRender
        *
        * This function decides if an answer counter should load.
        *
        */
    checkIfAnswerCounterShouldRender() {

        this.destroyChildViewsOfInstance(AnswerCounter);

        if (
            // Check if the taskgroup id is greater than 0,
            // meaning we're not showing a summary view
            this.taskGroupId > 0 &&

            this.slideState === 0 &&

            // There are students following the teacher
            this.teacherIsPresenting &&

            // Check if there is a task inside the task group, else it will return undefined
            this.getTaskInCurrentTaskGroup()

        ) {

            this.answerCounter = this.addChildView(
                new AnswerCounter({
                    presentation: this
                }), '.js-answer-counter'
            );
        }
    },

    /**
        * lockStudentAnswers
        *
        * This function sends an event to the active task view.
        * The view will lock the task so that students can't change their answer.
        *
        */
    lockStudentAnswers() {
        _.first(this.activeSlide.taskViews).templateView.lockAnswer();
    },

    /**
        * unlockStudentAnswers
        *
        * This function sends an event to the active task view.
        * The view will unlock the task so that students change their answer again.
        *
        */
    unlockStudentAnswers() {
        _.first(this.activeSlide.taskViews).templateView.unlockAnswer();
    },

    /**
        * onSendToUrl
        *
        * This is triggered when teacher send a student to another URL.
        * It will check if the user is in fullscreen and will then close it.
        *
        */
    onSendToUrl() {
        if (FullscreenMode.checkFullscreen()) {
            FullscreenMode.exitFromFullscreen();
        }
    },

    /**
        * beforeDestroy
        *
        * Before destroying the view, all presentation specific settings, event listeners, css classes etc.
        */
    beforeDestroy() {

        if (FullscreenMode.checkFullscreen()) {
            FullscreenMode.exitFromFullscreen();
        }

        $(document).off('keyup', this.onNavigationKeyUp)

        // initialize menubar and crumblepath
        if (Backbone.View.menubar) {
            Backbone.View.menubar.$el.show();
        }

        StandbyMode.enableAutoStandby();

        Backbone.Model.user.echo.leave('presentation.' + this.model.id)
    },

})
