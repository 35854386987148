export default class Util {

    /**
     * Calculate the sum of a list of numbers.
     *
     * @param  {Array} array    An array of numbers
     * @return {number}         Sum of the array values
     */
    static arraySum(array) {
        return _.reduce(array, (a, b) => {
            return a + b;
        }, 0);
    }

    /**
     * Input like 'https://storage.googleapis.com/uploads/student-files/2019-04-05-3ab426e5e.png' to gets returned
     * as 'png'. File must have a file extension in its provided file name for this to work.
     *
     * Source: https://stackoverflow.com/a/12900504
     *
     * @param {String} fileName file name to extract the extension from
     * @returns {String} the file extension
     */
    static getFileExtension(fileName = '') {
        // eslint-disable-next-line no-bitwise
        return fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase()
    }

    /**
     * Return file name without file extension.
     *
     * @param {String} fileName file name to remove the extension of.
     * @returns {String} file name without extension, unless the last period in the name was more than 12 characters
     *                   from the end, which most likely means the period doesn't denote a file extension.
     */
    static removeFileExtension(fileName = '') {
        const indexOfLastPeriod = fileName.lastIndexOf('.')
        if (indexOfLastPeriod === -1 || fileName.length - indexOfLastPeriod > 12) {
            return fileName
        }
        return fileName.slice(0, indexOfLastPeriod)
    }

    /**
     * Check if file type is appropriate to display on e.g. an img tag in most browsers.
     * See also https://en.wikipedia.org/wiki/Comparison_of_web_browsers#Image_format_support
     *
     * @param {String} imageUrl image file path
     * @returns {Boolean}       true if file is of type that can be displayed in most modern browsers.
    */
    static isDisplayableImage(imageUrl) {
        const extension = Util.getFileExtension(imageUrl)
        return this.displayableImageTypes.includes(extension)
    }

    static get displayableImageTypes() {
        return ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'avif']
    }

    /**
     * Source: https://stackoverflow.com/a/18650828
     *
     * @param {Number} bytes amount of bytes
     * @param {Number} toFixedSize amount of decimals in formatted string
     * @returns {String} Bytes converted to more human readable format
     */
    static formatBytes(bytes, toFixedSize = 2) {
        // Kilobyte size (1000 = kilobit)
        const k = 1000
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(toFixedSize))} ${sizes[i]}`;
    }

    /**
     * @param  {string} email   string that may or may not be a valid email address
     * @return {boolean}        if input is a valid email address
     */
    static validateEmail(email) {
        // Source: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation + top level domain requirement (?:\.[a-zA-Z]{2,24})
        var re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.[a-zA-Z]{2,24})$/;
        // jscs:enable maximumLineLength
        return re.test(email);
    }

    /**
     * Checks if a given string is a valid URL. This allows only http and https protocols so that we
     * avoid security risks like href="javascript:" or unwanted URLs like mailto:
     * The regex is based on https://stackoverflow.com/a/3809435
     *
     * @param  {string} url     the url as string
     * @return {boolean}        if this is a valid URL
    */
    static validateURL(url) {
        const re = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=!\[\],]*)$/
        return re.test(url);
    }

    // stolen from Modernizr
    // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
    //
    // Changed to fancy'er version from here, which actually detects if localstorage works:
    // https://mathiasbynens.be/notes/localstorage-pattern
    static hasSupportForLocalstorage() {

        // Check if hasSupportForLocalstorage has already been tested.
        var hasSupport = window.hasSupportForLocalstorage;

        // Test if local storage is supported if it has not been tested yet.
        if (_.isUndefined(hasSupport)) {

            // Create a string of 512 characters and try to insert it in local storage
            var uid = Array(512).join('~');
            var LStorage;
            var result;

            try {
                (LStorage = window.localStorage).setItem('hasSupport', uid);
                result = LStorage.getItem('hasSupport') === uid;
                LStorage.removeItem('hasSupport');
                hasSupport = !!(result && LStorage);
            } catch (exception) {
                hasSupport = false;
            }
        }

        // Save test result for later reference and return result.
        window.hasSupportForLocalstorage = hasSupport;
        return hasSupport;

    }

    static excelPasteElement(element, format, callback) {

        element.on('paste', (e) => {
            e.preventDefault();
            var htmlText;
            // IE and Edge have the clipboard stored under the window object.
            // http://stackoverflow.com/a/5552340/3091836
            if (window.clipboardData) {
                htmlText = window.clipboardData.getData('text');
            } else {
                htmlText = (e.originalEvent || e).clipboardData.getData('text/html');
            }
            var json_array = [];

            var matches = htmlText.match(/<tr([\s\S]+?(?!\/tr>))<\/tr>/ig);

            if (matches !== null) {
                for (var i = 0; i < matches.length; i++) {

                    var line = matches[i];
                    var regex = /<td[^>]*>([\s\S]*?(?!<\/td>)*)<\/td>/ig;

                    var values = [];
                    var m;
                    while ((m = regex.exec(line)) !== null) {
                        values.push(m[1].replace(/<span([\s\S]+(?=<\/span))<\/span>/ig, ''));
                    }

                    if (values[3] === '') {
                        values[3] = null;
                    }

                    if (values[3] === 'leeglaten') {
                        values[3] = '';
                    }

                    var itemObject = {};

                    for (var field of format) {
                        itemObject[format[field]] = values[field];
                    }

                    json_array.push(itemObject);
                }

                if (_.size(json_array)) {
                    callback(json_array);
                }
            }

            return true;
        });
    }

    // simplified version of Modernizr
    // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
    static hasSupportForTouch() {
        return !!(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);
    }

    /**
     * Check if two elements overlap.
     * Based on http://jsfiddle.net/98sAG/
     *
     * @param  {Element} element1   element to compare
     * @param  {Element} element2   element to compare
     * @return {boolean}        if element1 and element2 overlap or not
     */
    static elementsOverlaps(element1, element2) {
        var overlaps = (function() {
            /**
             * getPositions
             *
             * This function will get the positions of the given elements.
             * It wil get the postion by making an array with the start position
             * and calculate its ending position by adding the height and with
             * of the element
             *
             * @param  {DOMElement} elem    Element Element of which the position should be gotten
             * @return {Array}              Returns and array with the start and end positions
             */
            function getPositions(elem) {
                var pos;
                var width;
                var height;
                pos = $(elem).position();

                /**
                     * Call Math.round on pos.left and pos.right to simulate jQuery2 behaviour
                     * where integers were given back.
                     */
                const left = Math.round(pos.left);
                const top = Math.round(pos.top);

                width = $(elem).outerWidth(true);
                height = $(elem).outerHeight(true);

                return [
                    [left, left + width],
                    [top, top + height]
                ];
            }

            /**
                 * comparePositions
                 *
                 * This function will take the arrays as created by the getPositions function
                 * and calculate if the elements overlaps witch eachother or not.
                 *
                 * @param  {Array} p1   getPositions result of the first element
                 * @param  {Array} p2   getPositions result of the second element
                 * @return {Boolen}     This function will return a boolean which is true if overlaps
                 */
            function comparePositions(p1, p2) {
                var r1;
                var r2;
                r1 = p1[0] < p2[0] ? p1 : p2;
                r2 = p1[0] < p2[0] ? p2 : p1;

                return r1[1] >= r2[0] || r1[0] === r2[0];
            }

            return function(a, b) {
                var pos1 = getPositions(a);
                var pos2 = getPositions(b);
                return comparePositions(pos1[0], pos2[0]) && comparePositions(pos1[1], pos2[1]);
            };
        })();

        // Only execute when elements do exists!
        if (element1.length > 0 && element2.length > 0) {
            return overlaps(element1, element2);
        }
    }

    /**
     * Returns an array with general levels matching with the given ids
     *
     * @param  {Array} generalLevelIds  Array with general level ids
     * @return {Array}                  Array with general level names
     */
    static getNormalizedLevels(generalLevelIds) {
        return _.reduce(generalLevelIds, (m, levelId) => {
            const generalLevelModel = Backbone.Collection.generalLevels.get(levelId)
            if (generalLevelModel && m.indexOf(generalLevelModel.get('name')) === -1) {
                m.push(generalLevelModel.get('name'));
            }
            return m
        }, [])
    }

    /**
     * This method will convert een array with grades to a
     * ranged string. Example:
     * input: [1,2,3,5]
     * output: 1-3,5
     *
     * input: [1,2,3,4,5]
     * output: 1-5
     *
     * input: [1,2,4]
     * output: 1-2,4
    *
    * @param  {Array} arrayWithGrades  Array containing the grades
    * @return {string}                 Ranged grades string
    */
    static getRangedGrades(arrayWithGrades) {
        var rangedArray = [];
        var tempRange = [];

        _.each(arrayWithGrades, (value, index) => {

            if (!arrayWithGrades[index + 1] ||
                    (value + 1) !== arrayWithGrades[index + 1]
            ) {
                tempRange.push(value);
                rangedArray.push(tempRange.join('-'));
                tempRange = [];
            } else if (tempRange.length === 0) {
                tempRange.push(value);
            }
        });

        return rangedArray.join(',');
    }

    /**
     * This function will get the suffix for ordinal numbers.
     * It works trough some modulus magic.
     *
     * Found at: https://stackoverflow.com/a/31615643
     *
     * TODO consider upgrading to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
     *
     * @param  {number} n   Inputted number that should be ordinalled
     * @return {string}     Outputted string with ordinalled number
     */
    static getOrdinal(n) {

        // Make sure n is an integer
        n = parseInt(n);

        // Type of strings
        var s = [
            window.i18n.gettext('th'),
            window.i18n.gettext('st'),
            window.i18n.gettext('nd'),
            window.i18n.gettext('rd')
        ];

        // Check if higher then 100
        var v = n % 100;

        // Do some magic
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    static base64Decode(str) {
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
        return decodeURIComponent(atob(str).split('').map((c) => {
            return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
        }).join(''));
    }

    static base64Encode(str) {
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode(`0x${p1}`);
        }));
    }

    // Improved to match: http://stackoverflow.com/a/17980070
    // to prevent XSS
    static stripTags(html) {
        const safeHTMLString = this.stripScripts(html)

        var tmp = document.implementation.createHTMLDocument('New').body;
        tmp.innerHTML = safeHTMLString;
        return tmp.textContent || tmp.innerText || '';
    }

    /**
     * Convert positive number to letter in a range from ASCII A till Z.
     *
     * @param  {number} n       sequence number
     * @return {string}         index letter
     */
    static numToLetter(n) {
        var s = '';
        while (n >= 0 && _.isFinite(n)) {
            s = String.fromCharCode(n % 26 + 97) + s;
            n = Math.floor(n / 26) - 1;
        }
        return s.toUpperCase();
    }

    static shouldPreventDocumentLevelKeyEvent(e) {
        return (
            // Do not respond when a special key is pressed, to avoid conflicts with shortcuts.
            e.altKey || e.ctrlKey || e.metaKey || e.shiftKey ||

            // Do not respond when any type of modal is open.
            Backbone.View.Components.modal.isOpen ||
            Backbone.View.Components.fullscreen.isOpen ||
            Backbone.View.Components.keyboard.isOpen ||
            Backbone.View.layout.alertMessage?.isOpen ||
            Backbone.View.layout.confirmMessage?.isOpen ||

            // Do not respond when the focus is on a focusable element (excluding buttons and links).
            document.activeElement.matches(this.focusableElementsSelector(false)) ||

            // Do not respond when the focus ins on a working link or button and the user presses enter/space.
            (
                (e.key === 'Enter' || e.key === ' ') &&
                document.activeElement.matches('a[href]:not([disabled]),button:not([disabled])')
            )
        )
    }

    /**
     * Get focusable HTML elements within a certain scope
     *
     * @param {Element} el parent element to find focusable elements in
     * @param {Boolean} includeButtons if true, include elements <button> and <a>
     * @param {Boolean} includeNegativeTabIndex if true, include elements with a negative tab index
     * @returns {NodeList} focusable elements
     */
    static getFocusableElements(el, includeButtons = true, includeNegativeTabIndex = true) {
        return el.querySelectorAll(this.focusableElementsSelector(includeButtons, includeNegativeTabIndex))
    }

    /**
     * Get selector string of all elements that can be focused
     * @param {Boolean} includeButtons          If true, include links and buttons in selector query.
     * @param {Boolean} includeNegativeTabIndex if true, include elements with a negative tab index
     * @returns {String} String with comma-separated element selectors
     */
    static focusableElementsSelector(includeButtons = true, includeNegativeTabIndex = true) {
        let selectors = [
            'textarea:not([disabled])',
            'input:not([disabled])',
            'select:not([disabled])',
            '[contenteditable]',
            '[tabindex]',
            'akit-exercise',
        ]
        if (includeButtons) {
            selectors.push(
                'a[href]:not([disabled])',
                'button:not([disabled])'
            )
        }
        if (!includeNegativeTabIndex) {
            selectors = selectors.map(selector => {
                return `${selector}:not([tabindex="-1"])`;
            })
        }
        return selectors.join()
    }

    /**
     * Inspired by: https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
     *
     * @param {Element} el      parent element to trap focus inside off
     * @param {Number} firstFocusIndex   optional offset from which to set initial focus, can also be negative
     */
    static setFocusTrap(el, firstFocusIndex = 0) {
        const focusableElements = this.getFocusableElements(el, true, false)
        if (focusableElements.length === 0) {
            return
        }

        // If active element is not contained within el, set it to the last focusable element within el.
        // For example, when the user clicks a button that opens a confirm modal, focus is set on the 'Ok' button.
        if (document.activeElement !== null && !el.contains(document.activeElement)) {

            // If a negative value is given, start from the end
            if (firstFocusIndex < 0) {
                firstFocusIndex = Math.max(0, focusableElements.length - firstFocusIndex)
            }

            focusableElements[Math.min(firstFocusIndex, focusableElements.length - 1)].focus()
        }

        el.addEventListener('keydown', (e) => {
            // When the tab/tab+shift key is hit, prevent focus from leaving el wrapping the focused element around
            // to the first/last focusable element of el. Search for focusable elements each time to account for
            // changes to the child elements of el.
            if (e.keyCode === 9) {
                const focusableElements = this.getFocusableElements(el, true, false)
                if (e.shiftKey) {
                    if (document.activeElement === _.first(focusableElements)) {
                        _.last(focusableElements).focus()
                        e.preventDefault()
                    }
                } else if (document.activeElement === _.last(focusableElements)) {
                    _.first(focusableElements).focus()
                    e.preventDefault()
                }
            }
        })
    }

    /**
     * This function will transform new lines into <br /> tags
     *
     * @param  {String}     str         String which contains the new lines
     * @return {String}                 String with replaced new line
     */
    static nl2br(str) {
        if (!_.isString(str)) {
            return str
        }
        return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    }

    /**
     * This function will put the carret at the end of a content editable element.
     *
     * @param  {Element} el
     * Content editable element
     * @param  {Boolean|undefined} selectAll
     * If true, set selection range to all instead of placing caret at the end
     */
    static placeCaret(el, selectAll) {
        el.focus()
        if (selectAll === true) {
            el.select()
        } else if (Number.isInteger(el.selectionStart)) {
            el.selectionStart = el.selectionEnd = el.value.length
        }
    }

    /**
     * Render content safely
     *
     * Method which will combine other methods to make content safe for the user.
     * This method should be used for whenever content is placed in a Handlebars template
     * with triple brackets
     *
     * @param {string} s    String of element to be made safe
     * @return {string}     Safe string for rendering
     */
    static renderContentSafely(s) {
        // TODO: research if more methods are needed
        s = this.stripScripts(s);
        return s;
    }

    /**
     * Removes `<script>` tags and unwanted attributes like 'onclick' and other GlobalEventHandlers to prevent
     * script injection.
     *
     * @param  {string|HTMLElement} s   string or element to be stripped
     * @return {string}                 stripped content
     */
    static stripScripts(s) {

        // strips script tags and creates well formed html
        // http://stackoverflow.com/questions/6659351/removing-all-script-tags-from-html-with-js-regular-expression
        if (!s) {
            return '';
        }
        // Wrap string inside a div element to make it a proper HTMLElement.
        var div = document.createElement('div');
        div.innerHTML = s;

        // Create a TreeWalker object to safely iterate through the DOM tree in serial instead of recursivly, which
        // could cause stack overflow problems with big DOM trees. Add a node filter that only returns the element
        // nodes, since we do not need to process the textual part of the pasted content, only the HTML markup.
        // Remove all script tags from the content.
        // Remove all attributes that could contain event handlers like 'onclick', 'onload', 'onmouseenter', etc.
        // which could be potential XSS attack vectors.
        const treeWalker = document.createTreeWalker(
            div,
            NodeFilter.SHOW_ELEMENT
        )
        let currentNode = treeWalker.currentNode
        while (currentNode) {
            if (currentNode.tagName.toLowerCase() === 'script') {

                // If we encounter a script node, we want to remove it. Before removing it, request the next node
                // so that the loop can continue
                const scriptNode = currentNode
                currentNode = treeWalker.nextNode()
                scriptNode.parentNode.removeChild(scriptNode)
            } else {

                // For non-script nodes, clean their attributes and continue to the next node
                this.cleanElementAttributes(currentNode)
                currentNode = treeWalker.nextNode()
            }
        }

        return treeWalker.root.innerHTML

    }

    /**
     * Removes all attribures of an element except for those in the whitelist.
     *
     * @param {Element} node    Element node which attributes need to be cleaned.
     */
    static cleanElementAttributes(node) {

        // If node is an element node, remove all attributes from the element node except for the 'style' attribute
        // to preserve styling like <span style='text-decoration="underline">…</span>, typical selector attributes
        // like id and class, the src and alt attribute of an image element and data- attributes.

        const nodeAttributeWhiteList = {
            // Whitelist for all html tags
            '*': ['class', 'id', 'style', 'align'],
            // Whitelist for the <img>-tag
            img: ['src', 'alt'],
            // Whitelist for the <a>-tag
            a: ['target', 'rel', {
                attribute: 'href',
                // Only allow href tag if following condition is true:
                // href should not start with `javascript:`
                validationMethod: (value) => !/^javascript:/i.test(value)
            }],
            // Whitelist for the <ol>-tag
            ol: ['start', 'type'],
            // Whitelist for the <svg>-tag
            svg: ['xmlns', 'viewBox'],
            // Whitelist for the <path>-tag (used within SVG)
            path: ['d', 'stroke', 'fill'],
            // Whitelist for the <abbr>-tag
            abbr: ['title'],

            td: ['rowspan', 'colspan']
        }

        // Get a lowercased version of the node-tag to prevent matching issues
        const nodeTag = node.tagName && node.tagName.toLowerCase();
        const allowedTags = [
            ...nodeAttributeWhiteList['*'],
            ...(nodeAttributeWhiteList[nodeTag] || [])
        ];

        for (let i = node.attributes.length - 1; i >= 0; i--) {
            const attribute = node.attributes[i].name;
            const value = node.attributes[i].value;

            // First do the simple tags since it's the least heavy
            // operation and we can quit this loop-round directly
            // when it's done
            if (_.contains(allowedTags, attribute)) {
                continue;
            }

            // Secondly the next least heavy check, is the regex check
            // for checking if the attribute is a data attribute.
            // All data attributes are allowed.
            if (/^data-/.test(attribute)) {
                continue;
            }

            // At last, check if there is a method to validate this tag.
            // Since we don't know the content of the method, this can be
            // the least performant, so its the last check to do.
            const {
                validationMethod
            } = _.findWhere(allowedTags, {
                attribute
            }) || {};

            if (validationMethod && validationMethod(value, nodeTag)) {
                continue;
            }

            // If all checks failed, remove the attribute from the node
            node.removeAttribute(attribute)
        }
    }

    /**
     * Normalise Characters to remove html entities, simplify some characters and remove
     * unnecessary whitespace. This method is modelled after the private method
     * Response::_normalizeAnswerCharacters found in BaseLearnbeat.
     *
     * @param  {string} str     string to be cleaned
     * @param  {Object} options extra options for cleaning
     * @return {string}         cleaned string
     */
    static normaliseCharacters(str, { removeNewLines = false } = {}) {

        // Replace HTML entities with regular characters.
        str = this.unescape(str);

        // Replace left/right/high-reversed-9/low-9 double quotation mark and diaeresis
        // by simple double quotation mark U+0022.
        str = str.replace(/[“”‟„¨]/g, '"');

        // Replace left/right/low-9/high-reversed-9 single quotation mark and
        // acute/grave accent by an apostrophe U+0027.
        str = str.replace(/[‘’‚‛´`]/g, '\'');

        // Replace white characters that are not line line (\n) or carriage return (\r) characters with a single
        // space ordinary space (\u0020). In other words, all characters that are \s without being \n or \r.
        // See https://en.wikipedia.org/wiki/Whitespace_character#Unicode for a list of whitespace characters.
        str = str.replace(/[^\S\r\n]/g, ' ')

        // If the removeNewLines option is set to true, also replace \r and \n in the middle of the string
        // This is needed when grading/comparing multi-line responses like in open question
        if (removeNewLines) {
            str = str.replace(/[\r\n]/g, ' ')
        }

        // Replace two or more consecutive spaces with a single space.
        str = str.replace(/ {2,}/g, ' ');

        // Remove leading and trailing <br> tags.
        str = str.replace(/^\s*(?:<br\s*\/?\s*>)+|(?:<br\s*\/?\s*>)+\s*$/g, '')

        // Trim
        str = str.trim();

        return str;

    }

    /**
     * Replaces HTML entities with regular characters.
     * Adapted from the TinyMCE method tinymce.html.Entities.decode(str)
     * https://github.com/tinymce/tinymce/blob/e86ee039b6c4ed72d4dc8f2988010b325fe43538/modules/tinymce/src/core/main/ts/api/html/Entities.ts
     *
     * @param  {string} str     string to be cleaned
     * @return {string}         cleaned string
     */
    static unescape(str) {
        if (!str) {
            return str
        }

        const entityRegExp = /&#([a-z0-9]+);?|&([a-z0-9]+);/gi
        const asciiMap = {
            128: '\u20AC',
            130: '\u201A',
            131: '\u0192',
            132: '\u201E',
            133: '\u2026',
            134: '\u2020',
            135: '\u2021',
            136: '\u02C6',
            137: '\u2030',
            138: '\u0160',
            139: '\u2039',
            140: '\u0152',
            142: '\u017D',
            145: '\u2018',
            146: '\u2019',
            147: '\u201C',
            148: '\u201D',
            149: '\u2022',
            150: '\u2013',
            151: '\u2014',
            152: '\u02DC',
            153: '\u2122',
            154: '\u0161',
            155: '\u203A',
            156: '\u0153',
            158: '\u017E',
            159: '\u0178'
        }
        const namedEntities = {
            '&lt;': '<',
            '&gt;': '>',
            '&amp;': '&',
            '&quot;': '"',
            '&apos;': '\'',
            '&nbsp;': ' ',
            '&iexcl;': '¡',
            '&cent;': '¢',
            '&pound;': '£',
            '&curren;': '¤',
            '&yen;': '¥',
            '&brvbar;': '¦',
            '&sect;': '§',
            '&uml;': '¨',
            '&copy;': '©',
            '&ordf;': 'ª',
            '&laquo;': '«',
            '&not;': '¬',
            '&reg;': '®',
            '&macr;': '¯',
            '&deg;': '°',
            '&plusmn;': '±',
            '&sup2;': '²',
            '&sup3;': '³',
            '&acute;': '´',
            '&micro;': 'µ',
            '&para;': '¶',
            '&middot;': '·',
            '&cedil;': '¸',
            '&sup1;': '¹',
            '&ordm;': 'º',
            '&raquo;': '»',
            '&frac14;': '¼',
            '&frac12;': '½',
            '&frac34;': '¾',
            '&iquest;': '¿',
            '&Agrave;': 'À',
            '&Aacute;': 'Á',
            '&Acirc;': 'Â',
            '&Atilde;': 'Ã',
            '&Auml;': 'Ä',
            '&Aring;': 'Å',
            '&AElig;': 'Æ',
            '&Ccedil;': 'Ç',
            '&Egrave;': 'È',
            '&Eacute;': 'É',
            '&Ecirc;': 'Ê',
            '&Euml;': 'Ë',
            '&Igrave;': 'Ì',
            '&Iacute;': 'Í',
            '&Icirc;': 'Î',
            '&Iuml;': 'Ï',
            '&ETH;': 'Ð',
            '&Ntilde;': 'Ñ',
            '&Ograve;': 'Ò',
            '&Oacute;': 'Ó',
            '&Ocirc;': 'Ô',
            '&Otilde;': 'Õ',
            '&Ouml;': 'Ö',
            '&times;': '×',
            '&Oslash;': 'Ø',
            '&Ugrave;': 'Ù',
            '&Uacute;': 'Ú',
            '&Ucirc;': 'Û',
            '&Uuml;': 'Ü',
            '&Yacute;': 'Ý',
            '&THORN;': 'Þ',
            '&szlig;': 'ß',
            '&agrave;': 'à',
            '&aacute;': 'á',
            '&acirc;': 'â',
            '&atilde;': 'ã',
            '&auml;': 'ä',
            '&aring;': 'å',
            '&aelig;': 'æ',
            '&ccedil;': 'ç',
            '&egrave;': 'è',
            '&eacute;': 'é',
            '&ecirc;': 'ê',
            '&euml;': 'ë',
            '&igrave;': 'ì',
            '&iacute;': 'í',
            '&icirc;': 'î',
            '&iuml;': 'ï',
            '&eth;': 'ð',
            '&ntilde;': 'ñ',
            '&ograve;': 'ò',
            '&oacute;': 'ó',
            '&ocirc;': 'ô',
            '&otilde;': 'õ',
            '&ouml;': 'ö',
            '&divide;': '÷',
            '&oslash;': 'ø',
            '&ugrave;': 'ù',
            '&uacute;': 'ú',
            '&ucirc;': 'û',
            '&uuml;': 'ü',
            '&yacute;': 'ý',
            '&thorn;': 'þ',
            '&yuml;': 'ÿ',
            '&fnof;': 'ƒ',
            '&Alpha;': 'Α',
            '&Beta;': 'Β',
            '&Gamma;': 'Γ',
            '&Delta;': 'Δ',
            '&Epsilon;': 'Ε',
            '&Zeta;': 'Ζ',
            '&Eta;': 'Η',
            '&Theta;': 'Θ',
            '&Iota;': 'Ι',
            '&Kappa;': 'Κ',
            '&Lambda;': 'Λ',
            '&Mu;': 'Μ',
            '&Nu;': 'Ν',
            '&Xi;': 'Ξ',
            '&Omicron;': 'Ο',
            '&Pi;': 'Π',
            '&Rho;': 'Ρ',
            '&Sigma;': 'Σ',
            '&Tau;': 'Τ',
            '&Upsilon;': 'Υ',
            '&Phi;': 'Φ',
            '&Chi;': 'Χ',
            '&Psi;': 'Ψ',
            '&Omega;': 'Ω',
            '&alpha;': 'α',
            '&beta;': 'β',
            '&gamma;': 'γ',
            '&delta;': 'δ',
            '&epsilon;': 'ε',
            '&zeta;': 'ζ',
            '&eta;': 'η',
            '&theta;': 'θ',
            '&iota;': 'ι',
            '&kappa;': 'κ',
            '&lambda;': 'λ',
            '&mu;': 'μ',
            '&nu;': 'ν',
            '&xi;': 'ξ',
            '&omicron;': 'ο',
            '&pi;': 'π',
            '&rho;': 'ρ',
            '&sigmaf;': 'ς',
            '&sigma;': 'σ',
            '&tau;': 'τ',
            '&upsilon;': 'υ',
            '&phi;': 'φ',
            '&chi;': 'χ',
            '&psi;': 'ψ',
            '&omega;': 'ω',
            '&thetasym;': 'ϑ',
            '&upsih;': 'ϒ',
            '&piv;': 'ϖ',
            '&bull;': '•',
            '&hellip;': '…',
            '&prime;': '′',
            '&Prime;': '″',
            '&oline;': '‾',
            '&frasl;': '⁄',
            '&weierp;': '℘',
            '&image;': 'ℑ',
            '&real;': 'ℜ',
            '&trade;': '™',
            '&alefsym;': 'ℵ',
            '&larr;': '←',
            '&uarr;': '↑',
            '&rarr;': '→',
            '&darr;': '↓',
            '&harr;': '↔',
            '&crarr;': '↵',
            '&lArr;': '⇐',
            '&uArr;': '⇑',
            '&rArr;': '⇒',
            '&dArr;': '⇓',
            '&hArr;': '⇔',
            '&forall;': '∀',
            '&part;': '∂',
            '&exist;': '∃',
            '&empty;': '∅',
            '&nabla;': '∇',
            '&isin;': '∈',
            '&notin;': '∉',
            '&ni;': '∋',
            '&prod;': '∏',
            '&sum;': '∑',
            '&minus;': '−',
            '&lowast;': '∗',
            '&radic;': '√',
            '&prop;': '∝',
            '&infin;': '∞',
            '&ang;': '∠',
            '&and;': '∧',
            '&or;': '∨',
            '&cap;': '∩',
            '&cup;': '∪',
            '&int;': '∫',
            '&there4;': '∴',
            '&sim;': '∼',
            '&cong;': '≅',
            '&asymp;': '≈',
            '&ne;': '≠',
            '&equiv;': '≡',
            '&le;': '≤',
            '&ge;': '≥',
            '&sub;': '⊂',
            '&sup;': '⊃',
            '&nsub;': '⊄',
            '&sube;': '⊆',
            '&supe;': '⊇',
            '&oplus;': '⊕',
            '&otimes;': '⊗',
            '&perp;': '⊥',
            '&sdot;': '⋅',
            '&lceil;': '⌈',
            '&rceil;': '⌉',
            '&lfloor;': '⌊',
            '&rfloor;': '⌋',
            '&lang;': '〈',
            '&rang;': '〉',
            '&loz;': '◊',
            '&spades;': '♠',
            '&clubs;': '♣',
            '&hearts;': '♥',
            '&diams;': '♦',
            '&OElig;': 'Œ',
            '&oelig;': 'œ',
            '&Scaron;': 'Š',
            '&scaron;': 'š',
            '&Yuml;': 'Ÿ',
            '&circ;': 'ˆ',
            '&tilde;': '˜',
            '&ensp;': ' ',
            '&emsp;': ' ',
            '&thinsp;': ' ',
            '&ndash;': '–',
            '&mdash;': '—',
            '&lsquo;': '‘',
            '&rsquo;': '’',
            '&sbquo;': '‚',
            '&ldquo;': '“',
            '&rdquo;': '”',
            '&bdquo;': '„',
            '&dagger;': '†',
            '&Dagger;': '‡',
            '&permil;': '‰',
            '&lsaquo;': '‹',
            '&rsaquo;': '›',
            '&euro;': '€'
        }

        return str.replace(entityRegExp, (all, numeric) => {
            if (numeric) {
                if (numeric.charAt(0).toLowerCase() === 'x') {
                    numeric = parseInt(numeric.substr(1), 16);
                } else {
                    numeric = parseInt(numeric, 10);
                }

                // Support upper UTF
                if (numeric > 0xFFFF) {
                    numeric -= 0x10000;
                    // eslint-disable-next-line no-bitwise
                    return String.fromCharCode(0xD800 + (numeric >> 10), 0xDC00 + (numeric & 0x3FF));
                }

                return asciiMap[numeric] || String.fromCharCode(numeric);
            }
            return namedEntities[all] || _.unescape(all);
        });

    }

    /**
     * Start downloading a file without opening a new tab or replacing the current page.
     *
     * @param {String} uri file URL
     * @param {String} name file name
     */
    static createFileDownload(uri, name) {
        const link = document.createElement('a')
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /**
     * Capitalize the first letter of the given string
     *
     * @param  {string} string to capitilize the first letter of
     * @return {string} Capitilized string
     */
    static capitalizeFirstLetter(string) {
        if (typeof string === 'string') {
            return string.charAt(0).toUpperCase() + string.slice(1)
        }
        return string
    }

    /**
     * Sort data first on week, then on the more important year.
     * Both ascending.
     *
     * @param  {array} weeks array with objects that contain year and week data
     * @return {array}       sorted array
     */
    static sortWeeks(weeks) {
        weeks = _.sortBy((_.sortBy(weeks, (obj) => {
            return obj.week
        })), (obj) => {
            return obj.year
        })
        return weeks
    }

    /** Takes a string and looks for http(s)://
     * Returns the string with all links transformed to HTML a tags
     *
     * @param {String} string the string to transform
     * @return {String} the transformed result
     */
    static makeLinksClickable(string) {
        return string.replace(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi,
            '<a href="$&" target="_blank" rel="noopener">$&</a>'
        );
    }

    /**
     * The method can be used to remove the highlighting from an given element
     * by default it uses the '<mark>'-tag, but another tag can be given.
     *
     * @param {Element} element element to remove markings from
     * @param {Element} markElement element used to mark text
     */
    static removeAllHighlights(element, markElement = document.createElement('mark')) {

        var nodeIterator = document.createNodeIterator(
            element,
            NodeFilter.SHOW_ELEMENT,
            node => node.nodeName === markElement.tagName
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_REJECT
        )

        let node;

        // eslint-disable-next-line no-cond-assign
        while (node = nodeIterator.nextNode()) {
            node.outerHTML = node.innerHTML;
        }
    }

    /**
     * Method used to highlight a piece of text within the given element.
     * By default it will use the '<mark>'-tags.
     *
     * @param {Node} element element to add highlighting to
     * @param {Array} {[start, end]} array containing a start and end indices for highlighting
     * @param {Element} markElement element to use for marking
     */
    static highlightText(element, [start, end], markElement = document.createElement('mark')) {

        // To fix strange things from happening when highlighting we need to
        // normalize the element. This will combine multiple 'text' nodes to
        // a single one. This also makes our iterator iterations smaller.
        element.normalize();

        const textNodesIterator = document.createNodeIterator(
            element,
            NodeFilter.SHOW_TEXT,
            node => node.length > 0
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_SKIP
        );

        const ranger = document.createRange();

        // Determine the length we need to highlight by subtracting the start from
        // the end and add 1 to correct for starting at 0 vs. starting at 1.
        const highlightLength = (end - start) + 1;

        let startOffset = 0;
        let textNode;

        const wraplist = [];

        // eslint-disable-next-line no-cond-assign
        while (textNode = textNodesIterator.nextNode()) {

            const nodeStart = startOffset;
            const nodeSize = textNode.length;
            const nodeEnd = startOffset += nodeSize;
            const { parentNode } = textNode;

            // Check if start of highlighting is smaller than the end of this node. This
            // means we need to highlight (a part of) this text. Also do not 'mark' this
            // text if the text is already highlighted by checking their parent node
            if (start <= nodeEnd
                && end >= nodeStart
                && parentNode?.tagName !== markElement?.tagName
            ) {

                const from = start - nodeStart;
                const to = highlightLength + from;

                // Store changes in an array so we can do them after this while-loop. If we do
                // the changes in this while-loop the newly added tags will also be viewed as
                // a new node, which messes up the offset calculations.
                wraplist.push({
                    node: textNode,

                    // Set the start of the range to 0 or the from point. Since we can't highlight
                    // anything below 0
                    start: Math.max(from, 0),

                    // Set the end to the highlight length or to the nodesize if the length is
                    // bigger than the nodesize. This prevents the range to overflow the element
                    end: Math.min(to, nodeSize)
                });
            }
        }

        // Apply the changes created in the while loop above
        wraplist.forEach(({ node, start, end }) => {
            ranger.setStart(node, start);
            ranger.setEnd(node, end);
            ranger.surroundContents(markElement);
        });
    }

    /**
     * Function to handle search matches and highlight text of the match.
     * It uses the hightlight and removeHighlight functions in this same
     * util script.
     *
     * @param {Object} keyWithElements object with key / element combination
     *                 example:
     *                 {
     *                     'name': document.querySelector('.js-name')
     *                 }
     *                 It'll highlight the matches for name on element with
     *                 the class '.js-name'
     * @param {Array} matches array containing the search matches
     * @param {Element} markElement element to use for marking
     */
    static handleSearchMatches(keyWithElements, matches = [], markElement = document.createElement('mark')) {

        let offset = 0;

        for (const [attribute, element] of Object.entries(keyWithElements)) {

            this.removeAllHighlights(element);

            const { indices } = matches.find(match => match?.key === attribute) || {};

            if (indices && indices.length > 0) {
                indices.forEach(([start, end]) => {
                    const startWithOffset = start + offset;
                    const endWithOffset = end + offset;

                    offset += markElement.outerHTML.length;

                    try {
                        this.highlightText(
                            element,
                            [startWithOffset, endWithOffset]
                        );
                    } catch (e) {
                        // Sometimes the highlighttext throws an error telling the indices
                        // are out of bound for the given text. Since the code is working
                        // functionally and to keep the 'refactor' project in scope we'll
                        // 'ignore' these errors for now using a try/catch
                    }
                });
            }
        }
    }

    static firstNameLastName(firstName = '', prefix = '', sortableLastName = '') {
        return `${(firstName + ' ' + prefix).trim()} ${sortableLastName}`.trim()
    }

    static lastNameFirstName(firstName = '', prefix = '', sortableLastName = '') {
        if (sortableLastName) {
            return `${sortableLastName}, ${firstName} ${prefix}`.trim()
        }
        return firstName
    }

}
