import Styles from './SideNavigationItem.scss';

import Template from './SideNavigationItem.hbs';
import Util from 'util/util'
import SideNavigationSubItemTemplate from 'views/pages/activities/show/types/linear/PresentationMode/SideNavigation/NavigationItem/SideNavigationSubItem.hbs'
import ActivityNavigationItemStyles from 'views/pages/activities/show/navigationBar/navigationItem/NavigationItem.scss'

export default class SideNavigationItem extends BaseView {

    get events() {
        return {
            'click .js-task-group-button': 'onClickSideNavButton',
            'click .js-element-button': 'onClickSubSideNavButton',
        }
    }

    initialize({ presentationMode }) {
        this.presentationMode = presentationMode

        this.setElement(Template({
            Styles,
            ActivityNavigationItemStyles,
            label: this.model.get('index'),
            id: this.model.id,
            title: this.model.getShortText(),
        }))

        let counter = 0
        for (const elementModel of this.model.elements) {
            const isTask = elementModel.get('element_type') === 'task'
            this.$('.js-sub-items').append(SideNavigationSubItemTemplate({
                Styles,
                label: isTask && Util.numToLetter(counter),
                icon: !isTask && elementModel.getElementIcon(),
                id: elementModel.get('element_type') + elementModel.id,
                title: elementModel.getShortText(),
            }))
            if (isTask) {
                counter++
            }
        }

        presentationMode.work_on.activityShow.addStateToItem(this)
    }

    /**
     * setState
     *
     * This method can be called directly on this view to set the state.
     * It will remove the old state and apply the new state.
     *
     * @param  {string} state   New state in string, can be undefined then it wil remove state
     */
    setState(state) {

        // Check if current is not undefined
        if (this.state) {

            // Remove the current state's class
            this.$('.js-state').removeClass(ActivityNavigationItemStyles['nav-item__status--' + this.state])
        }

        // Store the state globally
        this.state = state

        // Check if the new state is not undefined
        if (this.state) {

            // Set the new state's class
            this.$('.js-state').addClass(ActivityNavigationItemStyles['nav-item__status--' + this.state])
        }
    }

    onClickSideNavButton() {
        this.presentationMode.setActiveElement(
            this.model
        )
        this.presentationMode.work_on.activityShow.addStateToItem(this)
    }

    onClickSubSideNavButton(e) {
        this.presentationMode.setActiveElement(
            this.model,
            this.model.elements.get(e.currentTarget.dataset.elementId)
        )
    }
}
