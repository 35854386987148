import Template from './Student.hbs';
import ResponseModel from 'models/ResponseModel';
import CheckAnswerStudentColumnView from 'views/components/answers/student/column/Column';
import NoteView from 'views/components/notes/noteWorkOn/NoteWorkOn';
import Button from 'views/components/button/Button'

export default class Student extends BaseView {

    /**
     * initialize
     *
     * this.model {StudentModel}                     Student information
     *
     * @param {ResponseModel} response               Most recent response.
     * @param {ResponseModel} responseOriginal       Original response.
     * @param {Task} taskView                        Parent task view this answer view is related to.
     * @param {Answer} parentView                    Parent Answer view.
     * @param {Boolean} isInPresentMode              If activity is in presentation mode
     */
    initialize({
        response,
        responseOriginal,
        taskView,
        parentView,
        isInPresentMode,
    }) {

        _.bindAll(this,
            'displayNotes',
            'renderStudentAnswer',
            'renderStudentAnswerView',
        )

        this.isInPresentMode = isInPresentMode;
        this.taskView = taskView;
        this.parentView = parentView;
        this.activityModel = this.taskView.work_on.model

        const isExamAnswerArchive = !!(taskView.isPreview && this.activityModel.isExam())

        // init response
        if (response) {
            response.set('original', 0)
        } else {
            response = new ResponseModel({
                user_id: this.model.id,
                task_id: this.taskView.model.id,
                activity_id: this.activityModel.id,
                score: -1,
                original: 0
            });
            this.activityModel.responses.add(response);
        }

        // init original response
        if (responseOriginal) {
            responseOriginal.set('original', 1);
        } else {
            responseOriginal = new ResponseModel({
                user_id: this.model.id,
                task_id: this.taskView.model.id,
                activity_id: this.activityModel.id,
                score: -1,
                original: 1
            });
            this.activityModel.responses_original.add(responseOriginal);
        }

        const shouldShowFeedbackButton =
            this.activityModel.get('type') !== 'presentation' &&
            !this.taskView.isPreview &&
            !isInPresentMode

        /**
         * pass studentId and taskId. in the progress screen this can be used
         * to target and hide graded answers
         */
        this.setElement(Template({
            studentId: this.model.id,
            taskId: this.taskView.model.id,
            isExamAnswerArchive,
            last_name_first_name: this.model.last_name_first_name(),
            shouldShowFeedbackButton,
        }));
        if (isInPresentMode) {
            this.toggleStudentName(false)
        }

        if (!isInPresentMode && shouldShowFeedbackButton) {
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Send feedback'),
                    theme: 'secondary',
                    icon: 'feedback-filled',
                    callback: () => this.onClickSendNote()
                }),
                '.js-feedback-button-header'
            )
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Send feedback'),
                    theme: 'secondary',
                    icon: 'feedback-filled',
                    callback: () => this.onClickSendNote()
                }),
                '.js-feedback-button-footer'
            )
        }

        const areReponseRevisionsEqual =
            response.get('responses_rev_id') ===
            responseOriginal.get('responses_rev_id')

        // Render original answers if one of these is true:
        // - the activity is an exam
        // - we are generating an exam archive
        // - the original revision is different than the latest revision
        if (this.activityModel.isExam() || isExamAnswerArchive || !areReponseRevisionsEqual) {
            /*
             *
             * add original answer
             *
             */
            const originalCheckAnswerStudentColumnView = this.renderStudentAnswer(
                responseOriginal,
                areReponseRevisionsEqual,
            )

            // Add a class to non-exam answers so they can be hidden with the 'original answers' setting
            if (!this.activityModel.isExam() && !isExamAnswerArchive) {
                originalCheckAnswerStudentColumnView.$el.addClass('js-non-exam-original-answer');
            }
            originalCheckAnswerStudentColumnView.$('.js-grade-answer').addClass('js-grade-for-original');

        }

        // Render latest answers if:
        // - the activity is not an exam
        // - we are not generating an exam archive
        if (!this.activityModel.isExam() && !isExamAnswerArchive) {

            /*
             *
             * add latest answer
             *
             */

            const latestCheckAnswerStudentColumnView = this.renderStudentAnswer(response, areReponseRevisionsEqual)

            latestCheckAnswerStudentColumnView.$('.js-grade-answer').addClass('js-grade-for-recent');

        }

        // Display feedback only for the activity view, not in the author and not presentation
        if (
            !this.taskView.practice &&
            APPLICATION !== 'author' &&
            this.activityModel.get('type') !== 'presentation' &&
            !this.taskView.isPreview &&
            !isInPresentMode
        ) {
            this.displayNotes();
        }

    }

    toggleStudentName(state) {
        this.toggleNameButton = this.addChildView(new Button({
            label: state ? window.i18n.gettext('Hide name') : window.i18n.gettext('Show name'),
            icon: state ? 'eye-off' : 'eye',
            callback: () => {
                this.toggleNameButton.destroy()
                this.toggleStudentName(!state)
            },
            theme: 'transparent',
        }), '.js-student-name', 'after')
        this.el.querySelector('.js-student-name').style.visibility = state ? 'visible' : 'hidden'
    }

    renderStudentAnswer(response, areReponseRevisionsEqual) {
        const studentAnswer = this.taskView.templateView.getStudentAnswer(response)

        // Student answer could be rendered as a view or a Promise of a view instead of HTML or a plain string.
        const studentAnswerIsView = (studentAnswer instanceof BaseView) || (studentAnswer instanceof Promise)

        // Create student answer column view
        const checkAnswerStudentColumnView = this.addChildView(
            new CheckAnswerStudentColumnView({
                activityModel: this.activityModel,
                response,
                // When the studentAnswerIsView is true set givenAnswer to a string, an empty
                // student answer will be used, which will be overridden by the studentAnswer view
                givenAnswer: studentAnswerIsView ? '' : studentAnswer,
                areReponseRevisionsEqual,
                isInPresentMode: this.isInPresentMode
            }),
            '.js-answer-columns-holder'
        );

        if (studentAnswerIsView) {
            this.renderStudentAnswerView(studentAnswer, checkAnswerStudentColumnView)
        }

        // If user is teacher and grading mode is on, show the answer grading for the original/latest answers.
        if (
            Backbone.Model.user.get('is_teacher') &&
            Backbone.Model.user.getSetting('is_grading')
        ) {
            checkAnswerStudentColumnView.$('.js-grade-answer').css('display', 'flex')
        }

        return checkAnswerStudentColumnView
    }

    renderStudentAnswerView(view, checkAnswerStudentColumnView) {
        if (view instanceof Promise) {
            view.then((resolvedView) => {
                this.renderStudentAnswerView(resolvedView, checkAnswerStudentColumnView)
            })
        } else {

            checkAnswerStudentColumnView.addChildView(
                view,
                '.js-student-answer',
                'html'
            );

            // Listen to the toggleOriginalAnswer from the parentView and pass it to student answer view.
            this.listenTo(this.parentView, 'toggleOriginalAnswer', (state) => {
                view.trigger('toggleOriginalAnswer', state)
            })
        }
    }

    onClickSendNote() {
        this.parentView.onSendnote(this.model.id, this);
        return false;
    }

    /**
     * displayNotes - displays feedback given to this student on this task.
     * Also is called after updating the feedback.
     * @param  {Backbone.model} addedNote updeted feedback for the student
     */
    displayNotes(addedNote) {
        if (addedNote) {
            this.activityModel.notes.add(addedNote);
        }

        this.destroyChildViewsOfInstance(NoteView);

        // filter feedback for this student and display it
        const noteModels = this.activityModel.notes.where({
            recipient_user_id: this.model.id,
            task_id: this.taskView.model.id
        })
        noteModels.forEach((model) => {
            this.addChildView(new NoteView({
                model,
                taskView: this.taskView,
                parentView: this
            }), '.js-notes');
        })
    }
}
