var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-counter") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-counter__has-not-answered") : stack1), depth0))
    + " js-unanswered-counter\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"has-not-answered__avatars") : stack1), depth0))
    + " js-unanswered-avatars\"></div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"counter") : stack1), depth0))
    + "\">\n            <span class=\"js-has-not-answered-counter\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"hasNotAnswered") : depth0), depth0))
    + "</span>\n        </div>\n        <span>"
    + alias2(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"not answered",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":35}}}))
    + "</span>\n    </div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"animating-avatars") : stack1), depth0))
    + " js-animating-avatars\"></div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-counter__has-answered") : stack1), depth0))
    + " js-has-answered\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-counter__has-answered-avatars") : stack1), depth0))
    + " js-answered-avatars\"></div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"counter") : stack1), depth0))
    + "\">\n            <div class=\"js-has-answered-counter\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"hasAnswered") : depth0), depth0))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});