export default {

    disableAutoStandby() {

        // Make sure any click listeners are removed
        $('body').off('click', this.requestWakeLock)

        // Request wake lock on next user interaction
        $('body').one('click', this.requestWakeLock)
    },

    enableAutoStandby() {

        // Make sure any click listeners are removed
        $('body').off('click', this.requestWakeLock)

        // Release wake lock if present
        window.wakeLock?.release()
    },

    async requestWakeLock() {

        // Check for browser support
        if (!('wakeLock' in navigator)) {
            return
        }

        try {
            // Try to get a wake lock
            window.wakeLock = await navigator.wakeLock.request('screen')

            // Remove the window variable when wake lock is released
            window.wakeLock.addEventListener('release', () => {
                delete window.wakeLock
            })

        } catch (error) {

            // Log error messages to Sentry
            window.sentry.captureMessage(error.message)
        }

    }

};
