import NotesReplyCollection from 'collections/NotesReplyCollection';

export default Backbone.Model.extend({

    url() {
        if (!this.id) {
            return '/notes/send.json'
        }

        return '/notes/update/' + this.id + '.json'
    },

    initialize() {
        this.note_replies = new NotesReplyCollection(
            this.get('NoteReply') || this.get('Replies')
        )
    }

}, {
    type: 'note'
});
