import Styles from './ListItem.scss';

import Template from './ListItem.hbs';
export default BaseView.extend({
    events: {
        'click .js-list-item-replaceable': 'handleClickListItem',
        'click .js-delete-icon': 'handleClickDeleteIcon'
    },
    initialize({
        leftItemContent,
        rightItemContent,
        deleteListItemCallback,
        editListItemCallback,
        leftItemWidth = 50,
    }) {
        this.deleteListItemCallback = deleteListItemCallback;
        this.editListItemCallback = editListItemCallback;

        this.setElement(Template({
            Styles,
            leftItemContent,
            rightItemContent,
        }));

        this.$('.js-left-item').css({ width: leftItemWidth + '%' });
    },
    handleClickListItem(e) {
        e.stopPropagation();
        this.editListItemCallback(this);

    },
    handleClickDeleteIcon(e) {

        // do not let a possible outer event trigger
        e.stopPropagation();

        this.deleteListItemCallback(this.model);
        this.destroy();
    }
});
