import Styles from './NewsMessage.scss';

import Template from './NewsMessage.hbs';
import Button from 'views/components/button/Button'
import NewsList from '../newsList/NewsList'

export default class NewsMessage extends BaseView {

    initialize() {
        this.setElement(Template({
            Styles,
            title: this.model.get('title'),
            headerImage: this.model.get('header_image_url'),
            htmlText: this.model.get('html_text'),
            date: new dayjs.utc(this.model.get('created_at')).local().format('D MMMM YYYY')
        }))

        this.$('.js-show-all').on('click', () => {
            Backbone.View.sidebar.showSidebar(
                new NewsList(),
                window.i18n.gettext('All messages'),
                520
            )
        })

        if (this.model.get('action_button') && this.model.get('action_button_url')) {
            this.addChildView(
                new Button({
                    label: this.model.get('action_button'),
                    iconRight: 'arrow-right',
                    inline: true,
                    callback: () => {
                        window.open(this.model.get('action_button_url'), '_blank')

                        window.statsTracker.trackEvent(
                            'News',
                            'Click on action button',
                            this.model.id
                        )
                    }
                }),
                '.js-action-button'
            )
        }

        window.statsTracker.trackEvent(
            'News',
            'Open message',
            this.model.id
        )
    }

}