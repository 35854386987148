<script>
    import Icon from '../icon/Icon.svelte'

    export let callback = () => {}
    export let label = ''
    export let isChecked = false
    export let isDisabled = false
    export let onClickDisabled = () => {}
    export let parentGroup = null
    export let rightAlignedText = null
    export let subText = null
    export let tooltip = null
    export let value = null

    const cid = _.uniqueId()

    export function getState() {
        return isChecked
    }

    export function toggleState() {
        setState(!isChecked)
    }

    export function setState(checked, silent = false) {
        isChecked = checked
        if (!silent && callback) {
            callback(isChecked)
        }
    }

    function onClick() {
        if (isDisabled && onClickDisabled) {
            onClickDisabled()
        }
    }

    function onChange() {
        if (callback) {
            callback(isChecked)
        }
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- Label needs to be clickable to explain why a checkbox is disabled -->
<label
    id={cid}
    class="checkbox"
    title={tooltip}
    on:click={onClick}>
    <input
        type="checkbox"
        name={parentGroup}
        {value}
        disabled={isDisabled}
        bind:checked={isChecked}
        on:change={onChange}
    />
    <span class="checkbox__box">
        <Icon name="checkmark"></Icon>
    </span>
    <span class="label-container">
        <span class="label">
            <slot name="label">
                {label}
            </slot>
        </span>
        {#if subText}
            <span class="sub-text">{subText}</span>
        {/if}
    </span>
    {#if rightAlignedText}
        <span class="right-aligned-text">{rightAlignedText}</span>
    {/if}
</label>

<style lang="scss">
    @import "src/styling/globals.scss";

    .checkbox {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:has(input:disabled) {
            cursor: not-allowed;
        }

        &__box {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            height: $spacing-24;
            width: $spacing-24;
            min-width: $spacing-24;

            border-radius: $spacing-8;
            border: 1px solid $status-gray;
            background-color: $white;

            > :global(svg) {
                display: none;
                width: 100%;
                fill: $white;
            }
        }

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;

            &:checked ~ .checkbox__box {
                background-color: $blue;
                border-color: $blue;

                > :global(svg) {
                    display: block;
                }
            }

            &:focus ~ .checkbox__box {
                @include box-shadow-focus;
            }

            &:disabled ~ .checkbox__box {
                border-color: $background-gray;
            }

            &:disabled:checked ~ .checkbox__box {
                background-color: $background-gray;
            }
        }

        .label-container {
            flex-grow: 1;
        }

        .sub-text {
            margin-top: 2px;
            color: $status-gray;
        }

        .label:not(:empty),
        .sub-text:not(:empty) {
            display: block;
            user-select: none;
            margin-left: 16px;
        }

        .right-aligned-text {
            color: $status-gray;
            flex-grow: 1;
            text-align: right;
            margin-left: 10px;
            user-select: none;
        }
    }
</style>
