import ContentLabel from 'views/components/contentLabel/ContentLabel'
import FullScreenVideo from 'views/pages/activities/show/types/video/fullScreenVideo/FullScreenVideo';

export default class VideoActivity extends BaseView {

    initialize() {

        Backbone.View.Components.fullscreen.open(
            FullScreenVideo, {
                model: this.model.task_groups.at(0),
                title: new ContentLabel({
                    model: this.model,
                    isCompact: true,
                    hasNoLink: true,
                }).el.outerHTML
            }
        );

        this.listenToOnce(
            Backbone.View.Components.fullscreen,
            'modalClosed',
            this.destroy
        );
    }

    onDestroy() {
        Backbone.history.navigate(
            '/sections/show/' + this.model.get('section_id'), {
                trigger: true
            }
        );
    }

}
