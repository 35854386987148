var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton") : stack1), depth0))
    + " no-print\">\n\n    <div class=\"js-actionbutton\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__inner") : stack1), depth0))
    + "\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button-label") : stack1), depth0))
    + "  js-actionbutton-label__outer\">\n                <div class=\"js-actionbutton-label\"></div>\n            </div>\n\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button") : stack1), depth0))
    + " js-actionbutton-inner\">\n                <svg class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button-icon") : stack1), depth0))
    + " js-actionbutton--icon\" data-icon-type=\"next\" fill=\"#FFF\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <path d=\"M0 0h24v24H0z\" fill=\"none\"/>\n                    <path d=\"M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z\"/>\n                </svg>\n\n                <svg class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button-icon") : stack1), depth0))
    + " js-actionbutton--icon\" data-icon-type=\"plus\" fill=\"#FFF\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <path d=\"M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z\"></path>\n                    <path d=\"M0 0h24v24H0z\" fill=\"none\"></path>\n                </svg>\n\n                <svg class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button-icon") : stack1), depth0))
    + " js-actionbutton--icon\" data-icon-type=\"confirm\" fill=\"#FFF\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <path d=\"M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z\"/>\n                </svg>\n\n                <svg class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button-icon") : stack1), depth0))
    + " js-actionbutton--icon\" data-icon-type=\"loading\" fill=\"#FFF\" viewBox=\"0 0 38 38\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <g transform=\"translate(1 1)\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\">\n                        <circle stroke-opacity=\".5\" cx=\"18\" cy=\"18\" r=\"18\"/><path d=\"M36 18c0-9.94-8.06-18-18-18\"><animateTransform attributeName=\"transform\" type=\"rotate\" from=\"0 18 18\" to=\"360 18 18\" dur=\"1s\" repeatCount=\"indefinite\"/></path>\n                    </g>\n                </svg>\n\n                <svg class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button-icon") : stack1), depth0))
    + " js-actionbutton--icon\" data-icon-type=\"refresh\" fill=\"#FFF\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <path d=\"M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z\"/>\n                    <path d=\"M0 0h24v24H0z\" fill=\"none\"/>\n                </svg>\n\n                <svg class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actionbutton__button-icon") : stack1), depth0))
    + " js-actionbutton--icon\" data-icon-type=\"cross\" fill=\"#FFF\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <path d=\"M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z\"/>\n                </svg>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});