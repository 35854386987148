import PortfolioModel from 'models/PortfolioModel'

export default class PortfolioCollection extends Backbone.Collection {

    preinitialize() {
        this.constructor.type = 'portfolios'
    }

    get model() {
        return PortfolioModel
    }

    initialize(models, userId) {
        this.userId = userId
    }

    url() {
        return `/portfolio/home/${this.userId}.json`
    }

    parse(response) {
        return response.projects
    }

}
