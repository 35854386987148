import Styles from './Template26.scss';

import Template from './Template26.hbs';
import Answer from 'views/components/taskGroups/tasks/template26/answer/Answer';
import Button from 'views/components/button/Button'

export default BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options object as passed from parent
     */
    initialize(options) {

        // Bind this to the following functions
        _.bindAll(this,
            'getCorrectAnswer',
            'getDataAndSaveAsResponse',
            'loadSketcherModal',
            'lockAnswer',
            'openSketcherModal',
            'showMoleculeStructureOfAnswer',
            'unlockAnswer'
        );

        // Make taskView accessible withing every method
        this.taskView = options.task_view;

        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {
        // Get the response JSON from the taskView
        this.response = this.taskView.response.get('json_answer');

        // Create hasAnswer boolean for when there should be a preview or not
        var hasAnswer = !_.isUndefined(this.response) && !_.isEmpty(this.response.molSketchData)

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        // Only show button for opening edit modal when when in webapp and the parent task view is not
        // in preview mode.
        if (APPLICATION === 'webapp' && !this.taskView.isPreview) {
            this.modalBtn = this.addChildView(new Button({
                label: window.i18n.gettext('Draw structure formula'),
                inline: true,
                icon: 'task-26',
                callback: this.loadSketcherModal
            }), '.js-open-sketcher-modal');
        }

        // Check if the task has an answer
        if (hasAnswer) {

            // convert the json string back to an object and set the response's
            // molSketchData to a this variable so it's accessible within sub methods
            this.responseMolSkechData = JSON.parse(this.response.molSketchData);

            // Delay the execution of the showMoleculeStructureOfAnswer
            _.defer(this.showMoleculeStructureOfAnswer);
        }
    },

    loadSketcherModal() {
        import(
            /* webpackChunkName: "molsketcher" */
            'views/components/taskGroups/tasks/template26/fullscreenMolSketcher/FullscreenMolSketcher'
        ).then(this.openSketcherModal)
    },

    /**
     * openSketcherModal
     *
     * This function will be executed when the user clicks on the open sketcher modal button.
     * It will open the molecule sketch on full screen.
     *
     */
    openSketcherModal({default: FullscreenMolSketcher}) {

        // Use the fullscreen component to open the molSketcher
        Backbone.View.Components.fullscreen.open(
            FullscreenMolSketcher, {

                // Set the molSketchData to the molSketchData from the response
                molSketchData: this.responseMolSkechData || false,

                // Set the title to the task's introduction, but do remove any <p> tags to remove padding.
                title: this.model.get('introduction')?.replace(/<\/?p>/g, ''),

                // Add buttons to this fullscreen view
                buttons: [{
                    label: window.i18n.gettext('Cancel'),
                    theme: 'secondary',
                    callback: Backbone.View.Components.fullscreen.close
                }, {
                    label: window.i18n.gettext('Save and go back'),
                    callback: this.getDataAndSaveAsResponse,
                    // Listen to the enter button (keycode: 13)
                    keyCode: 13
                }]
            }
        );
    },

    /**
         * getDataAndSaveAsResponse
         *
         * This function will be called when the user clicks on the save and go back button. It will get
         * the molSketch data from the editor within and save it as a response.
         *
         */
    getDataAndSaveAsResponse() {

        // Get the molSketchData from the subview and store it in a temporary variable
        this.responseMolSkechData = Backbone.View.Components.fullscreen.subView.getMolSketchData();

        // Only do stuff when user is a student
        if (Backbone.Model.user.get('is_student')) {

            // Add the molSketchData tot the response object
            var responseObject = {

                // Make molSketchData a string to prevent type conversion
                molSketchData: JSON.stringify(this.responseMolSkechData)
            };

            // Save the updated response
            this.taskView.saveResponse(responseObject);
        }

        // Check if there are any atoms and that there are any bonds
        if (this.responseMolSkechData.atoms.length || this.responseMolSkechData.bonds.length) {

            // If there is a student answer view, use it
            if (this.studentAnswer) {

                // Update the student answer with the new molSketchData
                this.studentAnswer.loadData(this.responseMolSkechData);

                // Else
            } else {

                // Construct a student answer
                this.showMoleculeStructureOfAnswer();
            }

        } else {

            // Check if there already is a child view
            if (this.studentAnswer) {

                // Unregister and destroy the student answer
                this.unregisterAndDestroyChildView(
                    this.studentAnswer
                );

                // clear student answer value
                this.studentAnswer = undefined;
            }
        }

        // Close the fullscreen
        Backbone.View.Components.fullscreen.close()
    },

    /**
         * showMoleculeStructureOfAnswer
         *
         * This function will show the container for the answer and initialize
         * the molsketch library on it to render the, by student, given answer
         *
         */
    showMoleculeStructureOfAnswer() {

        // Create a new Answer and store it within this.studentAnswer
        this.studentAnswer = new Answer({

            // Pass the initial molSketchData with it
            molSketchData: this.responseMolSkechData
        });

        // Register as childView
        this.registerChildView(this.studentAnswer);

        // Add it to the DOM
        this.studentAnswer.appendTo(this.$('.js-student-answer-holder'));

        // Call the show function
        this.studentAnswer.show();
    },

    showAnswer() {

        // Get the response model from the task view
        var responseModel = this.taskView.response;

        // Get the task info json from the model
        var taskInfoJson = this.model.get('task_info_json');

        // Create a boolean for if there is an answer set
        var hasAnswer = (!_.isUndefined(taskInfoJson) && !_.isEmpty(taskInfoJson.molSketchData));

        // If there is an answer set, create a new Answer view
        if (hasAnswer) {

            // Create a new Answer
            this.modelAnswer = new Answer({

                // Pass the taskInfoJson's answer
                molSketchData: taskInfoJson.molSketchData,

                label: window.i18n.gettext('Model answer'),
            });

            // Register the model answer as childView
            this.registerChildView(this.modelAnswer);

            // Add it to the model answer holder
            this.modelAnswer.appendTo(this.$('.js-model-answer-holder'));

            // Check if grading mode is auto and there is a student answer
            if (this.model.get('grading_mode') === 'auto' && this.studentAnswer) {

                // Call the setStateClass method on the model answer
                this.studentAnswer.setStateClassByScore(responseModel.get('score'));
            }

            // Call the show function
            this.modelAnswer.show();
        }
    },

    showAuthorAnswer() {
        this.showAnswer()
    },

    hideAnswer() {

        // Check if the model answer (still) exists
        if (this.modelAnswer) {

            // Destroy it
            this.unregisterAndDestroyChildView(this.modelAnswer);

            // Only do this if there is a student answer
            if (this.studentAnswer) {

                // Call the remove state class method
                this.studentAnswer.removeStateClass();
            }
        }
    },

    getCorrectAnswer() {

        // Get the task info json from the model
        var taskInfoJson = this.model.get('task_info_json');

        // Create a boolean for if there is an answer set
        var hasAnswer = (!_.isUndefined(taskInfoJson) && !_.isEmpty(taskInfoJson.molSketchData));

        // If there is an answer set, create a new Answer view
        if (hasAnswer) {

            // Return a new Answer (CheckAnswerView will register it as childview)
            return new Answer({

                // Pass the taskInfoJson's answer
                molSketchData: taskInfoJson.molSketchData
            });

        }

        // Else return empty string, which will result in the message that there
        // isn't a model answer available.
        return ''
    },

    getStudentAnswer(studentResponseModel) {

        // Distill the answer json from the studentResponseModel
        var answerJson = studentResponseModel.get('json_answer');

        // Create a boolean for if there is an answer set
        var hasAnswer = (!_.isUndefined(answerJson) && !_.isEmpty(answerJson.molSketchData));

        // If there is an answer set, create a new Answer view
        if (hasAnswer) {

            // Return a new Answer (Student answer column will register it as childview)
            return new Answer({

                // Pass the student's answer
                molSketchData: JSON.parse(answerJson.molSketchData),

                // Make answer full width
                fullWidth: true
            });

        }

        // Else return empty string, which will result in the message that there
        // isn't an answer available
        return ''
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {
        if (this.modalBtn) {
            this.modalBtn.disable()
        }
    },

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {
        if (this.modalBtn) {
            this.modalBtn.enable()
        }
    }

});
