import Styles from './AnimatingAvatar.scss';

import Template from './AnimatingAvatar.hbs';
import Avatar from 'views/components/avatar/Avatar'

export default class AnimatingAvatar extends BaseView {

    initialize({ avatar }) {
        this.setElement(Template({ Styles }))
        this.addChildView(
            new Avatar({ avatar }),
            '.js-avatar'
        )
    }

}