var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"frameworkTitle") : depth0), depth0))
    + "</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"logo") : stack1), depth0))
    + " js-method-logo\">\n	"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"learnbeat",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":2,"column":24}}})) != null ? stack1 : "")
    + "\n</div>\n\n<table class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"table") : stack1), depth0))
    + " js-table-holder\">\n	<tr>\n		<td></td>\n		<td>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Subject",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":22}}}))
    + "</td>\n		<td>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Question type",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":28}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"frameworkTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "")
    + "	</tr>\n</table>\n\n<table class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"table") : stack1), depth0))
    + "\">\n	<tr>\n		<td>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Author of this content",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":37}}}))
    + "</td>\n	</tr>\n	<tr>\n		<td class=\"js-author\">…</td>\n	</tr>\n</table>\n\n<div class=\"js-cc-license "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"cc-license") : stack1), depth0))
    + "\">\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"cc-license__icon") : stack1), depth0))
    + "\">\n		"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"creative-commons",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":27,"column":2},"end":{"line":27,"column":32}}})) != null ? stack1 : "")
    + "\n	</div>\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"cc-license__text") : stack1), depth0))
    + "\">\n		<div>\n			"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"License:",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":31,"column":3},"end":{"line":31,"column":20}}}))
    + " <a href=\"https://creativecommons.org/licenses/by-sa/4.0/\" target=\"_blank\">Creative Commons (CC BY-SA 4.0)</a>\n		</div>\n		<div>\n			("
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Modified by Learnbeat",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":34,"column":34}}}))
    + ")\n		</div>\n	</div>\n</div>";
},"useData":true});