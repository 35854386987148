export default Backbone.Model.extend({

    initialize() {

        this.set('searchContent', '');
        this.set('isVisible', true);
        this.on('changeFilter', this.onChangeFilter);
    },

    url() {
        if (!this.id) {
            return '/annotations/save_new_annotation.json';
        }
        return '/annotations/save_annotation/' + this.id + '.json'
    },

    // Will be called on trigger changeFilter
    onChangeFilter(state) {
        // Set the isVisible to the state of the filter checkbox
        this.set('isVisible', state);
    },
}, {
    type: 'annotation'
});
