import Styles from './CharacterButton.scss';

import Template from './CharacterButton.hbs';
export default BaseView.extend({

    initialize({
        character,
        input
    }) {

        this.setElement(Template({
            Styles,
            character
        }))

        // Insert the character when the button is clicked
        this.el.addEventListener('click', () => {
                
            // Insert the character at the cursor position (and overwrite selected text)
            const before = input.value.substr(0, input.selectionStart)
            const after = input.value.substr(input.selectionEnd)
            input.value = before + this.el.textContent + after

            // Place the cursor after the inserted character
            const cursorPosition = before.length + 1
            input.setSelectionRange(cursorPosition, cursorPosition)
            input.focus()
        })

        // Allow inserting capitals using the shift key
        input.addEventListener('keydown', (e) => {
            if (e.key === 'Shift') {

                // The replace is needed because ß.toUpperCase() turns into SS 
                this.el.textContent = this.el.textContent.toUpperCase().replace('SS', 'ẞ')
            }
        })
        input.addEventListener('keyup', (e) => {
            if (e.key === 'Shift') {
                this.el.textContent = this.el.textContent.toLowerCase()
            }
        })
    }

})