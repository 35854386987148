var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"model-answer") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"onlyOneAnswer") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":134}}})) != null ? stack1 : "")
    + " work-on__task-model-answer-outer js-answer-example\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"doShowModelAnswer") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":187},"end":{"line":3,"column":248}}})) != null ? stack1 : "")
    + ">\n\n        <div class=\"work-on__task-model-answer-wrap js-answer-example-wrap\">\n            <div class=\"work-on__task-model-answer-label\">"
    + alias2(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias3,"Model answer",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":6,"column":58},"end":{"line":6,"column":79}}}))
    + "</div>\n            <div class=\"work-on__task-model-answer-inner js-correct-answer-holder\">\n                "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"correctAnswer") : depth0), depth0)) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":65},"end":{"line":3,"column":123}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"is-sticky") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"js-actions "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"actions") : stack1), depth0))
    + "\">\n                <div class=\"js-note-to-all\"></div>\n                <div class=\"js-dropdown-view\"></div>\n            </div>\n"
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/isNotEqual.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"presentation",{"name":"isNotEqual","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":27}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"work-on__task-notes js-class-notes\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"js-close-answer-button "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-window__button-close") : stack1), depth0))
    + " js-check-answer\">\n                "
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/shape.js")).call(alias2,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":31,"column":35}}})) != null ? stack1 : "")
    + "\n                "
    + alias1(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias2,"Close answers by students",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":50}}}))
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"model-answer-and-answers") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"work-on__student-answers js-student-answers-container "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"student-answers-inner") : stack1), depth0))
    + "\">\n	   <div class=\"work-on__student-answers-inner\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"js-student-answers "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"student-answers") : stack1), depth0))
    + "\">\n                <div class=\"js-no-students "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"no-students") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias3,"There are no students in this group yet.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":26,"column":67},"end":{"line":26,"column":116}}}))
    + "</div>\n                <div class=\"js-hidden-answers-state "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"hidden-answers-state") : stack1), depth0))
    + "\"></div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isInPresentMode") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":34,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});