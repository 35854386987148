import Styles from './SetListNameModal.scss';

import Template from './SetListNameModal.hbs';
import QuickInput from 'views/components/quickInput/QuickInput.svelte'

export default class SetListNameModal extends BaseView {

    initialize({
        editMode = false,
        course
    }) {
        this.editMode = editMode
        this.course = course

        const bodyText = this.editMode ? '' : window.i18n.gettext('Name for your list for') + ' ' + course.label
        const listName = this.editMode ? this.model.get('metadata').name : ''

        this.setElement(Template({
            Styles,
            listName,
            bodyText
        }))

        this.nameInput = this.addSvelteChildView(
            '.js-change-name-input',
            QuickInput,
            {
                value: listName,
                placeholder: window.i18n.gettext('chapter 1 paragraph 2'),
                submitCallback: () => { this.onSubmit() },
                showSubmitButton: false,
                autoFocus: true,
            },
        )
    }

    addButtons() {
        Backbone.View.Components.modal.addButtons({
            [window.i18n.gettext('Cancel')]: {
                callback: Backbone.View.Components.modal.close,
                theme: 'secondary',
            },
            [this.editMode ? window.i18n.gettext('Save') : window.i18n.gettext('Continue')]: {
                callback: this.onSubmit,
                keyCode: 13,
            }
        })
    }

    onSubmit() {
        const name = this.nameInput.getValue()
        if (!name) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Enter a name')
            )
            return
        }

        // Update the list name and inform the parent view
        if (this.editMode) {

            $.ajax({
                url: '/training/update/' + this.model.id,
                type: 'PATCH',
                data: JSON.stringify({
                    name
                }),
                contentType: 'application/json',
            })

            this.model.get('metadata').name = name
            this.model.trigger('listNameUpdated')

            Backbone.View.Components.modal.close()
            return
        }

        // Create the list and navigate to it
        $.post('/training/add', {
            name,
            course_id: this.course.id
        }).then(response => {
            if (response.status === 'success') {
                const listId = response.training_list_id
                Backbone.history.navigate(`/words/list/${listId}`, { trigger: true })
            }
        })

        Backbone.View.Components.modal.close()

    }
}
