import Template from './Expression.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import LatexEditor from 'views/components/wysiwyg/keyboards/latexEditor/LatexEditor';
import LatexTemplate from 'views/components/wysiwyg/elements/latex.hbs';

export default class Expression extends Button {

    // Built-in TinyMCE command for inserting content at cursor position.
    command = 'mceInsertContent'

    // Activate button state if expression is in editor selection/cursor.
    commandQueryName = 'insertExpression'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Formula');
    }

    onClickButton(e) {

        // can also be called programmatically, so check for event first
        if (e) {
            this.stopAllEvents(e);
        }

        // If button is active && the keyboard is already opend, close keyboard.
        if (this.getActiveState() && Backbone.View.Components.keyboard.isOpen) {

            Backbone.View.Components.keyboard.close();

        } else {

            // Set wysiwyg toolbar button to the active state.
            this.setActive();

            // Force blur of tinyMCE editor. This hides the native keyboard on touchscreen devices.
            if (this.wysiwyg.editor) {
                this.wysiwyg.editor.mode.set('readonly');
                this.wysiwyg.editor.mode.set('design');
            }

            // Open the keyboard with the key layout from the current view, using the LatexEditor as
            // InputView, with the selected element from the editor as view option.
            // Don't define a onInputCallback, but do pass a onCloseCallback.
            Backbone.View.Components.keyboard.open(
                LatexEditor.keyboardLayout,
                LatexEditor,
                {
                    atElement: this.wysiwyg.getElementSelected()
                },
                undefined,
                this.onCloseCallback.bind(this)
            );

        }

    }

    /**
     * onCloseCallback
     *
     * When closing the keyboard, trigger an MceCommand, inserting the LaTeX formula img into the editor.
     */
    async onCloseCallback() {
        const keyboardOutput = await Backbone.View.Components.keyboard.inputView.getOptions()
        if (!keyboardOutput) {
            return
        }
        // If keyboard output is empty, do not insert the template.
        if (Object.keys(keyboardOutput).length === 0) {
            // If the currently selected element is already existing expression being edited, remove this element
            // to reflect the empty data from the keyboard.
            if (this.wysiwyg.getElementSelected()?.classList.contains('js-expression')) {
                this.wysiwyg.getElementSelected().remove()
            }
        } else {
            // Retrieve the final result from LatexEditor, the inputView of the keyboard component.
            const finishedElement = LatexTemplate(keyboardOutput)

            // Execute editor command, provided with the data from LatexEditor in order to assemble the content
            // to be inserted in the editor.
            this.wysiwyg.onMceCommand(
                this,
                finishedElement
            )
        }
    }

    /**
     * addCommands
     *
     * Adds a insertion command to the editor that can be triggered by the current
     * button view.
     *
     * @param {Object} editorObject Data on the editor itself.
     */
    addCommands(editorObject) {
        const editor = editorObject.editor
        const customSH = editorObject.customSH

        // Create the state handler for this block element
        customSH.ExpressionHandler(
            // Listen to command: insertExpression
            this.commandQueryName,
            // Pass the editor with it
            editor
        );
    }

}
