import Styles from './StatusCard.scss';

import Template from './StatusCard.hbs';
export default class StatusCard extends BaseView {

    get contentContainer() {
        return this.$('.js-card-content');
    }

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param {Object} {
     *  icon: string containing the icon that should be shown
     *  statusTitle: string containing the title of the card
     *  cardContent: string containing the content of the card for when no template
     *  statusColor: string containing the color state of the card. Default is blue
     * }
     */
    initialize({
        icon, statusTitle, cardContent, statusColor
    }) {
        // Create the view, passing the styling with it
        this.setElement(Template({
            icon,
            Styles,
            statusTitle,
            cardContent,
            statusColorClass: Styles['card-container__type--' + statusColor],
        }))
    }
}