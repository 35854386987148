import Template from './Underline.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Underline extends Button {

    // Built-in TinyMCE command for underlined text
    command = 'Underline'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Underline');
    }

}
