import Styles from './Template16.scss';

import Template from './Template16.hbs';
import ParseSentenceModal from './modal/Modal';
import Button from 'views/components/button/Button'
import AnswerExplanation from 'views/components/taskGroups/tasks/answerExplanation/AnswerExplanation'
export default class Template16 extends BaseView {

    // Store the definitionMapping in a getter so we it's possible to
    // translate it if it's ever needed to be translated.
    get definitionMapping() {
        return {
            o: 'Onderwerp',
            lv: 'Lijdend voorwerp',
            mv: 'Meewerkend voorwerp',
            bwb: 'Bijwoordelijke bepaling',
            bvb: 'Bijvoeglijke bepaling',
            wg: 'Werkwoordelijk gezegde',
            ng: 'Naamwoordelijk gezegde',
            dvb: 'Dubbelverbonden bepaling',
            vzv: 'Voorzetselvoorwerp',
            vw: 'Voegwoord'
        }
    }

    initialize({
        task_view,
    }) {

        _.bindAll(
            this,
            'createHTMLFragments',
            'onClickStartParsing',
            'showAnswer',
            'hideAnswer',
            'getCorrectAnswer',
            'getStudentAnswer',
            'lockAnswer',
            'unlockAnswer'
        )

        const {
            isPreview,
            response,
            show_answers,
            saveResponse
        } = task_view;

        this.response = response;
        this.show_answers = show_answers;
        this.saveResponse = saveResponse;

        this.setElement(Template({
            Styles
        }))

        this.parseButton = this.addChildView(
            new Button({
                label: window.i18n.gettext('Parse sentence'),
                callback: this.onClickStartParsing,
                icon: 'task-16',
                inline: true,
                isDisabled: APPLICATION !== 'webapp' || isPreview
            }),
            this.$('.js-button')
        );

        this.renderSentence();

        this.listenTo(
            this.response,
            'change:json_answer',
            () => {
                console.log('hello change');
                this.renderSentence();
            }
        );
    }

    /**
     * Method that will render the sentence on the screen. This allows us to rerender it.
     *
     * @param {Object} fakeData object containing words and underlined to fake a render for teachers
     * @memberof Template16
     */
    renderSentence(fakeData) {

        // Get the words and underlined from the response. If there is no response, use the task_info_json
        const { words, underlined } = fakeData || this.response.get('json_answer') || this.model.get('task_info_json')
        const fragments = this.createHTMLFragments(words, underlined);
        const sentenceHolder = this.el.querySelector('.js-sentence');

        sentenceHolder.innerText = '';
        fragments.forEach(fragment => sentenceHolder.appendChild(fragment));
    }

    /**
     * Method that will create HTML fragments for the given words. It'll
     * use the underlined parameter to determine which words should be
     * highlighted. The words argument is an array containing every
     * seperated piece of the sentece (every "cut").
     *
     * @param {array} words array containing "cuts"
     * @param {array} underlined array containg info about underlined words
     * @param {boolean} wrapWords whether to wrap each word in its own element
     *
     * @returns {array} containing a HTML structure for each "cut"
     * @memberof Template16
     */
    createHTMLFragments(words, underlined) {

        // Create a copy of underlined since we're using the shift method.
        // Using a copy prevents the original underlined array from being
        // modified.
        let wordIndex = 0;

        /**
         * The previous version of this template had some logic for
         * invisible separators which splitted the reading symbols from
         * the words. Since we don't want this feature anymore since it
         * needs a lot of extra code, we just add the reading symbol to
         * the word that comes before it. To prevent grading issues, we've
         * changed the grading algoritm to ignore reading symbols
         * completely.
         *
         * @param {array} words array containing word parts
         * @returns {array} containing modified word array
         * @memberof Template16
         */
        const fixLegacyWordsStructure = (words) => {
            words.forEach(({
                text,
                parse
            }, index) => {
                if (parse === '' && index > 0) {
                    words[index - 1].text += text;
                    words[index - 1].legacyAddition = text;
                    words.splice(index, 1);
                }
            });

            return words
        }

        words = fixLegacyWordsStructure(words);

        return words.reduce((fragments, { text, parse }) => {

            const fragment = document.createElement('section');

            const sentenceCard = document.createElement('div');
            sentenceCard.dataset.type = 'sentence-partial';

            (text.toString().split(' ')).forEach(word => {

                const wordElement = document.createElement('span');
                wordElement.dataset.type = 'word';

                // To prevent "1" from not parsing as underlined, use a parseInt so
                // we can check for the real integer version of 1
                if (parseInt(underlined[wordIndex]?.underlined) === 1) {
                    const underlined = document.createElement('u');
                    underlined.textContent = word;
                    wordElement.appendChild(underlined);
                } else {
                    wordElement.textContent = word;
                }

                sentenceCard.appendChild(wordElement);

                const space = document.createTextNode(' ');
                sentenceCard.appendChild(space);

                wordIndex++;
            })

            fragment.appendChild(sentenceCard);

            // If there is a parse option set, and it isn't a wildcard (unset)
            // add it to the dataset so we can show it using CSS
            if (parse && parse !== '*') {
                fragment.dataset.type = parse;
                if (this.definitionMapping[parse]) {
                    fragment.title = this.definitionMapping[parse];
                }
            }

            fragments.push(fragment)
            return fragments;
        }, [])
    }

    /**
     * This method will be called when the user click on the "parse" button.
     * It'll open the modal which lets the user parse the sentence.
     *
     * @memberof Template16
     */
    onClickStartParsing() {

        const buttons = [];

        // ! Outcommentent the show answer button since it is different from
        // ! all other templates which "locks" on viewing the modelanswer.
        // ! But keep the code here in case a lot of people will notice it
        // ! and start complaining. If we reenable this button, we should also
        // ! look at aligning the modelanswer withing the fullscreen modal.
        // if (this.show_answers &&
        //     this.model.get('grading_mode') !== 'none'
        // ) {
        //     buttons.push({
        //         icon: 'lightbulb',
        //         label: window.i18n.gettext('Show answer'),
        //         theme: 'secondary',
        //         callback: 'onShowAnswer'
        //     });
        // }

        buttons.push({
            label: window.i18n.gettext('Save and close'),
            callback: 'onClickSave'
        });

        Backbone.View.Components.fullscreen.open(ParseSentenceModal, {
            title: window.i18n.gettext('Parsing'),
            buttons,
            model: this.model,
            parentView: this
        });
    }

    /**
     * Method which will show the modelanswer to the user.
     *
     * @memberof Template16
     */
    showAnswer() {

        const {
            correct_words: words,
            correct_underlined: underlined
        } = this.model.get('task_info_json')

        const sentenceHolder = document.createElement('section');
        sentenceHolder.classList.add(Styles['sentence']);

        const fragments = this.createHTMLFragments(words, underlined);
        fragments.forEach(fragment => sentenceHolder.appendChild(fragment));

        if (APPLICATION === 'author') {
            this.$('.js-sentence').html(sentenceHolder)
            return
        }

        const answerExplanation = this.addChildView(
            new AnswerExplanation({
                isModelAnswer: true,
                title: window.i18n.gettext('Correct answer')
            }),
            '.js-sentence',
            'after'
        );

        // Use append child to add the sentenceholder to the element.
        // this is because the AnswerExplanation component renders
        // its content using tripple backets which is susceptible to
        // XSS injectes. We should fix that component and add this the
        // correct way. But since thats a project on its own, do it
        // this way temporarily.
        answerExplanation
            .el
            .querySelector('.js-answer-text')
            .appendChild(sentenceHolder);
    }

    showAuthorAnswer() {
        this.showAnswer()
    }

    /**
     * Method to remove/hide the modelanswer
     *
     * @memberof Template16
     */
    hideAnswer() {
        this.destroyChildViewsOfInstance(AnswerExplanation);
    }

    /**
     * Method to return the correct answer which the teacher will
     * see as modelanswer when checking the student answers
     *
     * @returns {string} containing a HTML answer.
     * @memberof Template16
     */
    getCorrectAnswer() {
        const {
            correct_words: words,
            correct_underlined: underlined
        } = this.model.get('task_info_json')

        const answerHolder = document.createElement('article');
        answerHolder.classList.add(Styles['sentence']);

        const fragments = this.createHTMLFragments(words, underlined);
        fragments.forEach(fragment => answerHolder.appendChild(fragment));
        return answerHolder.outerHTML;
    }

    /**
     *  Method which will show the answer of a student to the teacher
     *
     * @param {Backbone.Model} response model containing the current response
     * @returns {string} containing a HTML answers
     * @memberof Template16
     */
    getStudentAnswer(response) {

        // An answer can be empty, therefore add the exclamation mark to
        // prevent crashes on missing words and underlined
        if (response.get('json_answer')?.words
            && response.get('json_answer')?.underlined
        ) {
            const { words, underlined } = response.get('json_answer')
            const answerHolder = document.createElement('article');
            const fragments = this.createHTMLFragments(words, underlined);
            fragments.forEach(fragment => answerHolder.appendChild(fragment));
            answerHolder.classList.add(Styles['sentence']);
            return answerHolder.outerHTML;
        }

        return '';
    }

    /**
     * lockAnswer
     *
     * Triggered when the lock-answers event is sent from a Presentation view.
     * It will make sure students can't change their answer. This is typically
     * done when the taskState inside the presentation is 1 or 2, meaning the
     * teacher is showing the student's answers.
     */
    lockAnswer() {
        this.parseButton.disable();
    }

    /**
     * unlockAnswer
     *
     * Triggered when the unlock-answers event is sent from a Presentation view.
     * It will make sure students chan fill an answer again.
     */
    unlockAnswer() {
        this.parseButton.enable();
    }
}
