import Styles from './Template25.scss';

import Template from './Template25.hbs';
import CoordinateView from 'views/components/taskGroups/tasks/template25/coordinate/Coordinate';
import Draggable from 'views/components/taskGroups/tasks/template25/draggable/Draggable';

export default class Template25 extends BaseView {

    initialize(options) {

        // Make the taskview from options accesible in the this scope
        this.taskView = options.task_view

        _.bindAll(
            this,
            'saveAnswer',
            'lockAnswer',
            'unlockAnswer',
            'renderDraggables',
            'getShortestDistance',
            'resizeImage',
            'renderCoordinates'
        );

        this.renderTask()
    }

    /**
         * renderTask
         *
         * This method will render the template of the task. It will be
         * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
         * if only the answer view is necessary
         *
         */
    renderTask() {

        // Empty the coordinates array
        this.coordinates = [];

        // Create an empty array that will hold all draggable answers
        this.draggables = [];

        // Create an empty array that will hold the answers from the draggable task;
        this.dragResponses = [];

        // Check if task_info is passed through the model.
        // If so, bind model attributes to the view.
        if (this.model.get('task_info_json') !== undefined) {
            this.taskInfoJSON = this.model.get('task_info_json');
            this.image = this.taskInfoJSON.image;
            this.answerType = this.taskInfoJSON.answerType;

            this.isDragText = this.answerType === 'dragText';
        }

        // If it's a dragText task, check if there are reponses given.
        // Add them to the drag responses array
        if (this.taskView.response.get('json_answer') && this.isDragText) {
            this.dragResponses = _.clone(this.taskView.response.get('json_answer'));
        }

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            image: this.image,
            dragText: this.isDragText
        }));

        // Listen to save answers triggers put in functions.
        // When triggered call the saveAnswer function.
        this.listenTo(this, 'saveAnswer', this.saveAnswer);

        // If there is task_info passed through the model
        if (this.taskInfoJSON) {

            // Render coordinates on the image container
            this.renderCoordinates();

            // If it's a dragText excercise
            if (this.isDragText) {

                // render the draggable elements
                this.renderDraggables();

                // And apply the --drag class to the image container
                this.$('.js-container-outer').addClass(Styles['image-container-outer--drag']);
            }
        }

        this.el.querySelector('img.js-image').addEventListener('load', () => {
            this.resizeImage()
            if ('ResizeObserver' in window) {
                const resizeObserver = new ResizeObserver(() => {
                    window.requestAnimationFrame(() => {
                        this.resizeImage()
                    })
                })
                resizeObserver.observe(this.el)
            }
        })

    }

    /**
     * This function resizes the image based on the width and height of its container.
     * It makes sure that the image is not to big or out of proportion.
     */
    resizeImage() {

        // If the image width is greater than its height
        const imageElement = this.el.querySelector('img.js-image')
        if (imageElement.width > imageElement.height || ISMOBILE) {
            // Present the task as a column
            // This will put the label container underneath the image, which gives the image more room to scale
            this.$('.js-task-container').addClass(Styles['task-container--is-column']);
        }

        var imageContainer = this.$('.js-container-outer');

        // Add the width of the container to the view.
        // This is also needed for the positioning of the input
        this.imageWidth = imageContainer.innerWidth();

        // When image is ready and showing
        if (imageElement.complete) {

            // Check if the label of the coordinate needs to be re-aligned
            _.each(this.coordinates, (coordinate) => {

                // Don't bother checking if label needs to be re-aligned when label is undefined
                if (coordinate.label !== undefined) {
                    coordinate.checkLabelAlignment();
                }
            });

            // Set the size of the drop / click targets
            this.getShortestDistance()
        }
    }

    /**
         * renderDraggables
         *
         * This function renders an array of draggable objects.
         * It checks if responses are already given and makes sure that they are not displayed
         * as answer options.
         *
         */
    renderDraggables() {

        // Create a variable draggable array
        var draggableArray;

        // Check if there are answer options given through the taskmodel.
        // This means that it's not possible to check answers and the labels are passed as answer options.
        if (this.isDragText) {

            // Base the draggable array on the answer options
            draggableArray = this.taskInfoJSON.choices;

        } else {

            // This means that it's possible to check answers and the labels are passed along in the
            // answers array inside objects.
            // The labels need to be plucked from this array.
            draggableArray = _.pluck(this.taskInfoJSON.answers, 'label');

        }

        // Pluck all labbels from the given responses
        var responseLabels = _.pluck(this.dragResponses, 'label');

        // Iterate through the draggable array
        _.each(draggableArray, (answer) => {

            if (answer) {
                // Create a new draggable component out of every iteration
                var draggable = new Draggable({

                    // Set label
                    label: answer,

                    // And assign parent
                    parent: this
                });

                // Check if the response label equals the answer itaratee
                if (_.indexOf(responseLabels, answer) !== -1) {

                    // Hide this answer option draggable
                    draggable.$el.hide();
                }

                this.registerChildView(draggable);

                // Append each draggable to the labels container
                this.$('.js-labels').append(draggable.$el);

                // Save the labels in the array of draggable components
                this.draggables.push(draggable);
            }

        });

    }

    /**
         * renderCoordinates
         *
         * This function loops through the array of answers and render coordinates views on the image container.
         * If there any answers saved in the response model, add the label to the coordinate view.
         */
    renderCoordinates() {
        var label;
        _.each(this.taskInfoJSON.answers, (answer) => {
            // If JSON is passed through the response model.
            if (this.taskView.response.get('json_answer') !== undefined) {

                var matchingResponse = _.find(this.taskView.response.get('json_answer'), (response) => {

                    // Dont strict check here because both keys can be either a string or an integer.
                    // TODO fix setting of keys in the backend.
                    // Linear keys (1, 2, 3) are casted to integers automatically.
                    return answer.key == response.key;
                });

                label = matchingResponse ? matchingResponse.label : '';

                // When no JSON is passed. For example, when the teacher is viewing this task.
            } else {

                // Set the label to an empty string
                label = '';
            }

            if (answer.variants) {
                this.variants = _.pluck(answer.variants, 'text');
            }

            // Create a new coordinate component and set the following:
            var coordinate = new CoordinateView({
                // Position the coordinate based on the given X and Y
                x: answer.x,
                y: answer.y,
                parent: this,
                type: this.answerType,
                correctAnswer: answer.label,
                variants: this.variants,
                label,
                key: answer.key,
                model: this.model
            });

            this.registerChildView(coordinate);

            // Append the new coordinate view to the image container.
            this.$('.js-image-container').append(coordinate.$el);

            // Add the new coordinate view to the array of coordinates
            this.coordinates.push(coordinate);

        });

    }

    /**
         * getShortestDistance
         *
         * This function takes the coordinates array and calls the get distance function
         * if the two coordinate don't match.
         *
         */
    getShortestDistance() {

        // Remove min distances from possible previous run
        for (const node of this.el.querySelectorAll('[data-min-distance]')) {
            delete node.dataset.minDistance
        }

        // If there are more than 2 coordinates
        if (this.coordinates.length > 1) {

            // Loop into the coordinates array
            _.each(this.coordinates, (coordinateA, indexA) => {

                // Loop another time into the coordinates array
                _.each(this.coordinates, (coordinateB, indexB) => {

                    // Make sure the indexes of booth loops are not alike
                    if (indexA < indexB) {

                        // Then call the getDistance function
                        var distance = this.getDistance(coordinateA.$el, coordinateB.$el);

                        this.findMaxDroppableArea(distance, coordinateA.el.querySelector('.js-dropzone'))
                        this.findMaxDroppableArea(distance, coordinateB.el.querySelector('.js-dropzone'))

                    }
                });
            });

            this.setDroppableArea()
        }

    }

    /**
         * The smallest distance between the current dropzone element, and another one. Anytime this function is called
         * for a dropzone element, replace the [data-min-distance] value with the lowest distance
         *
         * @param {float} distance distance current dropzoneElement and another one
         * @param {HTMLDivElement} dropzoneElement dropzone element
         * @returns {void}
         */
    findMaxDroppableArea(distance, dropzoneElement) {

        if (!dropzoneElement.dataset.minDistance) {
            dropzoneElement.dataset.minDistance = distance
            return
        }

        const previousMinDistance = parseFloat(dropzoneElement.dataset.minDistance)

        if (distance < previousMinDistance) {
            dropzoneElement.dataset.minDistance = distance
        }

    }

    /**
         * Give these coordinates as much space as they can consume without overlapping another element
         *
         */
    setDroppableArea() {
        const overlappingCoordinates = this.el.querySelectorAll('[data-min-distance]')

        for (const element of overlappingCoordinates) {

            const coordinateElement = element.parentNode.querySelector('.js-coordinate')
            const animationElement = element.querySelector('.js-animation')

            let minDistance = parseFloat(element.dataset.minDistance)

            if (minDistance > 150) {
                minDistance = 150
            }

            element.style = `height: ${minDistance}px; width: ${minDistance}px; margin: ${-minDistance / 2 + $(coordinateElement).outerWidth() / 2}px`
            animationElement.style.margin = `${ (minDistance - $(animationElement).outerWidth()) / 2 }px`

        }
    }

    /**
         * getDistance
         *
         * This function calculates the distance between two coordinates.
         *
         * @param  {Object} coordinateA - the first coordinate element
         * @param  {Object} coordinateB - the second coordinate element
         * @returns {integer} the calculated distance between the coordinates
         */
    getDistance(coordinateA, coordinateB) {

        // Find offset for coordinate A
        var offsetA = coordinateA.position();

        // Find offset for coordinate B
        var offsetB = coordinateB.position();

        // Use the theory of Pythagoros from https://en.wikipedia.org/wiki/Pythagorean_theorem
        var distance = Math.sqrt(Math.pow(offsetA.top - offsetB.top, 2) + Math.pow(offsetA.left - offsetB.left, 2));
        return distance;
    }

    /**
         * showAnswer
         *
         * This function invokes the checkAnswer function
         * on every value in the coordinate array.
         *
         */
    showAnswer() {
        _.invoke(this.coordinates, 'checkAnswer');
        if (!this.isDragText) {
            _.invoke(
                this.coordinates,
                'deleteInput'
            );
        }
    }

    showAuthorAnswer() {
        this.showAnswer()
    }

    /**
         * hideAnswer
         *
         * This functions invokes the uncheck answer on every value in the coordinates array.
         *
         */
    hideAnswer() {
        _.invoke(this.coordinates, 'unCheckAnswer');
    }

    lockAnswer() {
        // Set an optional callback depending on if response needs scoring
        var callback = this.taskView.response.get('need-scoring') ?

        // If so, when user tries to edit user's response, trigger status message
        // telling user needs to score first
            this.taskView.openScoreFirstMessage :

        // Else make the callback null
            null;

        if (this.isDragText) {
            _.invoke(
                this.draggables,
                'disableDrag',
                callback
            );
        }

        _.invoke(
            this.coordinates,
            'lockAnswer',
            callback
        );

    }

    unlockAnswer() {
        if (this.isDragText) {
            _.invoke(
                this.draggables,
                'enableDrag',
                this.taskView.openScoreFirstMessage
            );
        }

        _.invoke(
            this.coordinates,
            'unlockAnswer',
            this.taskView.openScoreFirstMessage
        );
    }

    /**
         * saveAnswer
         *
         * This function loops through all the coordinates and returns an array of answer labels.
         * Each answer label will be saved through the task view.
         */
    saveAnswer() {

        // Loop through the array of coordinates
        var answers = _.map(this.coordinates, (coordinate) => {

            // From each coordinate save the key and label
            return {
                key: coordinate.key,
                label: coordinate.label
            };
        });

        // Call the saveResponse function in Task.js
        this.taskView.saveResponse(answers);

    }

    /**
         * getCorrectAnswer
         *
         * This function sets the right answers in the students answers modal.
         *
         * @return {string} - String of answers
         */
    getCorrectAnswer() {
        var answerString = '';

        // Loop through all answers in the array
        _.each(this.model.get('task_info_json').answers, (answer, key) => {

            // If there is an answer label set it, if not make it 3 dots
            var answerLabel = answer.label ? answer.label : '...';

            // Set the index + 1
            key = key + 1;

            // Add the a string to the full answer string
            answerString += '<span class=\'is-boxed\'>' + key + ': ' + answerLabel + '</span>';
        });
        return answerString;
    }

    /**
         * getStudentAnswer
         *
         * This function shows the answers given by the student and determines if they were
         * correct or incorrect.
         *
         * @param  {Object} responseModel the model that holds the responses per student
         * @return {string} A string of answers given by student and checked for correctness
         */
    getStudentAnswer(responseModel) {

        var answerString = '';

        // Simplify references to responses and correct answers
        var responses = responseModel.get('json_answer');

        // If the DB has a student response
        if (responses) {

            var correctAnswers = this.model.get('task_info_json').answers;

            if (

            // When the answer type is fillText
                this.model.get('task_info_json').answerType === 'fillText' &&

                    // and the grading mode is not case sensitive
                    !this.model.get('task_info_json').options.caseSensitive
            ) {

                // Call function that creates a lowcased and trimmed version of correct answers and responses
                correctAnswers = this.prepareNonCaseSensitiveAnswers(correctAnswers);
                responses = this.prepareNonCaseSensitiveAnswers(responses);
            }

            // Loop through all the answer options
            _.each(correctAnswers, (answer, index) => {

                var givenAnswer = _.find(responses, (response) => {

                    // Dont strict check here because both keys can be either a string or an integer.
                    // TODO fix setting of keys in the backend.
                    // Linear keys (1, 2, 3) are casted to integers automatically.
                    return answer.key == response.key;
                });

                // Turn any empty string into undefined (for better matching correctness of answer)
                var givenAnswerText = (
                    (
                    // If the given answer could not be found
                        !givenAnswer ||

                            // Or the answer was not filled
                            givenAnswer.label === ''

                    // Set the label as undefined
                    ) ? undefined :

                        // Or use the filled answer
                        givenAnswer.label
                );

                // Turn any empty string into undefined (for better matching correctness of answer)
                answer.label = (
                    (answer.label === '') ? undefined : answer.label
                );
                var correctVariants = [answer.label];

                if (answer.variants) {
                    _.each(answer.variants, (variant) => {
                        var variantText = (variant.text === '') ? undefined : variant.text;
                        correctVariants.push(variantText);
                    });
                }

                // Check if the label of the given answer equals that of the correct answer
                if (correctVariants.indexOf(givenAnswerText) > -1) {

                    // If so, it's correct.
                    // Add a correct response box and include the label or an ellipsis
                    // when both labels should be undefined
                    answerString +=
                            '<span class="is-boxed is-correct">' + (index + 1) + ': ' +
                            (givenAnswerText || '…') + '</span>';

                } else if (

                // If the labels do not match
                    givenAnswerText !== answer.label
                ) {
                    // Add an incorrect answer box that displays the incorrect response label or
                    // an ellipsis if the student didn't fill this answer option
                    answerString +=
                            '<span class="is-boxed is-incorrect">' +
                            (index + 1) + ': ' + (givenAnswerText || '…') + '</span>';
                }

            });
        }

        // if answer is given, return answer
        return answerString;
    }

    /**
         * prepareNonCaseSensitiveAnswers
         *
         * This function trims and turns labels of answer items to lower cased version.
         * This is needed to check the responses when the grading mode is not case sensitive.
         *
         * @param  {Array} list - List of answer items
         * @return {Array} - returns the manipulated list of answer items
         */
    prepareNonCaseSensitiveAnswers(list) {
        // Go through list of answer items
        return _.map(list, (item) => {
            if (_.size(item.variants) > 0) {
                _.each(item.variants, (variant) => {
                    if (typeof variant.text === 'string') {
                        variant.text = variant.text.trim().toLowerCase();
                    }
                });
            }

            // Check if answer item has a label
            if (item.label && typeof item.label === 'string') {

                // Trim and lowercase it
                item.label = item.label.trim().toLowerCase();
            }
            return item;
        });
    }
}
