/**
 * module: __
 *
 * This is the __ handlebars helper module. It will translate a string
 * given in a handlebars template to the right language.
 *
 * @param  {string} text        This is text that should be translated
 * @return {string}             This is the translated string
 */
module.exports = function(text) {

    // Remove first item from arguments (is: text), and the last (containing the options)
    // Use array.prototype.slice.call as a hack since we can't use slice on arguments. This
    // will create a valid copy of the arguments.
    // See: http://stackoverflow.com/a/9510206
    var replacements = Array.prototype.slice.call(arguments, 1).slice(0, -1);

    // Create the translated string
    var translatedString = window.i18n.gettext(text);

    // Loop trough each replacement (if any)
    _.each(replacements, function(replacement, index) {

        // Create a regex to find the replacement, for example: {0}
        var regex = new RegExp('\\{' + index + '\\}', 'g');

        // Replace the string within translated string
        translatedString = translatedString.replace(regex, replacement);
    });

    // Return the (modified) translated string
    return translatedString;
};
