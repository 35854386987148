var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label class=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"Styles") : depths[1])) != null ? lookupProperty(stack1,"summary-nav-item") : stack1), depth0))
    + "\">\n                    <input type=\"radio\" class=\"js-gap\" data-gap-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" name=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"cid") : depths[1]), depth0))
    + "\"/>\n                    <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"answer") : depth0), depth0))
    + "</div>\n                </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-answer-summary "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"stat-block") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"summary") : stack1), depth0))
    + "\">\n    <strong>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Latest 20 wrong answers",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":44}}}))
    + "</strong>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"task-31") : stack1), depth0))
    + "\">\n        <nav class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"summary-list") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"gaps") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "        </nav>\n        <section></section>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});