import Styles from './SelectCategory.scss';

import Template from './SelectCategory.hbs';
import Button from 'views/components/button/Button';
import SupportModalMessageForm from 'views/components/modals/support/supportMessage/SupportMessage';

export default class SelectSupportCategory extends BaseView {

    // Add events for this view
    get events() {
        return {
            'click .js-support-button': 'onChoseCategory'
        }
    }

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Object as defined by parent
     */
    initialize(options) {

        // Make options accessible within this
        _.extend(this, options);

        // set link to support page based on language
        let linkToSupportPage;
        if (LANGUAGE === 'nl_NL') {
            linkToSupportPage = Backbone.Model.user.get('is_teacher') ?
                'https://support.learnbeat.nl' :
                'https://support.learnbeat.nl/category/DkPF4zCLWR-ik-ben-leerling';
        }

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles,
            linkToSupportPage,

            // Set the is teacher to the is_teacher property
            isTeacher: Backbone.Model.user.get('is_teacher')
        }));

        if (linkToSupportPage) {
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Check our support articles'),
                    iconRight: 'arrow-right',
                    callback: () => {
                        window.open(
                            linkToSupportPage,
                            '_blank'
                        )
                    }
                }), '.js-support-page'
            )
        }

    }

    static getSupportModalMessageFormOptions(category) {
        const options = {
            sidepanel: {
                title: window.i18n.gettext('Support'),
                content:
                    '<p>' + window.i18n.gettext(
                        'Have you ran into trouble using Learnbeat? ' +
                        'Do you have ideas how to improve the learning environment?'
                    ) + '</p><p>' +
                    window.i18n.gettext('Support is looking forward to your message!') +
                    '</p>'
            }
        }
        switch (category) {
            case 'problem':
                return {
                    ...options,
                    title: window.i18n.gettext('I have a problem'),
                    supportData: {
                        messageType: category,
                        messageTypeLabel: window.i18n.gettext('problem')
                    }
                }
            case 'content-mistake':
                return {
                    ...options,
                    title: window.i18n.gettext('I see a content mistake'),
                    supportData: {
                        messageType: category,
                        messageTypeLabel: window.i18n.gettext('content-mistake')
                    }
                }
            case 'idea':
                return {
                    ...options,
                    title: window.i18n.gettext('I have an idea'),
                    supportData: {
                        messageType: category,
                        messageTypeLabel: window.i18n.gettext('idea')
                    }
                }
        }
    }

    onChoseCategory(e) {
        // Add the supportMessage view to the modal stack
        Backbone.View.Components.modal.add(
            SupportModalMessageForm,
            SelectSupportCategory.getSupportModalMessageFormOptions(e.currentTarget.dataset.type)
        )
    }
}
