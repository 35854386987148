<script>
    import QuickInput from '../QuickInput.svelte'
    import Icon from 'views/components/icon/Icon.svelte'

    // Optional initial value of the input.
    export let value = ''

    // Optional string to use as <label>.
    export let label = ''

    let quickInput

    function copyValue() {
        quickInput.inputElement.select()
        try {
            const copySuccess = document.execCommand('copy')
            if (copySuccess) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('The text has been copied to your clipboard.'),
                    'success'
                )
            } else {
                throw new Error('Could not copy to clipboard…')
            }
        } catch (e) {
            // Capture exception in sentry
            window.sentry.withScope(() => {
                window.sentry.captureException(e)
            })

            Backbone.View.layout.openStatus(
                window.i18n.gettext('We could not copy this text to your clipboard...')
            )
        }
    }
</script>

<QuickInput
    {value}
    isReadOnly="true"
    bind:this={quickInput}
>
    <button slot="special-actions" on:click={copyValue}><Icon name="copy"></Icon>{label}</button>
</QuickInput>

<style lang="scss">
    @import 'src/styling/globals.scss';

    button {
        @include button;
        @include button-hover-state;
        @include button-active-state;

        flex-shrink: 0;
        border-radius: 0;

        gap: 8px;

        &:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
</style>
