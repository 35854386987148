var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-words-list-item\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"list-item") : stack1), depth0))
    + " js-list-item-replaceable\">\n        <div class=\"js-left-item "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"list-item__section") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"list-item__section--left") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"leftItemContent") : depth0), depth0))
    + "</div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"list-item__section") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"list-item__section--right") : stack1), depth0))
    + "\">\n            <div class=\"js-right-item\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rightItemContent") : depth0), depth0))
    + "</div>\n            <div class=\"no-print "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"buttons-container") : stack1), depth0))
    + "\">\n                <button>"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"pencil",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":44}}})) != null ? stack1 : "")
    + "</button>\n                <button class=\"js-delete-icon "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"delete-icon") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"trash",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":8,"column":70},"end":{"line":8,"column":89}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});