import Styles from './Clock.scss';

import Template from './Clock.hbs';
export default class Clock extends BaseView {
    initialize() {
        this.setElement(Template({
            Styles
        }))

        this.updateClock()
    }

    updateClock() {
        const time = new dayjs().format('HH:mm')
        this.$('.js-clock-timer').text(time)
        setTimeout(() => { this.updateClock() }, 1000)
    }
}
