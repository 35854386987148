var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"variant") : stack1), depth0))
    + "\">\n    <div>\n        <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</strong><br>\n        "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"€{0} incl. {1}% VAT",(depth0 != null ? lookupProperty(depth0,"price") : depth0),(depth0 != null ? lookupProperty(depth0,"taxPercentage") : depth0),{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":56}}}))
    + "<br>\n        "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"valid till {0}",(depth0 != null ? lookupProperty(depth0,"expirationDate") : depth0),{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":46}}}))
    + "\n    </div>\n</div>";
},"useData":true});