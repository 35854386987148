import Styles from './Item.scss';

import Template from './Item.hbs';
import Util from 'util/util';

var SidebarAboutWorkOnItemView = BaseView.extend({

    initialize(options) {

        this.$el.html(Template({
            Styles,
            letter: Util.numToLetter(options.num - 1),
            subject: this.model.get('Subject').subject,
            questionType: this.model.get('QuestionType').question_type,
            framework: this.model.get('Framework').name
        }));
    }
});

export default SidebarAboutWorkOnItemView;
