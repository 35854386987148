import Styles from './AddGroupCard.scss';

import Template from './AddGroupCard.hbs';
import Button from 'views/components/button/Button'

export default BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     */
    initialize() {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        this.addChildView(new Button({
            label: window.i18n.gettext('Add your own group now'),
            icon: 'plus-circle',
            callback: this.onClickAddNewGroupButton,
        }), '.js-add-new-group-button');

    },

    /**
         * onClickAddNewGroupButton
         *
         * This function will be called when the user clicks on the add a new
         * group button
         *
         */
    onClickAddNewGroupButton() {

        // Navigate to the create page
        Backbone.history.navigate('groups/pick_course', {
            trigger: true
        });
    },

});
