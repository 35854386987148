import Styles from './UsernameAndPassword.scss';

import Template from './UsernameAndPassword.hbs';
import QuickInput from 'views/components/quickInput/QuickInput'
import Button from 'views/components/button/Button'
import LostPasswordModal from 'views/components/modals/lostPassword/inputModal/LostPasswordInputModal'
import PasswordInput from 'views/components/quickInput/passwordInput/PasswordInput'

export default BaseView.extend({

    events: {
        'click .js-forgot-password': 'onClickForgotPassword',
    },

    initialize(options) {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            hidePasswordHelp: options.hidePasswordHelp
        }));

        // bind it to this view.
        this.loginCallback = options.callback;

        // Create and append input field for username.
        this.nameInput = this.addChildView(new QuickInput({
            placeholder: window.i18n.gettext('Email address or username'),
            defaultValue: options.previousUsername,
            noMargin: true,
            type: 'email',
            autoFocus: !options.previousUsername,
            noAutoCapitalize: true
        }), '.js-username');
        // Auto complete username to help password managers.
        this.nameInput.inputElement.attr({
            autocomplete: 'email',
            name: 'username',
            id: 'username'
        });

        // Disable name input field if option is given.
        if (options.isNameInputDisabled) {
            this.nameInput.disable();
        }

        // Create and append new password input field.
        this.passwordInput = this.addChildView(new PasswordInput({
            placeholder: window.i18n.gettext('Password'),
            passwordCallback: () => {
                // Use an empty callback so the input component knows it is a password field
            },
            autoFocus: !!options.previousUsername
        }), '.js-password');

        // Add login button
        this.submitButtonView = this.addChildView(new Button({
            callback: (e) => this.onSubmitForm(e),
            label: window.i18n.gettext('Login'),
            size: 'medium',
            type: 'submit',
        }), '.js-login-btn');

    },

    /**
     * onSubmitForm
     *
     * When this function is triggered, it will check if username and password is filled.
     * If so, it will call the passed callback from the options.
     *
     * @param {jQuery.event} e submit event
     */
    onSubmitForm(e) {
        // Prevent the default form action
        e?.preventDefault();

        // Get username and password input value.
        const name = this.nameInput.getInput();
        const password = this.passwordInput.getInput();

        // Check for empty fields
        if (!name || !password) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Please fill in all fields to login'),
                'warning'
            );
            return;
        }

        // Start spinner as soon as user clicks submit button. Only stop
        // spinner if login fails and users stays on page.
        if (this.submitButtonView) {
            this.submitButtonView.disable(true)
        }

        // Call login callback and pass username, password and submitButtonView
        this.loginCallback(
            name,
            password,
            this.submitButtonView,
        );
    },

    /**
     * onClickForgotPassword
     *
     * Open a modal with a LostPasswordModal view.
     */
    onClickForgotPassword() {
        Backbone.View.Components.modal.open(LostPasswordModal, {
            title: window.i18n.gettext('Forgot password'),
            username: this.nameInput.getInput()
        });
    }

});
