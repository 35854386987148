var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"navigationbar-outer") : stack1), depth0))
    + " no-print\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"navigationbar") : stack1), depth0))
    + " js-bar-container\">\n\n\n        <button class=\"js-navigation-arrow js-navigation-arrow-top "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"navigation-arrow") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-up",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":96},"end":{"line":7,"column":118}}})) != null ? stack1 : "")
    + "</button>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"navigationbar__items") : stack1), depth0))
    + " js-items\"></div>\n\n        <button class=\"js-navigation-arrow js-navigation-arrow-bottom "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"navigation-arrow") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":12,"column":99},"end":{"line":12,"column":123}}})) != null ? stack1 : "")
    + "</button>\n    </div>\n</div>\n";
},"useData":true});