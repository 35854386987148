import Styles from './ExamPoints.scss';

import Template from './ExamPoints.hbs';
import NumberInput from 'views/components/quickInput/numberInput/NumberInput'

export default class ExamPoints extends BaseView {

    get isEditable() {
        return ACL.checkRole(ACL.roles.TEACHER) && !this.isActivityPreview
    }

    initialize({
        response,
        activityModel,
        isPreview,
        isExportingExamAnswers,
        hideLabel
    }) {

        _.bindAll(
            this,
            'editPoints'
        );

        this.activityModel = activityModel;
        this.editMode = false;
        this.isActivityPreview = isPreview

        this.attainablePoints = this.activityModel.getAttainableExamPoints(this.model.id);
        var pointsReceived = 0;
        var isGraded = true;
        if (!this.isEditable) {
            // Calculate how many points the student got, based on the score
            pointsReceived = this.activityModel.getExamPointsFromScore(
                this.model.id,
                response.get('score')
            );

            // If the answer has no score
            if (pointsReceived === -1) {

                // If the student did not answer the question, show 0 points
                if (response.get('json_answer') === undefined) {
                    pointsReceived = 0
                    isGraded = true

                    // If the student did answer, but was not graded, show a question mark
                } else {
                    isGraded = false
                    pointsReceived = '?'
                }
            }

        }

        this.setElement(Template({
            Styles,
            showReceivedPoints: isExportingExamAnswers ||
                (this.activityModel.get('show_answers') && !isPreview && !this.isEditable),
            pointsReceived: pointsReceived.toLocaleString(),
            isGraded,
            attainablePoints: this.attainablePoints.toLocaleString(),
            isEditable: this.isEditable
        }));

        if (this.isEditable) {
            this.$('.js-points-static').on('click', this.editPoints)
        }

        if (hideLabel) {
            this.$('.js-unit').remove();
        } else {
            this.setUnitPluralisation();
        }

    }

    editPoints(e) {
        this.stopAllEvents(e);
        if (!this.isEditable) {
            return
        }
        this.editMode = !this.editMode;
        if (this.editMode) {
            this.$('.js-points-static').hide();

            this.examPointInput = this.addChildView(new NumberInput({
                defaultValue: this.attainablePoints,
                step: 0.5,
                autoFocus: true,
                useCustomNumberInputButtons: false,
            }), '.js-points-edit')
            this.examPointInput.inputElement.on('keyup', this.keyupPointsInput)
            this.examPointInput.inputElement.one('blur', this.editPoints)
        } else if (this.examPointInput) {
            this.activityModel.setAttainableExamPoints(this.model.id, this.examPointInput.getInput());
            this.attainablePoints = this.activityModel.getAttainableExamPoints(this.model.id);
            this.examPointInput.destroy()
            this.$('.js-points-static').show();
            this.$('.js-attainable-exam-points').text(this.attainablePoints.toLocaleString());
            this.setUnitPluralisation();
        }
    }

    keyupPointsInput(e) {
        if (e.keyCode === 13 || e.key === 'Enter') {
            $(e.target).trigger('blur');
            $(e.target).off('keyup', this.keyupPointsInput)
        }
    }

    setUnitPluralisation() {
        this.$('.js-unit').text(window.i18n.ngettext('point', 'points', this.attainablePoints));
    }

}
