import Styles from './Template2ResponseItem.scss';

import Template from './Template2ResponseItem.hbs';
import Util from 'util/util';

export default BaseView.extend({

    // Max height of a card is 117
    maxCardHeight: 117,

    // Events for this view
    events: {
        click: 'onClickCard',
        'click .js-fold-icon': 'onClickFolded'
    },

    initialize(options) {

        _.bindAll(
            this,
            'checkIfCardShouldFold',
            'flipCard'
        );

        var responseString = options.modelAnswer ?
            options.response :
            JSON.parse(options.response.json_answer)

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            response: Util.renderContentSafely(responseString),
            backSide: options.modelAnswer ? options.backSide : options.group.students.get(options.response.user_id).get('first_name'),
            hasIcon: options.modelAnswer
        }));

        // Wait for card to load, to check if it should fold
        _.defer(this.checkIfCardShouldFold);

        // Start with showing the answer
        this.showAnswer = true;
    },

    /**
     * checkIfCardShouldFold
     *
     * This function checks if the card is greater than 117 px.
     * If so, it should be a folded card.
     *
     */
    checkIfCardShouldFold() {

        // If height is bigger than 117 px
        if (this.el.clientHeight > this.maxCardHeight) {

            // Card is foldable
            this.$el.addClass(Styles['card--foldable']);

            // Add folded class
            this.$el.addClass(Styles['card--folded']);

            // Set is folded boolean to true
            this.isFolded = true;

            // Show folded layer
            this.$('.js-folded-layer').css('display', 'flex');
        }
    },

    /**
     * onClickCard
     *
     * This function handles clicks on the card and directs them to the flipcard function
     *
     * @param {Event} e    click event
     */
    onClickCard(e) {

        // Check if user didn't click on fold icon
        if ($(_.first(e.currentTarget)).hasClass('js-fold-icon')) {

            // Toggle fold card function
            this.toggleFolded();

        } else {
            // Call flipCard function
            this.flipCard();

            // Flip the showAnswer boolean
            this.showAnswer = !this.showAnswer;
        }

    },

    /**
     * flipCard
     *
     * This function flips the response item cards visually.
     *
     */
    flipCard() {

        // If show answer is true
        if (this.showAnswer) {

            // Show the name card
            this.$('.js-name-card').show();

            // Hide the answer card
            this.$('.js-answer-card').hide();

            // Make the card dark
            this.$el.addClass(Styles['card--dark']);
        } else {

            // Hide the name card
            this.$('.js-name-card').hide();

            // Show the answer card
            this.$('.js-answer-card').show();

            // Remove the dark class
            this.$el.removeClass(Styles['card--dark']);
        }

    },

    /**
     * onClickFolded
     *
     * This function is triggered when a user clicks on the fold icon.
     * It will then call the toggle fold function.
     *
     * @param  {Object} e - click event object
     */
    onClickFolded(e) {

        // Make sure no other events are proceeded
        this.stopAllEvents(e);

        // Call function that handles the folding of the card
        this.toggleFolded();
    },

    /**
     * toggleFolded
     *
     * This function handles the folding of a card that's foldable.
     *
     */
    toggleFolded() {

        if (this.isFolded) {
            this.$el.removeClass(Styles['card--folded']);

        } else {
            this.$el.addClass(Styles['card--folded']);
        }

        this.isFolded = !this.isFolded;
    }

});
