import Styles from './Template2ResponseOverview.scss';

import Template from './Template2ResponseOverview.hbs';
import Util from 'util/util';
import OptionGroup from 'views/components/optionGroup/OptionGroup';
import Template2ResponseItem from 'views/pages/activities/show/types/presentation/parts/responseOverview/template2/responseItem/Template2ResponseItem';

export default BaseView.extend({

    initialize({ presentation, taskModel }) {

        _.bindAll(
            this,
            'onLoadResponses',
            'onChangePickedOption'
        );

        this.presentation = presentation;

        // Empty array that will hold the responseItem views
        this.responseItems = [];

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            introduction: Util.renderContentSafely(taskModel.get('introduction'))
        }));

        if (taskModel.get('has_model_answer')) {
            this.modelAnswer = this.addChildView(new Template2ResponseItem({
                response: taskModel.get('task_info_json').answer,
                backSide: window.i18n.gettext('Correct answer'),
                modelAnswer: true
            }), '.js-answer-container')

            this.responseItems.push(this.modelAnswer);
        }

        // Create new model
        var responseModel = new Backbone.Model();

        // Do a backend call
        responseModel.fetch({

            // Pass the activity id and task id as url params
            url: '/activities/get_presentation_answers/' + this.model.id + '/' + taskModel.id + '.json',
            success: this.onLoadResponses
        });

        this.addChildView(

            // Create an option group
            new OptionGroup({

                // with the following choices/tabs
                choices: [{
                    label: window.i18n.gettext('Answers'),
                    type: 'answers'
                }, {
                    label: window.i18n.gettext('Names'),
                    type: 'names'
                }],

                callback: this.onChangePickedOption
            }), '.js-tabs'
        );

        // Default is to show the answers
        this.showAnswers = true;

    },

    /**
         * onLoadResponses
         *
         * When the responses are succesfully fetched from the backend,
         * they will be constructed as response items.
         *
         * @param  {Array} responses - array with response objects sent from backend
         */
    onLoadResponses(responses) {
        // Check total of response received for this task
        var responseTotal = _.size(responses.attributes);

        var responseItem;

        if (responseTotal > 0) {

            // Loop through the response array
            _.each(responses.attributes, (response) => {

                // Construct a new response item per iteration
                responseItem = this.addChildView(
                    new Template2ResponseItem({

                        // Send response object
                        response,

                        // And total of responses
                        responseTotal,

                        group: this.presentation.model.getGroupModel()

                    }), '.js-answer-container'
                );

                // Add the response item view to the array
                this.responseItems.push(responseItem);

            })

        } else {
            this.$('.js-no-responses-text').show();
        }

    },

    /**
         * onChangePickedOption
         *
         * This function is a callback triggered when user clicks on one of the group options.
         * It will loop through the response items and call the flipCard function.
         *
         */
    onChangePickedOption() {

        // Loop through response item views
        _.each(this.responseItems, (item) => {

            // Make sure item view has the same state as
            // the showAnswers boolean of this view
            item.showAnswer = this.showAnswers;

            // Call the flipCard function on the item view
            item.flipCard();

            // Switch the state to be the opposite of
            // this view's showAnswers boolean
            item.showAnswer = !this.showAnswers;

        })

        // Switch the state of this view's show answers boolean
        this.showAnswers = !this.showAnswers;
    },

});
