var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<article class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"list-block") : stack1), depth0))
    + "\">\n\n    <div class=\"js-list-header "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\">\n        <div class=\"js-headers "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"columns") : stack1), depth0))
    + "\">\n            <div class=\"js-column-header-left\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"leftColumnName") || (depth0 != null ? lookupProperty(depth0,"leftColumnName") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"leftColumnName","hash":{},"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":65}}}) : helper)))
    + "</div>\n            <div class=\"js-column-header-right "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"right") : stack1), depth0))
    + "\">\n                <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"rightColumnName") || (depth0 != null ? lookupProperty(depth0,"rightColumnName") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"rightColumnName","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":43}}}) : helper)))
    + "</span>\n                <div class=\"no-print js-edit-columns\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"js-list-body "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0))
    + "\"></div>\n\n    <div class=\"js-no-list-items "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"no-list-items") : stack1), depth0))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"noItemsText") || (depth0 != null ? lookupProperty(depth0,"noItemsText") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"noItemsText","hash":{},"data":data,"loc":{"start":{"line":15,"column":59},"end":{"line":15,"column":76}}}) : helper)))
    + "</div>\n\n</article>\n";
},"useData":true});