import App from './App';
import Jed from 'jed';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import isoWeek from 'dayjs/plugin/isoWeek'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import localeData from 'dayjs/plugin/localeData'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import 'dayjs/locale/nl';
import UAParser from 'ua-parser-js';
import {
    BrowserClient,
    Breadcrumbs,
    Dedupe,
    defaultStackParser,
    FunctionToString,
    getCurrentHub,
    GlobalHandlers,
    HttpContext,
    InboundFilters,
    makeFetchTransport,
    makeBrowserOfflineTransport,
    LinkedErrors,
    TryCatch
} from '@sentry/browser';
import Matomo from 'matomo';
import RestoreScrollPosition from 'util/RestoreScrollPosition';

if (module?.hot) {
    module.hot.accept('./App', () => {

        // TODO: Create method for rerendering a BaseView view
        // TODO: Create method for calling method above on all childviews
        // const { layoutView } = window.app;
        // layoutView.rerenderChildviews();

        return true;
    });
}

// Define base path of all JavaScript assets on the current resourceLocation.
// More info: https://webpack.js.org/guides/public-path/
// Examples:
// - https://static.learnbeat.nl/webapp/5d6e1bffdfefa7eab1b78d1bf64eaab5c33d5ec0/js/learnbeat.nl.js
// - http://learnbeat.local/dist/js/0.learnbeat.nl.js
__webpack_public_path__ = `${window.resourceLocation}/`;

/**
 * Add method to Backbone.View to catch errors that may be caused by an
 * out of date content tree. If one attempts to get a model that does not exist, fetch a new content tree, then
 * continue where you left off.
 *
 * @param {Function} callback
 * Function to attempt. Gets called again after content has been fetched and updated.
 * Can also be passed as a string as long this is the name of a function of the context param object.
 * @param {Object|undefined} context
 * Backbone.Model, Backbone.Collection, Backbone.View or other kind of object that is the scope for callback.
 * @param  {...any} callbackArguments
 * Optional arguments that need to be passed with the callback function
 */
Backbone.View.prototype.attempt = function(callback, context, ...callbackArguments) {
    try {
        callback.call(context, ...callbackArguments)
    } catch (error) {
        console.warn(error)
        // In case of a TypeError (eg. "get is not a function" or "cannot read property 'get' of undefined"), fetch
        // and update global collections and re-attempt to execute the callback.
        if (error instanceof TypeError) {
            Backbone.Model.user.updateCollections().then(() => {
                callback.call(context, ...callbackArguments)
            })
        }
    }
}

// Expose underscore and jQuery to console
window._ = _;
// Both of these variables needs to be globally available in order
// for MathQuill to load correctly for Learnbeat and the Algebrakit widget.
window.jQuery = window.$ = $

// Load language PO file and initialize Jed
const languagePo = require('../resources/locale/' + LANGUAGE + '/default.po')
window.i18n = new Jed(languagePo)

// In the Dutch version of the MBO market (id 4), replace 'leerling' with 'student'
if (LANGUAGE === 'nl_NL') {
    const dcnpgettext = window.i18n.dcnpgettext
    window.i18n.dcnpgettext = function() {
        const translation = dcnpgettext.apply(this, arguments)
        if (Backbone.Model.user?.get('market_id') !== 4) {
            return translation
        }

        const replacements = {
            leerling: 'student',
            Leerling: 'Student'
        }
        const regExp = new RegExp(Object.keys(replacements).join('|'), 'g')
        return translation.replace(regExp, (match) => {
            return replacements[match]
        })
    }
}

var uaString = (
    (window && window.navigator && window.navigator.userAgent) ? window.navigator.userAgent : ''
);
// Create a new user agent parser, using the create UA string
window.uaparser = new UAParser(uaString);

// Enable animations from being interrupted when another animation starts on the same target.
TweenMax.defaults({
    overwrite: 'auto'
})

Backbone.Model.app_version = new Backbone.Model(window.app_version);

// set up the dayjs library for formatting dates, times, durations, etc
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(isoWeek)
dayjs.extend(isLeapYear)
dayjs.extend(isoWeeksInYear)
dayjs.extend(localeData)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(minMax)
if (window.app_version.language === 'nl') {
    dayjs.locale('nl')
}

// Only use Sentry in production mode
if (PRODUCTION) {

    let SentryEnvironment
    if (DOMAIN.isLearnBeta) {
        SentryEnvironment = 'Learnbeta'
    } else if (DOMAIN.isBeta) {
        SentryEnvironment = 'Beta'
    } else {
        SentryEnvironment = 'Live'
    }

    // Load the sentry config
    const client = new BrowserClient({
        dsn: 'https://2e291dbb79e55f728f4ba9f3743cee7a@monitoring.learnbeat.tools/2',
        environment: SentryEnvironment,
        release: VERSIONHASH,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],

        // Increase the normalize walk depth to 5 instead of the default 3
        // This will allow us to log more complex objects.
        normalizeDepth: 8,

        // Disable sessions to prevent (potentially) overloading our monitoring server
        autoSessionTracking: false,

        // When user is offline, store events and send them when connection is restored
        transport: makeBrowserOfflineTransport(makeFetchTransport),
        transportOptions: {

            // If there already is more than 1MB in localstorage, do not add this event
            // Reserve enough space to store responses
            shouldStore: () => {
                const localStorageSize = new Blob(Object.values(localStorage || {})).size
                return localStorageSize < 1024 * 1024
            }
        },
        stackParser: defaultStackParser,

        // Only the integrations listed here will be used
        integrations: [
            new Breadcrumbs(),
            new Dedupe(),
            new FunctionToString(),
            new GlobalHandlers(),
            new HttpContext(),
            new InboundFilters(),
            new LinkedErrors(),
            new TryCatch()
        ],
    })
    getCurrentHub().bindClient(client)
    window.sentry = getCurrentHub()

} else {
    window.sentry = {
        captureMessage(message, data) {
            console.log(message, data);
        },
        captureException(exception, data) {
            console.log(exception, data);
        },
        configureScope() {},
        withScope() {},
    };
}

// Only use Matomo in production mode
if (PRODUCTION) {
    if (DOMAIN.isLive) {
        window.statsTracker = Matomo.getTracker('https://stats.learnbeat.nl/matomo.php', '1');
    } else {
        window.statsTracker = Matomo.getTracker('https://stats.learnbeat.nl/matomo.php', '3');
    }

    window.statsTracker.enableLinkTracking(true);

    // track (single) pageview,
    // all other navigation is tracked using events instead of pageviews
    window.statsTracker.trackPageView();
} else {
    window.statsTracker = {
        setCustomUrl() { },
        trackPageView() { },
        trackEvent() {
            // console.log('Tracking event', arguments);
        },
        setUserId() { },
        setCustomDimension() { }
    };
}

// Object for tracking temporary values useful for stats tracking.
window.statsTrackerValues = {};

// While all the browsers we support (see browserslist in package.json) do support ServiceWorkers,
// navigator.serviceWorker is not present in certain contexts:
// - When Firefox is used in private mode, when history is disabled or when cookies are cleared when Firefox closes
// - Google Search App on iOS
// - In Chrome, registration fails when the "Block all cookies (not recommended)" option is enabled.
// - In Safari, if the domain is not HTTPS
// https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers
if ('serviceWorker' in navigator) {
    // Normally, service workers can only work on HTTPS or localhost domains.
    // To enable service workers in your local dev environment, enable the following setting:
    // - Chrome: Go to chrome://flags and enable "Insecure origins treated as secure" for "http://learnbeat.local"
    // - Firefox: Go to about:config and enable "devtools.serviceWorkers.testing.enabled"
    const registerServiceWorker = async (serviceWorkerPath) => {
        try {
            // Worker scripts are located in the /public dir in the backend repository.
            const registration = await navigator.serviceWorker.register(
                serviceWorkerPath, {scope: '/'}
            )
            if (registration.installing) {
                console.log('Service worker installing')
            } else if (registration.waiting) {
                console.log('Service worker installed')
            } else if (registration.active) {
                console.log('Service worker active')
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`)
        }
    }
    registerServiceWorker('/ImageServiceWorker.js')

    navigator.serviceWorker.addEventListener('message', (event) => {
        // Messages from the ImageServiceWorker
        if (event.data.type === 'setImagePlaceholder') {
            // When service worker sends a 'setImagePlaceholder' message, find <img> tags with the same URL as
            // the request that hasn't yet already loaded and give it the .image-loading class until the image
            // has been decoded.
            const urlWithoutOrigin = event.data.url.substring(event.origin.length)
            const imageElements = document.querySelectorAll(
                `img[src="${event.data.url}"],img[src="${urlWithoutOrigin}"]`
            )
            for (const element of imageElements) {
                if (element.complete) {
                    continue
                }
                element.classList.add('img-loading')
                element.addEventListener('load', () => {
                    element.classList.remove('img-loading')
                }, {once: true})
            }
        }
    })
}

/**
 * -> Don't cache AJAX calls because iOS webapps might not always contain cookies in their requests
 * For context: https://forums.developer.apple.com/thread/89050
 *
 * -> When using Backbone.Collection.fetch() with a GET request that has query params:
 * make sure the data is serialized correctly (url-safe &key=value pairs). See:
 *     https://stackoverflow.com/questions/6659283/backbone-js-fetch-with-parameters/6659501
 * and the documentation of $.param() :
 *     https://api.jquery.com/jquery.param/
 *
 */
$.ajaxSetup({
    // Match global settings with the AJAX implementation used by Backbone
    // (https://backbonejs.org/docs/backbone.html#section-191) to ensure consistent behaviour when encoding and
    // decoding request payload and response data.
    contentType: 'application/json',
    dataType: 'json',
    beforeSend() {

        /**
         * Stringify data, unless it is of type FormData
         */

        if (
            this.data instanceof Object &&
            !(this.data instanceof FormData)
        ) {
            this.data = JSON.stringify(this.data)
        }

        return true
    },
    processData: false,

    // Don't cache AJAX calls because iOS webapps might not always contain cookies in their requests
    // For context: https://forums.developer.apple.com/thread/89050
    cache: false
});

$(document).ajaxError((event, jqXHR, ajaxSettings, thrownError) => {

    // Check if the request got blocked by Cloudflare. If so, do a hard reload.
    // The hard reload is required so Cloudflare can provide a challenge page to the user.
    // This challenge allows the user to provide prove of being a "human user" and not part of a DDOS attack.
    if (jqXHR?.getResponseHeader('cf-mitigated') === 'challenge') {
        window.sentry.captureMessage('Cloudflare block on AJAX request')
        window.location.reload()
        return
    }

    // Distill the error code from the jqXHR object
    const errorCode = ((jqXHR !== undefined && jqXHR.status !== undefined) ? jqXHR.status : undefined);
    const errorJSON = errorCode && jqXHR.responseJSON;

    switch (errorCode) {

        // When error code is 401 (Unauthorized). Typically the session has expired.
        case 401:

            // If the user is an accessor, it won't know the password of the user
            // therefore, do not show relogin modal but go to the accessor connections
            // page
            if (Backbone.Model?.accessor?.get('email')) {
                Backbone.history.navigate('accessor/connections', {trigger: true});
            } else if (Backbone.Model.user.id) {
                // if frontend has a user, show the login modal
                Backbone.View.layout.openReEnterPasswordModal();
                Backbone.Model.user.set('sessionLost', true);

                // Remove this in order to prevent any commands coming in from the frontend or socket,
                // until user logs in again.
                // Otherwise use will be send backand forth from users/home to activities/show/*
                Backbone.Model.user.unset('lockToURL');
                Backbone.Model.user.disconnectSocket();
            }
            break

        // Backend responds with 403 (Forbidden). Typically, the user has no access.
        case 403:

            Backbone.View.layout.openStatus(
                window.i18n.gettext('You do not have access to this page.')
            )

            // If LearnbeatUnauthorized is present, it means our backend refused access
            // Example: when trying to open an activity that is deleted
            if (errorJSON?.message === 'LearnbeatUnauthorized') {
                return
            }

            // Log to Sentry with URL parameters and IDs removed, so that Sentry can group these errors
            window.sentry.withScope(scope => {
                scope.setExtra('data', ajaxSettings.data)
                scope.setExtra('dataType', ajaxSettings.dataType)
                scope.setExtra('type', ajaxSettings.type)
                scope.setExtra('url', ajaxSettings.url)
                scope.setExtra('errorMessage', thrownError)
                scope.setExtra('errorJSON', errorJSON)
                window.sentry.captureException('403 error on ' + ajaxSettings.url.replace(/(?:\d+\.json)|(?:\?.+)/g, ''))
            })

            break

        // Backend responds with 428 (Precondition Required). Typically there is a identity verification required
        case 428:
            Backbone.View.layout.openAdminVerificationModal()
            break;

        // Backend responds with 429 (Too many requests). Typically, the rate limiter is reached.
        case 429:
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Too many requests, please try again later'),
                'error'
            )

            // Log to Sentry with URL parameters and IDs removed, so that Sentry can group these errors
            window.sentry.withScope(scope => {
                scope.setExtra('data', ajaxSettings.data)
                scope.setExtra('dataType', ajaxSettings.dataType)
                scope.setExtra('type', ajaxSettings.type)
                scope.setExtra('url', ajaxSettings.url)
                scope.setExtra('errorMessage', thrownError)
                scope.setExtra('errorJSON', errorJSON)
                window.sentry.captureException('429 error on ' + ajaxSettings.url.replace(/(?:\d+\.json)|(?:\?.+)/g, ''))
            })

            break;

        // When error code is 456 (Learnbeat error)
        case 456:
        // When error code has no match, execute legacy if/else logic
        default:

            // If the request is allowed to fail silenty (for example when polling), do not execute the error handling
            // The failSilently parameter can be set in the error callback of $.ajax() calls
            if (jqXHR.failSilently) {
                return
            }

            if (errorCode === 456 && errorJSON && errorJSON.type) {

                switch (errorJSON.type) {
                    case 'NOT_ALLOWED_FOR_DEMO':
                        Backbone.View.layout.openNotAvailableForDemoMessage();
                        break;
                    default:
                        Backbone.trigger('LearnbeatError', errorJSON);
                }

                break;
            } else if (thrownError === 'abort') {
                // console.log("aborting ajax call");
            } else if (thrownError === 'Method Not Allowed') {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('You are not allowed to view this page: ') +
                Backbone.history.location.pathname
                );
                Backbone.history.navigate('users/home', {trigger: true});
            } else if (/\bresponses\/(?:exam_)?add\b/.test(ajaxSettings.url)) {
                // URL is /responses/add.json or /responses/exam_add.json
                console.log('error is taken care of by responses error handler');

            } else if (/users\/send_me_a_message/.test(ajaxSettings.url)) {
                // Url is /users/send_me_a_message a test to see if the socket is working
                console.log('error is not taken care of but that is okay');
            } else if (/users\/(?:save_time|check_status)/.test(ajaxSettings.url)) {
                // URL is /users/save_time.json or check_status
                console.log('error is not taken care of, but that is okay');
            } else if (/\/upload/.test(ajaxSettings.url)) {
                console.log('error is taken care of by upload model');
            } else if (/\/activities\/get_adaptive_taskgroups/.test(ajaxSettings.url)) {
                console.log('error is taken care of by adaptive activity');
            } else if (/\bexam_reports\/add\//.test(ajaxSettings.url)) {
                // URL is /exam_reports/add/:activityID.json
                console.log('error is not taken care of, but that is okay');
            } else if (thrownError === 'timeout') {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('The server could not be reached. Check your internet connection.')
                );
            } else if (!window.navigator.onLine) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('You are offline. Check your internet connection.')
                );
            } else {
                // Remove URL parameters and IDs, otherwise errors cannot be grouped by Sentry.
                var generalizedRequestUrl = ajaxSettings.url.replace(/(?:\d+\.json)|(?:\?.+)/g, '');

                Backbone.View.layout.openStatus(
                    `${window.i18n.gettext('Something went wrong:')} ${generalizedRequestUrl}`
                );

                console.log(event, jqXHR, ajaxSettings, thrownError);

                if (errorCode !== 0) {

                    // Reduce level to info for infrastructure errors because they prevent a good frontend overview
                    // TODO: remove this when infrastructure errors are fixed
                    let level = 'error'
                    if ([502, 503, 504, 520].includes(parseInt(errorCode))) {
                        level = 'info'
                    }

                    window.sentry.withScope(scope => {
                        scope.setExtra('data', ajaxSettings.data);
                        scope.setExtra('dataType', ajaxSettings.dataType);
                        scope.setExtra('type', ajaxSettings.type);
                        scope.setExtra('url', ajaxSettings.url);
                        scope.setExtra('location', Backbone.history.location.pathname);
                        scope.setExtra('errorCode', errorCode);
                        scope.setExtra('errorMessage', thrownError);
                        scope.setExtra('errorJSON', errorJSON);
                        window.sentry.captureMessage(`Something went wrong: ${generalizedRequestUrl} code: ${errorCode}`, level);
                    });
                }

                if (Backbone.Model.user.get('lockToURL')) {
                    return;
                }

                if (ACL.isAllowed(ACL.resources.HOME, ACL.actions.VIEW)) {
                    Backbone.history.navigate('users/home', { trigger: true });
                    return;
                }

                const firstGroupId = Backbone.Collection?.groups?.at(0)?.id;

                if (ACL.isAllowed(ACL.resources.GROUPS, ACL.actions.VIEW) && firstGroupId) {
                    Backbone.history.navigate(`groups/show/${firstGroupId}`, { trigger: true });
                    return;
                }

                // TODO: Determine what now?
            }
    }

});

// Restore scroll position when the back button of the browser is used
new RestoreScrollPosition()

window.app = new App()
