import PortfolioItemsCollection from 'collections/PortfolioItemsCollection'
import TeacherModel from './TeacherModel';
export default class PortfolioModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'portfolio'
    }

    initialize() {
        this.on('change:items', this.onChangeItems)
    }

    /**
     * url
     *
     * This method will return the correct url for the model for when
     * there is an id and for when there is no ID.
     *
     * @return {string}     URL to use for this model
     */
    url() {
        if (this.id) {
            return '/portfolio/project/' + this.id + '.json'
        }
        return '/portfolio/project.json';
    }

    parse(response) {
        if (!response.hasOwnProperty('skills')) {
            response.skills = []
        }

        if (response.thread_users_info) {
            this.participants = new Backbone.Collection(
                response.thread_users_info,
                { model: TeacherModel }
            )
            delete response.thread_users_info
        }

        return response
    }

    /**
     * onChangeItems
     *
     * This method will be called when the items attribute changes.
     * It will convert the item to a collection of elements using
     * the elementscollection and store it in an attribute called
     * elements
     */
    onChangeItems() {
        this.set('elements', new PortfolioItemsCollection(this.get('items'), {comparator: 'sequence'}))
    }

    /**
     * share template with colleagues
     */
    async share() {
        const newState = this.get('shared') === 0 ? 1 : 0

        const response = await $.post(`/portfolio/update_share_status/${this.id}.json`, {
            projectShared: newState,
        })

        if (response?.status === 'success') {
            this.set('shared', newState)
        }

    }

    /**
     * @param {string} title name of copied template
     * @returns {string|number} if of new template
     */
    async copy(title) {
        const res = await $.post(`/portfolio/copy_portfolio_template/${this.id}.json`, {
            title,
        })
        return res.templateId
    }

    async changeShowLastEdit() {
        const newState = this.get('show_last_edit') ? 0 : 1
        const response = await $.post(`/portfolio/show_last_edit_per_item/${this.id}.json`, {
            showLastEdit: newState,
        })

        if (response?.status === 'success') {
            this.set('show_last_edit', newState)
        }
    }

    /**
     * fetch placed templates: get the students who have this template
     * for a portfolio project
     */
    async fetchPlacedTemplates() {
        const response = await $.get(`/portfolio/get_students_for_template/${this.id}.json`)

        // filter out students that are not in teacher's student collection
        const students = response.students
            .filter(student => Backbone.Collection.students.get(student.user_id))

        return {
            ...response,
            students,
        }
    }

}
