import Styles from './Button.scss';

import Template from './Button.hbs';
import Spinner from 'views/components/spinner/Spinner.svelte'
import ShapeList from 'util/ShapeLoader'

export default class Button extends BaseView {

    /**
     * @returns {boolean} if button is disabled
     */
    get disabled() {
        return this.buttonElement.disabled
    }

    set disabled(state) {
        this.buttonElement.disabled = state
    }

    /**
     * @returns {HTMLButtonElement} the button element
     */
    get buttonElement() {
        if (this.el instanceof HTMLDivElement) {
            return this.el.querySelector('button')
        }
        return this.el
    }

    get labelElement() {
        return this.el.querySelector('.js-button-label')
    }

    get label() {
        return this.labelElement?.textContent
    }

    set label(value) {
        this.labelElement.textContent = value
    }

    set icon(iconName) {
        this.el.querySelector('.js-button-icon').innerHTML = ShapeList[iconName] || ''
    }

    /**
     *
     * @param {Object} options
     * options object
     * @param {Function} options.callback
     * callback function
     * @param {String} options.externalStyle
     * Optional: apply CSS definition from outside of Button
     * @param {boolean} options.inline
     * if button is inline
     * @param {string} [options.label]
     * button label
     * @param {string} [options.title]
     * title attribute (shows on hover) to be used for buttons without a label
     * @param {string} [options.icon]
     * button icon: defaults to being placed on left side. use iconRight for right side
     * @param {string} [options.iconRight]
     * button icon shown on right side
     * @param {boolean} options.isDisabled
     * if button is disabled
     * @param {string} [options.theme]
     * button theme
     * @param {string} [options.type]
     * shows type of button, for example "submit" button
     *
     */
    initialize({
        callback,
        externalStyle,
        inline = false,
        label,
        title,
        icon,
        iconRight,
        isDisabled = false,
        size = 'small',
        theme = 'primary',
        type,
    }) {

        this.setElement(Template({
            Styles,
            icon,
            iconRight,
            inline,
            isDisabled,
            label,
            title,
            externalStyle,
            sizeClass: Styles[`button__size-${ size }`],
            themeClass: Styles[`button__theme-${ theme }`],
            type,
        }))

        if (callback) {
            this.callback = callback
            this.el.addEventListener('click', e => {
                // prevent triggering the callback on a disabled button
                // by clicking on an inner element
                if (this.disabled) {
                    return
                }
                this.callback(e)
            })
        }

        this.spinner = this.addSvelteChildView('.js-spinner', Spinner, {
            isInsideButton: true
        })
    }

    enable() {
        this.disabled = false

        if (this.isShowingSpinner) {
            this.toggleSpinner(false)
        }
    }

    /**
     * @param {boolean} startSpinner start spinner
     */
    disable(startSpinner = false) {
        this.disabled = true

        if (startSpinner) {
            this.toggleSpinner(true)
        }

        if (!startSpinner && this.isShowingSpinner) {
            this.toggleSpinner(false)
        }
    }

    /**
     * @param {boolean} newState force new state if param is present
     */
    toggle(newState) {
        const state = newState === undefined ? this.disabled : newState

        if (state) {
            this.enable()
        } else {
            this.disable()
        }
    }

    /**
     * @param {boolean} showSpinner show spinner if true, hide if false
     */
    toggleSpinner(showSpinner = false) {
        this.buttonElement.classList.toggle(Styles['button__show-spinner'], showSpinner)
        this.isShowingSpinner = showSpinner
    }

    /**
     * @param {string} newLabel new label for button
     */
    changeLabel(newLabel) {
        this.labelElement.textContent = newLabel
    }

}
