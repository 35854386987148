export default class AudioStream {
    /**
     * askForPermission
     *
     * This function will check if the browser of the user support mediastreaming.
     * If so, it will open a dialog window asking the user permission to stream audio content.
     *
     * @param {Function} errorMessageCallback - Callback function that is triggered when an error occurs.
     * @param {Function} getUserMediaNotSupportedCallback - Callback for when media is not supported.
     *
     * @return {Promise<MediaStream>} MediaStream Object
     */
    static askForPermission(errorMessageCallback) {

        const constraints = {
            audio: true,
            video: false
        };

        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia(constraints)
                .then(stream => {
                    return resolve(stream);
                }).catch(err => {
                    AudioStream.handleError(err, errorMessageCallback)
                    return reject(err);
                });
        });

    }

    /**
         * handleError
         *
         * This function is triggered when an error occurs.
         * It checks the name of the error and transforms it into a message towards the user.
         *
         * @param  {Object} err - error event object
         * @param {Function} errorCallback The error callback that displays an error message.
         */
    static handleError(err, errorCallback) {
        console.log('error message', err);

        switch (err.name) {

            // User didn't give permission to acces the device
            case 'NotAllowedError':
                errorCallback(
                    window.i18n.gettext(
                        'You didn\'t give permission to use the microphone.'
                    )
                );
                break;

                // User and operating system both granted acces,
                // but an unkown problem prevented the device from being used.
            case 'AbortError':
                errorCallback(
                    window.i18n.gettext(
                        'An error occurred which prevented the device ' +
                            'from being used. Please try again.'
                    )
                );
                break;

                // Can't find the specified audio track
            case 'NotFoundError':
                errorCallback(
                    window.i18n.gettext(
                        'The specified audio input can\'t be found. ' +
                            'Please try another input or try again.'
                    )
                );
                break;

                // Although the user granted permission to use the matching devices,
                // a hardware error occurred
                // at the operating system, browser, or Web page level which prevented access to the device.
            case 'NotReadableError':
                errorCallback(
                    window.i18n.gettext(
                        'An error occurred at the operating ' +
                            'system, browser or webpage level. Please try again.'
                    )
                );
                break;

            default:
                errorCallback(
                    window.i18n.gettext(
                        'An error occurred while trying to connect to the microphone. ' +
                            'Please try again.'
                    )
                );
        }

    }

}
