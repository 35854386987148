import Styles from './StudentCards.scss';

import Template from './StudentCards.hbs';
import GroupCard from 'views/pages/users/home/studentCards/groupCard/GroupCard';
import PendingGroupCard from './pendingGroupCard/PendingGroupCard';
import NewsCard from './newsCard/NewsCard'

export default BaseView.extend({

    initialize() {
        _.bindAll(this,
            'fetchNewGroup',
            'renderGroupCard'
        );
        // Compile handlebars file
        this.setElement(Template({Styles}));

        // Loop trough each group
        _.each(this.model.get('pending_groups'), this.renderPendingGroupCard, this);

        // Loop trough each group
        _.each(this.model.get('last_groups'), (groupInfo) => {
            this.attempt(
                this.renderGroupCard,
                this,
                groupInfo
            )
        })

        // Show single news item for all students except demo and exam product
        if (!Backbone.Model.user.get('is_demo')) {
            this.renderNews()
        }

    },

    renderGroupCard(groupInfo) {

        const groupModel = Backbone.Collection.groups.get(groupInfo.group_id)
        if (groupModel) {
            const showActivities = groupInfo.hasOwnProperty('activities')

            this.addChildView(new GroupCard({
                model: groupModel,
                weeks: groupInfo.weeks,
                itemCollection: showActivities ?
                    groupInfo.activities :
                    groupInfo.sections || [],
                showActivities,

            }), this.$el);

        } else {
            this.fetchNewGroup(groupInfo, this.renderGroupCard)
        }

    },

    renderPendingGroupCard(groupInfo) {

        this.addChildView(new PendingGroupCard({
            model: new Backbone.Model(groupInfo),
        }), this.$el);

    },

    renderNews() {
        const newsItem = this.model.get('news')
        if (newsItem) {
            const message = new Backbone.Model(newsItem)
            this.addChildView(
                new NewsCard({
                    model: message
                }),
                this.$el
            )
        }
    },

    /**
         * @param  {Object} groupInfo - group info from data_for_home call
         * @param {Function} callback - method to retry group rendering agter fetch
         * */
    fetchNewGroup(groupInfo, callback) {

        if (this.hasFetchedNewGroups) {
            window.sentry.withScope(scope => {
                scope.setExtra('groupInfo', groupInfo);
                scope.setTag('group_id', groupInfo.group_id);
                window.sentry.captureException(new Error('No group found'));
            });
        } else {

            // Refresh user data, the group should be present now. Do this only once.
            Backbone.Model.user.loadUserInfo(() => {
                this.hasFetchedNewGroups = true
                callback(groupInfo)
            })
        }
    }

});
