var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"modal__add-table") : stack1), depth0))
    + " modal-margin\">\n    <label class=\"js-number-of-rows-input "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"inputfield-add__label") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Choose rows count",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":76},"end":{"line":2,"column":102}}}))
    + "</label>\n    <label class=\"js-number-of-cols-input "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"inputfield-add__label") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Choose columns count",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":76},"end":{"line":3,"column":105}}}))
    + "</label>\n    <div class=\"js-border-checkbox\"></div>\n</div>\n";
},"useData":true});