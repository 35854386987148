import Styles from './Analytics.scss';

import Template from './Analytics.hbs';
import TaskAnalyticsModel from 'models/TaskAnalyticsModel'
import Spinner from 'views/components/spinner/Spinner'
import Template1Analytics from '../template1/analytics/Analytics'
import Template2Analytics from '../template2/analytics/Analytics'
import Template31Analytics from '../template31/analytics/Analytics'
import FAQ from 'views/components/faq/FAQ'

export default class TaskAnalytics extends BaseView {

    get answerCountElement() {
        return this.el.querySelector('.js-students-made-count')
    }

    get pValueElement() {
        return this.el.querySelector('.js-p-value')
    }

    get accessCountElement() {
        return this.el.querySelector('.js-students-access-count')
    }

    initialize({
        taskModel
    }) {

        this.setElement(Template({Styles}))

        this.addChildView(new Spinner({}), $(this.answerCountElement))
        this.addChildView(new Spinner({}), $(this.pValueElement))
        this.addChildView(new Spinner({}), $(this.accessCountElement))

        this.addChildView(new FAQ({
            question: window.i18n.gettext('What is a p-value?'),
            link: 'https://support.learnbeat.nl/article/zxlbtw6iaj-inzage-in-de-analytics-van-zelfgemaakt-lesmateriaal',
            isInline: true,
        }), '.js-p-value-block')

        const model = new TaskAnalyticsModel({
            task_id: taskModel.id,
            templateId: taskModel.get('template_id'),
        })
        model.fetch({
            success: (analyticsModel) => {
                this.destroyChildViewsOfInstance(Spinner)
                this.renderAnalytics(analyticsModel, taskModel)
            }
        })

    }

    renderAnalytics(analyticsModel, taskModel) {
        this.answerCountElement.innerText = analyticsModel.get('responses')

        this.pValueElement.innerText = analyticsModel.pValue
        this.pValueElement.classList.toggle(
            Styles['stat-block--green'],
            analyticsModel.pValue >= 0.6 && analyticsModel.pValue <= 0.8
        )

        this.accessCountElement.innerText = analyticsModel.get('students')

        if (!(_.size(analyticsModel.get('mistakes')) || _.size(analyticsModel.get('corrects')))) {
            return
        }

        switch (analyticsModel.get('templateId')) {
            case 1:
                this.el.classList.add(Styles['analytics--with-answer-summary'])
                this.addChildView(new Template1Analytics({
                    mistakes: analyticsModel.get('mistakes'),
                    taskModel,
                }), 'section')
                break
            case 2:
                this.el.classList.add(Styles['analytics--with-answer-summary'])
                this.addChildView(new Template2Analytics({
                    mistakes: analyticsModel.get('mistakes'),
                    corrects: analyticsModel.get('corrects'),
                }), 'section')
                break
            case 31:
                this.el.classList.add(Styles['analytics--with-answer-summary'])
                this.addChildView(new Template31Analytics({
                    mistakes: analyticsModel.get('mistakes'),
                    taskModel,
                }), 'section')
                break
        }
    }

}
