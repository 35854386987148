export default class RestoreScrollPosition {

    // Restore the scroll position when the browser back button is used
    // Code is based on https://github.com/janpaul123/delayed-scroll-restoration-polyfill

    static SCROLL_RESTORATION_TIMEOUT_MS = 3000
    static TRY_TO_SCROLL_INTERVAL_MS = 50

    constructor() {
        if (!window.history.pushState) {
            return
        }

        // Disable browser scroll restoration (does not work in our SPA)
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual'
        }

        const originalPushState = window.history.pushState
        const originalReplaceState = window.history.replaceState

        // Store current scroll position in current state when navigating away.
        window.history.pushState = function() {
            const newStateOfCurrentPage = Object.assign({}, window.history.state, {
                __scrollY: window.scrollY,
            })
            originalReplaceState.call(window.history, newStateOfCurrentPage, '')

            originalPushState.apply(window.history, arguments)
        }

        // Make sure we don't throw away scroll position when calling "replaceState".
        window.history.replaceState = function(state, ...otherArgs) {
            const newState = Object.assign({}, {
                __scrollY: window.history.state && window.history.state.__scrollY,
            }, state)

            originalReplaceState.apply(window.history, [newState].concat(otherArgs))
        }

        window.addEventListener('popstate', () => { this.onPopState() }, true)
    }

    // Try scrolling to the previous scroll position on popstate
    onPopState() {
        const state = window.history.state

        if (state?.__scrollY > 0) {
            setTimeout(() => this.tryToScrollTo({
                y: state.__scrollY,
                latestTimeToTry: Date.now() + RestoreScrollPosition.SCROLL_RESTORATION_TIMEOUT_MS,
            }))
        }
    }

    // Try to scroll to the scrollTarget, but only if we can actually scroll there.
    // Otherwise keep trying until we time out.
    tryToScrollTo(scrollTarget) {
        // Stop any previous calls to "tryToScrollTo".
        clearTimeout(this.timeoutHandle)

        if (document.body.scrollHeight - window.innerHeight >= scrollTarget.y) {
            window.scrollTo({
                top: scrollTarget.y,
                behavior: 'instant'
            })
        } else if (Date.now() < scrollTarget.latestTimeToTry) {
            this.timeoutHandle = setTimeout(
                () => { this.tryToScrollTo(scrollTarget) },
                RestoreScrollPosition.TRY_TO_SCROLL_INTERVAL_MS
            )
        }
    }
}
