import Styles from './Answer.scss';

import Template from './Answer.hbs';
export default class Answer extends BaseView {

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Object as passed from parent view
     */
    initialize(options) {

        // Make this accessible within the following functions
        _.bindAll(this,
            'show',
            'loadData',
            'onDestroy',
            'onResizeWindow',
            'removeStateClass',
            'setStateClassByScore',
            'initializeMolSketcher'
        );

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            label: options.label,
            fullWidthForOriginal: (options.fullWidth && Backbone.Model.user.getSetting('is_original_watching'))
        }));

        // Store the fullWidth boolean in this.fullWidth to use it in submethods
        this.fullWidth = options.fullWidth;

        // Set the initialMolSketchData
        this.initialMolSketchData = options.molSketchData;

        // Listen to the toggle of original answer and call onResizeWindow
        this.listenTo(this, 'toggleOriginalAnswer', this.onToggleOriginalAnswer);
    }

    /**
     * show
     *
     * This function will be called after the answer is added to the DOM
     *
     */
    show() {

        import(
            /* webpackChunkName: "molsketcher" */
            'molsketcher/Sketcher'
        ).then((MolSketcher) => {
            this.initializeMolSketcher(
                MolSketcher,
                this.initialMolSketchData
            )
        })

    }

    /**
     * removeStateClass
     *
     * Remove the state class from the preview container
     *
     */
    removeStateClass() {

        // Remove all state class from preview container
        this.$('.js-preview-container')
            .removeClass(Styles['is-correct'])
            .removeClass(Styles['is-incorrect'])
            .removeClass(Styles['is-half-correct']);
    }

    /**
     * setStateClassByScore
     *
     * This method will add a css-class to the preview container
     * using the value of the score.
     *
     * @param  {number} score   Score given to the answer
     */
    setStateClassByScore(score) {

        if (score === 1) {
            this.$('.js-preview-container').addClass(Styles['is-correct']);
        } else if (score === 0) {
            this.$('.js-preview-container').addClass(Styles['is-incorrect']);
        } else {
            this.$('.js-preview-container').addClass(Styles['is-half-correct']);
        }
    }

    /**
     * onToggleOriginalAnswer
     *
     * This function will be called when the teacher toggles the original answers.
     * It will transform the preview container to half width or full with according
     * to the number of columns
     *
     * @param  {boolean} state  Wheter the toggle when to true or to false
     */
    onToggleOriginalAnswer(state) {

        // Check if the full width is enable
        if (this.fullWidth) {

            // Create a variable containing the class action that should be executed
            var action = (state) ? 'addClass' : 'removeClass';

            // Execute the action on the preview container, with the full-width modifier class
            this.$('.js-molsketch-preview')[action](Styles['molsketch-preview-container--full-width']);
        }

        // Defered execution of onResizeWindow
        _.defer(this.onResizeWindow);
    }

    /**
     * initializeMolSketcher
     *
     * This function will initalize the molview's molsketcher defered.
     * It will initialize a new molsketch and attach it to its container.
     * It will take one argument containing the initial molSketchData,
     * this argument is optional
     *
     * @param  {Sketcher} MolSketcher   Constructor of MolSketcher
     * @param  {Object} molSketchData   Initial molSketchData
     */
    initializeMolSketcher({default: MolSketcher}, molSketchData) {

        // Create a new molsketch sketcher, passing jQuery with it
        this.molSketch = new MolSketcher($,

            // Tell molsketcher sketch to which element it should bind
            this.$('.js-molsketch-preview')[0],

            // The disabled option of the molsketch is true,
            // this prevents the user from change the structure
            true
        );

        // Check if options is set and contains molSketchData
        if (molSketchData) {

            /**
                 * Load the given data. The defer is necessary: it sometimes doesn't render
                 * without defer (known case: drawn molecule doesn't render for teacher
                 * in ShowAllAnswersView and ProgressView when the answer is given in exam
                 */
            _.defer(() => {
                this.loadData(molSketchData)
            })

        } else {

            // Call once to set initial sizing correctly
            this.molSketch.resize();
        }

        // When the window resizes, update the molsketch
        $(window).on('resize', this.onResizeWindow);
    }

    /**
     * loadData
     *
     * This function will call the loadPlainData method on its own
     * molSketch initialization. After loading new data, it will
     * also call the resize method to make drawing visible
     *
     * @param  {Object} molSketchData   To be initialized molSketchData object
     */
    loadData(molSketchData) {

        // Load the made molecule structure
        this.molSketch.mol.loadPlainData(molSketchData);

        // Call once to set initial sizing correctly
        this.molSketch.resize();
    }

    /**
     * onResizeWindow
     *
     * This function will be called when the window is being resized
     *
     */
    onResizeWindow() {

        // Execute the molSketch's resize function binding itself as scope
        _.bind(this.molSketch.resize, this.molSketch)();
    }

    /**
     * onDestroy
     *
     * This function will be called when this view is being destroyed
     *
     */
    onDestroy() {

        // Remove the resize event
        $(window).off('resize', this.onResizeWindow);
    }

}
