var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"open-question") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SharedStyles") : depth0)) != null ? lookupProperty(stack1,"question") : stack1), depth0))
    + "\">\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"open-question__instruction") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SharedStyles") : depth0)) != null ? lookupProperty(stack1,"question__instruction") : stack1), depth0))
    + " js-instruction\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"questionLabel") : depth0), depth0))
    + "</div>\n    <p class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"open-question__value") : stack1), depth0))
    + " js-question\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"questionValue") : depth0), depth0))
    + "</p>\n\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer") : stack1), depth0))
    + " js-answer\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"input-container") : stack1), depth0))
    + " js-input-container\"></div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"tooltip") : stack1), depth0))
    + " js-tooltip\"></div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"result") : stack1), depth0))
    + " js-result\"></div>\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"next-button") : stack1), depth0))
    + " js-next-button\">\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"checkmark",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":35}}})) != null ? stack1 : "")
    + "\n        </button>\n    </div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"character-buttons") : stack1), depth0))
    + " js-character-buttons\"></div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-buttons") : stack1), depth0))
    + " js-answer-buttons\"></div>\n\n</div>\n";
},"useData":true});