import Styles from './FAQ.scss';

import Template from './FAQ.hbs';
export default class FAQ extends BaseView {

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Options as defined by parent
     */
    initialize({
        question,
        link,
        isInline = false,
    }) {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            question,
            link,
            isInline
        }))
    }

}
