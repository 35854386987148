import Styles from './StarRater.scss';

import Template from './StarRater.hbs';
import StarTemplate from 'views/components/starRater/Star.hbs'

export default class Stars extends BaseView {

    /**
     * @param {Object} options
     * options object
     * @param {number} options.amount
     * amount of stars
     * @param {string} options.identifier
     * unique name for the star component instance. this components uses
     * checkbox with ids and labels with for attributes that need to
     * be distinguished.
     * @param {number} [options.starred]
     * amount of already rated stars
     * @param {number} [options.isEditable]
     * if editable or view only
     * @param {string} [options.size] size
     * @param {Function} [options.callback] optional callback
     *
     */
    initialize({
        identifier,
        amount = 5,
        starred = 0,
        isEditable = true,
        size = null,
        callback,
    }) {

        if (!identifier) {
            throw new Error('StarRater component needs to initalized with a value for "identifier"!')
        }

        this.identifier = identifier
        this.amount = amount
        this.isEditable = isEditable
        this.callback = callback

        this.setElement(Template({
            Styles,
            size: Styles[`stars--${ size }`],
        }))

        this.render(starred)
    }

    get ratedStars() {
        return this.el.querySelectorAll('input:checked').length
    }

    /**
     * @param {number} newAmount new amount of rated stars
     */
    set ratedStars(newAmount) {

        if (!this.isEditable) {
            return
        }

        // every star is an input element of type "checkbox". Set the star checkbox
        // to checked state to make it count as rated. The counting of the rated
        // stars and the css state depends on the checked state of the inputs
        for (let i = 0; i < this.amount; i++) {
            const checkbox = this.getStarCheckbox(i)
            checkbox.checked = i <= newAmount
        }
    }

    get score() {
        return this.ratedStars / this.amount
    }

    disable() {
        this.el.dataset.viewOnly = ''
        this.isEditable = false
    }

    enable() {
        delete this.el.dataset.viewOnly
        this.isEditable = true

    }

    render(starred) {

        if (!this.isEditable) {
            this.el.dataset.viewOnly = ''
        }

        // for every star, create the html and
        // append it to this.el

        const templates = []

        for (let i = 0; i < this.amount; i++) {
            templates.push(StarTemplate({
                index: i,
                starIdentifier: this.getStarId(i),
                Styles,
            }))
        }

        this.el.innerHTML = templates.join('')

        // if an amount of star is already rated
        // set the matching checkboxs as checked

        for (let i = 0; i < this.amount; i++) {

            const starCheckbox = this.getStarCheckbox(i)

            if (i < starred) {
                starCheckbox.checked = true
            }

            this.addCallback(starCheckbox)
        }
    }

    /**
     * @param {HTMLInputElement} element checkbox for the star
     */
    addCallback(element) {

        // on click, set the new amount of rated stars
        element.addEventListener('input', (e) => {
            const dataIndex = this.getIndexFromStarElement(e)
            this.ratedStars = dataIndex

            // and if custom callback is set, call it
            if (this.callback) {
                this.callback(dataIndex)
            }
        })

        // on hover, simulate hovering over all previous stars
        element.parentElement.addEventListener('mouseenter', e => {
            this.hoverPreviousStars(parseInt(e.currentTarget.dataset.starIndex))
        })

        // and clear hover effect when pointer device leaves star element
        element.parentElement.addEventListener('mouseleave', e => {
            this.clearHoverPreviousStars(parseInt(e.currentTarget.dataset.starIndex))
        })
    }

    hoverPreviousStars(index) {
        for (let i = 0; i <= index; i++) {
            this.getStarCheckbox(i).parentElement.dataset.isShining = ''
        }
    }

    clearHoverPreviousStars() {
        const shiningStars = this.el.querySelectorAll('[data-is-shining]')

        for (const shiningStar of shiningStars) {
            delete shiningStar.dataset.isShining
        }
    }

    getIndexFromStarElement(e) {
        return parseInt(e.currentTarget.parentElement.dataset.starIndex)
    }

    getStarId(index) {
        return `star-${this.identifier}-${index}`
    }

    getStarCheckbox(index) {
        return this.el.querySelector(`#${ this.getStarId(index) }`)
    }

    get starsContainer() {
        return this.el.querySelector('.js-stars')
    }

}
