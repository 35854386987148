var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-outer") : stack1), depth0))
    + "\">\n	<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer") : stack1), depth0))
    + "\">\n		<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer__title") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":56}}})) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</div>\n		<div class=\"source-text "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer__text") : stack1), depth0))
    + " js-answer-text\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"explanation") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n	</div>\n    <div class=\"js-answer-feedback "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-feedback") : stack1), depth0))
    + "\"></div>\n</div>\n";
},"useData":true});