import Styles from './MultipleChoice.scss';

import Template from './MultipleChoice.hbs';
import Variant from 'views/pages/words/multipleChoice/Variant.hbs'
import SharedStyles from 'views/pages/words/questionCard/QuestionCard.scss'
import TextToSpeech from 'views/pages/words/textToSpeech/TextToSpeech'

export default class MultipleChoice extends BaseView {

    get events() {
        return {
            'click .js-variant': 'onClickVariant'
        }
    }

    initialize({
        parent,
        pairCardText
    }) {

        _.bindAll(
            this,
            'onKeyUpGiveAnswer',
            'onKeyUpNextCard'
        )

        const { pairId, questionLabel, questionValue, answerValue, variants } = pairCardText

        this.variants = variants
        this.parent = parent

        this.setElement(Template({
            Styles,
            SharedStyles,
            questionLabel,
            questionValue,
        }));

        this.appendVariants(variants, answerValue)

        // If the question is supported by text to speech, add a button
        const questionToSpeechLanguage = this.parent.getTextToSpeechLanguage(questionLabel)
        if (questionToSpeechLanguage && questionValue.length < 150) {
            const direction = this.parent.sessionModel.get('learn_direction')
            this.questionSpeechButton = this.addChildView(new TextToSpeech({
                pairId,
                value: direction === 'left_right' ? 'left' : 'right',
                lang: questionToSpeechLanguage
            }), this.$('.js-question'))

            // Add button to header to enable auto speech
            this.parent.addAutoSpeechButton()

            // If auto speech is enabled, read the question
            this.listenTo(this.parent.sessionModel, 'change:autoSpeech', this.playAutoSpeech)
            this.playAutoSpeech()
        }

        this.parent.setInstructionText(window.i18n.gettext('Press a number or click a card to choose'))

        document.addEventListener('keyup', this.onKeyUpGiveAnswer)
    }

    appendVariants(variants, answerValue) {

        this.$('.js-answers').append(_.reduce(variants, (m, variant, index) => {

            if (variant === answerValue) {
                this.correctId = index + 1
            }

            return m + Variant({
                Styles,
                number: index + 1,
                answer: variant
            })
        }, ''))

    }

    playAutoSpeech() {
        if (this.questionSpeechButton && this.parent.sessionModel.get('autoSpeech')) {
            this.questionSpeechButton.el.click()
        }
    }

    onClickVariant(e) {

        this.stopAllEvents(e)

        document.removeEventListener('keyup', this.onKeyUpGiveAnswer)

        this.undelegateEvents()
        const clickedVariant = $(e.currentTarget)
        const selectedId = parseInt(clickedVariant.attr('id'))
        this.parent.setInstructionText(window.i18n.gettext('Press enter to continue'))

        this.isCorrectAnswer = selectedId === this.correctId
        this.givenAnswer = this.variants[selectedId - 1]

        if (this.isCorrectAnswer) {
            clickedVariant.addClass('js-selected').addClass(Styles['variant--correct'])
        } else {
            clickedVariant.addClass('js-selected').addClass(Styles['variant--incorrect'])
            this.$el.find(`#${this.correctId}`).addClass(`js-selected ${Styles['variant--correct']}`)
        }

        // Transform the clicked item into a next button
        clickedVariant.find('.js-next-button').show()
        clickedVariant.on('click', () => {
            this.parent.moveToNextCard(true)
        })
        clickedVariant.siblings().css('pointer-events', 'none')

        this.parent.setResponse(this.isCorrectAnswer, this.givenAnswer)
        this.parent.moveToNextCard()
        document.addEventListener('keyup', this.onKeyUpNextCard)
    }

    onKeyUpGiveAnswer(e) {
        e.stopPropagation()
        switch (e.keyCode) {
            // key '1'
            case 49 :
                this.$el.find('#1').click()
                break;
            // key '2'
            case 50 :
                this.$el.find('#2').click()
                break;
            // key '3'
            case 51 :
                this.$el.find('#3').click()
                break;
            // key '4'
            case 52 :
                this.$el.find('#4').click()
                break;
        }
    }

    onKeyUpNextCard(e) {
        e.stopPropagation()
        if (e.keyCode === 13) {
            document.removeEventListener('keyup', this.onKeyUpNextCard)
            this.parent.moveToNextCard(true)
        }
    }

    onDestroy() {
        document.removeEventListener('keyup', this.onKeyUpGiveAnswer)
        document.removeEventListener('keyup', this.onKeyUpNextCard)
    }

}
