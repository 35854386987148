export default class DeadlineModel extends Backbone.Model {

    save({deadline, exceptions}, successCallback) {
        $.post({
            url: '/activities/save_deadline/' + this.get('activityId') + '.json',
            data: {
                deadline,
                exceptions
            },
            success: (response) => {
                successCallback(response)
            }
        })
    }

    getLabel() {
        if (!this.get('hasDeadline')) {
            return
        }

        const deadline = new dayjs.utc(this.get('deadline'))
        return window.i18n.sprintf(
            window.i18n.gettext('%s on %s'),
            deadline.local().format('dd D MMM'),
            deadline.local().format('HH:mm')
        )
    }

    hasExpired() {
        if (this.get('hasDeadline')) {
            const deadline = new dayjs.utc(this.get('deadline'))
            if (deadline.isBefore(new dayjs.utc())) {
                return true
            }
        }
        return false
    }

}