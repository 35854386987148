import ElementsCollection from 'collections/ElementsCollection'
import SourceModel from 'models/SourceModel'
export default class SourcesCollection extends ElementsCollection {

    preinitialize() {
        this.constructor.type = 'sources'
    }

    modelId(attr) {
        return attr.id
    }

    get model() {
        return SourceModel
    }
}
