import Styles from './Item.scss';

import Template from './Item.hbs';
import Util from 'util/util';
import ShapeList from 'util/ShapeLoader';

var Item = BaseView.extend({

    initialize(options) {

        this.isInCategory = options.isInCategory;

        this.setElement(Template({
            Styles,
            name: Util.renderContentSafely(this.model.get('name')),
            id: this.model.id,
            isInCategory: options.isInCategory
        }));

    },

    /**
         * lock
         *
         * Visually locks the component's functionality.
         */
    lock() {
        // Based on the chained state of the item, either a reset or move icon is shown
        // Switch the locked icon with the icon that is being displayed
        var iconToSwitch = this.isInCategory ? 'reset' : 'move';
        this.$('.js-icon-' + iconToSwitch).html(ShapeList.lock);

        this.$el.addClass(Styles['item--is-locked']);

        // category's items are not draggable, so calling this method would throw an error
        if (this.$el.hasClass('ui-draggable')) {
            this.$el.draggable('disable')
        }
    },

    /**
         * unlock
         *
         * Visually enables component's functionality.
         */
    unlock() {

        // Based on the chained state of the item we have to switch the locked icon
        // for a reset or move icon
        var iconToSwitch = this.isInCategory ? 'reset' : 'move';
        this.$('.js-icon-' + iconToSwitch).html(
            ShapeList[this.isInCategory ? 'cross' : 'move']
        );

        this.$el.removeClass(Styles['item--is-locked']);

        // category's items are not draggable, so calling this method would throw an error
        if (this.$el.hasClass('ui-draggable')) {
            this.$el.draggable('enable')
        }
    }

});

export default Item;
