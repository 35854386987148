import Template from './ContentListItem.hbs';
import ListItemStyles from 'views/components/taskGroups/selectContent/contentList/ContentList.scss';
import Checkbox from 'views/components/checkbox/Checkbox';
import 'jquery-ui/ui/widgets/sortable';

export default BaseView.extend({

    events: {
        click: 'onClickItem'
    },

    initialize({
        model,
        // item has the 'move' icon and could be dragged
        isSortable,
        // show preview for this item
        callback,
        // collection of selected items
        collection,
        // only one item from the list could be selected
        selectSingleItem
    }) {

        this.callback = callback;
        this.collection = collection

        // Use one of 2 types of styling depending if the parent view allows for having one than more selection.
        this.selectedItemStyle = `${
            selectSingleItem ? ListItemStyles['content-item--selected-solo'] : ListItemStyles['content-item--selected']
        } js-item-selected`;

        this.setElement(Template({
            Styles: ListItemStyles,
            id: model.id,
            title: model.get('title') || window.i18n.gettext('No title'),
            type: model.getElementName(),
            icon: model.getElementIcon(),
            isSortable
        }));

        // if multiple items could be selected
        if (collection && !selectSingleItem) {
            this.addChildView(new Checkbox({
                isChecked: this.collection.has(this.model),
                callback: (state) => this.onMarkContentIncluded(state)
            }), this.$('.js-checkbox'));
        }

    },

    /**
     * onClickItem
     *
     * Apply a selection status when item is clicked.
     *
     * @param  {jQuery.event} e jQuery click event
     */
    onClickItem() {

        this.$el.siblings().removeClass(this.selectedItemStyle);
        this.$el.toggleClass(this.selectedItemStyle);

        var elementID = this.$el.hasClass('js-item-selected') ? this.model.id : 0;
        this.callback(elementID);
    },

    /**
     *
     * @param {boolean} isSelected State of the CheckBox component. True if selected
     */
    onMarkContentIncluded(isSelected) {
        if (isSelected) {
            /**
             * ensure that the newly added source will be the last item
             * in the collection of learning texts after sorting
             */
            this.model.set({ sequence: this.collection.length })

            this.collection.add(this.model);
        } else {
            this.collection.remove(this.model)
        }
    }

});
