import Styles from './DrawingCanvasLoader.scss';

import Template from './DrawingCanvasLoader.hbs';
import Button from 'views/components/button/Button.svelte'
import Spinner from 'views/components/spinner/Spinner.svelte'

export default class DrawingCanvasLoader extends BaseView {

    initialize({
        // Options that get passed directly to the DrawingCanvasViewer/DrawingCanvasEditor view.
        canvasOptions = {},
        // If we want to load the DrawingCanvasViewer ('viewer') or the DrawingCanvasEditor ('editor')
        type = 'viewer',
    }) {
        this.setElement(Template({Styles}))

        this.loadCanvas(canvasOptions, type)
    }

    loadCanvas(options, type) {
        // Show spinner while drawing canvas chunk is loading.
        if (!this.spinner) {
            this.spinner = this.addSvelteChildView(this.el, Spinner, {})
        }
        switch (type) {
            case 'editor':
                this.promise = this.loadDrawingCanvasEditor(options, type)
                break
            case 'viewer':
                this.promise = this.loadDrawingCanvasViewer(options, type)
                break
        }
    }

    loadDrawingCanvasEditor(options, type) {
        return import(
            /* webpackChunkName: "drawingcanvas" */
            'views/components/drawingCanvas/DrawingCanvasEditor'
        ).then(({default: DrawingCanvasEditor}) => {
            this.removeLoadState()
            return this.addChildView(new DrawingCanvasEditor(options), this.$el)
        }).catch((error) => {
            this.onLoadDrawingCanvasError(error, options, type)
        })
    }

    loadDrawingCanvasViewer(options, type) {
        return import(
            /* webpackChunkName: "drawingcanvas" */
            'views/components/drawingCanvas/DrawingCanvasViewer'
        ).then(({default: DrawingCanvasViewer}) => {
            this.removeLoadState()
            return this.addChildView(new DrawingCanvasViewer(options), this.$el)
        }).catch((error) => {
            this.onLoadDrawingCanvasError(error, options, type)
        })
    }

    onLoadDrawingCanvasError(error, canvasOptions, type) {
        // Only log error to Sentry if error occurred while user was online,
        // since we can't be blamed for the user's bad internet connection.
        if (navigator.onLine) {
            console.error(error)
            window.sentry.captureException(error)
        }

        // Replace spinner with button which starts another load attempt when clicked.
        this.removeLoadState()
        this.el.innerText = window.i18n.gettext('Could not load')
        this.retryButton = this.addSvelteChildView(
            this.el,
            Button,
            {
                label: window.i18n.gettext('Try again'),
                icon: 'refresh',
                inline: true,
                callback: () => {
                    this.el.innerText = ''
                    this.removeLoadState()
                    this.loadCanvas(canvasOptions, type)
                }
            }
        )
    }

    removeLoadState() {
        if (this.spinner) {
            this.spinner.$destroy()
            delete this.spinner
        }
        if (this.retryButton) {
            this.retryButton.$destroy()
            delete this.retryButton
        }
    }

}
