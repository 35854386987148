<script>
    import {tick} from 'svelte'
    import Util from 'util/util'
    import InputUtil from 'util/InputUtil'
    import Icon from '../icon/Icon.svelte'

    // Optional initial value of the input.
    export let value = ''

    // Optional placeholder value.
    export let placeholder = ''

    // Optional string to use as <label>.
    export let label = ''

    export let formName = null

    // Input element type. Can only be set on initialization. Must be a text-like input type:
    // text, number, url, email, search. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
    export let type = 'text'

    // Whether the input should claim focus after it has been initialized.
    export let autoFocus = false

    // Whether to skip content sanitization when calling .getValue() (WARNING: USE WITH CARE!)
    export let skipValidation = false

    // If enabled, user can use key shortcuts to make selected text bold, italicized, underlined,
    // sub and super scripted.
    export let enableHTMLshortcuts = false

    // If true, disable automatic capitalization.
    export let noAutoCapitalize = false

    // If true, disable autocorrect behaviour, including Grammarly suggestions.
    export let noAutoCorrect = false

    // Optional string of space-separated tokens to hint to the browser and password managers how to autocomplete
    // this input field. For example "email username" will hint the browser to look for a email or username.
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    export let autoCompleteTokens = 'on'
    const autocomplete = noAutoCorrect ? 'off' : autoCompleteTokens

    // Optional. Set specific type of virtual keyboard layout (text, tel, url, email, numberic, decimal, search).
    // This overrides the virtual keyboard set by the input element type.
    export let inputMode = null

    // Whether input element is disabled.
    export let disabled = false

    // Whether input element is in read-only mode.
    export let isReadOnly = false

    // If more than 0, enforce a maximum length to the input value and show the current character count and max length
    // to the user.
    export let maxLength = null

    // Enforce minimum length to input value. Usefull for search inputs, which often require at least 3 characters.
    export let minLength = null

    // If enabled display input in ALL CAPS.
    export let hasUppercase = false

    // If true, display as inline-flex instead of flex and auto-resize to fit content.
    export let displayInline = false

    // If true and combined with displayInline, min width of input is bigger.
    export let isWide = false

    // If true, supersize input field.
    export let isXL = false

    // Function to call when user hits the enter key or clicks the submit button.
    export let submitCallback = null

    // Whether to show submit button next to input field. Enabled by default if this prop is not set and
    // submitCallback is present.
    export let showSubmitButton = null
    showSubmitButton = showSubmitButton === null ? submitCallback !== null : showSubmitButton

    export let doSubmitCallbackOnBlur = false

    // If true, do not remove the value from the input field after submit callback has been called.
    export let keepValueOnSubmit = true

    // Optional callback which get called on input event.
    export let inputCallback = null

    // Optional callback to call content is pasted into input field.
    export let pasteCallback = null

    // Optional callback that triggers a 'delete' action external to this component. Adds a delete button if defined.
    export let deleteCallback = null

    const cid = _.uniqueId()

    const submitIcon = getIcon()

    // Read only
    export let inputElement

    let dummyElement
    let resizedWidth = null

    let isInvalid = false

    let rawValue = value

    $: value = _getValue(rawValue)

    export function triggerSubmitCallback(e) {
        if (submitCallback) {
            if (!doSubmitCallbackOnBlur && e && e.type === 'focusout') {
                return
            }
            checkValidity()
            if (isInvalid) {
                return
            }
            submitCallback(value)
            if (!keepValueOnSubmit) {
                rawValue = ''
            }
        }
    }

    export function getValue() {
        return value
    }

    export function setValue(newValue) {
        rawValue = newValue
        value = newValue
    }

    export function clearValue() {
        rawValue = ''
        triggerInputCallback()
    }

    export function setInvalidState() {
        isInvalid = true
    }

    function _getValue(currentRawValue) {
        if (skipValidation) {
            return currentRawValue.trim()
        }

        // Strip unwanted things like <script> tags. Unescape is here to make characters like & work.
        return Util.unescape(Util.renderContentSafely(currentRawValue)).trim()
    }

    async function triggerInputCallback() {
        await tick()
        clearValidityState()
        if (inputCallback) {
            inputCallback(value)
        }
    }

    function triggerPasteCallback(e) {
        clearValidityState()
        if (pasteCallback && e) {
            pasteCallback(e)
        }
    }

    function onKeyUp(e) {
        if (e.keyCode === 13 || e.key === 'Enter') {
            e.preventDefault()
            triggerSubmitCallback()
        }
    }

    function checkValidity() {
        if (maxLength > 0 && rawValue.length > maxLength) {
            isInvalid = true
        }
        // If a min length is defined, do not invalidate an input length of 0.
        // This way the user can clear the input, which is usefull for search inputs.
        if (minLength > 0 && rawValue.length < minLength && rawValue.length > 0) {
            isInvalid = true
        }
        if (type === 'email' && !InputUtil.validateEmailInput(rawValue)) {
            isInvalid = true
        }
        if (type === 'url' && !InputUtil.validateURLInput(rawValue)) {
            isInvalid = true
        }
    }

    function clearValidityState() {
        isInvalid = false
    }

    function enterHTMLShortcut(e) {
        if (!enableHTMLshortcuts) {
            return
        }
        InputUtil.enterHTMLShortcut(e)
    }

    async function setAutoFocus(el) {
        if (autoFocus) {
            // Wait one tick before trying to set focus in input, since the component might not be fully
            // rendered at the moment .setAutoFocus() is called.
            await tick()
            Util.placeCaret(el)
        }
    }

    async function setInputWidth(el) {
        if (!displayInline) {
            resizedWidth = null
            return
        }

        if (!dummyElement) {
            dummyElement = el.previousElementSibling
        }
        await tick()
        resizedWidth = dummyElement.clientWidth + 2
    }

    function getIcon() {
        switch (type) {
            case 'search':
                return 'search'
        }
        return 'checkmark'
    }

    // Set input type after creating the element since Svelte doesn't allow a two-way binding for the type attribute.
    function setType(el) {
        el.type = type
    }

</script>

<div
    class="container"
    class:container--is-disabled={disabled}
    class:container--is-inline={displayInline}
    class:container--is-wider={isWide}
    class:container--is-xl={isXL}
>
    {#if label}
        <label for={cid}>{label}</label>
    {/if}

    <div class="input-container">
        {#if displayInline}
            <span class="inline-text-width-measure">
                {placeholder.length > rawValue.length ? placeholder : rawValue}
            </span>
        {/if}
        <input
            id={cid}
            bind:value={rawValue}
            bind:this={inputElement}
            {placeholder}
            inputmode={inputMode}
            {disabled}
            on:keydown={enterHTMLShortcut}
            on:keyup={onKeyUp}
            on:paste={triggerPasteCallback}
            on:focusout={triggerSubmitCallback}
            on:input={setInputWidth}
            on:input={triggerInputCallback}
            autocorrect={noAutoCorrect ? 'off' : null}
            {autocomplete}
            spellcheck={noAutoCorrect ? false : null}
            data-gramm_editor={noAutoCorrect ? false : null}
            data-enable-grammarly={noAutoCorrect ? false : null}
            data-gramm={noAutoCorrect ? false : null}
            autocapitalize={noAutoCapitalize ? 'off' : null}
            maxlength={maxLength}
            minlength={minLength}
            readonly={isReadOnly}
            title={label ? '' : placeholder}
            name={formName}
            class:has-uppercase={hasUppercase}
            class:invalid={isInvalid}
            style:width="{resizedWidth}px"
            size="1"
            use:setType
            use:setAutoFocus
            use:setInputWidth
        />

        {#if maxLength}
            <span class="char-count decoration">{rawValue.length} / {maxLength}</span>
        {/if}

        <slot name="special-actions"></slot>
        {#if showSubmitButton}
            <button class="decoration" on:click={triggerSubmitCallback}><Icon name={submitIcon}></Icon></button>
        {/if}
        {#if deleteCallback}
            <button class="decoration" on:click={deleteCallback}><Icon name="trash"></Icon></button>
        {/if}
    </div>
</div>

<style lang="scss">
    @import 'src/styling/globals.scss';

    .input-container {
        display: flex;
        width: 100%;
        position: relative;

        input {
            @include text-input;
            @include text-input-disabled-state;
            flex-grow: 1;

            &.has-uppercase {
                text-transform: uppercase;

                &::placeholder {
                    text-transform: none;
                }
            }

            &.invalid {
                @include text-input-invalid-state;
            }

            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .inline-text-width-measure {
            @include text-input;
            position: absolute;
            top: 0;
            visibility: hidden;
            white-space: pre;
        }

        .char-count {
            display: flex;
            align-items: center;
            padding: 8px;
            user-select: none;
            color: $status-gray;
            background-color: $white;
            border: solid 1px $line-gray;
            border-left: 0;
        }

        button {
            @include button;
            @include button-hover-state;
            @include button-active-state;

            flex-shrink: 0;
            border-radius: 0;
        }

        .decoration {
            &:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;

        &--is-disabled {
            opacity: 0.75;
        }

        &--is-inline {
            display: inline-flex;
            width: auto;
            margin: 2px 0;
            max-width: 100%;

            input {
                min-width: 100px;
            }
        }

        &--is-wider {
            input {
                min-width: 200px;
            }
        }

        &--is-xl {
            input {
                @include large-title-text;
            }

            :global(svg) {
                width: 24px;
                height: 24px;
            }
        }

        label {
            @include label-text;
            display: flex;
            justify-content: space-between;
            max-width: 100%;
            margin-bottom: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $blue;
        }
    }
</style>
