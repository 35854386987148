import Styles from './WordSelection.scss';
import Util from 'util/util';
import FragmentTemplate from 'views/components/taskGroups/tasks/wordSelection/Fragment.hbs';
import WordSelectionStyles from 'views/components/taskGroups/tasks/wordSelection/WordSelection.scss';

export default BaseView.extend({

    prepareData() {

        this.JSONAnswer = this.castAnswerIDToInt(this.taskView.response.get('json_answer') || []);

        this.fragments = this.model.get('task_info_json').text;

    },

    /**
         * castAnswerIDToInt
         *
         * Cast ID attribute to int to make looking up data easier.
         *
         * @param  {Array} jsonAnswer   json_answer object as it comes from the backend
         * @return {Array} converted json_answer data
         */
    castAnswerIDToInt(jsonAnswer) {
        return _.map(jsonAnswer, (answer) => {
            answer.id = parseInt(answer.id);
            return answer;
        });
    },

    /**
         * insertPlainText
         *
         * Insert plain text in between the gaps with linebreaks on the appropriate places.
         *
         * @param  {string} fragment     word or words in between gaps
         * @return {string} fragment with break tags and spaces at appropriate places
         */
    insertPlainText(fragment) {

        var str = '';

        if (fragment === '\n') {

            str += '<br>';

        } else {

            // TODO convert existing tasks of type 30, 31 and 32 to split linebreaks and text into
            // seperate fragments.

            // If fragment starts with a linebreak, append a <br> before
            // the fragment.
            if (/^\n/.test(fragment)) {
                str += '<br>';
            }

            // Put space before fragment only if this is not an HTML closing tag.
            if (!/^<\/.+?>$/.test(fragment)) {
                str += ' ';
            }

            str += fragment;

            // If fragment ends with a linebreak, append a <br> after
            // the fragment.
            if (/\n$/.test(fragment)) {
                str += '<br>';
            }

        }

        return str;

    },

    /**
         * getCorrectAnswer
         *
         * Generate summary of the correct answer to use above the list of student answers.
         *
         * @return {string} HTML blob to be inserted above the list of student answers.
         */
    getCorrectAnswer() {

        var answerString = '';

        _.each(this.fragments, (fragment) => {
            if (fragment instanceof Object) {
                _.each(fragment.answers, (gapAnswer) => {
                    if (
                        (gapAnswer.hasOwnProperty('correct') && gapAnswer.correct) ||
                            !gapAnswer.hasOwnProperty('correct')
                    ) {
                        answerString += FragmentTemplate({
                            Styles: WordSelectionStyles,
                            fragment: Util.renderContentSafely(gapAnswer.text),
                            fragmentStatus: (
                                WordSelectionStyles['fragment--answer-summary'] + ' ' +
                                    WordSelectionStyles['fragment--show-answer']
                            )
                        });
                    }
                });
            } else {
                answerString += fragment + ' ';
            }
        });

        return answerString;

    },

    /**
         * getStudentAnswer
         *
         * Generate summary of the answer student has given for use in the list of student answers.
         *
         * @param  {Object} responseModel Student response data
         * @return {string}               HTML blob to be used for the student answer view.
         */
    getStudentAnswer(responseModel) {

        var answerString = '';
        var JSONAnswer = responseModel.get('json_answer');

        if (JSONAnswer) {

            JSONAnswer = this.castAnswerIDToInt(JSONAnswer);

            _.each(_.filter(this.model.get('task_info_json').text, _.isObject), (gap) => {

                var gapAnswers = gap.answers;

                // If case does not matter, transform correct gapAnswers toLowerCase after deep-cloning
                // such that the transformation doesn't affect the task_info_json elsewhere. This makes
                // checking the correctness of the student answer easier.
                if (this.isCaseSensistive === false) {
                    gapAnswers = this.transformLowerCase(gapAnswers);
                }

                // Default fragment styled to add to the FragmentTemplate.
                var fragmentStatus = (
                    WordSelectionStyles['fragment--answer-summary'] + ' ' +
                        WordSelectionStyles['fragment--show-answer'] + ' '
                );
                var fragmentText;

                // Find the student answer given for the current gap.
                var givenGapAnswer = _.findWhere(JSONAnswer, {
                    id: gap.id
                });

                // If answer is given, check if it is correct and apply appropriate styling.
                if (givenGapAnswer) {

                    // Extract the student answer text and compare it to the answer model.
                    var lookupAnswer = givenGapAnswer.answer.text;
                    if (this.isCaseSensistive === false) {
                        lookupAnswer = lookupAnswer.toLowerCase();
                    }
                    var givenGapAnswerExists = _.findWhere(gapAnswers, {
                        text: lookupAnswer
                    });
                    fragmentText = givenGapAnswer.answer.text;

                    var givenGapAnswerIsCorrect = givenGapAnswerExists !== undefined;
                    // If model answer has an 'correct' attribute this means that the
                    // answer has been chosen from a list (Template30 or Template32).
                    // Use this attribute to check the correctness of the answer
                    // chosen by the student.
                    // Otherwise the answer has been created in an open format
                    // (Template31). Check if there is any answer given and that
                    // there is a correct answer in the first place.
                    if (
                        givenGapAnswerIsCorrect &&
                            givenGapAnswerExists.hasOwnProperty('correct')
                    ) {
                        givenGapAnswerIsCorrect = givenGapAnswerExists.correct;
                    } else if (lookupAnswer && gapAnswers[0].text.length === 0) {
                        givenGapAnswerIsCorrect = true;
                    }

                    // Add styling classes to indicate if the answer is correct.
                    if (this.model.get('grading_mode') === 'auto') {
                        if (givenGapAnswerIsCorrect) {
                            fragmentStatus += WordSelectionStyles['fragment--correct'];
                        } else {
                            fragmentStatus += WordSelectionStyles['fragment--incorrect'];
                        }
                    }
                } else {
                    if (this.model.get('grading_mode') === 'auto') {
                        fragmentStatus += WordSelectionStyles['fragment--incorrect'];
                    }
                    fragmentText = '…';
                }

                // Append fragment template DOM string to student answer summary.
                answerString += FragmentTemplate({
                    Styles: WordSelectionStyles,
                    fragment: Util.renderContentSafely(fragmentText),
                    fragmentStatus
                });

            })

        }

        return answerString;

    },

    /**
         * transformLowerCase
         *
         * Transform all answers toLowerCase to make comparing answers easier if case sensitivity does not matter.
         *
         * @param  {Array} answers  answers with unknown capitalisation
         * @return {Array}          answers where all text is in lower case
         */
    transformLowerCase(answers) {
        // Deep-clone answers such that is does not change the this.fragments object.
        return _.map(JSON.parse(JSON.stringify(answers)), (answer) => {
            answer.text = answer.text.toLowerCase();
            return answer;
        })
    },

    /**
         * saveAnswer
         *
         * Convert this.JSONAnswer to a simple object and pass it to the saveResponse method of the parent taskView
         * to patch the answer to the server.
         */
    saveAnswer() {

        // For gap text open, the input debounce has completed
        this.debounceIsActive = false

        this.taskView.saveResponse(this.JSONAnswer);
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {},

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {}

});
