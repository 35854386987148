export const LatexEditorLayout = [{
    title: '123',
    keyGroups: [
        [{
            key: '(',
            cmd: '(',
            x: 0,
            y: 0
        }, {
            key: '%',
            cmd: '%',
            x: 0,
            y: 1
        }, {
            key: '€',
            cmd: '\u20AC',
            x: 0,
            y: 2
        }, {
            key: '≠',
            cmd: '≠',
            x: 0,
            y: 3
        },

        {
            key: ')',
            cmd: ')',
            x: 1,
            y: 0
        }, {
            key: '•',
            cmd: '*',
            x: 1,
            y: 1
        }, {
            key: '°',
            cmd: '\u00B0',
            x: 1,
            y: 2
        }, {
            key: '≈',
            cmd: '≈',
            x: 1,
            y: 3
        },

        {
            key: 'x<sup>2</sup>',
            cmd: '^',
            x: 2,
            y: 0
        }, {
            key: '√',
            cmd: '\\sqrt{}',
            x: 2,
            y: 1
        }, {
            key: 'sin',
            cmd: '\\sin()',
            x: 2,
            y: 2
        }, {
            key: 'log',
            cmd: 'log',
            x: 2,
            y: 3
        },

        {
            key: 'x<sub>2</sub>',
            cmd: '_',
            x: 3,
            y: 0
        }, {
            key: '<sup>x</sup>√',
            cmd: '\\nthroot{x}{}',
            x: 3,
            y: 1
        }, {
            key: 'cos',
            cmd: '\\cos()',
            x: 3,
            y: 2
        }, {
            key: 'ln',
            cmd: 'ln',
            x: 3,
            y: 3
        },

        {
            key: '!',
            cmd: '!',
            x: 4,
            y: 0
        }, {
            key: 'π',
            cmd: 'π',
            x: 4,
            y: 1
        }, {
            key: 'tan',
            cmd: '\\tan()',
            x: 4,
            y: 2
        }, {
            key: 'e',
            cmd: 'e',
            x: 4,
            y: 3
        }
        ],
        [{
            key: '7',
            cmd: '7',
            x: 0,
            y: 0
        }, {
            key: '4',
            cmd: '4',
            x: 0,
            y: 1
        }, {
            key: '1',
            cmd: '1',
            x: 0,
            y: 2
        }, {
            key: '0',
            cmd: '0',
            x: 0,
            y: 3
        },

        {
            key: '8',
            cmd: '8',
            x: 1,
            y: 0
        }, {
            key: '5',
            cmd: '5',
            x: 1,
            y: 1
        }, {
            key: '2',
            cmd: '2',
            x: 1,
            y: 2
        }, {
            key: ',',
            cmd: ',',
            x: 1,
            y: 3
        },

        {
            key: '9',
            cmd: '9',
            x: 2,
            y: 0
        }, {
            key: '6',
            cmd: '6',
            x: 2,
            y: 1
        }, {
            key: '3',
            cmd: '3',
            x: 2,
            y: 2
        }, {
            key: '=',
            cmd: '=',
            x: 2,
            y: 3
        },

        {
            key: '÷',
            cmd: '/',
            x: 3,
            y: 0
        }, {
            key: '×',
            cmd: '\\times',
            x: 3,
            y: 1
        }, {
            key: '-',
            cmd: '-',
            x: 3,
            y: 2
        }, {
            key: '+',
            cmd: '+',
            x: 3,
            y: 3
        }
        ],
        [{
            key: '⇦',
            cmd: 'Backspace',
            x: 0,
            y: 0,
            w: 2,
            special: true
        }, {
            key: 'del',
            cmd: 'Del',
            x: 2,
            y: 0,
            special: true
        }, {
            key: '←',
            cmd: 'Left',
            x: 0,
            y: 3,
            special: true
        }, {
            key: '↑',
            cmd: 'Up',
            x: 1,
            y: 2,
            special: true
        }, {
            key: '→',
            cmd: 'Right',
            x: 2,
            y: 3,
            special: true
        }, {
            key: '↓',
            cmd: 'Down',
            x: 1,
            y: 3,
            special: true
        }]
    ]
}, {
    title: 'abc',
    keyGroups: [
        [{
            key: 'q',
            cmd: 'q',
            x: 0,
            y: 0
        }, {
            key: 'w',
            cmd: 'w',
            x: 1,
            y: 0
        }, {
            key: 'e',
            cmd: 'e',
            x: 2,
            y: 0
        }, {
            key: 'r',
            cmd: 'r',
            x: 3,
            y: 0
        }, {
            key: 't',
            cmd: 't',
            x: 4,
            y: 0
        }, {
            key: 'y',
            cmd: 'y',
            x: 5,
            y: 0
        }, {
            key: 'u',
            cmd: 'u',
            x: 6,
            y: 0
        }, {
            key: 'i',
            cmd: 'i',
            x: 7,
            y: 0
        }, {
            key: 'o',
            cmd: 'o',
            x: 8,
            y: 0
        }, {
            key: 'p',
            cmd: 'p',
            x: 9,
            y: 0
        },

        {
            key: 'a',
            cmd: 'a',
            x: 0.5,
            y: 1
        }, {
            key: 's',
            cmd: 's',
            x: 1.5,
            y: 1
        }, {
            key: 'd',
            cmd: 'd',
            x: 2.5,
            y: 1
        }, {
            key: 'f',
            cmd: 'f',
            x: 3.5,
            y: 1
        }, {
            key: 'g',
            cmd: 'g',
            x: 4.5,
            y: 1
        }, {
            key: 'h',
            cmd: 'h',
            x: 5.5,
            y: 1
        }, {
            key: 'j',
            cmd: 'j',
            x: 6.5,
            y: 1
        }, {
            key: 'k',
            cmd: 'k',
            x: 7.5,
            y: 1
        }, {
            key: 'l',
            cmd: 'l',
            x: 8.5,
            y: 1
        },

        {
            key: '/',
            cmd: '\\text{/} ',
            x: 0,
            y: 2
        }, {
            key: 'z',
            cmd: 'z',
            x: 1,
            y: 2
        }, {
            key: 'x',
            cmd: 'x',
            x: 2,
            y: 2
        }, {
            key: 'c',
            cmd: 'c',
            x: 3,
            y: 2
        }, {
            key: 'v',
            cmd: 'v',
            x: 4,
            y: 2
        }, {
            key: 'b',
            cmd: 'b',
            x: 5,
            y: 2
        }, {
            key: 'n',
            cmd: 'n',
            x: 6,
            y: 2
        }, {
            key: 'm',
            cmd: 'm',
            x: 7,
            y: 2
        }, {
            key: ',',
            cmd: ',',
            shiftKey: '!',
            shiftCmd: '!',
            x: 8,
            y: 2
        }, {
            key: '.',
            cmd: '.',
            shiftKey: '?',
            shiftCmd: '?',
            x: 9,
            y: 2
        },

        {
            key: '⇧',
            cmd: 'CapsLock',
            x: 0,
            y: 3,
            w: 2,
            special: true
        }, {
            key: '',
            cmd: ' ',
            x: 2,
            y: 3,
            w: 5
        }, {
            key: 'LaTeX',
            cmd: '\\',
            shiftKey: 'LaTeX',
            x: 7,
            y: 3,
            w: 2
        }
        ],
        [{
            key: '⇦',
            cmd: 'Backspace',
            x: 0,
            y: 0,
            w: 2,
            special: true
        }, {
            key: 'del',
            cmd: 'Del',
            x: 2,
            y: 0,
            special: true
        }, {
            key: '←',
            cmd: 'Left',
            x: 0,
            y: 3,
            special: true
        }, {
            key: '↑',
            cmd: 'Up',
            x: 1,
            y: 2,
            special: true
        }, {
            key: '→',
            cmd: 'Right',
            x: 2,
            y: 3,
            special: true
        }, {
            key: '↓',
            cmd: 'Down',
            x: 1,
            y: 3,
            special: true
        }]
    ]
}, {
    title: 'αβɣ',
    keyGroups: [
        [{
            key: 'ε',
            cmd: '\\epsilon',
            shiftCmd: 'E',
            x: 0,
            y: 0
        }, {
            key: 'ρ',
            cmd: '\\rho',
            shiftCmd: 'P',
            x: 1,
            y: 0
        }, {
            key: 'τ',
            cmd: '\\tau',
            shiftCmd: 'T',
            x: 2,
            y: 0
        }, {
            key: 'υ',
            cmd: '\\upsilon',
            shiftCmd: '\\Upsilon',
            x: 3,
            y: 0
        }, {
            key: 'θ',
            cmd: '\\theta',
            shiftCmd: '\\Theta',
            x: 4,
            y: 0
        }, {
            key: 'ι',
            cmd: '\\iota',
            shiftCmd: 'I',
            x: 5,
            y: 0
        }, {
            key: 'ο',
            cmd: 'o',
            x: 6,
            y: 0
        }, {
            key: 'π',
            cmd: '\\pi',
            shiftCmd: '\\Pi',
            x: 7,
            y: 0
        }, {
            key: '`',
            cmd: '`',
            x: 8,
            y: 0
        },

        {
            key: 'α',
            cmd: '\\alpha',
            shiftCmd: 'A',
            x: 0,
            y: 1
        }, {
            key: 'σ',
            cmd: '\\sigma',
            shiftCmd: '\\Sigma',
            x: 1,
            y: 1
        }, {
            key: 'δ',
            cmd: '\\delta',
            shiftCmd: '\\Delta',
            x: 2,
            y: 1
        }, {
            key: 'φ',
            cmd: '\\phi',
            shiftCmd: '\\Phi',
            x: 3,
            y: 1
        }, {
            key: 'γ',
            cmd: '\\gamma',
            shiftCmd: '\\Gamma',
            x: 4,
            y: 1
        }, {
            key: 'η',
            cmd: '\\eta',
            shiftCmd: 'H',
            x: 5,
            y: 1
        }, {
            key: 'ξ',
            cmd: '\\xi',
            shiftCmd: '\\Xi',
            x: 6,
            y: 1
        }, {
            key: 'κ',
            cmd: '\\kappa',
            shiftCmd: 'K',
            x: 7,
            y: 1
        }, {
            key: 'λ',
            cmd: '\\lambda',
            shiftCmd: '\\Lambda',
            x: 8,
            y: 1
        },

        {
            key: 'ζ',
            cmd: '\\zeta',
            shiftCmd: 'Z',
            x: 0,
            y: 2
        }, {
            key: 'χ',
            cmd: '\\chi',
            shiftCmd: 'X',
            x: 1,
            y: 2
        }, {
            key: 'ψ',
            cmd: '\\psi',
            shiftCmd: '\\Psi',
            x: 2,
            y: 2
        }, {
            key: 'ω',
            cmd: '\\omega',
            shiftCmd: '\\Omega',
            x: 3,
            y: 2
        }, {
            key: 'β',
            cmd: '\\beta',
            shiftCmd: 'B',
            x: 4,
            y: 2
        }, {
            key: 'ν',
            cmd: '\\nu',
            shiftCmd: 'N',
            x: 5,
            y: 2
        }, {
            key: 'μ',
            cmd: '\\mu',
            shiftCmd: 'M',
            x: 6,
            y: 2
        }, {
            key: ',',
            cmd: ',',
            shiftKey: '!',
            shiftCmd: '!',
            x: 7,
            y: 2
        }, {
            key: '.',
            cmd: '.',
            shiftKey: '?',
            shiftCmd: '?',
            x: 8,
            y: 2
        },

        {
            key: '⇧',
            cmd: 'CapsLock',
            x: 0,
            y: 3,
            w: 2,
            special: true
        }, {
            key: '',
            cmd: '',
            x: 2,
            y: 3,
            w: 5
        }, {
            key: 'LaTeX',
            cmd: '\\',
            shiftKey: 'LaTeX',
            x: 7,
            y: 3,
            w: 2
        }
        ],
        [{
            key: '⇦',
            cmd: 'Backspace',
            x: 0,
            y: 0,
            w: 2,
            special: true
        }, {
            key: 'del',
            cmd: 'Del',
            x: 2,
            y: 0,
            special: true
        }, {
            key: '←',
            cmd: 'Left',
            x: 0,
            y: 3,
            special: true
        }, {
            key: '↑',
            cmd: 'Up',
            x: 1,
            y: 2,
            special: true
        }, {
            key: '→',
            cmd: 'Right',
            x: 2,
            y: 3,
            special: true
        }, {
            key: '↓',
            cmd: 'Down',
            x: 1,
            y: 3,
            special: true
        }]
    ]
}, {
    title: '△',
    keyGroups: [
        [{
            key: '||',
            cmd: '\\parallel',
            x: 0,
            y: 0
        }, {
            key: '≡',
            cmd: '\\equiv',
            x: 0,
            y: 1,
        }, {
            key: '≅',
            cmd: '\\cong',
            x: 0,
            y: 2
        },
        {
            key: '~',
            cmd: '\\sim',
            x: 0,
            y: 3
        },

        {
            key: '∠',
            cmd: '\u2220',
            x: 1,
            y: 0
        }, {
            key: '△',
            cmd: '\\triangle',
            x: 1,
            y: 1
        }, {
            key: 'sin',
            cmd: '\\sin()',
            x: 1,
            y: 2
        }, {
            key: 'sin<sup>-1</sup>',
            cmd: '\\sin^{-1}()',
            x: 1,
            y: 3,
        },

        {
            key: '°',
            cmd: '\u00B0',
            x: 2,
            y: 0
        }, {
            key: '\'',
            cmd: '\'',
            x: 2,
            y: 1
        }, {
            key: 'cos',
            cmd: '\\cos()',
            x: 2,
            y: 2
        }, {
            key: 'cos<sup>-1</sup>',
            cmd: '\\cos^{-1}()',
            x: 2,
            y: 3
        },

        {
            key: 'rad',
            cmd: '\\text{rad} ',
            x: 3,
            y: 0,
        },
        {
            key: 'π',
            cmd: '\\pi',
            x: 3,
            y: 1
        }, {
            key: 'tan',
            cmd: '\\tan()',
            x: 3,
            y: 2
        }, {
            key: 'tan<sup>-1</sup>',
            cmd: '\\tan^{-1}()',
            x: 3,
            y: 3
        }],

        [{
            key: '<small>binom</small>',
            cmd: '\\binom{n}{k} ',
            x: 0,
            y: 0
        }, {
            key: '∫',
            cmd: '\\int',
            x: 0,
            y: 1
        }, {
            key: ':',
            cmd: ':',
            x: 0,
            y: 2
        }, {
            key: '‰',
            cmd: '\u2030',
            x: 0,
            y: 3
        },

        {
            key: '∀',
            cmd: '\\forall',
            x: 1,
            y: 0
        }, {
            key: '$',
            cmd: '$',
            x: 1,
            y: 1
        }, {
            key: '∞',
            cmd: '\\infty',
            x: 1,
            y: 2,
        }, {
            key: '∇',
            cmd: '\\del',
            x: 1,
            y: 3
        },

        {
            key: '∑',
            cmd: '\\sum',
            x: 2,
            y: 0
        }, {
            key: '→',
            cmd: '\\rightarrow',
            x: 2,
            y: 1
        }, {
            key: '↑',
            cmd: '\\uparrow',
            x: 2,
            y: 2
        }, {
            key: '↔',
            cmd: '\\leftrightarrow',
            x: 2,
            y: 3
        },

        {
            key: '∏',
            cmd: '\\prod',
            x: 3,
            y: 0
        }, {
            key: '←',
            cmd: '\\leftarrow',
            x: 3,
            y: 1
        }, {
            key: '↓',
            cmd: '\\downarrow',
            x: 3,
            y: 2
        }, {
            key: '⋯',
            cmd: '\\cdots',
            x: 3,
            y: 3
        }],

        [{
            key: '[',
            cmd: '[',
            x: 0,
            y: 0
        },
        {
            key: '<',
            cmd: '<',
            x: 0,
            y: 2
        }, {
            key: '≤',
            cmd: '≤',
            x: 0,
            y: 3
        }, {
            key: '{',
            cmd: '{',
            x: 0,
            y: 1
        },

        {
            key: ']',
            cmd: ']',
            x: 1,
            y: 0
        },
        {
            key: '>',
            cmd: '>',
            x: 1,
            y: 2
        }, {
            key: '≥',
            cmd: '≥',
            x: 1,
            y: 3
        }, {
            key: '}',
            cmd: '}',
            x: 1,
            y: 1
        }

        ]
    ]
}]

export const MobileLatexEditorLayout = [{
    title: '123',
    keyGroups: [
        [{
            key: 'ln',
            cmd: 'ln',
            x: 0,
            y: 0
        }, {
            key: 'log',
            cmd: 'log',
            x: 1,
            y: 0
        }, {
            key: '!',
            cmd: '!',
            x: 2,
            y: 0
        }, {
            key: 'sin',
            cmd: '\\sin()',
            x: 3,
            y: 0
        }, {
            key: 'cos',
            cmd: '\\cos()',
            x: 4,
            y: 0
        }, {
            key: 'tan',
            cmd: '\\tan()',
            x: 5,
            y: 0
        },

        {
            key: 'e',
            cmd: 'e',
            x: 0,
            y: 1
        }, {
            key: '√',
            cmd: '\\sqrt{}',
            x: 1,
            y: 1
        }, {
            key: '<sup>y</sup>√',
            cmd: '\\nthroot{x}{}',
            x: 2,
            y: 1
        }, {
            key: 'x<sup>2</sup>',
            cmd: '^',
            x: 3,
            y: 1
        }, {
            key: 'x<sup>y</sup>',
            cmd: '^',
            x: 4,
            y: 1
        }, {
            key: 'x<sub>y</sub>',
            cmd: '_',
            x: 5,
            y: 1
        },

        {
            key: 'π',
            cmd: 'π',
            x: 0,
            y: 2
        }, {
            key: '≠',
            cmd: '≠',
            x: 1,
            y: 2
        }, {
            key: '≈',
            cmd: '≈',
            x: 2,
            y: 2
        }, {
            key: '÷',
            cmd: '/',
            x: 3,
            y: 2
        }, {
            key: '(',
            cmd: '(',
            x: 4,
            y: 2
        }, {
            key: ')',
            cmd: ')',
            x: 5,
            y: 2
        },

        {
            key: '7',
            cmd: '7',
            x: 0,
            y: 3
        }, {
            key: '8',
            cmd: '8',
            x: 1,
            y: 3
        }, {
            key: '9',
            cmd: '9',
            x: 2,
            y: 3
        }, {
            key: '×',
            cmd: '\\times',
            x: 3,
            y: 3
        }, {
            key: '<',
            cmd: '<',
            x: 4,
            y: 3
        }, {
            key: '>',
            cmd: '>',
            x: 5,
            y: 3
        },

        {
            key: '4',
            cmd: '4',
            x: 0,
            y: 4
        }, {
            key: '5',
            cmd: '5',
            x: 1,
            y: 4
        }, {
            key: '6',
            cmd: '6',
            x: 2,
            y: 4
        }, {
            key: '-',
            cmd: '-',
            x: 3,
            y: 4
        }, {
            key: '≤',
            cmd: '≤',
            x: 4,
            y: 4
        }, {
            key: '≥',
            cmd: '≥',
            x: 5,
            y: 4
        },

        {
            key: '1',
            cmd: '1',
            x: 0,
            y: 5
        }, {
            key: '2',
            cmd: '2',
            x: 1,
            y: 5
        }, {
            key: '3',
            cmd: '3',
            x: 2,
            y: 5
        }, {
            key: '+',
            cmd: '+',
            x: 3,
            y: 5
        },

        {
            key: '0',
            cmd: '0',
            x: 1,
            y: 6
        }, {
            key: ',',
            cmd: ',',
            x: 2,
            y: 6
        }, {
            key: '=',
            cmd: '=',
            x: 3,
            y: 6
        }, {
            key: '↑',
            cmd: 'Up',
            x: 4,
            y: 6,
            special: true
        },

        {
            key: '⇦',
            cmd: 'Backspace',
            x: 1,
            y: 7,
            special: true
        }, {
            key: 'del',
            cmd: 'Del',
            x: 2,
            y: 7,
            special: true
        }, {
            key: '←',
            cmd: 'Left',
            x: 3,
            y: 7,
            special: true
        }, {
            key: '↓',
            cmd: 'Down',
            x: 4,
            y: 7,
            special: true
        }, {
            key: '→',
            cmd: 'Right',
            x: 5,
            y: 7,
            special: true
        }
        ]
    ]
}, {
    title: 'abc',
    keyGroups: []
}, {
    title: 'αβɣ',
    keyGroups: [
        [{
            key: 'α',
            cmd: '\\alpha',
            x: 0,
            y: 0
        }, {
            key: 'β',
            cmd: '\\beta',
            x: 1,
            y: 0
        }, {
            key: 'ɣ',
            cmd: '\\gamma',
            x: 2,
            y: 0
        }, {
            key: 'δ',
            cmd: '\\delta',
            x: 3,
            y: 0
        }, {
            key: 'Δ',
            cmd: '\\Delta',
            x: 4,
            y: 0
        }, {
            key: 'η',
            cmd: '\\eta',
            x: 5,
            y: 0
        },

        {
            key: 'θ',
            cmd: '\\theta',
            x: 0,
            y: 1
        }, {
            key: 'Θ',
            cmd: '\\Theta',
            x: 1,
            y: 1
        }, {
            key: 'λ',
            cmd: '\\lambda',
            x: 2,
            y: 1
        }, {
            key: 'Λ',
            cmd: '\\Lambda',
            x: 3,
            y: 1
        }, {
            key: 'µ',
            cmd: '\\mu',
            x: 4,
            y: 1
        }, {
            key: 'ν',
            cmd: '\\nu',
            x: 5,
            y: 1
        },

        {
            key: 'π',
            cmd: '\\pi',
            x: 0,
            y: 2
        }, {
            key: 'Π',
            cmd: '\\Pi',
            x: 1,
            y: 2
        }, {
            key: 'ρ',
            cmd: '\\rho',
            x: 2,
            y: 2
        }, {
            key: 'Σ',
            cmd: '\\Sigma',
            x: 3,
            y: 2
        }, {
            key: 'σ',
            cmd: '\\sigma',
            x: 4,
            y: 2
        }, {
            key: 'τ',
            cmd: '\\tau',
            x: 5,
            y: 2
        },

        {
            key: 'ϕ',
            cmd: '\\phi',
            x: 0,
            y: 3
        }, {
            key: 'Φ',
            cmd: '\\Phi',
            x: 1,
            y: 3
        }, {
            key: 'ω',
            cmd: '\\omega',
            x: 2,
            y: 3
        }, {
            key: 'Ω',
            cmd: '\\Omega',
            x: 3,
            y: 3
        }, {
            key: '€',
            cmd: '\u20AC',
            x: 4,
            y: 3
        }, {
            key: '$',
            cmd: '$',
            x: 5,
            y: 3
        },

        {
            key: 'x',
            cmd: 'x',
            x: 0,
            y: 4
        }, {
            key: 'y',
            cmd: 'y',
            x: 1,
            y: 4
        }, {
            key: 'z',
            cmd: 'z',
            x: 2,
            y: 4
        }, {
            key: 'n',
            cmd: 'n',
            x: 3,
            y: 4
        }, {
            key: '[',
            cmd: '[',
            x: 4,
            y: 4
        }, {
            key: ']',
            cmd: ']',
            x: 5,
            y: 4
        },

        {
            key: 'a',
            cmd: 'a',
            x: 0,
            y: 5
        }, {
            key: 'b',
            cmd: 'b',
            x: 1,
            y: 5
        }, {
            key: 'c',
            cmd: 'c',
            x: 2,
            y: 5
        }, {
            key: 'i',
            cmd: 'i',
            x: 3,
            y: 5
        }, {
            key: '{',
            cmd: '{',
            x: 4,
            y: 5
        }, {
            key: '}',
            cmd: '}',
            x: 5,
            y: 5
        },

        {
            key: '∠',
            cmd: '\u2220',
            x: 0,
            y: 6
        }, {
            key: '°',
            cmd: '\u00B0',
            x: 1,
            y: 6
        }, {
            key: '%',
            cmd: '%',
            x: 2,
            y: 6
        }, {
            key: '↑',
            cmd: 'Up',
            x: 4,
            y: 6,
            special: true
        },

        {
            key: '⇦',
            cmd: 'Backspace',
            x: 1,
            y: 7,
            special: true
        }, {
            key: 'del',
            cmd: 'Del',
            x: 2,
            y: 7,
            special: true
        }, {
            key: '←',
            cmd: 'Left',
            x: 3,
            y: 7,
            special: true
        }, {
            key: '↓',
            cmd: 'Down',
            x: 4,
            y: 7,
            special: true
        }, {
            key: '→',
            cmd: 'Right',
            x: 5,
            y: 7,
            special: true
        }
        ]
    ]
}]
