import CompetencyComponentModel from 'models/CompetencyComponentModel'
import CompetencyCriteriaModel from 'models/CompetencyCriteriaModel'

export default class CompetencyModel extends Backbone.Model {

    get url() {
        return `/competencies/${this.id}`
    }

    initialize(attr) {
        this.set({criteria: new Backbone.Collection(attr.criteria, {model: CompetencyCriteriaModel})})
        this.set({components: new Backbone.Collection(attr.components, {model: CompetencyComponentModel})})
        this.set('passing_score', attr.passing_score);
        this.get('components').competencyId = this.id
    }

    addComponent({id, title, description}) {
        return this.get('components').add({
            id,
            title,
            description,
        })
    }
}
