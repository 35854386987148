import Styles from './Component.scss';

import Template from './Component.hbs';
import Textarea from 'views/components/textarea/Textarea.svelte'

export default class Component extends BaseView {

    initialize({
        isPreview,
        isArchive,
        isOpenEvaluation,
        competencyModel,
        evaluationModel
    }) {

        this.setElement(Template({
            Styles,
            title: this.model.get('title'),
            description: this.model.get('description'),
            criteria: competencyModel.get('criteria').toJSON(),
            isReadOnly: isPreview || isArchive
        }))

        if (isArchive) {
            this.$('.js-comment').text(evaluationModel.getComment(this.model.id))
        } else {
            this.addSvelteChildView(
                '.js-comment',
                Textarea,
                {
                    label: window.i18n.gettext('Remark'),
                    minLines: 1,
                    value: evaluationModel?.getComment(this.model.id),
                    inputCallback: (value) => {
                        evaluationModel.setComment(this.model.id, value)
                    },
                    disabled: isPreview,
                },
            )
        }

        if (isOpenEvaluation || isArchive) {
            const selectedCriterium = evaluationModel.getCriterium(this.model)
            if (selectedCriterium) {
                this.el.querySelector(`.js-criterium-button[data-criterium-id="${selectedCriterium}"]`)?.classList.add(Styles['selected'])
            }
        }

        if (isOpenEvaluation) {
            this.$('.js-criterium-button').on('click', (e) => {
                const clickedButton = e.target
                for (const criteriaButton of clickedButton.parentElement.children) {
                    if (criteriaButton === clickedButton) {
                        criteriaButton.classList.toggle(Styles['selected'])
                        continue
                    }
                    criteriaButton.classList.remove(Styles['selected'])
                }
                evaluationModel.setCriterium(
                    this.model.id,
                    clickedButton.classList.contains(Styles['selected']) ? clickedButton.dataset.criteriumId : undefined
                )
            })
        }

    }

}
