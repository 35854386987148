var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isTeacher.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isTeacher","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":18}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"js-progress tile__navigation__btn\">\n            "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"progress",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":34}}})) != null ? stack1 : "")
    + "\n            <span>"
    + container.escapeExpression(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Progress",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":35}}}))
    + "</span>\n"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isNotInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"adaptive","adaptive_student","training",{"name":"isNotInList","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":28}}})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <strong>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"progress") : depth0), depth0))
    + "</strong>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isNotMobile.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":16}}})) != null ? stack1 : "");
},"useData":true});