import Template from './Unorderedlist.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Unorderedlist extends Button {

    // Built-in TinyMCE command for inserting unordered lists
    command = 'InsertUnorderedList'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Bulleted list');
    }

}
