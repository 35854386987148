import Template from './InvalidTemplate.hbs';
import StatusCard from 'views/components/statusCard/StatusCard'

// This "Template" serves as a fallback in the case
//   - a template id has become legacy, or
//   - has the id of 0 (for example, wrongly imported)
// It renders a card that warns the user, and provides
// mock functions for the required Template functions
export default class InvalidTemplate extends BaseView {
    initialize() {

        this.bindAll([
            'showAnswer',
            'hideAnswer',
            'lockAnswer',
            'unlockAnswer',
        ])

        this.setElement(Template({}))

        this.addChildView(
            new StatusCard({
                icon: 'alert',
                statusColor: 'red',
                cardContent: window.i18n.gettext('This task could not be loaded.'),
            }),
            this.$el,
        )
    }

    showAnswer() {}

    hideAnswer() {}

    lockAnswer() {}

    unlockAnswer() {}
}
