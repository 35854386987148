import Template from './LostPasswordInputModal.hbs';
/**
 * This modal instance is called when the user clicks on a forget password button.
 *
 * He will be asked to fill in his username or email, which results in a POST request to the backend.
 * On submit he will be directed to the LostPasswordSuccessModal.
 */

import QuickInput from 'views/components/quickInput/QuickInput.svelte'
import Button from 'views/components/button/Button'
import LostPasswordStyles from 'views/components/modals/lostPassword/LostPassword.scss';
import LostPasswordSuccessModal from 'views/components/modals/lostPassword/successModal/LostPasswordSuccessModal';

export default class LostPasswordInputModal extends BaseView {

    initialize({
        username
    }) {

        this.setElement(Template({
            Styles: LostPasswordStyles
        }));

        // Create username / email address input.
        const inputField = this.addSvelteChildView(
            '.js-forgot-input',
            QuickInput,
            {
                placeholder: window.i18n.gettext('Email address or username'),
                value: username,
                noAutoCapitalize: true,
                submitCallback: (value) => this.sendNewPasswordRequest(value),
                showSubmitButton: false,
                autoFocus: true,
                inputMode: 'email',
                autoCompleteTokens: 'email username',
            }
        )

        this.sendButton = this.addChildView(new Button({
            label: window.i18n.gettext('Send email'),
            iconRight: 'arrow-right',
            callback: () => this.sendNewPasswordRequest(inputField.getValue()),
        }), '.js-forgot-submit')

    }

    /**
     * Creates a POST request with the username or email inside the input field.
     *
     * @param {String} username     username or email value from forgetPasswordInput
     */
    sendNewPasswordRequest(username) {

        // Username cannot be empty
        if (!username) {
            return
        }

        // Toggle spinner
        this.sendButton.disable(true)

        // Do a POST request
        $.post(

            // to this url
            '/users/forgot_password.json', {
                // containing the username or email from the input
                username
            },

            // On response, trigger function
            (response) => {

                // Remove the form and inform the user that we sent an email
                if (response.status === 'success') {

                    Backbone.View.Components.modal.add(LostPasswordSuccessModal, {
                        title: window.i18n.gettext('Sent'),

                        // Let the view know which username was entered
                        username
                    });
                }
            }
        ).always(() => {
            this.sendButton.enable()
        })
    }

}
