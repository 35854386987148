var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-reply "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"reply") : stack1), depth0))
    + "\">\n    <div class=\"\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"NoteStyles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\">\n            <span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sender") : stack1), depth0))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"sender") || (depth0 != null ? lookupProperty(depth0,"sender") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"sender","hash":{},"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":54}}}) : helper)))
    + "</span><span class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"NoteStyles") : depth0)) != null ? lookupProperty(stack1,"timestamp") : stack1), depth0))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"pretty_time") || (depth0 != null ? lookupProperty(depth0,"pretty_time") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"pretty_time","hash":{},"data":data,"loc":{"start":{"line":4,"column":102},"end":{"line":4,"column":117}}}) : helper)))
    + "</span>\n        </div>\n        <div class=\"js-note-text "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"NoteStyles") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"reply-text") : stack1), depth0))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":6,"column":87}}}) : helper)))
    + "</div>\n    </div>\n</div>\n";
},"useData":true});