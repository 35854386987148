import Template from './ModifyAnnotationSidebarView.hbs';
import ActivityModel from 'models/ActivityModel';
import Button from 'views/components/button/Button.svelte'
import Input from '../input/Input';

export default BaseView.extend({

    hide(callback) {
        window.tinyMCE.get().forEach(editor => editor.destroy())
        callback(this);
    },

    initialize() {

        /*
             #
             #    All the stuff before rendering the template
             #
             */
        _.bindAll(this,
            'onRemoveAnnotation',
            'removeAnnotation',
            'onLoadActivity',
            'onSavedAndLoaded'
        );

        /*
             #
             #    Render the template
             #
             */

        // Compile template with the user and annotation objects
        this.setElement(Template({}));

        // create path information
        this.path = {
            group_id: this.model.get('group_id'),
            chapter_id: this.model.get('chapter_id'),
            section_id: this.model.get('section_id'),
            activity_id: this.model.get('activity_id'),
            task_group_id: this.model.get('task_group_id'),
            task_group_sequence: this.model.get('task_group_sequence'),
        };

        /*
             #
             #    All the listeners
             #
             */
        this.annotationInput = new Input(
            [this.path], {
                content: this.model.get('note_content'),
            },
            this
        );
        // Add the WYSIWYG editor into the input container
        this.$('.js-annotation-input-container').append(this.annotationInput.$el);
        this.registerChildView(this.annotationInput);

        this.modus = 'edit';

        // only annotation's author can remove it
        if (Backbone.Model.user.get('id') === this.model.get('user_id')) {
            this.addSvelteChildView(
                '.js-remove-annotations',
                Button,
                {
                    label: window.i18n.gettext('Remove annotation'),
                    theme: 'secondary',
                    inline: true,
                    callback: this.onRemoveAnnotation
                }
            );
        }
    },

    /*
         #
         #    Logic functions, below
         #
         */

    onSaveAnnotation() {

        var newNoteData = {
            note_content: this.annotationInput.inputField.getContent(),
            group_id: this.annotationInput.savePath.group_id,
            chapter_id: this.annotationInput.savePath.chapter_id,
            section_id: this.annotationInput.savePath.section_id,
            activity_id: this.annotationInput.savePath.activity_id,
            task_group_id: this.annotationInput.savePath.task_group_id,
            task_group_sequence: this.annotationInput.savePath.task_group_sequence
        };

        if (this.annotationInput.savePath.activity_id !== 0) {

            // Request the information for this activity
            this.activity_model = new ActivityModel({
                id: this.annotationInput.savePath.activity_id
            }, {
                simple: true
            })

            // For this annotation get Activity information
            this.activity_model.fetch({
                success: this.onLoadActivity,
                error: this.onErrorLoadingActivity
            });
        }

        this.model.save(newNoteData, {
            success: () => {

                this.model.set(newNoteData);

                if (this.annotationInput.savePath.activity_id !== 0) {
                    this.onSavedAndLoaded();
                } else {
                    this.annotationSaved();
                }
            }
        });
    },

    onLoadActivity(model, response) {
        this.model.set('Activity', response.Activity);
        this.onSavedAndLoaded();
    },

    onSavedAndLoadedCounter: 0,

    onSavedAndLoaded() {
        this.onSavedAndLoadedCounter++;
        if (this.onSavedAndLoadedCounter === 2) {
            this.annotationSaved();
        }
    },

    onErrorLoadingActivity() {

    },

    onRemoveAnnotation() {
        Backbone.View.layout.openConfirmStatus(
            window.i18n.gettext('Are you sure you want to remove this annotation?'),
            this.removeAnnotation,
            () => {},
            window.i18n.gettext('Remove')
        )
    },

    removeAnnotation() {
        // Check if the rights are correct to remove an annotation
        if (Backbone.Model.user.get('is_teacher') || this.model.get('user_id') === Backbone.Model.user.get('id')) {
            Backbone.View.sidebar.closeSidebar()

            // Remove from collection
            Backbone.Collection.groups.get(this.model.get('group_id')).annotations.remove(this.model);

            // Delete this model (will call a DELETE)
            this.model.destroy();
        }
    },

    annotationSaved() {
        // Close the sidebar
        Backbone.View.sidebar.closeSidebar();

        // Show a message that the annotation has been saved
        Backbone.View.layout.openStatus(
            window.i18n.gettext('The annotation has been saved succesfully.'),
            'success'
        );
    }

});
