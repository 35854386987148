import Styles from './DatePicker.scss';

import Template from './DatePicker.hbs';
import Button from 'views/components/button/Button'
import CurrentWeekTemplate from 'views/components/datePicker/parts/CurrentWeek.hbs'
import CurrentDayTemplate from 'views/components/datePicker/parts/CurrentDay.hbs'
import CalendarTemplate from 'views/components/datePicker/parts/Calendar.hbs';

export default class DataPicker extends BaseView {

    get events() {
        return {
            'click .js-date-label': 'onClickToggleCalendar',
            'click .js-prev-month': 'prevMonth',
            'click .js-next-month': 'nextMonth',
            'click .js-week-item': 'onClickCalendarItem',
        }
    }

    get disabled() {
        return this._disabled
    }

    set disabled(value) {
        this._disabled = value
        this.el.classList.toggle(Styles['date-picker--disabled'], value)
        if (value) {
            this.el.querySelector('.js-date-label').removeAttribute('tabindex')
        } else {
            this.el.querySelector('.js-date-label').tabIndex = 0
        }
        this.previousCalendarButton?.toggle(!value)
        this.nextCalendarButton?.toggle(!value)
    }

    get isOpen() {
        return this._isOpen
    }

    set isOpen(shouldOpen) {
        if (!this.disabled) {
            this._isOpen = shouldOpen
            this.el.classList.toggle(Styles['date-picker--open'], shouldOpen)
            if (shouldOpen) {
                $(document).on('click', this.onClickDocument)
                this.renderCalendar()
            } else {
                $(document).off('click', this.onClickDocument)
            }
        }
    }

    // Determines what month is shown in the calendar view.
    get viewDate() {
        // Default to showing selected date if no view date has been specified yet.
        if (this._viewDate) {
            return this._viewDate
        }
        return this.selectedDate.isValid() ? this.selectedDate : new dayjs()
    }

    // Value can be a dayjs object or anything that can be parsed by dayjs into a valid date.
    set viewDate(value) {
        const date = new dayjs(value)
        this._viewDate = this.clampDate(date.isValid() ? date : new dayjs())

        if (this.isOpen) {
            this.renderCalendar()
        }
    }

    get selectedDate() {
        return this._selectedDate
    }

    // Value can be a dayjs object or anything that can be parsed by dayjs into a valid date.
    set selectedDate(value) {
        const previousSelectedDate = this.selectedDate?.clone()
        if (this.selectionMode === 'week') {
            // Default to current date if no valid date was given for week selection mode.
            const weekDate = new dayjs(value)
            this._selectedDate = weekDate.isValid() ? weekDate : new dayjs()
        } else {
            this._selectedDate = new dayjs(value)
        }
        this._selectedDate = this.clampDate(this.selectedDate)
        if (previousSelectedDate && !previousSelectedDate.isSame(this.selectedDate)) {
            this.trigger('change:date', this.selectedDate)
        }

        if (this.selectionMode === 'week') {
            const startOfWeek = this.selectedDate.startOf('isoWeek')
            const endOfWeek = this.selectedDate.endOf('isoWeek')
            this.el.querySelector('.js-date-label').innerHTML = CurrentWeekTemplate({
                Styles,
                endOfWeek: endOfWeek.format('D MMM'),
                startOfWeek: startOfWeek.month() === endOfWeek.month()
                    ? startOfWeek.format('D')
                    : startOfWeek.format('D MMM'),
                weekNumber: this.selectedDate.isoWeek()
            })
        } else {
            this.el.querySelector('.js-date-label').innerHTML = CurrentDayTemplate({
                Styles,
                currentDay: this.selectedDate.isValid() ?
                    this.selectedDate.local().format('dd D MMM YYYY') :
                    window.i18n.gettext('No date selected')
            })
        }

        if (this.isOpen) {
            this.renderCalendar()
        }
    }

    get selectionRange() {
        return this._selectionRange
    }

    set selectionRange({min, max}) {
        if (min) {
            const minDate = new dayjs(min)
            if (minDate.isValid()) {
                this._selectionRange.min = this.selectionMode === 'week' ? minDate.startOf('isoWeek') : minDate
            }
        }
        if (max) {
            const maxDate = new dayjs(max)
            if (maxDate.isValid()) {
                this._selectionRange.max = this.selectionMode === 'week' ? maxDate.endOf('isoWeek') : maxDate
            }
        }
    }

    /**
     * Datepicker Component
     * offers user an view to view and set dates on a calendar paginated in months.
     *
     * @param {str} selectionMode                       valid values are 'week' or 'day'
     * @param {str|dayjs|Date|undefined} selectedDate   selected date; can be any value that can be parsed by dayjs
     * @param {object} selectionRange                   object containing a 'min' and/or 'max' key with a date that
     *                                                  can be parsed by dayjs. If given, view and selection dates
     *                                                  are constrained to these minimum and/or maximum dates.
     * @param {boolean} disabled                        Set disabled state
     * @param {string} label                            Label to show above date input
     */
    initialize({
        selectionMode = 'week',
        selectedDate = null,
        selectionRange = {},
        disabled = false,
        label = '',
    }) {

        this._disabled = false
        this._isOpen = false
        this._selectedDate = new dayjs()
        this._selectionRange = {min: null, max: null}

        this.selectionMode = selectionMode

        _.bindAll(
            this,
            'prevWeek',
            'nextWeek',
            'renderCalendar',
            'onClickDocument',
        );

        this.setElement(Template({
            Styles,
            selectionModeStyle: Styles[`date-picker--select-${selectionMode}`],
            selectDay: selectionMode === 'day',
            label,
        }))

        if (selectionMode === 'week') {

            this.previousCalendarButton = this.addChildView(new Button({
                icon: 'arrow-left',
                title: window.i18n.gettext('Previous week'),
                callback: this.prevWeek,
                theme: 'secondary',
            }), '.js-previous-button')

            this.nextCalendarButton = this.addChildView(new Button({
                icon: 'arrow-right',
                title: window.i18n.gettext('Next week'),
                callback: this.nextWeek,
                theme: 'secondary',
            }), '.js-next-button')
        }

        this.disabled = disabled
        this.selectionRange = selectionRange
        this.selectedDate = selectedDate
    }

    /**
     * renderCalendar
     *
     * This method can be called to (re)render the calendar element. It will
     * calculate a full calendar with days and weeks per month using dayjs
     *
     */
    renderCalendar() {

        // Get the start of the month and then the first day of that week. This will allow us
        // to navigate for example to 1 december, which is a saturday and then to the monday of
        // that week, which will give us the start of our calendar (26 november).
        const start = this.viewDate.startOf('month').startOf('isoWeek')

        // Get the end of the month, the same way as we've gotten the start of the week.
        const end = this.viewDate.endOf('month').endOf('isoWeek')

        // To keep the days sorted correctly (so that 01 doesn't come before 30) use an array
        // to store all the weeks in an array called calendar. It will contain every week and
        // every week will contain every day.
        const calendar = []

        // To keep all the dates seperated per week we need to create an object containing
        // the information per week. It needs to be an object so we can use the week number
        // as the key to request the data.
        const calendarPerWeek = {}

        // Loop from start until end, so we can build the array of the calendar
        for (let m = new dayjs(start); m.isBefore(end); m = m.add(1, 'days')) {

            // Set the week number and the year that week belongs to
            const weekNumber = m.isoWeek();
            const weekStartDate = m.startOf('isoWeek').format('YYYY-MM-DD');

            if (!calendarPerWeek[weekNumber]) {
                calendarPerWeek[weekNumber] = [];
                calendar.push({
                    weekNumber,
                    weekStartDate,

                    // Add the reference the the array for this week, which
                    // will be filled outside of this if statement.
                    dates: calendarPerWeek[weekNumber]
                });
            }

            // Fill the array with days, which will be referenced in the calendar
            // array in the if statement above.
            calendarPerWeek[weekNumber].push({
                day: m.format('DD'),
                month: m.format('MM'),
                monthText: m.format('MMM'),
                isWeekend: m.day() === 0 || m.day() === 6,
                isOutsideRange:
                    this.selectionRange.min?.isAfter(m, 'day') ||
                    this.selectionRange.max?.isBefore(m, 'day'),
            });
        }

        // Get the days of the week, starting with monday
        const daysInWeek = dayjs.weekdaysMin().slice(1);
        daysInWeek.push(dayjs.weekdaysMin()[0]);

        this.el.querySelector('.js-calendar').innerHTML = CalendarTemplate({
            Styles,
            calendar,
            daysInWeek,
            weekNumber: this.selectedDate?.isoWeek(),
            currentYear: this.viewDate.format('GGGG'),
            currentMonth: this.viewDate.format('MM'),
            currentMonthText: this.viewDate.format('MMMM'),
            isMinMonth: this.selectionRange.min?.isSame(this.viewDate, 'month'),
            isMaxMonth: this.selectionRange.max?.isSame(this.viewDate, 'month'),
        })

        if (ISMOBILE) {
            this.fixViewportBreakout();
        }

        if (this.selectedDate) {
            this.highlightSelectedDate()
        }
    }

    highlightSelectedDate() {
        if (this.selectedDate && this.viewDate.isSame(this.selectedDate, 'month')) {
            const element = this.el.querySelector(
                `[data-day="${this.selectedDate.format('DD')}"]:not([data-disabled])`
            )
            if (element) {
                element.dataset.selectedDay = ''
            }
        }
    }

    /**
     * fixViewportBreakout
     *
     * This method will check if the calendar is outside of the
     * viewport. If this is the case it will correct the position
     * to push the calendar inside the viewport.
     *
     */
    fixViewportBreakout() {
        var calendarHolder = this.$('.js-calendar');

        if (_.size(calendarHolder)) {

            calendarHolder.css('marginLeft', '');

            var offset = calendarHolder.offset();
            var leftOffset = offset && offset.left;

            if (leftOffset && leftOffset < 0) {
                calendarHolder.css('marginLeft', leftOffset * -1);
            } else if (leftOffset && (leftOffset + calendarHolder.outerWidth(true)) > window.innerWidth) {
                calendarHolder.css(
                    'marginLeft',
                    window.innerWidth - (leftOffset + calendarHolder.outerWidth(true))
                );
            }
        }
    }

    /**
     * onClickToggleCalendar
     *
     * This method will be called when the user clicks on the label. It
     * will toggle the openState variable and use the to open of close
     * the calendar.
     *
     */
    onClickToggleCalendar() {
        this.isOpen = !this.isOpen
    }

    /**
     * onClickDocument
     *
     * This method will be called when the 'document' receives a click event.
     * It will check if the clicked target was made on this view or a child
     * of this view. If not it will close the calendar.
     *
     * @param  {Object} e   Event object
     */
    onClickDocument(e) {

        // Check if target of click isn't the datepicker nor a descendant of the datepicker
        if (!this.$el.is(e.target) && this.$el.has(e.target).length === 0) {
            this.isOpen = false
        }
    }

    /**
     * When the user clicks a week or specific day on the calendar, set a new selected date
     * depending on the date selection mode.
     *
     * @param {Event} e click event
     */
    onClickCalendarItem(e) {
        switch (this.selectionMode) {
            case 'week':
                this.onClickWeekItem(e)
                break
            case 'day':
                this.onClickDayItem(e)
                break
        }
    }

    onClickWeekItem(e) {
        this.selectedDate = new dayjs(e.currentTarget.dataset.weekstartdate, 'YYYY-MM-DD')
    }

    onClickDayItem(e) {
        if (!e.target.dataset.day || 'disabled' in e.target.dataset
        ) {
            return
        }
        const day = parseInt(e.target.dataset.day, 10)
        this.selectedDate = this.viewDate.clone().date(day)
    }

    /**
     * nextWeek
     *
     * This method will be called when the user clicks on the next week.
     * It will set the selected date to the next week after the current one and
     * set the viewDate to that same date to keep it view.
     *
     */
    nextWeek() {
        this.selectedDate = this.selectedDate.add(1, 'week')
        this.viewDate = this.selectedDate
    }

    /**
     * prevWeek
     *
     * This method will be called when the user clicks on the previous week.
     * It will set the selected date to the previous week after the current one and
     * set the viewDate to that same date to keep it view.
     *
     */
    prevWeek() {
        this.selectedDate = this.selectedDate.subtract(1, 'week')
        this.viewDate = this.selectedDate
    }

    /**
     * nextMonth
     *
     * This method will be called when the user clicks on the next month.
     * This will advance the calendar page by one month without changing
     * the selected date.
     *
     * @param  {Event} e   Event object
     */
    nextMonth(e) {
        this.viewDate = this.viewDate.add(1, 'month')

        // Prevent onClickDocument from being triggered. Since we're
        // rerendering the calendar, the clicked target isn't a descendant
        // of the calendar anymore.
        this.stopAllEvents(e);
    }

    /**
     * prevMonth
     *
     * This method will be called when the user clicks on the next month.
     * This will revert the calendar page by one month without changing
     * the selected date.
     *
     * @param  {Event} e   Event object
     */
    prevMonth(e) {
        this.viewDate = this.viewDate.subtract(1, 'month')

        // Prevent onClickDocument from being triggered. Since we're
        // rerendering the calendar, the clicked target isn't a descendant
        // of the calendar anymore.
        this.stopAllEvents(e);
    }

    /**
     * Constrain a given date between a minimum and/or maximum date, if given/
     *
     * @param {dayjs} inputDate     a given date
     * @returns {dayjs}             constrained date object
     */
    clampDate(inputDate) {
        if (this.selectionRange.min) {
            inputDate = dayjs.max(inputDate, this.selectionRange.min)
        }
        if (this.selectionRange.max) {
            inputDate = dayjs.min(inputDate, this.selectionRange.max)
        }
        return inputDate
    }

    /**
     * onDestroy
     *
     * This method will be called when this view is destroyed. It will clean
     * up the event listeners that where set during the use of this component
     *
     */
    onDestroy() {
        $(document).off('click', this.onClickDocument);
    }

}
