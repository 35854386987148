var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"contextUrl") : depth0), depth0))
    + "\" target=\"_blank\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"not-found") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias2,"warning",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":37}}})) != null ? stack1 : "")
    + " "
    + alias1(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias2,"Can't find a link related to this source.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":6,"column":38},"end":{"line":6,"column":88}}}))
    + "\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"metadata") : stack1), depth0))
    + "\">\n                <h1>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":71}}})) != null ? stack1 : "")
    + "</h1>\n                <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"intro") : depth0), depth0))
    + "</p>\n            </div>\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"link-adress") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"source-4",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":38}}})) != null ? stack1 : "")
    + "\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"prettyLink") : depth0), depth0))
    + "\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"prettyLink") : depth0), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n    <div class=\"source-container\">\n        <a class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"source4") : stack1), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUrlData") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":100}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUrlData") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "        </a>\n    </div>\n</div>\n";
},"useData":true});