import Util from 'util/util';

export default Backbone.Model.extend({

    // Identify model by uniqid instead of the default id attribute.
    idAttribute: 'uniqid',

    initialize() {
        _.bindAll(this,
            'onSyncSuccess',
            'onSyncError',
            'onResponseRated',
            'hasSeenModelAnswer'
        );

        this.set({
            trySyncCount: 0
        }, {
            silent: true
        })

        // initialize values if model does not originate from responsesBuffer collection.
        if (this.collection !== Backbone.Model.user.responsesBuffer) {

            if (this.get('json_answer') !== undefined) {

                var json;

                try {
                    json = JSON.parse(this.get('json_answer'));
                } catch (e) {
                    json = this.get('json_answer');
                }

                this.set('json_answer', json);
            }

            if (this.get('score') === undefined) {
                this.set('score', -1);
            }
        }

    },

    hasSeenModelAnswer() {

        if (!ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD)) {
            return
        }

        // If the flag is already saved, do not re-send it to the backend
        if (this.get('has_seen_model_answer') === true) {
            return
        }

        const data = {
            type: 'view_model_answer',
            task_id: this.get('task_id'),
            activity_id: this.get('activity_id'),
            chapter_id: this.get('chapter_id'),
            practice_session: this.get('practice_session')
        }

        $.ajax({
            type: 'POST',
            url: '/events/add.json',
            xhrFields: {
                withCredentials: true
            },
            data,
            timeout: 10000,
            success: () => {
                /**
                 * this prevents re-sending this request to the backend after
                 * has_seen_model_answer is successfully saved in the backend
                 */
                this.set('has_seen_model_answer', true)
            }
        });
    },

    updateScore(score) {

        const data = {
            task_id: this.get('task_id'),
            student_id: this.get('user_id'),
            activity_id: this.get('activity_id'),
            practice_session: this.get('practice_session'),
            original: this.get('original'),
            score
        }

        // Capture an exception when we are about to send an incomplete response
        if (!data.task_id || !data.activity_id) {
            window.sentry.withScope(scope => {
                scope.setExtra('data', data);
                window.sentry.captureException('Response rating has missing data');
            })
        }

        this.set('sync', false);

        $.ajax({
            type: 'POST',
            url: '/responses/rate.json',
            xhrFields: {
                withCredentials: true
            },
            data,
            timeout: 10000,
            success: this.onResponseRated
        });

    },

    onResponseRated(response) {

        // The backend rated the response successfully, update the score
        if (response.status === 'success') {
            this.set({
                score: response.response.score,
            })
            return
        }

        // If the status is not 'success', the backend rejected the rating
        // Show an error message to the user
        Backbone.View.layout.openStatus(
            window.i18n.gettext('This response could not be rated.')
        )

        // Log to Sentry, with error code if present
        let message = 'Response rating failed'
        if (response.err_code) {
            message += ' with error ' + response.err_code
        } else {
            message += ' without error code'
        }
        window.sentry.withScope(scope => {
            scope.setExtra('response', response)
            window.sentry.captureMessage(message)
        })
    },

    onSyncSuccess(response) {
        delete this.activeRequest

        // For debugging answers, log when the add call succeeds
        if (Backbone.Model.user.getSetting('hasExtraLogging')) {
            window.sentry.withScope(scope => {
                scope.setExtra('response', response)
                scope.setExtra('task_id', this.get('task_id'))
                scope.setExtra('activity_id', this.get('activity_id'))
                scope.setExtra('user_id', this.get('user_id'))
                window.sentry.captureMessage('exta logging: add call succeeded, status ' + response.status)
            })
        }

        // Handle predefined backend errors
        if (response.status === 'error') {

            // Bad request, probably missing data
            if (response.err_code === 18400) {

                // Remove the response from responses buffer and local storage.
                Backbone.Model.user.responsesBuffer.removeFromBuffer(this)

                // Attempt to sync next oldest item in the buffer to the server
                Backbone.Model.user.responsesBuffer.syncResponseToServer()

                // Log event to Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('response', response)
                    scope.setExtra('task_id', this.get('task_id'))
                    scope.setExtra('activity_id', this.get('activity_id'))
                    scope.setExtra('user_id', this.get('user_id'))
                    window.sentry.captureException('Bad request while saving response')
                })

                // The response is for another user
            } else if (response.err_code === 18403) {

                // When syncing the response we already filter on user, so this probably means that
                // Backbone.Model.user.id is out of sync with the backend.
                // Therefore, check if the session is still valid
                window.app.checkStatus()

                // Log event to Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('response', response)
                    scope.setExtra('task_id', this.get('task_id'))
                    scope.setExtra('activity_id', this.get('activity_id'))
                    scope.setExtra('user_id', this.get('user_id'))
                    window.sentry.captureMessage('Response sent for wrong user')
                })

                // The response is for a task that has been deleted
            } else if (response.err_code === 18404) {

                // Remove the response from responses buffer and local storage.
                Backbone.Model.user.responsesBuffer.removeFromBuffer(this)

                // Attempt to sync next oldest item in the buffer to the server
                Backbone.Model.user.responsesBuffer.syncResponseToServer()

                // Log event to Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('response', response)
                    scope.setExtra('task_id', this.get('task_id'))
                    scope.setExtra('activity_id', this.get('activity_id'))
                    scope.setExtra('user_id', this.get('user_id'))
                    window.sentry.captureMessage('Response for a deleted task')
                })

                // The response was submitted after the deadline has expired
            } else if (response.err_code === 18405) {

                Backbone.View.layout.openStatus(
                    window.i18n.gettext('The deadline has expired. Your answer was not saved.')
                )

                // Remove the response from responses buffer and local storage.
                Backbone.Model.user.responsesBuffer.removeFromBuffer(this)

                // Attempt to sync next oldest item in the buffer to the server
                Backbone.Model.user.responsesBuffer.syncResponseToServer()

                // Log event to Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('response', response)
                    scope.setExtra('task_id', this.get('task_id'))
                    scope.setExtra('activity_id', this.get('activity_id'))
                    scope.setExtra('user_id', this.get('user_id'))
                    window.sentry.captureMessage('Response for an activity after deadline has expired')
                })

                // The exam response is illegal, because the exam was already closed
            } else if (response.err_code === 18406) {

                // Remove the response from responses buffer and local storage.
                Backbone.Model.user.responsesBuffer.removeFromBuffer(this)

                // Attempt to sync next oldest item in the buffer to the server
                Backbone.Model.user.responsesBuffer.syncResponseToServer()

                // Log event to Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('response', response)
                    scope.setExtra('task_id', this.get('task_id'))
                    scope.setExtra('activity_id', this.get('activity_id'))
                    scope.setExtra('user_id', this.get('user_id'))
                    window.sentry.captureMessage('Exam response after closing the session')
                })

                // A newer response has already been saved
            } else if (response.err_code === 18408) {

                // Log event to Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('response', response)
                    scope.setExtra('task_id', this.get('task_id'))
                    scope.setExtra('activity_id', this.get('activity_id'))
                    scope.setExtra('user_id', this.get('user_id'))
                    scope.setExtra('response_uniqid', this.get('uniqid'))
                    scope.setExtra('client_time_created', this.get('client_time_created'))
                    scope.setExtra('local storage', window.localStorage)
                    scope.setExtra('json_answer', this.get('json_answer'))
                    scope.setExtra('responses_rev_id', this.get('responses_rev_id'))
                    scope.setTag(
                        'templateId',
                        Backbone.Model.user?.currentOpenActivity?.tasks?.get(this.get('task_id'))?.get('template_id')
                        || '?'
                    )
                    // Log listeners associated with this model. Normally there should only be 1 event listener.
                    scope.setExtra('listeners', Object.keys(this._listeners || []))
                    window.sentry.captureMessage('Newer response already saved')
                })

                // Remove the response from responses buffer and local storage.
                Backbone.Model.user.responsesBuffer.removeFromBuffer(this)

                // Attempt to sync next oldest item in the buffer to the server
                Backbone.Model.user.responsesBuffer.syncResponseToServer()

                // There is an error we do not recognize
            } else {

                // Log event to Sentry
                window.sentry.withScope(scope => {
                    scope.setExtra('response', response)
                    scope.setExtra('task_id', this.get('task_id'))
                    scope.setExtra('activity_id', this.get('activity_id'))
                    scope.setExtra('user_id', this.get('user_id'))
                    window.sentry.captureException('Unknown error code while saving responses')
                })

            }

            // The backend successfully saved the response
        } else if (response.status === 'success') {

            // Update the saved state, so that the user can see the response is saved on the server
            this.set('saved_state', 'server');

            // In case this response originates from local storage instead of a current activity responses
            // collection, also update the saved_state flag of the model already there to indicate the response
            // for this task has been updated.
            if (this.collection === Backbone.Model.user.responsesBuffer) {

                if (
                    Backbone.Model.user.currentOpenActivity &&
                    Backbone.Model.user.currentOpenActivity.responses &&
                    Backbone.Model.user.currentOpenActivity.responses.has(this.id)
                ) {
                    Backbone.Model.user.currentOpenActivity.responses.get(this.id).set({
                        saved_state: 'server'
                    })
                }
            }

            // Update attributes of model with response from the server eg. "score" or "has_seen_model_answer".
            this.set(response.response);

            // Reset failed attempts
            Backbone.Model.user.attempts = 1

            // If the 'offline' status message is present, close it
            if (Backbone.View.layout.getStatusMessage()?.extraOptions.isOfflineStatusMessage) {
                Backbone.View.layout.closeStatus()
            }

            // Remove the response from responses buffer and local storage.
            Backbone.Model.user.responsesBuffer.removeFromBuffer(this)

            // Attempt to sync next oldest item in the buffer to the server
            Backbone.Model.user.responsesBuffer.syncResponseToServer()

            // The request returned with a status other than 'error' or 'success' (should not happen)
        } else {

            // Log an error to Sentry
            window.sentry.withScope(scope => {
                scope.setTag('response status', response.status)
                scope.setTag('response message', response.message)
                scope.setExtra('response', response)
                scope.setExtra('task_id', this.get('task_id'))
                window.sentry.captureException('Add call without error or success message')
            })

        }

    },

    onSyncError(jqXHR, textStatus, errorThrown) {
        delete this.activeRequest

        // For debugging answers, log when the add call fails
        if (Backbone.Model.user.getSetting('hasExtraLogging')) {
            window.sentry.withScope(scope => {
                scope.setExtra('jqXHR', jqXHR)
                scope.setExtra('textStatus', textStatus)
                scope.setExtra('errorThrown', errorThrown)
                scope.setTag('statusCode', jqXHR.status)
                window.sentry.captureMessage('exta logging: add call failed')
            })
        }

        // If the XHR is not complete, tell the user there is a problem with the connection
        if (jqXHR.readyState < 4) {

            // Default message telling the user their answers will be synced.
            let message = window.i18n.gettext('Oops, your internet connection is lost. You can continue to work. Your answer is saved when you\'re connected again.');

            let messageType = 'offline';

            // When there is no local storage available
            // Inform user their response will not be saved until there is a connection again
            if (!Util.hasSupportForLocalstorage()) {
                message = window.i18n.gettext('Oops, your internet connection is lost. Your answers will not be saved until you\'re connected again.');
                messageType = 'warning';
            }

            // Show status message which retries saving when you click on it
            Backbone.View.layout.openStatus(
                message,
                messageType,
                {
                    noHide: true,
                    elementCallback: Backbone.Model.user.saveLocalStoredData,
                    isOfflineStatusMessage: true
                }
            )

            // Plan another sync when there is not one already planned
            if (!Backbone.Model.user.isSyncingResponsesPeriodic) {
                Backbone.Model.user.syncResponsesPeriodic()
            }

            // No user is logged in
        } else if (jqXHR.status === 401) {

            // Ask the user to log in
            Backbone.View.layout.openReEnterPasswordModal();

            // The answer is too large to save
        } else if (jqXHR.status === 413) {

            // Remove the response from responses buffer and local storage.
            Backbone.Model.user.responsesBuffer.removeFromBuffer(this)

            // Attempt to sync next oldest item in the buffer to the server
            Backbone.Model.user.responsesBuffer.syncResponseToServer()

            Backbone.View.layout.openStatus(
                window.i18n.gettext('This response is too large to save.'),
                'error'
            );

            window.sentry.withScope(scope => {
                scope.setExtra('jqXHR', jqXHR)
                scope.setExtra('json_answer', (JSON.stringify(this.get('json_answer')) || '').substring(0, 5000))
                scope.setExtra('responses_rev_id', this.get('responses_rev_id'))
                scope.setExtra('json_answer_length', (JSON.stringify(this.get('json_answer')) || '').length)
                scope.setTag(
                    'templateId',
                    Backbone.Model.user?.currentOpenActivity?.tasks?.get(this.get('task_id'))?.get('template_id')
                    || '?'
                )
                window.sentry.captureException('Response too large to save (413)')
            })

            // Unexpected errors, probably server side such as 502
            // Log the error and try again
        } else {

            const error = {
                data: this.attributes,
                errorThrown,
                textStatus
            };

            window.sentry.withScope(scope => {
                scope.setExtra('errorData', error);
                scope.setExtra('textStatus', textStatus);
                scope.setTag('errorDescription', jqXHR.responseText);
                scope.setTag('statusCode', jqXHR.status);
                scope.setTag('readyState', jqXHR.readyState);
                scope.setTag('hasLocalStorage', Util.hasSupportForLocalstorage());
                window.sentry.captureException(new Error('An error occured while saving responses. Status code: ' + jqXHR.status));
            });

            // Plan another sync when there is not one already planned
            if (!Backbone.Model.user.isSyncingResponsesPeriodic) {
                Backbone.Model.user.syncResponsesPeriodic()
            }

        }

        // Increase the number of attempts and log it when it reaches 10
        this.set({
            trySyncCount: this.get('trySyncCount') + 1
        });
        if (this.get('trySyncCount') === 10) {
            window.sentry.withScope((scope) => {
                scope.setExtra('taskId', this.get('task_id'));
                scope.setExtra('trySyncCount', this.get('trySyncCount'));
                scope.setExtra('localstorage', window.localStorage)
                scope.setTag('textStatus', textStatus);

                scope.setTag(
                    'templateId',
                    Backbone.Model.user.currentOpenActivity &&
                    Backbone.Model.user.currentOpenActivity.tasks &&
                    Backbone.Model.user.currentOpenActivity.tasks.get(this.get('task_id'))?.get('template_id')
                )

                scope.setTag('hasLocalStorage', Util.hasSupportForLocalstorage());
                scope.setTag('statusCode', jqXHR.status);
                window.sentry.captureException(new Error('Response sync took too many tries [2.0]'));
            });
        }
    },

    sync() {

        Backbone.Model.user.responsesBuffer.addToBuffer(this)

        this.doAjaxCall()

    },

    setUniqIdentifier() {
        this.set({
            client_time_created: new Date().getTime(),
            uniqid: String.fromCharCode(
                Math.floor(
                    Math.random() * 11 + 72
                )
            ) + Math.floor(
                Math.random() * 1000000
            )
        })
    },

    doAjaxCall() {
        this.activeRequest = $.ajax({
            type: 'POST',
            url: this.get('is_in_exam') ?
                '/responses/exam_add.json' : '/responses/add.json',
            xhrFields: {
                withCredentials: true
            },
            data: this.attributes,
            timeout: 10000,
            success: this.onSyncSuccess,
            error: this.onSyncError
        });

        // For debugging answers, log when the add call is triggered
        if (Backbone.Model.user.getSetting('hasExtraLogging')) {
            window.sentry.withScope(scope => {
                scope.setExtra('attributes', this.attributes)
                scope.setExtra('localStorage', window.localStorage)
                window.sentry.captureMessage('exta logging: student did add call')
            })

            // Log when answer is null, empty string, empty array or empty object
            const answer = this.attributes.json_answer
            if (!answer ||
                (typeof answer === 'string' && answer.trim().length === 0) ||
                (typeof answer === 'object' && Object.keys(answer).length === 0)
            ) {
                window.sentry.withScope(scope => {
                    scope.setExtra('attributes', this.attributes)
                    scope.setExtra('localStorage', window.localStorage)
                    window.sentry.captureMessage('exta logging: student sent empty answer')
                })
            }
        }
    },

    syncFromLocalStorage() {
        this.doAjaxCall();
    },

}, {
    type: 'response'
});
