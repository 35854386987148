import TreeView from 'views/components/treeView/TreeView';

class TheoryTreeModel extends Backbone.Model {

    #type = 'theory';

    getParent() {
        return this.parent;
    }

    getChildren() {
        return this.children;
    }

    getSiblings() {
        return this.collection;
    }

    getCheckedIds() {

        let checkedIds = this.get('checked')
            ? [this.id]
            : [];

        return this.children.models.reduce(
            (checkedIds, child) => {
                if (child.get('checked')) {
                    checkedIds.push(child.id)
                }
                return checkedIds;
            },
            checkedIds
        )
    }
}
export default class SelectTheoryModal extends BaseView {

    get selectedTheory() {
        return Array.from(this._checkedModels);
    }

    initialize({ parentView }) {

        this._checkedModels = new Set();

        this.parentView = parentView;

        const rootCollection = this.createTreeStructure(this.parentView.theoryBranches);

        this.treeView = new TreeView({
            rootCollection,
            isSelectable: true,
            allClosedByDefault: true
        });

        this.registerChildView(this.treeView);

        // Create the view, passing the styling with it
        this.setElement(this.treeView.el);
    }

    createTreeStructure(base) {

        const treeCollection = new Backbone.Collection();

        _.each(base, branch => {
            branch.index = ++branch.sequence;

            const branchModel = new TheoryTreeModel(branch);
            branchModel.children = new Backbone.Collection();
            branchModel.children.comparator = this.parentView.theoryCollection.comparator;

            treeCollection.add(branchModel);

            _.each(branch.children, subBranch => {
                subBranch.index = `${branch.index}.${++subBranch.sequence}`;

                const subBranchModel = new TheoryTreeModel(subBranch);
                subBranchModel.parent = branchModel;

                this.listenTo(
                    subBranchModel,
                    'change:isChecked',
                    this.toggleCheckedState
                );

                branchModel.children.add(subBranchModel);
            })
        });

        return treeCollection;
    }

    toggleCheckedState(model) {
        this._checkedModels[
            model.get('isChecked')
                ? 'add'
                : 'delete'
        ](model.id);
    }
}
