import Styles from './FullScreenVideo.scss';

import Template from './FullScreenVideo.hbs';
import Source13View from 'views/components/taskGroups/sources/source13/Source13';

export default class FullScreenVideo extends BaseView {

    initialize() {

        // Get the first element from the model, since this type of activity
        // always only has 1 single element.
        const sourceModel = this.model?.elements.at(0);

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
        }));

        // Add a new child
        this.addChildView(

            // Child of the type source13
            new Source13View({

                // Pass on the created model
                model: sourceModel,

                isVideoActivity: true
            }),

            // Add it to the following container
            '.js-video'
        );
    }

}
