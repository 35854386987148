import Styles from './StudentGrade.scss';

import Template from './StudentGrade.hbs';
var StudentGrade = BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     */
    initialize() {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        var scoreEl = this.$('.js-score');
        switch (this.model.get('score')) {
            case 0:
                scoreEl.addClass(Styles['grading-option--red']).text(window.i18n.gettext('Incorrect'));
                break;
            case 0.5:
                scoreEl.addClass(Styles['grading-option--orange']).text(window.i18n.gettext('Almost correct'));
                break;
            case 1:
                scoreEl.addClass(Styles['grading-option--green']).text(window.i18n.gettext('Correct'));
                break;
        }

    }

});

export default StudentGrade;
