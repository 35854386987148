import Styles from './TeacherCards.scss';

import Template from './TeacherCards.hbs';
import GroupCard from './groupCard/GroupCard';
import AddGroupCard from './addGroupCard/AddGroupCard';
import NewsCard from './newsCard/NewsCard';
import NewsList from './newsCard/newsList/NewsList';

export default BaseView.extend({

    initialize() {

        // Set the default element
        this.setElement(Template({Styles}));

        // Show news for all teachers except demo and exam product
        if (!Backbone.Model.user.get('is_demo') && !Backbone.Model.user.get('exam_product')) {
            this.renderNews()
        }

        this.hasFavoriteGroups = !!Backbone.Collection.groups.findWhere({ is_favorite: true })

        // Set headers for groups
        if (this.hasFavoriteGroups) {
            this.$('.js-favorite-header').text(window.i18n.gettext('My favorite groups'))

            if (Backbone.Collection.groups.findWhere({ is_favorite: false })) {
                this.$('.js-header').text(window.i18n.gettext('My other groups'))
            }
        } else {
            this.$('.js-header').text(window.i18n.gettext('My groups'))
        }

        // Render all group cards
        Backbone.Collection.groups.each((groupModel) => {
            this.renderGroupCard(groupModel)
        })

        // Add a card to create a group when user has no groups or only a demo group
        if (
        // Exclude demo users
            !Backbone.Model.user.get('is_demo') &&

                // Exclude the mobile version
                !ISMOBILE &&

                (
                    (
                        // Check if the user has no groups
                        Backbone.Collection.groups.length === 0
                    ) ||

                    // If user has a group, but it's a demo group
                    (
                        // Check if the user only has one group
                        Backbone.Collection.groups.length === 1 &&

                        // Check if the first group is a demo group by matching the name
                        /^Demo /.test(Backbone.Collection.groups.first().get('name'))
                    )
                )
        ) {
            // Show card that lets the user create a group
            this.addChildView(new AddGroupCard(), '.js-groups');
        }
    },

    renderNews() {
        const messagesCollection = new Backbone.Collection(this.model.get('news'))
        if (!messagesCollection.length) {
            return
        }

        messagesCollection.each((model) => {
            this.addChildView(
                new NewsCard({
                    model
                }),
                '.js-news'
            )

            window.statsTracker.trackEvent(
                'News',
                'Show message on home',
                model.id
            )
        })

        this.$('.js-news-all').on('click', () => {
            Backbone.View.sidebar.showSidebar(
                new NewsList(),
                window.i18n.gettext('All messages'),
                520
            )
        })

        window.statsTracker.trackEvent(
            'News',
            'Show messages on home',
            messagesCollection.length
        )
    },

    renderGroupCard(groupModel) {

        // Find the results data for this group. If not present, skip rendering this group.
        const groupInfo = this.model.get('last_groups').find(lastGroup => lastGroup.results.group_id === groupModel.id)
        if (!groupInfo) {
            Backbone.Model.user.updateCollections()
            window.sentry.withScope(scope => {
                scope.setExtra('group', groupInfo);
                window.sentry.captureMessage('data_for_home.json has group_id not in global GroupsCollection');
            });
            return
        }

        // Create new card
        const container = groupModel.get('is_favorite') ? this.$('.js-favorite-groups') : this.$('.js-groups')
        this.addChildView(new GroupCard({
            model: groupModel,
            score: groupInfo.results.score,
            sections: groupInfo.results.sections || [],
            activities: groupInfo.results.activities || [],
            weeks: groupInfo.studyplanner ? groupInfo.studyplanner.weeks : undefined
        }), container)
    },
});
