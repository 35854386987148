export default class DOMAIN {

    static get hostNames() {
        return document.location.hostname.split('.')
    }

    static get isBeta() {
        return (this.hostNames[0] === 'beta' && this.hostNames[1] === 'learnbeat')
            || (this.hostNames[0] === 'beta' && this.hostNames[1] === 'learnbe')
            || (this.hostNames[0] === 'beta-old' && this.hostNames[1] === 'learnbeat')
    }

    static get isLearnBeta() {
        return this.hostNames[1] === 'learnbeta'
    }

    static get isDevelopment() {
        return this.hostNames[1] === 'local'
    }

    static get isLive() {
        return !this.isBeta && !this.isLearnBeta && !this.isDevelopment
    }

}