import Styles from './LearningText.scss';

import Template from './LearningText.hbs';
import SourceView from 'views/components/taskGroups/sources/Source';

export default class SidebarLearningText extends BaseView {
    initialize({
        activityModel
    }) {

        _.bindAll(
            this,
            'renderLearningText'
        );

        this.setElement(Template({
            Styles
        }));

        this.activityModel = activityModel

        // Initially, render learning text that aren't additional.
        this.renderLearningText(false);

    }

    /**
     * renderLearningText
     *
     * Destroy any existing element views and create a new list of element views filtered
     * by if said elements are additional content or not.
     *
     */
    renderLearningText() {

        // Destroy any existing element views and clear array.
        this.destroyChildViewsOfInstance(SourceView)

        for (const model of this.collection) {
            this.addChildView(new SourceView({
                model,
                activityModel: this.activityModel,
                isLearningText: true,
            }), '.js-learning-text-content')
        }

    }

}
