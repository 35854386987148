export default class Score {

    // Convert a score between 0 and 1 inclusive to a color name
    static getColor(score) {

        if (!Number.isFinite(score) || score < 0 || score > 1) {
            return
        }

        score = Math.round(score * 100)

        if (score < 50) {
            return 'red'
        }

        if (score < 67) {
            return 'orange'
        }

        return 'green'
    }

}