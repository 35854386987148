import Styles from './ContentList.scss';

import Template from './ContentList.hbs';
import ListItem from 'views/components/taskGroups/selectContent/contentList/ContentListItem/ContentListItem.js';
import 'jquery-ui/ui/widgets/sortable';
import Spinner from 'views/components/spinner/Spinner';

export default BaseView.extend({

    initialize({
        collection,
        selectedCollection,
        selectDefault,
        isSortable,
        // on select callback
        callback,
        // only one item from this list can be selected
        selectSingleItem
    }) {

        this.bindAll([
            'onUpdateSortable',
            'setLoading'
        ])

        this.collection = collection
        this.selectedCollection = selectedCollection
        this.selectDefault = selectDefault
        this.isSortable = isSortable
        this.callback = callback
        this.selectSingleItem = selectSingleItem

        this.setElement(Template({
            Styles
        }))
    },

    // Show a loading state for the list
    setLoading() {
        this.$el.empty();
        const spinner = this.addChildView(new Spinner(), this.$el);
        spinner.el.classList.add(Styles['spinner'])
    },

    /**
     * createList
     *
     * Populate DOM with list item templates.
     */
    createList() {

        this.$el.empty();

        this.collection.each(model => {
            this.addChildView(new ListItem({
                model,
                isSortable: this.isSortable,
                callback: this.callback,
                collection: this.selectedCollection,
                selectSingleItem: this.selectSingleItem,
            }), this.$el)
        })

        // Init sortable if view allows it and collection is longer than 1 item.
        if (this.isSortable && this.collection.length > 1) {
            this.$el.sortable({
                update: this.onUpdateSortable,
                items: '.js-item',
                axis: 'y',
                cursor: 'move'
            });
        }

        // select first item by default
        if (this.selectDefault) {
            this.$('.js-item:first').trigger('click');
        }
    },

    /**
     * onUpdateSortable
     *
     * Sort collection based on order of appearance in the DOM.
     */
    onUpdateSortable() {
        const ordering = this.$el.sortable('toArray', { attribute: 'data-id' })

        ordering.forEach((selectedLearningTextId, index) => {
            const model = this.collection.get(selectedLearningTextId)
            model.set({ sequence: index })
        })

        this.collection.sort()

    }

});
