import Template from './Italic.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Italic extends Button {

    // Built-in TinyMCE command to set selection to italic
    command = 'Italic'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Italic');
    }

}
