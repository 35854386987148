import StudentModel from 'models/StudentModel';

export default class StudentsCollection extends Backbone.Collection {

    get model() {
        return StudentModel
    }

    preinitialize() {
        this.constructor.type = 'students'
    }

    addStudentToGroup(student) {

        // add student to groups.StudentCollection
        this.add(student);

        // add student to global StudentCollection
        Backbone.Collection.students.add(student);
    }

    deleteStudentFromClass(student, callback) {
        this.remove(student);
        $.post('/groups/remove_student_from_class/' +
            this.group_id + '/' +
            student.get('id') +
            '.json', {}, callback);
        callback();
    }

    getNext(student) {
        if (this.indexOf(student) + 1 < this.length) {
            return this.at(this.indexOf(student) + 1);
        }
    }

    getPrevious(student) {
        if (this.indexOf(student) - 1 >= 0) {
            return this.at(this.indexOf(student) - 1);
        }
    }

    comparator(studentA, studentB) {
        // Compare students by sortable_last_name + first_name using the sorting conventions of the present locale
        // by comparing the base letters (a ≠ b, a = å, a = A, A = Â),so students who's sortable names start with
        // an accented letter don't get sorted to the bottom of the list.
        return (
            studentA.last_name_first_name() || ''
        ).localeCompare(
            studentB.last_name_first_name(),
            window.app_version.language, {
                sensitivity: 'base'
            }
        )
    }

}
