import Styles from './ToolTip.scss';

import Template from './ToolTip.hbs';
export default BaseView.extend({

    initialize({ title, text, alignment = 'top', parentElement }) {

        _.bindAll(
            this,
            'open',
            'close'
        )

        this.setElement(Template({
            Styles,
            title,
            text,
            alignment: Styles['tooltip--alignment-' + alignment]
        }))

        parentElement.css('position', 'relative')
        parentElement.on('click', this.open)
    },

    open() {
        if (this.isOpen) {
            this.close()
            return
        }

        this.el.style.display = 'block'
        this.isOpen = true

        // Use a short timeout here to prevent the tooltip we just opened from closing again
        setTimeout(() => {
            $(document).on('click', this.close)
        }, 10)
    },

    close() {
        $(document).off('click', this.close)
        this.isOpen = false
        this.el.style.display = ''
    }

})
