import ReEnterPasswordForm from 'views/components/modals/reEnterPassword/reEnterPasswordForm/ReEnterPasswordForm'
import Modal from 'views/components/modals/Modal'

export default class ReEnterPassword extends Modal {

    initialize() {

        super.initialize({})

        this.open(ReEnterPasswordForm, {
            title: window.i18n.gettext('Session expired'),
            // Prevent modal from being dismissed.
            removeCloseButton: true,
        })

    }

}
