var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"analytics") : stack1), depth0))
    + "\">\n    <section>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"stat-block") : stack1), depth0))
    + "\">\n            <header>\n                <var class=\"js-students-made-count\"></var>\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"comment",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":37}}})) != null ? stack1 : "")
    + "\n            </header>\n            "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"students have given a response to this task.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":65}}}))
    + "\n        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"stat-block") : stack1), depth0))
    + " js-p-value-block\">\n            <header>\n                <var class=\"js-p-value\" data-empty=\""
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"P-value can only be calculated when there are 25 or more responses.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":13,"column":52},"end":{"line":13,"column":128}}}))
    + "\" data-p-value-label=\""
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Good level",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":13,"column":150},"end":{"line":13,"column":169}}}))
    + "\"></var>\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"star-rounded",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":42}}})) != null ? stack1 : "")
    + "\n            </header>\n            "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"p-value for this task.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":43}}}))
    + "\n        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"stat-block") : stack1), depth0))
    + "\">\n            <header>\n                <var class=\"js-students-access-count\"></var>\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"people",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":36}}})) != null ? stack1 : "")
    + "\n            </header>\n            "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"students have access to this task.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":55}}}))
    + "\n        </div>\n    </section>\n</div>\n";
},"useData":true});