// Make upload progress visible in the status message
function onProgressChange() {
    Backbone.View.layout.getStatusMessage()?.setProgress(this.get('progress'))
}

export default function(model) {

    // Make sure the progress change listener is only present when needed
    model.off('change:progress', onProgressChange, model)

    switch (model.get('status')) {
        case 'error':
            Backbone.View.layout.openStatus(
                model.get('errMessage') || window.i18n.gettext('Upload failed'),
                'error'
            );
            break;

        case 'loading':
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Uploading file...'),
                'loading',
                { noHide: true, hasProgress: true }
            );
            model.on('change:progress', onProgressChange, model)
            break;

        case 'processing':
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Processing...'),
                'loading',
                { noHide: true }
            );
            break;

        case 'success':
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Upload succeeded'),
                'success'
            );
            break;
    }
}
