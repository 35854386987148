import Styles from './Source13.scss';

import Template from './Source13.hbs';
import Util from 'util/util';
import YouTubeView from 'views/components/taskGroups/sources/source13/youtube/YouTube';
import NoContentTemplate from 'views/components/taskGroups/sources/source13/noContent.hbs';
import Digistudies from './digistudies/Digistudies.svelte';

// HTML5/YouTube video view
var Source13 = BaseView.extend({

    initialize({
        isDemo,
        isVideoActivity,
        portfolioStyling: isPortfolio,
        work_on,
    }) {

        this.work_on = work_on

        this.setElement(Template({
            Styles,
            isPortfolio,
            isVideoActivity,
            title: Util.renderContentSafely(this.model && this.model.get('title')),

            // the source is used for the video demo tour;
            // do not constrain the width of the video
            isDemo,
        }));

        if (this.model && _.size(this.model.get('source')) && (
            this.model.get('source').fileId || this.model.get('source').hash
        )) {
            _.defer(
                _.bind(
                    this.insertVideo,
                    this, {
                        isDemo,
                        isVideoActivity
                    }
                )
            );
        } else {
            this.$el.append(NoContentTemplate({
                Styles,
                isVideoActivity
            }));
        }

    },

    insertVideo({
        isVideoActivity,
        isDemo: openAndPlay
    }) {

        var videoElement = this.$('.js-video');

        var source = this.model.get('source');

        // In activities (exams) that require fullscreen view, disable the fullscreen feature of the video
        // to prevent collisions
        let disableFullscreen = false
        if (this.work_on && parseInt(this.work_on.model.getMetadata('fullscreen_enabled'))) {
            disableFullscreen = true
        }

        switch (source.type) {
            case 'upload':
                var controlsList = disableFullscreen ? ' controlsList="nofullscreen"' : ''

                // Template literal cannot be used here due to a bug in xgettext, the GNU tool for generating the
                // translation file used by Poedit. See: https://gitlab.gnome.org/GNOME/gnome-shell/issues/537#note_309678
                // eslint-disable-next-line prefer-template
                videoElement.html(
                    '<video src=\'/edu_files/open/' + source.fileId + '/' + source.fileIdHash + '\' controls' + controlsList + '></video>'
                );

                break;
            case 'digistudies':
                this.addSvelteChildView(
                    videoElement,
                    Digistudies,
                    {
                        fileId: source.fileId,
                        fileIdHash: source.fileIdHash,
                        isVideoActivity
                    }
                );
                break;
            case 'youtube':
                var youTubeView = new YouTubeView({
                    openAndPlay,
                    isVideoActivity,
                    videoHash: source.hash,
                    disableFullscreen
                });
                this.registerChildView(youTubeView);
                youTubeView.appendTo(videoElement);
                break;
        }

    }

});

export default Source13;
