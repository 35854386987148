var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "js-deadline";
},"3":function(container,depth0,helpers,partials,data) {
    return "tile__navigation__btn--locked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isTeacher.js")).call(alias1,{"name":"isTeacher","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":51}}})) != null ? stack1 : "")
    + " tile__navigation__btn "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isStudent.js")).call(alias1,{"name":"isStudent","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":131}}})) != null ? stack1 : "")
    + "\">\n    <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Deadline",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":27}}}))
    + "</span>\n    <strong>"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"deadline") : depth0), depth0))
    + "</strong>\n</div>";
},"useData":true});