import Styles from './KeyboardLayout.scss';

import Template from './KeyboardLayout.hbs';
import KeyGroup from 'views/components/keyboard/keyboardLayout/KeyGroup.hbs';
import Key from 'views/components/keyboard/keyboardLayout/Key.hbs';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'addKeyGroup',
            'addKey'
        );

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        // If keyboard has an input view with an toggleVirtualKeyboard method,
        // enable or disable the logic that hides the native on-screen keyboard
        // to not interfere with the virtual keyboard inside Learnbeat.
        // When there are no keys in the current KeyboardLayout and this is
        // Learnbeat Mobile, disable the virtual keyboard such that the native
        // on-screen keyboard can appear.
        if (
            ISMOBILE &&
            Backbone.View.Components.keyboard.inputView &&
            Backbone.View.Components.keyboard.inputView.toggleVirtualKeyboard
        ) {
            Backbone.View.Components.keyboard.inputView.toggleVirtualKeyboard(
                options.keyGroupList.length > 0
            );
        }

        _.each(options.keyGroupList, this.addKeyGroup);

        this.capsLock = false;
        this.capsLockKey = this.$('.js-key[data-cmd="CapsLock"]');

    },

    /**
     * addKeyGroup
     *
     * @param {Array} keyGroup Array with a collection of keys.
     */
    addKeyGroup(keyGroup) {
        var keyGroupID = this.$('.js-key-group').length;
        this.$el.append(KeyGroup({
            Styles,
            keyGroupID
        }));
        _.each(keyGroup, _.partial(this.addKey, _, keyGroupID));
    },

    /**
     * addKey
     *
     * @param {Object} key        Data object for the an invidual key.
     * @param {number} keyGroupID The ID of the current key group.
     */
    addKey(key, keyGroupID) {
        // Add the key to the keyGroup element with the keyGroupID.
        const columnStart = Math.floor(key.x) + 1
        const columnEnd = key.w ? Math.floor(key.x + key.w) + 1 : columnStart
        const isColumnOffset = key.x % 1 > 0

        this.$('.js-key-group[data-group=' + keyGroupID + ']').append(Key({
            Styles,
            key: key.key,
            cmd: key.cmd,
            shiftKey: key.shiftKey,
            shiftCmd: key.shiftCmd,
            columnStart,
            columnEnd,
            isColumnOffset,
            hasCustomWidth: columnStart !== columnEnd,
            row: key.y + 1,
            s: key.special
        }));
    },

    /**
     * setKeyActive
     *
     * Set a key to an actived mode.
     *
     * @param {HTMLElement} keyElement key
     * @param {boolean} active         is active
     */
    setKeyActive(keyElement, active) {
        keyElement.toggleClass(Styles['key--actived'], active);
    }

});
