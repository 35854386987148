import Styles from './Template6.scss';

import Template from './Template6.hbs';
import Item from 'views/components/taskGroups/tasks/template6/item/Item';
import 'jquery-ui/ui/widgets/sortable';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'saveAnswer',
            'showAnswer',
            'getCorrectAnswer',
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;

        this.renderTask()
    },

    /**
         * renderTask
         *
         * This method will render the template of the task. It will be
         * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
         * if only the answer view is necessary
         *
         */
    renderTask() {

        this.setElement(Template({
            Styles
        }));

        this.items = this.model.get('task_info_json').options;
        this.correctItems = this.model.get('task_info_json').correct_answer;
        this.answeredItems = this.taskView.response.get('json_answer');

        if (APPLICATION === 'author') {
            return
        }

        if (!_.isEmpty(this.answeredItems)) {
            this.items = this.answeredItems;
        }

        _.each(this.items, (item, index) => {
            this.addChildView(new Item({
                label: item,
                index,
            }), '.js-sortable')
        })

        this.$('.js-sortable').sortable({
            containment: this.$el,
            cursor: 'ns-resize',
            items: '.js-item',

            // NOTE changed this for desktop and mobile on 20-03-2018.
            // This fix will make it easier for draggable elements with more than 1 line of text to
            // switch positions with the top element. For more info:
            // https://api.jqueryui.com/sortable/#option-tolerance
            tolerance: 'pointer',
            handle: ISMOBILE ? '.js-handle-holder' : this.$el,
            start: this.onStart,
            stop: this.onStop,
            over: this.onHover,
            opacity: 1,
            update: this.saveAnswer,
            revert: 250,
            scrollSpeed: 10
        });

    },

    /**
         * onStart
         *
         * Triggered when the sorting starts
         *
         * @param  {Object} e  event object
         * @param  {Object} ui - jQuery ui object
         */
    onStart(e, ui) {
        ui.item.addClass(Styles['is-sorting']);
    },

    /**
         * onStop
         *
         * Triggered when the sorting stops
         *
         * @param  {Object} e  event object
         * @param  {Object} ui - jQuery ui object
         */
    onStop(e, ui) {
        ui.item.removeClass(Styles['is-sorting']);
    },

    /**
         * onHover
         *
         * Triggered when the element that is being dragged is inside a dropping position
         *
         * @param  {Object} e  event object
         * @param  {Object} ui - jQuery ui object
         */
    onHover(e, ui) {

        ui.placeholder.addClass(Styles.dropzone).css({
            visibility: 'visible'
        });

    },

    saveAnswer() {
        var ordering = this.$('.js-sortable').sortable('toArray');
        var savedAnswer = [];
        _.each(ordering, (index) => {
            savedAnswer.push(this.items[index]);
        })
        this.taskView.saveResponse(savedAnswer);
    },

    /**
     * Show the correct answer to the task.
     */
    showAnswer() {
        _.each(this.$('.js-item'), (itemElement, index) => {
            $(itemElement).find('.js-sequence').text(this.correctItems.indexOf(this.items[itemElement.id]) + 1);
            $(itemElement).find('.js-handle').hide();
            $(itemElement).find('.js-sequence').css('display', 'flex');
            if (APPLICATION !== 'author') {
                if (_.isEqual(this.items[itemElement.id], this.correctItems[index])) {
                    $(itemElement).addClass(Styles['item--correct']);
                } else {
                    $(itemElement).addClass(Styles['item--incorrect']);
                }
            }
        })

    },

    showAuthorAnswer() {
        _.each(this.model.get('task_info_json').correct_answer, (item, index) => {
            const itemView = this.addChildView(new Item({
                label: item,
                index,
            }), '.js-sortable')
            itemView.$('.js-sequence').text(index + 1).css('display', 'flex')
            itemView.$('.js-handle').hide()
        })
    },

    /**
         * hideAnswer
         *
         * Hide the correct answer to the task.
         */
    hideAnswer() {
        this.$('.js-item').removeClass(Styles['item--correct'] + ' ' + Styles['item--incorrect']);
        this.$('.js-item .js-sequence').empty().hide();
        this.$('.js-item .js-handle').show();
    },

    /**
         * getCorrectAnswer
         *
         * Generate summary of the correct answer to use above the list of student answers.
         *
         * @return {string} HTML blob to be inserted above the list of student answers.
         */
    getCorrectAnswer() {
        var answerString = '';
        _.each(this.model.get('task_info_json').correct_answer, (answer, key) => {
            answerString += '<span class="is-boxed">' + (key + 1) + '. ' + answer + '</span> ';
        })
        return answerString;
    },

    /**
         * getStudentAnswer
         *
         * Generate summary of the answer student has given for use in the list of student answers.
         *
         * @param  {Object} responseModel Student response data
         * @return {string}               HTML blob to be used for the student answer view.
         */
    getStudentAnswer(responseModel) {
        var answerString = '';
        if (responseModel.get('json_answer')) {
            var answers = responseModel.get('json_answer');
            _.each(answers, (answer, key) => {
                if (this.model.get('task_info_json').correct_answer[key] === answer) {
                    answerString += '<span class="is-boxed is-correct">' + (key + 1) + '. ' + answer + '</span> ';
                } else {
                    answerString += '<span class="is-boxed is-incorrect">' + (key + 1) + '. ' + answer + '</span> ';
                }
            })
        }
        return answerString;
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {
        _.invoke(this.getChildViewsOfInstance(Item), 'lock')
        this.$('.js-sortable').sortable('instance')?.disable()
    },

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {
        _.invoke(this.getChildViewsOfInstance(Item), 'unlock')
        this.$('.js-sortable').sortable('instance')?.enable()
    }

});
