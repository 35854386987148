import Styles from './ShareListModal.scss';

import Template from './ShareListModal.hbs';
import ToggleSwitch from 'views/components/toggleSwitch/ToggleSwitch.svelte';
import CopyInput from 'views/components/quickInput/copyInput/CopyInput.svelte'

export default class ShareListModal extends BaseView {
    /**
     *
     * @param {object} options Options object
     * @param {string} [options.shareLink] Shareable URL for list
     */
    initialize({
        isShared = false,
        changeShareLevel,
    }) {

        this.changeShareLevel = changeShareLevel;

        this.setElement(Template({
            Styles,
        }));

        this.addSvelteChildView('.js-toggle-share-div', ToggleSwitch, {
            callback: this.toggleShareLink.bind(this),
            isChecked: isShared,
        })

        this.addSvelteChildView(
            '.js-sharelink-input',
            CopyInput,
            {
                label: window.i18n.gettext('Copy link'),
                value: window.location.href,
            },
        )

        if (!isShared) {
            _.defer(() => {
                $('.js-sharelink-input').hide();
            });
        }

        this.isShared = isShared;
    }

    toggleShareLink() {
        if (this.isShared) {
            $('.js-sharelink-input').hide();
            this.isShared = false
        } else {
            $('.js-sharelink-input').show();
            this.isShared = true;
        }
        this.changeShareLevel(this.isShared ? 1 : 0);
    }

}
