import SettingsModel from 'models/SettingsModel';

export default Backbone.Collection.extend({

    model: SettingsModel,

    /**
     * This method will add a setting to this collection
     *
     * @param {string} key under which to store this setting
     * @param {string} value to store under this key
     * @param {boolean} isEncrypted wheter the value should be stored encrypted in the database
     * @returns {SettingsModel} created model
     */
    addSetting(key, value, isEncrypted = false) {

        this.url = '/users/settings_backend.json';

        var existingModel = this.findWhere({
            keyword: key
        });

        if (existingModel !== undefined) {
            existingModel.set({
                value,
                isEncrypted
            });
            existingModel.save();

            return existingModel;
        }

        var newModel = new SettingsModel({
            keyword: key,
            value,
            isEncrypted
        });

        this.create(newModel);
        return newModel;

    }
}, {
    type: 'settings'
});
