var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " open ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<details "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"detailsOpen") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":42}}})) != null ? stack1 : "")
    + " class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"details") : stack1), depth0))
    + "\">\n    <summary>\n        "
    + alias3(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias1,"I do not have an activation code",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":49}}}))
    + "\n        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias1,"arrow-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":32}}})) != null ? stack1 : "")
    + "\n    </summary>\n    <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"form") : stack1), depth0))
    + "\">\n        <p>"
    + alias3(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias1,"Order and get instant access to the learning material.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":74}}}))
    + "</p>\n        <div class=\"js-variants "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"variants") : stack1), depth0))
    + "\"></div>\n        <div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"ideal-button") : stack1), depth0))
    + "\">\n            <div class=\"js-order-btn\"></div>\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias1,"ideal",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":31}}})) != null ? stack1 : "")
    + "\n        </div>\n        <p>"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(alias1,"We will send the order confirmation with invoice for <strong>{0}</strong> to <strong>{1}</strong>.",(depth0 != null ? lookupProperty(depth0,"userFullName") : depth0),(depth0 != null ? lookupProperty(depth0,"userMail") : depth0),{"name":"__","hash":{},"data":data,"loc":{"start":{"line":13,"column":11},"end":{"line":13,"column":142}}})) != null ? stack1 : "")
    + "</p>\n        <p class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"fineprint") : stack1), depth0))
    + "\">\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(alias1,"By clicking the order button I place an order that requires payment and accept the <a href='https://static.learnbeat.nl/docs/gebruiksvoorwaarden_learnbeat_20180208.pdf' target='_blank'>user terms and conditions</a>.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":238}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(alias1,"{0} is supplied by Dedact BV, located at the 3e Binnenvestgracht 23L1, 2312 NR in Leiden. You can contact support on working days between 9:00 and 17:00 via <a href='mailto:support@dedact.nl'>support@dedact.nl</a> or <a href='tel:020-7009854'>020-7009854</a>. The legal cooling-off period of {0} is 14 working days. Are you not satisfied? Send an e-mail to <a href='mailto:support@dedact.nl'>support@dedact.nl</a> during the cooling-off period stating the invoice number. We will deactivate your license and refund the purchase amount. Terms and conditions apply. You can find our privacy policy <a href='https://static.learnbeat.nl/docs/25-05-2018/privacybijsluiter-Learnbeat-v1.0.pdf' target='_blank'>here</a>. Dedact BV is registered with the Chamber of Commerce under number 34392982 and the VAT number is 8224.36.590.B01.",(depth0 != null ? lookupProperty(depth0,"productName") : depth0),{"name":"__","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":861}}})) != null ? stack1 : "")
    + "\n        </p>\n    </div>\n</details>\n";
},"useData":true});