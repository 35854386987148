import Styles from './Toolbar.scss';

import Template from './Toolbar.hbs';
import ShapeList from 'util/ShapeLoader';
import FullscreenMode from 'util/FullscreenMode';
import Util from 'util/util'

export default BaseView.extend({

    // Events for this view
    events: {
        'click .js-back': 'onClickPrevious',
        'click .js-next': 'onClickNext',
        'click .js-exit': 'onClickExit',
        'click .js-author': 'onClickAuthor',
        'click .js-fullscreen': 'onClickFullscreen',
        'click .js-online-sidebar': 'onClickOnlineStatus'
    },

    initialize({ presentation }) {
        _.bindAll(
            this,
            'onKeyUp',
            'onIsFollowingPresentation',
            'onFullscreenChange',
            'setSlideIndex'
        );

        // Bind presentation parent to this view
        this.presentation = presentation;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            isTeacher: Backbone.Model.user.get('is_teacher'),
            isFullscreenPossible: FullscreenMode.checkIfFullscreenIsPossible()
        }));

        // Listen to keypresses to catch arrow left and right presses
        $(document).on('keyup', this.onKeyUp);

        // Listen to fullscreen mode changes on the document
        $(document).on(
            'webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange',
            this.onFullscreenChange
        );

        // Set default to zero
        this.setSlideIndex(0);
    },

    /**
     * setSlideIndex
     *
     * This function gets the index of the active slide compared to the total of taskgroups inside an activity.
     *
     * @param {Number} index    active slide index
     */
    setSlideIndex(index) {

        // Get the index of the taskgroup and number of total taskgroups inside the activity
        index = index + ' / ' + this.model.task_groups.length;

        // Set it
        this.$('.js-slide-index').text(index);
    },

    /**
     * onKeyUp
     *
     * Listen for keyup for left and right arrow keys for navigating
     * between task groups.
     *
     * @param  {jQuery.event} e     jQuery keyup event
     */
    onKeyUp(e) {

        // Do not respond when focus input element has focus, fullscreen is active or a modifier key is pressed.
        if (Util.shouldPreventDocumentLevelKeyEvent(e)) {
            return
        }

        if (e.keyCode === 37 || e.key === 'ArrowLeft' || e.keyCode === 33 || e.key === 'PageUp') {
            this.onClickPrevious();
        } else if (e.keyCode === 39 || e.key === 'ArrowRight' || e.keyCode === 34 || e.key === 'PageDown') {
            this.onClickNext();
        }

    },

    /**
     * OnClickPrevious
     *
     * This function is triggered when the user clicks on the
     * previous button.
     * It will navigate the user either to the previous phase of a task or the previous slide.
     *
     */
    onClickPrevious() {

        // Go to previous slide unless the back button is disabled
        if (this.buttonDisabled !== 'back') {
            this.presentation.setPrevious();

            // The next button was disabled because we were in the ending screen
            if (this.buttonDisabled === 'next') {

                // When we are moving back, we no longer need to disable it
                this.disableButton(false);
            }
        }

    },

    /**
     * OnClickNext
     *
     * This function navigates the user to the next phase of task
     * or the next slide.
     *
     */
    onClickNext() {

        // Go to next slide unless the forward button is disabled
        if (this.buttonDisabled !== 'next') {
            this.presentation.setNext();

            // The back button was disable because we are in the intro screen
            if (this.buttonDisabled === 'back') {

                // This is no longer needed because we are moving to the next slide
                this.disableButton(false);
            }
        }

    },

    /**
     * disableButton
     *
     * This function disables a navigation button.
     * Typically done in one of the summary screens where you reached the begin/end of navigation.
     *
     * @param  {string} button - string describing the button.
     */
    disableButton(button) {
        this.buttonDisabled = button;

        if (button) {
            this.$('.js-' + button).addClass(Styles['options__option--is-disabled']);
        } else {
            this.$('.js-next').removeClass(Styles['options__option--is-disabled']);
            this.$('.js-back').removeClass(Styles['options__option--is-disabled']);
        }
    },

    /**
     * onClickExit
     *
     * When a user clicks the exit(X) icon he will be send to the section showview of the activity.
     * If user is a teacher, and there are students in the presentation, they will be send too.
     *
     */
    onClickExit() {

        if (Backbone.Model.user.get('is_teacher') && this.presentation.teacherIsPresenting && this.presentation.studentsInPresentation.length) {

            // Let every student in the presentation navigate to the section of the presentation
            Backbone.Model.user.sendSocketEvent(
                this.presentation.studentsInPresentation.pluck('id'),
                'go-to-url',
                '/sections/show/' + this.model.get('section_id')
            )
        }

        if (FullscreenMode.checkFullscreen()) {
            FullscreenMode.exitFromFullscreen();
        }

        // Make sure any styling, event listeners and other settings are removed before exiting presentation
        this.presentation.destroy();

        // Navigate to section view of activity
        Backbone.history.navigate('sections/show/' + this.model.get('section_id'), {
            trigger: true
        });
    },

    /**
     * onClickAuthor
     *
     * When user clicks the author icon, he/she will be navigated to the author version of the
     * task group.
     *
     */
    onClickAuthor() {

        // If presentation is showing a task group
        if (this.presentation.taskGroupId > 0) {

            // Go to the author version of this task group
            window.location = '/task_groups/author/' + this.model.id + '/' + this.presentation.taskGroupId;
        } else {

            // Go to author version of the activity
            window.location = '/activities/author/' + this.model.id;
        }
    },

    onClickOnlineStatus() {
        this.presentation.openSendStudentSidebar();
    },

    /**
     * onFullscreenChange
     *
     * This function is triggered when the document changes it fullscreen state.
     * It will then check if document is fullscreen and adjust the icon accordingly.
     *
     */
    onFullscreenChange() {
        var icon;
        var fullscreenElem = this.$('.js-fullscreen');

        if (FullscreenMode.checkFullscreen()) {

            // Change icon to full-screen exit
            icon = ShapeList['fullscreen-exit'];
            fullscreenElem.attr('data-label', window.i18n.gettext('Exit fullscreen mode'));
        } else {

            // Change icon back to fullscreen icon
            icon = ShapeList.fullscreen;
            fullscreenElem.attr('data-label', window.i18n.gettext('Enter fullscreen mode'));
        }

        // Set icon in DOM
        fullscreenElem.html(icon);

    },

    /**
     * onClickFullscreen
     *
     * When user clicks the fullscreen icon, the fullscreen mode will be toggled.
     *
     */
    onClickFullscreen() {

        // When in full screen mode
        if (FullscreenMode.checkFullscreen()) {

            // Exit
            FullscreenMode.exitFromFullscreen();

        } else {

            // If not, set the whole document to fullscreen
            FullscreenMode.launchIntoFullscreen(document.documentElement);

        }
    },

    updateStudentsInPresentation() {
        if (this.presentation.teacherIsPresenting) {
            this.$('.js-online-number').text(this.presentation.studentsInPresentation.length);
        } else {
            this.$('.js-online-number').text('0')
        }

    },

    /**
     * onDestroy
     *
     * When view is destroyed, stop listening to the keyup event.
     *
     */
    onDestroy() {
        $(document).off('keyup', this.onKeyUp);
    },

    /**
     * onIsFollowingPresentation
     *
     * This function is triggered when a student is following a presentation.
     * It will not let the view listen to key up and hide the navigation keys.
     * This way the student cannot navigate when following.
     */
    onIsFollowingPresentation() {
        $(document).off('keyup', this.onKeyUp);
        this.$('.js-back').hide();
        this.$('.js-next').hide();
    },

    /**
     * onHasStoppedFollowingPresentation
     *
     * The teacher has left the presentation. This will end the follow mode of the student.
     *
     */
    onHasStoppedFollowingPresentation() {
        $(document).on('keyup', this.onKeyUp);
        this.$('.js-back').show();
        this.$('.js-next').show();
        this.disableButton(false);
    }

});
