<script>
    import Spinner from 'views/components/spinner/Spinner.svelte';
    import Button from 'views/components/button/Button.svelte';

    export let fileId
    export let fileIdHash
    export let isVideoActivity

    let player
    let isLoading = true
    let errorState
    let videoElement

    loadVideoDetails()

    function loadVideoDetails() {
        errorState = null
        isLoading = true

        jQuery.get({
            url: `/edu_files/open/${fileId}/${fileIdHash}`,
            success: async (response) => {
                if (response.status === 'error') {
                    switch (response.err_code) {
                        case 41404:
                            errorState = 'outdated'
                            return;
                        default:
                            errorState = 'load_error'
                            return;
                    }
                }

                const videoUrl = response.videoUrl
                const token = response.token

                let shaka
                try {
                    shaka = await import(
                        /* webpackChunkName: "shaka-player" */
                        'shaka-player'
                    )
                } catch {
                    errorState = 'load_error'
                    return
                }

                player = new shaka.Player(videoElement)

                // We need to use the token in every request that we are doing, the code below will fix this
                player.getNetworkingEngine().registerRequestFilter((type, request) => {
                    // Only apply to manifest and media segment requests
                    if (
                        type === shaka.net.NetworkingEngine.RequestType.MANIFEST ||
                        type === shaka.net.NetworkingEngine.RequestType.SEGMENT
                    ) {
                        request.uris = request.uris.map(uri => uri + token);
                    }
                })

                try {
                    await player.load(videoUrl);
                } catch {
                    errorState = 'load_error'
                    return
                }

                window.statsTracker.trackEvent(
                    window.app.controller.activePath.join('/'),
                    'Digistudies video opened'
                )
            },
            error: () => {
                errorState = 'load_error'
            },
            complete: () => {
                isLoading = false
            }
        })
    }
</script>

<div
    class="player-holder"
    class:player-holder--video-activity={isVideoActivity}
>
    <!-- svelte-ignore a11y-media-has-caption -->
    <video bind:this={videoElement} hidden={isLoading || errorState} controls></video>

    {#if isLoading}
        <div class="spinner-holder">
            <Spinner></Spinner>
        </div>
    {:else if errorState === 'outdated'}
        <div class="error">
            {window.i18n.gettext('This video is outdated and therefore not available anymore')}
        </div>
    {:else if errorState === 'load_error'}
        <div class="error">
            {window.i18n.gettext('Something went wrong while loading the video.')}
        </div>
        <Button label={window.i18n.gettext('Try again')} callback={loadVideoDetails} icon="refresh"></Button>
    {/if}
</div>

<style lang="scss">
    .player-holder {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .player-holder--video-activity {
        height: 100%;
    }

    .spinner-holder {
        width: 150px;
        height: 150px;
        margin: auto;
        color: rgba(0, 0, 0, 0.25);
    }

    .error {
        padding: 16px;
        font-size: 1.2rem;
    }
</style>
