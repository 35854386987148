import Styles from './Summary.scss';

import Template from './Summary.hbs';
import Avatar from 'views/components/avatar/Avatar.svelte'
import Button from 'views/components/button/Button'

export default BaseView.extend({

    initialize({ presentation, summaryType }) {

        // Make this accessible within following methods
        _.bindAll(
            this,
            'onClickSendStudentButton',
            'onIsFollowingPresentation'
        );

        // Make showview accessible within methods
        this.presentation = presentation;

        // Define subtitle variable by checking if we are at the ending version of this view
        var subtitle = (summaryType === 'summary-end') ?

            // If true, set string as follows
            window.i18n.gettext('End of the presentation') :

            // If we are not, check if there is a share_url key present in the activity model,
            // otherwise set an empty string
            this.presentation.model.get('share_url') || '';

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            isTeacher: Backbone.Model.user.get('is_teacher'),
            activityName: this.presentation.model.get('name'),
            isStart: summaryType === 'summary-start',
            subtitle
        }));

        if (
            Backbone.Model.user.get('is_teacher') &&
            summaryType === 'summary-start'
        ) {
            this.checkWhoIsInPresentation();

            this.renderSendStudentButton();
        }

        if (summaryType === 'summary-end') {

            // Render a finish button which lets the user quit the activity
            this.addChildView(
                new Button({
                    label: window.i18n.gettext('Finish'),
                    callback: () => {
                        this.presentation.toolbar.onClickExit()
                    },
                    icon: 'cross',
                    size: 'medium'
                }), '.js-done-btn'
            );
        }

        if (Backbone.Model.user.get('is_student') && this.presentation.teacherIsPresenting) {
            this.onIsFollowingPresentation()
        }
    },

    /**
    * checkWhoIsInPresentation
    *
    * This function checks which students are in followMode.
    * Students who match this criterium will be displayed on the screen with their avatar.
    *
    */
    checkWhoIsInPresentation() {
        this.destroyChildViewsOfInstance(Avatar)
        this.$('.js-students-text').text('')

        if (!this.presentation.teacherIsPresenting) {
            return
        }

        // Go through each student and show their avatar
        this.presentation.studentsInPresentation.each(studentModel => {
            this.addSvelteChildView('.js-avatars', Avatar, {
                avatar: studentModel.get('avatar'),
                label: studentModel.first_name_last_name(),
                showLabelOnHover: true
            })
        })

        // Set the text
        this.$('.js-students-text').text(window.i18n.sprintf(
            window.i18n.ngettext(
                '%s student is following the presentation.',
                '%s students are following the presentation.',
                this.presentation.studentsInPresentation.length
            ),
            this.presentation.studentsInPresentation.length
        ))

    },

    renderSendStudentButton() {
        this.addChildView(
            new Button({
                icon: 'sign-direction',
                label: window.i18n.gettext('Invite students'),
                callback: this.onClickSendStudentButton,
                size: 'medium'
            }), '.js-send-students'
        );
    },

    /**
    * onClickSendStudentButton
    *
    * When user clicks on the send student button, the SendStudentSideBar will be opened.
    *
    */
    onClickSendStudentButton() {
        this.presentation.openSendStudentSidebar();
    },

    /**
    * onIsFollowingPresentation
    *
    * This events listens if the student is now in follow mode.
    * If so, it will then hide the done button.
    *
    */
    onIsFollowingPresentation() {
        this.$('.js-wait-message').show();
    }
})
