import Styles from './WeekLabel.scss';

import Template from './WeekLabel.hbs';
export default class WeekLabel extends BaseView {

    initialize() {
        this.setElement(Template({
            Styles,
            week: this.model.get('week'),
            name: this.getName(this.model.get('index'))
        }))
    }

    getName(index) {
        switch (index) {
            case 0:
                return window.i18n.gettext('Previous week')
            case 1:
                return window.i18n.gettext('Current week')
            case 2:
                return window.i18n.gettext('Next week')
        }
    }
}
