export default class TaskAnalyticsModel extends Backbone.Model {

    get idAttribute() {
        return 'task_id'
    }

    get defaults() {
        return {
            task_id: null,
            responses: 0,
            students: 0,
            weighted_p_value: 0,
        }
    }

    get pValue() {
        // Do not show value if less than 25 responses are given.
        if (this.get('responses') < 25) {
            return ''
        }
        return this.get('weighted_p_value').toLocaleString(undefined, {
            minimumFractionDigits: 2, maximumFractionDigits: 2
        })
    }

    url() {
        return `/tasks/get_analytics/${this.get('task_id')}.json`
    }

}
