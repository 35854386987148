import Styles from './Item.scss';

import Template from './Item.hbs';
import Util from 'util/util';
import RenderSpecialElements from 'util/RenderSpecialElements';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import ItemPlaceholderTemplate from 'views/components/taskGroups/tasks/template34/item/ItemPlaceholder.hbs';
import LockNavTemplate from 'views/components/taskGroups/tasks/template34/item/LockNav.hbs';
import ArrowNavTemplate from 'views/components/taskGroups/tasks/template34/item/ArrowNav.hbs';
import IndexTemplate from 'views/components/taskGroups/tasks/template34/item/Index.hbs';

export default BaseView.extend({

    // Make a mapping for element templates
    specialElementsConvertList: {
        // Convertion for HTML element: 'img'
        img: {

            // Default options
            global: {

                // Set template for the img tag
                template: DefaultImageElement,
            }
        }
    },

    events: {
        'click .js-move-up': 'moveItemUp',
        'click .js-move-down': 'moveItemDown'
    },

    initialize() {

        this.setElement(Template({
            Styles,
            content: Util.renderContentSafely(this.model.text),
            id: this.model.id
        }));

        RenderSpecialElements({}, this)

        this.navElement = this.$('.js-nav');
        this.showNavigation();

    },

    /**
     * getItemIndex
     *
     * Get current index of item in item collection.
     *
     * @return {number}  current index
     */
    getItemIndex() {
        return _.indexOf(this.collection, this.model);
    },

    /**
     * showNavigation
     *
     * Show appropriate navigation for current item.
     */
    showNavigation() {
        if (this.model.lock) {
            this.showLock();
        } else {
            this.showArrowNav();
        }
    },

    /**
     * showLock
     *
     * Display lock icon if item can not be moved.
     */
    showLock() {
        this.navElement.html(LockNavTemplate({
            Styles
        }));
    },

    /**
     * showArrowNav
     *
     * Show arrow to move item up and down the list.
     */
    showArrowNav() {
        var index = this.getItemIndex();
        this.navElement.html(ArrowNavTemplate({
            Styles
        }));
        this.navElement.find('.js-move-up').toggleClass(
            Styles['nav-btn--disabled'],
            index === 0
        );
        this.navElement.find('.js-move-down').toggleClass(
            Styles['nav-btn--disabled'],
            index === this.collection.length - 1
        );
    },

    /**
     * moveItemUp
     *
     * Move current item one place up in the collection of items.
     */
    moveItemUp() {
        var oldIndex = this.getItemIndex();
        var newIndex = _.findLastIndex(this.collection, (o, i) => {
            return i < oldIndex && !o.lock;
        });
        // Only move item if index is 1 or greater.
        if (oldIndex > 0) {
            this.moveItem(oldIndex, newIndex);
        }
    },

    /**
     * moveItemDown
     *
     * Move current item one place down in the collection of items.
     */
    moveItemDown() {
        var oldIndex = this.getItemIndex();
        var newIndex = _.findIndex(this.collection, (o, i) => {
            return i > oldIndex && !o.lock;
        });
        // Only move item if index is less than the maximum index.
        if (oldIndex < this.collection.length - 1) {
            this.moveItem(oldIndex, newIndex);
        }
    },

    /**
     * moveItem
     *
     * Move item to the new location
     *
     * @param  {number} oldIndex    old location
     * @param  {number} newIndex    new location
     */
    moveItem(oldIndex, newIndex) {
        if (newIndex > -1) {

            // Get the element with the ID on the new location.
            var itemElementAtNewIndex = this.$el.siblings('[data-id=' + this.collection[newIndex].id + ']');

            // Replace existing item on the new location and replace it
            // with the current item.
            var itemModelAtNewIndex = this.collection.splice(newIndex, 1, this.model)[0];
            // Replace the current item with the other item at the old
            // location in the collection.
            this.collection.splice(oldIndex, 1, itemModelAtNewIndex);

            // y-offset relative to parent container.
            var newY = itemElementAtNewIndex.position().top;
            var newH = itemElementAtNewIndex.height();
            var oldY = this.$el.position().top;
            var oldH = this.$el.height();

            // Add placeholder divs to fill the height of the item
            // elements that are being moved.
            var placeHolderNew = $(ItemPlaceholderTemplate({
                h: newH
            }));
            itemElementAtNewIndex.after(placeHolderNew);
            var placeHolderOld = $(ItemPlaceholderTemplate({
                h: oldH
            }));
            this.$el.after(placeHolderOld);

            // Animate position swap animation for current item element.
            TweenMax.fromTo(this.$el, {
                position: 'absolute',
                y: oldY
            }, {
                y: oldY < newY ? newY - (oldH - newH) : newY,
                // Ensure current item element is above all other items.
                zIndex: 10,
                duration: 0.7,
                ease: 'expo.inOut'
            });

            // Grow placeholders to size of new occupants.
            TweenMax.to(placeHolderNew, {
                height: oldH,
                duration: 0.7,
                ease: 'expo.inOut'
            });
            TweenMax.to(placeHolderOld, {
                height: newH,
                duration: 0.7,
                ease: 'expo.inOut'
            });

            // Animate position swap animation for other item element.
            TweenMax.fromTo(itemElementAtNewIndex, {
                position: 'absolute',
                y: newY
            }, {
                y: oldY < newY ? oldY : oldY - (newH - oldH),
                duration: 0.7,
                ease: 'expo.inOut',
                // Trigger re-render.
                onComplete: _.bind(this.trigger, this, 'orderChange')
            });

        }
    },

    /**
     * showAnswer
     *
     * Show if the present item is on the correct position.
     *
     * @param  {Array} correctOrder     array of IDs in the correct order
     */
    showAnswer(correctOrder) {
        this.navElement.html(IndexTemplate({
            Styles,
            // If correctOrder is undefined, use current item index instead.
            index: (correctOrder ? correctOrder.indexOf(this.model.id) : this.getItemIndex()) + 1
        }));
        if (this.model.lock || APPLICATION === 'author') {
            this.$el.addClass(Styles['gray']);
        } else if (correctOrder[this.getItemIndex()] === this.model.id) {
            this.$el.addClass(Styles['correct']);
        } else {
            this.$el.addClass(Styles['incorrect']);
        }
    },

    /**
     * hideAnswer
     *
     * Hide the correct answer for the present item.
     */
    hideAnswer() {
        this.$el.removeClass(
            Styles['gray'] + ' ' +
            Styles['correct'] + ' ' +
            Styles['incorrect']
        );
        this.showNavigation();
    },

    lockAnswer() {
        this.navElement.addClass(Styles['nav--locked'])
    },

    unlockAnswer() {
        this.navElement.removeClass(Styles['nav--locked'])
    }

})
