var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"linear-activity--is-mobile") : stack1), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"linear-activity") : stack1), depth0))
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isMobile.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":101}}})) != null ? stack1 : "")
    + "\">\n\n    <div class=\"js-activity-viewport\">\n\n        <div class=\"center-panel\">\n            <div class=\"js-license-message "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"license-message") : stack1), depth0))
    + "\"></div>\n            <div class=\"js-deadline-message "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"deadline-message") : stack1), depth0))
    + "\"></div>\n        </div>\n\n        <div class=\"js-taskgroup\"></div>\n\n    </div>\n\n    <div class=\"center-panel\">\n        <div class=\"js-pagination-bar "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"pagination") : stack1), depth0))
    + "\"></div>\n    </div>\n\n    <div class=\"js-presentation-mode\"></div>\n\n</div>\n";
},"useData":true});