var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"outer") : stack1), depth0))
    + " js-dont-close-sidebar\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"outside") : stack1), depth0))
    + "\"></div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"keyboard") : stack1), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"nav-outer") : stack1), depth0))
    + "\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"nav") : stack1), depth0))
    + " js-nav\">\n                <div class=\"js-tabs\"></div>\n                <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"nav__btn") : stack1), depth0))
    + " js-close\" title=\""
    + alias2(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias3,"Cancel",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":68},"end":{"line":7,"column":83}}}))
    + "\">"
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":85},"end":{"line":7,"column":104}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n        </div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"output") : stack1), depth0))
    + " js-output-container\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"output__editor") : stack1), depth0))
    + " js-keyboard-output\"></div>\n            <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"output__btn") : stack1), depth0))
    + " js-submit\" title=\""
    + alias2(__default(require("../../../util/handlebarsHelpers/__.js")).call(alias3,"Save",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":12,"column":68},"end":{"line":12,"column":81}}}))
    + "\">\n                <span>\n                    "
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/shape.js")).call(alias3,"checkmark",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":43}}})) != null ? stack1 : "")
    + "\n                    <div class=\"js-spinner "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"spinner") : stack1), depth0))
    + "\"></div>\n                </span>\n            </button>\n        </div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"panel") : stack1), depth0))
    + " js-keyboard-panel js-tab-view-container\"></div>\n    </div>\n</div>\n";
},"useData":true});