import AnnotationModel from 'models/AnnotationModel';

export default Backbone.Collection.extend({

    model: AnnotationModel,

    loadAnnotations(id, type) {
        switch (type) {
            case 'schoolSchoolclassCourse':
                this.url = '/annotations/load_annotations_for_course/' + id + '.json';
                break;
        }
    },

    // Make a save annotation function for inheritence
    createAnnotation(annotation, path, callback) {
        if (annotation.trim().length > 1) {
            // Save the new annotation
            this.create(
                // Make a object of the to be saved annotation
                {
                    // For the flag noteText,
                    // set the value to a trimmed version of the text area input
                    note_text: annotation.trim(),
                    group_id: path.group_id,
                    chapter_id: path.chapter_id,
                    section_id: path.section_id,
                    activity_id: path.activity_id,
                    task_group_id: path.task_group_id,
                    task_group_sequence: path.task_group_sequence,
                    is_teacher: ((Backbone.Model.user.get('is_teacher')) ? '1' : '0')
                }, {
                    // Callback function when note has been called
                    success: callback
                }
            );
        }
    },
}, {
    type: 'annotations'
})
