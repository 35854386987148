import Styles from './Item.scss';

import Template from './Item.hbs';
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte'
import ProgressBarUtil from 'views/components/progressBar/ProgressBarUtil'

export default class Item extends BaseView {

    get events() {
        return {
            click: 'onClick',
        }
    }

    /**
     * @param {Object} options options
     * @param {Backbone.Model} options.activity_model activity model
     * @param {Backbone.View} options.work_on linear activity view
     * @param {number} num index of item
     */
    initialize({
        activity_model,
        work_on,
    }) {
        this.activity_model = activity_model
        this.work_on = work_on

        this.setElement(Template({
            Styles,
            index: this.model.get('index'),
            label: this.model.getShortText(),
            hasProgress: Backbone.Model.user.get('is_student') && this.activity_model.tasks.length,
            isActive: this.model.get('index') === this.work_on.activeItem.model.get('index')
        }))

        // Students will receive a sidebar with their latest progress inside the activity.
        // Teachers will only receive an overview of the task groups inside the activity, so they can
        // make use of the quick navigation.
        if (!Backbone.Model.user.get('is_student')) {
            return
        }

        const countTasks = this.model.tasks.length
        const taskIds = this.model.tasks.pluck('id')

        let countMade = 0
        let countChecked = 0
        let score = 0

        for (const response of this.activity_model.responses) {

            if (!response.has('json_answer')) {
                continue
            }

            if (taskIds.includes(response.get('task_id'))) {
                countMade++

                if (response.get('score') !== -1) {
                    countChecked++
                    score += parseFloat(response.get('score'))
                }
            }

        }

        // If the task group contains tasks, add a progress bar
        if (countTasks > 0) {

            this.addSvelteChildView('.js-progress', ProgressBar, {
                fragments: this.activity_model.get('showScores') ? ProgressBarUtil.getStudentProgressFragments(
                    true, false,
                    countChecked ?
                        score / countChecked : 0,
                    countChecked / countMade,
                    countTasks ?
                        countMade / countTasks : 0
                ) : {
                    blue: countMade / countTasks
                }
            })
        }

    }

    /**
     * onClick
     *
     * When item is clicked, navigate to related task group.
     *
     * @returns {Boolean} abort
     */
    onClick() {

        // Find the index of the model inside the activity's taskgroups.
        // Add 1 to align with the navigation index that starts at 1
        var navigationIndex = _.indexOf(this.work_on.model.task_groups.models, this.model) + 1

        this.work_on.navigateToIndex(navigationIndex)
        Backbone.View.sidebar.closeSidebar()
        return false
    }

}
