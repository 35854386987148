import Template from './Bold.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Bold extends Button {

    // Built-in TinyMCE command to make text bold
    command = 'Bold'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Bold');
    }

}
