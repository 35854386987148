import AppRouter from 'AppRouter';
import Controller from 'controller/Controller';
import LayoutView from 'views/components/layout/Layout';
import UserModel from 'models/UserModel';

export default class App {

    constructor() {

        _.bindAll(this,
            'onClickDocument',
        );

        if (ISMOBILE) {
            $('html,body').addClass('is-mobile');
        }

        this.router = new AppRouter();
        this.router.on('route', this.onRoute, this);

        this.layoutView = new LayoutView($('body'));

        // Tell it's attached to the DOM
        this.layoutView.setAttached();

        this.controller = new Controller();

        this.checkPushStateSupport();

        $(document).on('click', 'a', this.onClickDocument);

        // We check the status of the user and frontend state when the network status changes from offline to online
        // and when the page becomes visible
        window.addEventListener('online', () => {
            this.checkStatus()
        })
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.checkStatus()
            }
        })

        // if path is login (probably redirected to this page by backend,
        // load the frontend before getting the user_model)
        // to increase speed of showing login screen
        if (document.location.pathname === '/users/login') {
            Backbone.history.start({
                pushState: this.pushStateSupport,
                root: '/'
            });
        }

        Backbone.Model.user = new UserModel();
    }

    onRoute(route, params) {
        var routeResult;
        // TODO Ignore 'route' param and handle everthing ourselves.
        // We can't use 'route' here because we can't use it in testRoute

        switch (route) {
            case AppRouter.Routes.GENERIC:
            case AppRouter.Routes.GENERIC_INDEX:
                routeResult = this.controller.setRoute(params);
                break;
        }

        // If route is connected to some kind of view, construct said view and
        // add route to router history.
        if (routeResult && routeResult.viewConstructor) {
            this.layoutView.setView(
                routeResult.viewConstructor,
                routeResult.activePath,
                routeResult.viewParams
            );
        }
    }

    checkPushStateSupport() {
        if (window.history && window.history.pushState) {
            this.pushStateSupport = true;
        }

        // Redirect to hash fragment if no support
        if (!this.pushStateSupport) {
            if (window.location.pathname !== '/') {
                window.location = `/#${window.location.pathname.replace(/^\//, '')}`;
            }
        }
    }

    /**
     * testRoute
     *
     * Test if route exists in our controller.
     *
     * @param {String} fragment
     * route
     * @returns {Boolean|Object}
     * If false, no controller is defined or no router has been found that can handle the input URL format (fragment).
     * If an object is returned, it means the controller is present and that the URL has a valid format. The object
     * provides information if the page exists in the controller {isExistingPageType} and if the content associated
     * with this page exists for the current user {isExistingContent}.
     */
    testRoute(fragment) {
        if (!this.controller) {
            return false
        }
        for (let i = 0; i < Backbone.history.handlers.length; i++) {
            const handler = Backbone.history.handlers[i]
            if (handler.route.test(fragment)) {
                // Extract page controller (part after the first slash), the action (part after the second slash) and
                // the other parameters from the URL fragment.
                const {controller, action, params} = this.controller.extractParams(
                    this.router._extractParameters(handler.route, fragment)
                )
                return {
                    isExistingPageType: this.controller.isExistingPageType(controller, action),
                    isExistingContent: this.controller.isExistingContent(controller, action, params, true)
                }
            }
        }
        return false
    }

    /**
     * Hijack internal links for push history support
     *
     * @param {Event} event     click event
     * @returns {Boolean}       prevent propagation
     */
    onClickDocument(event) {
        if (!event.isDefaultPrevented()) {
            const anchor = event.currentTarget;

            // Check if link is internal, not set to open a new tab,
            // and the user did not press any modifier keys (e.g. cmd-click to open a new tab)
            if (
                anchor.hostname === window.location.hostname &&
                anchor.getAttribute('target') !== '_blank' &&
                !event.originalEvent.altKey &&
                !event.originalEvent.ctrlKey &&
                !event.originalEvent.metaKey &&
                !event.originalEvent.shiftKey
            ) {

                // Abort navigation if path names are the same.
                if (anchor.pathname === window.location.pathname) {
                    return false
                }

                // Remove slash in front of url
                const fragment = anchor.pathname.replace(/^\//, '');

                // Test if requested route is possible within our routing schemes. If that is the case, only navigate
                // to the page if the page in the controller map AND its associated content exists. If the latter is
                // not the case, abort navigation.
                const routeTestResult = this.testRoute(fragment)
                if (routeTestResult && routeTestResult.isExistingPageType) {
                    if (routeTestResult.isExistingContent) {
                        event.preventDefault()
                        // Don't let the browser handle going to the next page, but do it ourselves
                        this.router.navigate(fragment, {trigger: true})
                        return true

                        // TODO Support hashes (to scroll within page)
                    }

                    return false
                }
            }
        }
    }

    // Check the status of the user and frontend with the backend
    checkStatus() {

        // If we are already doing the request or we did it less than 10 seconds ago, skip it
        if (this.statusRequest || this.statusLastCheck > (Date.now() - 10 * 1000)) {
            return
        }

        this.statusRequest = $.get({
            url: '/users/check_status.json',
            success: (response) => {
                this.statusLastCheck = Date.now()

                // Is there a session in the backend or in the frontend
                if (response.user_id || Backbone.Model.user.id) {

                    // If there is no backend session but there is a session in the frontend, aks the user
                    // to login again to re-establish the backend session
                    if (!response.user_id && Backbone.Model.user.id) {

                        // If the user is an accessor, it won't know the password of the user
                        // therefore, do not show relogin modal but go to the accessor connections
                        // page
                        if (Backbone.Model?.accessor?.get('email')) {
                            Backbone.history.navigate('accessor/connections', { trigger: true });
                        } else {
                            Backbone.View.layout.openReEnterPasswordModal();
                            Backbone.Model.user.set('sessionLost', true);
                        }

                        // If the backend has a session, but the frontend does not agree,
                        // do a full reload to get back in sync
                    } else if (response.user_id !== Backbone.Model.user.id) {

                        document.location.reload()
                        return

                        // If the backend and frontend sessions match, close the relogin modal (if present)
                    } else {
                        Backbone.View.layout.closeReEnterPasswordModal()
                    }
                }

                // Check if we have the latest frontend
                if (response.frontendVersion) {
                    this.checkFrontendVersion(response.frontendVersion);
                }

                // If we have a logged in user, update their status
                if (response.user_id) {
                    Backbone.Model.user.updateStatus(response)
                }
            },
            complete: () => {
                delete this.statusRequest
            },
            timeout: 10000
        })
    }

    /**
     * checkFrontendVersion
     *
     * Compares client's frontend version to that of the latest version
     * fetched from Redis which should always be the latest.
     * If client's version is not the same, add an attribute that tells user to update the next time
     * the user visits the home screen.
     *
     * @param  {String} frontendVersion - Hash representing the frontend version in Redis
     */
    checkFrontendVersion(frontendVersion) {
        if (
            PRODUCTION &&
            frontendVersion &&
            frontendVersion !== VERSIONHASH
        ) {
            this.needsFrontendUpdate = true
        }
    }
}
