import Template from './Source.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';

export default class Source extends Button {

    // Initialize function for initializing button specific logic
    initializeButton() {

        // Set the icon to the template
        this.icon = Template();

        // Set the tooltip to a translated string
        this.tooltip = window.i18n.gettext('View source');
    }

    onClickButton(e) {
        // Prevent weird behaviour by blocking default actions
        this.stopAllEvents(e)

        // Check if the editor is hidden
        if (this.wysiwyg.editor.isHidden()) {

            // Deactive the button
            this.setDeactive()

            // Unset fixed height from source code text area.
            this.wysiwyg.editor.getElement().style.height = null

            // Reshow the editor
            this.wysiwyg.editor.show()

            // When the editor is not hidden
        } else {

            // Activate the button
            this.setActive()

            // Set source code text area to same height as the WYSIWYG editor.
            this.wysiwyg.editor.getElement().style.height = `${this.wysiwyg.editor.getContainer().clientHeight}px`

            // Hide the editor
            this.wysiwyg.editor.hide()
        }
    }

}
