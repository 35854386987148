import Styles from './Symbols.scss';

import Template from './Symbols.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import SymbolPopupTemplate from 'views/components/wysiwyg/parts/buttons/symbols/SymbolsPopup.hbs';
import SymbolsButtonTemplate from 'views/components/wysiwyg/parts/buttons/symbols/SymbolButton.hbs';

export default class Symbols extends Button {

    // Built-in TinyMCE command for inserting content at cursor position.
    command = 'mceInsertContent'

    /* eslint-disable max-len */

    symbols = [
        // division ( ÷ ), multiplication ( × ), interpunct ( ⋅ ), square root ( √ ), degree ( ° )
        '#xF7', '#xD7', '#x22c5', '#x221A', '#xB0',

        // angle ( ∠ ), almost equal to ( ≈ ), not equal to ( ≠ ), arrow right ( → ), arrow left ( ← )
        '#x2220', '#x2248', '#x2260', '#x2192', '#x2190',

        // arrow up ( ↑ ), arrow down ( ↓ ), arrow left/right ( ⇄ ), alpha ( α ), beta ( β )
        '#x2191', '#x2193', '#x21C4', '#x3b1', '#x3b2',

        // gamma ( γ ), Delta ( Δ ), pi ( π ), rho ( ρ ), Sigma ( Σ )
        '#x3b3', '#x394', '#x3c0', '#x3c1', '#x3a3',
    ]

    extendedSymbols = [
        // division ( ÷ ), multiplication ( × ), interpunct ( ⋅ ), square root ( √ ), degree ( ° ), angle ( ∠ ),
        '#xF7', '#xD7', '#x22c5', '#x221A', '#xB0', '#x2220',

        // almost equal to ( ≈ ), not equal to ( ≠ ), estimates ( ≙ ), triple bar ( ≡ ), smaller or equal ( ≤ ), larger or equal ( ≥ )
        '#x2248', '#x2260', '#x2259', '#x2261', '#x2264', '#x2265',

        // infinity ( ∞ ) arrow right ( → ), arrow left ( ← ), arrow up ( ↑ ), arrow down ( ↓ ), arrow left/right ( ⇄ ),
        '#x221e', '#x2192', '#x2190', '#x2191', '#x2193', '#x21C4',

        // alpha ( α ), beta ( β ), gamma ( γ ), delta ( δ ), Delta ( Δ ), epsilon ( ε ),
        '#x3b1', '#x3b2', '#x3b3', '#x3b4', '#x394', '#x3b5',

        // eta ( η ), theta ( θ ), lambda ( λ ), mu ( μ ), pi ( π ), rho ( ρ ),
        '#x3b7', '#x3b8', '#x3bb', '#x3bc', '#x3c0', '#x3c1',

        // sigma ( σ ), Sigma ( Σ ), phi ( φ ), Psi ( Ψ ), omega ( ω ), Omega ( Ω )
        '#x3c3', '#x3a3', '#x3c6', '#x3a8', '#x3c9', '#x3a9',
    ]

    /* eslint-enable max-len */

    initializeButton() {
        _.bindAll(this,
            'openPopupMenu',
            'onChosenSymbol',
            'mouseUpDocument'
        );

        this.icon = Template();
        this.tooltip = window.i18n.gettext('Symbols');

        // Only show contents of `symbols` if false.
        this.showExpanded = false
    }

    mouseUpDocument(e) {
        // Close the symbol picker menu if the click target is outside of the symbol picker or symbol picker button.
        if (!this.el.contains(e.target)) {
            this.closePopupmenu()
        }
    }

    onClickButton(e) {
        this.stopAllEvents(e);
        this.openPopupMenu(e);
    }

    closePopupmenu() {
        // Re-enable the tooltip
        this.disableTooltip = false;

        // Remove popup
        this.popup?.remove()
        delete this.popup;

        // Remove click listener from Learnbeat document and TinyMCE iframe document.
        document.removeEventListener('click', this.mouseUpDocument)
        if (this.wysiwyg.editor) {
            this.wysiwyg.editor.getDoc().removeEventListener('click', this.mouseUpDocument)
        }
    }

    openPopupMenu(e) {
        // Open popup if it's not already visible.
        if (this.popup === undefined) {
            this.popup = $(SymbolPopupTemplate({
                Styles
            }))
            this.$('.js-icon-holder').after(this.popup);

            const visibleSymbolsContainer = this.el.querySelector('.js-symbols-container')

            // add symbols to visible symbols container
            this.changeVisibleSymbolSet(this.showExpanded, visibleSymbolsContainer)

            // listen to click event for expanding/collapsing symbol set
            this.el.querySelector('.js-show-more-symbols-button').addEventListener('click', () => {
                this.changeVisibleSymbolSet(
                    visibleSymbolsContainer.dataset.hasOwnProperty('isCollapsed'),
                    visibleSymbolsContainer
                )
            })

            // Disable tooltip so it does not go over the popup menu.
            this.disableTooltip = true;
            this.onDeHoverButton();

            // Add click listener to Learnbeat document and TinyMCE iframe document so the popup gets closed
            // when user clicks anywhere but the popup menu itself.
            document.addEventListener('click', this.mouseUpDocument)
            if (this.wysiwyg.editor) {
                this.wysiwyg.editor.getDoc().addEventListener('click', this.mouseUpDocument)
            }

            // Right align instead of left align popup when it is too close to right edge of the editor toolbar.
            this.popup.css({
                right: this.$el.width() * this.$el.index() > this.popup.width() ? '-1px' : 'unset'
            })

        } else if (!this.popup[0].contains(e.target)) {
            // Close when popup is open and clicked target is not popup.
            this.closePopupmenu();
        }
    }

    /**
     * change displayed symbols
     *
     * @param {boolean} showExpandedSymbolsSet Show expanded symbol set if true
     * @param {HTMLButtonElement} visibleSymbolsContainer The "show more/less symbols" button
     */
    changeVisibleSymbolSet(showExpandedSymbolsSet, visibleSymbolsContainer) {
        const showMoreSymbolsButton = this.el.querySelector('.js-show-more-symbols-button')

        const visibleSymbols = showExpandedSymbolsSet ? this.extendedSymbols : this.symbols

        showMoreSymbolsButton.textContent = showExpandedSymbolsSet ?
            window.i18n.gettext('Less symbols') :
            window.i18n.gettext('More symbols')

        if (showExpandedSymbolsSet) {
            visibleSymbolsContainer.removeAttribute('data-is-collapsed')
        } else {
            visibleSymbolsContainer.dataset.isCollapsed = ''
        }

        // remember the "expanded" state for if user closes and re-opens popup
        this.showExpanded = showExpandedSymbolsSet

        this.appendSymbolButtons(visibleSymbols)
    }

    appendSymbolButtons(symbols) {
        const symbolsContainer = this.$('.js-symbols')

        symbolsContainer.empty()

        for (const symbol of symbols) {
            const button = SymbolsButtonTemplate({
                character: `&${symbol}`,
            })
            symbolsContainer.append(button)
        }

        // Search in this.popup for symbol buttons and added click listener to is
        symbolsContainer.find('.js-symbol-button').on('click', this.onChosenSymbol)
    }

    onChosenSymbol(e) {

        // Stop all the events
        this.stopAllEvents(e);

        // Distill the symbol from the clicked element
        const symbol = e.currentTarget.dataset.symbol

        // Execute the MCE command
        this.wysiwyg.onMceCommand(this, symbol);

        // Close popupmenu after choosing symbol
        this.closePopupmenu();
    }

}
