var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"news-message") : stack1), depth0))
    + "\">\n    <img class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header-image") : stack1), depth0))
    + "\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"headerImage") : depth0), depth0))
    + "\">\n    <p class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"date") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"date") : depth0), depth0))
    + "</p>\n    <h2 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h2>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"html-text") : stack1), depth0))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"htmlText") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n    <div class=\"js-action-button\"></div>\n    <a class=\"js-show-all "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"show-all") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-left",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":47},"end":{"line":7,"column":71}}})) != null ? stack1 : "")
    + alias2(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"All messages",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":71},"end":{"line":7,"column":92}}}))
    + "</a>\n</div>";
},"useData":true});