var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"js-commands\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"training-card") : stack1), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"training-template") : stack1), depth0))
    + " js-training-template\"></div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"question-status") : stack1), depth0))
    + " js-question-status\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"spinner") : stack1), depth0))
    + " js-progress-circle\">\n                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"spinner",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":37}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"spinner",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":37}}})) != null ? stack1 : "")
    + "\n            </div>\n"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/isNotMobile.js")).call(alias3,{"name":"isNotMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":12,"column":28}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"training-progress") : stack1), depth0))
    + " js-training-progress\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"current-progress") : stack1), depth0))
    + " js-progress\"></div>\n        </div>\n    </div>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"close-button-container") : stack1), depth0))
    + " js-close-button\"></div>\n</div>\n";
},"useData":true});