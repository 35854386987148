import Styles from './PasteListModal.scss';

import Template from './PasteListModal.hbs';
import Textarea from 'views/components/textarea/Textarea.svelte'

export default BaseView.extend({
    initialize(options) {

        this.submitCallback = options.submitCallback
        this.inputCallback = options.inputCallback

        this.setElement(Template({ Styles }))

        // Add a textarea with multiline placeholder
        this.textarea = this.addSvelteChildView('.js-text-area', Textarea, {
            placeholder: window.i18n.gettext('Example') + ':\n\n' + window.i18n.gettext('Word') + ' 1\t' + window.i18n.gettext('Meaning') + ' 1\n' + window.i18n.gettext('Word') + ' 2\t' + window.i18n.gettext('Meaning') + ' 2',
            minLines: 7,
            inputCallback: () => {
                const text = this.textarea.getValue()
                const pairs = this.inputCallback(text)
                this.el.querySelector('.js-count-pairs').textContent = window.i18n.sprintf(
                    window.i18n.ngettext(
                        '%d word recognized',
                        '%d words recognized',
                        pairs.length
                    ),
                    pairs.length
                )
            },
            pasteCallback: (e) => {
                let pastedText = e.clipboardData.getData('text')
                e.preventDefault()

                // Start by removing empty lines
                pastedText = pastedText.trim().replace(/\n\s+/g, '\n')

                // If we do not have tabs, reformat the input to contain tabs
                if (!/\t/.test(pastedText)) {

                    // Assume that each odd (1st, 3rd, 5th, etc) newline should be a tab instead
                    pastedText = pastedText.replace(/(.+?)\n(.+?)$/gm, '$1\t$2')
                }

                // Insert the processed pasted content in the text
                const textarea = e.target
                const before = textarea.value.substr(0, textarea.selectionStart)
                const after = textarea.value.substr(textarea.selectionEnd)
                textarea.value = before + pastedText + after

                // Place the cursor after the pasted content
                const cursorPosition = before.length + pastedText.length
                textarea.setSelectionRange(cursorPosition, cursorPosition)

                // Trigger input event so that other views can update and the textarea can be resized
                textarea.dispatchEvent(new Event('input', {bubbles: true}))
            },
            enableInsertTabs: true,
            autoFocus: true,
        })

    },

    addButtons() {
        Backbone.View.Components.modal.addButtons({
            [window.i18n.gettext('Cancel')]: {
                callback: Backbone.View.Components.modal.close,
                theme: 'secondary',
            },
            [window.i18n.gettext('Add words')]: {
                callback: () => {
                    const text = this.textarea.getValue()
                    this.submitCallback(text)
                },
            }
        })
    }
});
