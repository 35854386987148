import Template from './Link.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import HyperlinkMakerModal from 'views/components/wysiwyg/modals/hyperlink/HyperlinkMaker';
import URLTemplate from 'views/components/wysiwyg/elements/url.hbs';

export default class Link extends Button {

    // Custom command
    command = 'insertLink'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Link');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        const customEl = editorObject.customEl
        const customSH = editorObject.customSH

        // Override TinyMCE command for inserting links.
        editor.addCommand('mceInsertLink', (ui, content) => {
            editor.execCommand('mceInsertContent', false, URLTemplate({
                hyperlink: content,
                title: editor.selection.getContent({format: 'text'}) || window.i18n.gettext('link')
            }))
        })

        editor.addCommand(this.command, () => {

            // Pass the template, modal and editor with it
            customEl.modalComponentElement(

                // Set the modal title
                window.i18n.gettext('Add hyperlink'),

                // Pass template
                URLTemplate,

                // Define the to-be-opened modal(view)
                HyperlinkMakerModal,

                // Pass the editor object
                editor
            );

        })

        // Create the state handler for this block element
        customSH.blockElementHandler(

            // Listen to command: insertLink
            this.command,

            // Compare element on url template
            URLTemplate(),

            // Pass the editor with it
            editor
        )
    }

}
