import Styles from './Scoring.scss';

import Template from './Scoring.hbs';
import Score from 'util/Score';
import ScoringButton from './scoringButton/ScoringButton';

export default BaseView.extend({

    events: {
        'click .js-grade-answer-option': 'gradeAnswer'
    },

    /**
     * @param {ResponseModel} response               Most recent response.
     * @param {Boolean} isExam                       If true, response is part of an exam activty, which requires a
     *                                               different type of scoring interface and logic.
     * @param {Array} ranges                         List of possible score values for this response.
     */
    initialize({
        response,
        isExam,
        ranges
    }) {

        this.response = response

        _.bindAll(
            this,
            'gradeAnswer'
        );

        this.setElement(Template({ Styles }))

        for (const range of ranges) {
            const score = range[0]
            let label = range[1]
            let color

            if (!isExam) {
                label += '%'
                color = Score.getColor(score)
            }

            this.addChildView(
                new ScoringButton({ score, label, color }),
                this.$el
            )
        }

        let score = this.response.get('score')

        if (score !== undefined && score !== -1) {

            // If the value of the score does not match any of the scores in range, find the one in the range that
            // is the most close. Does does not affect the student's real score, only the display of it.
            const scoreRange = _.unzip(ranges)[0]
            if (scoreRange.includes(score) === false) {
                const scoreDistances = scoreRange.map(s => Math.abs(score - s))
                score = scoreRange[scoreDistances.lastIndexOf(Math.min(...scoreDistances))]
            }

            this.$(`.js-grade-answer-option[data-score="${score}"]`).addClass('is-active')
        }

    },

    gradeAnswer(e) {

        // Get the data needed and add it in vars
        const currentScore = $(e.currentTarget)
        const scoreBar = currentScore.parent()
        const typeOfScore =
            // Check if a parent has class js-grade-for-recent
            (scoreBar.hasClass('js-grade-for-recent')) ?
            // Then type is recent
                'recent' :

            // If not recent check if a parent has class is-original
                (scoreBar.hasClass('js-grade-for-original')) ?
                // Then type is original
                    'original' :

                // Else type is unknown
                    'unknown';

        // Get the score from the selected grade
        const currentGrade = currentScore.hasClass('is-active') ? -1 : currentScore.data('score')

        // Remove all the is-active classes
        $(scoreBar).find('.js-grade-answer-option').removeClass('is-active')

        // Toggle active class on the selected grade
        currentScore.toggleClass('is-active', currentGrade !== -1)

        // Possibly change corresponding response model in other response-type (if revision is the same)
        this.$el.trigger('gradedAnswer', [currentGrade, typeOfScore])

        // Do server call to change score on server
        this.response.updateScore(currentGrade)

        // update response model with new score
        this.response.set('score', currentGrade)

    }

});
