var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Click 'Check' to check your answer",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":53}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-title") : stack1), depth0))
    + "\">\n        "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":31}}})) != null ? stack1 : "")
    + "\n        "
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Correct answer",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":31}}}))
    + "\n    </div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer-text") : stack1), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"CHOICE",{"name":"isEqual","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":20}}})) != null ? stack1 : "")
    + "        <akit-exercise session-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sessionId") : depth0), depth0))
    + "\" solution-mode/>\n    </div>\n</div>";
},"useData":true});