import Styles from './ContentLabel.scss';

import Template from './ContentLabel.hbs';
import ContentLabelModel from 'models/ContentLabelModel'
import ShapeList from 'util/ShapeLoader'
import Avatar from 'views/components/avatar/Avatar'

/**
 * ContentLabel
 *
 * Component which creates standarised labels for common data types like chapters, sections, activities, task groups,
 * students, groups, etc. By using the ContentLabel component the user can easily recognise the data type represented
 * on the page. This component can be made clickable to link to another relevant page or some other callback.
 *
 * this.model {Backbone.Model}
 * Model to be represented by ContentLabel.
 *
 * @param {Object} options
 * Options object
 *
 * @param {Boolean|undefined} [options.noMargin]
 * If true, removes the default margin from around the component.
 *
 * @param {String|undefined} [options.labelPrefix]
 * Optional string to prepend in front of the content label.
 *
 * @param {String|undefined} [options.labelSuffix]
 * Optional string to append after the content label.
 *
 * @param {Boolean|undefined} [options.isCompact]
 * If true, save space and information overload in some contexts by using more compact representation.
 * For example: for a section, the section is expressed as "(3) SectionName" instead of "(4.3) SectionName".
 *
 * @param {Boolean|false} [options.smallText]
 * If true, use a font size of 14px instead of 16px for both the index and the name.
 *
 * @param {Boolean|undefined} [options.onBackground]
 * Optional, if true, render content label for a dark background.
 *
 * @param {String} [options.linkType]
 * Unless hasNoLinkCallback is true, use this param to define what part of the controller to navigate to if the
 * ContentLabel gets clicked. By default this is 'show', meaning it will link to ':modelCollectionType/show/:modelId'
 * For example: 'sections/show/695209'.
 *
 * @param {String|undefined} [options.linkSuffix]
 * Optional information to append after link mentioned above. For example: 'sections/show/695209#48266'
 *
 * @param {Boolean|undefined} [options.hasNoLink]
 * If true, disable default click behaviour, making the component static.
 *
 * @param {Function|undefined} [options.hasNoLinkCallback]
 * Optional callback to replace default click behaviour.
 *
 * @param {Boolean|false} [options.truncateName]
 * Optional, if true, use ellipsis to truncate the name of the label when needed.
 */
export default class ContentLabel extends BaseView {

    initialize({
        noMargin,
        labelPrefix,
        labelSuffix,
        isCompact,
        smallText = false,
        linkType = 'show',
        linkSuffix,
        hasNoLink,
        hasNoLinkCallback,
        publishedContentTree,
        displayFirstNameFirst = false,
        truncateName = false
    }) {

        if (this.model instanceof Backbone.Model === false) {
            window.sentry.withScope(scope => {
                scope.setExtra('model', this.model)
                window.sentry.captureMessage('ContentLabel was used without a valid model')
            })
            return
        }

        // Create the element, passing the styling with it.
        this.setElement(Template({

            Styles,

            hasNoLink,

            // If the no margin option is passed this will show a &nsbp; character
            noMargin,

            // Add optional string in front of content label.
            labelPrefix,

            // Add optional string after the content label.
            labelSuffix,

            truncateName

        }));

        // URL that redirects to content in question from a partical type of view:
        // ['show','progress','results','exercise','annotate','author']
        // If options.linkType is undefined, 'show' is used instead.
        // linkSuffix is used to append some extra information at the end of the link.
        this.contentLink = '/';
        this.linkSuffix = linkSuffix || '';
        this.displayFirstNameFirst = displayFirstNameFirst
        this.isCompact = isCompact
        this.smallText = smallText
        this.publishedContentTree = publishedContentTree

        this.contentLabelModel = new ContentLabelModel(
            null, {
                layerModel: this.model,
                model: this.model,
                isCompact,
                publishedContentTree,
                displayFirstNameFirst,
            })

        const name = this.contentLabelModel.get('name')
        const index = this.contentLabelModel.get('indexForContentLabelComponent')
        const isNumberless = this.contentLabelModel.get('isNumberless')

        this.el.title = this.contentLabelModel.get('title')

        if (typeof index === 'number' || typeof index === 'string') {
            const elem = document.createElement('span')
            elem.textContent = index
            this.indexElement.appendChild(elem)
        }

        this.$('.js-name').text(name)

        // Apply the passed styling from the constructor's options
        this.applyStyling(arguments[0])
        this.addLayerStyling()

        if (!hasNoLink) {
            // Add a link to the contentlabel
            this.setContentLink(linkType);
        } else if (hasNoLinkCallback) {
            // If label does not link to anywhere for a special reason,
            // replace click listener with custom behaviour.
            this.$el.on('click', hasNoLinkCallback);
        }

        // If content doesn't need to have visibile index, apply the numberless styling with hides the index
        // and replaces it with a • symbol.
        if (isNumberless) {
            this.el.classList.add(Styles['numberless'])
            this.indexElement.insertAdjacentHTML('afterbegin', ShapeList['arrow-forward'])
        }

    }

    setContentLink(linkType, doNotAddLink = false) {

        if (this.model.constructor.type === 'group') {
            // If user is a student wanting to view their results, navigate to /students/results instead.
            if (Backbone.Model.user.get('is_student') && linkType === 'results') {
                this.contentLink = '/students/' + linkType + '/' + this.model.id + '/' + Backbone.Model.user.id
            } else {
                this.contentLink = '/groups/' + linkType + '/' + this.model.id;
            }
        } else if (this.model.constructor.type === 'chapter') {
            this.contentLink = '/chapters/' + linkType + '/' + this.model.id;
        } else if (this.model.constructor.type === 'section') {
            this.contentLink = '/sections/' + linkType + '/' + this.model.id;
        } else if (this.model.constructor.type === 'activity') {
            this.contentLink = '/activities/' + linkType + '/' + this.model.id;
        } else if (this.model.constructor.type === 'taskGroup') {
            var activityModel = this.model.getActivityModel();
            this.contentLink = '/task_groups/' + linkType + '/' + activityModel.id + '/' +
                    this.model.id;
        } else if (this.model.constructor.type === 'student') {

            // Get the progress information from the model.
            var progressInformation = this.model.get('progressInformation');

            // Get the results information from the model.
            var resultsInformation = this.model.get('resultsInformation');

            // Check if the type if progress and there is progress information available
            if (linkType === 'progress' && progressInformation) {

                // Check if the taskgroup is known
                if (progressInformation.taskGroupId) {
                    this.contentLink =
                            '/students/' +
                            linkType +
                            '/' +
                            progressInformation.activityId +
                            '/' +
                            this.model.id +
                            '/task_group_' +
                            progressInformation.taskGroupId;

                    // Else go to the activity without task group
                } else {
                    this.contentLink =
                            '/students/' +
                            linkType +
                            '/' +
                            progressInformation.activityId +
                            '/' +
                            this.model.id;
                }

            } else if (linkType === 'results' && resultsInformation) {

                if (resultsInformation.isAnswers) {
                    this.contentLink = '/answers/';
                } else {
                    this.contentLink = '/students/';
                }
                this.contentLink += linkType + '/' + resultsInformation.groupID + '/' + this.model.id;

                // When not progress or no progress information available, go to normal content link
            } else {
                this.contentLink = '/students/' + linkType + '/' + this.model.id;
            }
        }

        if (!doNotAddLink) {
            // Set href attribute of the <a> tag.
            this.el.href = this.contentLink + this.linkSuffix
        }

    }

    /**
     * applyStyling
     *
     * This function regulates the applying of the styling passed through the
     * constructor's options.
     *
     * @param  {Object} options options passed to the constructor
     */
    applyStyling(options) {

        // Use less margin between contentlabel and surrounding elements
        if (options.noMargin) {
            this.$el.addClass(Styles['has-no-margin']);
        }

        if (options.isCompact) {
            this.$el.addClass(Styles['label--is-compact']);
        }

        if (options.smallText) {
            this.$el.addClass(Styles['label--small-text']);
        }

        if (!options.hasNoLink ||
                (options.hasNoLink && options.hasNoLinkCallback)
        ) {
            // Style the element so it looks clickable
            this.$el.addClass(Styles['label--is-clickable']);
        }

        // Apply semi transparent styling intended for use of content label behind a dark background.
        if (options.onBackground) {
            this.$el.addClass(Styles['label--on-background'])
        }
    }

    addLayerStyling() {
        switch (this.model.constructor.type) {
            case 'group': {
                this.indexElement.classList.add(Styles['label--is-group'])
                this.indexElement.insertAdjacentHTML('afterbegin', ShapeList.people)
                break
            }
            case 'activity': {

                if (this.model.get('type') === 'adaptive_student') {
                    if (this.isCompact) {
                        this.indexElement.insertAdjacentHTML('afterbegin', ShapeList.exercises)
                    } else {
                        const sectionModel = this.model?.getSectionModel()
                        const chapterModel = sectionModel?.getChapterModel()

                        if (chapterModel) {
                            this.indexElement.insertAdjacentHTML(
                                'beforeend',
                                ShapeList.exercises
                            )
                        }
                    }
                }
                break
            }
            case 'teacher':
            case 'user':
            case 'student': {
                this.indexElement.classList.add(Styles['label--avatar'])
                this.addChildView(
                    new Avatar({
                        avatar: this.model.get('avatar'),
                        model: this.model,
                    }),
                    '.js-index'
                )
            }
        }
    }

    get indexElement() {
        return this.el.querySelector('.js-index')
    }

}
