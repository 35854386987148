import Styles from './Source2.scss';

import Template from './Source2.hbs';
// Embed source view
export default class Source2 extends BaseView {

    initialize() {
        // change youtube embeds so that youtube-nocookie is used
        // to prevent youtube from showing cookie consent
        var re = /www\.youtube\.com\/embed\//;
        var m;
        if ((m = re.exec(this.model.get('source'))) !== null) {
            if (m.index === re.lastIndex) {
                re.lastIndex++;
            }
            this.model.set('source', this.model.get('source').replace('youtube', 'youtube-nocookie'));
        }

        this.setElement(Template({
            Styles,
            title: this.model.get('title'),
            embedCode: this.model.get('source')
        }));

    }

}
