import Styles from './Avatar.scss';

import Template from './Avatar.hbs';
export default BaseView.extend({

    /**
     * Avatar
     *
     * this.model {StudentModel}
     *
     * @param {Boolean|undefined} hasMargin         Has extra margin.
     * @param {Boolean|undefined} isHero            if component is used inside hero.
     * @param {String} label                        Text displayed right of avatar.
     * @param {Boolean|undefined} onHoverShowLabel  Enables label to be only shown on hover.
     */
    initialize({
        avatar,
        hasMargin,
        isHero,
        label,
        onHoverShowLabel,
    }) {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            avatarImage: avatar,
            hasMargin,
            isHero,
            label,
            onHoverShowLabel,
            hasOnlineStatus: this.model && this.model.constructor.type === 'student',
        }));

    },

    onShowAndLoaded() {
        if (this.model) {
            this.setOnlineStateCircle();
            // change online state circle if user's connectivity changes
            this.listenTo(this.model, 'change:online-state', this.setOnlineStateCircle.bind(this))
        }
    },

    /**
         * Visually display online status student by changing the circle right to the avatar
         * green when online and grey when offline.
         */
    setOnlineStateCircle() {
        if (!this.model.has('online-state')) {
            return;
        }

        const onlineStateCircle = this.el.querySelector('.js-online-state');

        if (onlineStateCircle) {
            onlineStateCircle.dataset.onlineState = this.model.get('online-state')
        }
    }
});
