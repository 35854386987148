import Styles from './AnswerCounter.scss';

import Template from './AnswerCounter.hbs';
import Avatar from 'views/components/avatar/Avatar';
import AnimatingAvatar from './animatingAvatar/AnimatingAvatar';

export default BaseView.extend({

    reasonableTimeToAnswer: 10000,

    events: {
        'click .js-unanswered-counter': 'onClickUnansweredCounter'
    },

    initialize(options) {

        _.bindAll(
            this,
            'increment',
            'onReasonableTimeToAnswerHasPassed'
        );

        this.presentation = options.presentation;

        this.isShowingAvatars = false;

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            hasNotAnswered: (
                this.presentation.studentsInPresentation.length - this.presentation.studentsThatFilledAnswer.length
            ),
            hasAnswered: this.presentation.studentsThatFilledAnswer.length
        }));

        // Wait 10 seconds, then after that check during every increment if
        // there are 5 students or less who need to answer
        setTimeout(this.onReasonableTimeToAnswerHasPassed, this.reasonableTimeToAnswer);

    },

    /**
     * onClickUnansweredCounter
     *
     * This function handles clicks on the unanswered counter.
     * It will determine if the avatars of students who didn't answer need to show or hide.
     *
     */
    onClickUnansweredCounter() {

        if (this.isShowingAvatars) {
            this.hideStudentsWithoutAnswer();
        } else {
            this.showStudentsWithoutAnswer();
        }

        // Reverse the boolean state of isShowingAvatars
        this.isShowingAvatars = !this.isShowingAvatars;
    },

    /**
     * increment
     *
     * This function increments the answer and let the avatar from the student
     * who answered perform an animation.
     *
     * @param  {StudentModel} studentModel  Model of the student who answered
     * @param  {Boolean} silent             if true, do not display an animation
     */
    increment(studentModel, silent = false) {

        // Subtract amount of students following the teacher by amount of students who answered
        var numberOfStudentsWithoutAnswer =
            this.presentation.studentsInPresentation.length - this.presentation.studentsThatFilledAnswer.length;

        // Check if the avatars of the students who haven't answered yet should be shown
        this.checkIfStudentsWithoutAnswerShouldShow(studentModel, numberOfStudentsWithoutAnswer);

        // Display the updated number of students who haven't answered yet
        this.$('.js-has-not-answered-counter').text(numberOfStudentsWithoutAnswer);

        // Display the updated number of students who have answered
        this.$('.js-has-answered-counter').text(this.presentation.studentsThatFilledAnswer.length)

        // Animate avatar of the student the answered
        if (!silent) {
            this.addChildView(
                new AnimatingAvatar({
                    avatar: studentModel.get('avatar'),
                    hasMargin: true
                }), '.js-animating-avatars'
            )
        }
    },

    /**
     * checkIfStudentsWithoutAnswerShouldShow
     *
     * This function checks if the avatars of the students who still
     * need to answer have to render.
     *
     * @param  {Backbone.Model} studentModel - Model of the student who needs to be taken from the list of
     *                                         students who didn't answer. Can be undefined.
     * @param  {number} numberOfStudentsWithoutAnswer - number of students who didn't answer yet
     */
    checkIfStudentsWithoutAnswerShouldShow(studentModel, numberOfStudentsWithoutAnswer) {

        if (
            studentModel &&
            // If the answer counter already created a list of
            // avatars of students who still need to answer
            this.unansweredAvatars &&

            // And we can find the passed student model id in the list
            // of unanswered avatars
            this.unansweredAvatars[studentModel.id]
        ) {
            this.unregisterAndDestroyChildView(this.unansweredAvatars[studentModel.id]);
        }
        if (

            this.isShowingAvatars !== true &&

            (
                // When the set amount of time to answer has passed
                this.reasonableTimeToAnswerHasPassed &&

                // And the number of students who need to answer is 5 or less
                numberOfStudentsWithoutAnswer <= 5
            )
        ) {

            // Show the students who still need to answer
            this.showStudentsWithoutAnswer();
            this.isShowingAvatars = true;
        }

    },

    /**
     * onReasonableTimeToAnswerHasPassed
     *
     * This function is triggered when the timeOut has passed.
     * It will set the boolean that keeps track of the timeOut has passed to true.
     *
     */
    onReasonableTimeToAnswerHasPassed() {

        this.reasonableTimeToAnswerHasPassed = true;

        var numberOfStudentsWithoutAnswer = this.presentation.studentsInPresentation.length -
            (this.presentation.studentsThatFilledAnswer.length || 0);

        this.checkIfStudentsWithoutAnswerShouldShow(undefined, numberOfStudentsWithoutAnswer);
    },

    /**
     * showStudentsWithoutAnswer
     *
     * This function is triggered when the reasonableTimeToAnswerHasPassed boolean is true
     * and the amount of students remaining is 5 or less.
     *
     */
    showStudentsWithoutAnswer() {

        // Check which students didn't answer yet by looking for the student id's
        // that are not in both arrays
        var studentIdsWithoutAnswer = this.getStudentsIdsWithoutAnswer();

        // Render the avatars of students that didn't answer
        this.renderAvatarsForStudentsWithoutAnswer(studentIdsWithoutAnswer);
    },

    /**
     * hideStudentsWithoutAnswer
     *
     * This function hides and destroys any displayed avatars of student who didn't anaswer
     *
     */
    hideStudentsWithoutAnswer() {
        var studentIdsWithoutAnswer = this.getStudentsIdsWithoutAnswer();

        _.each(studentIdsWithoutAnswer, function(studentId) {
            var studentWithUnansweredAvatar = this.unansweredAvatars[studentId];
            if (studentWithUnansweredAvatar) {

                TweenMax.fromTo(studentWithUnansweredAvatar.$el, {
                    opactiy: 1,
                }, {
                    opacity: 0,
                    duration: 0.5,
                    onComplete: () => {
                        this.unregisterAndDestroyChildView(studentWithUnansweredAvatar);
                    }
                });

            }
        }, this);

    },

    getStudentsIdsWithoutAnswer() {

        // Check which students are in the presentation but didn't answer yet
        return _.difference(
            this.presentation.studentsInPresentation.pluck('id'), this.presentation.studentsThatFilledAnswer
        ) || [];
    },

    /**
     * renderAvatarsForStudentsWithoutAnswer
     *
     * This function takes the list of students that didnt answer and
     * renders their related avatars.
     * It will also keep a list of these avatars to make sure we can delete the right one, when
     * a student has given an answer.
     *
     * @param  {Array} studentIdsWithoutAnswer - Array of student id's beloning to students who didn't answer
     */
    renderAvatarsForStudentsWithoutAnswer(studentIdsWithoutAnswer) {

        if (!this.unansweredAvatars) {

            // Keep a list of the unanswered avatars that will be rendered
            this.unansweredAvatars = [];
        }

        // Loop through list of student ids
        _.each(studentIdsWithoutAnswer, function(studentId) {

            if (!_.contains(this.unansweredAvatars, studentId)) {

                // Get the related student model
                var studentModel = this.presentation.model.getGroupModel().students.get(studentId);

                // Construct an avatar view
                var avatarView = new Avatar({
                    avatar: studentModel.get('avatar'),
                    label: studentModel.first_name_last_name()
                });

                // Fade in the avatar view
                TweenMax.from(avatarView.$el, 1, {
                    opacity: 0
                });

                // Create an object from the studentId with the avatar view inside
                this.unansweredAvatars[studentId] = avatarView;

                // Add the avatar to the DOM
                this.addChildView(avatarView, '.js-unanswered-avatars');
            }
        }, this);
    }

});
