import Styles from './CopyToPortfolioView.scss';

import Template from './CopyToPortfolioView.hbs';
import Button from 'views/components/button/Button'
import PortfolioCollection from 'collections/PortfolioCollection'
import PortfolioModel from 'models/PortfolioModel'
import CopyToPortfolioModal from './copyToPortfolioModal/CopyToPortfolioModal'

export default class CopyToPortfolioView extends BaseView {

    async copy() {

        const selection = Backbone.View.Components.modal.subView.chooser.getSelection()
        if (!selection) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Select an option.')
            )
            return
        }

        _.last(Backbone.View.Components.modal.getButtons())?.disable(true)

        const portfolioProject = new PortfolioModel({
            id: selection.value,
        })
        await portfolioProject.fetch()
        this.saveSource(portfolioProject)
    }

    get copyForTaskType() {
        return window.i18n.gettext('Copy task to portfolio')
    }

    initialize({
        saveSource,
    }) {

        this.bindAll([
            'openPortfolioChooser',
        ])

        this.saveSource = saveSource

        this.setElement(Template({
            Styles,
        }))

        this.button = this.addChildView(
            new Button({
                label: this.copyForTaskType,
                callback: this.openPortfolioChooser,
                inline: true,
                icon: 'folder',
                theme: 'outlined',
            }),
            '.js-portfolio-button'
        )

        this.collection = new PortfolioCollection([], Backbone.Model.user.id)
    }

    openPortfolioChooser() {
        if (Backbone.Model.user.get('is_teacher')) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext(
                    'This feature is only available for students'
                ),
                'info'
            )
            return
        }

        Backbone.View.Components.modal.open(CopyToPortfolioModal, {
            title: window.i18n.gettext('To which portfolio do you want to copy the task?'),
            fetchCollection: this.collection,
            buttons: {
                [window.i18n.gettext('Cancel')]: {
                    callback: Backbone.View.Components.modal.close,
                    theme: 'secondary',
                },
                [window.i18n.gettext('Save')]: {
                    callback: () => this.copy(),
                }
            }
        })

    }

}
