var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td data-column=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cell") : depth0)) != null ? lookupProperty(stack1,"columnIndex") : stack1), depth0))
    + "\" data-gap-type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cell") : depth0)) != null ? lookupProperty(stack1,"gapType") : stack1), depth0))
    + "\">\n    <input type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cell") : depth0)) != null ? lookupProperty(stack1,"gapType") : stack1), depth0))
    + "\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cid") : depth0), depth0))
    + "-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cell") : depth0)) != null ? lookupProperty(stack1,"rowIndex") : stack1), depth0))
    + "\">\n    <div>\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"checkmark","input-checkmark",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":49}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross","input-cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":41}}})) != null ? stack1 : "")
    + "\n    </div>\n</td>\n";
},"useData":true});