import Styles from './NumberInput.scss';
import Button from 'views/components/button/Button'
import QuickInput from 'views/components/quickInput/QuickInput'

export default class NumberInput extends QuickInput {

    get step() {
        const step = Number(this.inputElement.attr('step'))
        return Number.isFinite(step) ? step : 1
    }

    set step(step) {
        if (Number.isFinite(step)) {
            this.inputElement.attr({step})
        }
    }

    get min() {
        const min = Number(this.inputElement.attr('min'))
        return Number.isFinite(min) ? min : -Infinity
    }

    set min(min) {
        if (Number.isFinite(min)) {
            this.inputElement.attr({min})
        } else if (!min) {
            this.inputElement.removeAttr('min')
        }
    }

    get max() {
        const max = Number(this.inputElement.attr('max'))
        return Number.isFinite(max) ? max : Infinity
    }

    set max(max) {
        if (Number.isFinite(max)) {
            this.inputElement.attr({max})
        } else if (!max) {
            this.inputElement.removeAttr('max')
        }
    }

    initialize({
        step = 1,
        min = 0,
        max = Infinity,
        displayInline = true,
        useCustomNumberInputButtons = true,
        fixedDecimals,
    }) {

        const options = arguments[0]

        options.type = 'number'
        options.keepValueOnCallback = true
        options.defaultIfEmpty = true
        options.displayInline = displayInline

        super.initialize(options)

        this.step = step
        this.min = min
        this.max = max

        // If fixedDecimals is not already defined, set fixedDecimals based on how many decimals in the step size.
        if (fixedDecimals === undefined) {
            fixedDecimals = Math.floor(this.step) === this.step ? 0 : this.step.toString().split('.')[1].length
            this.fixedDecimals = fixedDecimals
        }

        let decrementButton
        let incrementButton
        if (useCustomNumberInputButtons) {
            this.el.classList.add(Styles['number-input-custom-buttons'])
            decrementButton = this.addButton(false)
            incrementButton = this.addButton(true)
        }

        // On input event, set the input number to a fixed amount of decimals, toggling custom decrement/increment
        // buttons, and auto-resizing the input field.
        const onInputCallback = (e) => {
            const target = e.target
            const value = target.valueAsNumber
            // Only use fixed decimals when there's no inputType defined (user uses spinner buttons instead of typing)
            // and the user is also not doing a composition input.
            if (fixedDecimals && !e.inputType && !e.isComposing) {
                target.value = value.toFixed(this.fixedDecimals)
            }
            if (Number.isNaN(value)) {
                return
            }
            if (useCustomNumberInputButtons) {
                decrementButton.toggle(value > this.min)
                incrementButton.toggle(value < this.max)
            }
            if (displayInline) {
                super.autoResize()
            }
        }
        // When view is inline, use auto resize, but override QuickInput autoResize method
        // such that the onInputCallback of NumberInput is called before the autoResize method
        // to ensure the width of fixed decimal values are used.
        if (displayInline) {
            this.autoResize = onInputCallback
        }

        this.inputElement[0].addEventListener('input', onInputCallback)
        this.inputElement[0].dispatchEvent(new Event('input'))

    }

    addButton(isIncrementer) {
        return this.addChildView(new Button({
            label: isIncrementer ? '+' : '-',
            theme: 'secondary',
            inline: true,
            externalStyle: Styles['button'],
            callback: () => {
                if (isIncrementer) {
                    this.inputElement[0].stepUp()
                } else {
                    this.inputElement[0].stepDown()
                }
                this.inputElement[0].dispatchEvent(new Event('input'))
            }
        }), this.inputElement, isIncrementer ? 'after' : 'before')
    }

    getInput() {
        return Number(super.getInput())
    }

    // The triggerInputEvent parameter is optional to prevent this Event from being triggered
    // For example, when we update the input but don't want it to be handled like a user input change
    setInput(value, triggerInputEvent = true) {
        super.setInput(value)
        if (triggerInputEvent) {
            this.inputElement[0].dispatchEvent(new Event('input'))
        }
    }

    // Override this method to prevent moving focus back to input field after interacting with
    // the decrement/increment buttons.
    focusInput() {}

}
