import Styles from './Textarea.scss';

import Template from './Textarea.hbs';
import Util from 'util/util';

export default class Textarea extends BaseView {

    get events() {
        return {
            'input textarea': 'setTextareaHeight'
        }
    }

    /**
     *
     * @param {String} value                Initial value of text area
     * @param {String} placeholder          Text area placeholder value
     * @param {Boolean} autoFocus           If true, focus text area on init
     * @param {Boolean} defaultIfEmpty      If true, return initial default value when input field is empty
     * @param {Function} pasteCallback      Callback called after paste event
     * @param {Function} inputCallback      Callback when user hits enter or the button
     * @param {Boolean} skipValidation      If true, skips content sanitisation (WARNING: USE WITH CARE)
     * @param {Boolean} enableHTMLshortcuts If true, enable shortcuts to add markup HTML tags using keyboard commands
     * @param {Boolean} minLines = 3        If given, set minimum rows to given number instead of 3
     * @param {Boolean} noAutoCapitalize If true, disable auto capitalization
     * @param {Boolean} noAutoCorrect   If true, disable auto correct, auto complete and spell check
     * @param {String} label            Optional string to use as <label>
     *
     * @memberof Textarea
     */
    initialize({
        value,
        placeholder,
        autoFocus,
        defaultIfEmpty,
        pasteCallback,
        inputCallback,
        skipValidation,
        enableHTMLshortcuts,
        minLines = 3,
        noAutoCapitalize,
        noAutoCorrect,
        label,
    }) {

        _.bindAll(
            this,
            'enterHTMLshortcut'
        )

        // Placeholder value.
        this.placeholder = placeholder

        // Initial value.
        this.value = value

        // Return default value (or placeholder, if no default value is specified) when textarea is empty.
        this.defaultIfEmpty = defaultIfEmpty

        // Set the minimum amount of rows to use when resizing the textarea height
        this.minLines = minLines

        // If true, removal of script tags and other interactivity by validating the input as HTML is skipped.
        this.skipValidation = skipValidation

        this.pasteCallback = pasteCallback

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            placeholder: this.placeholder,
            value: this.value,
            noAutoCorrect,
            noAutoCapitalize,
            label,
            cid: this.cid,
        }));

        this.textAreaElement = this.$('textarea');
        this.textAreaElement.on('paste', pasteCallback);
        this.textAreaElement.on('input', inputCallback)

        // after the input view is loaded - set the height of the element
        this.textAreaElement.ready(() => {
            this.setTextareaHeight()

            // Set focus and cursor at end of input if autoFocus is true.
            if (autoFocus) {
                this.focus()
            }
        })

        // If true, allow users to press for example ctrl-b to insert <b> tags
        if (enableHTMLshortcuts) {
            this.el.querySelector('textarea').addEventListener('keydown', this.enterHTMLshortcut)
        }

    }

    getValue() {
        const value = this.textAreaElement.val()
        var s = (this.skipValidation ? value : Util.renderContentSafely(value)).trim()
        return this.defaultIfEmpty && s.length === 0 ? this.value || this.placeholder || s : s;
    }

    clearValue() {
        this.textAreaElement.val('');
    }

    setValue(value) {
        this.textAreaElement.val(value);
    }

    enable() {
        this.textAreaElement.prop('disabled', false)
    }

    disable() {
        this.textAreaElement.prop('disabled', true)
    }

    focus() {
        Util.placeCaret(this.textAreaElement[0])
    }

    // note: this function has been copied from Template 2 (open question).
    // Template 2 uses an own textarea element, and has a better text
    // area resizing function than this component had. Template 2's
    // textarea should eventually be removed
    setTextareaHeight() {

        const value = Util.renderContentSafely(this.textAreaElement.val()) || ''

        // When inputfield is empty, set default height for textarea element.
        if (!value) {
            this.textAreaElement.attr('rows', this.minLines)
        } else {
            // Create a dummy element to measure the height the textarea needs
            // We append a '.' character to prevent blank lines at the end from being skipped
            const dummyElement = $('<div></div>').addClass(Styles.dummy)
            // Set dummy element content equal to that of the text area. Add an extra character
            // create an empty line to suggests to the user they can type as many lines as
            // they want, except for when the textarea is in single line mode.
            dummyElement.html(Util.nl2br(_.escape(value + '\n' + (this.minLines === 1 ? '' : '.'))))
            this.textAreaElement.after(dummyElement)
            const rows = Math.max(
                this.minLines,
                Math.ceil(dummyElement.height() / parseInt(dummyElement.css('line-height')))
            )
            this.textAreaElement.attr('rows', rows)
            dummyElement.remove()
        }

    }

    // If the user presses ctrl-b, ctrl-i, ctrl-u, ctrl-= or ctrl-shift-= insert HTML tags around the selected
    // text or at the cursor position
    enterHTMLshortcut(e) {

        // On Apple devices check if command key is pressed, otherwise check if control key is pressed
        const os = window.uaparser.getOS().name
        if (os === 'Mac OS' || os === 'iOS' ? !e.metaKey : !e.ctrlKey) {
            return
        }

        let tag
        switch (e.keyCode) {
            case 66:
                tag = 'b'
                break
            case 73:
                tag = 'i'
                break
            case 85:
                tag = 'u'
                break
            case 187:
                tag = e.shiftKey ? 'sup' : 'sub'
                break
            default:
                return
        }

        e.stopPropagation()
        e.preventDefault()

        // Insert the HTML tag in the text
        const textarea = this.el.querySelector('textarea')
        const before = textarea.value.substr(0, textarea.selectionStart)
        const selection = textarea.value.substr(
            textarea.selectionStart,
            textarea.selectionEnd - textarea.selectionStart
        )
        const after = textarea.value.substr(textarea.selectionEnd)
        textarea.value = before + '<' + tag + '>' + selection + '</' + tag + '>' + after

        // Place the cursor after the closing tag (after the selection, 2 tag names, 5 characters such as <>)
        const cursorPosition = before.length + selection.length + (2 * tag.length) + 5
        textarea.setSelectionRange(cursorPosition, cursorPosition)

        // Trigger input event so that other views can update and the textarea can be resized
        textarea.dispatchEvent(new Event('input', { bubbles: true }))
    }
}
