var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"progress-bar--loaded") : stack1), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n    data-upload-url-identifier=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"urlIdentifier") : depth0), depth0))
    + "\"\n    class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"uploaded-file-container") : stack1), depth0))
    + "\"\n>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"preview-container") : stack1), depth0))
    + "\">\n        <button class=\"js-remove-file "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"remove-file-icon") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":67},"end":{"line":6,"column":86}}})) != null ? stack1 : "")
    + "</button>\n        <div class=\"js-icon-holder "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"file-tile") : stack1), depth0))
    + "\">\n            <div class=\"js-progress-bar "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"progress-bar") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isPreviousUpload") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":64},"end":{"line":8,"column":129}}})) != null ? stack1 : "")
    + "\"></div>\n            <div class=\"js-upload-percentage "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"upload-percentage") : stack1), depth0))
    + "\"></div>\n\n            <div class=\"js-upload-finished "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"upload-finished-container") : stack1), depth0))
    + "\">\n                <div class=\"js-upload-finished__icon "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"checkmark-icon") : stack1), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"checkmark",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":12,"column":80},"end":{"line":12,"column":103}}})) != null ? stack1 : "")
    + "</div>\n                <div class=\"js-upload-finished__label "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"finished-label") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Upload completed",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":13,"column":81},"end":{"line":13,"column":106}}}))
    + "</div>\n            </div>\n\n        </div>\n\n    </div>\n    <div class=\"js-metadata-container "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"metadata-container") : stack1), depth0))
    + "\">\n        <div class=\"js-file-name "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"filename") : stack1), depth0))
    + "\"></div>\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"meta") : stack1), depth0))
    + "\">\n            <div class=\"js-file-size "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"size") : stack1), depth0))
    + "\"></div>\n            <div class=\"js-warning-button "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"warning") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"alert",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":35}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});