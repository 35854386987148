import Styles from './TableMaker.scss';

import Template from './TableMaker.hbs';
import Checkbox from 'views/components/checkbox/Checkbox';
import NumberInput from 'views/components/quickInput/numberInput/NumberInput'

export default BaseView.extend({

    chosenOptions: {
        // Default with border
        border: true,

        // Default 2 rows and 2 columns.
        rows: [0, 1],
        cols: [0, 1],
    },

    initialize() {

        _.bindAll(this,
            'onClickCheckbox'
        );

        this.setElement(Template({
            Styles,
            rows: this.chosenOptions.rows.length,
            cols: this.chosenOptions.cols.length
        }));

        const numbersOfRowsLabel = this.$('.js-number-of-rows-input')
        this.numberOfRowsInput = this.addChildView(new NumberInput({
            min: 1,
            defaultValue: this.chosenOptions.rows.length
        }), numbersOfRowsLabel, 'after')
        numbersOfRowsLabel.attr({for: this.numberOfRowsInput.cid})

        const numberOfColumnsLabel = this.$('.js-number-of-cols-input')
        this.numberOfColumnsInput = this.addChildView(new NumberInput({
            min: 1,
            defaultValue: this.chosenOptions.cols.length
        }), numberOfColumnsLabel, 'after')
        numberOfColumnsLabel.attr({for: this.numberOfColumnsInput.cid})

        // Table border checkbox
        if (ACL.checkRole(ACL.roles.TEACHER)) {
            this.addChildView(new Checkbox({
                isChecked: this.chosenOptions.border,
                label: window.i18n.gettext('Border'),
                callback: this.onClickCheckbox
            }), '.js-border-checkbox')
        }

    },

    onClickCheckbox(state) {
        this.chosenOptions.border = state;
    },

    getRange(n) {
        return Array.from(Array(
            Number.isSafeInteger(n) && n >= 1 ? n : 1
        ).keys())
    },

    getOptions() {
        this.chosenOptions.rows = this.getRange(parseInt(this.numberOfRowsInput.getInput(), 10))
        this.chosenOptions.cols = this.getRange(parseInt(this.numberOfColumnsInput.getInput(), 10))
        return this.chosenOptions;
    }
});
