var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SharedStyles") : depth0)) != null ? lookupProperty(stack1,"question") : stack1), depth0))
    + "\">\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"SharedStyles") : depth0)) != null ? lookupProperty(stack1,"question__instruction") : stack1), depth0))
    + " js-instruction\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"questionLabel") || (depth0 != null ? lookupProperty(depth0,"questionLabel") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"questionLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":71},"end":{"line":3,"column":88}}}) : helper)))
    + "</div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"multiplechoice__question") : stack1), depth0))
    + " js-question\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"questionValue") || (depth0 != null ? lookupProperty(depth0,"questionValue") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"questionValue","hash":{},"data":data,"loc":{"start":{"line":4,"column":65},"end":{"line":4,"column":82}}}) : helper)))
    + "</div>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"multiplechoice__answers") : stack1), depth0))
    + " js-answers\"></div>\n\n</div>\n";
},"useData":true});