module.exports = function(shapeName, cssClass) {
    const ShapeList = require('util/ShapeLoader')
    let shape = ShapeList[shapeName]

    if (typeof cssClass === 'string') {
        shape = $(shape).attr('class', cssClass);
        shape = $(shape).prop('outerHTML');
    }

    return shape;
};
