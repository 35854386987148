import Styles from './Table.scss';

import Template from './Table.hbs';
import Util from 'util/util'

export default class Template22Table extends BaseView {

    get events() {
        return {
            'focus td[contenteditable="true"]': 'onFocusGap',
            'blur td[contenteditable="true"]': 'onBlurGap',
            'keydown td[contenteditable="true"]': 'onKeyDownGap'
        }
    }

    get gapCells() {
        return this.tableData.filter((cell) => cell.isGapCell)
    }

    initialize({
        tableData = [],
        columnWidths = [],
        response,
        isReadOnly
    }) {

        this.tableData = this.getRectangularTableData(tableData)
        this.response = response

        this.setElement(this.generateTemplate(tableData, columnWidths))

        this.setupTable(isReadOnly)

        // This fixes a Firefox bug where it inserts a <br> in contenteditables, which prevents :empty from working
        // See https://bugzilla.mozilla.org/show_bug.cgi?id=1615852
        if (window.uaparser.getBrowser().name === 'Firefox') {
            setTimeout(() => {
                for (const cell of this.el.querySelectorAll('td[contenteditable=true]')) {
                    if (cell.innerText === '\n') {
                        cell.innerText = ''
                    }
                }
            }, 0)
        }

    }

    getNumberOfColumns(tableData) {
        const maxColumnIndex = _.max(tableData, 'columnIndex').columnIndex
        return maxColumnIndex ? maxColumnIndex + 1 : 0
    }

    /**
     * Transforms input table data into a table which is rectangular, meaning it each row has the
     * same number of columns and visa-versa. This prevents Table from rendering a "ragged" table
     * where missing empty cells make other cells allign incorrectly.
     *
     * @param {Object} tableData        original table data object
     * @returns {Object} transformed    tableData object
     */
    getRectangularTableData(tableData) {
        const numberOfColumns = this.getNumberOfColumns(tableData)
        const tableRows = []
        for (const cell of tableData) {
            // For the total number of columns, create a cell for each in the current row,
            // then replace this cell with one from the data if present at that row and column index.
            if (Array.isArray(tableRows[cell.rowIndex]) === false) {
                tableRows[cell.rowIndex] = _.times(numberOfColumns, (columnIndex) => {
                    return {
                        rowIndex: cell.rowIndex,
                        columnIndex
                    }
                })
            }
            tableRows[cell.rowIndex][cell.columnIndex] = cell
        }
        return _.sortBy(_.sortBy(_.flatten(tableRows).filter(Boolean), 'columnIndex'), 'rowIndex')
    }

    /**
     *
     * @param {Object} tableData        table data object
     * @param {Array} columnWidths      optional array of custom column widths
     * @returns {String} Template to become the DOM representation of the table data
     */
    generateTemplate(tableData, columnWidths = []) {
        const numberOfColumns = this.getNumberOfColumns(tableData)
        const headerColumns = _.times(numberOfColumns, (columnIndex) => {
            let columnWidth = columnWidths[columnIndex] || {}
            if (columnWidth.percentage) {
                columnWidth = `${columnWidth.percentage}%`
            } else if (columnWidth.px) {
                columnWidth = `${columnWidth.px}px`
            } else {
                columnWidth = false
            }
            return {
                columnIndex,
                columnWidth
            }
        })

        return Template({
            Styles,
            tableRows: Object.values(_.groupBy(this.tableData, 'rowIndex')),
            headerColumns,
            cid: this.cid,
        })
    }

    /**
     * Applies content and properties to the table element.
     *
     * @param {Boolean} isReadOnly
     * If true, disable the contenteditable of all table cell elements.
     */
    setupTable(isReadOnly) {

        for (const cell of this.tableData) {

            if (cell.isGapCell) {
                this.setupCellGapElement(cell, isReadOnly)
            }

            this.applyCellElementStyling(cell)

            this.setCellElementContent(cell)

        }
    }

    /**
     * Get a cell from the data representation of the table
     *
     * @param {Number} rowIndex row
     * @param {Number} columnIndex column
     * @returns {Object} cell data object
     */
    getCell(rowIndex, columnIndex) {
        // Allow loose comparision since the indexes in the response may be saved as strings instead of numbers.
        // eslint-disable-next-line eqeqeq
        return this.tableData.find(c => c.rowIndex == rowIndex && c.columnIndex == columnIndex)
    }

    /**
     * Get a cell element (TD) from the DOM
     *
     * @param {Number} rowIndex row
     * @param {Number} columnIndex column
     * @returns {HTMLTableCellElement} table cell element
     */
    getCellElement(rowIndex, columnIndex) {
        return this.el.querySelector(Template22Table.getCellElementSelector(rowIndex, columnIndex))
    }

    static getCellElementSelector(rowIndex, columnIndex) {
        return `table tr[data-row="${rowIndex}"] td[data-column="${columnIndex}"]`
    }

    /**
     * Get sanitized gap cell text content from a student response (if present) or the table data itself
     *
     * @param {Number} rowIndex     row
     * @param {Number} columnIndex  column
     * @param {Boolean} showAnswer  show correct answer for this gap cell
     * @returns {String} gap cell text
     */
    getGapText(rowIndex, columnIndex, showAnswer) {
        if (showAnswer) {
            const cell = this.getCell(rowIndex, columnIndex)
            if (cell.isGapCell && cell.cellText) {
                return Util.stripTags(Util.normaliseCharacters(cell.cellText))
            }
        }
        if (this.response) {
            // Allow loose comparision since the student response indexes may be saved as
            // strings instead of numbers.
            // eslint-disable-next-line eqeqeq
            const response = this.response.find(c => c.row == rowIndex && c.column == columnIndex) || {}
            if (response.text) {
                return Util.stripTags(Util.normaliseCharacters(response.text))
            }
        }
        return ''
    }

    /**
     * Sets a cell element for a given cell to be editable or not if it's a gap
     *
     * @param {Object} cell cell data
     * @param {Boolean} isReadOnly  can user edit inputs
     */
    setupCellGapElement(cell, isReadOnly) {
        const cellElement = this.getCellElement(cell.rowIndex, cell.columnIndex)
        cellElement.contentEditable = cell.isGapCell && !isReadOnly ? 'true' : 'false'
    }

    /**
     * Apply styling to the cell element based on the formattings array of corresponding cell data
     *
     * @param {Object} cell cell data
     */
    applyCellElementStyling(cell) {

        if (!Array.isArray(cell.formattings)) {
            return
        }

        const cellElement = this.getCellElement(cell.rowIndex, cell.columnIndex)

        // Remove existing classes and apply classes from formattings array
        cellElement.classList.remove(...cellElement.classList)
        for (const formatting of cell.formattings) {
            cellElement.classList.add(Styles[formatting])
        }

        if (cell.formattings.includes('is-merged')) {
            const previousCellElementInRow = this.getCellElement(cell.rowIndex, cell.columnIndex - 1)
            if (previousCellElementInRow) {
                previousCellElementInRow.colSpan = 2
            }
        }

    }

    /**
     * Set cell element content based on its corresponding cell data object.
     * Remove formatting depending on if it is a gap or not.
     *
     * @param {Object} cell cell data
     * @param {Boolean} showAnswer  show correct answer for this gap cell
     */
    setCellElementContent(cell, showAnswer) {

        const cellElement = this.getCellElement(cell.rowIndex, cell.columnIndex)

        if (cell.isGapCell) {
            cellElement.innerText = this.getGapText(cell.rowIndex, cell.columnIndex, showAnswer)
        } else if (cell.cellText) {
            cellElement.innerHTML = Util.renderContentSafely(cell.cellText)
        }

    }

    onFocusGap(e) {
        e.stopPropagation()
        Util.placeCaret(e.currentTarget)
    }

    onBlurGap(e) {
        e.stopPropagation()
        e.currentTarget.innerText = Util.normaliseCharacters(e.currentTarget.innerText)
    }

    onKeyDownGap(e) {
        const cellElement = e.currentTarget
        const cellRow = cellElement.parentElement
        let nextCellElement
        if (e.key === 'Enter') {
            // Allow for inserting line breaks in non gap cells when pressing shift+enter.
            if (e.shiftKey && !cellElement.dataset.hasOwnProperty('isGap')) {
                return
            }
            this.stopAllEvents(e)

            cellElement.blur()

            // If there is a row below that of e.currentTarget and there is a editable cell in the same column as
            // cellElement, focus on that cell when the user hits the Enter key.
            nextCellElement = cellRow.nextElementSibling?.cells[cellElement.cellIndex]
        } else if (!cellElement.hasChildNodes()) {
            // When cell element is empty, allow the arrow keys to jump to adjacent cells.
            switch (e.key) {
                case 'ArrowLeft':
                    nextCellElement = cellRow.cells[cellElement.cellIndex - 1]
                    break
                case 'ArrowRight':
                    nextCellElement = cellRow.cells[cellElement.cellIndex + 1]
                    break
                case 'ArrowUp':
                    nextCellElement = cellRow.previousElementSibling?.cells[cellElement.cellIndex]
                    break
                case 'ArrowDown':
                    nextCellElement = cellRow.nextElementSibling?.cells[cellElement.cellIndex]
                    break;
            }
        }

        if (
            nextCellElement &&
            nextCellElement.isContentEditable
        ) {
            nextCellElement.focus()
        }
    }

}
