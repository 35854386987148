var Handlebars = require("../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../../../../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"lightbulb",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":55}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"card") : stack1), depth0))
    + "\">\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"card__answer-side") : stack1), depth0))
    + " js-answer-card\">\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"answer") : stack1), depth0))
    + "\">\n            <div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasIcon") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":17},"end":{"line":6,"column":62}}})) != null ? stack1 : "")
    + "</div>\n            <div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"response") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n        </div>\n\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"folded__layer") : stack1), depth0))
    + " js-folded-layer\">\n            <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"folded__icon") : stack1), depth0))
    + " js-fold-icon\">\n                "
    + ((stack1 = __default(require("../../../../../../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-drop-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":45}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"card__name-side") : stack1), depth0))
    + " js-name-card\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n            <div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasIcon") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":17},"end":{"line":19,"column":62}}})) != null ? stack1 : "")
    + "</div>\n            <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"backSide") : depth0), depth0))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});