import Styles from './Chooser.scss';

import Template from './Chooser.hbs';
import Checkbox from 'views/components/checkbox/Checkbox'
import RadioButton from 'views/components/radioButton/RadioButton'
import ContentLabel from 'views/components/contentLabel/ContentLabel'

export default BaseView.extend({

    /**
     * Chooser
     *
     * Simple list of items, where each item has a select input in the form of a radio button or checkbox.
     *
     * @param {Boolean|undefined} useContentLabel       If true, assume items in the list are models which can be
     *                                                  represented using the ContentLabel component (ChapterModel,
     *                                                  SectionModel, StudentModel, etc.).
     *
     * @param {Boolean|undefined} isCompactContentLabel If true and useContentLabel is true, render compact version
     *                                                  of ContentLabel.
     * @param {Array} list                              Array of objects, where if useContentLabel is not true, each
     *                                                  object has an unique 'id' attribute, a 'label' attribute for
     *                                                  display purposes and an optional 'isChecked' attribute to
     *                                                  indicate if this item is checked by default.
     * @param {Boolean|undefined} isMultiSelect         If true, use checkboxes instead of radio buttons.
     * @param {String|undefined} selectAllText          If isMultiSelect is true, use this a custom label next to the
     *                                                  select all items checkbox on the top of the list.
     * @param {Number|undefined} defaultIndex           If isMultiSelect is not true, use this to indicate the default
     *                                                  selected radio button. Otherwise select the first radio button.
     * @param {boolean} showSelectAllInput              If true, show option to select/deselect all options in the list.
     * @param {String|undefined} emptyStateMessage      Optional message to show if the list has a size of 0.
     */
    initialize({
        useContentLabel,
        isCompactContentLabel,
        list,
        isMultiSelect,
        selectAllText,
        defaultIndex = 0,
        showSelectAllInput,
        style,
        callback,
        processList,
        hasExternalStyling = false,
        emptyStateMessage = window.i18n.gettext('Empty')
    }) {

        // if Chooser is called from a modal with "fetchCollection", the list comes
        // from this.collection.
        this.list = list || processList(this.collection)
        this.isMultiSelect = isMultiSelect

        this.setElement(Template({Styles}));

        if (_.size(this.list) === 0) {
            this.$el.append(`<label>${emptyStateMessage}</label>`)
            return
        }

        const InputView = isMultiSelect ? Checkbox : RadioButton;

        _.each(this.list, (item, index) => {

            this.addChildView(new InputView({

                // Checkbox / Radio button label.
                label: useContentLabel ? this.addChildView(new ContentLabel({
                    model: item.model || item,
                    hasNoLink: true,
                    isCompact: isCompactContentLabel
                })).el.outerHTML : item.label,

                // Use view CID of this Chooser instance to group checkboxes / radio buttons.
                parentGroup: this.cid,

                // Checkbox / Radio button index in list.
                value: index,

                // Set default selection if defined.
                isChecked: isMultiSelect ? item.isChecked : defaultIndex === index,

                rightAlignedText: item.rightAlignedText,

                subText: item.subText,

                isDisabled: item.isDisabled,

                onClickDisabled: item.onClickDisabled,

                callback,

            }), this.$el);

        });

        // try to keep the base Chooser component as unstyled as possible
        // so other views can determine the styling.
        const chooserStyle = hasExternalStyling ?
            style :
            Styles[`list__style--${ style || 'default' }`]

        this.el.classList.add(chooserStyle)

        if (isMultiSelect && this.list.length > 1 && showSelectAllInput) {
            const selectAllInput = this.addChildView(new InputView({
                label: selectAllText || window.i18n.gettext('Select all items'),
                parentGroup: this.cid,
                value: 'ALL',
                isChecked: this.list.filter(item => !item.isDisabled && !item.isChecked).length === 0
            }), this.$el, 'prepend')
            selectAllInput.$el.addClass(Styles['select-all'])
            this.$('input').on('change', (e) => {
                if (e.target.value === 'ALL') {
                    const state = selectAllInput.getState()
                    _.each(this.el.querySelectorAll('input:not([value=ALL]):not([disabled])'), (inputElement) => {
                        inputElement.checked = state
                    })
                } else {
                    const selectionCount = this.getSelection().length
                    selectAllInput.setState(
                        selectionCount >= this.el.querySelectorAll('input:not([value=ALL]):not([disabled])').length
                    )
                    // TODO implement intermediate state
                    // // Selection is 0 or this.list.length, otherwise 'intermediate'.
                    // selectAllInput.setState(
                    //     selectionCount % this.list.length === 0 ?
                    //         selectionCount === this.list.length :
                    //         'intermediate'
                    // )
                }
            })
        }

        if (!isMultiSelect && this.list.length > 1 && showSelectAllInput) {
            const selectAllInput = this.addChildView(new InputView({
                label: selectAllText || window.i18n.gettext('Deselect all'),
                parentGroup: this.cid,
                value: -1,
                isChecked: defaultIndex < 0 || isNaN(defaultIndex)
            }), this.$el, 'prepend')
            selectAllInput.$el.addClass(Styles['select-all'])
        }

    },

    /**
    * getSelection
    *
    * If isMultiSelect is true, return list of currently selected items. Else return currently selected item.
    *
    * @returns {Array|Object}  Array of list item or a single list item
    */
    getSelection() {
        // Get checkbox/radiobutton value property to tell the selected index.
        if (this.isMultiSelect) {
            return _.reduce(this.el.querySelectorAll(':checked:not([value=ALL])'), (m, checkedInputElement) => {
                const selectedIndex = parseInt(checkedInputElement.value);
                if (this.list[selectedIndex]) {
                    m.push(this.list[selectedIndex]);
                }
                return m;
            }, []);
        }

        const checked = this.el.querySelector(':checked')
        if (!checked) {
            return null
        }
        return this.list[parseInt(checked.value)]
    },

    getInputs() {
        return this.childViews.filter(view => {
            if (this.isMultiSelect) {
                return view instanceof Checkbox
            }
            return view instanceof RadioButton
        } )

    }

});
