import Styles from './Source15.scss';

import Template from './Source15.hbs';
import Button from 'views/components/button/Button.svelte'
import Hedy from 'views/components/taskGroups/tasks/template38/hedy/Hedy'

export default class Source15 extends BaseView {

    static getLanguages() {
        return [{
            label: 'CSS',
            value: 'css'
        }, {
            label: 'Hedy',
            value: 'hedy'
        }, {
            label: 'HTML / XML',
            value: 'xml'
        }, {
            label: 'Java',
            value: 'java'
        }, {
            label: 'Javascript',
            value: 'javascript'
        }, {
            label: 'Lua',
            value: 'lua'
        }, {
            label: 'PHP',
            value: 'php'
        }, {
            label: 'Python',
            value: 'python'
        }, {
            label: 'SQL',
            value: 'sql'
        }, {
            label: window.i18n.gettext('Text'),
            value: 'plaintext'
        }]
    }

    initialize() {

        const isHedy = this.model.get('source').language === 'hedy'

        this.setElement(Template({
            Styles,
            title: this.model.get('title'),
            isHedy
        }))

        if (isHedy) {
            this.renderHedyViewer()
        } else {
            this.renderDefaultViewer()
        }
    }

    async renderDefaultViewer() {
        const language = Source15.getLanguages().find(language => {
            return language.value === this.model.get('source').language
        })

        let hljs
        try {
            ({ default: hljs } = await import(
                /* webpackChunkName: "highlightjs" */
                '@highlightjs/cdn-assets/es/core.min'
            ))
            await import(
                /* webpackChunkName: "highlightjs" */
                '@highlightjs/cdn-assets/styles/github-dark-dimmed.min.css'
            )
            const { default: grammar } = await import(
                /* webpackChunkName: "highlightjs" */
                '@highlightjs/cdn-assets/es/languages/' + language.value + '.min'
            )
            hljs.registerLanguage(language.value, grammar)
        } catch {
            const errorButton = this.addSvelteChildView(this.$('.js-container'), Button, {
                icon: 'refresh',
                label: window.i18n.gettext('Loading failed. Try again'),
                inline: true,
                callback: () => {
                    errorButton.$destroy()
                    this.renderDefaultViewer()
                }
            })
            return
        }

        const highlight = hljs.highlight(
            this.model.get('source').code,
            {
                language: this.model.get('source').language
            }
        )

        this.$('.js-code').attr('data-language', language.label)
        this.$('.js-code').html(highlight.value)
    }

    renderHedyViewer() {
        this.addChildView(new Hedy({
            level: this.model.get('source').hedyLevel,
            program: this.model.get('source').code,
            showRun: false,
            readOnly: true
        }), '.js-hedy')
    }
}
