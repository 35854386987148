export default class TextToSpeech extends BaseView {

    playDummyAudio() {
        // Play a silent sound file to enable sound on Safari (without this playback is blocked)
        new Audio('data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA').play()
    }

    /**
     *
     * @param {str} url                 endpoint from where to fetch the audio
     * @param {boolean} asBlob          if true, return audio as Blob which can be set as src for a HTMLMediaElement.
     *                                  Otherwise return ArrayBuffer for use with APIs that take raw audio data
     *                                  directly, such as AudioContext.
     * @returns {Blob|ArrayBuffer}      audio representation
     */
    async fetchAudio(url, asBlob = false) {

        if (!url) {
            throw 'TextToSpeech URL cannot be empty'
        }

        this.abortController = new AbortController()

        // Request audio from the backend
        const response = await fetch(url, {
            signal: this.abortController.signal
        })
        if (!response.ok) {
            window.sentry.withScope(scope => {
                scope.setExtra('response', response)
                window.sentry.captureException('Synthesize returned non-ok response')
            })
            throw {
                message: 'Synthesize returned non-ok response',
                displayMessage: window.i18n.gettext('Something went wrong while trying to download audio')
            }
        }

        // Check if the backend returned an error status
        if (response.headers.get('content-type').includes('application/json')) {
            const json = await response.json()
            if (json.status === 'error') {
                window.sentry.withScope(scope => {
                    scope.setExtra('json', json)
                    window.sentry.captureException(json.message)
                })
            }
            switch (json.error_code) {
                case 35413:
                    json.displayMessage = window.i18n.gettext('Text is too long for text to speech')
                    break
                case 35405:
                    json.displayMessage = window.i18n.gettext('Language not allowed')
                    break
                case 35202:
                    json.displayMessage = window.i18n.gettext('Audio is not ready yet. Please wait.')
                    break
            }
            throw json
        }

        // Decode the response
        const arrayBuffer = await response.arrayBuffer()

        // If true, create a Blob the audio data to use as the src of an <audio> element.
        if (asBlob) {
            return new Blob([arrayBuffer], {type: 'audio/mp3'})
        }

        return arrayBuffer
    }

    onDestroy() {
        this.abortController?.abort()
    }

}
