var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"menubar-holder") : stack1), depth0))
    + " no-print\">\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"topbar") : stack1), depth0))
    + " js-topbar\">\n        <button class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"menu-button") : stack1), depth0))
    + " js-toggle-menu\">\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"menu",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":30}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"Menu",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":27}}})) != null ? stack1 : "")
    + "\n        </button>\n    </div>\n\n    <nav class=\"js-primary-navigation "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"primary-navigation") : stack1), depth0))
    + "\">\n        <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"upper-menu") : stack1), depth0))
    + "\">\n            <button class=\"js-group-button "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"group-button") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"people",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":36}}})) != null ? stack1 : "")
    + "\n                <span class=\"js-group-label\"></span>\n                "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":40}}})) != null ? stack1 : "")
    + "\n            </button>\n            <div class=\"js-default-menu\"></div>\n        </div>\n        <div class=\"js-lower-menu "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lower-menu") : stack1), depth0))
    + "\"></div>\n    </nav>\n\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"close-button") : stack1), depth0))
    + " js-toggle-menu\">\n        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"cross",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});