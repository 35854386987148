var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<details class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"details") : stack1), depth0))
    + "\">\n    <summary>\n        "
    + alias2(__default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"I have an activation code",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":42}}}))
    + "\n        "
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/shape.js")).call(alias3,"arrow-down",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":32}}})) != null ? stack1 : "")
    + "\n    </summary>\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"form") : stack1), depth0))
    + "\">\n        <p>"
    + ((stack1 = __default(require("../../../../util/handlebarsHelpers/__.js")).call(alias3,"An activation code consists of 10 characters. You got it from the webshop where you ordered (MBO webshop, Ilge or Learnbeat). After entering it, you get instant access to the learning material.</br></br>Do you have questions? Please <a href=\"#\" class=\"js-support-options\">contact the webshop</a> that supplied your activation code.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":353}}})) != null ? stack1 : "")
    + "</p>\n        <div class=\"js-code-input\"></div>\n        <div class=\"js-submit-code\"></div>\n    </div>\n</details>\n";
},"useData":true});