import Styles from './ScoringButton.scss';

import Template from './ScoringButton.hbs';
export default class ScoringButton extends BaseView {

    initialize({ score, label, color }) {
        this.setElement(Template({
            Styles,
            score,
            label,
        }))

        if (color) {
            this.$el.addClass(Styles[color])
        }
    }

}