import Styles from './GroupCard.scss';

import Template from './GroupCard.hbs';
import Util from 'util/util';
import ContentLabel from 'views/components/contentLabel/ContentLabel.svelte'
import Item from './item/Item';

export default class StudentGroupCard extends BaseView {

    initialize({
        weeks,
        itemCollection,
        showActivities
    }) {

        this.setElement(Template({
            Styles,
            groupName: this.model.get('name'),
            groupID: this.model.id,
            groupImage: this.model.getBackgroundImage()
        }))

        this.addSvelteChildView(
            '.js-header',
            ContentLabel,
            {
                model: this.model,
                isOnBackground: true,
                truncateName: true,
            },
        )

        if (Array.isArray(weeks)) {

            weeks = Util.sortWeeks(weeks)

            let index = 0
            for (const week of weeks) {
                this.addChildView(
                    new Item({
                        model: new Backbone.Model({
                            week: week.week,
                            year: week.year,
                            index
                        }),
                        url: '/groups/planner/' + this.model.id + '/' + week.week + '/' + week.year,
                        hasProgress: _.isFinite(week.progress),
                        progress: Math.round(week.progress * 100) / 100
                    }),
                    '.js-list'
                )
                index++
            }

        } else {

            const layerCollection = Backbone.Collection[showActivities ? 'activities' : 'sections']

            const alteredSections = itemCollection.reduce((m, item) => {
                const model = layerCollection.get( item[showActivities ? 'activity_id' : 'section_id'])

                if (model && m.length < 3) {
                    m.push({
                        model,
                        hasProgress: _.isFinite(item.progress),
                        progress: Math.round(item.progress * 100) / 100
                    })
                }
                return m
            }, []);

            for (const content of alteredSections) {
                this.addChildView(
                    new Item({
                        model: content.model,
                        url: (showActivities ? '/activities/show/' : '/sections/show/') + content.model.id,
                        hasProgress: content.hasProgress,
                        progress: content.progress
                    }),
                    '.js-list'
                )
            }

        }

    }

}
