var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"lost-password-modal") : stack1), depth0))
    + " modal-margin\">\n\n    <h4>"
    + alias1(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias2,"Check your email",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":33}}}))
    + "</h4>\n    <p>"
    + alias1(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias2,"We've sent an email to set up a new password. Click the link in the email to proceed.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":7},"end":{"line":4,"column":101}}}))
    + "</p>\n    <p>"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias2,"Did you not receive an email? Check your spam folder and check if <strong>{0}</strong> belongs to you.",(depth0 != null ? lookupProperty(depth0,"username") : depth0),{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":7},"end":{"line":5,"column":129}}})) != null ? stack1 : "")
    + "</p>\n\n"
    + ((stack1 = container.invokePartial(require("../LostPasswordHelp.hbs"),depth0,{"name":"../LostPasswordHelp.hbs","hash":{"Styles":(depth0 != null ? lookupProperty(depth0,"Styles") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n</div>\n";
},"usePartial":true,"useData":true});