import NavigationGuard from 'controller/NavigationGuard';

export default class SharedController {

    /**
     *
     * @param {Array}
     * params all URL parameters
     * @returns {Object}
     * Extracted parameters containing the following:
     *      {String|null} controller
     *      target page controller (part after the first /)
     *      {String|null} action
     *      target page action (part after the second /)
     *      {Array}
     *      Array containing the remaining URL parameters.
     */
    extractParams(params) {
        const controller = params.length === 0 || params[0] === null ? null : params.shift().toLowerCase()
        const action = params.length === 0 || params[0] === null ? null : params.shift().toLowerCase()
        params = params.length > 0 && params[0] !== null ? params[0].split('/') : []
        return {controller, action, params}
    }

    setRoute(rawParams) {

        const {controller, action, params} = this.extractParams(rawParams)

        window.APPLICATION = action === 'author' ? 'author' : 'webapp';

        // This condition checks if the user is navigating outside a given url (lockToURL attribute)
        // Used in: standalone activities and exam activities.
        // Example lockToURL: activities/show/182341
        if (
            Backbone.Model.user &&
            Backbone.Model.user.get('lockToURL') !== undefined
        ) {

            // Extract controller, action and other parameters from lockToURL using the same method used for the
            // current target URL to compare them for equality. If there is any difference while the user has the
            // lockToURL attribute defined in its user model, allways rediect the user to this page if the current
            // target URL is different from the lockToURL.
            const {
                controller: lockToURLController,
                action: lockToURLAction,
                params: lockToURLParams
            } = this.extractParams(Backbone.Model.user.get('lockToURL').split('/'))
            if (
                lockToURLController !== controller ||
                lockToURLAction !== action ||
                lockToURLParams[0] !== params[0]
            ) {
                // Navigate the user to the locked url
                Backbone.history.navigate(Backbone.Model.user.get('lockToURL'), {trigger: true})
                return
            }
        }

        var viewConstructor;

        // Create copy of previous path and parameters for tracking and reverting purposes.
        const previousPath = this.activePath.join('/')
        const previousParams = this.activeParams.join('/')

        // Is the userID absent?
        var isLoggedOut = !Backbone.Model?.user?.id;

        if (this.isExistingPageType(controller, action)) {

            // This if-statement will handle the navigation for an accessor user.
            // it will make sure to fallback to the correct page for an accessor
            // instead of redirecting the accessor to the homepage which doesn't
            // exist for this type of user
            if (
                // Only do this when logged out, else we can't access the rest of
                // learnbeat when we've became another user
                isLoggedOut

                // And there isn't a previous path, or the previous path was the
                // login page
                && (
                    previousPath.length === 0
                    || previousPath === 'users/login'
                    || previousPath === 'accessor/login'
                )

                // Only do this fallback if we aren't already on the correct route
                && `${controller}/${action}` !== 'accessor/connections'

                // Make sure we only do this is the accessor page is allowed.
                // Because an accessor doesn't have a home, we should prefer this
                // page above the home
                && NavigationGuard.isAllowed('accessor/connections')
            ) {
                Backbone.history.navigate('accessor/connections', {trigger: true});
                return;
            }

            if (`${controller}/${action}` !== 'accessor/connections') {
                // Is page present in the list of pages that are allowed to be
                // viewed without a userID?
                const isAllowedWithoutUserID = _.contains(this.allowedWithoutUserID[controller], action)

                // Redirect to login page if user model is not initialized
                // unless it visits a page that is allowed to be viewed
                // without a userID. When the user visits one of these
                // pages while already logged in, redirect the user to the
                // home page or a previous page.
                if (!isAllowedWithoutUserID && isLoggedOut) {
                    Backbone.history.navigate('users/login', { trigger: true });
                    return;
                } else if (isAllowedWithoutUserID && !isLoggedOut) {
                    this.invalidTargetHandler(previousPath, previousParams)
                    return;
                }
            } else {
                const isAllowed = NavigationGuard.isAllowed(controller + '/' + action)

                if (!isAllowed) {
                    Backbone.history.navigate('accessor/login', { trigger: true });
                    return;
                }
            }

            // Check if the requested page is within the navigation guard
            if (!isLoggedOut) {

                const isAllowed = NavigationGuard.isAllowed(controller + '/' + action)
                if (!isAllowed) {
                    this.invalidTargetHandler(previousPath, previousParams)
                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('You have no access to the page you are trying to visit')
                    );
                    return;
                }
            }

            // If page is for content (student, group, chapter, section, etc.) does not exist in the global collection,
            // abort navigation and return to home.
            if (this.isExistingContent(controller, action, params) === false) {
                this.invalidTargetHandler(previousPath, previousParams)
                return
            }

            // if menubar is loaded (when user is logged in)
            // change the active icon depending on the location of the user
            // This menubar feature is only available in the non-mobile version
            if (Backbone.View.menubar !== undefined && !ISMOBILE) {
                Backbone.View.menubar.onExternalChangeLinkItem(controller, action)
            }

            // check if current route needs a redirect to a root layer
            const rootLayerUrl = this.redirectToRootLayer(controller, action, params)

            if (rootLayerUrl) {
                Backbone.history.navigate(rootLayerUrl, {trigger: true, replace: true })
                return
            }

            // Store the new path into the active path
            this.activePath = [controller, action];
            this.activeParams = params

            // Set the constructor for the view listed in Controller file.
            viewConstructor = this.urlMap[controller][action]

        } else {
            if (!isLoggedOut) {
                this.invalidTargetHandler(previousPath, previousParams)
            } else {
                Backbone.history.navigate('users/login', {trigger: true});
            }

            // Check if controller or action is set, if not the user probably went to '/'
            // which can occur trough the backend after login. Which will make the message
            // bellow not correct.
            if (controller || action) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('The page you are trying to visit does not exist')
                );
            }
            return;
        }

        // If the user has an outdated version of the frontend, do a full reload
        // so that the frontend is renewed. See checkStatus in App.js for how this is set.
        if (window.app?.needsFrontendUpdate) {
            window.location.reload();
            return;
        }

        // Track the navigation in Matomo
        window.statsTracker.trackEvent(
            previousPath,
            this.activePath.join('/')
        );

        return {
            viewConstructor,
            viewParams: params,
            activePath: this.activePath,
        };
    }

    /**
     * For when the user is logged in, this functions handles when the user targets a page which type or content does
     * not exist. If previous path and parameters are known, replace the faulty URL with this information. If these
     * are unknown, navigate to the users/home instead.
     *
     * @param {String} previousPath :controller/:action (eg. groups/show) of the previous page
     * @param {String} previousParams URL parameters following after the :controller/:action
     */
    invalidTargetHandler(previousPath, previousParams) {

        // Prefer accessor/connections above users/home, since accessors cant access
        // users/home
        if ((previousPath.length === 0 || previousPath === 'users/login')
            && NavigationGuard.isAllowed('accessor/connections')
        ) {
            Backbone.history.navigate('accessor/connections', { trigger: true, replace: true })
            return;
        }

        // important! when user has just logged in, and previousPath is users/login
        // send to user to users/home. Else user will keep seeing the login view
        if (previousPath.length === 0 || (previousPath === 'users/login' && Backbone.Model.user.id)) {
            Backbone.history.navigate('users/home', {trigger: true, replace: true})
            return
        }

        Backbone.history.navigate(
            `${previousPath}${previousParams.length ? '/' + previousParams : ''}`,
            { replace: true }
        )
    }

    /**
     *
     * @param {string} controller       first order path ('users', 'groups', etc.)
     * @param {string} action           second order path ('show', 'author', etc.)
     * @returns {boolean}               If true, this type of page exist in the urlMap defined in
     *                                  Controller.js
     */
    isExistingPageType(controller, action) {
        return !!(this.urlMap[controller] && this.urlMap[controller][action])
    }

    /**
     *
     * @param {string} controller       first order path ('users', 'groups', etc.)
     * @param {string} action           second order path ('show', 'author', etc.)
     * @param {Array} params            optional URL parameters containing a content ID (groupId, sectionId, etc.)
     * @param {Boolean} isTest          if true, do not do any navigation actions.
     * @returns {boolean}               If true, page has content that is already present in the global collections or
     *                                  does not require said content.
     */
    isExistingContent(controller, action, params, isTest = false) {
        if (this.isExistingPageType(controller, action) === false) {
            return false
        }

        if (controller === 'groups') {
            const groupId = params[0]
            const groupModel = Backbone.Collection.groups.get(groupId)

            // If no groupId is defined as the first param, but the current working group is defined, set first param
            // to that group ID to navigate to this page instead. For example: if
            // Backbone.View.menubar.getGroup().id: 1234, 'groups/author/' becomes 'groups/author/1234/'
            // Do not use this logic for admin pages, since user in the role of the admin can see groups that they
            // as a teacher don't have and therefore don't appear in the group chooser.
            if (!groupId && Backbone.View.menubar?.getGroup() && action !== 'admin') {
                params[0] = Backbone.View.menubar.getGroup().id
                if (isTest === false) {
                    Backbone.history.navigate(`/${controller}/${action}/${params.join('/')}`, {replace: true})
                }
                return true
            }

            if (
                !groupId && (
                    action === 'show' ||
                    action === 'planner' ||
                    action === 'progress' ||
                    action === 'results' ||
                    action === 'author' ||
                    action === 'annotate'
                )
            ) {
                Backbone.View.layout.openGroupChooser(action)
                return false
            }

            if (
                // Create and created can contain multiple group id's
                // which come in like (12345+67890).
                // I'm ignoring them now since this functions don't really have to be
                // checked on if the group exist, because we do a users/get call just before. - RH.
                action !== 'create' &&
                action !== 'created' &&
                action !== 'admin' &&
                groupId !== undefined &&
                !groupModel
            ) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('This group could not be found.')
                )
                return false
            }

            // If student does not have an active study planner for the requested group, show friendly info message
            // and abort navigation.
            if (
                Backbone.Model.user.get('is_student') &&
                action === 'planner' &&
                groupModel &&
                !groupModel.get('has_active_planner')
            ) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Your teacher did not enable a study planner for this course.'),
                    'info'
                )
                return false
            }

        } else if (controller === 'chapters') {
            const chapterId = params[0]
            if (Backbone.Collection.chapters.has(chapterId) === false) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('This chapter could not be found.')
                )
                return false
            }
        } else if (controller === 'sections') {
            const sectionId = params[0]
            if (Backbone.Collection.sections.has(sectionId) === false) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('This section could not be found.')
                )
                return false
            }
        } else if (
            controller === 'students' &&
            action === 'results'
        ) {
            const groupId = params[0]

            // If a group id is given, check if it is present in our groups collection
            if (groupId && !Backbone.Collection.groups.get(groupId)) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('This group could not be found.')
                )
                return false
            }

            // If no groupId is defined as the first param, but the current working group is defined, set first param
            // to that group ID to navigate to this page instead.
            if (!groupId && Backbone.Model.user.get('is_student') && Backbone.View.menubar?.getGroup()) {
                params[0] = Backbone.View.menubar.getGroup().id
                params[1] = Backbone.Model.user.id
                if (isTest === false) {
                    Backbone.history.navigate(`/${controller}/${action}/${params.join('/')}`, {replace: true})
                }
                return true
            }
            if (
                Backbone.Model.user.get('is_teacher') &&
                params[1] && !Backbone.Collection.groups.get(groupId).students.has(params[1])
            ) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('This student could not be found.')
                )
                return false
            }

            if (!groupId) {
                Backbone.View.layout.openGroupChooser(action)
                return false
            }
        } else if (controller === 'task_groups' && action === 'progress') {

            // The second parameter should be a valid student id
            if (!Backbone.Collection.students.has(params[1])) {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('This student could not be found.')
                )
                return false
            }
        }

        return true

    }

    redirectToRootLayer(controller, action, [ layerId ]) {

        const excluded = [
            'annotate',
            'createaccounts',
            'invite',
            'planner',
            'pick_course',
            'results',
            'settings',
            'students',
            'admin',
        ]

        if (excluded.includes(action)) {
            return null
        }

        if (controller === 'groups' && layerId) {

            const layers = Backbone.Collection.groups.get(layerId)?.get('layers')

            // do nothing special for 3-layer groups
            if (layers === 3) {
                return null
            }

            const rootChapter = Backbone.Collection.chapters.findWhere({ group_id: parseInt(layerId) })

            // if a 2 or 3 layer group has no content, send to regular groups controller
            if (!rootChapter) {
                return null
            }

            if (layers === 2) {
                return `/chapters/${action}/${rootChapter.id}`
            }

            const rootSection = Backbone.Collection.sections.findWhere({ chapter_id: rootChapter.id })

            return `/sections/${action}/${rootSection.id}`

        } else if (controller === 'chapters' && layerId) {
            const layers = Backbone.Collection.chapters.get(layerId).getGroupModel()?.get('layers')

            if (layers !== 1) {
                return null
            }

            const rootSection = Backbone.Collection.sections.findWhere({ chapter_id: parseInt(layerId) })
            return `/sections/${action}/${rootSection.id}`
        }

        return null
    }

}
