import Styles from './Logout.scss';

import Template from './Logout.hbs';
/**
 * This view is shown when the user is asked to logout.
 * This is done when the user is on a mobile device while trying to make an exam, which is not supported yet.
 */
import Button from 'views/components/button/Button'

var Logout = BaseView.extend({

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     */
    initialize() {

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        this.addChildView(
            new Button({
                label: window.i18n.gettext('Logout'),
                callback: Backbone.Model.user.logOut,
            }), '.js-logout-btn'
        );

        this.addShowDesktopLink()
    },

    addShowDesktopLink() {
        this.el.querySelector('a').addEventListener('click', () => {
            window.location.search = '?force_mobile=0'

            const {
                width, height
            } = window.screen

            // log (info level) to sentry that user switched environment
            window.sentry.withScope(scope => {
                scope.setLevel('info')
                scope.setTag('direction', 'mobile-to-desktop')
                scope.setTag('inExam', true)
                scope.setTag('screen', `${width}x${height}:${window.devicePixelRatio}`)
                window.sentry.captureMessage('User switched mobile/desktop environment v2')
            })
        })

    }

});

export default Logout;
