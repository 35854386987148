import Styles from './Template21.scss';

import Template from './Template21.hbs';
import answerBlockTemplate from 'views/components/taskGroups/tasks/template21/partials/AnswerBlock.hbs';
import ModelAnswer from 'views/components/taskGroups/tasks/template21/partials/modelAnswer/ModelAnswer';
import ShapeList from 'util/ShapeLoader';
import TimeUtil from 'util/TimeUtil';
import Util from 'util/util';
import UploadModel from 'models/UploadModel';
import FileBlock from './fileblock/FileBlock';
import FileHelper from 'views/components/taskGroups/tasks/template21/FileHelper';
import GoogleDriveExtension from 'views/components/taskGroups/tasks/template21/extensions/GoogleDrive/GoogleDrive';
import Microsoft365Extension from 'views/components/taskGroups/tasks/template21/extensions/Microsoft365/Microsoft365';
import CopyToPortfolioView from 'views/pages/activities/copyToPortfolioView/CopyToPortfolioView'

const Microsoft365 = {
    get clientId() {

        if (ISREVIEWAPP) {
            return '44f59b47-1040-41c1-a080-b01e3d3a9d74';
        }

        switch (LANGUAGE) {
            case 'en_US':
                return '878eb797-cbde-495d-81dc-0aa00eecd7d1';

            case 'nl_NL':
            default:
                return '3ca25896-7651-472b-a4ae-5bd6de241ea9';
        }
    },
    advanced: {
        redirectUri: `${location.protocol}//${window.location.host}/oauth/microsoft365`
    },
    multiSelect: true
}

const GoogleDriveAPI = {
    appId: '1095031334652',
    developerkey: 'AIzaSyD47z2keH4OOojUypOVFAG5E0D7DrNE9XE',
    client_id: '1095031334652-56chm61anhfkj7ttk9421pqls3re1f5g.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/drive.file',
    immediate: true
};

export default BaseView.extend({

    events: {
        'click .js-dropzone-container': 'onClickHandinArea'
    },

    /**
     *  initialize
     *
     *  Constructor method for this view
     *
     * @param {Object} options passed options from parent view
     */
    initialize(options) {

        _.bindAll(this,
            'show',
            'onDragEnter',
            'onDragLeave',
            'onSend',
            'onFileHasAnError',
            'onFileIsInProgress',
            'onFileIsUploaded',
            'removeUploadedFile',
            'renderResponseAsUploadedFiles',
            'renderFileBlock',
            'lockAnswer',
            'loadExtensions',
            'unlockAnswer',
            'onLoadDropzone',
            'onFailedLoadDropzone',
            'saveSource',
        );

        _.extend(this, options);

        this.render();
    },

    /**
     * This method will be used to initialized extensions. These extensions
     * can add extra functionality to this template. The extensions should be
     * invisble (don't know an use case for that yet) or in the form of a button.
     *
     * An example of an extension is the Google Drive integration which will add
     * a button to allow the user to add files of Google Drive
     *
     * @param {array} extensionList array containing extensions to be initialized
     */
    loadExtensions(extensionList) {
        this.extensions = extensionList.reduce((initializedExtensions, {
            Extension, condition, params
        }) => {

            if (!condition()) {
                return initializedExtensions;
            }

            const extension = new Extension(params);

            // Create a fake element for if the exntensions buttons element is not set.
            // This will be the case when only the answerview is rendered (see comment
            // of the renderTask method )
            const extensionHolder = (this.$('.js-extension-buttons').length === 0) ? document.createElement('div') : '.js-extension-buttons'

            initializedExtensions.push({
                element: this.addChildView(
                    extension,
                    extensionHolder
                ),
                view: extension
            });

            return initializedExtensions;
        }, this.extensions || []);
    },

    render() {
        this.allowedLabels = [];
        this.allowedMimeTypes = [];
        this.allowedExtensions = [];
        this.essentialExtensions = [];

        // Check if the model is containing any allowed types
        if (this.model.get('task_info_json').allowedTypes !== null ||
                this.model.get('task_info_json').allowedTypes !== undefined
        ) {
            const fileGroup = new FileHelper(this.model.get('task_info_json').allowedTypes);
            this.allowedLabels = fileGroup.groupLabels;
            this.allowedMimeTypes = fileGroup.fileMimeTypes;
            this.allowedExtensions = fileGroup.fileExtensions;
            this.essentialExtensions = fileGroup.fileExtensionsEssentials;
        }

        this.renderTask();

        // If there is not a school setting or there is a school setting, and it is Google Drive -> add extension
        if (!Backbone.Model.user.getSetting('fileUploadProvider')
            || Backbone.Model.user.getSetting('fileUploadProvider') === 'GoogleDrive') {
            this.loadExtensions([{
                Extension: GoogleDriveExtension,
                condition: () => true,
                params: {
                    parent: this,
                    authOptions: GoogleDriveAPI,
                    mimeTypes: this.allowedMimeTypes.join(',')
                }
            }]);
        }

        // If there is not a school setting or there is a school setting, and it is Microsoft 365 -> add extension
        if (!Backbone.Model.user.getSetting('fileUploadProvider')
            || Backbone.Model.user.getSetting('fileUploadProvider') === 'Microsoft365') {
            this.loadExtensions([{
                Extension: Microsoft365Extension,
                condition: () => true,
                params: {
                    parent: this,
                    pickerOptions: Microsoft365,
                    allowedExtensions: this.essentialExtensions
                }
            }]);
        }

        this.renderPortfolioPlacer()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {

        this.responseObject = this.task_view.response.get('json_answer') || [];

        // Compile the template and set it as the element for this view
        this.setElement(Template({
            Styles,
            types: this.allowedLabels.join(', '),
            maxSize: UploadModel.getMaximumAllowedSize('deliverable-file')
        }));
    },

    /**
     * if linear activity allows copying files to portfolio,
     * render copy to portfolio button
     *
     * @returns {void}
     */
    renderPortfolioPlacer() {

        if (!this.model.get('task_info_json').canBePlacedInPortfolio) {
            return
        }

        this.portfolioPlacerView = this.addChildView(
            new CopyToPortfolioView({
                model: this.model,
                saveSource: this.saveSource,
            }),
            '.js-add-to-portfolio-holder'
        )

        if (Backbone.Model.user.get('is_student')) {
            this.toggleCopyToPortfolioButton()

            this.listenTo(this.task_view.response, 'change:json_answer', () => {
                this.toggleCopyToPortfolioButton()
            })
        }

    },

    /**
     * Save a new item to an existing portfolio project
     *
     * @param {Backbone.Model} portfolioProject portfolio project model
     */
    async saveSource(portfolioProject) {
        // upload every file
        for (const [ filename, data ] of Object.entries(this.responseObject)) {
            const originalFilename = decodeURIComponent(filename)
            const title = Backbone.Model.user.currentOpenActivity.get('name') + ' - ' + originalFilename

            // get correct template_id and source specific data.
            // the uploaded file will be converted to another
            // source if there's a better alternative
            const sourceData = this.getAlternativeForPortfolioSource(data, originalFilename)

            await portfolioProject.get('elements').create({
                ...sourceData,
                project_id: portfolioProject.get('id'),
                element_type: 'source',
                sequence: portfolioProject.get('elements').length,
                title,
            })
        }

        Backbone.View.Components.modal.close()

        // and show status message with link callback
        const count = this.getUploadedFilesCount()

        Backbone.View.layout.openStatus(
            window.i18n.sprintf(
                window.i18n.ngettext(
                    '%d file is copied to your portfolio.',
                    '%d files are copied to your portfolio.',
                    count,
                ),
                count,
                portfolioProject.get('title')
            ),
            'success',
            {
                hasArrowIcon: true,
                elementCallback: () => {
                    Backbone.history.navigate(
                        `/portfolio/project/${portfolioProject.id}`,
                        { trigger: true }
                    )
                }
            }
        )
    },

    /**
     * Get correct data for converting an uploaded file to a portfolio source,
     *
     * @param {Object} data response object
     * @param {string} originalFilename original filename
     * @returns {Object} template id and source specific data
     */
    getAlternativeForPortfolioSource(data, originalFilename) {

        const eduFileSource = {
            fileId: data.fileId,
            fileIdHash: data.fileIdHash,
        }

        // convert edufile image uploads to the image source.
        if (Util.isDisplayableImage(originalFilename) && data.fileId) {
            return {
                template_id: 1,
                source: eduFileSource,
            }
        }

        const isAllowedAudioType = ['mp3', 'wav'].includes(
            Util.getFileExtension(originalFilename)
        )

        // convert edufile audio files to audio source
        if (isAllowedAudioType && data.fileId) {
            return {
                template_id: 7,
                source: {
                    url: `${data.fileId}/${data.fileIdHash}`
                },
            }
        }

        // for video, only allow mp4 files. uploaded files in this template
        // are not converted. the video source expects an mp4 file
        // note: an mp4 file can also be only an audio track. the video
        // element can deal with audio only mp4 files
        if (Util.getFileExtension(originalFilename) === 'mp4') {
            return {
                template_id: 13,
                source: {
                    ...eduFileSource,
                    type: 'upload'
                },
            }
        }

        // default to "File" source.
        return {
            template_id: 3,
            source: data.fileId ? eduFileSource : { cloudStorageUrl: data.url }
        }
    },

    /**
     * onClickHandinArea
     *
     * Extra click event to fix crappy clicks on iPads
     *
     * @returns {Boolean} for if flow can continue or not
     */
    onClickHandinArea() {

        if (this.inputIsLocked) {
            if (this.task_view.response.get('need-scoring')) {
                this.task_view.openScoreFirstMessage()
            }

            return false
        }

        if (Backbone.Model.user.get('is_teacher')) {
            this.showTeacherNotAllowedToUploadWarning()
            return false;
        }

        return true;
    },

    /**
     * lockAnswer
     *
     * This method will prevent the user from entering an answer. In this
     * case it will prevent the user from handing in any files.
     *
     */
    lockAnswer() {
        this.$el.addClass(Styles['is-locked']);

        if (this.dropZone?.hiddenFileInput) {
            this.dropZone.hiddenFileInput.setAttribute('disabled', true)
        }

        this.inputIsLocked = true;

        _.invoke(
            this.getChildViewsOfInstance(FileBlock),
            'hideRemoveButton'
        );
    },

    /**
     * unlockAnswer
     *
     * This method will reenable the template after its been locked. In this
     * case it will re-allow the user to hand in files
     *
     */
    unlockAnswer() {
        this.$el.removeClass(Styles['is-locked']);

        if (this.dropZone?.hiddenFileInput) {
            this.dropZone.hiddenFileInput.removeAttribute('disabled')
        }

        this.inputIsLocked = false;

        _.invoke(
            this.getChildViewsOfInstance(FileBlock),
            'showRemoveButton'
        );
    },

    /**
     * show
     *
     * This function will be called after this template is attached to the DOM.
     * This function is mostly used for DOM mutation because we know for sure that
     * the element which should be mutated is available
     *
     */
    show() {
        if (Backbone.Model.user.get('is_teacher')) {
            return
        }

        import(
            /* webpackChunkName: "dropzone" */
            'dropzone'
        ).then(
            this.onLoadDropzone,
            this.onFailedLoadDropzone
        )
    },

    onLoadDropzone({default: Dropzone}) {
        // Create a dropdone for the .js-dropzone-area element
        this.dropZone = new Dropzone(this.el.querySelector('.js-drag-and-drop-zone'), {
            url: UploadModel.uploadURL,

            // Set timeout to 15 minutes (default = 30seconds)
            timeout: 900000,

            // Our custom accept method will validate the file against our rules.
            // This gives us control to check if the extension is allowed for the
            // chosen categories for this template
            accept: (file, done) => {

                const ext = Util.getFileExtension(file.name)

                ext && this.allowedExtensions.indexOf(ext) > -1 ? done() : done('Incorrect type');
            },

            // Max file size is in MB's

            maxFilesize: UploadModel.getMaximumAllowedSize('deliverable-file'),
            sending: this.onSend,
            dragenter: this.onDragEnter,
            dragleave: this.onDragLeave,
            // Hack the paramname to file. It is file by default, but because we
            // allow uploadMultiple, dropzone appends array backets to it.
            // The upload server wont read `file[]`, but it does read `file`
            paramName() {
                return 'file';
            },
            params: {
                uploadType: 'deliverable-file',
                taskId: this.model.get('id'),
                userId: Backbone.Model.user.id,
            },
            uploadMultiple: true,
            parallelUploads: 1,
            autoProcessQueue: true,
            // Do not create thumbnails since we are build our own UI elements which
            // will conflict with this setting
            createImageThumbnails: false,
            previewsContainer: this.el.querySelector('.js-file-preview')
        });

        /**
             * Rename files on download: instead of showing a Google Cloud Storage
             * url, return a sortable, for the user understandable filename. The
             * Upload server will add this name to the content-disposition header
             */
        this.dropZone.on('sending', (file, xhr, formData) => {
            const student = Backbone.Model.user.last_name_first_name().replace(',', '').trim().replace(/ +/g, '_')

            const date = new Date()
            const dateString = date.toISOString().slice(0, 10).replace(/-/g, '')
            const timeString = date.toLocaleTimeString().replace(/:/g, '')

            const activityName = this.task_view.work_on.model.get('name').replace(/ +/g, '_')
            const fileName = file.overwrittenName || file.name

            const taskGroup = this.task_view.work_on.activeItem.model.get('index')
            const task = Util.numToLetter(this.task_view.model.get('taskIndex'))

            /**
                 * Create filename safe and lowercased string
                 *
                 * Sample: akhnikh_elianne_alle_sjablonen_11a_20210506181556_voorbeeld.jpg
                 *
                 * See: https://stackoverflow.com/questions/42210199/remove-illegal-characters-from-a-file-name-but-leave-spaces
                 */
            const fileNameOnDownload = `${ student }_${ activityName }_${ taskGroup }${ task }_${ dateString }${ timeString }_${ fileName }`
                .replace(/[\/\\?%*:|"<>]/g, '_')
                .toLocaleLowerCase()
            formData.append('fileNameOnDownload', fileNameOnDownload)

        })

        this.dropZone.on('addedfile', (file) => {
            this.renderFileBlock({
                uuid: file?.upload?.uuid,
                size: file?.size
            }, file?.name)
        })

        this.dropZone.on('uploadprogress', this.onFileIsInProgress)

        this.dropZone.on('error', this.onFileHasAnError)

        this.dropZone.on('success', this.onFileIsUploaded)

        // When the user is a student and has a response which is not empty
        if (!_.isEmpty(this.responseObject)) {

            // Render the response as files
            this.renderResponseAsUploadedFiles(this.responseObject);
        }
    },

    // Show an error to the student and try again on click
    onFailedLoadDropzone() {
        this.el.querySelector('.js-dropzone-container').style.display = 'none'
        this.el.querySelector('.js-dropzone-failed').style.display = 'flex'

        this.el.querySelector('.js-dropzone-failed').addEventListener('click', () => {
            this.el.querySelector('.js-dropzone-container').style.display = ''
            this.el.querySelector('.js-dropzone-failed').style.display = ''
            this.show()
        }, { once: true })
    },

    onFileIsInProgress(file, percentage) {
        const fileBlock = this.getFileblockByIdentifier(file.upload.uuid);

        // prevent error when user navigates away when upload has not finished yet
        if (!fileBlock) {
            return;
        }

        fileBlock.setProgress(percentage);
    },

    /**
     * onFileHasAnError
     *
     * This method will be called when the file has an error.
     * It will transform the file block into an error message.
     *
     * @param {Object} file object containing file information
     * @param {string|*} error error message as string. Can be anything else when something else is
     *                         return in the done callback for accept method passed in the dropzone init
     * @param {Object} request object containing request information
     * @returns {undefined} it uses return to prevent sentry logging, returns nothing
     */
    onFileHasAnError(file, error, request) {

        const fileBlock = this.getFileblockByIdentifier(file.upload.uuid);

        this.dropZone.removeFile(file)

        // prevent error when user navigates away when upload has not finished yet
        if (!fileBlock) {
            return;
        }

        if (/(File is too big)/i.test(error)) {

            fileBlock.error = window.i18n.sprintf(
                window.i18n.gettext('This file is too big. The maximum file size is %d MB.'),
                UploadModel.getMaximumAllowedSize('deliverable-file'),
            )

        } else if (/(Incorrect type)/i.test(error)) {

            window.sentry.withScope(scope => {
                scope.setTag('extension', Util.getFileExtension(file.name))
                scope.setExtra('file name', file.name)
                scope.setExtra('allowed extensions', this.allowedExtensions.join(' '))
                scope.setExtra('allowed labels', this.allowedLabels.join(' '))
                window.sentry.captureMessage('File extension for upload not allowed by frontend')
            })

            fileBlock.error = window.i18n.gettext(
                'This file is not the correct type. Upload an other file.'
            )

        } else {

            fileBlock.error = window.i18n.gettext(
                window.i18n.gettext('Uploading file failed, please try again.')
            )

            // Don't log client connection timeouts in Sentry
            if (request.status === 0) {
                return;
            }

            // Send error to Sentry
            window.sentry.withScope(scope => {
                scope.setExtra('error', error);
                scope.setExtra('request', request);
                scope.setExtra('fileType', file.type);
                scope.setExtra('fileSize', file.size);
                scope.setExtra('bytesSent', file.upload && file.upload.bytesSent);

                window.sentry.captureMessage('Error uploading file for template 21');
            });
        }

        if (!fileBlock.error) {
            fileBlock.error = true;
        }

        Backbone.View.layout.openStatus(
            window.i18n.gettext('Something went wrong while handing in your file'),
            'error'
        );
    },

    /**
     * onFileIsUploaded
     *
     * Callback function for when the file is fully uploaded via Dropzone. It will
     * transform the fileBlock from a progressbar into a fileBlock with icon
     *
     * @param {Object} file object containing the file information
     * @param {Object} response object containing response from the upload server
     *
     */
    onFileIsUploaded(file, response) {

        file.url = response.eduFileUrl || response.url
        this.dropZone.removeFile(file, response)

        // Correctly dereference/clone response map variable
        // https://stackoverflow.com/questions/8206988/clone-copy-a-javascript-map-variable
        this.responseObject = _.extend({}, this.responseObject);

        // Replace characters that break saving the response
        let newFileName = encodeURIComponent(response.original.fileName)

        /**
             * If file name already exists as key in the response object,
             * replace it with a new name. Since File.name is immutable,
             * add new property to File object (to rename the tile title
             * later)
             */
        newFileName = this.replaceFileNameIfDuplicate(newFileName)

        if (response.original.fileName !== newFileName) {
            file.overwrittenName = newFileName
        }

        // Add the uploaded file to the response object
        this.responseObject[newFileName] = {
            url: response.eduFileUrl || response.url,
            size: response.size,
            date: response.date,
            fileId: response.filesId,
            fileIdHash: response.hash,
            originalFileName: newFileName,
        };

        this.task_view.saveResponse(this.responseObject);

        const fileBlock = this.getFileblockByIdentifier(file.upload.uuid);

        // prevent error when user navigates away when upload has not finished yet
        if (!fileBlock) {
            return;
        }

        fileBlock.completeUpload(() => this.addIconToFileBlock(fileBlock, file));
    },

    /**
     * Replace the file name if it already exists in the response object
     *
     * @param {string} originalFileName
     * the file name of the to be uploaded file
     *
     * @return {string} image.jpeg -> image.hhmmss.jpeg -> e.g. image.162230.jpeg
     */
    replaceFileNameIfDuplicate(originalFileName) {

        if (!this.responseObject.hasOwnProperty(originalFileName)) {
            return originalFileName
        }

        const splitFileNameArray = originalFileName.split('.')
        const date = new Date()

        const timeString = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`
        const extension = splitFileNameArray.pop()

        return splitFileNameArray.concat([timeString, extension]).join('.')

    },

    /**
     * renderResponseAsUploadedFiles
     *
     * This function will take the response as an argument and will render
     * each file found in this response as an uploaded file so the user can
     * remove the files. And so the files will not become lost.
     *
     * @param  {Object} responses Object containing the response
     */
    renderResponseAsUploadedFiles(responses) {

        if (typeof responses === 'object') {

            Object.keys(responses).forEach(key => {

                if (typeof responses[key] === 'string') {
                    /**
                     * Sometimes, files are incorrectly saved in task_info_json.
                     * The task_info_json answers don't have an id. For
                     * the Template21 answers the original file name is used
                     * as a key to distinguish the task_info_json answers.
                     * Sometimes characters in the original file name don't
                     * function well and return a string for the upload response
                     * instead of a normal object with url, name etc. log these
                     * and prevent frontend errors
                     */

                    window.sentry.withScope(scope => {
                        scope.setExtra('response', responses);
                        scope.setExtra('taskId', this.model.get('id'));
                        scope.setExtra('file name', key)

                        window.sentry.captureException('uploaded file has file name that breaks this.responseObject object key')

                    })

                    /**
                     * If json_answer of response contains corrupt template 21
                     * object (a string, instead of object), delete the key and
                     * save new response object. Use a copy in order for
                     * this.task_view.saveResponse to detect a change
                     */
                    const responseCopy = {...this.responseObject
                    }
                    delete responseCopy[key]
                    this.task_view.saveResponse(responseCopy)

                    delete this.responseObject[key]

                } else {
                    this.renderFileBlock(responses[key], key, true)
                }
            })

        } else {

            window.sentry.withScope(scope => {
                scope.setExtra('response', responses);
                scope.setExtra('taskId', this.model.get('id'));

                window.sentry.captureMessage('Corrupt template 21 response');
            });
        }
    },

    /**
     * getFileBlockByIdentifier
     *
     * This method will be used to get the correct fileBlock childview
     * for a given identifier
     *
     * @param {String} identifier unique identifier for this fileBlock
     * @returns {FileBlock} fileBlock childview
     */
    getFileblockByIdentifier(identifier) {
        return this.getChildViewsOfInstance(FileBlock).find((fileBlock) => fileBlock.identifier === identifier);
    },

    /**
     * renderFileBlock
     *
     * This method will be called for each file. It will add a FileBlock
     * component and configure it correctly
     *
     * @param {Object} {
     *                 id {Number} id for this file when uploaded via Google or Microsoft
     *                 url {String} url of this file when uploaded from files
     *                 size {Number} containing the size of the file
     *                 uuid {String} containing and unique id for this fileblock (optional)
     *                 fileType {String} containing the type of the file
     *                 isExtensionFile {Boolean} whether the file is added via an extension (Google / Microsoft)
     *                 extensionName {String} Name of the extension (Google / Microsoft)
     *                 sharingFailed {Boolean} Whether sharing has failed or not
     *             }
     * @param {String} filename name of the file where we need to create fileBlock for
     * @param {boolean} [isPreviousUpload=false] if true, it wont render the progressbar
     * @returns {FileBlock} returns fileblock or undefined
     */
    renderFileBlock(
        {
            id,
            url,
            size,
            uuid,
            fileType,
            isExtensionFile,
            extensionName,
            sharingFailed
        },
        filename,
        isPreviousUpload = false
    ) {

        const fileIdentifier = (isExtensionFile) ? id : url && this.getUrlIdentifier(url);

        const existingFileBlock = this.getFileblockByIdentifier(fileIdentifier || uuid);

        if (existingFileBlock) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('This file is already added'),
                'info'
            );
            return existingFileBlock;
        }

        const fileBlock = this.addChildView(
            new FileBlock(
                fileIdentifier || uuid, {
                    name: filename,
                    size,
                    url,
                    icon: this.getExtensionIcon({
                        isExtensionFile,
                        extensionName
                    }),
                    fileId: uuid
                }
            ),
            '.js-uploaded-files-container'
        );

        if (isPreviousUpload || isExtensionFile) {
            _.defer(() => this.addIconToFileBlock(fileBlock, {
                fileType,
                isExtensionFile,
                extensionName
            }))
        }

        if (sharingFailed) {
            fileBlock.showSharingFailedMessage();
        }

        this.listenToOnce(
            fileBlock,
            'remove', () => this.removeUploadedFile(fileBlock)
        );

        if (this.inputIsLocked) {
            fileBlock.hideRemoveButton();
        }

        return fileBlock;
    },

    /**
     * getTypeGroupByExtension
     *
     * This function will determine the typegroup of the uploaded file
     * by looking at the given extension.
     *
     * @param  {string} extension String containing the extensions of the file
     * @return {string}           String containing the filetype group name
     */
    getTypeGroupByExtension(extension) {

        // Define a empty var which will be holding the type
        var fileType;

        // Loop trough each allowd types
        _.each(this.getAllowedTypes(), (typeOptions, type) => {

            // Find within extension list the the extension match
            const matchedExtensions = _.find(typeOptions.extensions, (item) => {

                // Return true when matched
                return item === extension;
            });

            // When the matched extensions is not undefined and at least one extension is found
            if (_.size(matchedExtensions)) {

                // Update the filetype type
                fileType = type;
            }
        });

        // Return the filetype
        return fileType;
    },

    /**
     * addIconToFileBlock
     *
     * Method for rendering the correct icon for this file. Can also be seen as
     * the rounding up method for finishing an upload. It will remove the  progressbar
     *
     * @param {FileBlock} fileBlock to add the icon to
     * @param {Object} file extra information about the file
     */
    addIconToFileBlock(fileBlock, file) {

        // prevent error when user navigates away when upload has not finished yet
        if (!fileBlock) {
            return
        }

        fileBlock.name = file.overwrittenName || file.name || fileBlock.name;
        fileBlock.size = file.size || fileBlock.size;
        fileBlock.url = file.url || fileBlock.url;

        const ext = Util.getFileExtension(fileBlock.name)

        const fileIcon = file.extensionName ? FileHelper.getIcon(file.fileType, 'mimeTypes', false) : FileHelper.getIcon(ext, 'extensions', false);

        const iconOptions = {
            icon: ShapeList[fileIcon],
            imageUrl: fileIcon === 'image' && !file.isExtensionFile && this.getImageUrl(fileBlock),
        };

        fileBlock.setFileIcon(iconOptions);
    },

    /**
     * @param {Object} fileBlock data
     * @param {string} fileBlock.name file name
     * @param {string} fileBlock.url file url
     * @returns {string|undefined} url if not SVG
     */
    getImageUrl({ name, url }) {
        if (Util.getFileExtension(name) !== 'svg') {
            return url
        }
    },

    /**
     * onSend
     *
     * Callback when a new file is being sent.
     *
     * @param  {File} file the intended for upload
     * @param  {XMLHttpRequest} xhr  the AJAX request
     */
    onSend(file, xhr) {

        // When the AJAX request timeouts, remove the file that was intended for upload.
        // Removing will trigger the generic error in this.onUploadError
        xhr.ontimeout = () => {
            this.dropZone.removeFile(file);
        }
    },

    /**
     *
     * @param {FileBlock} fileBlock fileblock view
     *
     */
    removeUploadedFile(fileBlock) {
        TweenMax.to(fileBlock.el, {
            duration: 0.3,
            opacity: 0,
            onComplete: () => {
                // Correctly dereference/clone response map variable
                // https://stackoverflow.com/questions/8206988/clone-copy-a-javascript-map-variable
                this.responseObject = _.extend({}, this.responseObject);

                // Response object
                delete this.responseObject[fileBlock.name];
                this.task_view.saveResponse(this.responseObject);

                // remove the upload tile
                this.unregisterAndDestroyChildView(fileBlock);
            }
        })
    },

    // Counter to fix drag leave/enter bug
    dragCounter: 0,

    /**
     * onDragEnter
     *
     * This function will be called when the file has entered the dropzone
     *
     */
    onDragEnter() {

        // Add a counter to keep the enters and leaves in sync
        this.dragCounter++;

        // Add an is-active class
        this.$('.js-dropzone-area-outer').addClass(Styles['droparea--is-active']);
    },

    /**
     * onDragEnter
     *
     * This function will be called when the file has left the dropzone
     *
     */
    onDragLeave() {

        // Count down the counter to keep the enters and leaves in sync
        this.dragCounter--;

        // When all the enters al left
        if (this.dragCounter === 0) {

            // Remove the is-active class
            this.$('.js-dropzone-area-outer').removeClass(Styles['droparea--is-active']);
        }
    },

    /**
     * getCorrectAnswer
     *
     * This is the function which will get the correct answer and
     * show it to the user
     *
     * @returns {ModelAnswer|String} correct answer
     *
     */
    getCorrectAnswer() {
        const answer = this.model.get('task_info_json').answer
        return answer && (answer.file || answer.text) ? new ModelAnswer({
            parentView: this,
            answerObject: answer
        }) : ''
    },

    /**
     * getStudentAnswer
     *
     * This function wil show a formated student answer to the teacher.
     *
     * @param  {Backbone.Model}       response_model This is the model for the response
     * @return {string}               HTML blob to be used for the student answer view.
     */
    getStudentAnswer(response_model) {

        // Loop through each file in this answer and generate student answer template.
        return _.reduce(response_model.get('json_answer'), (m, {
            date, size, url, isExtensionFile, extensionName, sharingFailed
        }, filename) => {

            const prettyDate = TimeUtil.prettyDate(date, true)
            const timeStamp = window.i18n.sprintf(
                window.i18n.gettext('%s at %s'),
                TimeUtil.getAbsoluteDate(date),
                TimeUtil.getAbsoluteTime(date)
            )
            const fileSize = size && Util.formatBytes(size, 0)
            const isImage = Util.isDisplayableImage(filename)

            return m += answerBlockTemplate({
                Styles,
                filename: decodeURIComponent(filename),
                prettyDate,
                timeStamp,
                fileSize,
                isImage,
                url,
                sharingFailed,
                extensionIcon: this.getExtensionIcon({
                    isExtensionFile,
                    extensionName
                }),
            })

        }, '')

    },

    /**
     * showAnswer
     *
     * This function will be called when the show answer button has been clicked.
     *
     */
    showAnswer() {
        if (this.task_view.response.get('sync') === undefined) {
            this.task_view.response.set({
                sync: true
            });
        }
        this.modelAnswer = this.addChildView(new ModelAnswer({
            parentView: this,
            answerObject: this.model.get('task_info_json').answer
        }), '.js-model-answer-holder');
    },

    showAuthorAnswer() {
        this.showAnswer()
    },

    /**
     * hideAnswer
     *
     * This function will be called when the hide answer button has been clicked.
     *
     */
    hideAnswer() {
        if (this.modelAnswer) {
            this.unregisterAndDestroyChildView(this.modelAnswer);
        }
    },

    /**
     * Get identifier for extension storage url. This makes is possible to
     * select the tile of the uploaded file
     *
     * @param {string} url extension storage url
     * @param {number} lastCharsAmount Amount of characters to return
     * @returns {string} base64 encoded string of the last 15 characters of the URL
     */
    getUrlIdentifier(url, lastCharsAmount) {
        return btoa(url).slice(-1 * lastCharsAmount)
    },

    /**
     * @param {Object} response {
     *  isExtensionFile boolean whether the file is from an extension
     *  extensionName   string  containing the name of the extensions
     * }
     *
     * @returns {undefined|string} The svg as string or undefined
     */
    getExtensionIcon({
        isExtensionFile, extensionName
    }) {
        if (!isExtensionFile) {
            return
        }

        if (!this.extensionIconMapping) {
            this.extensionIconMapping = this.extensions.reduce(
                (map, {
                    view
                }) => {
                    map[view.extensionName] = view.extensionIcon
                    return map;
                }, {}
            );
        }

        if (this.extensionIconMapping[extensionName]) {
            return ShapeList[this.extensionIconMapping[extensionName]];
        }

        return;
    },

    showTeacherNotAllowedToUploadWarning() {
        Backbone.View.layout.openStatus(
            window.i18n.gettext('As teacher it isn\'t possible to upload a file.'),
            'info'
        )
    },

    toggleCopyToPortfolioButton() {
        const count = this.getUploadedFilesCount()
        const button = this.portfolioPlacerView.button

        if (count === 0 && !button.disabled) {
            button.disable()
        } else if (count > 0 && button.disabled) {
            button.enable()
        }
    },

    /**
     * Get count of uploaded files
     *
     * @returns {number}
     * amount of files uploaded
     */
    getUploadedFilesCount() {
        const response = this.task_view?.response.get('json_answer')

        if (response === undefined || Array.isArray(response)) {
            return 0
        }

        return Object.keys(response).length
    }
});
