import Template from './Heading.hbs';
import Button from 'views/components/wysiwyg/parts/buttons/Button';
import Heading1Template from 'views/components/wysiwyg/elements/heading1.hbs';

export default class Heading extends Button {

    // Custom command
    command = 'insertHeading1'

    initializeButton() {
        this.icon = Template();
        this.tooltip = window.i18n.gettext('Heading');
    }

    addCommands(editorObject) {
        const editor = editorObject.editor
        const customEl = editorObject.customEl
        const customSH = editorObject.customSH

        // Add the command for inserting an h1.
        editor.addCommand(this.command, () => {
            // Pass the template and editor with it as a blockElement.
            // Note that Heading1Template doesn't have a newline at end of file.
            // If it did, this newline would be inserted into the editor,
            // adding an unwanted empty line after the inserted content.
            customEl.blockElement(Heading1Template, editor);
        });

        // Create the state handler for this block element
        customSH.blockElementHandler(
            // Listen to command: insertHeading1
            this.command,
            // Use the Heading1Template template to indentify the block
            Heading1Template(),
            // Pass the editor with it
            editor
        );
    }
}
