import Template from './Source9.hbs';
import Util from 'util/util';
// Video source view
var Source9View = BaseView.extend({

    initialize() {

        if (!this.model.get('source').url) {
            return;
        }

        this.setElement(Template({
            title: Util.renderContentSafely(this.model.get('title')),
            contextUrl: this.model.get('source').url
        }));
    }
});

export default Source9View;
