var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"js-goto-activity tile__navigation__btn\">\n    "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"lock",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":22}}})) != null ? stack1 : "")
    + "\n    <strong>"
    + container.escapeExpression(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Order or enter your code to gain access.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":61}}}))
    + "</strong>\n</div>\n";
},"useData":true});