var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"js-exam-progress tile__navigation__btn\">\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"eye",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":25}}})) != null ? stack1 : "")
    + "\n        <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Visibility",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":33}}}))
    + "</span>\n    </div>\n\n    <div class=\"js-exam-grades tile__navigation__btn\">\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"progress",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":30}}})) != null ? stack1 : "")
    + "\n        <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Progress & Grades",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":40}}}))
    + "</span>\n    </div>\n\n"
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"exam","generated_exam",{"name":"isInList","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "\n    <div class=\"js-exam-grading tile__navigation__btn\">\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"bar-chart",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":31}}})) != null ? stack1 : "")
    + "\n        <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Grading",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":21,"column":14},"end":{"line":21,"column":30}}}))
    + "</span>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"js-exam-notifications tile__navigation__btn\">\n        "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"notifications-active",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":42}}})) != null ? stack1 : "")
    + "\n        <span>"
    + container.escapeExpression(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Notifications",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":15,"column":14},"end":{"line":15,"column":36}}}))
    + "</span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isNotMobile.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":23,"column":16}}})) != null ? stack1 : "");
},"useData":true});