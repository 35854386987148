var Handlebars = require("../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"sidebar") : stack1), depth0))
    + "\">\n            <h1>"
    + alias1(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Filter",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":31}}}))
    + "</h1>\n            <div class=\"js-content\"></div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"subjects-modal") : stack1), depth0))
    + "\">\n    <div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"container") : stack1), depth0))
    + "\">\n        <h1>"
    + alias2(__default(require("../../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Select subjects",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":36}}}))
    + "</h1>\n        <div class=\"js-subjects "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"subjects") : stack1), depth0))
    + "\"></div>\n    </div>\n"
    + ((stack1 = __default(require("../../../../../../../util/handlebarsHelpers/isNotMobile.js")).call(alias3,{"name":"isNotMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":11,"column":20}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});