import Styles from './AnswerDiff.scss';

import Template from './AnswerDiff.hbs';
import { diffWords } from 'diff/lib/diff/word'
import TextareaStyling from 'views/components/textarea/Textarea.scss'
import Util from 'util/util'

export default class AnswerDiff extends BaseView {

    initialize({
        givenAnswer,
        modelAnswer,
        modelAnswerVariants = [],
        ignoreCase,
        answerInputElement
    }) {

        if (givenAnswer.length === 0) {
            return
        }

        let {diff, changeRatio, newStr} = this.generateDiff(givenAnswer, modelAnswer, ignoreCase)

        // If there are variants of the model answer, pick the answer which is the closest to given answer.
        if (modelAnswerVariants.length > 1) {
            const variantDiffs = [{diff, changeRatio, newStr}, ..._.rest(modelAnswerVariants).map((variant) => {
                return this.generateDiff(givenAnswer, variant.text, ignoreCase)
            })]
            const closestVariant = _.max(variantDiffs, 'changeRatio')
            diff = closestVariant.diff
            changeRatio = closestVariant.changeRatio
            // If there are multiple model answer variants, use the model answer which is closest to the given answer
            // to display as the model answer.
            this.closestModelAnswer = closestVariant.newStr
        }

        // Do not show the diff if there are too many changes.
        if (changeRatio < 2) {
            return
        }

        // Hide answer input so it can be replaced by the diff input.
        answerInputElement.hide()
        this.answerInputElement = answerInputElement

        // Create elements for the diff, where the parts "removed" from the model answer are marked as incorrect
        // and the parts that are "added" are marked as missing.
        const diffElements = diff.reduce((m, part) => {
            if (part.removed) {
                return m + ` <del class=${Styles['incorrect']}>${part.value}</del> `
            }
            if (part.added) {
                return m + ` <ins class=${Styles['missing']}>…</ins> `
            }
            return m + part.value
        }, '')
        this.setElement(Template({
            Styles,
            TextareaStyling,
            diff: diffElements
        }))

    }

    generateDiff(givenAnswer, modelAnswer, ignoreCase) {
        givenAnswer = Util.normaliseCharacters(givenAnswer)
        modelAnswer = Util.normaliseCharacters(Util.stripTags(modelAnswer))

        // Create diff using word-level tokens. Interpunctions and whitespace also counts as separate tokens.
        const diff = diffWords(
            givenAnswer,
            modelAnswer,
            {
                ignoreCase
            }
        )

        // If number of changed characters is much higher than the number of common characters (those which are the
        // same between given answer and model answer), then do not show the diff, since it isn't off much use.
        let changedCharacters = 0
        let commonCharacters = 0
        for (const part of diff) {
            if (part.removed || part.added) {
                changedCharacters += part.value.length
                continue
            }
            commonCharacters += part.value.length
        }

        return {
            diff,
            changeRatio: commonCharacters / changedCharacters,
            newStr: modelAnswer
        }
    }

    onDestroy() {
        if (this.answerInputElement) {
            this.answerInputElement.show()
        }
    }

}
