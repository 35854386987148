import Styles from './CombinedInputs.scss';

import Template from './CombinedInputs.hbs';
import QuickInput from 'views/components/quickInput/QuickInput';

export default class CombinedInputs extends BaseView {
    get events() {
        return {
            'click .js-inputs-icon-container': 'validateInputs',
        }
    }

    initialize({
        leftInputWidth = 50,
        leftInputPlaceholder,
        rightInputPlaceholder,
        rightSideIcon,
        rightSideIconText,
        validationCallback,
        callback,
        pasteCallback,
        deleteCallback,
        enableHTMLshortcuts,
    }) {

        // prevent the "this" of this.validateInputs to point to a QuickInput
        this.validateInputs = this.validateInputs.bind(this);

        this.setElement(Template({
            Styles,
            rightSideIconText,
            rightSideIcon
        }));

        this.leftInputElement = this.$('.js-combination-input-left');
        this.rightInputElement = this.$('.js-combination-input-right');
        this.validationCallback = validationCallback;

        this.leftInputElement.css({
            width: leftInputWidth + '%',
        });

        this.leftInputView = this.addChildView(
            new QuickInput({
                noMargin: true,
                placeholder: leftInputPlaceholder,
                inputCallbackWithoutButton: this.validateInputs,
                keepValueOnCallback: true,
                pasteCallback,
                enableHTMLshortcuts,
                statusIcon: 'tab',
                statusIconCallback: () => {
                    this.rightInputView.inputElement.focus()
                }
            }),
            '.js-combination-input-left'
        );

        this.rightInputView = this.addChildView(
            new QuickInput({
                noMargin: true,
                placeholder: rightInputPlaceholder,
                inputCallback: this.validateInputs,
                keepValueOnCallback: true,
                pasteCallback,
                enableHTMLshortcuts,
                deleteCallback
            }),
            '.js-combination-input-right'
        );

        this.callback = callback;
    }

    validateInputs() {

        if (!this.validationCallback) {
            return;
        }

        // focus right input if empty
        if (this.leftInputView.getInput() && !this.rightInputView.getInput()) {
            this.rightInputView.focusInput();
            return;
        }

        const isValid = this.validationCallback(this.leftInputView, this.rightInputView);
        if (!isValid) {
            return;
        }

        const { leftInputValue, rightInputValue } = this.getInputValues();

        this.callback(leftInputValue, rightInputValue);

        this.clearInputs();
    }

    getInputValues() {
        return {
            leftInputValue: this.leftInputView.getInput(),
            rightInputValue: this.rightInputView.getInput(),
        }
    }

    clearInputs() {

        [this.leftInputView, this.rightInputView].forEach(input => {
            input.setInput('');
        });

        this.leftInputView.focusInput();

    }

    setInputs(leftInputContent, rightInputContent) {
        this.leftInputView.setInput(leftInputContent);
        this.rightInputView.setInput(rightInputContent);
    }

    toggleActiveState() {
        this.$el.toggleClass(Styles['inputs-container--is-disabled']);
    }

}
