var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"solution-container") : stack1), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"exerciseHtml") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <akit-exercise session-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sessionId") : depth0), depth0))
    + "\" start-active=\"false\" "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mode") : depth0), depth0))
    + "/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"solution-mode",{"name":"isEqual","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":87}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"COMPOUND",{"name":"isEqual","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":16}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"SINGLE",{"name":"isEqual","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":16}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});