import ExamTaskGroupSetModel from 'models/ExamTaskGroupSetModel'

export default class ExamTaskGroupSetsCollection extends Backbone.Collection {

    get comparator() {
        return 'sequence'
    }

    get model() {
        return ExamTaskGroupSetModel
    }

    initialize(models, {activity_id}) {
        this.activity_id = activity_id
    }

    /**
     * Set sequence of collection when one of the elements is moved.
     *
     * @param {Number} oldIndex     index in collection of the current model
     * @param {Number} newIndex     index in collection of the place current should be moved to
     */
    setSequence(oldIndex, newIndex) {

        const currentModel = this.at(oldIndex)
        const modelAtNewIndex = this.at(newIndex)

        // As long the current model and the model at the desired index exist and these indexes are positive (negative
        // will retrieve a model from the back of the collection: https://backbonejs.org/#Collection-at, which is not
        // the behaviour we are looking for), swap the sequences of the current and ajecent model and sort the
        // collection. Save this new order to the backend.
        if (oldIndex >= 0 && newIndex >= 0 && currentModel && modelAtNewIndex) {
            currentModel.set({sequence: newIndex})
            modelAtNewIndex.set({sequence: oldIndex})
            this.sort()

            $.post(
                `/exam_sets/update_sequence/${this.activity_id}.json`,
                {exam_sets: this.pluck('id')}
            )
        }

    }

}
