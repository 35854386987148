import Styles from './Template35.scss';

import Template from './Template35.hbs';
import AuthorTemplate from 'views/components/taskGroups/tasks/template35/author.hbs';
import StudentAnswer from 'views/components/taskGroups/tasks/template35/StudentAnswer';
import AkitUtil from 'views/components/taskGroups/tasks/template35/Util';
import ModelAnswer from 'views/components/taskGroups/tasks/template35/ModelAnswer.hbs';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(this,
            'onSetStep',
            'initWidget'
        );

        this.taskView = options.task_view;

        const taskInfoJSON = this.model.get('task_info_json')
        if (APPLICATION === 'webapp' && !this.taskView.isPreview) {

            this.type = taskInfoJSON.type;
            this.events = []
            this.sessionId = taskInfoJSON.sessionId;
            this.exerciseHtml = taskInfoJSON.exerciseHtml;

            if (Backbone.Model.user.get('is_student')) {

                // Compound questions use the same session to listen to events.
                // However in Learnbeat compound questions are split up between different tasks.
                // To make sure the interaction event can be related to the right task we need to
                // keep track of the ref ids every interaction has.

                // NOTE: Single Algebrakit questions now also can have multiple ref-id's.
                // For example: 1a359dfc-4836-4e5a-8b29-5f29fe235ee1
                this.refIds = []

                // Parse one or multiple ref ids from the exercise html,
                // gaptexts with multiple gaps typically have a ref id per gap but have the same compound index
                // Example string: <akit-interaction ref-id=\"ZcYGP\" ref-name=\"name_3\"></akit-interaction>\

                let match = false
                const regex = /(?:ref-id=\\")(.+?)(?:\\)/g

                do {
                    match = regex.exec(this.exerciseHtml);
                    if (match && match[1]) {
                        this.refIds.push(match[1])
                    }
                } while (match);

            }

            AkitUtil.initAlgebrakit();

            // don't try to render algebrakit without exerciseHtml
            if (!this.exerciseHtml) {
                return;
            }

            // Create the view, passing the styling with it
            this.setElement(Template({
                Styles,
                exerciseHtml: this.exerciseHtml
            }));

            _.defer(
                this.initWidget
            );
        } else {
            // Show simple overview of the task for in the author interface or preview activity.
            this.setElement(AuthorTemplate({
                subject: this.model.get('Subject').subject || '…'
            }));
        }

    },

    /**
     * initWidget
     *
     * Initialize AlgebraKiT widget (when the dependencies are loaded)
     *
     */
    initWidget() {

        if (
            Backbone.Model.user.get('is_student') &&
            window.AlgebraKIT.addExerciseListener !== undefined
        ) {
            if (
                this.type === 'SINGLE' && window.AlgebraKITListeners.indexOf(this.sessionId) > -1
            )
            {
                // Don't add a new listener when we are already listening to this session
                return;
            }

            // Don't add a new listener when there is already a listener for this session and for
            // atleast one of the ref ids for this task
            if (_.some(
                window.AlgebraKITListeners,
                ({sessionId, refId}) => {sessionId === this.sessionId && refId.includes(this.refIds)})
            ) {
                return
            }

            this.addListener()

        } else {
            _.defer(this.initWidget, 500)
        }
    },

    /**
     * addListener
     *
     * Tells the Algebrakit API to proxy events
     * for this session to learnbeat.
     * And also keeps track of sessions that we're listening to.
     *
     */
    addListener() {
        window.AlgebraKIT.addExerciseListener(
            this.sessionId,
            (eventData) => this.handleEvents(eventData)
        )

        window.AlgebraKITListeners.push({
            type: this.type,
            sessionId: this.sessionId,
            refId: this.refIds
        })
    },

    /**
     * handleEvents
     *
     * Determines if the incoming event data from Algebrakit
     * should be saved as an answer.
     *
     * @param  {Object} eventData data about the event sent by Algebrakit
     */
    handleEvents(eventData) {

        if (
            Backbone.Model.user.get('is_student') &&
            // Single exercises only have 1 session, therefore refId is not important
            this.type === 'SINGLE' ||

            (
                // Compound questions with the same sessions will trigger the same events.
                // Only add the event data to the json_answer
                // when it matches with one of the ref ids in this task.
                this.type === 'COMPOUND' &&
                this.refIds.indexOf(eventData.refId) > -1
            )
        ) {

            // When Algebrakit is initialized the evaluate or finished event can be replayed,
            // which causes another unjustified responses/add call in Learnbeat.
            // Therefore don't save if it's a replay event.
            if (eventData?.data.replay === true) {

                return
            }
            // Akit widgets send a lot of events.
            // Only the following ones are related to the student's response
            // and should therefore be saved.
            if (
                eventData.event === 'interaction-evaluate' ||
                eventData.event === 'interaction-finished' ||
                eventData.event === 'interaction-hint'
            ) {

                this.onSetStep(eventData);
            }
        }
    },

    /**
     * onSetStep
     *
     * When a students sets a step (asks for hint or submits a intermediate answer),
     * add the step (event) to the response
     *
     * @param {String} eventData    data on the event transmitted from Algebrakit
     *
     */
    onSetStep(eventData) {
        this.events.push(eventData);
        this.taskView.saveResponse({
            sessionId: this.sessionId,
            exerciseHtml: this.exerciseHtml,
            events: this.events,
            number_of_events: this.events.length
        });
    },

    /**
     * getStudentAnswer
     *
     * Returns a widget showing the student answer
     *
     * @param {Backbone.Model} responseModel    student response
     * @returns {String|StudentAnswer} student answer representation
     */
    getStudentAnswer(responseModel) {
        if (
            !responseModel ||
            !responseModel.get('json_answer')
        ) {
            return '';
        }

        return new StudentAnswer({
            exerciseHtml: responseModel.get('json_answer').exerciseHtml,
            sessionId: responseModel.get('json_answer').sessionId,
            mode: 'review-mode',
            model: this.model,
            type: this.type
        })
    },

    /**
     * getCorrectAnswer
     *
     * Returns a widget showing the correct answer.
     * When the question is dynamic, no such thing can be given (since all student get a different question).
     *
     * @returns {StudentAnswer} correct answer view
     */
    getCorrectAnswer() {

        // Check if the student has already started a session for this task.
        // If so, we can take the same session so that the teacher sees the same question as the student.
        let studentResponse
        if (
            this.taskView.singleAnswerUserId
        ) {
            studentResponse = this.taskView.work_on.model.responses.find(
                (response) => {

                    return (
                        response.get('user_id') === this.taskView.singleAnswerUserId &&
                        response.get('task_id') === this.taskView.model.id
                    )

                }
            )
        }
        const sessionId = studentResponse ? studentResponse.get('json_answer').sessionId : this.sessionId

        return new StudentAnswer({
            exerciseHtml: this.type === 'COMPOUND' && this.exerciseHtml,
            sessionId,
            mode: 'solution-mode',
            type: this.type
        });

    },

    /**
     * showAnswer
     *
     * Show the correct answer to the task.
     */
    showAnswer() {

        if (APPLICATION === 'webapp' && !this.taskView.isPreview) {
            this.el.querySelector('.js-model-answer').innerHTML = ModelAnswer({
                sessionId: this.sessionId,
                type: this.model.get('task_info_json').type,
                Styles
            });
        }
    },

    showAuthorAnswer() {},

    /**
     * hideAnswer
     *
     * Hide the correct answer to the task.
     */
    hideAnswer() {
        this.el.querySelector('.js-model-answer').innerHTML = '';
    },

    /**
     * lockAnswer
     *
     * Triggered when the lock-answers event is sent from a Presentation view.
     * It will make sure students can't change their answer. This is typically
     * done when the taskState inside the presentation is 1 or 2, meaning the
     * teacher is showing the student's answers.
     */
    lockAnswer() {},

    /**
     * unlockAnswer
     *
     * Triggered when the unlock-answers event is sent from a Presentation view.
     * It will make sure students chan fill an answer again.
     */
    unlockAnswer() {}

});
