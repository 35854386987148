import Styles from './StudentGradePicker.scss';

import Template from './StudentGradePicker.hbs';
export default BaseView.extend({

    events: {
        'click .js-score': 'onClickGradingScore'
    },

    initialize({
        templateView,
        activityModel,
    }) {

        // Make the templateview accessible in this methods
        this.templateView = templateView

        this.activityModel = activityModel

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

    },

    onClickGradingScore(e) {

        // Get the score from the event object
        var score = this.$(e.currentTarget).data('score');

        // Make sure no score higher than 1 can be given
        score = Math.min(score, 1);

        // Call the update score method, this will sync the rating to the backend
        this.model.updateScore(score);

        // Update the score within the response model
        this.model.set({

            score,

            // Set the score to be scored by student
            scored_by: 'student',

            // Set the need scoring to 0, since the response
            // is now scored
            'need-scoring': 0
        });

        // do not unlock in case of diagnostic exam or when the deadline has expired
        if (this.activityModel.get('type') === 'diagnostic_exam' || this.activityModel.deadline.hasExpired()) {
            this.destroy()
            return
        }

        // Unlock the answer for this template
        this.templateView.unlockAnswer()

        // Hide model answer for this template
        this.templateView.hideAnswer()

        // Tell the user he/she can change their answer now
        Backbone.View.layout.openStatus(
            window.i18n.gettext(
                'You can now edit your answer'
            ),
            'success'
        )

        // Destroy this view since the answer is scored
        // so we don't need this view anymore
        this.destroy();

    }

});
