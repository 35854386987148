import ExamReportModel from 'models/ExamReportModel';
import WordsResponseModel from 'models/WordsResponseModel';
import TimeLogModel from 'models/TimeLogModel';

export default Backbone.Model.extend({

    // List of model types supported by LocalStorageModel.
    ExamReportModel,
    WordsResponseModel,
    TimeLogModel,

    // Use uniqueID as id attribute of this model.
    idAttribute: 'uniqueID',

    initialize() {

        _.bindAll(
            this,
            'attemptSync'
        );

        // Add userID of the current user.
        if (!this.has('userID')) {
            this.set({
                userID: Backbone.Model.user.id
            });
        }

        // If no uniqueID has been found it means that this model does not yet exist
        // in window.localStorage. Create a new uniqueID and add it to localStorage.
        if (!this.has('uniqueID')) {
            this.set({
                uniqueID: String.fromCharCode(
                    Math.floor(Math.random() * 11 + 72)
                ) + _.random(0, 1000000),
                client_time_created: new Date().getTime()
            })
            try {
                window.localStorage.setItem(
                    this.collection.localStoragePrefix + this.id,
                    JSON.stringify(this.attributes)
                )
            } catch (err) {
                window.sentry.withScope(scope => {
                    scope.setExtra('errorMessage', err)
                    scope.setExtra('responseData', this.attributes)
                    scope.setExtra('local storage', window.localStorage)
                    window.sentry.captureException('Can\'t save model to local storage')
                })
            }
        }

    },

    /**
         * attemptSync
         *
         * Contruct model based on its original type and try to sync.
         */
    attemptSync() {

        // If model model of type has not been constructed yet, create one with the
        // current localStorage ID. This ID is usefull for removing this
        // LocalStorageModel after it has been synced with the backend.
        if (!this.typeModel) {
            this.typeModel = new this[this.get('modelType')](this.get('modelData'));
        }
        this.typeModel.localStorageModelID = this.id;

        // Call original model sync method, if it has any.
        if (this.typeModel.sync) {
            this.typeModel.sync();
        }

    }

}, {
    type: 'localStorage'
});
