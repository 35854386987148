import CompetencyEvaluationModel from 'models/CompetencyEvaluationModel'
import CompetencyModel from 'models/CompetencyModel'

export default class CompetencyEvaluationCollection extends Backbone.Collection {

    get model() {
        return CompetencyEvaluationModel
    }

    get url() {
        // TODO also implement endpoint for group `/competencies/evaluations/group/${this.groupId}/${this.competencyId}`
        return `/competencies/evaluations/user/${this.userId}/${this.competencyId}`
    }

    comparator(model) {
        return `${model.get('evaluation_date')}${model.get('updated_at')}`
    }

    initialize(attr, {competencyModel, userId}) {
        // ID of the original competency
        this.competencyId = competencyModel.id
        // Student ID
        this.userId = userId

        this.competencyVersions = new Backbone.Collection([competencyModel], {model: CompetencyModel})
    }

    parse(response) {
        this.competencyVersions.push(response.competencies)
        return response.evaluations
    }

}
