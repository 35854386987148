import Styles from './Template22.scss';

import Template from './Template22.hbs';
import WrongAnswerTemplate from './WrongAnswer.hbs'
import Util from 'util/util'
import Table from './table/Table'
import TableStyles from './table/Table.scss'

export default BaseView.extend({

    debounceIsActive: false,

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     The options as passed from the parent
     */
    initialize(options) {

        _.bindAll(this,
            'lockAnswer',
            'saveResponse',
            'getCorrectAnswer'
        );

        // Make the taskview from options accesible in the this scope
        this.taskView = options.task_view;

        // Get the task info json from the model
        this.taskInfoJson = this.model.get('task_info_json');
        this.isCaseSensitive = this.taskInfoJson?.options?.caseSensitive === true

        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {

        // Debounce the saveResponse method by 1000 ms so it only fires when more
        // than 1000 ms has elapsed since the last time it was called. This
        // is to prevent DoSS-ing ourselfs every time the user types something
        // in a cell input field.
        this.saveResponseDebounced = _.debounce(this.saveResponse, 1000)

        this.setElement(Template({Styles}))

        this.table = this.addChildView(new Table({
            tableData: this.taskInfoJson.gridCells,
            columnWidths: this.taskInfoJson.columnWidths,
            response: this.taskView.response.get('json_answer'),
        }), this.$el)

        this.table.$('td[contenteditable="true"]').on('input', this.saveResponseDebounced)

    },

    getGapCellAnswers(cell) {
        const correctCellGapAnswers = [cell.cellText]
        if (Array.isArray(cell.variants)) {
            for (const variant of cell.variants) {
                correctCellGapAnswers.push(variant.text)
            }
        }
        return correctCellGapAnswers.map((answer) => {
            const text = Util.stripTags(Util.normaliseCharacters(answer))
            if (this.isCaseSensitive) {
                return text
            }
            return text.toLowerCase()
        })
    },

    /**
     * showAnswer
     *
     * This function will be called when the user or teacher click on the show answer
     * button. It will tell the student/teacher which cells where filled in wrong or
     * correct.
     *
     */
    showAnswer() {

        // If a student clicks the show answer button while the debounce is active,
        // save the response first and then show the answer
        if (this.debounceIsActive) {
            this.saveResponseDebounced.cancel()
            this.saveResponse()
        }

        // Loop through each cell that is a gap
        for (const cell of this.table.gapCells) {

            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)

            let cellGapResponse = Util.stripScripts(Util.normaliseCharacters(cellElement.innerText))
            if (!this.isCaseSensitive) {
                cellGapResponse = cellGapResponse.toLowerCase()
            }

            const correctCellGapAnswers = this.getGapCellAnswers(cell)

            if (
                this.model.get('grading_mode') !== 'student' &&
                correctCellGapAnswers.includes(cellGapResponse)
            ) {
                cellElement.classList.add(TableStyles['is-correct'])
            } else {

                if (this.model.get('grading_mode') !== 'student') {
                    cellElement.classList.add(TableStyles['is-incorrect'])
                }

                // Create a wrong cell element
                cellElement.insertAdjacentHTML('afterbegin', WrongAnswerTemplate({
                    Styles,
                    correct: cell.cellText,
                    studentGradingMode: this.model.get('grading_mode') === 'student'
                }))

            }

        }
    },

    showAuthorAnswer() {
        for (const cell of this.table.gapCells) {
            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            cellElement.innerText = cell.cellText
        }
    },

    /**
     * getCorrectAnswer
     *
     * This function will be called when the user wants to see the model answer.
     * It will return a string which can be HTML and
     * that HTML will be shown for each student
     *
     * @return {string}  String containing the correct answer (HTML)
     */
    getCorrectAnswer() {

        const correctAnswerTable = new Table({
            tableData: this.taskInfoJson.gridCells,
            columnWidths: this.taskInfoJson.columnWidths,
            isReadOnly: true
        })

        for (const cell of correctAnswerTable.gapCells) {

            const cellElement = correctAnswerTable.getCellElement(cell.rowIndex, cell.columnIndex)

            cellElement.innerText = correctAnswerTable.getGapText(cell.rowIndex, cell.columnIndex, true)
            cellElement.classList.add(TableStyles['is-stateless'])

        }

        return correctAnswerTable

    },

    /**
     * getStudentAnswer
     *
     * This function will be called for each student for when the users clicks
     * on student answers button. It will return a string which can be HTML and
     * that HTML will be shown for each student
     *
     * @param  {Backbone.Model} responseModel   The response model for each student
     * @return {string}                         String containing a student answer (HTML)
     */
    getStudentAnswer(responseModel) {
        // Create a variable for response's json_answer
        const response = responseModel.get('json_answer')

        if (!response) {
            return ''
        }

        const studentResponseTable = new Table({
            tableData: this.taskInfoJson.gridCells,
            columnWidths: this.taskInfoJson.columnWidths,
            response,
            isReadOnly: true
        })

        for (const cell of studentResponseTable.gapCells) {

            const cellElement = studentResponseTable.getCellElement(cell.rowIndex, cell.columnIndex)

            let gapCellText = studentResponseTable.getGapText(cell.rowIndex, cell.columnIndex)

            if (this.model.get('grading_mode') === 'auto') {

                if (!this.isCaseSensitive) {
                    gapCellText = gapCellText.toLowerCase()
                }

                const correctCellGapAnswers = this.getGapCellAnswers(cell)

                if (correctCellGapAnswers.includes(gapCellText)) {
                    cellElement.classList.add(TableStyles['is-correct'])
                } else {
                    cellElement.classList.add(TableStyles['is-incorrect'])
                }

            } else {
                cellElement.classList.add(TableStyles['is-stateless'])
            }

        }

        return studentResponseTable

    },

    /**
     * hideAnswer
     *
     * This function will be called when the user already has the answers visible
     * and click again on the answers button. This will hide the answers for the student
     * and bring the table back to its original state before the answers where made
     * visible
     *
     */
    hideAnswer() {

        for (const cell of this.table.gapCells) {

            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            cellElement.classList.remove(TableStyles['is-correct'], TableStyles['is-incorrect'])
            cellElement.querySelector('.js-tooltip')?.remove()
            cellElement.innerText = Util.normaliseCharacters(cellElement.innerText)

        }

    },

    lockAnswer() {

        const needsScoring = this.taskView.response.get('need-scoring')

        for (const cell of this.table.gapCells) {

            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            cellElement.contentEditable = 'false'

            if (needsScoring) {
                cellElement.addEventListener('click', this.taskView.openScoreFirstMessage)
            }

        }

    },

    unlockAnswer() {

        for (const cell of this.table.gapCells) {

            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)
            cellElement.contentEditable = 'true'
            cellElement.removeEventListener('click', this.taskView.openScoreFirstMessage)

        }

    },

    /**
     * saveResponse
     *
     * This function can be called to save the users response to the database. It will
     * loop trough all the columns that should be a gap and store the content of that cell
     * into the response object.
     *
     */
    saveResponse() {

        this.debounceIsActive = false

        // For each gap, get it's current user input.
        const responseObject = this.table.gapCells.map((cell) => {

            // Get the gridCell's element
            const cellElement = this.table.getCellElement(cell.rowIndex, cell.columnIndex)

            // Add a new object to the response object
            return {

                // Save the row
                row: cell.rowIndex,

                // Save the column
                column: cell.columnIndex,

                // Save the text
                text: Util.normaliseCharacters(cellElement.innerText)
            }

        })

        // Save the updated response
        this.taskView.saveResponse(responseObject)

    },

    beforeDestroy() {

        if (!this.debounceIsActive) {
            return
        }

        this.saveResponseDebounced.cancel()

        this.saveResponse()

    }

});
