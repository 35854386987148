import Styles from './Source12.scss';

import Template from './Source12.hbs';
import Util from 'util/util';
import renderSpecialElements from 'util/RenderSpecialElements';
import TheoryButton from 'views/components/taskGroups/sources/source12/templates/theory-button/theory-button.hbs';
import Button from 'views/components/button/Button'
import TextToSpeechSource from 'views/components/taskGroups/sources/textToSpeech/TextToSpeech';

// All the possible templates with elements:

/*      Default template */
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import DefaultReaderTitleElement from 'views/components/taskGroups/sources/source12/templates/elements/readerTitle.hbs';
import DefaultReaderYearElement from 'views/components/taskGroups/sources/source12/templates/elements/readerYear.hbs';
import DefaultReaderSectionElement from 'views/components/taskGroups/sources/source12/templates/elements/readerSection.hbs';
import DefaultNoContentElement from 'views/components/taskGroups/sources/source12/templates/elements/noContent.hbs';

export default BaseView.extend({

    // Make a mapping for element templates
    specialElementsConvertList: {

        // Convertion for HTML element: 'img'
        img: {
            global: {

                // Set template for the img tag
                template: DefaultImageElement,
            }
        },

    },

    initialize(options) {

        _.bindAll(
            this,
            'onClickOpenTheory'
        )

        // Make source more easy accessible
        this.source = this.model.get('source');

        // default template options
        const templateOptions = {
            // Title of the source
            title: Util.renderContentSafely(this.model.get('title')),
            content: Util.renderContentSafely(

                // Content of the source
                (this.source !== undefined && this.source.text !== undefined) ?
                    this.source.text : ''
            ),
            // Set style to is-default (methode)
            styling: 'default'
        }

        if (options.portfolioStyling) {
            this.source.options = this.source.options || {};
            this.source.options.template = 'plainwhite';
            templateOptions.isPortfolio = options.portfolioStyling;
        }

        // If the source is rendered in a theory reader, remove the background image if present
        if (this.model.get('inTheory') && this.source?.options?.background) {
            delete this.source.options.background
        }

        let programmingSafeTemplateName

        const sourceOptions = this.source?.options || {}

        // Check if there are any options & that there is a template set
        if (sourceOptions?.template !== undefined) {

            // Update template options with advanced extra options
            templateOptions.styling = sourceOptions.template

            // Create a holder for template name
            programmingSafeTemplateName = sourceOptions.template

            // Switch case for templates
            switch (sourceOptions.template) {

                case 'default' :
                    // Check if there is background info and if there is a background image_url
                    if (sourceOptions.background !== undefined && sourceOptions.background.image_url !== undefined) {
                        // Set the image for the template
                        templateOptions.image = sourceOptions.background.image_url

                        // Okay there is an background, check if there is a position
                        switch (sourceOptions.background.position) {
                            // If background, do other stuff
                            case 'background' :
                                programmingSafeTemplateName = 'default_image_background';

                                break;

                                // Default behaviour
                            default :

                                // Set position as direction
                                if (sourceOptions.background.position === 'right') {
                                    templateOptions.direction = 'right';
                                } else if (sourceOptions.background.position === 'top') {
                                    templateOptions.direction = 'top';
                                } else {
                                    templateOptions.direction = 'left';
                                }

                                programmingSafeTemplateName = 'default_image';

                                break;
                        }

                        // Check if there is a background position set
                        if (sourceOptions.background['background-position'] !== undefined) {

                            // If so pass it to the HBS
                            templateOptions.backgroundposition = sourceOptions.background['background-position']
                        }
                    }
                    break;

                case 'learningtext' :
                    // Check if there is background info and if there is a background image_url
                    if (sourceOptions.background !== undefined && sourceOptions.background.image_url !== undefined) {
                        // Set the image for the template
                        templateOptions.image = sourceOptions.background.image_url

                        // Okay there is an background, check if there is a position
                        switch (sourceOptions.background.position) {
                            // If background, do other stuff
                            case 'background' :
                                programmingSafeTemplateName = 'learningtext_image_background';

                                break;

                                // Default behaviour
                            default :

                                // Set position as direction
                                if (sourceOptions.background.position === 'right') {
                                    templateOptions.direction = 'right';
                                } else if (sourceOptions.background.position === 'top') {
                                    templateOptions.direction = 'top';
                                } else {
                                    templateOptions.direction = 'left';
                                }

                                programmingSafeTemplateName = 'learningtext_image';

                                break;
                        }

                        // Check if there is a background position set
                        if (sourceOptions.background['background-position'] !== undefined) {

                            // If so pass it to the HBS
                            templateOptions.backgroundposition = sourceOptions.background['background-position']
                        }
                    }
                    break;

                // If template is custom
                case 'custom' :
                    // Do special stuff
                    // Update template options with cover image
                    // Update cover image background position

                    // Check if there is background info and if there is a background image_url
                    if (sourceOptions.background !== undefined && sourceOptions.background.image_url !== undefined) {
                        // Set the image for the template
                        templateOptions.image = sourceOptions.background.image_url

                        // Okay there is an background, check if there is a position
                        switch (sourceOptions.background.position) {
                            // If background, do other stuff
                            case 'background' :
                                programmingSafeTemplateName = 'custom_image_background';

                                break;

                                // Default behaviour
                            default :

                                // Set position as direction
                                if (sourceOptions.background.position === 'right') {
                                    templateOptions.direction = 'right';
                                } else if (sourceOptions.background.position === 'top') {
                                    templateOptions.direction = 'top';
                                } else {
                                    templateOptions.direction = 'left';
                                }

                                programmingSafeTemplateName = 'custom_image';

                                break;
                        }

                        // Check if there is a background position set
                        if (sourceOptions.background['background-position'] !== undefined) {

                            // If so pass it to the HBS
                            templateOptions.backgroundposition = sourceOptions.background['background-position']
                        }
                    }

                    templateOptions.custom_font = this.getFont(sourceOptions.font);

                    templateOptions.custom_color = this.getColorRGB(sourceOptions.color);

                    break;

                case 'website' :

                    templateOptions.contextUrl = sourceOptions.url

                    break;
            }
        }

        var thirdLevel;
        var secondLevel;
        var firstLevel;

        if (this.model.get('path') !== undefined &&
            this.model.get('path').length > 0 &&
            sourceOptions.template === 'default'
        ) {
            templateOptions.title = '';

            templateOptions.content = DefaultReaderTitleElement({
                title: this.model.get('title')
            }) + templateOptions.content;

            thirdLevel = this.model.get('path')[this.model.get('path').length - 3];
            secondLevel = this.model.get('path')[this.model.get('path').length - 2];
            firstLevel = this.model.get('path')[this.model.get('path').length - 1];

            if (thirdLevel !== undefined) {
                templateOptions.title = thirdLevel.name;
                templateOptions.meta = true;
            } else if (secondLevel !== undefined) {
                templateOptions.title = secondLevel.name;
                templateOptions.meta = true;
            } else if (firstLevel !== undefined) {
                templateOptions.title = firstLevel.name;
            }

        }

        // Compile the template
        this.setElement(Template(templateOptions));

        // Disable speech if explicitly disabled or content is less than 10 characters long.
        if (!options.disableSpeech && Util.stripTags(templateOptions.content).length > 10) {
            this.addChildView(new TextToSpeechSource({
                sourceId: this.model.get('mergedSourceIds'),
                audioPlayerTargetElement: this.$('.js-speech-player'),
            }), '.js-get-speech-button')
        }

        if (!templateOptions.content && templateOptions.isPortfolio) {
            this.setElement(DefaultNoContentElement({Styles}));
        }

        // add show Theory button
        // if source is part of a theory collection
        // and the current view is not in the theory
        if (
            APPLICATION === 'webapp' &&
            this.model.get('theory_collection_id') !== 0 &&
            this.model.get('inTheory') === undefined &&
            !options.isPreview
        ) {
            this.$el.append(TheoryButton({ Styles }))
            this.addChildView(
                new Button({
                    callback: this.onClickOpenTheory,
                    label: window.i18n.gettext('Show all theory'),
                    theme: 'secondary',
                    inline: true,
                    icon: 'theory',
                }),
                '.js-theory-button'
            )
        }

        if (templateOptions.meta === true) {
            if (thirdLevel !== undefined) {
                this.$('.js-meta-data').append(DefaultReaderSectionElement({
                    title: secondLevel.name
                }));
                this.$('.js-meta-data').append(DefaultReaderYearElement({
                    year: firstLevel.name,
                    isPlus: this.model.get('theory_type') === 'plus',
                    isExtra: this.model.get('theory_type') === 'extra'
                }));
            } else if (secondLevel !== undefined) {
                this.$('.js-meta-data').append(DefaultReaderSectionElement({
                    title: firstLevel.name
                }));
            }
        }

        renderSpecialElements({
            template: programmingSafeTemplateName,
            matchingTemplate: sourceOptions.template,
            color: templateOptions.custom_color,
            font: templateOptions.custom_font
        }, this);

        // Do stuff when custom image with background after rendering
        if (programmingSafeTemplateName === 'custom_image_background' ||
            programmingSafeTemplateName === 'default_image_background' ||
            programmingSafeTemplateName === 'learningtext_image_background'
        ) {

            // Delay for DOM to load
            _.defer(() => {

                // Calculate offset
                var offset = this.$('.js-source-container').height() / 2;

                // Get height of background
                var bgHeight = this.$('.js-background-image').height();

                // If the offset is smaller then the bgHeight
                if (offset < bgHeight) {

                    // Shift by 100px so it appears above the text itself.
                    offset += 100;

                    // Set background height to offset
                    this.$('.js-background-image').css({
                        height: offset,
                        marginBottom: -offset / 2
                    });
                }

            })
        }
    },

    // when the user clicks the open theory button
    onClickOpenTheory() {

        var secondLevelBranchId = null;
        if (this.model.get('path').length > 1) {
            secondLevelBranchId = this.model.get('path')[1].id;
        }

        // open the fullscreen component with the Theory View
        Backbone.View.layout.openTheory(
            this.model.get('theory_collection_id'),
            secondLevelBranchId,
            this.model.get('id')
        );

    },

    // Get the font family for custom template
    getFont(keyword) {
        const font = this.model.getFonts().find((el) => {
            return el.keyword === keyword
        })

        return font?.family || this.model.getFonts()[0].family
    },

    // Get background color in rgb for custom template
    getColorRGB(keyword) {
        const color = this.model.getColors().find((el) => {
            return el.keyword === keyword
        })

        return color?.rgb || this.model.getColors()[0].rgb
    },

    addContent(content) {
        this.$('.js-inner-sources-container').append($(content));
    }
});
