export default class CompetencyEvaluationModel extends Backbone.Model {

    get url() {
        if (this.id) {
            return `/competencies/evaluations/${this.id}`
        }
        return '/competencies/evaluations'
    }

    initialize({evaluation}) {
        this.set({evaluation: new Backbone.Collection(evaluation)})
        this.saveEvaluationDebounced = _.debounce(this.saveEvaluation, 1000)
        this.on('change:evaluator change:evaluation_date', () => {
            if (!this.isNew()) {
                this.saveEvaluationDebounced()
            }
        })
        this.get('evaluation').on('update', () => {
            this.saveEvaluationDebounced()
        })
    }

    saveEvaluation(isFinished) {
        this.gatherComponentEvaluations()
        // When finalizing the evaluation, set the is_finished flag during save and wait for the backend
        // to respond to show the right score in the frontend.
        this.pendingRequestXHR = isFinished ? this.save({is_finished: true}, {wait: true}) : this.save()
    }

    gatherComponentEvaluations() {
        this.get('evaluation').remove(this.get('evaluation').filter((componentEvaluationModel) => {
            return !componentEvaluationModel.isValid()
        }), {silent: true})
    }

    getCriterium(componentId) {
        return this.get('evaluation').get(componentId)?.get('criterium_id')
    }

    setCriterium(componentId, criterium_id) {
        this.get('evaluation').add({
            id: componentId,
            criterium_id
        }, {merge: true})
    }

    getComment(componentId) {
        return this.get('evaluation').get(componentId)?.get('comment')
    }

    setComment(componentId, comment) {
        this.get('evaluation').add({
            id: componentId,
            comment
        }, {merge: true})
    }

    finalize() {
        if (!this.get('evaluator')) {
            throw window.i18n.gettext('Evaluator\'s name is missing')
        }
        if (!this.get('evaluation_date')) {
            throw window.i18n.gettext('Evaluation date is missing')
        }
        const hasAnswerForAllComponents = this.getCompetencyVersion().get('components').every((component) => {
            return this.get('evaluation').has(component.id)
        })
        if (!hasAnswerForAllComponents) {
            throw window.i18n.gettext('Not all evaluation criteria are set')
        }

        this.saveEvaluationDebounced.cancel()
        // If there is on ongoing request, wait for this to complete before saving the final evaluation.
        if (this.pendingRequestXHR) {
            this.pendingRequestXHR.then(() => {
                this.saveEvaluation(true)
            })
        } else {
            this.saveEvaluation(true)
        }
    }

    getCompetencyVersion() {
        return this.collection.competencyVersions.findWhere({id: this.get('competency_id')})
    }

    getScore() {
        const score = this.get('score')
        if (Number.isFinite(score)) {
            return score
        }
        if (this.has('scores')) {
            return this.get('scores').normalized_score
        }
        return 0
    }

}
