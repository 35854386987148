import SubjectModel from 'models/SubjectModel';

export default Backbone.Collection.extend({
    model: SubjectModel,

    parse(response) {
        return response.subjects
    }
}, {
    type: 'subjects'
})
