import Styles from './LatexEditor.scss';

import Template from './LatexEditor.hbs';
import Util from 'util/util';
import 'vendor/mathquill-0.10.1/mathquill.css';
import { LatexEditorLayout, MobileLatexEditorLayout } from './LatexEditorLayout'

export default class LatexEditor extends BaseView {

    static get keyboardLayout() {
        return ISMOBILE ? MobileLatexEditorLayout : LatexEditorLayout
    }

    initialize(options) {

        // Bind this to the following functions
        _.bindAll(
            this,
            'getLatex',
            'onVirtualKeyboardInput',
            'getOptions',
            'mathQuillLoaded',
            'mathQuillLoadFail',
            'onPaste',
            'onKeyDown',
        );

        this.setElement(Template({
            Styles
        }));

        this.atElement = options.atElement;

        this.chosenOptions = {}

        import(
            /* webpackChunkName: "mathquill" */
            'mathquill'
        ).then(this.mathQuillLoaded, this.mathQuillLoadFail)
    }

    mathQuillLoadFail() {
        Backbone.View.layout.openStatus(
            window.i18n.gettext('You are offline. Check your internet connection.'),
            'warning'
        );
        Backbone.View.Components.keyboard.close();
        this.destroy();
    }

    mathQuillLoaded({default: MathQuill}) {

        // The DOM element the cursor of the editor is currently at.
        const cursorElement = $(this.atElement)

        // Create an instance of MathQuill with the latest version of its
        // interface (v2 at the moment of writing).
        // http://docs.mathquill.com/en/latest/Api_Methods
        var MQ = MathQuill.getInterface(2);
        // Create new editable MathQuill input field.
        this.mathField = MQ.MathField(
            // Inputfield element
            this.el,
            {
                substituteTextarea() {
                    return $(
                        '<textarea autocomplete="off" autocorrect="off" autocapitalize="off" ' +
                        'spellcheck="false" readonly autofocus>'
                    )[0];
                },
                restrictMismatchedBrackets: true,
                spacesBehavesLikeTab: true,
                supSubsRequireOperand: true
            }
        );

        // If the element under the cursor is an image with the .js-expression
        // class, prefil the mathField with the LaTeX string. Setting a LaTeX
        // string with the .latex() method will trigger an edit event, resulting
        // in a preview rendering of the LaTeX as an SVG.
        if (cursorElement.hasClass('js-expression') && cursorElement.is('img')) {
            let encodedLatex
            let b64Latex
            // Try extracting a base 64 encoded LaTeX formule from base64formula data attribute. Decode from
            // URL-safe encoding to make sure it is a valid b64 input string. If the base64formula data attribute is
            // empty, try extracting it from the base64svg svg shape data instead.
            try {
                b64Latex = cursorElement.data('base64formula')
                if (b64Latex) {
                    b64Latex = decodeURIComponent(b64Latex);
                    encodedLatex = Util.base64Decode(b64Latex);
                } else {
                    encodedLatex = Util.base64Decode(
                        $(Util.base64Decode(cursorElement.data('base64svg'))).data('base64-encoded-tex')
                    );
                }
            } catch (e) {
                console.error(e)

                window.sentry.withScope(() => {
                    window.sentry.captureException(e);
                });

            }
            encodedLatex = encodedLatex.replace(/(?:\\,)/g, ' ').replace(/\\text\{\}/g, '')
            this.mathField.latex(encodedLatex);
            this.chosenOptions = {
                texFormula: b64Latex,
                content: cursorElement.data('base64svg'),
            }
        }

        this.mathFieldTextArea = this.el.querySelector('.mq-textarea textarea')

        // Set focus on mathField text area after everything else is fully loaded.
        _.defer(() => {
            this.mathField.reflow()
            this.mathFieldTextArea.focus()

            document.addEventListener('keydown', this.onKeyDown)
            document.addEventListener('paste', this.onPaste)
        })

    }

    /**
     * onPaste
     *
     * When user triggers a paste event, retrieve the clipboard data and write it as LaTeX to the mathField.
     *
     * @param  {Event} e Paste event data
     */
    onPaste(e) {
        e.preventDefault();
        this.mathField.write((e.originalEvent || e).clipboardData.getData('text'))
    }

    /**
     * onKeyDown
     *
     * Since mathField uses a readonly textarea, it's impossilbe to type into
     * this field directly. With the keydown event and this function it becomes
     * possible to emulate a real textarea without having to apply focus. This
     * prevents any native on screen keyboards on iOS and other touchscreen
     * environments from appearing.
     *
     * @param  {Event} e keydown event data.
     */
    onKeyDown(e) {
        // Prevent keyboard event if user presses the space bar on a keyboard button.
        if (e.key === ' ' && e.target.tagName === 'BUTTON') {
            return
        }
        // Pass key events to mathquill field instance if the key pressed is a single character,
        // also not when the textarea doesn't have the readonly attribute that
        // prevents keyboard inputs from being applied twice.
        if (
            !e.isComposing &&
            e.metaKey === false && e.ctrlKey === false &&
            e.key.length === 1 &&
            this.mathFieldTextArea.readOnly
        ) {
            e.preventDefault()
            this.mathField.cmd(e.key);
        }
    }

    /**
     * onVirtualKeyboardInput
     *
     * Mandatory method that is required for having the current view work with
     * the keyboard component (Backbone.View.Components.keyboard). It's called by
     * they keyboard every time a key is pressed.
     *
     * @param  {String}  input     The command retrieved from the key 'data-cmd' attribute.
     * @param  {Boolean} isSpecial If the key is a special key (shift, arrow keys, etc.).
     */
    onVirtualKeyboardInput(input, isSpecial) {
        // If it's a special key, execute a keystroke in the math field
        // http://docs.mathquill.com/en/latest/Api_Methods/#keystrokekeys
        // If not, just put in the input verbatim
        // http://docs.mathquill.com/en/latest/Api_Methods/#typedtexttext
        if (isSpecial) {
            this.mathField.keystroke(input);
        } else if (input.length > 1 && input.charAt(0) === '\\') {
            this.mathField.write(input);

            // If input ends with a closing bracket (eg. "sin()"), move cursor inside the brackets.
            if (/[\)\]\}]$/.test(input)) {
                this.mathField.keystroke('Left')
            }
        } else {
            this.mathField.typedText(input)

            // If input starts with a backslash and ends with a space, like '\\times ', enter a backspace keystroke
            // to remove the space at the end of the input that is needed to close the command to remove unwanted
            // whitespace in the output.
            if (/^\\.+\s$/.test(input)) {
                this.mathField.keystroke('Backspace')
            }
        }
        // After each establish focus on the math quil instance so the cursor maintains it's
        // position.
        this.mathField.focus();
    }

    /**
     * toggleVirtualKeyboard
     *
     * Performs actions that disables virtual keyboard such that the native on-screen keyboard
     * is allowed to appear.
     *
     * @param {Boolean} enable  Enable virtual keyboard
     */
    toggleVirtualKeyboard(enable) {
        if (enable) {
            this.mathFieldTextArea.readOnly = true
        } else {
            this.mathFieldTextArea.readOnly = false
            this.mathFieldTextArea.focus()
        }
    }

    /**
     * getLatex
     *
     * Extract the current LaTeX string from the mathField. If string is not the same as the last one
     * to prevent duplicate server requests. Then request a SVG render of the LaTeX formula.
     * @returns {Promise} AJAX request object
     */
    getLatex() {
        const latex = this.mathField.latex() || '\ '
        const b64Latex = Util.base64Encode(latex)

        // Encode base64 LaTeX string to URL-safe format.
        const urlSafeB64Latex = encodeURIComponent(b64Latex)

        return $.ajax({
            // For debugging use http://localhost:6010/?latex=${urlSafeB64Latex}
            url: `/sources/render_tex/${urlSafeB64Latex}.json`,
            beforeSend: (jqXHR) => {
                // If latex is just whitespace or an empty string, empty the chosenOptions and do not bother requesting
                // an SVG from the LaTeX rendering server.
                if (latex.replace(/[\\\s]/g, '').length === 0) {
                    this.chosenOptions = {}
                    // Abort any still ongoing requests to prevent earlier laster resolving requests do not overwrite
                    // the result of the most recent request.
                    jqXHR.abort()
                    throw new Error('Formula is empty!')
                }
            },
            success: data => {
                const base64Image = Util.base64Encode(data)

                // Set the content param to the SVG
                this.chosenOptions = {
                    texFormula: b64Latex,
                    content: base64Image,
                }
            },
            error: () => {
                const errorMessage = window.i18n.gettext('The server could not be reached. Check your internet connection.')
                Backbone.View.layout.openStatus(errorMessage)
            },
            /**
             * override our own JSON dataType default implementation (see our
             * $.ajaxSetup() method) else jQuery will try to parse JSON and break
             */
            dataType: 'text',
            cache: true,
            global: false,
        })
    }

    // Getter function for options
    async getOptions() {
        try {
            await this.getLatex()
            Backbone.View.Components.keyboard.close()
            return _.clone(this.chosenOptions)
        } catch (error) {
            console.warn('Requesting rendered LaTeX went wrong…', error)
        }
    }

    onDestroy() {
        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('paste', this.onPaste)
    }

}
