var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \" style=\"background-image:url("
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"groupImage") : depth0), depth0))
    + ");\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"no-image") : stack1), depth0))
    + "\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"card") : stack1), depth0))
    + "\">\n\n    <a href=\"/groups/show/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"groupID") : depth0), depth0))
    + "\" class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"header") : stack1), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"groupImage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "\"\n    >\n        <div class=\"js-header\"></div>\n        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"groupName") : depth0), depth0))
    + "</span>\n    </a>\n\n    <div class=\"js-list "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"list") : stack1), depth0))
    + "\" data-empty=\""
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"This group doesn't have any content yet.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":14,"column":53},"end":{"line":14,"column":102}}}))
    + "\"></div>\n\n</div>\n";
},"useData":true});