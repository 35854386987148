import Styles from './Checkbox.scss';

import Template from './Checkbox.hbs';
import Util from 'util/util';

export default BaseView.extend({

    events: {
        'change input': 'onChange',
        click: 'onClick'
    },

    initialize({
        callback,
        label,
        isChecked,
        isDisabled,
        onClickDisabled,
        parentGroup,
        rightAlignedText,
        subText,
        tooltip,
        value,
    }) {

        const hasViewLabel = label instanceof BaseView;

        this.setElement(Template({
            Styles,
            cid: this.cid,
            label: hasViewLabel
                // Render empty string if label is a view
                ? ''
                : Util.renderContentSafely(label),
            parentGroup,
            rightAlignedText,
            subText,
            tooltip,
            value,
        }));

        if (hasViewLabel) {
            label.appendTo(this.$('.js-checkbox-label'));
        }

        this.inputElement = this.el.querySelector('input');

        this.callback = callback;
        this.onClickDisabled = onClickDisabled

        this.setDisabled(isDisabled);

        this.setState(isChecked, true);
    },

    setDisabled(isDisabled = false) {
        this.inputElement.disabled = isDisabled;
    },

    isDisabled() {
        return this.inputElement.disabled;
    },

    toggleState() {
        this.setState(!this.getState());
    },

    /**
     * setState
     *
     * Set state in DOM and execute optional callback.
     *
     * @param {Boolean} isChecked   New active state
     * @param {Boolean} silent      If true do not execute the callback
     */
    setState(isChecked, silent) {
        this.inputElement.checked = isChecked;
        if (!silent && this.callback) {
            this.callback(this.getState());
        }
    },

    getState() {
        return this.inputElement.checked;
    },

    /**
     * onChange
     *
     * On change event, apply state checkbox state and execute the optional callback using the setState method.
     */
    onChange() {
        this.setState(this.getState());
    },

    /**
     * onClick
     *
     * Prevent click event listeners in parent elements from triggering.
     *
     * @param {jQuery.event} e      click event
     */
    onClick(e) {
        if (this.isDisabled() && this.onClickDisabled) {
            this.onClickDisabled()
        }
        e.stopImmediatePropagation();
    },

    setLabel(newLabel) {
        this.el.querySelector('.js-checkbox-label').textContent = newLabel
    },

    hide() {
        this.$el.hide();
    },

    show() {
        this.$el.css({
            display: 'flex'
        });
    }

});
