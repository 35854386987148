import Styles from './Greeting.scss';

import Template from './Greeting.hbs';
export default class Greeting extends BaseView {

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     * @param  {Object} options     Object as passed by parent
     */
    initialize({
        userModel = Backbone.Model.user,
        hideAvatar,
        flipLabels,
        message,
        stringName,
        fullName,
        callback
    }) {
        let avatar = userModel.get('avatar')
        if (hideAvatar) {
            avatar = false
        }

        // Create the view, passing the styling with it
        this.setElement(Template({

            // Sent the avatar url to the view
            userAvatar: avatar,

            flipLabels,

            // Set the message to a given a passed one or set default
            message: message || (

            // Check if the user is a teacher
                (userModel.get('is_teacher')) ?

                    // Get a more formal version of hello
                    this.getCorrectMessageForTimeOfDay() :

                    // Else the user is a student, send informal version of hello
                    window.i18n.gettext('Hello!')
            ),

            // Sent the username to the view
            userName: (

            // Check if there is a string-name set
                (stringName) ?

                    // If set, use the stringname as name
                    stringName :

                    // Else when not set
                    (

                        // Check if fullname is requested or user is teacher
                        (fullName || userModel.get('is_teacher')) ?

                        // If fullName is requested, send the fullname
                            userModel.first_name_last_name() :

                        // Else only use the first name
                            userModel.get('first_name')
                    )
            ),

            hasCallback: !!callback,

            // Pass the styling to the view
            Styles
        }));

        if (callback) {
            this.el.addEventListener('click', callback)
        }
    }

    getCorrectMessageForTimeOfDay() {

        // Put possible options nicely in an array
        const timezones = [

            // Between 06:00 & 11:00
            [6, 11, window.i18n.gettext('Good morning!')],

            // Between 12:00 & 17:00
            [12, 17, window.i18n.gettext('Good afternoon!')],

            // Between 18:00 & 23:00
            [18, 23, window.i18n.gettext('Good evening!')],

            // Between 0:00 & 05:00
            [0, 5, window.i18n.gettext('Good night!')],
        ];

        // Get the hour
        var hour = new Date().getHours();

        // Create a variable for timezone message, default it to universal
        var timezoneMessage = window.i18n.gettext('Good day!');

        // Loop trough each timezone option
        for (var timezoneIndex in timezones) {
            // Check if this matches the between hours
            if (hour >= timezones[timezoneIndex][0] && hour <= timezones[timezoneIndex][1]) {

                // Put the string in the right element
                timezoneMessage = timezones[timezoneIndex][2];
                return timezoneMessage;
            }
        }

        // Return the selected timezone
        return timezoneMessage;
    }

}
