import Styles from './SinglePracticeCard.scss';

import Template from './SinglePracticeCard.hbs';
import Button from 'views/components/button/Button'
import StarRater from 'views/components/starRater/StarRater'
import shapeList from 'util/ShapeLoader'

export default class SinglePracticeCard extends BaseView {

    initialize({
        cardTitle,
        cardIcon,
        descriptionText,
        starAmount,
        buttonCallback,
        isDone,
        isDisabled = false,
    }) {

        this.setElement(Template({
            Styles,
            cardTitle,
            descriptionText,
            cardIcon,
            isDone,
        }))

        this.button = this.addChildView(new Button({
            label: window.i18n.gettext('Begin'),
            callback: buttonCallback,
            disabled: isDisabled,
        }), '.js-card-button')

        this.$('.js-card-icon-container').html(shapeList[cardIcon])

        this.addChildView(
            new StarRater({
                amount: 3,
                size: 'small',
                starred: starAmount,
                isEditable: false,
                identifier: `card-${starAmount}`
            }),
            '.js-stars-container'
        )

    }
}
