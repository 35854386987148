import Styles from './AudioRecorder.scss';

import Template from './AudioRecorder.hbs';
import AudioStream from 'util/AudioStream'
import AudioPlayer from 'views/components/audioPlayer/AudioPlayer'
import UploadModel from 'models/UploadModel'
import Button from 'views/components/button/Button'

export default class AudioRecorder extends BaseView {

    get maxDuration() {
        // 1200 seconds = 20 minutes
        // Depending on the encoder on the user's device, this should be close to but safely under the
        // max file size of `task-audio` and `source-audio` of 50 megabytes.
        return 1200
    }

    initialize({
        uploadType,
        onRecordingUploaded = () => {},
        onRecordingDeleted = () => {},
        audioPlayerOptions = { url: null },
        hasFileUploadButton = false,
        fileUploadButtonOptions = {},
    }) {

        this.uploadType = uploadType
        this.onRecordingUploaded = onRecordingUploaded
        this.onRecordingDeleted = onRecordingDeleted
        this.audioPlayerOptions = audioPlayerOptions
        this.hasFileUploadButton = hasFileUploadButton
        this.fileUploadButtonOptions = fileUploadButtonOptions

        this.setElement(Template({Styles}))

        if (audioPlayerOptions.url) {
            this.createPlayer()
        } else {
            this.createRecorder()
        }

    }

    reset() {
        this.player?.destroy()
        this.startRecordButton?.destroy()
        this.stopRecordButton?.destroy()
        this.fileUploadButton?.destroy()
        this.deleteRecordButton?.destroy()
        this.uploadModel?.destroy()
    }

    createPlayer() {
        this.reset()
        this.$('.js-upload-file').hide()
        this.deleteRecordButton = this.addChildView(new Button({
            inline: true,
            icon: 'trash',
            theme: 'transparent',
            callback: () => {
                Backbone.View.layout.openConfirmStatus(
                    window.i18n.gettext('Are you sure you want to remove this recording?'),
                    () => {
                        this.onRecordingDeleted()
                        this.createRecorder()
                    },
                    () => {},
                    window.i18n.gettext('Remove'),
                )
            }
        }), '.js-player')
        this.player = this.addChildView(new AudioPlayer(this.audioPlayerOptions), '.js-player')
    }

    createRecorder() {
        this.reset()
        if (this.hasFileUploadButton) {
            this.fileUploadButton = this.addChildView(new Button({
                label: window.i18n.gettext('Upload audio file'),
                inline: true,
                icon: 'file-upload',
                ...this.fileUploadButtonOptions,
            }), '.js-upload-file', 'prepend')
        }
        this.$('.js-upload-file').toggle(this.hasFileUploadButton === true)

        this.uploadModel = new UploadModel({
            uploadType: this.uploadType,
            accept: '.mp3, .wav, .m4a, audio/*',
            doNotCreateInputElement: !this.hasFileUploadButton,
            element: this.hasFileUploadButton ? this.fileUploadButton.$el : null,
        })
        this.uploadModel.on('change:status', (model, status) => {
            switch (status) {
                case 'loading':
                    this.startRecordButton?.disable()
                    this.stopRecordButton?.disable(true)
                    this.fileUploadButton?.disable(model.get('initiator') === model.get('element'))
                    break
                case 'success':
                    this.onRecordingUploaded(model)
                    this.audioPlayerOptions.url = model.get('eduFileUrl')
                    this.createPlayer()
                    break
                case 'error':
                    this.createRecorder()
                    break
            }
        })

        this.startRecordButton = this.addChildView(new Button({
            inline: true,
            icon: 'microphone',
            label: window.i18n.gettext('Start recording'),
            callback: this.activateRecorder.bind(this)
        }), '.js-recorder')
    }

    async activateRecorder() {
        this.mediaRecorder = await this.createMediaRecorderObject()
        if (!this.mediaRecorder) {
            return
        }

        this.seconds = 0
        let recordTimer

        this.stopRecordButton = this.addChildView(new Button({
            inline: true,
            icon: 'stop',
            callback: () => {
                this.mediaRecorder.stop()
            },
            label: `${this.countSeconds(0)} / ${this.countSeconds(this.maxDuration)}`,
        }), '.js-recorder')

        this.startRecordButton.destroy()
        this.fileUploadButton?.disable()

        this.mediaRecorder.addEventListener('start', () => {
            this.$('.js-recording-dot').show()
            recordTimer = setInterval(() => {
                this.seconds++
                if (this.seconds > this.maxDuration) {
                    this.mediaRecorder.stop()
                }
                this.stopRecordButton.changeLabel(
                    `${this.countSeconds(this.seconds)} / ${this.countSeconds(this.maxDuration)}`
                )
            }, 1000)
        })

        this.mediaRecorder.addEventListener('stop', () => {
            this.$('.js-recording-dot').hide()
            clearInterval(recordTimer)
            for (const track of this.mediaRecorder.stream.getAudioTracks()) {
                track.stop()
            }
            this.stopRecordButton.disable(true)
        })

        this.mediaRecorder.addEventListener('dataavailable', (dataAvailableEvent) => {
            if (dataAvailableEvent.data.size === 0) {
                this.createRecorder()
                return
            }
            this.stopRecordButton.changeLabel(window.i18n.gettext('Saving your recording…'))
            this.uploadModel.submitFile.call(this.uploadModel, dataAvailableEvent.data)
        })

        this.mediaRecorder.start()
    }

    async createMediaRecorderObject() {
        const mediaRecorderOptions = {}
        if (MediaRecorder.isTypeSupported('audio.mp4')) {
            mediaRecorderOptions.mimeType = 'audio/mp4'
        } else if (MediaRecorder.isTypeSupported('audio/webm')) {
            mediaRecorderOptions.mimeType = 'audio/webm'
        }

        try {
            const stream = await AudioStream.askForPermission((message) => {
                Backbone.View.layout.openStatus(
                    message
                )
            })
            return new MediaRecorder(stream, mediaRecorderOptions)
        } catch (error) {
            console.warn('createMediaRecorderObject', error)
        }
    }

    countSeconds(seconds) {
        return Math.floor(seconds / 60) + ':' + (seconds % 60).toString().padStart(2, '0')
    }

    enable() {
        if (this.audioPlayerOptions.url) {
            this.createPlayer()
        } else {
            this.createRecorder()
        }
    }

    disable() {
        try {
            this.mediaRecorder?.stop()
        } catch (error) {
            console.warn('mediaRecorder cannot be stopped', error)
        }
        this.startRecordButton?.disable()
        this.stopRecordButton?.disable()
        this.deleteRecordButton?.disable()
    }

}
