var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"star") : stack1), depth0))
    + "\" data-star-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\">\n    <input type=\"checkbox\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"starIdentifier") : depth0), depth0))
    + "\" />\n    <label for=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"starIdentifier") : depth0), depth0))
    + "\">"
    + ((stack1 = __default(require("../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"star-rounded",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":64}}})) != null ? stack1 : "")
    + "</label>\n</div>\n";
},"useData":true});