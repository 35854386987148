import Styles from './Item.scss';

import Template from './Item.hbs';
import Util from 'util/util';

export default BaseView.extend({

    initialize(options) {
        this.setElement(Template({
            Styles,
            label: Util.renderContentSafely(options.label),
            index: options.index
        }));

    },

    lock() {
        this.el.classList.add(Styles['is-locked'])
        this.undelegateEvents();
    },

    unlock() {
        this.el.classList.remove(Styles['is-locked'])
        this.delegateEvents();
    }

});
