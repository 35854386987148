import Styles from './GroupCard.scss';

import Template from './GroupCard.hbs';
import Button from 'views/components/button/Button'
import Util from 'util/util'
import ContentLabel from 'views/components/contentLabel/ContentLabel.svelte'
import Item from './item/Item'

export default BaseView.extend({

    events: {
        'click a': 'logAnalytics'
    },

    /**
     * initialize
     *
     * View for the teacher's home page containing information either about 3 studyplanner weeks or
     * about the 3 most recent worked on sections or activities. Each with statisics on progress and
     * links to the content itself.
     *
     * @param  {type} options   Options as defined by parent
     */
    initialize({
        sections,
        activities,
        weeks
    }) {

        const hasSections = !!sections.length
        const hasActivities = !!activities.length

        this.setElement(Template({
            Styles,
            isFavorite: this.model.get('is_favorite')
        }))

        this.addSvelteChildView('.js-card-title', ContentLabel, {
            model: this.model,
            isOnBackground: true,
            truncateName: true,
            link: '/groups/show/' + this.model.id
        })

        if (weeks) {
            this.renderCardWithStudyplanner(weeks)
        } else if (hasActivities) {
            this.renderCardWithContent(activities, true)
        } else if (hasSections) {
            this.renderCardWithContent(sections, false)
        } else {
            this.$('.js-list-empty').text(window.i18n.gettext('This group doesn\'t have content yet.'))
            if (!ISMOBILE) {
                this.addChildView(
                    new Button({
                        icon: 'library',
                        label: window.i18n.gettext('Go to the library'),
                        callback: () => {
                            Backbone.history.navigate('library/home/' + this.model.get('id'), { trigger: true })
                        },
                        theme: 'secondary',
                        inline: true
                    }),
                    '.js-list-empty'
                )
            }
        }

    },

    renderCardWithStudyplanner(weeks) {

        weeks = Util.sortWeeks(weeks)

        let index = 0
        for (const week of weeks) {
            const url = '/groups/planner/' + this.model.id + '/' + week.week + '/' + week.year
            this.addChildView(
                new Item({
                    model: new Backbone.Model({
                        week: week.week,
                        year: week.year,
                        index
                    }),
                    contentUrl: url,
                    hasProgress: _.isFinite(week.progress),
                    progress: Math.round(week.progress * 100) / 100,
                    progressUrl: url
                }),
                '.js-list'
            )
            index++
        }

    },

    renderCardWithContent(items = [], showActivities = false) {

        const layerCollection = Backbone.Collection[showActivities ? 'activities' : 'sections']

        const alteredSections = items.reduce((m, item) => {
            const model = layerCollection.get(item[showActivities ? 'activity_id' : 'section_id'])

            if (model && m.length < 3) {
                m.push({
                    model,
                    hasProgress: _.isFinite(item.progress),
                    progress: Math.round(item.progress * 100) / 100
                })
            }
            return m
        }, [])

        for (const content of alteredSections) {
            this.addChildView(
                new Item({
                    model: content.model,
                    contentUrl: (showActivities ? '/activities/show/' : '/sections/show/') + content.model.id,
                    hasProgress: content.hasProgress,
                    progress: content.progress,
                    progressUrl: (showActivities ? '/activities/progress/' : '/sections/progress/') + content.model.id,
                }),
                '.js-list'
            )
        }

    },

    logAnalytics(e) {

        // Get the URL without id's (e.g. /groups/planner)
        const baseUrl = e.currentTarget.getAttribute('href').split('/').slice(0, 3).join('/')

        // If the link is part of an item, get the index of the chosen item (e.g. second item)
        let itemText = ''
        const closestItem = e.currentTarget.closest('.js-item')
        if (closestItem) {
            for (const [key, item] of this.el.querySelectorAll('.js-item').entries()) {
                if (item === closestItem) {
                    itemText = ' item ' + (key + 1)
                    break
                }
            }
        }

        window.statsTracker.trackEvent(
            'users/home',
            'Teacher card: ' + baseUrl + itemText
        )
    }

});
