import Styles from './Modal.scss';

import Template from './Modal.hbs';
import Button from 'views/components/button/Button'
import Util from 'util/util'
import T16Styles from '../Template16.scss'
import ShapeList from 'util/ShapeLoader'
import AppointMenu from './appointMenu/AppointMenu'
export default class ParseSentenceModal extends BaseView {

    get events() {
        return {
            'click .js-parse-sentence [data-type="separator"]': 'onClickSeparator',
            'click .js-parse-sentence [data-type="glue"]': 'onClickGlue',
            'click .js-parse-sentence [data-type="word"]': 'onClickWord'
        }
    }

    get resettedWordsAndUnderlined() {

        const currentWords = this.model.get('task_info_json')?.sentence
            || this.model.get('task_info_json')?.words
            || [{ text: '', parse: '*' }];
        const sentence = _.pluck(currentWords, 'text').join(' ');

        const words = [{
            text: sentence,
            parse: '*'
        }];

        const underlined = (sentence.split(' ') || []).map(text => {
            return {
                text,
                underlined: 0
            }
        });

        return { words, underlined }
    }

    get baseWordsAndUnderlined() {

        if (this.response?.get('json_answer')?.words
            && this.response?.get('json_answer')?.underlined
        ) {
            return { ...this.response.get('json_answer') }
        }

        if (this.model.get('task_info_json').words
            && this.model.get('task_info_json').underlined
        ) {
            return { ...this.model.get('task_info_json') }
        }

        return this.resettedWordsAndUnderlined;
    }

    initialize({ parentView }) {

        _.bindAll(
            this,
            'reset',
            'onClickGlue',
            'onClickSeparator',
            'onClickWord',
            'onClickSave'
        );

        this.parentView = parentView;

        this.createHTMLFragments = parentView.createHTMLFragments;
        this.response = parentView.response;
        this.saveResponse = parentView.saveResponse;
        this.definitionMapping = parentView.definitionMapping;

        this.setElement(Template({
            Styles,
            T16Styles,
            taskIntroduction: Util.renderContentSafely(this.model.get('introduction'))
        }))

        this.addChildView(
            new Button({
                label: window.i18n.gettext('Start over'),
                callback: () => this.reset(),
                icon: 'restart',
                inline: true,
                theme: 'secondary'
            }),
            '.js-reset-button'
        )

        this.taskInfo = this.baseWordsAndUnderlined;
        this.renderSentence();
    }

    /**
     * Method to reset back to the base. It'll replace the taskInfo
     * which is used for rendering with the base with which we started
     * and call a rerender.
     *
     * @memberof ParseSentenceModal
     */
    reset() {
        this.taskInfo = this.resettedWordsAndUnderlined;
        this.renderSentence()
    }

    /**
     * This method will render the sentence withing the fullscreen modal.
     * It'll use the taskInfo property of this instance as truth and can
     * be called again if taskInfo is changed to trigger a rerender.
     *
     * @memberof ParseSentenceModal
     */
    renderSentence() {

        this.destroyChildViewsOfInstance(AppointMenu);

        const { words, underlined } = this.taskInfo;

        const fragments = this.createHTMLFragments(words, underlined, true)
        const container = this.el.querySelector('.js-parse-sentence')

        container.innerText = '';

        fragments.forEach((fragment, index) => {
            fragment.dataset.wordIndex = index;
            container.appendChild(fragment)

            if (fragments.length !== index + 1) {
                const glue = document.createElement('span');
                glue.dataset.type = 'glue'
                glue.innerHTML = ShapeList['horizontal-align-center']
                container.appendChild(glue);
            }
        });

        container
            .querySelectorAll('.js-parse-sentence [data-type="word"]:not(:last-of-type)')
            .forEach(wordElement => {
                const separator = document.createElement('span');
                separator.dataset.type = 'separator';
                wordElement.after(separator);
            })

        const appointOptions = this.model.get('task_info_json')?.options;

        if (appointOptions.indexOf('*') !== -1) {
            appointOptions.splice(appointOptions.indexOf('*'), 1);
        }

        if (appointOptions && appointOptions.length > 0) {

            container
                .querySelectorAll('.js-parse-sentence [data-type="sentence-partial"]')
                .forEach(sentencePartial => {

                    const parentNode = sentencePartial?.closest('[data-word-index]')
                    const wordIndex = parseInt(parentNode?.dataset?.wordIndex)
                    const currentType = words[wordIndex].parse;

                    this.addChildView(
                        new AppointMenu({
                            definitionMapping: this.definitionMapping,
                            currentType,
                            changeParseType: (parse) => {
                                const changedWord = {
                                    ...words[wordIndex],
                                    parse
                                }

                                this.taskInfo.words = [
                                    ...words.slice(0, wordIndex),
                                    changedWord,
                                    ...words.slice(wordIndex + 1),
                                ]

                                this.renderSentence()
                            },
                            appointOptions
                        }),
                        this.$(sentencePartial),
                        'after'
                    )
                });
        }
    }

    /**
     * Method which will be called when the user click on a
     * separator element. It should split the sentence on the
     * clicked position.
     *
     * @param {Object} e event object
     * @memberof ParseSentenceModal
     */
    onClickSeparator(e) {
        const { words } = this.taskInfo;
        const clickedSeparator = e.currentTarget
        const parentNode = clickedSeparator.closest('[data-word-index]');
        const wordIndex = parseInt(parentNode?.dataset?.wordIndex || 0);

        let sibling = clickedSeparator.previousElementSibling;
        const firstHalf = []

        while (sibling instanceof Element) {
            if (sibling.dataset.type === 'word') {
                firstHalf.push(sibling.innerText)
            }
            sibling = sibling.previousElementSibling;
        }

        sibling = clickedSeparator.nextElementSibling;
        const secondHalf = []

        while (sibling instanceof Element) {
            if (sibling.dataset.type === 'word') {
                secondHalf.push(sibling.innerText)
            }
            sibling = sibling.nextElementSibling;
        }

        // We should reverse the first half because we're starting at the clicked word
        // and work our way to the first element. Therefore the array is in the opposite
        // order of which we want it to be.
        firstHalf.reverse();

        // Do not change the words array itself, rather replace it with a newly
        // constructed array. This way we don't have to dereference the taskInfo
        // because else the reset won't work because the words will be changed in
        // the base
        this.taskInfo.words = [
            ...words.slice(0, wordIndex),
            { text: firstHalf.join(' '), parse: '*' },
            { text: secondHalf.join(' '), parse: '*' },
            ...words.slice(wordIndex + 1)
        ];

        this.renderSentence();
    }

    /**
     * Method which will be called when the user clicks on a
     * glue element. It should merge the section before the glue
     * to the section after the glue.
     *
     * @param {object} e event object
     * @memberof ParseSentenceModal
     */
    onClickGlue(e) {

        const { words } = this.taskInfo
        const glueElement = e.currentTarget

        const previousItemIndex = parseInt(glueElement.previousElementSibling.dataset.wordIndex);
        const nextItemIndex = parseInt(glueElement.nextElementSibling.dataset.wordIndex);

        const combinedItem = {
            text: [
                words[previousItemIndex].text,
                words[nextItemIndex].text
            ].join(' '),
            parse: '*'
        };

        // Do not change the words array itself, rather replace it with a newly
        // constructed array. This way we don't have to dereference the taskInfo
        // because else the reset won't work because the words will be changed in
        // the base
        this.taskInfo.words = [
            ...words.slice(0, previousItemIndex),
            combinedItem,
            ...words.slice(nextItemIndex + 1)
        ];

        this.renderSentence();
    }

    /**
     * Method which will be called if the user clicks on a
     * word element. It will search the word in the underlined
     * array and toggle the underlined state property of the
     * corresponding word.
     *
     * @param {Object} e event object
     * @memberof ParseSentenceModal
     */
    onClickWord(e) {
        const { underlined } = this.taskInfo;
        const wordElement = e.currentTarget;
        const allWordElements = [...this.el.querySelectorAll('.js-parse-sentence [data-type="word"]')];
        const wordIndex = allWordElements.indexOf(wordElement);

        const newUnderlinedItem = {
            ...underlined[wordIndex],
            underlined: !underlined[wordIndex]?.underlined ? 1 : 0
        }

        // Do not change the underlined array itself, rather replace it with a newly
        // constructed array. This way we don't have to dereference the taskInfo
        // because else the reset won't work because the underlined will be changed in
        // the base
        this.taskInfo.underlined = [
            ...underlined.slice(0, wordIndex),
            newUnderlinedItem,
            ...underlined.slice(wordIndex + 1),
        ]

        this.renderSentence();
    }

    /**
     * Method which will be called from the work on after the user
     * clicks on the save button. It will finalize the changes made
     * in the taskInfo property of this instance to the response and
     * close this fullscreen modal.
     *
     * @memberof ParseSentenceModal
     */
    onClickSave() {

        // If we can't add respones, we should fake the response by just
        // rendering what was done. This way the user can still see how this
        // template works.
        if (!ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD)) {
            this.parentView.renderSentence(this.taskInfo);
        } else {
            this.saveResponse(this.taskInfo);
        }

        Backbone.View.Components.fullscreen.close();
    }

    /**
     * Method which will be called from the author interface after
     * an author clicks on the save button. It will finalize the
     * changes made to the taskInfo property of this instace to the
     * taskInfoJson using the setJSON method of the parentview. And
     * close this fullscreen modal.
     *
     * @memberof ParseSentenceModal
     */
    onClickSaveAsJSON() {
        this.parentView.setJSON(this.taskInfo);
        Backbone.View.Components.fullscreen.close();
    }

    /**
     * This method will render the modelanswer in the modal so the
     * student can compare their answer to the modelanswer.
     *
     * @memberof ParseSentenceModal
     */
    onShowAnswer() {

        if (!this.modelAnswerElement) {
            const {
                correct_words: words,
                correct_underlined: underlined
            } = this.model.get('task_info_json')

            const sentenceHolder = document.createElement('section');
            sentenceHolder.classList.add(T16Styles['sentence']);

            const fragments = this.createHTMLFragments(words, underlined);
            fragments.forEach(fragment => sentenceHolder.appendChild(fragment));

            this.el.querySelector('.js-parse-sentence').before(sentenceHolder);

            this.modelAnswerElement = sentenceHolder;
        } else {
            this.modelAnswerElement.remove();
            delete this.modelAnswerElement
        }
    }
}
