import Template from './Session.hbs';
import QuestionCard from 'views/pages/words/questionCard/QuestionCard'
import ResultsCard from 'views/pages/words/results/Results'
import SessionModel from 'models/WordsSessionModel'

export default BaseView.extend({

    initialize(options) {

        this.setElement(Template({}))

        this.activityId = this.model && this.model.get('id')
        const sessionId = options.sessionId ? options.sessionId : options.params[0]

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle()
        Backbone.View.header.setCrumblepath()

        this.sessionModel = new SessionModel({
            id: sessionId,
            activityId: this.activityId
        })

        this.sessionModel.fetch({
            success: () => {
                this.onLoadList()
                this.addHeader();
            }
        })
    },

    onLoadList() {

        if (this.sessionModel.isFinished) {
            this.onLastAnswer(this.sessionModel.result)
        } else {
            this.questionCard = this.addChildView(
                new QuestionCard({
                    sessionModel: this.sessionModel,
                    showResults: (result) => {
                        this.onLastAnswer(result)
                    },
                    closeCallback: () => { this.closeTraining() }
                }),
                '.js-training-panel'
            )
        }
    },

    onLastAnswer(result) {

        if (this.questionCard) {
            this.unregisterAndDestroyChildView(this.questionCard)
        }
        this.addChildView(
            new ResultsCard({
                result,
                type: this.sessionModel.get('type'),
                listId: this.sessionModel.get('training_list_id'),
                closeCallback: () => { this.closeTraining() }
            }),
            '.js-training-panel'
        )
    },

    getTrainingName(type) {
        switch (type) {
            case 'cards' :
                return window.i18n.gettext('Turn cards')
            case 'multiple_choice' :
                return window.i18n.gettext('Multiple choice')
            case 'open' :
                return window.i18n.gettext('Open Question')
        }
    },

    addHeader() {

        if (this.activityId) {

            Backbone.View.header.setCrumblepath(
                this.model.getAllCrumblepathModels(),
                'show'
            );
        } else {

            Backbone.View.header.setCrumblepath([
                new Backbone.Model({
                    label: window.i18n.gettext('Little words'),
                    url: '/words/home'
                }),
                new Backbone.Model({
                    label: this.sessionModel.list.get('name'),
                    url: `/words/list/${this.sessionModel.list.get('id')}`
                }),
                new Backbone.Model({
                    label: this.getTrainingName(this.sessionModel.get('type')),
                    url: '/words/sessions/' + this.sessionModel.id
                }),
            ]);
        }
    },

    closeTraining() {
        const url = this.activityId ? `/activities/show/${this.activityId}` : `/words/list/${this.sessionModel.get('training_list_id')}`
        Backbone.history.navigate(url, {trigger: true})
    }

});
