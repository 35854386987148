var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-fullscreen-overlay "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"fullscreen-overlay") : stack1), depth0))
    + "\">\n    <h2>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h2>\n    <div class=\"js-fullscreen-btn\"></div>\n    <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Open fullscreen mode to go to the exam.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":58}}}))
    + "</span>\n</div>\n<div class=\"js-blur-overlay "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"blur-overlay") : stack1), depth0))
    + "\">\n    <h2>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h2>\n    <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Click on this screen to see the exam.",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":56}}}))
    + "</span>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"exam-activity--is-mobile") : stack1), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStudentExamMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"exam-activity") : stack1), depth0))
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/isMobile.js")).call(alias1,{"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":98}}})) != null ? stack1 : "")
    + "\">\n\n    <div class=\"js-activity-viewport\">\n        <div class=\"js-taskgroup\"></div>\n    </div>\n\n    <div class=\"center-panel\">\n        <div class=\"js-pagination-bar "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"Styles") : depth0)) != null ? lookupProperty(stack1,"pagination") : stack1), depth0))
    + "\"></div>\n    </div>\n\n</div>\n";
},"useData":true});