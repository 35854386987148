var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"center-panel\">\n    <div class=\"source-container\">\n"
    + ((stack1 = container.invokePartial(require("./downloadFile.hbs"),depth0,{"name":"downloadFile.hbs","hash":{"Styles":(depth0 != null ? lookupProperty(depth0,"Styles") : depth0),"title":(depth0 != null ? lookupProperty(depth0,"title") : depth0),"fileIdHash":(depth0 != null ? lookupProperty(depth0,"fileIdHash") : depth0),"fileId":(depth0 != null ? lookupProperty(depth0,"fileId") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});