var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./ActivityProgressBar.hbs"),depth0,{"name":"./ActivityProgressBar","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasExamGrade") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tile__navigation__btn tile__navigation__btn--locked\">\n            "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"assignment",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":36}}})) != null ? stack1 : "")
    + "\n            <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Exam grade",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":7,"column":18},"end":{"line":7,"column":37}}}))
    + "</span><strong>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/toLocaleString.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"examGrade") : depth0),{"name":"toLocaleString","hash":{},"data":data,"loc":{"start":{"line":7,"column":52},"end":{"line":7,"column":80}}}))
    + "</strong>\n        </div>\n        <div class=\"tile__navigation__btn tile__navigation__btn--locked\">\n            "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"assignment",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":36}}})) != null ? stack1 : "")
    + "\n            <span>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Exam points",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":11,"column":18},"end":{"line":11,"column":38}}}))
    + "</span><strong>"
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/toLocaleString.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"examAttainedPoints") : depth0),{"name":"toLocaleString","hash":{},"data":data,"loc":{"start":{"line":11,"column":53},"end":{"line":11,"column":90}}}))
    + " "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/__.js")).call(alias1,"of",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":11,"column":91},"end":{"line":11,"column":102}}}))
    + "\n                "
    + alias2(__default(require("../../../../../../util/handlebarsHelpers/toLocaleString.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"examAttainablePoints") : depth0),{"name":"toLocaleString","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":55}}}))
    + "</strong>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tile__navigation__btn tile__navigation__btn--locked\">\n            "
    + ((stack1 = __default(require("../../../../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"assignment",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":36}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"ungradedAnswersText") : depth0), depth0)) != null ? stack1 : "")
    + "\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasOpenExamSession") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});